import React from "react";
import { observer } from "mobx-react";

import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import AddonsSelection from "components/AddonsSelection";
import { useStores } from "../../../../stores";

const Devices = () => {
  const { productsStore, cartStore } = useStores();
  const devices = productsStore.getDevices();
  const devicesInCart = cartStore.getDevices();

  return (
    <AddonsSelection
      headline="Geräte Festnetz"
      name="Geräte"
      products={devices}
      activeProducts={devicesInCart}
      allowCustomProducts
      isFungible
      portfolio={PortfolioKeys.LANDLINE}
      addonGroup={Group.device}
    />
  );
};

export default observer(Devices);
