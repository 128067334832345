import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal as LPGModal, Button } from "@dtpk-cc/components";

import "components/Modal/modal.scss";

const Modal = ({
  children,
  active,
  close,
  showSubmitButton,
  handleSubmitButton,
  submitButtonText,
  submitButtonFullWidth,
  submitButtonDisabled,
  minHeight,
  customClass = "",
}) => {
  const modal = useRef(null);
  useEffect(() => {
    if (active && modal.current) modal.current.focus();
  }, [active]);

  return (
    <LPGModal
      customClass={customClass}
      isOpen={active}
      afterClose={close}
      portalless
      containerProps={{ "data-testid": "pst-modal" }}
      zIndex={100}
      style={{ width: "auto", minHeight }}
    >
      <LPGModal.Section customClass="modal--high-contrast">
        <div
          ref={modal}
          className={`modal__content ${
            showSubmitButton ? "modal__content--pb" : ""
          }`}
        >
          {children}
        </div>

        {showSubmitButton && (
          <div className="modal__button-container">
            <Button
              customClass={`
                modal__button
                ${submitButtonFullWidth ? "modal__button--fw" : ""}
              `}
              data-testid="modal-close"
              type="button"
              onClick={handleSubmitButton}
              hasMinWidth
              disabled={submitButtonDisabled}
              aria-label={submitButtonText}
            >
              {submitButtonText}
            </Button>
          </div>
        )}
      </LPGModal.Section>
    </LPGModal>
  );
};

Modal.defaultProps = {
  buttonText: "Ein default Button",
  active: false,
  showSubmitButton: false,
  submitButtonText: "Auswahl übernehmen",
  submitButtonFullWidth: false,
  submitButtonDisabled: false,
  minHeight: "auto",
};

Modal.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  buttonText: PropTypes.string,
  showSubmitButton: PropTypes.bool,
  handleSubmitButton: PropTypes.func,
  submitButtonText: PropTypes.string,
  submitButtonFullWidth: PropTypes.bool,
  submitButtonDisabled: PropTypes.bool,
  minHeight: PropTypes.string,
};

export default Modal;
