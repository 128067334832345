import React from "react";
import AddIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/step-header/add-tariff.svg";
import StepHeaderActionBtn from "../StepHeaderActionBtn";

type DisplayConfigurationFlyoutBtnProps = {
  onClick: (value?: unknown) => void;
  customClass?: string;
};

const DisplayConfigurationFlyoutBtn = ({
  customClass = "",
  onClick,
}: DisplayConfigurationFlyoutBtnProps) => (
  <StepHeaderActionBtn
    buttonCustomClass={customClass}
    onClick={onClick}
    icon={AddIcon}
    text="Vergleichen"
  />
);

export default DisplayConfigurationFlyoutBtn;
