import { BaseOffer } from "../core/entities/PencilSelling/BaseOffer/BaseOffer";
import {
  CustomerType,
  DomainType,
} from "../core/entities/PencilSelling/Customer/ICustomer";

export const getCustomDevicesDomainType = (offerStore: BaseOffer) =>
  offerStore.customer.get().customerType !== CustomerType.BUSINESS
    ? DomainType.PRIVATE
    : DomainType.BUSINESS;
