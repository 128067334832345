import { PortfolioKeys } from "../../Product/IProduct";
import {
  Bandwidths,
  BusinessCasesLandline,
  BusinessCasesMobile,
  TariffLevelsKeys,
} from "../../Product/Tariff/ITariff";
import { TariffWorldKeys } from "../../../repositories/ProductsRepository/DefinitionKeys";
import { ExpansionArea } from "../../Applications";
import {
  IBusinessClientsCardSettings,
  ICardSettings,
  ICartConfigurationType,
  ILandlineSettings,
  IMobileSettings,
} from "./IConfigurations";

export const initialLandlineSettings: ILandlineSettings = {
  portfolio: PortfolioKeys.LANDLINE,
  businessCase: BusinessCasesLandline.NEUKUNDE,
  fiber: false,
  bandwidth: Bandwidths.XL,
  isYoung: false,
  isVario: null,
  tariffWorld: TariffWorldKeys.magenta_home_tv,
  level: "magenta_tv_smart",
  expansionArea: ExpansionArea.telekom,
} as const;

export const initalMobileSettings: IMobileSettings = {
  portfolio: PortfolioKeys.MOBILE,
  // TODO possibly could be set up as optional or union with null
  businessCase: BusinessCasesMobile.NEUVERTRAG,
  isYoung: false,
  isVario: null,
  tariffWorld: TariffWorldKeys.magenta_mobile,
  // TODO: Through AdminGUI level key can be arbitrary. Switch from string check to primitive value check
  level: TariffLevelsKeys.PHONE_NONE,
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
};
// TODO check for possible redundancy 11.03.2024
export const initialCardSettings: ICardSettings = {
  tariff: null,
  inheritedDataVolume: null,
  benefitIsActive: true,
};

export const initialBusinessClientsCardSettings: IBusinessClientsCardSettings =
  {
    isYoung: null,
    isVario: false,
    tariffWorld: TariffWorldKeys.business_card_3,
    level: "eco_mobile_level_definition",
    tariff: null,
    inheritedDataVolume: null,
    businessCase: BusinessCasesMobile.NEUVERTRAG,
    portfolio: PortfolioKeys.MOBILE,
    hasProvisionFee: true,
  };

export const initialBusinessClientsMobileSettings: IMobileSettings = {
  portfolio: PortfolioKeys.MOBILE,
  businessCase: BusinessCasesMobile.NEUVERTRAG,
  isYoung: null,
  isVario: false,
  tariffWorld: TariffWorldKeys.business_mobil_3,
  level: TariffLevelsKeys.DEVICE_NONE,
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
};
export const initialBusinessClientsLandlineSettings: ILandlineSettings = {
  portfolio: PortfolioKeys.LANDLINE,
  businessCase: BusinessCasesLandline.NEUKUNDE,
  fiber: false,
  bandwidth: Bandwidths.XL,
  isYoung: null,
  isVario: false,
  tariffWorld: TariffWorldKeys.company_start_landline,
  level: TariffLevelsKeys.STANDART_TARIFF_GK,
  expansionArea: ExpansionArea.telekom,
};

export const configurationsDefaultNameMap = {
  [ICartConfigurationType.MOBILE]: "Mobilfunk Nr.",
  [ICartConfigurationType.CARD]: "Zusatzkarten Nr.",
  [ICartConfigurationType.LANDLINE]: "Festnetz Nr.",
  [ICartConfigurationType.DEVICE]: "Geräte",
};
