import React from "react";
import { CopyText } from "@dtpk-cc/components";

import * as styles from "./radio-button-main.module.scss";
import RadioButtonInput from "./RadioButtonInput";

type RadioButtonMainProps = {
  inputTestId: string;
  title: string;
  onChange: (value) => void;
  checked: boolean;
  value: string | number;
  name: string;
  id: string;
  customClass?: string;
  titleCustomClass?: string;
  disabled?: boolean;
};

const RadioButtonMain = ({
  customClass = "",
  titleCustomClass = "",
  disabled = false,
  inputTestId,
  title,
  onChange,
  checked,
  value,
  name,
  id,
}: RadioButtonMainProps) => (
  <label
    htmlFor={id}
    className={`${disabled ? styles.disabled : ""} ${styles.radioButtonMain} ${
      checked ? styles.active : ""
    } ${customClass}`}
  >
    <CopyText customClass={`${styles.title} ${titleCustomClass}`}>
      {title}
    </CopyText>
    <RadioButtonInput
      customClass={styles.radioMark}
      value={value}
      name={name}
      id={id}
      inputTestId={inputTestId}
      checked={checked}
      onChange={onChange}
    />
  </label>
);

export default RadioButtonMain;
