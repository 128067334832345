import React from "react";
import { TextLink } from "@dtpk-cc/components";
import ContinueOutline from "@dtpk-cc/components/dist/icons/Lpg/ContinueOutline";
import * as stylesTariffConfiguration from "../BaseMobileTariffConfiguration/tariff-configuration.module.scss";
import * as styles from "./tariff-configuration-link.module.scss";

type TariffConfigurationLinkProps = {
  linkValue: string;
  linkTitle: string;
  customClass?: string;
};

const TariffConfigurationLink = ({
  customClass = "",
  linkTitle,
  linkValue,
}: TariffConfigurationLinkProps) => (
  <div className={`${stylesTariffConfiguration.addonsWrapper} ${customClass}`}>
    <TextLink
      customClass={styles.link}
      href={linkValue}
      target="_blank"
      iconRight={ContinueOutline}
    >
      {linkTitle}
    </TextLink>
  </div>
);

export default TariffConfigurationLink;
