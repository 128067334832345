import { Button } from "@dtpk-cc/components";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import {
  GROSS_PRICE_PREFIX,
  NET_PRICE_PREFIX,
} from "core/entities/PencilSelling/Customer/const";
import { Endpoints } from "core/repositories/ProductsRepository/IProductsRepository";
import { trackClick } from "helpers/reactTracking";
import React from "react";
import { useStores } from "stores/BusinessClient";
import * as styles from "../customer-type-selection-modal.module.scss";
import CustomerTypeSelectionModalTileItem from "../CustomerTypeSelectionModalTileItem";

type CustomerTypeSelectionModalTileProps = {
  title: string;
  type: CustomerType;
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  requestUrl: Endpoints;
  fetchData: (getProductsUrl: Endpoints) => void;
};

const CustomerTypeSelectionModalTile: React.FC<
  CustomerTypeSelectionModalTileProps
> = ({ title, Icon, requestUrl, type, fetchData, children }) => {
  const { offerStore } = useStores();

  return (
    <div className={styles.customerTypeTileContainer}>
      {CustomerType.PRIVATE === type ? (
        <a
          data-turbolinks="false"
          tabIndex={0}
          href={`${window.location.origin}/angebote-erstellen/angebotstool`}
          className={`${styles.customerTypeTile} ${styles.item}`}
          rel="noopener noreferrer"
        >
          <CustomerTypeSelectionModalTileItem Icon={Icon} title={title} />
        </a>
      ) : (
        <Button
          key={title}
          tabIndex={0}
          variants={Button.Variant.bare}
          customClass={`${styles.customerTypeTile} ${styles.item}`}
          onClick={() => {
            trackClick(`${title}.auswahl.des.kundentyps`);
            offerStore.getBaseOffer().customer.set({
              ...offerStore.getBaseOffer().customer.get(),
              customerType: type,
              customerPricePrefix:
                type !== CustomerType.BUSINESS
                  ? GROSS_PRICE_PREFIX
                  : NET_PRICE_PREFIX,
            });
            fetchData(requestUrl);
          }}
        >
          <CustomerTypeSelectionModalTileItem Icon={Icon} title={title} />
        </Button>
      )}
      {children}
    </div>
  );
};

export default CustomerTypeSelectionModalTile;
