import React from "react";
import { Heading, HeadingVariants } from "@dtpk-cc/components";

import * as styles from "./tile-container-title.module.scss";

type TileContainerTitleProps = {
  customClass?: string;
  title: string;
  variants?: HeadingVariants;
};

const TileContainerTitle = ({
  title,
  customClass,
  variants = [Heading.Variant.tertiary],
}: TileContainerTitleProps) => (
  <Heading
    tag="h3"
    variants={variants}
    customClass={`${styles.tileContainerTitle} ${customClass}`}
  >
    {title}
  </Heading>
);

export default TileContainerTitle;
