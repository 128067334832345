import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";
import { ICartConfigurationType } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { getConfigurationName } from "../../helpers/GetConfigurationName";

export const useGetActiveConfigurationDefaultName = (
  type: ICartConfigurationType,
  configurationsStore: ConfigurationsEntity
) => {
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const activeConfigurationIndex = configurationsStore
    .getConfigurationsByType(type)
    .findIndex(
      (configuration) =>
        configuration.key === configurationsStore.getActiveConfiguration().key
    );

  return {
    activeConfigurationIndex,
    activeConfigurationName: getConfigurationName(
      activeConfiguration,
      activeConfigurationIndex,
      type
    ),
  };
};
