import React, { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { Heading } from "@dtpk-cc/components";
import { LpgCancelSolid } from "@dtpk-cc/components/dist/icons";
import { AddressItem } from "../../../../views/ServiceChargePrivilege/types";
import { InputAutocomplete, InputAutocompleteItem } from "../InputAutocomplete";
import { fetchAddressesByQuery } from "./utils/fetchAddressesByQuery";
import * as styles from "./address-check-input-wrapper.module.scss";

interface AddressCheckProps {
  onAddressSelect: (address: string) => void;
  selectedAddress: string;
  requestUrl: string;
}

const AddressCheckInputWrapper: React.FC<AddressCheckProps> = ({
  onAddressSelect,
  selectedAddress,
  requestUrl,
  children,
}) => {
  const [inputValue, setInputValue] = useState<{ value: string }>({
    value: "",
  });
  const [suggestedItems, setSuggestedItems] = useState<AddressItem[]>([]);

  const checkAddress: (query: string) => void = useMemo(() => {
    const handler = async (query: string) => {
      try {
        const res = await fetchAddressesByQuery(requestUrl, query);
        const { addresses }: { addresses: Array<{ address: string }> } =
          await res.json();
        setSuggestedItems(addresses.map(({ address }) => ({ value: address })));
      } catch {
        setSuggestedItems([]);
        // error handling TODO
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return debounce(handler, 250);
  }, [requestUrl]);

  const onChange = (input: InputAutocompleteItem) => {
    checkAddress(input.value);
    setInputValue(input);
    if (selectedAddress) {
      onAddressSelect(null);
    }
  };

  const onItemSelect = (item: InputAutocompleteItem) => {
    setInputValue(item);
    onAddressSelect(item.value);
  };

  const onCloseIconClick = () => {
    setInputValue({ value: "" });
    setSuggestedItems([]);
    onAddressSelect(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <Heading tag="h3" variants={[Heading.Variant.secondary]}>
          Adress-Check
        </Heading>
        {children}
        <InputAutocomplete
          value={inputValue}
          items={suggestedItems}
          onChange={onChange}
          onItemSelect={onItemSelect}
          inputProps={
            inputValue.value
              ? {
                  button: {
                    icon: LpgCancelSolid,
                    label: "Cancel",
                    onClick: onCloseIconClick,
                  },
                }
              : {}
          }
        />
      </div>
    </div>
  );
};

export default AddressCheckInputWrapper;
