import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import {
  ICardDetail,
  ICardDetailSection,
} from "core/entities/Product/Card/ICard";
import type { ISetCardSectionItem } from "./ISetCardSectionItem";

export class SetCardSectionItem implements ISetCardSectionItem {
  constructor(private entity: ITariff) {}

  execute(
    sectionKey: keyof ICardDetailSection,
    cardDetails: ICardDetail[]
  ): void {
    this.entity.setCardSectionItem(sectionKey, cardDetails);
  }
}
