import { CardSections } from "core/entities/Product/Card/ICard";
import type { ICard } from "core/entities/Product/Card/ICard";
import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import type { ISetActiveCard } from "./ISetActiveCard";

export class SetActiveCard implements ISetActiveCard {
  constructor(private entity: ITariff, private legacyEntity: ILegacyTariff) {}

  execute(card: ICard, isLegacy?: boolean): void {
    if (!card) return;

    const currentEntity = isLegacy ? this.legacyEntity : this.entity;

    if (!isLegacy && card.tariffWorld.key === TariffWorldKeys.magenta_mobile) {
      currentEntity.getMagentaMobileTariffs(true).forEach(({ key }) => {
        currentEntity.setQuantity(key, 0);
      });
    }

    if (card.tariffWorld.key === CardSections.CUSTOM_CARD) {
      (currentEntity as ILegacyTariff).addCustomCard(card);
    }

    currentEntity.setQuantity(card.key, 1);
  }
}
