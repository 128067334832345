import React from "react";
import { CopyText, Input } from "@dtpk-cc/components";
import * as styles from "../card-category.module.scss";

type CardCategoryItemTitleProps = {
  isNameEditable: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => boolean | void;
  setIsNameEditable: React.Dispatch<React.SetStateAction<boolean>>;
  cardTitle: string;
  cardQuantity?: number;
  defaultName: string;
  isValid?: boolean;
  invalidMessage?: string;
};

const CardCategoryItemTitle = ({
  isNameEditable,
  onChange,
  setIsNameEditable,
  cardTitle,
  cardQuantity,
  defaultName,
  isValid = true,
  invalidMessage = "",
}: CardCategoryItemTitleProps) => (
  <>
    {isNameEditable ? (
      <Input
        invalidMessage={invalidMessage}
        isValid={isValid}
        value={cardTitle}
        onChange={onChange}
        onBlur={() => {
          if (isValid) {
            setIsNameEditable(false);
          }
        }}
      >
        {defaultName}
      </Input>
    ) : (
      <CopyText customClass={`${styles.cardCategory} ${styles.cardName}`}>
        <span className={`${styles.cardCategory} ${styles.cardQuantity}`}>
          {cardQuantity && `${cardQuantity} x`} &nbsp;
        </span>
        {cardTitle || defaultName}
      </CopyText>
    )}
  </>
);

export default CardCategoryItemTitle;
