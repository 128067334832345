import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ISetHasProvisionFee } from "./ISetHasProvisionFee";

export class SetHasProvisionFee implements ISetHasProvisionFee {
  constructor(private readonly entity: ITariff) {}

  execute(hasProvisionFee: boolean): void {
    return this.entity.setHasProvisionFee(hasProvisionFee);
  }
}
