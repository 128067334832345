import React, { useState } from "react";
import { trackClick } from "helpers/reactTracking";
import { Modal as LPGModal, CopyText, Container } from "@dtpk-cc/components";

import Modal from "components/Modal";
import PromotionTile from "elements/PromotionTile";

import "./promotions-modal.scss";

const PromotionsModal = ({
  trackingContext = "",
  isOpen,
  closeModal,
  title,
  product,
  promotions,
  onSave,
  condition,
}) => {
  // TODO Fix: filtering is redundant because 'condition' prop is always null
  const filteredPromotions = condition
    ? promotions.filter((promotion) => promotion.conditions.includes(condition))
    : promotions;

  const [activePromotions, setActivePromotions] = useState(
    product.promotions.map((item) => item.key)
  );

  const productPrice = product.price[product.paymentType];

  const handlePromotionClick = (promotionKey) => {
    if (activePromotions.includes(promotionKey))
      setActivePromotions(
        activePromotions.filter((key) => key !== promotionKey)
      );
    else setActivePromotions([...activePromotions, promotionKey]);
  };

  const handleSubmit = () => {
    const updatedPromotions = filteredPromotions.filter((promotion) =>
      activePromotions.includes(promotion.key)
    );
    onSave(updatedPromotions);
  };

  return (
    <Modal
      active={isOpen}
      showSubmitButton
      close={closeModal}
      handleSubmitButton={() => {
        handleSubmit();
      }}
    >
      <LPGModal.Heading>{title}</LPGModal.Heading>
      <CopyText customClass="modal__copy">
        Für <b>{product.name}</b>
      </CopyText>
      <Container
        customClass={`modal__container ${
          filteredPromotions?.length <= 2 ? "modal__container--center" : ""
        }`}
      >
        <div
          className={`
            promotions-modal
            ${filteredPromotions?.length <= 2 ? "promotions-modal--center" : ""}
          `}
        >
          {filteredPromotions?.map((promotion) => (
            <PromotionTile
              key={promotion.key}
              pricing={promotion.discount}
              name={promotion.description}
              additionalDescription={promotion.additionalDescription}
              active={activePromotions.includes(promotion.key)}
              productPrice={productPrice}
              handleClick={() => {
                handlePromotionClick(promotion.key);
                trackClick(`${promotion.name}`, trackingContext);
              }}
            />
          ))}
        </div>
      </Container>
    </Modal>
  );
};

export default PromotionsModal;
