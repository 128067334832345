import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import type { IGetCardsInCartUseCase } from "./IGetCardsInCart";

export class GetCardsInCartUseCase implements IGetCardsInCartUseCase {
  constructor(private cart: ICart) {}

  execute(): ICartItemData[] {
    return this.cart.getCards();
  }
}
