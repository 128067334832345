import { IGetBenefitsData } from "./IGetBenefitsData";
import {
  IBenefitsData,
  ITariff,
} from "../../../entities/MagentaNext/Tariff/ITariff";

export class GetBenefitsDataUseCase implements IGetBenefitsData {
  constructor(private readonly entity: ITariff) {}

  execute(): IBenefitsData {
    return this.entity.getBenefitsData();
  }
}
