import { CustomerType } from "../../core/entities/PencilSelling/Customer/ICustomer";
import mapDevicesSummary from "../../components/new-design/Summary/helpers/mapDevicesSummary";
import mapMobileSummary from "../../components/new-design/Summary/helpers/mapMobileSummary";
import businessClientMapSecondaryCardsSummary from "../../components/new-design/Summary/helpers/BusinessClient/mapSecondaryCardsSummary";
import privateClientMapSecondaryCardsSummary from "../../components/new-design/Summary/helpers/PrivateClient/mapSecondaryCardsSummary";
import mapLandlineSummary from "../../components/new-design/Summary/helpers/mapLandlineSummary";
import {
  IBannerLinks,
  IDistributionData,
  IOfferSummaryBasePresenterExport,
  OfferSummaryBasePresenter,
} from "../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import {
  IConfigurationCartGroup,
  IConfigurationsSummary,
} from "../../components/new-design/Summary/ISummary";
import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  calculateConfigurationsOnceTotalPrice,
  getConfigurationsTotalMonthlyPrice,
} from "../../components/new-design/Summary/helpers";
import { Offer as PrivateClientOffer } from "../../core/entities/PencilSelling/PrivateClient/Offer/Offer";
import { Offer as BusinessClientOffer } from "../../core/entities/PencilSelling/BusinessClient/Offer/Offer";
import {
  IPrivateClientOfferSummaryPresenterExport,
  PrivateClientOfferSummaryPresenter,
} from "../../core/presenter/PencilSelling/PrivateClient/PrivateClientOfferSummaryPresenter";
import { IOfferExport } from "../../core/entities/PencilSelling/PrivateClient/Offer/IOffer";
import { IBusinessCasesTypeValues } from "../../core/entities/Product/Tariff/ITariff";
import { IConfigurationItemExport } from "../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import { TOTAL_SAVINGS_VALUE_DESCR } from "../../constants/const";
import { formatNumberWithTrimTrailingZeros } from "../../helpers/NumberHelpers";

type UseGetSummaryDataProps = {
  distributionData: IDistributionData;
  bannerLinks: IBannerLinks;
  offerStore: BusinessClientOffer | PrivateClientOffer;
  customerType: CustomerType;
};

export type IGetSummaryData = () => {
  configurations: IConfigurationsSummary;
  configurationsExists: boolean;
  offerPresenterExport:
    | IOfferSummaryBasePresenterExport
    | IPrivateClientOfferSummaryPresenterExport;
  savingsTotalValue: number | null;
};

const businessClientSummaryDataMap = {
  mapDevicesSummary,
  mapMobileSummary,
  mapLandlineSummary,
  mapSecondaryCardsSummary: businessClientMapSecondaryCardsSummary,
};

const summaryDataMap = {
  [CustomerType.PRIVATE]: {
    mapDevicesSummary,
    mapMobileSummary,
    mapLandlineSummary,
    mapSecondaryCardsSummary: ({
      offerExportConfigurations,
      configurationsSummary,
      priceType,
      businessCase,
    }: {
      offerExportConfigurations: ICartConfiguration[];
      configurationsSummary: IConfigurationsSummary;
      priceType: string;
      businessCase: IBusinessCasesTypeValues;
    }) =>
      privateClientMapSecondaryCardsSummary({
        offerExportConfigurations,
        configurationsSummary,
        priceType,
        businessCase,
      }),
  },
  [CustomerType.BUSINESS]: businessClientSummaryDataMap,
  [CustomerType.EMPLOYEE]: businessClientSummaryDataMap,
};

export const useGetSummaryData = ({
  distributionData,
  bannerLinks,
  offerStore,
  customerType,
}: UseGetSummaryDataProps) => {
  const getSummaryData: IGetSummaryData = () => {
    const offerExport = offerStore.export();
    const { customerPricePrefix } = offerStore.getBaseOffer().customer.get();
    const secondaryCardsBusinessCase = offerStore
      .getBaseOffer()
      .getBusinessCase(ICartConfigurationType.CARD);
    const offerSummaryPresenter =
      customerType !== CustomerType.PRIVATE
        ? new OfferSummaryBasePresenter(
            bannerLinks,
            distributionData,
            offerExport.details,
            offerExport.customerNote,
            offerExport.displayConfigurationTotalPrice,
            offerExport.displayOfferTotalPrice
          )
        : new PrivateClientOfferSummaryPresenter(
            offerExport as IOfferExport,
            bannerLinks,
            distributionData
          );
    let savingsTotalValue: number | null = null;
    const offerPresenterExport = offerSummaryPresenter.export();
    const configurations: IConfigurationsSummary = {
      [ICartConfigurationType.DEVICE]: {
        pdfTitle: null,
        configurationItems: [],
      },
      [ICartConfigurationType.CARD]: {
        pdfTitle: "Zusatzkarten",
        configurationItems: [],
      },
      [ICartConfigurationType.LANDLINE]: {
        pdfTitle: "Festnetz",
        configurationItems: [],
      },
      [ICartConfigurationType.MOBILE]: {
        pdfTitle: "Mobilfunk",
        configurationItems: [],
      },
      totalPrice: null,
    };

    const deviceConfigurations =
      offerExport.cartSummary[ICartConfigurationType.DEVICE];
    const mobileConfigurations =
      offerExport.cartSummary[ICartConfigurationType.MOBILE];
    const cardsConfigurations =
      offerExport.cartSummary[ICartConfigurationType.CARD];
    const landlineConfigurations =
      offerExport.cartSummary[ICartConfigurationType.LANDLINE];

    if (deviceConfigurations.length) {
      summaryDataMap[customerType].mapDevicesSummary({
        offerExportConfigurations: deviceConfigurations,
        configurationsSummary: configurations,
        priceType: customerPricePrefix,
      });
    }

    if (mobileConfigurations.length) {
      summaryDataMap[customerType].mapMobileSummary({
        offerExportConfigurations: mobileConfigurations,
        configurationsSummary: configurations,
        priceType: customerPricePrefix,
      });
    }

    if (cardsConfigurations.length) {
      summaryDataMap[customerType].mapSecondaryCardsSummary({
        offerExportConfigurations: cardsConfigurations,
        configurationsSummary: configurations,
        priceType: customerPricePrefix,
        businessCase: secondaryCardsBusinessCase,
      });
    }

    if (landlineConfigurations.length) {
      summaryDataMap[customerType].mapLandlineSummary({
        offerExportConfigurations: landlineConfigurations,
        configurationsSummary: configurations,
        priceType: customerPricePrefix,
      });
    }

    const allSummaryConfigurations = [
      ...configurations[ICartConfigurationType.MOBILE].configurationItems,
      ...configurations[ICartConfigurationType.CARD].configurationItems,
      ...configurations[ICartConfigurationType.LANDLINE].configurationItems,
      ...configurations[ICartConfigurationType.DEVICE].configurationItems,
    ];

    if (allSummaryConfigurations.length) {
      configurations.totalPrice = {
        priceType: customerPricePrefix,
        title: "Gesamtsumme",
        monthlyPrice: getConfigurationsTotalMonthlyPrice(
          allSummaryConfigurations
        ),
        oncePrice: calculateConfigurationsOnceTotalPrice(
          allSummaryConfigurations
        ),
        totalOncePriceDeductions: null,
        savingsTotalValueDescr: null,
      };
      const allSummaryConfigurationsSections = allSummaryConfigurations.reduce(
        (acc, configurationItem) => [...acc, ...configurationItem.sections],
        [] as IConfigurationCartGroup[]
      );
      const allSummaryConfigurationsSectionsItems =
        allSummaryConfigurationsSections.reduce(
          (acc, section) => [...acc, ...section.items],
          [] as IConfigurationItemExport[]
        );

      savingsTotalValue = allSummaryConfigurationsSectionsItems.reduce(
        (acc, configurationExport) =>
          acc + configurationExport.additionalRenderData.savingsTotalValue,
        0
      );

      if (savingsTotalValue) {
        configurations.totalPrice.savingsTotalValueDescr = `${TOTAL_SAVINGS_VALUE_DESCR} ${formatNumberWithTrimTrailingZeros(
          savingsTotalValue
        )} €`;
      }

      configurations.totalPrice.totalOncePriceDeductions =
        allSummaryConfigurations
          .map(
            (configurationItem) =>
              configurationItem.totalPrice.totalOncePriceDeductions
          )
          .flat();
    }

    return {
      configurations,
      savingsTotalValue,
      configurationsExists: !!allSummaryConfigurations.length,
      offerPresenterExport,
    };
  };

  return {
    getSummaryData,
  };
};
