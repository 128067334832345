import { IServicesEntity } from "core/entities/PencilSelling/Services/IServicesEntity";
import { IGetStreamingServicesPriceUseCase } from "./IGetStreamingServicesPrice";

export class GetStreamingServicesPriceUseCase
  // eslint-disable-next-line prettier/prettier
  implements IGetStreamingServicesPriceUseCase {
  constructor(private readonly entity: IServicesEntity) {}

  execute(): number {
    return this.entity.getStreamingServicesPrice();
  }
}
