import { IConsultingTime } from "../IConsultingTime";

export interface ICustomer {
  consultingTime: IConsultingTime;
  notice: string;
  customerType: CustomerType;
  customerPricePrefix: string;
  domainType: DomainType;
}

export interface ICustomerEntity {
  get(): ICustomer;
  set(customer: ICustomer): void;
  reset(): void;
}

export enum CustomerType {
  EMPLOYEE = "employee",
  BUSINESS = "business",
  PRIVATE = "private",
}

export enum DomainType {
  PRIVATE = "personal",
  BUSINESS = "business",
  EMPLOYEE = "employee",
}