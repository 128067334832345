import React, { useState } from "react";
import { observer } from "mobx-react";
import { CopyText } from "@dtpk-cc/components";
import TileContainer from "components/new-design/TileContainer";
import DropdownMain from "elements/new-design/DropdownMain";
import { useStores } from "stores/BusinessClient";
import TileContainerTitle from "components/new-design/TileContainer/TileContainerTitle";
import { trackClick } from "helpers/reactTracking";
import { IFrameworkContractSelectState } from "../../../IPencilSelling";
import FrameworkContractModal from "./FrameworkContractModal";
import NotifyHintBox from "../../../../../elements/new-design/NotifyHintBox";
import { useGetProductsWithFrameworkContract } from "../../../../../hooks/PencilSelling/BusinessClient/useGetProductsWithFrameworkContract";
import * as styles from "./framework-contract.module.scss";

type FrameworkContractProps = {
  customClass?: string;
  trackingContext: string;
  frameworkContractSelectState: IFrameworkContractSelectState;
  setFrameworkContractSelectState: React.Dispatch<
    React.SetStateAction<IFrameworkContractSelectState>
  >;
};

const FrameworkContract = ({
  customClass = "",
  frameworkContractSelectState,
  setFrameworkContractSelectState,
  trackingContext,
}: FrameworkContractProps) => {
  const { offerStore, configurationsStore } = useStores();
  const { setIsLoading } = offerStore.getBaseOffer();
  const { getProductsWithFrameworkContract } =
    useGetProductsWithFrameworkContract();
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const currentFrameworkContract = offerStore.getFrameworkContract();

  const frameworkContractSelectHandler = async (
    frameworkContractName: string
  ) => {
    setIsLoading(true);
    const targetFrameworkContract = offerStore
      .getFrameworkContractsList()
      .find(
        (frameworkContract) => frameworkContract.name === frameworkContractName
      );
    try {
      await getProductsWithFrameworkContract(
        targetFrameworkContract,
        setFrameworkContractSelectState
      );
      configurationsStore.reset();
      offerStore.resetBusinessGen3CardsData();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }

    trackClick("rahmenvertragsauswahl", trackingContext);
  };

  const frameworkContractsNameList = offerStore
    .getFrameworkContractsList()
    .map((frameworkContract) => frameworkContract.name);

  return (
    <div className={customClass}>
      <TileContainer
        customClass={`${styles.frameworkContract} ${
          frameworkContractSelectState.displayErrorMsg ? styles.withError : ""
        }`}
      >
        <div className={styles.titleWrapper}>
          <TileContainerTitle
            customClass={styles.title}
            title="Rahmenvertrag"
          />
          {/* TODO Temporary hidden */}
          {/* <Button */}
          {/*  data-testid="framework-contract-info" */}
          {/*  customClass={styles.titleInfoBtn} */}
          {/*  tabIndex={0} */}
          {/*  variants={Button.Variant.bare} */}
          {/*  onClick={() => { */}
          {/*    setInfoModalIsOpen(true); */}
          {/*    trackClick("rahmenvertragsauswahl.info", trackingContext); */}
          {/*  }} */}
          {/* > */}
          {/*  <Icon icon={AlertInformationDefault} size={Icon.Size.xsmall} /> */}
          {/*  <A11yText>Informationen zum Rahmenvertrag</A11yText> */}
          {/* </Button> */}
        </div>
        <DropdownMain
          label="Rahmenvertragsnummer*"
          items={frameworkContractsNameList}
          onSelection={frameworkContractSelectHandler}
          value={currentFrameworkContract?.name}
        />
        {frameworkContractSelectState.displayErrorMsg ? (
          <CopyText customClass={styles.errorMsg}>
            Achtung: Der Rahmenvertrag muss ausgewählt werden, um fortzufahren!
          </CopyText>
        ) : (
          <CopyText customClass="m-t-24">
            <b className="m-r-6">Ändern des Rahmenvertrags:</b>Nachträgliche
            Änderung des Rahmenvertrags entfernt alle bereits ausgewählten
            Produkte und Konfigurationen.
          </CopyText>
        )}
        <FrameworkContractModal
          onClose={() => setInfoModalIsOpen(false)}
          isOpen={infoModalIsOpen}
          containerCustomClass={styles.infoModalWrapper}
        />
      </TileContainer>
      {!!currentFrameworkContract?.conditions && (
        <NotifyHintBox>
          <CopyText
            customClass={styles.conditionText}
            dangerouslySetInnerHTML={{
              __html: currentFrameworkContract.conditions,
            }}
          />
        </NotifyHintBox>
      )}
    </div>
  );
};

export default observer(FrameworkContract);
