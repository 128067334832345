import React, { useEffect, useState } from "react";

import "./loading-spinner.scss";

const LoadingSpinner = ({ children, isLoading }) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <>
      {children}

      {isLoading && (
        <div
          className={`
            loading
            ${isRendered ? "loading--rendered" : ""}
          `}
          data-testid="loading-spinner"
        >
          <div className="loading__spinner-container">
            <div className="loading__spinner" />
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
