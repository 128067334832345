import React, { useState } from "react";
import {
  Tile,
  RadioButton,
  Button,
  CopyText,
  Icon,
  Input,
  Container,
} from "@dtpk-cc/components";
import { LpgExpandOutline } from "@dtpk-cc/components/dist/icons";
import { ILevel } from "core/entities/Product/IProduct";
import { ISettings } from "core/entities/MagentaNext/Tariff/ITariff";
import * as styles from "./tariff-settings.module.scss";
import { trackClick } from "../../../helpers/reactTracking";

const LEVEL_NAME_KEY_MAP = {
  phone_none: "ohne Smartphone",
  phone_default: "Smartphone",
  phone_top: "Top-Smartphone",
  phone_premium: "Premium-Smartphone",
  phone_premium_plus: "Premium-Plus-Smartphone",
};

type TariffSettingsProps = {
  levels: ILevel[];
  onChange: (key: keyof ISettings, value: boolean | number | string) => void;
  trackingContext: string;
  settings: ISettings;
};

const TariffSettings = ({
  levels,
  onChange,
  trackingContext,
  settings,
}: TariffSettingsProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const isExpandedToggle = () => setIsExpanded((prev) => !prev);

  return (
    <Tile
      variant="shadow"
      customClass={`${styles.tariffSettings} ${styles.tile}`}
    >
      <Tile.Content>
        <Button
          variants={[Button.Variant.bare, Button.Variant.icon]}
          customClass={`${styles.tariffSettings} ${styles.toggleBtn} ${
            isExpanded ? styles.expanded : ""
          }`}
          onClick={isExpandedToggle}
        >
          <CopyText customClass={`${styles.tariffSettings} ${styles.title}`}>
            Tarifkonfiguration
          </CopyText>
          <Icon icon={LpgExpandOutline} />
        </Button>

        <div
          className={`${styles.tariffSettings} ${styles.content} ${
            isExpanded ? styles.expanded : ""
          }`}
        >
          <RadioButton.Group
            customClass={`${styles.tariffSettings} ${styles.radioButtons}`}
          >
            {levels.map((level) => (
              <RadioButton
                customClass={`${styles.tariffSettings} ${styles.item} ${
                  settings.level === level.key ? styles.active : ""
                }`}
                onChange={(event) => {
                  onChange("level", event.target.value);
                }}
                checked={settings.level === level.key}
                key={level.name}
                value={level.key}
                onClick={() =>
                  trackClick("magentaeins-vorteile", trackingContext)
                }
              >
                <Tile variant="shadow">{LEVEL_NAME_KEY_MAP[level.key]}</Tile>
              </RadioButton>
            ))}
          </RadioButton.Group>
          <Container
            customClass={`${styles.tariffSettings} ${styles.smartphone}`}
          >
            <Input
              value={settings.smartphoneName}
              id={`phone-name-${settings.level}`}
              customClass={`${styles.tariffSettings} ${styles.smartphoneName}`}
              onChange={(event) => {
                onChange("smartphoneName", event.target.value);
              }}
              onClick={() => trackClick("smartphone")}
              type="text"
            >
              Smartphone
            </Input>
            <Input
              value={String(settings.smartphonePrice ?? "")}
              id={`phone-price-${settings.level}`}
              customClass={`${styles.tariffSettings} ${styles.smartphonePrice}`}
              onChange={(event) => {
                onChange("smartphonePrice", event.target.value);
              }}
              onClick={() => trackClick("kaufpreis")}
              type="number"
            >
              Kaufpreis
            </Input>
          </Container>
        </div>
      </Tile.Content>
    </Tile>
  );
};
export default TariffSettings;
