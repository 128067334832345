import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetPromotionsTotalPrice } from "./IGetPromotionsTotalPrice";

export class GetPromotionsTotalPrice implements IGetPromotionsTotalPrice {
  constructor(private readonly entity: ITariff) {}

  execute(): number {
    return this.entity.getPromotionsTotalPrice();
  }
}
