import { useCallback, useState } from "react";
import {
  INavigationItemClickHandlerParams,
  INavigationMap,
} from "../../../components/new-design/Navigation/INavigation";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { useGetDefaultConfigurationSettings } from "../useGetDefaultConfigurationSettings";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { trackPencilSellingPage } from "../../../helpers/reactTracking";

export const useNavigationMenuMap = (
  configurationsEntity: ConfigurationsEntity,
  offerStore: BaseOffer
) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { getDefaultConfigurationSettings } =
    useGetDefaultConfigurationSettings(offerStore);

  const defineActiveConfiguration = useCallback(
    (type: ICartConfigurationType) => {
      const configurationsList =
        configurationsEntity.getConfigurationsByType(type);
      //  If none configuration from current list is active
      if (
        configurationsList.length &&
        configurationsEntity.getActiveConfiguration()?.type !== type
      ) {
        configurationsEntity.setActiveConfiguration(
          configurationsList[configurationsList.length - 1]
        );
      }
    },
    [configurationsEntity]
  );

  const addConfigurationHandler = useCallback(
    (type: ICartConfigurationType) => {
      const configurationsAreExist =
        !!configurationsEntity.getConfigurationsByType(type).length;

      if (!configurationsAreExist) {
        configurationsEntity.addConfiguration(
          getDefaultConfigurationSettings(type)
        );
      }
      //  If none configuration from current list is active
      defineActiveConfiguration(type);
    },
    [
      configurationsEntity,
      getDefaultConfigurationSettings,
      defineActiveConfiguration,
    ]
  );

  const navigateToStepWithoutFlyout = ({
    setActiveItem,
    setActiveFlyoutKey,
    setBackDropIsActive,
    stepIndex,
    key,
  }: Partial<INavigationItemClickHandlerParams> & { stepIndex: number }) => {
    setActiveItem(key);
    setActiveFlyoutKey(null);
    setBackDropIsActive(false);
    setActiveStepIndex(stepIndex);
    trackPencilSellingPage(stepIndex + 1);
  };

  const navigateToStepWithFlyout = ({
    stepIndex,
    key,
    setActiveItem,
    setActiveFlyoutKey,
    setBackDropIsActive,
    activeFlyoutKey,
    displayFlyout = true,
  }: INavigationItemClickHandlerParams & { stepIndex: number }): void => {
    setActiveStepIndex(stepIndex);
    setActiveItem(key);
    if (displayFlyout) {
      const flyoutData =
        key !== activeFlyoutKey
          ? { backDropIsActive: true, activeFlyoutKey: key }
          : { backDropIsActive: false, activeFlyoutKey: null };
      setBackDropIsActive(flyoutData.backDropIsActive);
      setActiveFlyoutKey(flyoutData.activeFlyoutKey);
    } else {
      setBackDropIsActive(false);
      setActiveFlyoutKey(null);
    }
    trackPencilSellingPage(stepIndex + 1);
  };

  const navigationMenuMap: INavigationMap = {
    menu: {
      title: "menü",
      items: [],
    },
  };

  return {
    navigationMenuMap,
    activeStepIndex,
    setActiveStepIndex,
    addConfigurationHandler,
    navigateToStepWithFlyout,
    navigateToStepWithoutFlyout,
    getDefaultConfigurationSettings,
    defineActiveConfiguration,
  };
};
