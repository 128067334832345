import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetTariffTotalQuantity } from "./IGetTariffTotalQuantity";

export class GetTariffTotalQuantity implements IGetTariffTotalQuantity {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(isLegacy?: boolean): number {
    return (isLegacy ? this.legacyEntity : this.entity).getTotalQuantity();
  }
}
