import { FALLBACK_APP_LINK } from "../../../constants/const";
import { DateTimeHelper } from "../../../helpers/DateTimeHelper";
import { ICustomer } from "../../entities/PencilSelling/Customer/ICustomer";

export interface IDetailNote {
  headline?: string;
  text: string;
}

export interface IDistributionPoint {
  name: string;
  pls: string;
  adress: string;
  location: string;
}

export interface IDistributionData {
  distributionLink: string;
  distributionPoint: IDistributionPoint | null;
  showDistributionData?: boolean;
}

export interface IBannerLinks {
  magentaAppLink: string;
  googleReviewLink: string;
  onlineAppointmentBookingLink: string;
}

export interface IOfferSummaryBasePresenterDetails {
  consultingTime: ICustomer["consultingTime"];
  validUntil: Date;
}

export interface IOfferSummaryBasePresenterExport {
  details: IDetailNote[];
  distributionData: IDistributionData;
  bannerLinks: IBannerLinks;
  displayConfigurationTotalPrice: boolean;
  displayOfferTotalPrice: boolean;
}

export class OfferSummaryBasePresenter {
  defaultDistributionPoint = {
    name: "",
    pls: "",
    adress: "",
    location: "",
  };

  constructor(
    protected bannerLinks: IBannerLinks,
    protected distributionData: IDistributionData,
    protected offerExportDetails: IOfferSummaryBasePresenterDetails,
    protected customerNote: string,
    protected displayConfigurationTotalPrice: boolean,
    protected displayOfferTotalPrice: boolean
  ) {
    this.offerExportDetails = offerExportDetails;
    this.distributionData = {
      showDistributionData: distributionData.showDistributionData,
      distributionPoint:
        distributionData.distributionPoint ?? this.defaultDistributionPoint,
      distributionLink: distributionData.distributionLink,
    };
    this.bannerLinks = {
      magentaAppLink: bannerLinks.magentaAppLink
        ? bannerLinks.magentaAppLink
        : FALLBACK_APP_LINK,
      googleReviewLink: bannerLinks.googleReviewLink || "",
      onlineAppointmentBookingLink:
        bannerLinks.onlineAppointmentBookingLink || "",
    };
    this.displayConfigurationTotalPrice = displayConfigurationTotalPrice;
    this.displayOfferTotalPrice = displayOfferTotalPrice;
  }

  protected getCustomerNote(): IDetailNote {
    return {
      headline: "Ihre Wünsche",
      text: this.customerNote,
    };
  }

  protected static getAgbNote(): IDetailNote {
    return {
      headline: "Hinweis",
      text: "<b>Änderungen und Irrtum vorbehalten.</b> Die Empfehlung ist freibleibend und basiert auf den geltenden Allgemeinen Geschäftsbedingungen von Telekom Deutschland GmbH für das jeweilige Produkt. Die Allgemeinen Geschäftsbedingungen sind in den Telekom Shops, bei den Telekom Partnern oder im Internet unter www.telekom.de/agb erhältlich.",
    };
  }

  protected getValidUntilText(): string | undefined {
    return (
      this.offerExportDetails.validUntil &&
      `Angebot gültig bis: ${DateTimeHelper.currentDisplayDate(
        this.offerExportDetails.validUntil
      )}`
    );
  }

  protected getConsultingTime(): string | undefined {
    const isDate =
      typeof this.offerExportDetails.consultingTime.nextAppointmentDate
        ?.getMonth === "function";
    const consultingTimeText = `
    ${
      isDate
        ? DateTimeHelper.currentDisplayDate(
            this.offerExportDetails.consultingTime.nextAppointmentDate
          )
        : ""
    } ${
      DateTimeHelper.isTimeValid(
        this.offerExportDetails.consultingTime.nextAppointmentTime
      )
        ? `um ${this.offerExportDetails.consultingTime.nextAppointmentTime} Uhr`
        : ""
    }`.trim();

    return consultingTimeText
      ? `Nächster Beratungstermin: ${consultingTimeText}`
      : undefined;
  }

  protected get offerDates() {
    return {
      validUntil: this.getValidUntilText(),
      consultingTime: this.getConsultingTime(),
    };
  }

  protected getOfferDetailText() {
    return Object.values(this.offerDates).reduce((acc, item) => {
      const lineBefore = acc ? `${acc}<br />` : "";
      return item ? `${lineBefore}${item}` : acc;
    }, "");
  }

  protected getDetails(): IDetailNote[] {
    const notes = [] as IDetailNote[];
    const dateText = this.getOfferDetailText();
    const customerNote = this.getCustomerNote();
    const getAgbNote = OfferSummaryBasePresenter.getAgbNote();

    if (dateText) notes.push({ text: dateText });
    if (customerNote.text) notes.push(customerNote);
    if (getAgbNote) notes.push(getAgbNote);

    return notes;
  }

  export(): IOfferSummaryBasePresenterExport {
    return {
      distributionData: this.distributionData,
      bannerLinks: this.bannerLinks,
      details: this.getDetails(),
      displayConfigurationTotalPrice: this.displayConfigurationTotalPrice,
      displayOfferTotalPrice: this.displayOfferTotalPrice,
    };
  }
}
