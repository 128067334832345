import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetSavingsTotalPrice } from "./IGetSavingsTotalPrice";

export class GetSavingsTotalPriceUseCase implements IGetSavingsTotalPrice {
  constructor(private readonly entity: ITariff) {}

  execute(): number {
    return this.entity.getSavingsTotalPrice();
  }
}
