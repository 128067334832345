import { lottieJSON1floor60 } from "./Lottie-Exports/60sqm–1floor/60sqm–1floor";
import { lottieJSON2floor60 } from "./Lottie-Exports/60sqm–2floor/60sqm–2floor";

import { lottieJSON1floor61_100 } from "./Lottie-Exports/61-100sqm–1floor/61-100sqm–1floor";
import { lottieJSON2floor61_100 } from "./Lottie-Exports/61-100sqm–2floor/61-100sqm–2floor";
import { lottieJSON3floor61_100 } from "./Lottie-Exports/61-100sqm–3floor/61-100sqm–3floor";

import { lottieJSON1floor101_200 } from "./Lottie-Exports/101-200sqm–1floor/101-200sqm–1floor";
import { lottieJSON2floor101_200 } from "./Lottie-Exports/101-200sqm–2floor/101-200sqm–2floor";
import { lottieJSON3floor101_200 } from "./Lottie-Exports/101-200sqm–3floor/101-200sqm–3floor";
import { lottieJSON4floor101_200 } from "./Lottie-Exports/101-200sqm–4floor/101-200sqm–4floor";

import { lottieJSON1floor201 } from "./Lottie-Exports/201+sqm–1floor/201+sqm–1floor";
import { lottieJSON3floor201 } from "./Lottie-Exports/201+sqm–3floor/201+sqm–3floor";
import { lottieJSON4floor201 } from "./Lottie-Exports/201+sqm–4floor/201+sqm–4floor";

import { lottieJSON1floor300 } from "./Lottie-Exports/300+sqm–1floor/300+sqm–1floor";
import { lottieJSON4floor300 } from "./Lottie-Exports/300+sqm–4floor/300+sqm–4floor";

import { lottieJSON2floor400 } from "./Lottie-Exports/400+sqm–2floor/400+sqm–2floor";

import NeedRouterImg from "../../../../assets/images/home-configurator/speedport-smart-4-boxed.png";
import SmartRouterImg from "../../../../assets/images/home-configurator/speedport-smart-4.png";
import ProPlusRouterImg from "../../../../assets/images/home-configurator/speedport-pro-plus.png";
import OtherRouterImg from "../../../../assets/images/home-configurator/router-anderen.png";

import {
  FloorVariantKeys,
  IConfiguratorParamsMap,
  ConfigParamsKeys,
  IArraySegment,
  RouterType,
  IFloorItem,
} from "../../../core/entities/PencilSelling/HomeConfigurator/IHomeConfigurator";

export type ServiceDataItemModalData = {
  title: string;
  content: {
    mainText: string;
    list: {
      title: string;
      items: string[];
    } | null;
  };
};

export type ServiceDataItem = {
  name: string;
  modalContent: ServiceDataItemModalData;
};

export type ServiceCasesItem = {
  serviceList: ServiceDataItem[];
};

export type RecommendationProductItem = {
  productKey: string;
  productAmount: number;
};

export type RecommendationTypeItem = {
  products: RecommendationProductItem[];
  serviceCase: ServiceCasesItem | null;
  note: string | null;
};

export const REPEATER_DEVICE_NAME = "Speed Home WLAN";

export const ROUTER_TITLE_NEED_ONE = "Ich brauche einen neuen";
export const ROUTER_TITLE_SMART4 = "Speedport Smart 4";
export const ROUTER_TITLE_PRO_PLUS = "Speedport Pro Plus";
export const ROUTER_TITLE_OTHER = "Anderen Router";

export const SMART4_ROUTER_DEVICE_KEY = "speedport_smart_4";
export const REPEATER_DEVICE_KEY = "speed_home_wlan";

export const configParamsMap: IConfiguratorParamsMap = {
  [ConfigParamsKeys.LESS_THAN_60]: {
    [FloorVariantKeys.FLOOR1]: {
      lottieFile: lottieJSON1floor60,
      baseRepeatersAmount: 0,
    },
    [FloorVariantKeys.FLOOR2]: {
      lottieFile: lottieJSON2floor60,
      baseRepeatersAmount: 1,
    },
    [FloorVariantKeys.FLOOR3]: {
      lottieFile: lottieJSON3floor61_100,
      baseRepeatersAmount: 2,
    },
    [FloorVariantKeys.FLOOR4]: {
      lottieFile: lottieJSON4floor101_200,
      baseRepeatersAmount: 3,
    },
  },
  [ConfigParamsKeys.MORE_THAN_60]: {
    [FloorVariantKeys.FLOOR1]: {
      lottieFile: lottieJSON1floor61_100,
      baseRepeatersAmount: 1,
    },
    [FloorVariantKeys.FLOOR2]: {
      lottieFile: lottieJSON2floor61_100,
      baseRepeatersAmount: 2,
    },
    [FloorVariantKeys.FLOOR3]: {
      lottieFile: lottieJSON3floor101_200,
      baseRepeatersAmount: 3,
    },
    [FloorVariantKeys.FLOOR4]: {
      lottieFile: lottieJSON4floor201,
      baseRepeatersAmount: 4,
    },
  },
  [ConfigParamsKeys.MORE_THAN_100]: {
    [FloorVariantKeys.FLOOR1]: {
      lottieFile: lottieJSON1floor101_200,
      baseRepeatersAmount: 2,
    },
    [FloorVariantKeys.FLOOR2]: {
      lottieFile: lottieJSON2floor101_200,
      baseRepeatersAmount: 3,
    },
    [FloorVariantKeys.FLOOR3]: {
      lottieFile: lottieJSON3floor201,
      baseRepeatersAmount: 4,
    },
    [FloorVariantKeys.FLOOR4]: {
      lottieFile: lottieJSON4floor300,
      baseRepeatersAmount: 5,
    },
  },
  // Edge-cases
  [ConfigParamsKeys.MORE_THAN_200]: {
    [FloorVariantKeys.FLOOR1]: {
      lottieFile: lottieJSON1floor201,
      baseRepeatersAmount: 3,
    },
    [FloorVariantKeys.FLOOR2]: {
      lottieFile: lottieJSON2floor400,
      baseRepeatersAmount: 4,
    },
  },
  [ConfigParamsKeys.MORE_THAN_300]: {
    [FloorVariantKeys.FLOOR1]: {
      lottieFile: lottieJSON1floor300,
      baseRepeatersAmount: 4,
    },
  },
};

export const floorsArr: IFloorItem[] = [
  {
    key: FloorVariantKeys.FLOOR1,
    value: 1,
    disabled: false,
  },
  {
    key: FloorVariantKeys.FLOOR2,
    value: 2,
    disabled: false,
  },
  {
    key: FloorVariantKeys.FLOOR3,
    value: 3,
    // Disabled on component mount
    disabled: true,
  },
  {
    key: FloorVariantKeys.FLOOR4,
    value: 4,
    // Disabled on component mount
    disabled: true,
  },
];

export const arrayOfRouterSegments: IArraySegment[] = [
  {
    key: RouterType.NEED_ROUTER,
    value: ROUTER_TITLE_NEED_ONE,
    segment: [0, 163],
    src: NeedRouterImg as string,
  },
  {
    key: RouterType.SMART4,
    value: ROUTER_TITLE_SMART4,
    segment: [0, 163],
    src: SmartRouterImg as string,
  },
  {
    key: RouterType.PRO_PLUS,
    value: ROUTER_TITLE_PRO_PLUS,
    segment: [164, 327],
    src: ProPlusRouterImg as string,
  },
  {
    key: RouterType.OTHER,
    value: ROUTER_TITLE_OTHER,
    segment: [328, 491],
    src: OtherRouterImg as string,
  },
];

export const dialogBoxContent = {
  invalidTypeOfRouter: {
    title: "Wir empfehlen einen Routerwechsel",
    mainText: `
    Um Ihr Zuhause flächendeckend mit WLAN auszustatten empfehlen wir den 
    Wechsel auf einen Speeport Smart 4. Möchten Sie wechseln?.
    `,
    submitBtnText: "Router wechseln",
    cancelBtnText: "",
  },
};

const SERVICES_DATA = {
  meinMagenta: {
    name: "MeinMagenta App",
    modalContent: {
      title: "MeinMagenta App",
      content: {
        mainText: `
        Die MeinMagenta App unterstützt Sie bei der 
        Einrichtung des Speed Home WLAN/Speedport 
        und der Optimierung Ihres WLAN-Signals.
        `,
        list: null,
      },
    },
  },
  magentaZuhause: {
    name: "MagentaZuhause App Pro",
    modalContent: {
      title: "MagentaZuhause App Pro",
      content: {
        mainText: `
        Nutzen Sie das gesamten SmartHome Angebot 
        an kompatiblen Geräten und erhalten 
        Sie schnelle und professionelle 
        Unterstützung mit telefonischem Support.
        `,
        list: null,
      },
    },
  },
  // TODO temporary hidden
  // vorOrtService: {
  //   name: "Vor-Ort-Service",
  //   modalContent: {
  //     title: "Vor-Ort-Service",
  //     content: {
  //       mainText: `
  //       Im Rahmen einer telefonischen Expertenberatung,
  //       kann unser Team Sie vor Ort unterstützen.
  //       Die Installation der Hardware erfolgt
  //       kostenlos durch Telekom Techniker.
  //       Die Beratung erfolgt aktiv telefonisch/per
  //       sicherem Fernzugriff und falls notwendig einmalig vor Ort.
  //       `,
  //       list: null,
  //     },
  //   },
  // },
  heimnetzService: {
    name: "Heimnetz-Service",
    modalContent: {
      title: "Heimnetz-Service",
      content: {
        mainText: null,
        list: {
          title: "<b>Lösung aller Kundenanliegen zum Heimnetz</b>, z. B. bei",
          items: [
            "sich verschlechternder WLAN-Performance",
            "neuen Endgeräten",
            "Umbaumaßnahmen",
            // TODO temporary hidden
            // "Individuelle Lösungsermittlung",
            // "Identifizierung von Störquellen",
            // "Optimale Platzierung der Geräte",
            // "Geräteempfehlung zur Heimnetz-Verstärkung",
          ],
        },
      },
    },
  },
  awu: {
    name: "Anwendungsunterstützung",
    modalContent: {
      title: "Anwendungsunterstützung",
      content: {
        mainText: `
        Erhalten Sie professionellen Service im 
        gesamten digitalen Zuhause. 
        Wir beraten Sie telefonisch/per sicherem 
        Fernzugriff und falls notwendig einmal pro Jahr vor Ort.
        `,
        list: {
          title: "Wir helfen bei Fragen rundum:",
          items: [
            "PC/Tablet/Smartphone und weiter Geräte",
            "Home Office inkl. Videokonferenzlösungen (z.B. Zoom)",
            "Betriebssysteme",
            "Sicherheitssoftware, Anwender-Software",
            "Magenta TV und SmartHome",
          ],
        },
      },
    },
  },
};

const SERVICE_CASES = {
  // # 1
  routerCase: {
    serviceList: [SERVICES_DATA.meinMagenta, SERVICES_DATA.magentaZuhause],
  },
  // # 2
  wlanComfortCase: {
    serviceList: [...Object.values(SERVICES_DATA)],
  },
  // # 3
  wlanPaketCase: {
    serviceList: [SERVICES_DATA.meinMagenta, SERVICES_DATA.heimnetzService],
  },
};

export const recommendationRentProductsMap = {
  repeaterAmount_0: {
    needRouter: {
      products: [{ productKey: SMART4_ROUTER_DEVICE_KEY, productAmount: 1 }],
      serviceCase: SERVICE_CASES.routerCase,
      note: `
          Bei dieser Größe reicht das WLAN Signal des Routers. 
          Bei verwinkelten Wohnbereichen empfehlen wir jedoch, 
          zusätzlich einen Verstärker einzusetzen.
        `,
    },
    // Submit button is disabled
    haveRouter: {
      products: [],
      serviceCase: null,
      note: null,
    },
  },
  repeaterAmount_1: {
    needRouter: {
      products: [
        { productKey: "speedport_smart_4_wlan_comfort", productAmount: 1 },
      ],
      serviceCase: SERVICE_CASES.wlanComfortCase,
      note: null,
    },
    haveRouter: {
      products: [{ productKey: "speed_home_wlan_s", productAmount: 1 }],
      serviceCase: SERVICE_CASES.wlanPaketCase,
      note: null,
    },
  },
  repeaterAmount_2: {
    needRouter: {
      products: [
        {
          productKey: "speedport_smart_4_wlan_comfort",
          productAmount: 1,
        },
        {
          productKey: "speed_home_wlan_s",
          productAmount: 1,
        },
      ],
      serviceCase: SERVICE_CASES.wlanComfortCase,
      note: null,
    },
    haveRouter: {
      products: [{ productKey: "speed_home_wlan_s", productAmount: 2 }],
      serviceCase: SERVICE_CASES.wlanPaketCase,
      note: null,
    },
  },
  repeaterAmount_3: {
    needRouter: {
      products: [
        {
          productKey: "speedport_smart_4_wlan_comfort",
          productAmount: 1,
        },
        {
          productKey: "speed_home_wlan_s",
          productAmount: 2,
        },
      ],
      serviceCase: SERVICE_CASES.wlanComfortCase,
      note: null,
    },
    haveRouter: {
      products: [{ productKey: "speed_home_wlan_s", productAmount: 3 }],
      serviceCase: SERVICE_CASES.wlanPaketCase,
      note: null,
    },
  },
  repeaterAmount_4: {
    needRouter: {
      products: [
        {
          productKey: "speedport_smart_4_wlan_comfort",
          productAmount: 1,
        },
        { productKey: "speed_home_wlan_s", productAmount: 3 },
      ],
      serviceCase: SERVICE_CASES.wlanComfortCase,
      note: null,
    },
    haveRouter: {
      products: [{ productKey: "speed_home_wlan_s", productAmount: 4 }],
      serviceCase: SERVICE_CASES.wlanPaketCase,
      note: null,
    },
  },
  repeaterAmount_5: {
    needRouter: {
      products: [
        {
          productKey: "speedport_smart_4_wlan_comfort",
          productAmount: 1,
        },
        { productKey: "speed_home_wlan_s", productAmount: 4 },
      ],
      serviceCase: SERVICE_CASES.wlanComfortCase,
      note: null,
    },
    haveRouter: {
      products: [{ productKey: "speed_home_wlan_s", productAmount: 5 }],
      serviceCase: SERVICE_CASES.wlanPaketCase,
      note: null,
    },
  },
};
