import React, { useMemo } from "react";
import "./button-groups.scss";
import ButtonGroupsItem from "./ButtonGroupsItem";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { BREAKPOINTS } from "../../constants/breakpoints";

const ButtonGroups = ({
  wrapperClasses = "",
  id = null,
  label = "",
  name,
  items,
  getValue,
  getIsChecked,
  displayItemContent,
  onChange,
  itemsAmountLg = 0,
  itemsAmountMd = 0,
  itemsAmountXs = 0,
  inputTestId = null,
  isDisabled = () => false,
  itemWidthAuto = false,
}) => {
  const windowWidth = useWindowWidth();

  const itemsInRow = useMemo(() => {
    if (windowWidth >= BREAKPOINTS.lg && itemsAmountLg) {
      return itemsAmountLg;
    }

    if (windowWidth >= BREAKPOINTS.md && (itemsAmountMd || itemsAmountLg)) {
      return itemsAmountMd || itemsAmountLg;
    }

    if (
      windowWidth >= BREAKPOINTS.xs &&
      (itemsAmountXs || itemsAmountMd || itemsAmountLg)
    ) {
      return itemsAmountXs || itemsAmountMd || itemsAmountLg;
    }

    return items.length;
  }, [windowWidth, items.length, itemsAmountLg, itemsAmountMd, itemsAmountXs]);

  const splitStreamingItemsIntoChunks = (streamingItemsArray, chunkSize) => {
    const result = streamingItemsArray.reduce((acc, level, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = []; // start a new chunk
      }

      acc[chunkIndex].push(level);

      return acc;
    }, []);
    return result;
  };

  return (
    <fieldset
      id={id}
      className={`button-groups ${wrapperClasses}`}
      name={name || null}
    >
      {label && <legend className="button-groups__title">{label}</legend>}
      <div className="button-groups__list">
        {splitStreamingItemsIntoChunks(items, itemsInRow).map(
          (buttonGroupChunk, index) => (
            <div key={`${name}-${index}`} className="button-groups__row">
              {buttonGroupChunk.map((buttonItem, innerIndex) => (
                <ButtonGroupsItem
                  itemWidth={
                    itemWidthAuto ? null : (100 / itemsInRow).toFixed(2)
                  }
                  idIndex={innerIndex}
                  rowIndex={index}
                  name={name}
                  key={`${name}-${index}-${innerIndex}`}
                  value={getValue(buttonItem)}
                  checked={getIsChecked(buttonItem)}
                  onChange={onChange}
                  inputTestId={inputTestId}
                  disabled={isDisabled(buttonItem)}
                >
                  {displayItemContent(buttonItem)}
                </ButtonGroupsItem>
              ))}
            </div>
          )
        )}
      </div>
    </fieldset>
  );
};

export default ButtonGroups;
