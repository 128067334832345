import React from "react";
import { A11yText, Button } from "@dtpk-cc/components";

import * as styles from "./switch-button.module.scss";

type SwitchButtonProps = {
  onClick: () => void;
  checked: boolean;
  allyText?: string;
  customClass?: string;
  disabled?: boolean;
};

const SwitchButton = ({
  disabled = false,
  onClick,
  checked,
  allyText = "Geschäftsfall typ Festnetz",
  customClass,
}: SwitchButtonProps) => (
  <Button
    tabIndex={disabled ? -1 : 0}
    onClick={onClick}
    variants={Button.Variant.bare}
    customClass={`${customClass} ${styles.switchButton} ${
      checked ? styles.active : ""
    } ${disabled ? styles.disabled : ""}
    `}
    role="radio"
  >
    <A11yText>{allyText}</A11yText>
    <span className={styles.switcher} />
  </Button>
);

export default SwitchButton;
