import { useState } from "react";

export interface IQRCodeModalData {
  imageSrc: string;
  urlLink: string;
  isOpen: boolean;
}

export const useQrCodeModalData = () => {
  const [qrCodeModalData, setQrCodeModalData] = useState<IQRCodeModalData>({
    imageSrc: "",
    urlLink: "",
    isOpen: false,
  });

  return { qrCodeModalData, setQrCodeModalData };
};
