import React from "react";
import { Heading } from "@dtpk-cc/components";
import * as styles from "./summary-configuratio-section-wrapper.module.scss";

type SummaryConfigurationSectionWrapperProps = {
  name: string;
  customClass?: string;
};

const SummaryConfigurationSectionWrapper: React.FC<
  SummaryConfigurationSectionWrapperProps
> = ({ name, children, customClass = "" }) => (
  <div className={`${styles.container} ${customClass}`}>
    <Heading
      tag="p"
      customClass={styles.name}
      variants={[Heading.Variant.quaternary, Heading.Variant.uppercase]}
    >
      {name}
    </Heading>
    {children}
  </div>
);

export default SummaryConfigurationSectionWrapper;
