import OtherRouterImg from "./images/router-anderen.png";
import Smart4RouterImg from "./images/speedport-smart-4.png";
import ProPlusRouterImg from "./images/speedport-pro-plus.png";
import WomanRouterImg from "./images/woman-desk.png";
import RepeaterImg from "./images/mesh-verstaerker.png";
import LandscapeImg from "./images/landschaft.png";

const getImageData = (imagePath) => {
  // get the index of the last "/" in the path
  const pathEndIndex = imagePath.lastIndexOf("/") + 1;
  const originPath = window ? window.location.origin : "";
  return {
    imageDataPath: `${originPath}${imagePath.slice(0, pathEndIndex)}`,
    imageDataName: imagePath.slice(pathEndIndex, imagePath.length),
  };
};

export const otherRouterImageData = getImageData(OtherRouterImg);
export const smartRouterImageData = getImageData(Smart4RouterImg);
export const proRouterImageData = getImageData(ProPlusRouterImg);
export const womanImageData = getImageData(WomanRouterImg);
export const repeaterImageData = getImageData(RepeaterImg);
export const landscapeImageData = getImageData(LandscapeImg);