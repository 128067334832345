import React from "react";
import * as styles from "../tariff-selection.module.scss";
import TariffLink from "../TariffLink";
import TariffSelectionCheckboxToggle from "../TariffSelectionCheckboxToggle";
import { ICartConfigurationSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { ITariffWorld } from "../../../../../core/entities/Product/IProduct";

type TariffSelectionHeaderProps = {
  settings: ICartConfigurationSettings;
  settingKey: keyof ICartConfigurationSettings;
  isChecked: boolean;
  activeTariffWorld: ITariffWorld;
  checkboxLabelText: string;
  onChange: (
    key: keyof ICartConfigurationSettings,
    value: string | number | boolean
  ) => void;
  checkboxIsDisplayed?: boolean;
};

const TariffSelectionHeader = ({
  settings,
  settingKey,
  isChecked,
  onChange,
  activeTariffWorld,
  checkboxLabelText,
  checkboxIsDisplayed = true,
}: TariffSelectionHeaderProps) => (
  <div className={styles.tariffEntriesSubtitleWrapper}>
    <TariffLink settings={settings} activeTariffWorld={activeTariffWorld} />
    {checkboxIsDisplayed && (
      <TariffSelectionCheckboxToggle
        labelText={checkboxLabelText}
        name={`${settingKey}-setting`}
        id={`${settingKey}-setting`}
        inputTestId={`${settingKey}-setting-1`}
        checked={isChecked}
        onChange={() => onChange(settingKey, !settingKey[settingKey])}
      />
    )}
  </div>
);

export default TariffSelectionHeader;
