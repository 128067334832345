import React, { useState } from "react";
import { List, Button, Icon, Heading, CopyText } from "@dtpk-cc/components";
import { ILoyaltyBonusSummaryRenderData } from "core/presenter/PencilSelling/CartItemSummaryPresenter";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";
import LoyaltyBonusIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/loyalty-bonus.svg";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummarySectionOptionsTitle from "../SummarySectionOptionsTitle";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";
import ModalWrapper from "../../../../elements/new-design/ModalWrapper";

import * as styles from "./summary-configuration-loyalty-bonus.module.scss";

type SummaryConfigurationLoyaltyBonusProps = {
  loyaltyBonusData: ILoyaltyBonusSummaryRenderData;
  customClass?: string;
  customerPricePrefix: string;
};

const SummaryConfigurationLoyaltyBonus = ({
  customClass = "",
  loyaltyBonusData,
  customerPricePrefix,
}: SummaryConfigurationLoyaltyBonusProps) => {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  const infoModalToggleHandler = () => {
    setInfoModalIsOpen((prev) => !prev);
  };

  return loyaltyBonusData ? (
    <>
      <SummarySectionItemWrapper
        variant="borderedInSection"
        customClass={customClass}
      >
        <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
          <SummarySectionOptionsTitle
            title={loyaltyBonusData.name}
            fontColor="magenta"
            Icon={LoyaltyBonusIcon}
          />
        </SummarySectionItemWrapper.Main>
        <SummarySectionItemWrapper.Monthly />
        <SummarySectionItemWrapper.Once />
        <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
          <List customClass="m-l-36 m-r-12">
            <List.Item>{loyaltyBonusData.loyaltyBonusText}</List.Item>
          </List>
        </SummarySectionItemWrapper.Main>
        <SummarySectionItemWrapper.Monthly>
          <SummaryConfigurationPriceItem
            suffix={customerPricePrefix}
            price={loyaltyBonusData.monthlyPrice}
          />
        </SummarySectionItemWrapper.Monthly>
        <SummarySectionItemWrapper.Once />
        <SummarySectionItemWrapper.Main
          variant="paddedLeftInAddition"
          customClass={`m-t-12 ${styles.loyaltyBonusDescrWrapper}`}
        >
          <div className="m-l-36">
            <CopyText
              dangerouslySetInnerHTML={{
                __html: loyaltyBonusData.loyaltyBonusDescription,
              }}
            />
            <Button
              tabIndex={0}
              customClass={styles.modalOpenBtn}
              onClick={infoModalToggleHandler}
              variants={[Button.Variant.bare, Button.Variant.icon]}
            >
              <Icon size={Icon.Size.small} icon={AlertInformationDefault} />
            </Button>
          </div>
        </SummarySectionItemWrapper.Main>
        <SummarySectionItemWrapper.Monthly />
        <SummarySectionItemWrapper.Once />
      </SummarySectionItemWrapper>
      <ModalWrapper
        containerCustomClass={styles.modal}
        isOpen={infoModalIsOpen}
        onClose={infoModalToggleHandler}
      >
        <Heading
          tag="h4"
          variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
        >
          Treuebonus auf der monatlichen Rechnung
        </Heading>
        <CopyText>
          <strong>
            Der rechnerische Durchschnittspreis erscheint nicht auf der
            Kundenrechnung. Der gesamte Treuebonus wird als Gutschrift
            angezeigt, der Bereitstellungspreis wird damit verrechnet und der
            Grundpreis entfällt für bis zu{" "}
            <CopyText
              tag="span"
              variants={[CopyText.Variant.highlight, CopyText.Variant.magenta]}
            >
              {loyaltyBonusData.freeMonths}
              {loyaltyBonusData.freeMonths === 1 ? " Monat" : " Monate"}.
            </CopyText>
          </strong>
        </CopyText>
        <CopyText>
          (weitere Leistungen / Optionen des Hauptvertrages oder Zusatzkosten
          wie Auslandstelefonie reduzieren zusätzlich den Treuebonus und können
          zu Abweichungen bei der Berechnung der Monate führen)
        </CopyText>
      </ModalWrapper>
    </>
  ) : null;
};

export default SummaryConfigurationLoyaltyBonus;
