import React, { ChangeEvent, FormEvent, Fragment, useCallback } from "react";
import { observer } from "mobx-react";
import {
  Grid,
  GridColProps,
  Tile,
  Heading,
  Button,
  Icon,
} from "@dtpk-cc/components";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import { CustomPromotionTypes } from "../../../../core/entities/PencilSelling/ICustomPromotion";
import { ICustomPromotionsForm } from "../IPromotionSelection";
import { PortfolioKeys } from "../../../../core/entities/Product/IProduct";
import { PromotionPriceType } from "../../../../core/entities/Product/IDiscount";
import Input from "../../../../elements/new-design/Input";
import { onlyNumber } from "../../../../helpers/NumericCalculation";
import { ICartItemDataPromotions } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import CustomPromotionsActionBlock from "../CustomPromotionsActionBlock";
import DropdownMain from "../../../../elements/new-design/DropdownMain";
import { trackClick } from "../../../../helpers/reactTracking";

import * as styles from "./custom-promotions-form.module.scss";

type CustomPromotionsFormProps = {
  formState: ICustomPromotionsForm;
  defaultFormConfig: ICustomPromotionsForm;
  portfolioKey: PortfolioKeys;
  setFormState: React.Dispatch<React.SetStateAction<ICustomPromotionsForm>>;
  modalPromotionsState: ICartItemDataPromotions;
  availablePromotions: ICartItemDataPromotions;
  isAddMode: boolean;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteConfirmModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trackingContext: string;
  onAdd: (ICustomPromotion) => void;
  onEdit: (ICustomPromotion) => void;
};

const promotionTypesMap: {
  [key in CustomPromotionTypes]: string;
} = {
  [CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE]:
    "Monatliche Preisreduktion",
  [CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE]: "Einmalige Preisreduktion",
  [CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE]: "Aktion ohne Preis",
};

const CustomPromotionsForm = ({
  formState,
  setFormState,
  portfolioKey,
  availablePromotions,
  isAddMode,
  setIsFormVisible,
  setDeleteConfirmModalIsOpen,
  trackingContext,
  onAdd,
  onEdit,
  defaultFormConfig,
}: CustomPromotionsFormProps) => {
  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getInputComponentTemplate = ({
    customClass = "",
    placeholder = "",
    name = "",
    value = "",
    isNumberType = false,
    isValid = true,
    invalidMessage = "",
    required = false,
    width = {
      m: 12,
      l: 12,
      xl: 24,
    },
  }) => (
    <Grid.Col
      customClass={customClass}
      m={width.m as GridColProps["m"]}
      l={width.l as GridColProps["l"]}
      xl={width.xl as GridColProps["xl"]}
    >
      <Input
        id={name}
        placeholder={placeholder}
        invalidMessage={invalidMessage}
        isValid={isValid}
        name={name}
        value={value}
        required={required}
        onChange={(event) => inputChangeHandler(event)}
        onKeyPress={(e) => {
          if (isNumberType) {
            onlyNumber(e);
          }
        }}
      />
    </Grid.Col>
  );

  const convertPromotionItem = () => {
    const from = formState.from ? parseInt(formState.from, 10) : null;
    const to = formState.to ? parseInt(formState.to, 10) : null;
    const interval = from && to ? to - from + 1 : null;

    return {
      key: formState.key,
      portfolio: portfolioKey,
      name: formState.name,
      description: formState.name,
      type: formState.type,
      discount: {
        interval,
        from,
        to,
        value: formState.value
          ? parseFloat(formState.value.replace(",", "."))
          : null,
        kind: PromotionPriceType.CUSTOM_PROMOTION,
      },
    };
  };

  const validateNameForUnique = () =>
    !availablePromotions.some(
      // key !== formState.key check statement for Edit mode
      ({ name, key }) => name === formState.name && key !== formState.key
    );

  const formTypeConfigMap = {
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_MONTHLY]: {
    //   components: {
    //     interval: getInputComponentTemplate(
    //       "Monate",
    //       "interval",
    //       formState?.interval,
    //       true
    //     ),
    //     from: getInputComponentTemplate(
    //       "Gültig ab",
    //       "from",
    //       formState?.from,
    //       true
    //     ),
    //     to: getInputComponentTemplate("Gültig bis", "to", formState?.to, true),
    //   },
    //   formConfigData: {
    //     interval: "",
    //   },
    // },
    [CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE]: {
      components: {
        value: getInputComponentTemplate({
          customClass: "m-b-12",
          placeholder: "Preis",
          name: "value",
          value: formState.value || "",
          isNumberType: true,
          isValid: parseInt(formState.value, 10) >= 0,
          invalidMessage: "Dieses Feld ist erforderlich",
        }),
        // interval: getInputComponentTemplate(
        //   "Monate",
        //   "interval",
        //   formState?.interval,
        //   true,
        //   parseInt(formState?.interval, 10) > 0,
        //   "Muss größer als 0 sein"
        // ),
        from: getInputComponentTemplate({
          customClass: "p-r-12",
          placeholder: "Gültig ab",
          name: "from",
          value: formState.from || "",
          isNumberType: true,
          isValid: parseInt(formState?.from, 10) > 0,
          invalidMessage: "Muss größer als 0 sein",
          width: {
            m: 6,
            l: 6,
            xl: 12,
          },
        }),
        to: getInputComponentTemplate({
          placeholder: "Gültig bis",
          name: "to",
          value: formState.to || "",
          isNumberType: true,
          isValid:
            parseInt(formState?.to, 10) > 0 &&
            parseInt(formState.to, 10) >= parseInt(formState.from, 10),
          invalidMessage:
            parseInt(formState.to, 10) < parseInt(formState.from, 10)
              ? "Muss größer oder gleich „Gültig ab“ sein"
              : "Muss größer als 0 sein",
          width: {
            m: 6,
            l: 6,
            xl: 12,
          },
        }),
      },
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name &&
        formState.type &&
        formState.value &&
        parseInt(formState.from, 10) > 0 &&
        parseInt(formState.to, 10) > 0 &&
        parseInt(formState.to, 10) >= parseInt(formState.from, 10) &&
        validateNameForUnique(),
    },
    [CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE]: {
      components: {
        value: getInputComponentTemplate({
          placeholder: "Preis",
          name: "value",
          value: formState.value || "",
          isNumberType: true,
          isValid: parseInt(formState.value, 10) >= 0,
          invalidMessage: "Dieses Feld ist erforderlich",
        }),
      },
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name &&
        formState.type &&
        formState.value &&
        validateNameForUnique(),
    },
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_PROVISION_FEE]: {
    //   components: {},
    //   formConfigData: {
    //     interval: null,
    //     from: null,
    //     to: null,
    //   },
    // },
    [CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE]: {
      components: {},
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name && formState.type && validateNameForUnique(),
    },
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_CONST_PRICE_REDUCE]: {
    //   components: {},
    //   formConfigData: {
    //     interval: null,
    //     from: null,
    //     to: null,
    //   },
    // },
  };

  const actionBtnIsDisabled = useCallback(
    (customValidationHandler: () => boolean) => {
      if (customValidationHandler) {
        const formIsValid = customValidationHandler();
        return !formIsValid;
      }
      return true;
    },
    []
  );

  const promotionTypeChangeHandler = (displayName) => {
    // Search for custom promotion type by selected dropdown value
    const promotionTypeKey = Object.keys(promotionTypesMap).find(
      (key) => promotionTypesMap[key] === displayName
    ) as CustomPromotionTypes;

    setFormState((prev) => ({
      ...prev,
      ...(formTypeConfigMap[promotionTypeKey]
        .formConfigData as Partial<ICustomPromotionsForm>),
      name: prev.name,
      key: prev.key,
      type: promotionTypeKey,
    }));
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isAddMode) {
      onAdd(convertPromotionItem());
    } else {
      onEdit(convertPromotionItem());
    }
  };

  const formComponents = formTypeConfigMap[formState.type]?.components || {};

  return (
    <Tile variant="shadow">
      <form onSubmit={submitHandler}>
        <div className={`m-b-24 ${styles.header}`}>
          <Heading
            customClass="m-b-0"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            {isAddMode
              ? "Eigene Aktion hinzufügen"
              : "Eigene Aktion bearbeiten"}
          </Heading>
          {!isAddMode && (
            <Button
              variants={Button.Variant.bare}
              onClick={() => {
                setIsFormVisible(false);
                setDeleteConfirmModalIsOpen(true);
                trackClick(
                  "öffnen-sie-den-löschbestätigungsdialog",
                  trackingContext
                );
              }}
            >
              <Icon size={Icon.Size.small} icon={ActionRemoveDefault} />
            </Button>
          )}
        </div>
        <Grid>
          <Grid.Row customClass="m-l-0 m-r-0 m-b-12">
            <Grid.Col m={12} l={12} xl={24}>
              <Input
                id="name"
                name="name"
                placeholder="Aktionsname"
                invalidMessage={`${formState.name} existiert bereits`}
                value={formState.name}
                isValid={validateNameForUnique()}
                onChange={inputChangeHandler}
                requiredMessage="Dieses Feld ist erforderlich"
                required
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row customClass="m-l-0 m-r-0 m-b-12">
            <Grid.Col m={12} l={12} xl={24}>
              <DropdownMain
                label="Wähle die Aktionsart"
                items={Object.values(promotionTypesMap)}
                onSelection={promotionTypeChangeHandler}
                value={promotionTypesMap[formState.type]}
                invalidMessage="Dieses Feld ist erforderlich"
                required
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid.Row
          customClass={`m-l-0 m-r-0 ${
            Object.entries(formComponents).length ? "m-b-26" : "m-b-12"
          }`}
        >
          {Object.entries(formComponents).map(([key, formComponent], index) => (
            <Fragment key={`${key}${index}`}>{formComponent}</Fragment>
          ))}
        </Grid.Row>
        <CustomPromotionsActionBlock
          isAddMode={isAddMode}
          submitBtnIsDisabled={actionBtnIsDisabled(
            formTypeConfigMap[formState.type]?.validationHandler
          )}
          cancelBtnHandler={() => {
            setIsFormVisible(false);
            setFormState(defaultFormConfig);
            trackClick("benutzerdefiniertes-werbeformular-schließen");
          }}
        />
      </form>
    </Tile>
  );
};

export default observer(CustomPromotionsForm);
