import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
} from "react";
import RestartSvg from "@dtpk-cc/components/dist/icons/Action/Restart/Default";
import { useUseCases } from "hooks/useUseCases";
import { businessCaseOptions } from "core/entities/Product/Tariff/ITariff";
import { StreamingAnalysisPresenter } from "core/presenter/PencilSelling/StreamingAnalysisPresenter";
import {
  Button,
  CopyText,
  EqualHeight,
  EqualHeightElement,
  Heading,
  List,
  Modal,
  Price,
  Container,
} from "@dtpk-cc/components";
import "./streaming-analysis.scss";
import { trackClick } from "helpers/reactTracking";
import "@dtpk-cc/components/dist/components/ButtonGroup/ButtonGroup.css";
import { PaymentTypes } from "core/entities/PencilSelling/CartItem/ICartItem";
import StreamingServiceItem from "elements/StreamingServiceItem";
import StreamingServiceResultItem from "elements/StreamingServiceResultItem";
import BadgeCustom from "elements/BadgeCustom";
import { IStreamingServicesKeys } from "core/entities/Product/IStreamingServiceKeys";
import {
  Group,
  ILevel,
  ITariffWorld,
  PortfolioKeys,
} from "core/entities/Product/IProduct";
import { ExpansionArea } from "core/entities/Applications";
import RangeSlider from "elements/RangeSlider";
import ButtonGroups from "elements/ButtonGroups";
import { IStreamingVariant } from "core/entities/Product/IStreamingVariant";
import MegaSportLogo from "../assets/mega-sport-logo.svg";
import PencilSellingToolbarCloseBtn from "../../../components/PencilSellingToolbar/PencilSellingToolbarCloseBtn";

type StreamingAnalysisProps = {
  toggleToolbar: () => void;
  applyToolConfig: () => void;
  setForceRerender: () => void;
  closeHandler: () => void;
};

const DAZN_SERVICE = "tv_options_dazn_twelve";
const MAGENTA_SPORT_SERVICE = "tv_options_magenta_sport_landline";
const WOW_LIVE_SPORT_SERVICE = "tv_options_wow_live_sport_twelve_landline";

const MEGASPORT_PRODUCT_PACKAGE_KEYS = [
  DAZN_SERVICE,
  MAGENTA_SPORT_SERVICE,
  WOW_LIVE_SPORT_SERVICE,
];

const MEGASPORT_STREAMING_SERVICES_KEYS = [
  "dazn",
  "magenta_sport",
  "wow_live_sport",
];

const MEGASPORT_PRODUCT_KEY = "tv_addon_mega_sport_twelve";

const StreamingAnalysis: React.FC<StreamingAnalysisProps> = ({
  toggleToolbar,
  applyToolConfig,
  setForceRerender,
  closeHandler,
}) => {
  const trackingContext = "tv-streaming check";
  const {
    addToCartUseCase,
    getOptionsUseCase,
    getLandlineSettingsUseCase,
    setLandlineSettingsUseCase,
    getStreamingServicesUseCase,
    getStreamingVariantsUseCase,
    setStreamingServiceActiveUseCase,
    getStreamingServicesPriceUseCase,
    getTariffsUseCase,
    findProductInCartUseCase,
    deleteFromCartUseCase,
  } = useUseCases();
  const tariffs = getTariffsUseCase.execute();
  const landlineSettings = getLandlineSettingsUseCase.execute();
  const [exampleTariff, setExampleTariff] = useState(
    tariffs.landline.find(
      (tariff) => tariff.level.key === "magenta_tv_smart" && tariff.size === "L"
    )
  );

  const [services, setServices] = useState(() =>
    getStreamingServicesUseCase.execute()
  );
  const [rangeSliderValue, setRangeSliderValue] = useState(1);
  const variants = useMemo(
    () => getStreamingVariantsUseCase.execute(),
    [getStreamingVariantsUseCase]
  );
  const servicesPrice = getStreamingServicesPriceUseCase.execute();
  const [presenter] = useState(
    new StreamingAnalysisPresenter(landlineSettings, services, variants)
  );
  const [promotions, setPromotions] = useState(() =>
    presenter.getPromotions(exampleTariff)
  );
  const [currentVariant, setCurrentVariant] = useState(
    () => presenter.getStreamingVariant
  );
  const [currentVariantPrice, setCurrentVariantPrice] = useState(
    presenter.priceSum
  );
  const settings = presenter.getSettings();
  const creditPrice = servicesPrice - currentVariantPrice;
  const isRecommendation = presenter.isRecommendatedVariantActive;

  const checkMegaSportIsActive = useCallback(
    () =>
      [DAZN_SERVICE, WOW_LIVE_SPORT_SERVICE].every((optionKey) =>
        presenter.exportOptionKeys().includes(optionKey)
      ),
    [presenter]
  );

  const [isMegaSportActive, setIsMegaSportActive] = useState(
    checkMegaSportIsActive()
  );
  const [resetModalIsOpen, setResetModalIsOpen] = useState(false);

  const toggleResetModal = () => setResetModalIsOpen((prev) => !prev);

  const getLandlineAddon = useCallback(
    (targetOptionKey: string) =>
      getOptionsUseCase
        .execute()
        .landline.find((option) => option.key === targetOptionKey),
    [getOptionsUseCase]
  );

  const handleServiceClick = (key: string, isActive: boolean): void => {
    setStreamingServiceActiveUseCase.execute(key, isActive);
    // For every streaming service toggle, check for cheapest variant ( level )
    const levelValue = presenter.getCheapestVariant()?.level?.key || "";
    if (levelValue && settings.level !== levelValue) {
      presenter.setSetting("level", levelValue);
    }
    setServices(getStreamingServicesUseCase.execute());
    setCurrentVariant(presenter.getStreamingVariant);
    setIsMegaSportActive(checkMegaSportIsActive());
  };

  const changeTariffWorld = (value: string): void => {
    presenter.setSetting("tariffWorld", value);
    setCurrentVariant(presenter.getStreamingVariant);
  };

  const changeLevel = (value: string): void => {
    presenter.setSetting("level", value);
    setCurrentVariant(presenter.getStreamingVariant);
  };

  const changeBusinessCase = (value: string): void => {
    presenter.setSetting("businessCase", value);
    setPromotions(presenter.getPromotions(exampleTariff));
  };

  const resetStreamingAnalysis = () => {
    // Reset services state
    services.forEach((service) => {
      handleServiceClick(service.key, false);
    });

    // Reset TariffWorld, Level and BusinessCase
    const selectedTariffWorld = presenter.getInitialTariffWorld();
    changeTariffWorld(selectedTariffWorld);
    const selectedLevel = presenter.getSettings().level;
    changeLevel(selectedLevel);
    changeBusinessCase(landlineSettings.businessCase);

    setLandlineSettingsUseCase.execute({
      ...landlineSettings,
      tariffWorld: selectedTariffWorld,
      level: selectedLevel,
    });

    // Remove all products that can be added by streaming analysis
    const filteredServices = services
      .reduce((acc, service) => {
        if (service.productKey) {
          acc.push(service.productKey);
        }
        return acc;
      }, [] as string[])
      .concat(MEGASPORT_PRODUCT_KEY)
      .concat(presenter.getAdditionalProductKeys());

    filteredServices.forEach((productKey) => {
      deleteFromCartUseCase.execute(productKey);
    });
    setForceRerender();
    toggleToolbar();
  };

  const exportProducts = (): void => {
    toggleToolbar();

    // TODO: use a data flag to indentify regio / telekom expansionArea instead of tariffWorld
    const streamingAnalysisSettings = presenter.getSettings();
    setLandlineSettingsUseCase.execute({
      ...landlineSettings,
      ...streamingAnalysisSettings,
      expansionArea:
        streamingAnalysisSettings.tariffWorld === "magenta_home_regio"
          ? ExpansionArea.regio
          : ExpansionArea.telekom,
    });
    let exportedOptions = presenter.exportOptionKeys();
    const filteredMegaSportOptions = exportedOptions.filter((optionKey) =>
      MEGASPORT_PRODUCT_PACKAGE_KEYS.includes(optionKey)
    );

    const addProductToCart = (targetOptionKey: string, callback = () => {}) => {
      const addon = getLandlineAddon(targetOptionKey);
      if (addon && !findProductInCartUseCase.execute(targetOptionKey)) {
        const cartItem = {
          ...addon,
          paymentType: PaymentTypes.MONTHLY,
          promotions: [],
          quantity: 1,
          isFungible: false,
          group: Group.addon,
          portfolio: PortfolioKeys.LANDLINE,
        };
        addToCartUseCase.execute(cartItem);
      }
      callback();
    };
    // Check whether all three or two required services were selected
    if (
      [DAZN_SERVICE, WOW_LIVE_SPORT_SERVICE].every((optionKey) =>
        filteredMegaSportOptions.includes(optionKey)
      )
    ) {
      // Filter out three or two related megaSport products after megaSport product will be added
      const filterExportedOptions = () => {
        exportedOptions = exportedOptions.filter(
          (optionKey) => !MEGASPORT_PRODUCT_PACKAGE_KEYS.includes(optionKey)
        );
      };
      addProductToCart(MEGASPORT_PRODUCT_KEY, filterExportedOptions);
    }
    exportedOptions.forEach((key) => {
      addProductToCart(key);
    });
  };
  // useLayoutEffect prevents layout flickering of creditPrice on streaming service isActive toggle
  useLayoutEffect(() => {
    let currentlySelectedVariant: IStreamingVariant;
    const pricesIsUpdated = MEGASPORT_STREAMING_SERVICES_KEYS.every(
      (optionKey) => currentVariant.streamingServices[optionKey] === 0
    );
    if (isMegaSportActive && !pricesIsUpdated) {
      const megaSportPrice =
        getLandlineAddon(MEGASPORT_PRODUCT_KEY).price?.monthly || 0;
      currentlySelectedVariant = {
        ...currentVariant,
        streamingServices: { ...currentVariant.streamingServices },
      };

      MEGASPORT_STREAMING_SERVICES_KEYS.forEach((key) => {
        currentlySelectedVariant.streamingServices[key] = 0;
      });
      setCurrentVariantPrice(
        presenter.getVariantPriceByActiveServices({
          ...currentlySelectedVariant,
        }) + megaSportPrice
      );
      setCurrentVariant(currentlySelectedVariant);
    }
    // If prices are updated and megaSport is not active -> get original variant with default streaming services prices
    if (pricesIsUpdated && !isMegaSportActive) {
      currentlySelectedVariant = variants.find(
        (variantItem) => variantItem.key === currentVariant.key
      );
      setCurrentVariant(currentlySelectedVariant);
      setCurrentVariantPrice(
        presenter.getVariantPriceByActiveServices(currentlySelectedVariant)
      );
    }
    // Use regular sum calculation
    if (!pricesIsUpdated && !isMegaSportActive) {
      setCurrentVariantPrice(presenter.priceSum);
    }
  }, [
    currentVariant,
    isMegaSportActive,
    presenter,
    services,
    variants,
    getLandlineAddon,
  ]);

  useEffect(() => {
    const selectedTariff = tariffs.landline.find(
      (tariff) => tariff.level.key === currentVariant.key && tariff.size === "L"
    );
    setExampleTariff(selectedTariff);
    setPromotions(presenter.getPromotions(selectedTariff));
  }, [
    currentVariant,
    setExampleTariff,
    setPromotions,
    presenter,
    tariffs.landline,
  ]);

  const megaSportCurrentVariantPriceTemplate = useMemo(
    () =>
      presenter.megaSportStreamingServices.map(({ key, active, name }) => (
        <StreamingServiceResultItem
          key={key}
          keyValue={key}
          isActive={!currentVariant.streamingServices[key] || active}
          priceValue={presenter.getDisplayPrice(
            currentVariant.streamingServices[key]
          )}
          priceIsDisplayed={!!currentVariant.streamingServices[key]}
          withAdditionalInfo={[
            IStreamingServicesKeys.live_tv,
            IStreamingServicesKeys.hd_plus,
          ].includes(key)}
          serviceName={name}
        />
      )),
    // Services dependency is required to react on streaming service isActive state toggle
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presenter, currentVariant.streamingServices, services]
  );

  return (
    <>
      <PencilSellingToolbarCloseBtn
        customClass="sketchboard__close-btn"
        onClick={closeHandler}
      />
      <EqualHeight>
        <Heading
          tag="h1"
          customClass="streaming-analysis__headline"
          variants={Heading.Variant.secondary}
        >
          TV-Streaming Check
          <Button
            onClick={toggleResetModal}
            customClass="streaming-analysis__reset-button"
            variants={[Button.Variant.outline]}
          >
            <Button.Icon icon={RestartSvg} />
            <span className="streaming-analysis__reset-button-text">
              Neuer Check
            </span>
          </Button>
        </Heading>
        <div className="streaming-analysis__range-slider-wrapper">
          <RangeSlider
            min={1}
            max={2}
            value={rangeSliderValue}
            onChange={(value: number) => setRangeSliderValue(value)}
          />
        </div>
        <div className="streaming-analysis__wrapper">
          <div
            className={`streaming-analysis streaming-analysis--position-${rangeSliderValue}`}
          >
            <div className="streaming-analysis__column streaming-analysis__column--padded streaming-analysis__column--with-bg">
              <div className="streaming-services">
                <EqualHeightElement name="header" />
                <EqualHeightElement name="megathek">
                  <div className="streaming-services__title-wrapper">
                    <CopyText
                      customClass="streaming-services__title"
                      tag="h3"
                      variants={[CopyText.Variant.highlight]}
                    >
                      Anbieter
                    </CopyText>
                    <CopyText
                      customClass="streaming-services__title streaming-services__title--right-spaced"
                      tag="h3"
                      variants={[CopyText.Variant.highlight]}
                    >
                      Kosten beim Anbieter
                    </CopyText>
                  </div>
                  <div className="streaming-services__megathek-wrapper">
                    {presenter.megathekStreamingServices.map(
                      ({ key, name, price, active }) => (
                        <StreamingServiceItem
                          key={key}
                          isActive={active}
                          toggleServiceActive={() => {
                            handleServiceClick(key, !active);
                            trackClick(
                              `streaming-check-${key}`,
                              trackingContext
                            );
                          }}
                          name={name}
                          priceValue={presenter.getDisplayPrice(price.monthly)}
                          customClass="streaming-services__item"
                        />
                      )
                    )}
                  </div>
                </EqualHeightElement>
                <EqualHeightElement name="main-streaming-services">
                  <div className="streaming-services__main-wrapper">
                    {presenter.mainStreamingServicesKeys.map(
                      ({ key, name, price, active }) => (
                        <StreamingServiceItem
                          key={key}
                          isActive={active}
                          toggleServiceActive={() => {
                            handleServiceClick(key, !active);
                            trackClick(
                              `streaming-check-${key}`,
                              trackingContext
                            );
                          }}
                          name={name}
                          priceValue={presenter.getDisplayPrice(price.monthly)}
                          customClass={`${
                            key === IStreamingServicesKeys.live_tv
                              ? "streaming-services__item--live_tv"
                              : ""
                          } streaming-services__item`}
                        />
                      )
                    )}
                    <EqualHeightElement name="mega-sport-streaming-services">
                      {presenter.megaSportStreamingServices.map(
                        ({ key, name, price, active }) => (
                          <StreamingServiceItem
                            key={key}
                            isActive={active}
                            toggleServiceActive={() =>
                              handleServiceClick(key, !active)
                            }
                            name={name}
                            priceValue={presenter.getDisplayPrice(
                              price.monthly
                            )}
                            customClass="streaming-services__item"
                          />
                        )
                      )}
                    </EqualHeightElement>
                  </div>
                </EqualHeightElement>
                <div className="streaming-services__item">
                  <div className="streaming-services__button">&nbsp;</div>
                  <div className="streaming-services__price streaming-services__price--sum streaming-services__price--sum-services streaming-services__price--center">
                    <Heading
                      tag="h3"
                      variants={[
                        Heading.Variant.tertiary,
                        Heading.Variant.display,
                      ]}
                    >
                      {presenter.getDisplayPrice(servicesPrice)} mtl.
                    </Heading>
                  </div>
                </div>
              </div>
            </div>
            <div className="streaming-analysis__column streaming-analysis__column--padded streaming-analysis__column--bordered-magenta streaming-analysis__column--center currentVariant">
              {isRecommendation && (
                <BadgeCustom customClass="streaming-analysis__recommendation-badge">
                  <span>Empfehlung</span>
                </BadgeCustom>
              )}
              {currentVariant && (
                <>
                  <EqualHeightElement name="header">
                    <CopyText
                      customClass="streaming-services__title"
                      tag="h3"
                      variants={[
                        CopyText.Variant.highlight,
                        CopyText.Variant.magenta,
                      ]}
                    >
                      {currentVariant.name}
                    </CopyText>
                    <CopyText>{currentVariant.areaOfUse}</CopyText>
                    <CopyText
                      customClass="streaming-analysis__spacer"
                      variants={CopyText.Variant.highlight}
                    >
                      {presenter.getDisplayPrice(currentVariant.monthly)} mtl.
                    </CopyText>
                  </EqualHeightElement>
                  <EqualHeightElement name="megathek">
                    <div className="currentVariant__inner-box-wrapper">
                      <CopyText
                        variants={CopyText.Variant.magenta}
                        customClass="currentVariant__inner-box-header currentVariant__inner-box-header--megathek"
                      >
                        Mit dabei: MEGATHEK
                      </CopyText>
                      <div className="currentVariant__megathek-subtitle-wrapper">
                        <CopyText>
                          Exklusiven Serien
                          <CopyText
                            variants={CopyText.Variant.magenta}
                            tag="span"
                          >
                            inklusive
                          </CopyText>
                        </CopyText>
                        <CopyText>
                          Serien & Filme von Sony One, Paramount, BBC ...
                          <CopyText
                            variants={CopyText.Variant.magenta}
                            tag="span"
                          >
                            inklusive
                          </CopyText>
                        </CopyText>
                      </div>
                      {presenter.megathekStreamingServices.map(
                        ({ key, active, name }) => (
                          <StreamingServiceResultItem
                            key={key}
                            keyValue={key}
                            isActive={
                              !currentVariant.streamingServices[key] || active
                            }
                            priceValue={presenter.getDisplayPrice(
                              currentVariant.streamingServices[key]
                            )}
                            priceIsDisplayed={
                              !!currentVariant.streamingServices[key]
                            }
                            withAdditionalInfo={[
                              IStreamingServicesKeys.live_tv,
                              IStreamingServicesKeys.hd_plus,
                            ].includes(key)}
                            serviceName={name}
                          />
                        )
                      )}
                    </div>
                  </EqualHeightElement>
                  <EqualHeightElement name="main-streaming-services">
                    {presenter.mainStreamingServicesKeys.map(
                      ({ key, active, name }) => (
                        <StreamingServiceResultItem
                          key={key}
                          keyValue={key}
                          isActive={
                            !currentVariant.streamingServices[key] || active
                          }
                          priceValue={presenter.getDisplayPrice(
                            currentVariant.streamingServices[key]
                          )}
                          priceIsDisplayed={
                            !!currentVariant.streamingServices[key]
                          }
                          withAdditionalInfo={[
                            IStreamingServicesKeys.live_tv,
                            IStreamingServicesKeys.hd_plus,
                          ].includes(key)}
                          serviceName={name}
                        />
                      )
                    )}
                    <EqualHeightElement name="mega-sport-streaming-services">
                      {isMegaSportActive ? (
                        <div className="currentVariant__inner-box-wrapper currentVariant__inner-box-wrapper--megasport">
                          <div className="currentVariant__inner-box-header currentVariant__inner-box-header--megasport">
                            <MegaSportLogo />
                            <Price
                              className="currentVariant__megasport--price"
                              value={
                                getLandlineAddon(MEGASPORT_PRODUCT_KEY).price
                                  ?.monthly
                              }
                              suffix="mtl."
                              prefix="für"
                            />
                          </div>
                          {megaSportCurrentVariantPriceTemplate}
                        </div>
                      ) : (
                        <div className="currentVariant__megasport-package-wrapper">
                          {megaSportCurrentVariantPriceTemplate}
                        </div>
                      )}
                    </EqualHeightElement>
                  </EqualHeightElement>
                  <div className="streaming-services__price streaming-services__price--sum streaming-services__price--sum-main streaming-services__price streaming-services__price--center">
                    <Price
                      value={currentVariantPrice}
                      suffix="mtl."
                      variants={[Price.Variant.offering]}
                    />
                    {creditPrice > 0 && (
                      <Price.Text className="streaming-services__price--sum-save">
                        <CopyText
                          customClass="streaming-services__price--sum-save"
                          tag="span"
                        >
                          {presenter.getDisplayPrice(creditPrice)}
                        </CopyText>
                        mtl. sparen
                      </Price.Text>
                    )}
                  </div>
                </>
              )}

              <Button
                onClick={() => {
                  exportProducts();
                  applyToolConfig();
                  trackClick("ins-angebot-übernehmen", trackingContext);
                }}
              >
                Ins Angebot übernehmen
              </Button>
            </div>
            <div className="streaming-analysis__column streaming-analysis__column--flexed-col">
              <div className="streaming-analysis__spacer streaming-analysis__column--with-bg streaming-analysis__variant-section">
                <CopyText
                  customClass="streaming-analysis--uppercased streaming-analysis__spacer--small"
                  tag="h3"
                  variants={[CopyText.Variant.highlight]}
                >
                  Tarifwelt
                </CopyText>
                <div className="streaming-analysis__lists-wrapper">
                  <ButtonGroups
                    name="world-buttongroup"
                    items={presenter.tariffWorlds}
                    itemsAmountMd={2}
                    itemsAmountXs={1}
                    onChange={(value: string) => {
                      changeTariffWorld(value);
                      trackClick(value, trackingContext);
                    }}
                    getValue={(world: ITariffWorld) => world.key}
                    getIsChecked={(world: ITariffWorld) =>
                      world.key === settings.tariffWorld
                    }
                    displayItemContent={(world: ITariffWorld) => world.name}
                  />
                </div>
              </div>

              {!!presenter.levels.length && (
                <div className="streaming-analysis__lists-wrapper streaming-analysis__spacer streaming-analysis__column--with-bg streaming-analysis__variant-section">
                  <CopyText
                    customClass="streaming-analysis--uppercased streaming-analysis__spacer--small"
                    tag="h3"
                    variants={[CopyText.Variant.highlight]}
                  >
                    Tarifvariante
                  </CopyText>
                  <ButtonGroups
                    name="level-buttongroup"
                    onChange={(value: string) => {
                      changeLevel(value);
                      trackClick(value, trackingContext);
                    }}
                    items={presenter.levels}
                    itemsAmountLg={2}
                    itemsAmountXs={1}
                    getValue={(level: ILevel) => level.key}
                    getIsChecked={(level: ILevel) =>
                      level.key === settings.level
                    }
                    displayItemContent={(level: ILevel) => level.name}
                  />
                </div>
              )}

              <div className="streaming-analysis__lists-wrapper streaming-analysis__spacer streaming-analysis__column--with-bg streaming-analysis__variant-section">
                <CopyText
                  customClass="streaming-analysis--uppercased streaming-analysis__spacer--small"
                  tag="h3"
                  variants={[CopyText.Variant.highlight]}
                >
                  Geschäftsfall
                </CopyText>
                <ButtonGroups
                  name="businesscase-buttongroup"
                  items={businessCaseOptions.slice(0, 2)}
                  itemsAmountLg={2}
                  itemsAmountXs={1}
                  onChange={(value: string) => {
                    changeBusinessCase(value);
                    trackClick(value, trackingContext);
                  }}
                  getValue={({ key }: { key: string }) => key}
                  getIsChecked={({ key }: { key: string }) =>
                    key === settings.businessCase
                  }
                  displayItemContent={({ name }: { name: string }) => name}
                />
                <CopyText
                  customClass="streaming-analysis--uppercased streaming-analysis__spacer--small streaming-analysis--mt-12"
                  tag="h3"
                  variants={[CopyText.Variant.highlight]}
                >
                  Geschäftsfall Bestandskunde
                </CopyText>

                <ButtonGroups
                  wrapperClasses="streaming-analysis__button-group--reversed"
                  name="businesscase-buttongroup-2"
                  items={businessCaseOptions.slice(2, 5)}
                  itemsAmountLg={2}
                  itemsAmountXs={1}
                  onChange={(value: string) => {
                    changeBusinessCase(value);
                    trackClick(value, trackingContext);
                  }}
                  getValue={({ key }: { key: string }) => key}
                  getIsChecked={({ key }: { key: string }) =>
                    key === settings.businessCase
                  }
                  displayItemContent={({ name }: { name: string }) => name}
                />
              </div>
              {presenter.isPromotionActive(exampleTariff) && (
                <div className="streaming-benefit streaming-analysis__column--with-bg streaming-analysis__variant-section">
                  <Heading
                    tag="h3"
                    customClass="streaming-benefit__subtitle"
                    variants={[
                      Heading.Variant.quaternary,
                      Heading.Variant.display,
                    ]}
                  >
                    Jetzt im Komplettpaket sparen!
                  </Heading>
                  <CopyText>
                    Z.B. {exampleTariff.name} mit {currentVariant.name} im
                    Geschäftsfall {presenter.getBusinessCaseName()}:
                  </CopyText>
                  <List>
                    {promotions.map(
                      (promotion) =>
                        !!promotion?.description && (
                          <List.Item key={promotion.key}>
                            <List.Text>{promotion?.description}</List.Text>
                          </List.Item>
                        )
                    )}
                  </List>
                </div>
              )}
            </div>
          </div>
        </div>
      </EqualHeight>
      <Modal closable={false} zIndex={100} isOpen={resetModalIsOpen}>
        <Modal.Section>
          <CopyText customClass="streaming-analysis__reset-modal-text">
            Bei Erstellung eines neuen Streaming-Checks werden alle Produkte,
            welche dem Angebotstool durch den Streaming-Check hinzugefügt
            wurden, aus dem Angebot entfernt. Willst du wirklich fortfahren?
          </CopyText>
          <Container customClass="streaming-analysis__reset-modal-btns-wrapper">
            <Button
              customClass="streaming-analysis__reset-modal-btn"
              onClick={() => {
                resetStreamingAnalysis();
                toggleResetModal();
              }}
            >
              Ja
            </Button>
            <Button
              customClass="streaming-analysis__reset-modal-btn"
              onClick={toggleResetModal}
              variants={Button.Variant.outline}
            >
              Nein
            </Button>
          </Container>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default StreamingAnalysis;
