import React, { useState } from "react";
import {
  Asterisk,
  CopyText,
  Flex,
  Heading,
  Modal,
  TextLink,
} from "@dtpk-cc/components";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import { Bandwidths } from "core/entities/Product/Tariff/ITariff";
import { ExpansionArea } from "core/entities/Applications";
import { PersonalDataPresenter } from "core/presenter/PencilSelling/PersonalDataPresenter";
import { useStores } from "stores";
import { trackClick, trackLink } from "../../../../../helpers/reactTracking";
import ButtonGroups from "../../../../../elements/ButtonGroups";

export const FIBER_IS_ACTIVE_DATA_SETTINGS = [
  { value: true, title: "Ja" },
  { value: false, title: "Nein" },
];

const LandlineBusinessCases = ({
  handleSettingsChange,
  trackingContext,
  isWarningModalOpen,
  setIsWarningModalOpen,
}) => {
  const { offerStore, cartStore } = useStores();

  const landlineSettings = offerStore.getLandlineSettings();
  const {
    bandwidth: landlineBandwidth,
    businessCase: landlineBusinessCaseKey,
    fiber: isFiber,
    expansionArea,
  } = landlineSettings;

  const [isAsteriskModalOpen, setIsAsteriskModalOpen] = useState(false);

  const presenter = new PersonalDataPresenter(isFiber);

  const openAsteriskModal = (isOpen) => {
    setIsAsteriskModalOpen(isOpen);
  };

  const updateLandlineSettings = (newSettings) => {
    cartStore.resetCart(PortfolioKeys.LANDLINE);
    offerStore.setLandlineSettings({
      ...landlineSettings,
      ...newSettings,
    });
    setIsWarningModalOpen(false);
  };

  const setBandwidth = (value, expansionAreaValue) => () => {
    updateLandlineSettings({
      bandwidth: Number(value),
      expansionArea: expansionAreaValue,
    });
  };

  const setFiber = (fiberIsActive) => {
    updateLandlineSettings({
      bandwidth: Number(fiberIsActive ? Bandwidths.GIGA : Bandwidths.XL),
      expansionArea: ExpansionArea.telekom,
      fiber: fiberIsActive,
    });
  };

  const getBandwidth = (value) =>
    presenter.bandwidths.find((bandwidth) => bandwidth.name === value);

  const setBusinessCase = (value) => {
    updateLandlineSettings({
      businessCase: value,
    });
  };

  return (
    <>
      <div
        className="
                personal-offer__section
                personal-offer__section--padded
                personal-offer__section--with-bg
                personal-offer__section--full-height
                personal-offer__section--with-radius"
      >
        <Flex
          flexDirection="row"
          customClass="personal-offer__section__list-wrapper personal-offer__input-field personal-offer__input-field--spacer"
          alignItems="center"
          wrap
        >
          <span className="personal-offer__input-label personal-offer__input-label--fw">
            <b>{presenter.landlineBusinessCase.label}</b>
            <Asterisk
              data-testid="asterisk-info"
              onClick={() => {
                openAsteriskModal(true);
                trackClick("festnetz.asterisk", trackingContext);
              }}
            >
              <b>Info</b>
            </Asterisk>
          </span>
          <ButtonGroups
            inputTestId="landlineBusinessCase-1"
            wrapperClasses="personal-offer__main-list-wrapper--main-width personal-offer__main-list-wrapper--bt"
            label="Geschäftsfall"
            name="landlineBusinessCase"
            itemsAmountLg={2}
            items={presenter.landlineBusinessCase.items.slice(0, 2)}
            getValue={(businessCase) => businessCase.key}
            getIsChecked={(businessCase) =>
              businessCase.key === landlineBusinessCaseKey
            }
            displayItemContent={(businessCase) => businessCase.name}
            onChange={(value) => {
              handleSettingsChange({
                value,
                isLandline: true,
                callback: setBusinessCase,
              });
              trackClick(value, trackingContext);
            }}
          />

          <ButtonGroups
            inputTestId="landlineBusinessCase-2"
            wrapperClasses="personal-offer__main-list-wrapper--main-width
                   personal-offer__main-list-wrapper--reversed personal-offer__main-list-wrapper--bt"
            label="Geschäftsfall Bestandskunde"
            name="landlineBusinessCase-2"
            itemsAmountLg={2}
            items={presenter.landlineBusinessCase.items.slice(2, 5)}
            getValue={(businessCase) => businessCase.key}
            getIsChecked={(businessCase) =>
              businessCase.key === landlineBusinessCaseKey
            }
            displayItemContent={(businessCase) => businessCase.name}
            onChange={(value) => {
              handleSettingsChange({
                value,
                isLandline: true,
                callback: setBusinessCase,
              });
              trackClick(value, trackingContext);
            }}
          />
        </Flex>
        <hr className="personal-offer__settings-delimiter" />
        <Flex
          flexDirection="row"
          customClass="personal-offer__section__list-wrapper personal-offer__input-field personal-offer__input-field--spacer"
          wrap
        >
          <Flex flexDirection="column">
            <ButtonGroups
              inputTestId="fiberquestion"
              key={`fiber${isWarningModalOpen}`}
              wrapperClasses="personal-offer__fiber-list-wrapper personal-offer__main-list-wrapper--half-width"
              id={presenter.fiberQuestionTitle}
              label={presenter.fiberQuestionTitle}
              name="fiberquestion"
              items={FIBER_IS_ACTIVE_DATA_SETTINGS}
              getValue={(fiberItem) => fiberItem.value}
              getIsChecked={(fiberItem) =>
                fiberItem.value === landlineSettings.fiber
              }
              displayItemContent={(fiberItem) => fiberItem.title}
              onChange={(value) => {
                handleSettingsChange({
                  value,
                  isLandline: true,
                  callback: setFiber,
                });
                trackClick(
                  `glasfaser ${value ? "Ja" : "Nein"}`,
                  trackingContext
                );
              }}
            />
            <Flex
              customClass={`personal-offer__bandwidth-list ${
                isFiber ? "personal-offer__bandwidth-list--disabled" : ""
              }`}
              flexDirection="column"
              wrap
            >
              <CopyText customClass="personal-offer__label-title">
                Verfügbare Festnetz-Bandbreite
              </CopyText>
              <ButtonGroups
                inputTestId="settings-size"
                label={presenter.bandwidthData.label}
                name="settings-size"
                items={presenter.bandwidths}
                onChange={(value) => {
                  handleSettingsChange({
                    value: getBandwidth(value).size,
                    isLandline: true,
                    callback: setBandwidth(
                      Bandwidths[value],
                      ExpansionArea.telekom
                    ),
                  });
                  trackClick(`festnetz-bandbreite ${value}`, trackingContext);
                }}
                getIsChecked={(item) =>
                  item.size === landlineSettings.bandwidth &&
                  expansionArea === ExpansionArea.telekom
                }
                getValue={(item) => item.name}
                displayItemContent={(item) => item.name}
                isDisabled={() => isFiber}
              />
            </Flex>
            {!isFiber && (
              <Flex
                customClass="personal-offer__bandwidth-list"
                flexDirection="column"
                wrap
              >
                <ButtonGroups
                  inputTestId="settings-regio-size"
                  name="settings-regio-size"
                  label={presenter.bandwidthData.regioLabel}
                  items={presenter.regioBandwidths}
                  getValue={(item) => item.name}
                  getIsChecked={(item) =>
                    item.size === landlineBandwidth &&
                    landlineSettings.expansionArea === ExpansionArea.regio
                  }
                  displayItemContent={(item) => item.name}
                  onChange={(value) => {
                    handleSettingsChange({
                      value: getBandwidth(value).size,
                      isLandline: true,
                      callback: setBandwidth(
                        Bandwidths[value],
                        ExpansionArea.regio
                      ),
                    });

                    trackClick(`regio-ausbaugebiet ${value}`, trackingContext);
                  }}
                  isDisabled={() => isFiber}
                />
              </Flex>
            )}

            <TextLink
              customClass="personal-offer__speed-link"
              title="Ausbaukarte"
              // eslint-disable-next-line max-len
              href="https://ebs07.telekom.de/cet/front/slideseleven/ausbaukarte"
              target="_blank"
              onClick={() => trackLink("Ausbaukarte", "basisdaten")}
            >
              Ausbaukarte
            </TextLink>
          </Flex>
        </Flex>
      </div>
      <Modal
        isOpen={isAsteriskModalOpen}
        afterClose={() => openAsteriskModal(false)}
      >
        <Modal.Section>
          <Modal.Heading>Geschäftsfälle Festnetz</Modal.Heading>
          <Heading
            tag="h3"
            variants={[Heading.Variant.quaternary]}
            dangerouslySetInnerHTML={{
              __html: presenter.landlineBusinessCase.subline,
            }}
          />
          {presenter.landlineBusinessCase.tooltips.map((tooltip) => (
            <React.Fragment key={tooltip.title}>
              <Heading
                tag="h3"
                customClass="personal-offer__legal-headline"
                variants={[
                  Heading.Variant.quaternary,
                  Heading.Variant.highlight,
                ]}
              >
                {tooltip.title}
              </Heading>
              {tooltip.content.map((copy, index) => (
                <CopyText key={index.toString()}>{copy}</CopyText>
              ))}
            </React.Fragment>
          ))}
        </Modal.Section>
      </Modal>
    </>
  );
};

export default LandlineBusinessCases;
