import { LatLng, LatLngBounds, Point } from "leaflet";
import { InfrastructureKind } from "../api/types";

export type MapLayerOptionsKey = InfrastructureKind | "outdoorNetworkCoverage";

export type MapLayerOptions = Record<MapLayerOptionsKey, boolean>;

export type MapViewport = {
  bounds: LatLngBounds;
  size: Point;
  zoom: number;
};

export type IconData = {
  icon: string;
  description: string;
};

export type LocationPoint = LatLng | null;

export enum LocationPointKey {
  start = "start",
  end = "end",
}

export enum RouteTransportation {
  drive = "drive",
  approximated_transit = "approximated_transit",
  walk = "walk",
  bicycle = "bicycle",
}

export enum MapSettingsColorScheme {
  magenta = "magenta",
  colorful = "colorful",
}

export enum MapSettingsBackgroundColorScheme {
  default = "default",
  topografic = "topografic",
  blackWhite = "blackWhite",
}

export enum MapSettingsMode {
  viewport = "viewport",
  address = "address",
  route = "route",
}

export type MapSettingsManager = {
  mapSettings: MapSettings;
  mapSettingsSetter: MapSettingsSetter;
  mapSettingsMode: MapSettingsMode;
};

export type MapSettings = {
  layerOptions: MapLayerOptions;
  locationPoints: Record<LocationPointKey, LocationPoint>;
  routeTransportation: RouteTransportation;
  viewport: MapViewport | null;
  colorScheme: MapSettingsColorScheme;
  backgroundColorScheme: MapSettingsBackgroundColorScheme;
  hideInfrastructure: boolean;
};

export type MapSettingsSetter = {
  toggleMapLayerOptions: (optionKeys: MapLayerOptionsKey[]) => void;
  setOutdoorNetworkCoverage: (isOutdoor: boolean) => void;
  setLocationPoint: (key: LocationPointKey, point: LocationPoint) => void;
  setViewport: (viewport: MapViewport) => void;
  setRouteTransportation: (transportation: RouteTransportation) => void;
  setColorScheme: (scheme: MapSettingsColorScheme) => void;
  setBackgroundColorScheme: (scheme: MapSettingsBackgroundColorScheme) => void;
  setEnvironment: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
