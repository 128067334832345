// extracted by mini-css-extract-plugin
var _1 = "tariff-settings-module-active-aRcB9";
var _2 = "tariff-settings-module-content-_nxvW";
var _3 = "tariff-settings-module-expanded-uN4zr";
var _4 = "tariff-settings-module-item-Pg2by";
var _5 = "tariff-settings-module-radioButtons-XPi3x";
var _6 = "tariff-settings-module-smartphone-F4QDk";
var _7 = "tariff-settings-module-smartphoneName-shCUo";
var _8 = "tariff-settings-module-smartphonePrice-LFSiP";
var _9 = "tariff-settings-module-tariffSettings-Bpea5";
var _a = "tariff-settings-module-tile-dd5G3";
var _b = "tariff-settings-module-title-ZYC5K";
var _c = "tariff-settings-module-toggleBtn-HjtC_";
export { _1 as "active", _2 as "content", _3 as "expanded", _4 as "item", _5 as "radioButtons", _6 as "smartphone", _7 as "smartphoneName", _8 as "smartphonePrice", _9 as "tariffSettings", _a as "tile", _b as "title", _c as "toggleBtn" }
