import type { IPromotionSections } from "core/entities/PencilSelling/IPromotionSections";
import { Group, PortfolioKeys } from "../../Product/IProduct";
import { ICartConfigurationType } from "../Configurations/IConfigurations";
import {
  BusinessCasesLandline,
  BusinessCasesMobile,
} from "../../Product/Tariff/ITariff";
import { IBusinessCasesTypes } from "./IBaseOffer";

export const initialCustomPromotions: IPromotionSections = {
  [PortfolioKeys.MOBILE]: [],
  [PortfolioKeys.LANDLINE]: [],
  [Group.card]: [],
};

export const initialBusinessCases: IBusinessCasesTypes = {
  [ICartConfigurationType.MOBILE]: BusinessCasesMobile.NEUVERTRAG,
  [ICartConfigurationType.LANDLINE]: BusinessCasesLandline.NEUKUNDE,
  [ICartConfigurationType.CARD]: BusinessCasesMobile.NEUVERTRAG,
};
