import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ISetCardQuantity } from "./ISetCardQuantity";

export class SetCardQuantity implements ISetCardQuantity {
  constructor(private entity: ITariff, private legacyEntity: ILegacyTariff) {}

  execute(key: string, quantity: number, isLegacy?: boolean): void {
    const currentEntity = isLegacy ? this.legacyEntity : this.entity;
    currentEntity.setQuantity(key, quantity);
  }
}
