import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { IGetValueCalculatedByPeriod } from "./IGetValueCalculatedByPeriod";

export class GetValueCalculatedByPeriod implements IGetValueCalculatedByPeriod {
  constructor(private readonly entity: ITariff) {}

  execute(value: number, period?: number): number {
    return this.entity.getValueCalculatedByPeriod(value, period);
  }
}
