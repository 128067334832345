// extracted by mini-css-extract-plugin
var _1 = "secondary-card-section-module-active-qxnNW";
var _2 = "secondary-card-section-module-buttonCol-uZtEL";
var _3 = "secondary-card-section-module-dataVolume-ePDYA";
var _4 = "secondary-card-section-module-disabled-q4nNN";
var _5 = "secondary-card-section-module-gridRow-Mkaia";
var _6 = "secondary-card-section-module-limited-mqstb";
var _7 = "secondary-card-section-module-price-oAFIs";
var _8 = "secondary-card-section-module-priceCol-CeybK";
var _9 = "secondary-card-section-module-priceSummary-B0PUx";
var _a = "secondary-card-section-module-secondaryCardSection-vUahN";
var _b = "secondary-card-section-module-tile-GJvkI";
export { _1 as "active", _2 as "buttonCol", _3 as "dataVolume", _4 as "disabled", _5 as "gridRow", _6 as "limited", _7 as "price", _8 as "priceCol", _9 as "priceSummary", _a as "secondaryCardSection", _b as "tile" }
