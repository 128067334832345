import React, { forwardRef } from "react";
import { Input as DefaultInput } from "@dtpk-cc/components";

import * as styles from "./input.module.scss";

type BaseInputProps = Partial<{
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
  onKeyUp: (event: React.KeyboardEvent) => void;
  onClick: () => void | null;
  placeholder: string;
  id: string;
  name: string;
  customClass?: string;
  wrapperCustomClass?: string;
  required?: boolean;
  invalidMessage?: string;
  requiredMessage?: string;
  isValid?: boolean;
  maxLength?: number;
}>;

const Input = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      customClass = "",
      wrapperCustomClass = "",
      onKeyUp = () => {},
      value,
      onChange = () => {},
      placeholder,
      onClick = null,
      onKeyPress = () => {},
      id,
      name,
      invalidMessage = "",
      requiredMessage = "",
      required = false,
      isValid = true,
      maxLength,
    }: BaseInputProps,
    ref
  ) => (
    <DefaultInput
      maxLength={maxLength}
      invalidMessage={invalidMessage}
      id={id}
      name={name}
      onClick={onClick}
      tabIndex={0}
      customClass={`${styles.inputWrapper} ${wrapperCustomClass}`}
      inputCustomClass={`${styles.input} ${customClass}`}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      ref={ref}
      required={required}
      isValid={isValid}
      requiredMessage={requiredMessage}
    >
      {placeholder}
    </DefaultInput>
  )
);

export default Input;
