import { ProductsRepository } from "core/repositories/ProductsRepository/ProductsRepository";
import { Tariff } from "core/entities/MagentaNext/Tariff/Tariff";
import { LegacyTariff } from "core/entities/MagentaNext/LegacyTariff/LegacyTariff";
import { GetMagentaMobileCards } from "core/usecases/MagentaNext/GetMagentaMobileCards/GetMagentaMobileCards";
import { GetActiveCards } from "core/usecases/MagentaNext/GetActiveCards/GetActiveCards";
import { GetTariffTotalPrice } from "core/usecases/MagentaNext/GetTariffTotalPrice/GetTariffTotalPrice";
import { GetTariffTotalQuantity } from "core/usecases/MagentaNext/GetTariffTotalQuantity/GetTariffTotalQuantity";
import { SetActiveMagentaMobileCard } from "core/usecases/MagentaNext/SetActiveMagentaMobileCard/SetActiveMagentaMobileCard";
import { SetActiveCard } from "core/usecases/MagentaNext/SetActiveCard/SetActiveCard";
import { SetLegacyTariffCustomPrice } from "core/usecases/MagentaNext/SetLegacyTariffCustomPrice/SetLegacyTariffCustomPrice";
import { GetCards } from "core/usecases/MagentaNext/GetCards/GetCards";
import { GetPromotionsTotalPrice } from "core/usecases/MagentaNext/GetPromotionsTotalPrice/GetPromotionsTotalPrice";
import { GetPromotions } from "core/usecases/MagentaNext/GetPromotions/GetPromotions";
import { SetPromotions } from "core/usecases/MagentaNext/SetPromotions/SetPromotions";
import { SetActiveAdditionCard } from "core/usecases/MagentaNext/SetActiveAdditionCard/SetActiveAdditionCard";
import { DeleteCard } from "core/usecases/MagentaNext/DeleteCard/DeleteCard";
import { SetCardQuantity } from "core/usecases/MagentaNext/SetCardQuantity/SetCardQuantity";
import { SetCardPrice } from "core/usecases/MagentaNext/SetCardPrice/SetCardPrice";
import { GetHasBenefit } from "core/usecases/MagentaNext/GetHasBenefit/GetHasBenefit";
import { SetHasBenefit } from "core/usecases/MagentaNext/SetHasBenefit/SetHasBenefit";
import { FetchCardsUseCase } from "core/usecases/MagentaNext/FetchCards/FetchCards";
import { SetActiveFamilyCard } from "core/usecases/MagentaNext/SetActiveFamilyCard/SetActiveFamilyCard";
import { SetCardName } from "./usecases/MagentaNext/SetCardName/SetCardName";
import { GetIsCardLimitReached } from "./usecases/MagentaNext/GetIsCardLimitReached/GetIsCardLimitReached";
import { ResetMagentaNextCalculator } from "./usecases/MagentaNext/ResetMagentaNextCalculator/ResetMagentaNextCalculator";
import { GetTariffExportUseCase } from "./usecases/MagentaNext/GetTariffExport/GetTariffExport";
import { GetLegacySummaryCardUseCase } from "./usecases/MagentaNext/GetLegacySummaryCard/GetLegacySummaryCard";
import { GetBenefitsDataUseCase } from "./usecases/MagentaNext/GetBenefitsData/GetBenefitsData";
import { GetCalculatorPDFUseCase } from "./usecases/MagentaNext/GetCalculatorPDF/GetCalculatorPDF/GetCalculatorPDF";
import { SetCardDataVolumeUseCase } from "./usecases/MagentaNext/SetCardDataVolume/SetCardDataVolume";
import { GetLoyaltyBonus } from "./usecases/MagentaNext/GetLoyaltyBonus/GetLoyaltyBonus";
import { SetLoyaltyBonus } from "./usecases/MagentaNext/SetLoyaltyBonus/SetLoyaltyBonus";
import { GetValueCalculatedByPeriod } from "./usecases/MagentaNext/GetValueCalculatedByPeriod/GetValueCalculatedByPeriod";
import { GetHasProvisionFee } from "./usecases/MagentaNext/GetHasProvisionFee/GetHasProvisionFee";
import { SetHasProvisionFee } from "./usecases/MagentaNext/SetHasProvisionFee/SetHasProvisionFee";
import { GetSettingsUseCase } from "./usecases/MagentaNext/GetSettings/GetSettings";
import { SetSettingsUseCase } from "./usecases/MagentaNext/SetSettings/SetSettings";
import { GetMobileTariffsLevelsUseCase } from "./usecases/MagentaNext/GetMobileTariffsLevels/GetMobileTariffsLevels";
import { GetSavingsTotalPriceUseCase } from "./usecases/MagentaNext/GetSavingsTotalPrice/GetSavingsTotalPrice";
import { SetCardSectionItem } from "./usecases/MagentaNext/SetCardSectionItem/SetCardSectionItem";
import { GetCardDetailSection } from "./usecases/MagentaNext/GetCardDetailSection/GetCardDetailSection";
import { SubmitPdfQrCodeUseCase } from "./usecases/MagentaNext/SubmitPdfQrCode/SubmitPdfQrCode";

let httpFetch: typeof fetch = () => Promise.reject();
if (window.fetch) httpFetch = window.fetch.bind(window) as typeof fetch;

const MAX_AMOUNT_PRODUCTS = {
  productKey: 10,
} as const;

export const tariff = new Tariff(MAX_AMOUNT_PRODUCTS);
export const legacyTariff = new LegacyTariff();
export const productsRepository = new ProductsRepository(httpFetch);

export const useCases = {
  fetchCardsUseCase: new FetchCardsUseCase(
    productsRepository,
    tariff,
    legacyTariff
  ),
  getIsCardLimitReached: new GetIsCardLimitReached(tariff),
  getActiveCards: new GetActiveCards(tariff, legacyTariff),
  getCards: new GetCards(tariff, legacyTariff),
  getPromotionsTotalPrice: new GetPromotionsTotalPrice(tariff),
  getPromotions: new GetPromotions(tariff),
  setPromotions: new SetPromotions(tariff),
  setActiveCard: new SetActiveCard(tariff, legacyTariff),
  setCardPrice: new SetCardPrice(legacyTariff),
  setActiveMagentaMobileCard: new SetActiveMagentaMobileCard(tariff),
  getMagentaMobileCards: new GetMagentaMobileCards(tariff, legacyTariff),
  getTariffTotalPrice: new GetTariffTotalPrice(tariff, legacyTariff),
  getTariffTotalQuantity: new GetTariffTotalQuantity(tariff, legacyTariff),
  setLegacyTariffCustomPrice: new SetLegacyTariffCustomPrice(legacyTariff),
  setActiveAdditionCard: new SetActiveAdditionCard(tariff),
  deleteCard: new DeleteCard(legacyTariff),
  setCardQuantity: new SetCardQuantity(tariff, legacyTariff),
  setCardName: new SetCardName(legacyTariff),
  getHasBenefit: new GetHasBenefit(tariff),
  setHasBenefit: new SetHasBenefit(tariff),
  setActiveFamilyCard: new SetActiveFamilyCard(tariff),
  resetMagentaNextCalculator: new ResetMagentaNextCalculator(
    tariff,
    legacyTariff
  ),
  getTariffExportUseCase: new GetTariffExportUseCase(tariff, legacyTariff),
  getLegacySummaryCardUseCase: new GetLegacySummaryCardUseCase(legacyTariff),
  getBenefitsDataUseCase: new GetBenefitsDataUseCase(tariff),
  getCalculatorPDFUseCase: new GetCalculatorPDFUseCase(productsRepository),
  setCardDataVolumeUseCase: new SetCardDataVolumeUseCase(legacyTariff),
  getLoyaltyBonus: new GetLoyaltyBonus(tariff),
  setLoyaltyBonus: new SetLoyaltyBonus(tariff),
  getValueCalculatedByPeriod: new GetValueCalculatedByPeriod(tariff),
  getHasProvisionFee: new GetHasProvisionFee(tariff),
  setHasProvisionFee: new SetHasProvisionFee(tariff),
  getSettingsUseCase: new GetSettingsUseCase(tariff),
  setSettingsUseCase: new SetSettingsUseCase(tariff),
  getMobileTariffsLevelsUseCase: new GetMobileTariffsLevelsUseCase(tariff),
  getSavingsTotalPrice: new GetSavingsTotalPriceUseCase(tariff),
  setCardSectionItem: new SetCardSectionItem(tariff),
  getCardDetailSection: new GetCardDetailSection(tariff),
  submitPdfQrCodeUseCase: new SubmitPdfQrCodeUseCase(productsRepository),
} as const;
