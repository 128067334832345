import React, { useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { Heading } from "@dtpk-cc/components";
import LottieVisual from "elements/LottieVisual";
import DialogBox from "./DialogBox";
import NotifyHintBox from "./NotifyHintBox";
import "@dtpk-cc/components/dist/components/HintBox/HintBox.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";

import HomeConfiguratorOptions from "./HomeConfiguratorOptions";
import ConfiguratorRecommendations from "./ConfiguratorRecommendations";
import {
  ConfigParamsKeys,
  RouterType,
  REPEATERS_AMOUNT_MAX,
} from "../../../core/entities/PencilSelling/HomeConfigurator/IHomeConfigurator";

import {
  configParamsMap,
  arrayOfRouterSegments,
  dialogBoxContent,
} from "./const";

import * as styles from "./home-configurator.module.scss";
import PencilSellingToolbarCloseBtn from "../../../components/PencilSellingToolbar/PencilSellingToolbarCloseBtn";
import { useStores } from "../../../stores/PrivateClient";

const dialogBoxDefaultSetup = {
  isActive: true,
  submitBtnHandler: () => {},
  cancelBtnHandler: () => {},
  content: {
    title: "",
    mainText: "",
    submitBtnText: "",
    cancelBtnText: "",
  },
};

const trackingContext = "heimnetz";

type HomeConfiguratorProps = {
  // TODO Temporary until PK redesign is finished
  applyToolConfig?: () => void;
  closeHandler: () => void;
};

const HomeConfigurator = ({ closeHandler }: HomeConfiguratorProps) => {
  const { homeConfiguratorStore } = useStores();
  const {
    routerIndex,
    squareMetersValue: squareMeters,
    activeFloorVariant,
  } = homeConfiguratorStore;
  const [dialogBoxIsActive, setDialogBoxIsActive] = useState(false);
  const [animationSource, setAnimationSource] = useState(null);
  const [dialogBoxParams, setDialogBoxParams] = useState({
    dialogMode: { ...dialogBoxDefaultSetup },
    // Confirm mode property is defined, to handle the dialogBox (hintBox) layout when we have only one 'schließen' button.
    confirmMode: { ...dialogBoxDefaultSetup },
  });

  const [squareMetersInputValue, setSquareMetersInputValue] =
    useState(squareMeters);
  const [averageSquareMeters, setAverageSquareMeters] = useState(
    squareMeters / activeFloorVariant.value
  );
  const [configuratorSettings, setConfiguratorSettings] = useState({
    squareMeters: homeConfiguratorStore.squareMetersKey,
    floorVariant: activeFloorVariant.key,
  });

  const hintBoxIsActive = useMemo(() => {
    const typeIsOfTelekomRouter =
      arrayOfRouterSegments[routerIndex].key === RouterType.SMART4 ||
      arrayOfRouterSegments[routerIndex].key === RouterType.PRO_PLUS;
    return (
      averageSquareMeters <= 60 &&
      activeFloorVariant.value === 1 &&
      typeIsOfTelekomRouter
    );
  }, [averageSquareMeters, activeFloorVariant, routerIndex]);

  const closeDialogBox = useCallback(() => {
    setDialogBoxIsActive(false);
  }, []);

  const setNoneRouterTypeDialogBox = useCallback(() => {
    // Sets Router Device type to "I need one router/no-router"
    homeConfiguratorStore.setRouterIndex(0);
    closeDialogBox();
  }, [closeDialogBox, homeConfiguratorStore]);

  const inapprRouterConfigDisplayHandler = useCallback(() => {
    // Set params for dialog box
    setDialogBoxParams((prev) => ({
      dialogMode: {
        ...prev.dialogMode,
        isActive: false,
      },
      confirmMode: {
        isActive: true,
        submitBtnHandler: setNoneRouterTypeDialogBox,
        cancelBtnHandler: closeDialogBox,
        content: dialogBoxContent.invalidTypeOfRouter,
      },
    }));
    setDialogBoxIsActive(true);
  }, [setNoneRouterTypeDialogBox, closeDialogBox]);

  const getSquareMetersKey = useCallback((currentAverageSquareMeters) => {
    if (currentAverageSquareMeters <= 60) {
      return ConfigParamsKeys.LESS_THAN_60;
    }

    if (currentAverageSquareMeters > 60 && currentAverageSquareMeters <= 100) {
      return ConfigParamsKeys.MORE_THAN_60;
    }

    if (currentAverageSquareMeters > 100 && currentAverageSquareMeters <= 200) {
      return ConfigParamsKeys.MORE_THAN_100;
    }

    if (currentAverageSquareMeters > 200 && currentAverageSquareMeters <= 300) {
      return ConfigParamsKeys.MORE_THAN_200;
    }

    return ConfigParamsKeys.MORE_THAN_300;
  }, []);

  const totalRepeaterAmount = useMemo(() => {
    const currentRepeaterAmount =
      configParamsMap[configuratorSettings.squareMeters][
        configuratorSettings.floorVariant
      ].baseRepeatersAmount;

    if (
      arrayOfRouterSegments[routerIndex].key === RouterType.OTHER &&
      currentRepeaterAmount < REPEATERS_AMOUNT_MAX
    ) {
      return currentRepeaterAmount + 1;
    }

    return currentRepeaterAmount;
  }, [configuratorSettings, routerIndex]);

  useEffect(() => {
    const currentAverageSquareMeters = squareMeters / activeFloorVariant.value;
    const squareMetersKey = getSquareMetersKey(currentAverageSquareMeters);
    setConfiguratorSettings({
      squareMeters: squareMetersKey,
      floorVariant: activeFloorVariant.key,
    });
    setAverageSquareMeters(currentAverageSquareMeters);
    homeConfiguratorStore.setSquareMetersKey(squareMetersKey);
    if (
      currentAverageSquareMeters > 100 &&
      activeFloorVariant.value === 4 &&
      arrayOfRouterSegments[routerIndex].key === RouterType.OTHER
    ) {
      inapprRouterConfigDisplayHandler();
    } else {
      closeDialogBox();
    }
  }, [
    closeDialogBox,
    inapprRouterConfigDisplayHandler,
    squareMeters,
    activeFloorVariant,
    getSquareMetersKey,
    routerIndex,
    homeConfiguratorStore,
  ]);

  useEffect(() => {
    setAnimationSource(
      configParamsMap[configuratorSettings.squareMeters][
        configuratorSettings.floorVariant
      ].lottieFile
    );
  }, [configuratorSettings]);

  return (
    <div className={styles.outer}>
      <div className={styles.homeConfigurator}>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <PencilSellingToolbarCloseBtn
              customClass={styles.closeBtn}
              onClick={closeHandler}
            />
            <Heading
              customClass={styles.title}
              tag="h3"
              variants={[Heading.Variant.secondary, Heading.Variant.display]}
            >
              WLAN konfigurieren
            </Heading>
          </div>
          <div className={styles.inner}>
            <HomeConfiguratorOptions
              customClass={styles.optionsWrapper}
              squareMetersInputValue={squareMetersInputValue}
              setSquareMetersInputValue={setSquareMetersInputValue}
              trackingContext={trackingContext}
            />
            <div className={styles.animationContainer}>
              {animationSource && (
                <LottieVisual
                  customClass={styles.animationInner}
                  file={animationSource}
                  initialSegment={arrayOfRouterSegments[routerIndex].segment}
                />
              )}

              {hintBoxIsActive && <NotifyHintBox />}
              {dialogBoxIsActive && (
                <DialogBox
                  trackingContext={trackingContext}
                  activeMode={
                    dialogBoxParams.dialogMode.isActive
                      ? dialogBoxParams.dialogMode
                      : dialogBoxParams.confirmMode
                  }
                />
              )}
            </div>
          </div>
        </div>
        <ConfiguratorRecommendations
          customClass={styles.recommendationsWrapper}
          baseRepeatersAmount={totalRepeaterAmount}
          needRouter={
            arrayOfRouterSegments[routerIndex].key === RouterType.NEED_ROUTER
          }
          // TODO Temporary hidden
          // setSquareMetersInputValue={setSquareMetersInputValue}
          trackingContext={trackingContext}
        />
      </div>
    </div>
  );
};

export default observer(HomeConfigurator);
