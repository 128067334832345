import type { CardExport } from "core/entities/Product/Card/ICard";
import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { IGetActiveCards } from "./IGetActiveCards";

export class GetActiveCards implements IGetActiveCards {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(isLegacy?: boolean): CardExport {
    return isLegacy
      ? this.legacyEntity.getActiveCards()
      : this.entity.getActiveCards();
  }
}
