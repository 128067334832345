import React from "react";
import {
  Grid,
  CopyText,
  Heading,
  Price,
  List,
  Icon,
} from "@dtpk-cc/components";
import { LOYALTY_BONUS_TEXT } from "constants/const";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";

import TreasureImg from "../../../assets/images/treasure.png";

const LoyaltyBonusSection = ({
  openModal,
  reducedTariffMonthlyPrice,
  freeMonths,
  settings,
  _contractPeriod,
}) => (
  <>
    <Grid.Col s={6} m={12} l={12} xl={24}>
      <Heading
        customClass="summary__promotion-heading"
        variants={Heading.Variant.brand}
      >
        <img
          className="summary__logo"
          src={TreasureImg}
          alt="loyalty-bonus-icon"
        />
        Treuebonus
      </Heading>
    </Grid.Col>

    <Grid.Col s={6} m={12} l={12} xl={24}>
      <List>
        <Grid.Row>
          <Grid.Col
            customClass="summary__list-column"
            s={3}
            m={7}
            l={7}
            xl={14}
          >
            <List.Item>
              <List.Text>{LOYALTY_BONUS_TEXT}</List.Text>
            </List.Item>
          </Grid.Col>

          <Grid.Col s={2} m={3} l={3} xl={6}></Grid.Col>

          <Grid.Col s={2} m={2} l={2} xl={4}>
            <Price
              variants={[Price.Variant.benefit, Price.Variant.large]}
              // value={-(settings.loyaltyBonusValue / contractPeriod)}
              value={-settings.loyaltyBonusValue}
            />
          </Grid.Col>
        </Grid.Row>
      </List>
    </Grid.Col>

    <Grid.Col s={6} m={11} l={11} xl={23}>
      <CopyText customClass="cart-item-summary__loyalty-bonus-text">
        {
          <>
            <span>
              <b>
                Der rechnerische Durchschnittspreis für den gewählten Tarif
                {settings.hasProvisionFee
                  ? " (inkl.Bereitstellungspreis) "
                  : " "}
                beträgt unter Berücksichtigung des Treuebonus und
                MagentaEINS&nbsp;Vorteils{" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  für 24&nbsp;Monate{" "}
                  <CopyText
                    tag="span"
                    variants={[
                      CopyText.Variant.highlight,
                      CopyText.Variant.magenta,
                    ]}
                  >
                    {reducedTariffMonthlyPrice}
                  </CopyText>
                  <Icon
                    customClass="cart-item-summary__info-icon"
                    icon={AlertInformationDefault}
                    size={Icon.Size.small}
                    onClick={() => openModal(freeMonths)}
                    wrapperProps={{ style: { display: "inline" } }}
                  />
                </span>
              </b>
            </span>
          </>
        }
      </CopyText>
    </Grid.Col>
  </>
);

export default LoyaltyBonusSection;
