import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import SecondaryCardsIcon
  from "../../../../../assets/images/icons/pencil-selling-tool/navigation/config-list/secondary-cards-configuration.svg";
import MobileIcon
  from "../../../../../assets/images/icons/pencil-selling-tool/navigation/config-list/mobile-configuration.svg";
import LandlineIcon
  from "../../../../../assets/images/icons/pencil-selling-tool/navigation/config-list/festnetz-configuration.svg";

export const iconsMap = {
  [ICartConfigurationType.CARD]: SecondaryCardsIcon,
  [ICartConfigurationType.MOBILE]: MobileIcon,
  [ICartConfigurationType.LANDLINE]: LandlineIcon,
};

export const configurationsNameMap: {
  [key in ICartConfigurationType]?: {
    title: string;
    deleteModeTitle: string;
  };
} = {
  [ICartConfigurationType.CARD]: {
    title: "Übersicht aller Zusatzkarten Konfigurationen",
    deleteModeTitle: "Löschen der Zusatzkarten Konfigurationen",
  },
  [ICartConfigurationType.MOBILE]: {
    title: "Übersicht aller Mobilfunk Konfigurationen",
    deleteModeTitle: "Löschen der Mobilfunk Konfigurationen",
  },
  [ICartConfigurationType.LANDLINE]: {
    title: "Übersicht aller Festnetz Konfigurationen",
    deleteModeTitle: "Löschen der Festnetz Konfigurationen",
  },
};

export const TRACKING_CONTEXT = "konfiguration";