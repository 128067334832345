import React, { useState } from "react";
import { observer } from "mobx-react";
import { Container, Heading, Grid, CopyText, Modal } from "@dtpk-cc/components";
import CartSection from "components/CartSection";
import ExtensionSection from "components/Extension/ExtensionSection";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import TelekomSvg from "../../../../../assets/images/icons/telekom-icon.svg";
import { useStores } from "../../../../stores";

import "./summary.scss";
import { OfferSummaryPresenter } from "../../../../core/presenter/PencilSelling/OfferSummaryPresenter";

type SummaryProps = {
  presenter: OfferSummaryPresenter;
};

const Summary = ({ presenter }: SummaryProps) => {
  const { offerStore } = useStores();
  const offerExport = offerStore.export();

  const {
    landlineTariff,
    mobileTariff,
    landlineSettings,
    mobileSettings,
    devices,
    landlineOptions,
    mobileOptions,
    cards,
    multiSimCards,
    notes,
    benefits,
  } = offerExport.cartSummary;
  const summary = presenter.exportSummary();
  const showLoyaltyBonus = offerStore.showLoyaltyBonus();

  const [modalOpen, setModalOpen] = useState(false);
  const [monthsValue, setMonthsValue] = useState(0);

  const itemsInCart = () =>
    [
      ...[landlineTariff],
      ...[mobileTariff],
      ...landlineOptions,
      ...mobileOptions,
      ...devices,
      ...cards,
      ...multiSimCards,
    ].filter((e) => e != null);

  return (
    <Container variants={Container.Variant.padded} customClass="summary">
      <Container customClass="summary__print-container">
        <TelekomSvg className="summary__print-logo" />
        <CopyText
          variants={CopyText.Variant.magenta}
          customClass="summary__print-copy-text"
        >
          <b>Erleben, was verbindet.</b>
        </CopyText>
      </Container>
      <Heading
        tag="h1"
        variants={[Heading.Variant.secondary]}
        style={{ textAlign: "center" }}
      >
        Ihre persönliche Empfehlung
      </Heading>
      <CopyText customClass="summary__sub-heading">
        Vielen Dank für Ihr Interesse.
      </CopyText>

      <Grid customClass="summary__grid" padded>
        {/* TODO: refactor CartSection to use summary.sections */}
        {landlineTariff && (
          <CartSection
            headline="Zuhause"
            items={[landlineTariff]}
            settings={landlineSettings}
            // hotfix: remove landline benefit if mobile prepaid is activated
            benefit={
              benefits.isActive &&
              mobileTariff?.tariffWorld.key !==
                TariffWorldKeys.magenta_mobile_prepaid
                ? benefits.landlineBenefit
                : null
            }
            note={notes.landline}
          />
        )}
        {landlineTariff && landlineTariff.extension && (
          <ExtensionSection
            headline="Netflix Option"
            extensionItem={landlineTariff.extension}
          />
        )}

        <CartSection
          headline="Geräte"
          items={devices}
          settings={landlineSettings}
        />

        <CartSection
          headline="Extras Festnetz"
          items={landlineOptions}
          settings={landlineSettings}
        />

        {!!mobileTariff && (
          <CartSection
            headline="Mobilfunk"
            items={[mobileTariff]}
            settings={mobileSettings}
            benefit={benefits.isActive ? benefits.mobileBenefit : null}
            note={notes.mobile}
            showLoyaltyBonus={showLoyaltyBonus}
            openModal={(freeMonths) => {
              setModalOpen(true);
              setMonthsValue(freeMonths);
            }}
          />
        )}

        <CartSection
          headline="Mobilfunk"
          items={multiSimCards}
          settings={mobileSettings}
          isCardSection={true}
          withHeading={!mobileTariff}
        />

        <CartSection
          headline="Zusatzkarten"
          items={cards}
          settings={mobileSettings}
          isCardSection={true}
        />

        <CartSection
          headline="Extras Mobilfunk"
          items={mobileOptions}
          settings={mobileSettings}
        />

        <Container style={!itemsInCart().length ? { marginTop: "40px" } : null}>
          {summary.details.map((detail, index) => (
            <Grid.Row
              key={index.toString()}
              customClass="summary__row summary__row--hint"
            >
              <Grid.Col
                customClass="summary__hint-col"
                s={6}
                m={12}
                l={12}
                xl={24}
              >
                {detail.headline && (
                  <Heading tag="h2" variants={[Heading.Variant.tertiary]}>
                    {detail.headline}
                  </Heading>
                )}
                <CopyText customClass="summary__hint-copy-text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: detail.text,
                    }}
                  />
                </CopyText>
              </Grid.Col>
            </Grid.Row>
          ))}
        </Container>
      </Grid>

      <Modal
        isOpen={modalOpen}
        portalless
        afterClose={() => setModalOpen(false)}
        zIndex={150}
      >
        <Modal.Section>
          <Heading
            customClass="pst-intro__copy"
            tag="h4"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            Treuebonus auf der monatlichen Rechnung
          </Heading>
          <CopyText>
            <strong>
              Der rechnerische Durchschnittspreis erscheint nicht auf der
              Kundenrechnung. Der gesamte Treuebonus wird als Gutschrift
              angezeigt, der Bereitstellungspreis wird damit verrechnet und der
              Grundpreis entfällt für bis zu{" "}
              <CopyText
                tag="span"
                variants={[
                  CopyText.Variant.highlight,
                  CopyText.Variant.magenta,
                ]}
              >
                {monthsValue} {monthsValue === 1 ? "Monat" : "Monate"}.
              </CopyText>
            </strong>
          </CopyText>
          <CopyText>
            (weitere Leistungen / Optionen des Hauptvertrages oder Zusatzkosten
            wie Auslandstelefonie reduzieren zusätzlich den Treuebonus und
            können zu Abweichungen bei der Berechnung der Monate führen)
          </CopyText>
        </Modal.Section>
      </Modal>
    </Container>
  );
};

export default observer(Summary);
