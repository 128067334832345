import { useState } from "react";
import { LatLng } from "leaflet";
import { LocationPointKey, MapSettingsSetter } from "./types";

export const useManageRoute = (mapSettingsSetter: MapSettingsSetter) => {
  const [onlyAddress, setOnlyAddress] = useState<boolean>(true);

  const [selectedAddressField, setSelectedAddressField] =
    useState<LocationPointKey | null>(LocationPointKey.start);

  const handleSetLocationPoint = (key: LocationPointKey, point: LatLng) => {
    mapSettingsSetter.setLocationPoint(key, point);
  };

  const toggleRouteStatus = () => {
    if (onlyAddress) {
      setOnlyAddress(false);
      setSelectedAddressField(LocationPointKey.end);
    } else {
      setOnlyAddress(true);
      setSelectedAddressField(LocationPointKey.start);
      mapSettingsSetter.setLocationPoint(LocationPointKey.end, null);
    }
  };

  return {
    onlyAddress,
    selectedAddressField,
    setSelectedAddressField,
    handleSetLocationPoint,
    toggleRouteStatus,
  };
};
