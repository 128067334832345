import { useMemo, useState } from "react";
import { trackClick } from "helpers/reactTracking";
import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";

export type IStepActionHeaderNavMap = {
  title: string;
  onClick: () => void;
  disabled: boolean;
};

export const useStepActionHeaderNavMap = (
  trackingContext: string,
  configurationsStore: ConfigurationsEntity
) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const defaultNavMap = useMemo(
    () => [
      {
        title: "tarifkonfiguration",
        onClick: () => {
          setActiveTabIndex(0);
          trackClick("tarifkonfiguration.tab.wechseln", trackingContext);
        },
        disabled: false,
      },
      {
        title: "extras",
        onClick: () => {
          setActiveTabIndex(1);
          trackClick("extras.tab.wechseln", trackingContext);
        },
        disabled: false,
      },
    ],
    [trackingContext]
  );

  const getMobileNavMap = () => {
    defaultNavMap[defaultNavMap.length - 1].disabled = !configurationsStore
      .getActiveConfigurationChildren()
      .getMobileTariff();
    return defaultNavMap;
  };

  return {
    activeTabIndex,
    setActiveTabIndex,
    defaultNavMap,
    getMobileNavMap,
  };
};
