import React from "react";
import * as styles from "./radio-button.module.scss";

type RadioButtonInputProps = {
  value: string | number;
  name: string;
  id: string;
  inputTestId: string;
  checked: boolean;
  onChange: (value) => void;
  customClass?: string;
  disabled?: boolean;
};

const RadioButtonInput = ({
  customClass = "",
  disabled = false,
  value,
  name,
  id,
  inputTestId,
  checked,
  onChange,
}: RadioButtonInputProps) => (
  <>
    <input
      tabIndex={disabled ? -1 : 0}
      type="radio"
      value={value}
      name={name}
      onChange={(event) => {
        onChange(event.currentTarget.value);
      }}
      data-testid={inputTestId}
      id={id}
      checked={checked}
      className={styles.input}
    />
    <span className={`${styles.radioMark} ${customClass}`} />
  </>
);

export default RadioButtonInput;
