import React from "react";
import { Grid, Heading } from "@dtpk-cc/components";
import "./summary-section-header.scss";
import { summarySectionGridColData } from "../CartSection/summarySectionGridColData";

type ISummarySectionHeaderProps = {
  customClass?: string;
  headline: string;
};

const SummarySectionHeader: React.FC<ISummarySectionHeaderProps> = ({
  customClass = "",
  headline,
}) => (
  <Grid.Row customClass={`summary-section-header ${customClass}`}>
    <Grid.Col {...summarySectionGridColData.main}>
      <Heading
        tag="h2"
        variants={[Heading.Variant.secondary, Heading.Variant.brand]}
      >
        {headline}
      </Heading>
    </Grid.Col>
    <Grid.Col s={2} m={3} l={3} xl={6}>
      <Heading variants={Heading.Variant.tertiary}>Monatlich</Heading>
    </Grid.Col>

    <Grid.Col s={1} m={2} l={2} xl={4}>
      <Heading variants={Heading.Variant.tertiary}>Einmalig</Heading>
    </Grid.Col>
  </Grid.Row>
);

export default SummarySectionHeader;
