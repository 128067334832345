import L, { LatLng } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";
import { Tower } from "views/Map/api/types";
import MapLayerTowerTooltip from "../MapLayerTowerTooltip";

type MapLayerLocationProps = {
  locationPoint: LatLng | null;
  iconUrl: string;
  iconSize: [number, number];
  tower: Tower | null;
};

const MapLayerLocation = ({
  locationPoint,
  iconUrl,
  iconSize,
  tower,
}: MapLayerLocationProps) => {
  const customIcon = new L.Icon({
    iconUrl,
    iconSize,
    iconAnchor: [iconSize[0] / 2, iconSize[1]],
  });

  if (locationPoint) {
    return (
      <Marker position={locationPoint} icon={customIcon}>
        <MapLayerTowerTooltip tower={tower} />
      </Marker>
    );
  }

  return null;
};

export default MapLayerLocation;
