import { ICartConfigurationType } from "core/entities/PencilSelling/Configurations/IConfigurations";
import { IOption } from "core/entities/Product/Option/IOption";
import { useGetMappedProductsOptions } from "hooks/PencilSelling/useGetMappedProductsOptions";
import { useEffect, useMemo, useState } from "react";
import { useStores } from "stores/BusinessClient";

export type ConfigType = Extract<
  ICartConfigurationType,
  ICartConfigurationType.LANDLINE | ICartConfigurationType.MOBILE
>;

export const useAddonSelection = (configType: ConfigType) => {
  const { configurationsStore } = useStores();
  const [addons, setAddons] = useState<IOption[]>([]);

  const activeConfigurationChildren =
    configurationsStore.getActiveConfigurationChildren();

  const capitalizedConfig = `${configType
    .charAt(0)
    .toUpperCase()}${configType.slice(1)}` as "Mobile" | "Landline";

  const addonsInCart =
    activeConfigurationChildren[`get${capitalizedConfig}Options`]();

  const activeTariffKey = useMemo(
    () =>
      activeConfigurationChildren[`get${capitalizedConfig}Tariff`]()?.key ||
      null,
    [activeConfigurationChildren, capitalizedConfig]
  );

  const { getMappedProductsSummary } =
    useGetMappedProductsOptions(activeTariffKey);

  useEffect(() => {
    getMappedProductsSummary()
      .then((options) => {
        setAddons(options[`${configType}Options`]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [getMappedProductsSummary, configType]);

  return {
    addons,
    addonsInCart,
    activeTariffKey,
  };
};
