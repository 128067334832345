import type {
  IOffer,
  IMobileSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetMobileSettingsUseCase } from "./ISetMobileSettings";

export class SetMobileSettingsUseCase implements ISetMobileSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(settings: IMobileSettings): void {
    this.entity.setMobileSettings(settings);
  }
}
