export const NAV_TITLE_BASE_DATA = "Kundenangaben";
export const NAV_TITLE_MOBILE_TARIFF = "Mobilfunk";
export const NAV_TITLE_SECONDARY_CARDS = "Zusatzkarten";
export const NAV_TITLE_LANDLINE_TARIFF = "Festnetz";
export const NAV_TITLE_DEVICES = "Geräte";
export const NAV_TITLE_OFFER_FINALISATION = "Fertigstellen";
export const NAV_TITLE_SUMMARY = "Angebot";
export const NAV_TITLE_SKETCHBOARD = "Notizblock";
export const NAV_TITLE_TOOLS = "Alle Tools";
export const NAV_TITLE_RESET = "Neues Angebot";
export const NAV_TITLE_FULLSCREEN = "Vollbildmodus";
export const NAV_TITLE_HEIMNETZ = "Heimnetz";
