import React from "react";
import { Tile, Checkbox } from "@dtpk-cc/components";
import { trackClick } from "../../helpers/reactTracking";

import * as styles from "./provision-fee.module.scss";

type ProvisionFeeProps = {
  value: boolean;
  onChange: (hasProvisionFee: boolean) => void;
  trackingContext?: string;
  disabled?: boolean;
};

const ProvisionFee = ({
  value,
  onChange,
  trackingContext = "",
  disabled = false,
}: ProvisionFeeProps) => (
  <>
    <Tile
      variant="shadow"
      customClass={`${styles.provisionFee} ${styles.container}`}
    >
      <Tile.Content customClass={`${styles.provisionFee} ${styles.content}`}>
        <Checkbox
          disabled={disabled}
          checked={value}
          onChange={(hasProvisionFee) => {
            onChange(hasProvisionFee);
            trackClick("Bereitstellungspreis-berücksichtigen", trackingContext);
          }}
        >
          Bereitstellungspreis berücksichtigen
        </Checkbox>
      </Tile.Content>
    </Tile>
  </>
);

export default ProvisionFee;
