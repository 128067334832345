import React from "react";
import StreamingServicePriceResult from "../StreamingServicePriceResult";

type StreamingServiceResultItemProps = {
  keyValue: string;
  isActive: boolean;
  priceValue: string;
  priceIsDisplayed: boolean;
  withAdditionalInfo: boolean;
  serviceName: string;
};

const StreamingServiceResultItem = ({
  keyValue,
  isActive,
  priceValue,
  priceIsDisplayed,
  withAdditionalInfo,
  serviceName,
}: StreamingServiceResultItemProps) => (
  <div
    className={`
      currentVariant__service
      streaming-services__price
      ${isActive ? "" : "streaming-services__price--inactive"}
    `}
  >
    <StreamingServicePriceResult
      keyValue={keyValue}
      isActive={isActive}
      priceValue={priceValue}
      priceIsDisplayed={priceIsDisplayed}
      withAdditionalInfo={withAdditionalInfo}
      serviceName={serviceName}
    />
  </div>
);

export default StreamingServiceResultItem;
