type WindowScrollToProps = {
  left?: number;
  top?: number;
  behavior?: "auto" | "smooth";
};

export const windowScrollTo: (data?: WindowScrollToProps) => void = (
  data = { top: 0, left: 0 }
) => {
  window.scrollTo(data);
};
