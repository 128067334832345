import React from "react";
import * as styles from "./summary-configuration-section.module.scss";

type SummaryConfigurationSectionProps = {
  customClass?: string;
};

const SummaryConfigurationSection: React.FC<
  SummaryConfigurationSectionProps
> = ({ children, customClass = "" }) => (
  <div className={`${styles.container} ${customClass}`}>{children}</div>
);

export default SummaryConfigurationSection;
