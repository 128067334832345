import React, { useEffect, useRef } from "react";
import { Accordion, CopyText } from "@dtpk-cc/components";
import L from "leaflet";
import * as styles from "./map-legend.module.scss";
import {
  LocationIcons,
  NetworkQualityColorsMetaData,
  ShopIcons,
  TowerIcons,
} from "../constants/const";
import { IconData, MapSettingsColorScheme } from "../hooks/types";

type MapLegendProps = {
  colorScheme: MapSettingsColorScheme;
};

const MapLegend = ({ colorScheme }: MapLegendProps) => {
  const legendRef = useRef(null);

  useEffect(() => {
    if (legendRef.current) {
      L.DomEvent.disableClickPropagation(legendRef.current);
      L.DomEvent.disableScrollPropagation(legendRef.current);
    }
  }, []);

  const iconDataToLegendElement = (
    iconData: IconData,
    isLocationIcon?: boolean
  ) => ({
    iconDiv: (
      <img
        className={`${styles.legendIcon} ${
          isLocationIcon ? styles.locationIcon : ""
        }`}
        src={iconData.icon}
        alt={iconData.description}
      />
    ),
    description: iconData.description,
  });

  const MapLegendColumns = [
    NetworkQualityColorsMetaData.map((colorData) => ({
      iconDiv: (
        <div
          className={styles.legendQualityDiv}
          style={{
            backgroundColor: colorData.colorCode[colorScheme],
          }}
        />
      ),
      description: colorData.description,
    })),
    Object.values(TowerIcons).map((data) => iconDataToLegendElement(data)),
    Object.values(ShopIcons)
      .map((data) => iconDataToLegendElement(data))
      .concat(
        Object.values(LocationIcons).map((data) =>
          iconDataToLegendElement(data, true)
        )
      ),
  ];

  return (
    <Accordion customClass={styles.accordion} ref={legendRef}>
      <Accordion.Item customClass={styles.accordionItem} isExpanded={true}>
        <Accordion.Item.Toggle customClass={styles.accordionToggle}>
          <CopyText variants={CopyText.Variant.highlight}>Legende</CopyText>
        </Accordion.Item.Toggle>
        <Accordion.Panel customClass={styles.accordionPanel}>
          <div className={styles.legendColumn}>
            {MapLegendColumns.map((column, colIndex) => (
              <div className={styles.legendElementsContainer} key={colIndex}>
                {column.map((element, elIndex) => (
                  <div className={styles.legendElement} key={elIndex}>
                    <div className={styles.legendIconDiv}>
                      {element.iconDiv}
                    </div>
                    <CopyText>{element.description}</CopyText>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
export default MapLegend;
