import React from "react";
import {
  Badge,
  Button,
  Price,
  Heading,
  Container,
  CopyText,
} from "@dtpk-cc/components";
import { trackClick } from "helpers/reactTracking";

import "elements/TariffEntry/tariff-entry.scss";

import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import { Bandwidths, ITariff } from "core/entities/Product/Tariff/ITariff";

interface TariffEntityProps {
  active: boolean;
  legacyPrice?: number | null;
  price: number;
  size: keyof Bandwidths | string;
  displaySize: ITariff["size"];
  customClass?: string;
  displaySizeSuffix?: string;
  young?: boolean;
  subtitle: { tariffFeature: string; isHighlighted: boolean };
  description?: string;
  highlightDescription?: boolean;
  isHybrid?: boolean;
  isHighlighted?: boolean;
  highlightText?: string;
  toggleActive: () => void;
  disabled?: boolean;
  withPrice?: boolean;
  name: string;
  isBenefitActive?: boolean;
  trackingContext: string;
  loyaltyBonusPriceBadgeComp?: React.ReactNode;
  isLandline?: boolean;
  index: number;
  withHappyHour?: boolean;
}

const TariffEntry = ({
  active,
  legacyPrice,
  price,
  size,
  displaySize,
  customClass = "",
  displaySizeSuffix = "",
  young = false,
  subtitle = { tariffFeature: "", isHighlighted: false },
  description,
  highlightDescription = false,
  isHybrid,
  isHighlighted = false,
  highlightText = "Empfehlung",
  toggleActive,
  disabled = false,
  withPrice = true,
  name = "",
  isBenefitActive = false,
  trackingContext = "",
  loyaltyBonusPriceBadgeComp = null,
  isLandline,
  index,
  withHappyHour = false,
}: TariffEntityProps) => {
  const activeStyling = () => (active ? "tariff-entry__container--active" : "");
  const descriptionVariants =
    !isBenefitActive || Number(size) >= 4 || !highlightDescription
      ? [CopyText.Variant.additional]
      : [CopyText.Variant.additional, CopyText.Variant.magenta];

  return (
    <Container
      data-testid={`${
        isLandline ? "landline" : "mobile"
      }-tariff-entry-${index}`}
      customClass={`tariff-entry ${
        disabled ? "tariff-entry--disabled" : ""
      } ${customClass}`}
    >
      <Container
        customClass={`tariff-entry__container tariff-entry__container--${
          size ?? 1
        } ${activeStyling()} ${isHybrid && "tariff-entry__container--hybrid"}`}
      >
        {loyaltyBonusPriceBadgeComp}
        {isHighlighted && (
          <Badge
            customClass={`tariff-entry__badge ${
              withHappyHour
                ? "tariff-entry__badge--orange"
                : "tariff-entry__badge--blue"
            }`}
            variants={Badge.Variant.flag}
          >
            <Badge.Paragraph
              style={{ color: "#fff" }}
              fonts={Badge.Paragraph.Font.additionalHighlight}
            >
              {highlightText}
            </Badge.Paragraph>
          </Badge>
        )}
        <Container>
          <Heading
            customClass="tariff-entry__size-heading"
            variants={[Heading.Variant.tertiary, Heading.Variant.display]}
          >
            {displaySize}

            <span className="tariff-entry__young-heading">
              {" "}
              {displaySizeSuffix}
              {displaySizeSuffix && <br />}
              {young ? "Young" : ""}
            </span>
          </Heading>
          <CopyText
            variants={
              subtitle.isHighlighted && !disabled
                ? CopyText.Variant.magenta
                : undefined
            }
          >
            <b>{subtitle.tariffFeature}</b>

            {isHybrid && (
              <>
                <br />
                <b>+ 300 MBit/s (LTE)</b>
              </>
            )}
          </CopyText>
          {!!description && (
            <CopyText variants={descriptionVariants}>{description}</CopyText>
          )}
        </Container>
        <Container>
          {!!legacyPrice && legacyPrice !== price && (
            <Price
              value={legacyPrice}
              variants={[Price.Variant.scaled, Price.Variant.large]}
            />
          )}
          {withPrice && (
            <Price
              variants={Price.Variant.brand}
              style={{ marginBottom: "6px" }}
              value={price}
            />
          )}
          <Button
            role="radio"
            // @ts-ignore
            type="radio"
            customClass="tariff-entry__button"
            style={{ fontSize: "15px" }}
            onClick={() => {
              toggleActive();
              trackClick(`${name}`, trackingContext);
            }}
            data-testid="toggle-tariff-entry-is-active"
            variants={!active ? Button.Variant.outline : undefined}
            aria-label={`${name} ${active ? "ausgewählt" : "auswählen"}`}
          >
            {active ? "Ausgewählt" : "Auswählen"}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default TariffEntry;
