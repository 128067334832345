// extracted by mini-css-extract-plugin
var _1 = "fact-modal-module-defaultLayout-Az_j_";
var _2 = "fact-modal-module-factButton-sXugv";
var _3 = "fact-modal-module-factButtonIcon-_VJm7";
var _4 = "fact-modal-module-factModalBackdrop-fh2Wo";
var _5 = "fact-modal-module-factModalContentWrapper-BUSz9";
var _6 = "fact-modal-module-factModalFooter-jo_gs";
var _7 = "fact-modal-module-factModalImg-sDjcS";
var _8 = "fact-modal-module-factModalTextual-oGNoF";
var _9 = "fact-modal-module-factModalWrapper-qLWvX";
var _a = "fact-modal-module-fadeIn-qyX_s";
var _b = "fact-modal-module-imageLayout-G32xs";
var _c = "fact-modal-module-isActive-MycJy";
var _d = "fact-modal-module-permanentCloseBtn-xi5p0";
export { _1 as "defaultLayout", _2 as "factButton", _3 as "factButtonIcon", _4 as "factModalBackdrop", _5 as "factModalContentWrapper", _6 as "factModalFooter", _7 as "factModalImg", _8 as "factModalTextual", _9 as "factModalWrapper", _a as "fadeIn", _b as "imageLayout", _c as "isActive", _d as "permanentCloseBtn" }
