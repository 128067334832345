import { IDeleteCustomPromotion } from "./IDeleteCustomPromotion";
import { IProductsRepository } from "../../../repositories/ProductsRepository/IProductsRepository";

export class DeleteCustomPromotionUseCase implements IDeleteCustomPromotion {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(promotionKey: string): Promise<Response> {
    const result = await this.repository.deleteCustomPromotion(promotionKey);
    return result;
  }
}
