import React, { useState } from "react";
import { observer } from "mobx-react";
import { trackClick, trackLink } from "helpers/reactTracking";
import {
  AddonsPresenter,
  IAddon,
  SelectionTypes,
} from "core/presenter/PencilSelling/AddonsPresenter";
import {
  Tabs,
  Container,
  Heading,
  Modal as LPGModal,
  CopyText,
  Input,
  Flex,
  Dropdown,
  TextLink,
} from "@dtpk-cc/components";

import ProductPricesModal from "components/ProductPricesModal";
import GroupedProductsModal from "components/GroupedProductsModal";
import PromotionsModal from "components/PromotionsModal";
import Modal from "components/Modal";
import TileItem from "elements/TileItem";
import CustomItem from "elements/CustomItem";
import DetailTileItem from "elements/DetailTileItem";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";

import "./addons.scss";
import {
  Egsp,
  ICartItemData,
  PaymentTypes,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import { useStores } from "../../stores";
import { PRODUCT_DEFAULT_CONTRACT_PERIOD } from "../../constants/const";

type AddonsSelectionProps = {
  headline: string;
  name: string;
  products: IAddon[];
  activeProducts: ICartItemData[];
  condition?: string;
  isFungible: boolean;
  allowCustomProducts: boolean;
  portfolio: PortfolioKeys;
  addonGroup: Group;
};

const AddonsSelection = ({
  headline,
  name,
  products,
  activeProducts,
  condition = null,
  isFungible = false,
  allowCustomProducts = false,
  portfolio,
  addonGroup,
}: AddonsSelectionProps) => {
  const trackingContext = headline;
  const { cartStore } = useStores();
  const presenter = new AddonsPresenter(products, condition);
  const categories = presenter.formattedCategories;
  const [currentTabCategory, setCurrentTabCategory] = useState(
    categories[0]?.key
  );
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isPromotionsModalOpen, setIsPromotionsModalOpen] = useState(false);
  const [promotionsProduct, setPromotionsProduct] = useState(null);
  const [isCustomItemModalOpen, setIsCustomItemModalOpen] = useState(false);
  const initialDevice = { price: "", paymentType: "monatlich", deviceName: "" };
  const [customDevice, setCustomDevice] = useState(initialDevice);
  const [productChoices, setProductChoices] = useState([]);

  const addProduct = (product, paymentType) => {
    const activeGroupItem =
      !product.isCustom &&
      presenter.activeGroupItem(activeProducts, product.productGroup.key);

    const cartItem = {
      portfolio,
      name: product.name,
      price: product.price,
      paymentType,
      key: product.key,
      promotions: [],
      quantity: activeGroupItem?.quantity || 1,
      group: product.type || addonGroup,
      description:
        (product.egsp === Egsp.DEFAULT || product.egsp === Egsp.PLUS) &&
        paymentType === PaymentTypes.MONTHLY
          ? {
              summary: product.description.summary_egsp,
              text: product.description.text_egsp,
            }
          : product.description,
      additionalDescription: product.additionalDescription,
      contractPeriod: product.contractPeriod || PRODUCT_DEFAULT_CONTRACT_PERIOD,
      isFungible,
      egsp: product.egsp,
      availableFor: product.availableFor,
      inheritingCard: product.inheritingCard,
    };

    if (activeGroupItem) cartStore.replace(activeGroupItem.key, cartItem);
    else cartStore.addToCart(cartItem);
  };

  const addonsProductPromotions = (addonKey) => {
    const addonsProduct = products.find((product) => product.key === addonKey);
    const productPromotions = addonsProduct?.promotions || [];

    return productPromotions.filter((promotion) =>
      promotion.concerned
        ? promotionsProduct.paymentType === promotion.concerned
        : true
    );
  };

  const deleteDevice = (productKey) => {
    cartStore.delete(productKey);
  };

  const showProductGroupChoices = (items) => {
    setIsGroupModalOpen(true);
    setProductChoices(items);
  };

  const showPaymentTypeChoices = (items) => {
    setIsPriceModalOpen(true);
    setProductChoices(items);
  };

  const handleProductSelection = (product, payment = null) => {
    const { monthly } = product.price;
    // payment parameter is optional
    const paymentType = payment || presenter.getPaymentType(monthly);

    addProduct(product, paymentType);

    setIsGroupModalOpen(false);
    setIsPriceModalOpen(false);
  };

  const handleProductGroupSelection = (product) => {
    handleProductSelection(product);
  };

  const handleGroupTileClick = (group) => {
    const type = presenter.getSelectionType(group.key);

    if (type === SelectionTypes.PRODUCTS)
      showProductGroupChoices(group.products);
    if (type === SelectionTypes.PRICE) showPaymentTypeChoices(group.products);
    if (type === SelectionTypes.NONE) handleProductSelection(group.products[0]);
  };

  const handleSelectionChange = (key) => {
    const productGroup = presenter.getProductGroupByProduct(key);
    handleGroupTileClick(productGroup);
  };

  const isProductGroupActive = (items: IAddon[]) => {
    const activeProductsKeys = activeProducts.map(({ key }) => key);
    return items.some(({ key }) => activeProductsKeys.includes(key));
  };

  const onQuantityChange = (key, quantity) => {
    cartStore.setQuantity(key, quantity);
  };

  const handleCustomItemChange = (value, key) => {
    setCustomDevice((previousState) => ({
      ...previousState,
      [key]: value,
    }));
  };

  const handleCustomItemSubmit = () => {
    const { price, deviceName, paymentType } = customDevice;
    const payment = paymentType === "einmalig" ? "once" : "monthly";
    const key = new Date().getTime();

    const product = {
      name: deviceName,
      price: { monthly: null, [payment]: Number(price) },
      key,
      group: addonGroup,
      isCustom: true,
    };
    addProduct(product, payment);
    setIsCustomItemModalOpen(false);
    setCustomDevice(initialDevice);
  };

  const showPromotionChoices = (product) => {
    setPromotionsProduct(product);
    setIsPromotionsModalOpen(true);
  };

  const handlePromotionSelection = (promotions) => {
    setIsPromotionsModalOpen(false);
    cartStore.replace(promotionsProduct.key, {
      ...promotionsProduct,
      promotions,
    });
  };

  const getEgspText = (item) => {
    if (item.egsp === Egsp.NONE || !item.egsp) return "";
    return item.egsp === Egsp.DEFAULT ? "mit EGSP" : "mit EGSP+";
  };

  return (
    <Container variants={Container.Variant.padded} customClass="addons">
      <Heading
        tag="h1"
        variants={[Heading.Variant.secondary]}
        data-qa="quality"
        style={{ textAlign: "center" }}
      >
        {headline}
      </Heading>
      <Container>
        {!!activeProducts.length && (
          <>
            <Heading
              tag="h2"
              customClass="addons__subheading"
              variants={Heading.Variant.tertiary}
            >
              Aktuell ausgewählt
            </Heading>
            <div className="addons__item-wrapper">
              {activeProducts.map((product) => (
                <DetailTileItem
                  key={product.key}
                  description={product.additionalDescription}
                  item={product}
                  isFungible={isFungible}
                  onRemove={() => {
                    deleteDevice(product.key);
                    trackClick(`${product.name}.entfernen`, trackingContext);
                  }}
                  hasConditions={presenter.isProductWithSelection(product.key)}
                  hasPromotions={presenter.isProductWithPromotions(
                    product.key,
                    product.paymentType
                  )}
                  onConditionChange={() => {
                    handleSelectionChange(product.key);
                    trackClick(
                      `${product.name}.auswahl-ändern`,
                      trackingContext
                    );
                  }}
                  onQuantityChange={(quantity) => {
                    onQuantityChange(product.key, quantity);
                    trackClick(
                      `${product.name}.menge-${quantity}`,
                      trackingContext
                    );
                  }}
                  onPromotionChange={() => {
                    showPromotionChoices(product);
                    trackClick(
                      `${product.name}.aktion-hinzufügen/ändern`,
                      trackingContext
                    );
                  }}
                />
              ))}
            </div>
          </>
        )}
      </Container>

      <Container customClass="addons__tab-navigation">
        <Tabs
          scheme={Tabs.Variant.large}
          onChange={(index) => {
            setCurrentTabCategory(categories[index]?.key);
          }}
        >
          <Tabs.List>
            {categories.map((category) => (
              <Tabs.Tab
                onClick={() => {
                  trackClick(`${category.name}`, trackingContext);
                }}
                key={category.key}
                value={category.key}
              >
                {category.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {categories.map((category) => (
            <Tabs.Panel
              aria-label={`${category.name} Tabelle`}
              className="addons__tabs-panel"
              key={category.key}
              tabIndex={-1}
            >
              {category.key === currentTabCategory && (
                <>
                  <div className="addons__item-wrapper">
                    {category.productGroups.map((productGroup) => (
                      <TileItem
                        disabled={isProductGroupActive(productGroup.products)}
                        price={productGroup.minPrice}
                        additionalPriceSuffix={getEgspText(
                          productGroup.products[0]
                        )}
                        isProductGroup={productGroup.products.length > 1}
                        key={productGroup.key}
                        item={productGroup}
                        onClick={() => {
                          handleGroupTileClick(productGroup);
                          trackClick(`${productGroup.name}`, trackingContext);
                        }}
                      />
                    ))}
                  </div>
                  {category.subcategories.map((subcategory) => (
                    <React.Fragment key={subcategory.key}>
                      <div className="addons__subcategory-heading">
                        <Heading
                          style={{ display: "inline-block", marginBottom: 0 }}
                          tag="h3"
                          variants={Heading.Variant.quaternary}
                        >
                          {subcategory.name}
                        </Heading>{" "}
                        {subcategory.link && (
                          <TextLink
                            href={subcategory.link}
                            target="_blank"
                            customClass="addons__link"
                            onClick={() =>
                              trackLink(subcategory.link, trackingContext)
                            }
                          >
                            {subcategory.link_title}
                          </TextLink>
                        )}
                      </div>

                      <div className="addons__item-wrapper">
                        {subcategory.productGroups.map((productGroup) => (
                          <TileItem
                            disabled={isProductGroupActive(
                              productGroup.products
                            )}
                            price={productGroup.minPrice}
                            additionalPriceSuffix={getEgspText(
                              productGroup.products[0]
                            )}
                            isProductGroup={productGroup.products.length > 1}
                            key={productGroup.key}
                            item={productGroup}
                            onClick={() => {
                              handleGroupTileClick(productGroup);
                              trackClick(productGroup.name, trackingContext);
                            }}
                          />
                        ))}
                      </div>
                    </React.Fragment>
                  ))}

                  {allowCustomProducts && (
                    <div className="addons__item-wrapper">
                      <CustomItem
                        cssClass="tile-item"
                        handleOnClick={() => {
                          setIsCustomItemModalOpen(true);
                          trackClick(
                            "weiteres-gerät-hinzufügen",
                            trackingContext
                          );
                        }}
                        text="Weiteres Gerät hinzufügen"
                      />
                    </div>
                  )}
                </>
              )}
            </Tabs.Panel>
          ))}
        </Tabs>

        <Modal
          active={isCustomItemModalOpen}
          close={() => setIsCustomItemModalOpen(false)}
          showSubmitButton
          handleSubmitButton={() => {
            handleCustomItemSubmit();
            trackClick("modal-gerät-hinzufügen", trackingContext);
          }}
          submitButtonDisabled={!customDevice.price || !customDevice.deviceName}
          submitButtonText="Hinzufügen"
        >
          <CopyText customClass="modal__copy">{name}typ</CopyText>
          <LPGModal.Heading>Weiteres Gerät hinzufügen</LPGModal.Heading>
          <Container customClass="addons__name-input">
            <Input
              value={customDevice.deviceName}
              onChange={(e) =>
                handleCustomItemChange(e.target.value, "deviceName")
              }
              aria-label="Gerätename"
              onClick={() => trackClick("geräte-name", trackingContext)}
            >
              {name}name
            </Input>
          </Container>
          <Flex justifyContent="space-between">
            <Container customClass="addons__price-input">
              <Input
                value={customDevice.price}
                onChange={(e) =>
                  handleCustomItemChange(e.target.value, "price")
                }
                onClick={() => trackClick("geräte-preis", trackingContext)}
                type="number"
                aria-label="Gerätepreis"
              >
                Preis
              </Input>
            </Container>

            <Container customClass="addons__price-input">
              <Dropdown
                value={customDevice.paymentType}
                label="Zahlungsintervall"
                items={["einmalig", "monatlich"]}
                onSelection={(value) => {
                  handleCustomItemChange(value, "paymentType");
                  trackClick("zahlungsintervall", trackingContext);
                }}
              />
            </Container>
          </Flex>
        </Modal>
        {/* TODO check for possibility to set modal variants as single component */}
        {isPriceModalOpen && (
          <ProductPricesModal
            headline={headline}
            isOpen={isPriceModalOpen}
            closeModal={() => setIsPriceModalOpen(false)}
            product={productChoices.length ? productChoices[0] : null}
            activePrice={
              activeProducts.find(
                (product) => product.key === productChoices[0]?.key
              )?.paymentType || ""
            }
            title="Preisvariante"
            description="Konditionsauswahl"
            getAdditionalPriceSuffix={(item) => getEgspText(item)}
            onSave={handleProductSelection}
          />
        )}

        {isGroupModalOpen && (
          <GroupedProductsModal
            headline={headline}
            isOpen={isGroupModalOpen}
            closeModal={() => setIsGroupModalOpen(false)}
            groupedProducts={productChoices}
            activeProducts={activeProducts}
            title="Produktvariante"
            description="Konditionsauswahl"
            getAdditionalPriceSuffix={(item) => getEgspText(item)}
            onSave={handleProductGroupSelection}
          />
        )}

        {isPromotionsModalOpen && (
          <PromotionsModal
            condition={null}
            trackingContext={trackingContext}
            isOpen={isPromotionsModalOpen}
            closeModal={() => setIsPromotionsModalOpen(false)}
            product={promotionsProduct}
            promotions={addonsProductPromotions(promotionsProduct.key)}
            title="Aktionsangebote"
            onSave={(selectedPromotions) => {
              handlePromotionSelection(selectedPromotions);
              trackClick("modal-aktionen-auswahl-übernehmen", headline);
            }}
          />
        )}
      </Container>
    </Container>
  );
};

export default observer(AddonsSelection);
