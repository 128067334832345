import React from "react";
import { Icon } from "@dtpk-cc/components";
import AlertInformationDefault from "@dtpk-cc/components/dist/icons/Alert/Information/Default";
import * as styles from "./notify-hint-box.module.scss";
import { NotifyHintBoxBgVariants } from "./INotifyHintBox";

type NotifyHintBoxProps = {
  customClass?: string;
  variant?: NotifyHintBoxBgVariants;
};

const NotifyHintBox: React.FC<NotifyHintBoxProps> = ({
  customClass = "",
  children,
  variant = NotifyHintBoxBgVariants.gray,
}) => (
  <div className={`${styles.wrapper} ${styles[variant]} ${customClass}`}>
    <Icon
      wrapperProps={{
        className: styles.notifyIconWrapper,
      }}
      icon={AlertInformationDefault}
      size={Icon.Size.xlarge}
    />
    {children}
  </div>
);

export default NotifyHintBox;
