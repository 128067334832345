import { ITariff } from "core/entities/Product/Tariff/ITariff";
import { GetTariffPriceProp } from "../../ITariffSelection";

export const getBenefitDiscount = (tariff: ITariff) => {
  const rebateOption = tariff.benefits[0]?.options.find(
    (option) => option?.key === "discount"
  );

  return parseFloat(`${rebateOption?.value || 0}`);
};

export const getDefaultTariffPrice: GetTariffPriceProp = (
  tariff: ITariff,
  isBenefitActive: boolean,
  businessCase
) => {
  let currentPrice = tariff.price.monthly;

  if (
    tariff.isYoung &&
    typeof tariff.price.youngDiscount?.price === "number" &&
    tariff.price.youngDiscount?.availableForBusinessCases.includes(businessCase)
  ) {
    currentPrice = tariff.price.youngDiscount.price;
  }

  return isBenefitActive
    ? currentPrice - getBenefitDiscount(tariff)
    : currentPrice;
};
