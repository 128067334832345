import React from "react";

const ButtonGroupsItem = ({
  idIndex,
  value,
  checked,
  onChange,
  children,
  name,
  rowIndex,
  itemWidth,
  inputTestId,
}) => (
  <div
    style={itemWidth ? { width: `${itemWidth}%` } : null}
    className="button-groups__list-item"
  >
    <input
      data-testid={inputTestId}
      className="button-groups__input"
      tabIndex="0"
      type="radio"
      onChange={(event) => onChange(value, event)}
      name={name}
      checked={checked}
      id={`${name}-radio-${rowIndex}-${idIndex}`}
      value={value}
    />
    <label
      className="button-groups__input-label"
      htmlFor={`${name}-radio-${rowIndex}-${idIndex}`}
    >
      {children}
    </label>
  </div>
);
export default ButtonGroupsItem;
