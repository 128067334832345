import React from "react";
import { Tile, CopyText, Icon } from "@dtpk-cc/components";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";
import PriceInput from "../../elements/PriceInput";
import { trackClick } from "../../helpers/reactTracking";
import TreasureImg from "../../../assets/images/treasure.png";

import "./loyalty-bonus.scss";

export const MAX_LOYALTY_BONUS = 500;

interface LoyaltyBonusProps {
  value: number;
  disabled: boolean;
  onChange: (value: string) => void;
  trackingContext?: string;
  rerender?: boolean;
  freeMonths?: number;
  openModal?: (value: boolean) => void;
  showFreeMonthsText?: boolean;
}

const LoyaltyBonus = ({
  value,
  disabled,
  onChange,
  trackingContext = "",
  rerender = false,
  freeMonths = null,
  openModal = () => {},
  showFreeMonthsText = false,
}: LoyaltyBonusProps) => {
  const label = value > 0 ? "Treuebonus hinzugefügt" : "Treuebonus hinzufügen";
  const isActive = value > 0;

  return (
    <Tile
      variant="shadow"
      customClass={`loyalty-bonus ${
        disabled ? "loyalty-bonus--disabled" : ""
      } ${isActive ? "loyalty-bonus--active" : ""} `}
    >
      <Tile.Content customClass="loyalty-bonus__content">
        <div className="loyalty-bonus__title">
          <img
            className="loyalty-bonus__title-icon"
            src={TreasureImg}
            alt="loyalty-bonus-icon"
          />
          <span className="loyalty-bonus__title-text">
            <b>{label}</b>
            {/*  This empty Icon is needed to fix a LPG styling problem */}
            <Icon
              customClass="loyalty-bonus__info-icon"
              icon={AlertInformationDefault}
              size={Icon.Size.small}
              wrapperProps={{
                style: { visibility: "hidden", display: "inline" },
              }}
            />
            <span className="loyalty-bonus__additional-text">
              {showFreeMonthsText && (
                <>
                  Bis zu{" "}
                  <CopyText
                    tag="span"
                    variants={[
                      CopyText.Variant.highlight,
                      CopyText.Variant.magenta,
                    ]}
                  >
                    {freeMonths}
                  </CopyText>
                  {freeMonths === 1 ? " Freimonat" : " Freimonate"}
                  <Icon
                    customClass="loyalty-bonus__info-icon"
                    icon={AlertInformationDefault}
                    size={Icon.Size.small}
                    onClick={() => {
                      openModal(true);
                      trackClick("treuebonus-info-icon");
                    }}
                    wrapperProps={{ style: { display: "inline" } }}
                  />
                </>
              )}
            </span>
          </span>
        </div>

        <div className="loyalty-bonus__price-wrapper">
          <PriceInput
            rerender={rerender}
            maxValue={MAX_LOYALTY_BONUS}
            value={value}
            suffix="€"
            onChange={onChange}
            inFloat={false}
            onClick={() => {
              trackClick("treuebonus-input", trackingContext);
            }}
          />
        </div>
      </Tile.Content>
    </Tile>
  );
};

export default LoyaltyBonus;
