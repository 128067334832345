import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import type {
  ExportCartTariffs,
  IGetTariffsInCartUseCase,
} from "./IGetTariffsInCart";

export class GetTariffsInCartUseCase implements IGetTariffsInCartUseCase {
  constructor(private cart: ICart) {}

  execute(): ExportCartTariffs {
    return {
      [PortfolioKeys.LANDLINE]: this.cart.getLandlineTariff(),
      [PortfolioKeys.MOBILE]: this.cart.getMobileTariff(),
    };
  }
}
