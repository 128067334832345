// extracted by mini-css-extract-plugin
var _1 = "fiber-address-check-module-addressCheckDescr-spvOq";
var _2 = "fiber-address-check-module-bgImgWrapper-tLfQa";
var _3 = "fiber-address-check-module-headerBgImg-oOBjr";
var _4 = "fiber-address-check-module-headerFirstTitle-zOMah";
var _5 = "fiber-address-check-module-headerItem-dW4Ru";
var _6 = "fiber-address-check-module-headerSecondTitle-Clard";
var _7 = "fiber-address-check-module-headerTitle-FixaX";
var _8 = "fiber-address-check-module-headerWrapper-kzGmZ";
var _9 = "fiber-address-check-module-peopleImg-ZPXT6";
var _a = "fiber-address-check-module-subHeading-I2jWy";
export { _1 as "addressCheckDescr", _2 as "bgImgWrapper", _3 as "headerBgImg", _4 as "headerFirstTitle", _5 as "headerItem", _6 as "headerSecondTitle", _7 as "headerTitle", _8 as "headerWrapper", _9 as "peopleImg", _a as "subHeading" }
