/* global Cookies, tracking */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CookieConsent, CopyText, Icon } from "@dtpk-cc/components";
import "./cookie-layer-setting.scss";

import ServiceSettingsDefault from "@dtpk-cc/components/dist/icons/Service/Settings/Default";
import UserFileAnalyticsDefault from "@dtpk-cc/components/dist/icons/UserFile/Analytics/Default";

import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/CookieConsent/CookieConsent.css";

const REQUIRED_COOKIES = "required_cookies";
const ANALYTICAL_COOKIES = "analytical_cookies";

const CookieLayer = () => {
  const onPublicPage = () => {
    if (window.location.href.match(/^.*\/datenschutzhinweise$/)) {
      return true;
    }
    return false;
  };

  const [isOpen, setIsOpen] = useState(
    !Cookies.get(REQUIRED_COOKIES) && !onPublicPage()
  );

  useEffect(() => {
    localStorage.setItem("DIVECookieLayer", isOpen);
  }, [isOpen]);

  const openCookieLayer = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    // CustomEvent when CookieLayer should be opened
    window.addEventListener("CookieLayerOpenEvent", openCookieLayer, false);

    return () => {
      window.removeEventListener("CookieLayerOpenEvent", openCookieLayer);
    };
  }, []);

  const handleCookieSelections = (cookies = [REQUIRED_COOKIES]) => {
    Cookies.remove(REQUIRED_COOKIES);
    Cookies.remove(ANALYTICAL_COOKIES);

    if (cookies.length === 1 && cookies.includes(ANALYTICAL_COOKIES)) {
      cookies.push(REQUIRED_COOKIES);
    }

    cookies.forEach((cookie) => {
      Cookies.set(cookie, "1", { expires: 90 });
    });

    if (cookies.includes(ANALYTICAL_COOKIES)) {
      tracking.init(true);
    } else {
      tracking.init(false);
    }
  };

  const selectOnlyRequired = (event) => {
    if (event) {
      event.preventDefault();
    }

    handleCookieSelections([REQUIRED_COOKIES]);
    setIsOpen(false);
  };

  const acceptAllCookies = () => {
    handleCookieSelections([REQUIRED_COOKIES, ANALYTICAL_COOKIES]);
    setIsOpen(false);
  };

  return (
    <div className="cookie-no-print">
      {isOpen && (
        <CookieConsent
          isOpen={isOpen}
          privacyPolicyUrl="/datenschutzhinweise"
          imprintUrl="https://www.telekom.de/impressum"
          onChange={handleCookieSelections}
          onAcceptAll={acceptAllCookies}
          onAcceptRequired={selectOnlyRequired}
          onConfirmSelection={(cookies) => {
            handleCookieSelections([REQUIRED_COOKIES, ...cookies]);
            setIsOpen(false);
          }}
          portalless={true}
          style={{ zIndex: "999" }}
        >
          <CookieConsent.Main>
            <CookieConsent.Headline>
              Einwilligung in die Datenverarbeitung
            </CookieConsent.Headline>
            <CookieConsent.Text>
              <CopyText>
                Mittels Cookies und ähnlicher Technologien können Informationen
                auf Ihrem Endgerät gespeichert, angereichert und gelesen werden.
                Mit einem Klick auf „Alle akzeptieren“ stimmen Sie dem Zugriff
                auf Ihr Endgerät zu sowie der Verarbeitung Ihrer{" "}
                <i
                  title="&#x2022;HTTP Header-Informationen (u.a. IP-Adresse, Informationen zum Webbrowser, Seitenspeicherort,
                        Dokument, URL der Webseite, Tag und Uhrzeit der Nutzung),
                      &#x2022; Pixelspezifische Daten (wie z.B. Pixel-ID)
                      &#x2022; Nutzungsdaten der Webseite (gesuchte Produkte, getätigte Bestellungen, aufgerufene Seiten)
                      &#x2022; Vertragsdaten (gebuchte Tarife/Produkte ausschließlich TDG Zwecke, keine Drittnutzung)
                      &#x2022; Soziodemografische Daten (Altersgruppe, PLZ-Bereich (z.B. 532xx Bonn) und Geschlecht)
                      &#x2022; E-Mail-Adresse für geräteübergreifende Profile"
                >
                  Daten
                </i>
                , der Webseiten- sowie partner- und geräteübergreifenden
                Erstellung und Verarbeitung von individuellen Nutzungsprofilen
                sowie der Weitergabe Ihrer Daten an Drittanbieter, die zum Teil
                Ihre Daten in Ländern außerhalb der europäischen Union
                verarbeiten (DSGVO Art. 49). Die Telekom kann unter Umständen
                nicht in allen Fällen sicherstellen, dass das europäische
                Datenschutzniveau eingehalten wird. Details finden Sie unter{" "}
                <a href="/datenschutzhinweise">Datenschutz</a>. Die Daten werden
                teilweise mit soziodemografischen Informationen (wie z.B.
                Geschlecht, Altersdekade und PLZ-Bereich) ergänzt und für
                Analysen, Retargeting und zur Ausspielung von personalisierten
                Inhalten und Angeboten auf Seiten der Telekom, als auch zur
                Werbeausspielung auf Drittanbieterseiten, sowie zu eigenen
                Zwecken von Partnern genutzt und mit Daten zusammengeführt.
              </CopyText>
              <br />
              <CopyText>
                Wenn Sie uns Ihre Einwilligung zum Informationsservice sowie
                Ihre Cookie Einwilligung erteilt haben, berücksichtigen wir zur
                individuellen Angebotsausspielung auf Telekom und
                Drittanbieterseiten auch pseudonymisierte Informationen aus
                Ihren Verträgen und soziodemografische Daten (z.B. Altersdekade,
                gebuchte Produkte), die über einen Cookie und einen E-Mail-Hash
                Ihren Web-/Appnutzungsdaten zugeordnet werden.
              </CopyText>
              <br />
              <CopyText>
                Weitere Informationen, auch zur Datenverarbeitung durch
                Drittanbieter und zum jederzeit möglichen Widerrufs Ihrer
                Einwilligung, finden Sie in den Einstellungen sowie in unseren
                Datenschutzhinweisen.
              </CopyText>
            </CookieConsent.Text>
          </CookieConsent.Main>

          <CookieConsent.Detail>
            <CookieConsent.Headline>
              Verwalten Sie Ihre Datenschutz-Einstellungen
            </CookieConsent.Headline>
            <CookieConsent.Text>
              Um Ihnen ein optimales Webseitenerlebnis zu bieten, setzen wir
              Cookies ein. Dazu zählen Cookies für den Betrieb und die
              Optimierung der Seite als auch für Services, wie die Nutzung des
              Text- oder Video Chats sowie auch an Ihrem online
              Nutzungsverhalten orientierte Werbung. So kann z.B. erkannt
              werden, wenn Sie unsere Seiten vom selben Gerät aus wiederholt
              besuchen. Wir möchten Ihnen die Wahl geben, welche Cookies Sie
              zulassen:
            </CookieConsent.Text>

            <CookieConsent.Option
              name="required_cookies"
              toggleCheckedCaption="Erforderlich"
              required
            >
              <CookieConsent.Option.Icon>
                <Icon icon={ServiceSettingsDefault} size={Icon.Size.parent} />
              </CookieConsent.Option.Icon>
              <CookieConsent.Option.Description>
                <CookieConsent.Option.Headline>
                  Erforderliche Cookies
                </CookieConsent.Option.Headline>
                Diese Cookies sind notwendig, damit Sie durch die Seiten
                navigieren und wesentliche Funktionen nutzen können.
                <CookieConsent.Option.AdditionalInfo>
                  Sie ermöglichen Grundfunktionen, wie die Bestellabwicklung im
                  Online-Shop und den Zugriff auf gesicherte Bereiche der
                  Webseite. Zudem dienen sie der anonymen Auswertung des
                  Nutzerverhaltens, die von uns verwendet werden, um unseren
                  Webauftritt stetig für Sie weiterzuentwickeln.
                </CookieConsent.Option.AdditionalInfo>
              </CookieConsent.Option.Description>
            </CookieConsent.Option>

            <CookieConsent.Option name="analytical_cookies">
              <CookieConsent.Option.Icon>
                <Icon icon={UserFileAnalyticsDefault} size={Icon.Size.parent} />
              </CookieConsent.Option.Icon>
              <CookieConsent.Option.Description>
                <CookieConsent.Option.Headline>
                  Analytische Cookies
                </CookieConsent.Option.Headline>
                Diese Cookies helfen uns, das Nutzungsverhalten besser zu
                verstehen.
                <CookieConsent.Option.AdditionalInfo>
                  Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und
                  Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so
                  genannten pseudonymen Nutzungsprofilen. Wir benutzen
                  beispielsweise Analyse-Cookies, um die Zahl der individuellen
                  Besucher einer Webseite oder eines Dienstes zu ermitteln oder
                  um andere Statistiken im Hinblick auf den Betrieb unserer
                  Produkte zu erheben, als auch das Nutzerverhalten auf Basis
                  anonymer und pseudonymer Informationen zu analysieren, wie
                  Besucher mit der Webseite interagieren. Ein unmittelbarer
                  Rückschluss auf eine Person ist dabei nicht möglich.
                </CookieConsent.Option.AdditionalInfo>
              </CookieConsent.Option.Description>
            </CookieConsent.Option>
          </CookieConsent.Detail>
        </CookieConsent>
      )}
    </div>
  );
};

CookieLayer.propTypes = {
  isOpen: PropTypes.bool,
};

export default CookieLayer;
