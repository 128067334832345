import { GetLegacySummaryCard } from "./IGetLegacySummaryCard";
import { ILegacyTariff } from "../../../entities/MagentaNext/LegacyTariff/ILegacyTariff";
import { LegacySummaryBaseCardData } from "../../../entities/MagentaNext/LegacyTariff/ILegacyTariff";

export class GetLegacySummaryCardUseCase implements GetLegacySummaryCard {
  constructor(private readonly legacyEntity: ILegacyTariff) {}

  execute(): LegacySummaryBaseCardData {
    return this.legacyEntity.getLegacySummaryCardData();
  }
}
