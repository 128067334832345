import React from "react";
import StepHeaderTitle from "./StepHeaderTitle";

import * as styles from "./step-header.module.scss";

type StepHeaderProps = {
  title: string;
  subtitle: string;
  customClass?: string;
  titleCustomClass?: string;
};

const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  subtitle,
  children,
  customClass = "",
  titleCustomClass = "",
}) => (
  <div className={`${styles.stepHeader} ${customClass}`}>
    <StepHeaderTitle title={title} subtitle={subtitle} titleCustomClass={titleCustomClass} />
    {children}
  </div>
);

export default StepHeader;
