import React from "react";
import SummaryConfigurationSectionWrapper from "../SummaryConfigurationSectionWrapper";
import SummaryConfigurationSection from "../SummaryConfigurationSection";
import SummaryConfigurationSectionDescription from "../SummaryConfigurationSectionDescription";
import SummaryConfigurationAddition from "../SummaryConfigurationAddition";
import SummaryConfigurationBenefits from "../SummaryConfigurationBenefits";
import { IConfigurationSummaryItemSections } from "../ISummary";
import { Group } from "../../../../core/entities/Product/IProduct";
import SummaryConfigurationMainAveragePriceDescr from "../SummaryConfigurationMainAveragePriceDescr";

type SummaryConfigurationAddonItemProps = {
  configurationItem: IConfigurationSummaryItemSections;
  customerPricePrefix: string;
};

const SummaryConfigurationAddonItem = ({
  configurationItem,
  customerPricePrefix,
}: SummaryConfigurationAddonItemProps) => (
  <>
    {configurationItem.sections
      .filter((sectionItem) => sectionItem.group === Group.addon)
      .map((section) => (
        <SummaryConfigurationSectionWrapper
          key={section.name}
          name={section.name}
        >
          {section.items.map(
            ({ additionalRenderData, ...sectionItem }, index) => (
              <SummaryConfigurationSection
                key={`${additionalRenderData.productBaseName}-${index}`}
              >
                <SummaryConfigurationSectionDescription
                  customerPricePrefix={customerPricePrefix}
                  sectionItemDescriptionData={{
                    additionalRenderData,
                    ...sectionItem,
                  }}
                />
                {additionalRenderData.itemOptionsAreExist && (
                  <>
                    <SummaryConfigurationAddition
                      customerPricePrefix={customerPricePrefix}
                      additionItems={sectionItem.additions}
                    />
                    <SummaryConfigurationBenefits
                      customerPricePrefix={customerPricePrefix}
                      benefits={sectionItem.benefits}
                    />
                  </>
                )}
                <SummaryConfigurationMainAveragePriceDescr
                  description={
                    sectionItem.mainAveragePriceDescription
                  }
                />
              </SummaryConfigurationSection>
            )
          )}
        </SummaryConfigurationSectionWrapper>
      ))}
  </>
);

export default SummaryConfigurationAddonItem;
