import { Button, CopyText } from "@dtpk-cc/components";
import React, { useState } from "react";
import { NetworkProvidersQualitySummary } from "views/Map/api/types";
import { MapSettingsColorScheme } from "views/Map/hooks/types";
import * as styles from "./map-sidebar-chart.module.scss";
import MapSidebarChartSummary from "./MapSidebarChartSummary";
import MapSidebarChartDetails from "./MapSidebarChartDetails";

type MapSidebarChartProps = {
  networkProvidersQualitySummary: NetworkProvidersQualitySummary;
  colorScheme: MapSettingsColorScheme;
};

const MapSidebarChart = ({
  networkProvidersQualitySummary,
  colorScheme,
}: MapSidebarChartProps) => {
  const [showDetails, setShowStatistics] = useState(false);

  return (
    <div className={styles.chartContainer}>
      {showDetails ? (
        <MapSidebarChartDetails
          networkProvidersQualitySummary={networkProvidersQualitySummary}
        />
      ) : (
        <MapSidebarChartSummary
          colorScheme={colorScheme}
          networkProvidersQualitySummary={networkProvidersQualitySummary}
        />
      )}
      <Button
        customClass={styles.toggleButtonWrapper}
        variants={[Button.Variant.clean]}
        onClick={() => setShowStatistics((cur) => !cur)}
      >
        <CopyText
          customClass={styles.buttonText}
          variants={CopyText.Variant.additional}
        >
          {`Statistik ${showDetails ? "schließen" : "ansehen"}`}
        </CopyText>
      </Button>
    </div>
  );
};

export default MapSidebarChart;
