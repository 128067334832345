import React, { ElementType } from "react";
import { CopyText } from "@dtpk-cc/components";
import TileButtonWrapper from "../../../../../elements/new-design/TileButtonWrapper";

import * as styles from "./tariff-configuration-tile-button.module.scss";

type TariffConfigurationTileButtonProps = {
  Icon: ElementType | string;
  title: string;
  subtitle: string;
  onClick: () => void;
  disabled?: boolean;
  customClass?: string;
};

const TariffConfigurationTileButton = ({
  Icon,
  title,
  subtitle,
  onClick,
  customClass = "",
  disabled = false,
}: TariffConfigurationTileButtonProps) => (
  <TileButtonWrapper
    customClass={`${styles.tileButton} ${customClass}`}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon className={styles.icon} />
    <div className={styles.textual}>
      <CopyText
        variants={CopyText.Variant.additional}
        customClass={styles.title}
      >
        {title}
      </CopyText>
      <CopyText
        variants={CopyText.Variant.additional}
        customClass={styles.subtitle}
      >
        {subtitle}
      </CopyText>
    </div>
  </TileButtonWrapper>
);

export default TariffConfigurationTileButton;
