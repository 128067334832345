import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";
import { ICartConfigurationType } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { useGetDefaultConfigurationSettings } from "./useGetDefaultConfigurationSettings";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";

export const useRemoveConfiguration = (
  configurationsStore: ConfigurationsEntity,
  offerStore: BaseOffer
) => {
  const { getDefaultConfigurationSettings } =
    useGetDefaultConfigurationSettings(offerStore);

  const removeConfigurationHandler = ({
    type,
    configurationKeysToDelete,
  }: {
    type: ICartConfigurationType;
    configurationKeysToDelete: string[];
  }) => {
    configurationsStore.removeConfiguration(configurationKeysToDelete);
    const configurationsList =
      configurationsStore.getConfigurationsByType(type);

    if (configurationsList.length) {
      configurationsStore.setActiveConfiguration(configurationsList[0]);
    }

    if (!configurationsList.length) {
      configurationsStore.addConfiguration(
        getDefaultConfigurationSettings(type)
      );
    }
  };

  return { removeConfigurationHandler };
};
