import React from "react";
import { CopyText } from "@dtpk-cc/components";
import CheckboxInput from "elements/new-design/CheckboxInput";

import * as styles from "./tariff-selection-checkbox-toggle.module.scss";

type TariffSelectionCheckboxToggleProps = {
  value?: string;
  name: string;
  id: string;
  inputTestId: string;
  checked: boolean;
  onChange: (value) => void;
  labelText: string;
  customClass?: string;
};

const TariffSelectionCheckboxToggle = ({
  customClass = "",
  value,
  id,
  name,
  inputTestId,
  checked,
  onChange,
  labelText,
}: TariffSelectionCheckboxToggleProps) => (
  <label htmlFor={id} className={`${styles.checkboxToggle} ${customClass}`}>
    <CheckboxInput
      value={value}
      name={name}
      id={id}
      inputTestId={inputTestId}
      checked={checked}
      onChange={onChange}
    />
    <CopyText customClass={styles.checkboxToggleText}>{labelText}</CopyText>
  </label>
);

export default TariffSelectionCheckboxToggle;
