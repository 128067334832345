import React from "react";
import { Grid, Button, A11yText, CopyText } from "@dtpk-cc/components";
import { ActionRemoveDefault } from "@dtpk-cc/components/dist/icons";
import { onlyNumber } from "helpers/NumericCalculation";
import Input from "../../../../../../elements/new-design/Input";

import * as styles from "./phone-item.module.scss";
import DropdownMain from "../../../../../../elements/new-design/DropdownMain";
import { SMARTPHONES_MANUFACTURERS } from "../../../../../../constants/const";
import CheckboxInput from "../../../../../../elements/new-design/CheckboxInput";

type PhoneItemSelectionProps = {
  name: string;
  price: string;
  onNameChange: (value) => void;
  onManufacturerChange: (value) => void;
  onNameClick: () => void;
  onPriceChange: (value) => void;
  onPriceClick: () => void;
  onIsAlternativeChange: () => void;
  onRemove: () => void;
  index: number;
  manufacturer: string;
  isAlternative: boolean;
  customClass?: string;
};

const PhoneItem = ({
  name,
  price,
  onRemove,
  onNameChange,
  onManufacturerChange,
  onPriceChange,
  onNameClick,
  onPriceClick,
  index,
  manufacturer,
  isAlternative,
  onIsAlternativeChange,
}: PhoneItemSelectionProps) => (
  <Grid.Row customClass={styles.phoneItemSelection}>
    <Grid.Col customClass={styles.removeCheckBoxWrapper} m={1} l={1} xl={2}>
      <label
        className={`${styles.isAlternativeCheck} ${
          index === 0 ? styles.disabled : ""
        }`}
        htmlFor={`is-alternative-${index}`}
      >
        <CheckboxInput
          disabled={index === 0}
          name={`is-alternative-${name}`}
          id={`is-alternative-${index}`}
          checked={isAlternative}
          onChange={onIsAlternativeChange}
        />
      </label>
    </Grid.Col>
    <Grid.Col
      customClass={`${styles.dropdownWrapper} p-r-12`}
      m={5}
      l={5}
      xl={9}
    >
      {isAlternative && (
        <CopyText
          customClass={styles.phoneItemSubtitle}
          variants={[CopyText.Variant.additional, CopyText.Variant.highlight]}
        >
          Alternative
        </CopyText>
      )}
      <DropdownMain
        label="Herstellername"
        items={SMARTPHONES_MANUFACTURERS}
        onSelection={onManufacturerChange}
        value={manufacturer}
      />
    </Grid.Col>
    <Grid.Col customClass={"p-r-12"} m={3} l={3} xl={7}>
      <Input
        id={`phone-name-${index}`}
        value={name}
        onClick={onNameClick}
        onChange={({ target: { value } }) => onNameChange(value)}
        placeholder="Endgerätename"
      />
    </Grid.Col>
    <Grid.Col customClass={"p-r-12"} m={2} l={2} xl={4}>
      <Input
        id={`phone-price-${index}`}
        value={price}
        onChange={({ target: { value } }) => onPriceChange(value)}
        onClick={onPriceClick}
        onKeyPress={(e) => onlyNumber(e)}
        placeholder="Kaufpreis"
      />
    </Grid.Col>
    <Grid.Col customClass={styles.removeBtnWrapper} m={1} l={1} xl={2}>
      <Button
        tabIndex={0}
        customClass={styles.removeBtn}
        onClick={onRemove}
        variants={[Button.Variant.bare, Button.Variant.icon]}
      >
        <Button.Icon icon={ActionRemoveDefault} />
        <A11yText>Smartphone-Element entfernen</A11yText>
      </Button>
    </Grid.Col>
  </Grid.Row>
);

export default PhoneItem;
