import {
  IBusinessClientsCardSettings,
  ICartConfigurationType,
  ICartConfiguration,
} from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { CartItemSummaryPresenter } from "../../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import { Group } from "../../../../../core/entities/Product/IProduct";
import { IConfigurationsSummary } from "../../ISummary";
import {
  calculateConfigurationItemOnceTotalPrice,
  getConfigurationItemTotalMonthlyPrice,
  getCashbackPromotions,
  mapCashbackPromotions,
  mapTariffAddonsSummary,
} from "../index";

const mapSecondaryCardsSummary = ({
  offerExportConfigurations,
  configurationsSummary,
  priceType,
}: {
  offerExportConfigurations: ICartConfiguration[];
  configurationsSummary: IConfigurationsSummary;
  priceType: string;
}) => {
  const filteredOfferExportConfigurations = offerExportConfigurations.filter(
    (offerExportedConfigurationItem) =>
      !!offerExportedConfigurationItem.children.getMobileTariff()
  );

  filteredOfferExportConfigurations.forEach((configurationItem, index) => {
    const cardTariff = configurationItem.children.getMobileTariff();

    const configurationSettings = configurationItem.data
      .settings as IBusinessClientsCardSettings;
    const presenter = new CartItemSummaryPresenter(
      cardTariff,
      configurationSettings,
      priceType
    );
    const cardsAddons = configurationItem.children.getMobileOptions();

    const summaryConfigurationsArr =
      configurationsSummary[ICartConfigurationType.CARD].configurationItems;
    summaryConfigurationsArr.push({
      title: configurationItem.name
        ? configurationItem.name
        : `Zusatzkarten-Konfiguration Nr. ${index + 1}`,
      amount: configurationItem.amount,
      // Map Tariff
      sections: [
        {
          name: "Zusatzkarten Tarif",
          group: Group.tariff,
          items: [
            presenter.configurationExport(null, configurationItem.data.note),
          ],
        },
      ],
      totalPrice: {
        priceType,
        title: "Teilsumme",
        monthlyPrice: {
          mainPrice: null,
          priceStepsDescriptions: [],
        },
        oncePrice: null,
        totalOncePriceDeductions: null,
      },
    });
    // Map Tariff Addons
    if (cardsAddons.length) {
      mapTariffAddonsSummary(
        configurationItem,
        configurationsSummary,
        cardsAddons,
        ICartConfigurationType.CARD,
        index
      );
    }

    summaryConfigurationsArr[index].totalPrice.totalOncePriceDeductions =
      mapCashbackPromotions(
        getCashbackPromotions([cardTariff, ...cardsAddons])
      );

    summaryConfigurationsArr[index].totalPrice.monthlyPrice =
      getConfigurationItemTotalMonthlyPrice(summaryConfigurationsArr[index]);
    summaryConfigurationsArr[index].totalPrice.oncePrice =
      calculateConfigurationItemOnceTotalPrice(summaryConfigurationsArr[index]);
  });
};

export default mapSecondaryCardsSummary;
