import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IResetMagentaNextCalculator } from "./IResetMagentaNextCalculator";

export class ResetMagentaNextCalculator implements IResetMagentaNextCalculator {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(): void {
    this.entity.reset();
    this.legacyEntity.reset();
  }
}
