import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import type { ISubmitEmailUseCase } from "./ISubmitEmail";
import { IOfferSummary } from "../../../presenter/PencilSelling/OfferSummaryPresenter";
import {
  IOfferConfigurationsSummary
} from "../../../../components/new-design/Summary/ISummary";

export class SubmitEmailUseCase implements ISubmitEmailUseCase {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(data: IOfferSummary | IOfferConfigurationsSummary, email: string): Promise<string> {
    return this.repository.submitOrder(data, email);
  }
}
