import React from "react";
import {
  Badge,
  CopyText,
  Heading,
  Price,
  Tile,
  Button,
  A11yText,
} from "@dtpk-cc/components";
import { ActionEditDefault } from "@dtpk-cc/components/dist/icons";
import { trackClick } from "helpers/reactTracking";
import * as styles from "../card-tile.module.scss";

type FamilyCardTileProps = {
  isActive: boolean;
  title: string;
  onClick: () => void;
  onChange: () => void;
  price: number;
  priceSuffix: string;
  content: string;
  className?: string;
  disabled?: boolean;
};

const FamilyCardTile = ({
  isActive,
  title,
  onClick,
  onChange,
  price,
  priceSuffix,
  content,
  className = "",
  disabled = false,
}: FamilyCardTileProps) => (
  <Tile
    variant="shadow"
    tabIndex={0}
    role="button"
    onClick={onClick}
    onKeyPress={onClick}
    customClass={`
        ${styles.cardTile}
        ${styles.tile}
        ${className}
        ${isActive ? styles.active : ""}
        ${disabled ? styles.disabled : ""}
      `}
  >
    {isActive && (
      <Badge
        variants={Badge.Variant.flag}
        customClass={`${styles.rightCornerBadge}`}
      >
        <Badge.Paragraph fonts={Badge.Paragraph.Font.additionalHighlight}>
          <span className={`${styles.cardTile} ${styles.badge}`}>
            Ausgewählt
          </span>
        </Badge.Paragraph>
      </Badge>
    )}
    <Tile.Content customClass={`${styles.cardTile} ${styles.content}`}>
      <div className={`${styles.cardTile} ${styles.headerWrapper}`}>
        <Button
          customClass={`${styles.cardTile} ${styles.button}`}
          variants={[Button.Variant.outline, Button.Variant.icon]}
          onClick={() => {
            onChange();
            trackClick("tarif-modal-open");
          }}
        >
          <Button.Icon icon={ActionEditDefault} />
          <A11yText>Tarif ändern</A11yText>
        </Button>
        <Heading customClass={`${styles.cardTile} ${styles.headingFamily}`}>
          {title}
        </Heading>
        <div className={`${styles.cardTile}`}>
          {content && (
            <CopyText
              customClass={`${styles.cardTile} ${styles.magentaNextText}`}
              variants={[CopyText.Variant.highlight]}
              tag="span"
            >
              {content}
            </CopyText>
          )}
          {price && (
            <Price
              value={price}
              suffix={priceSuffix}
              variants={[
                Price.Variant.brand,
                Price.Variant.large,
                Price.Variant.highlight,
              ]}
            />
          )}
        </div>
      </div>
    </Tile.Content>
  </Tile>
);

export default FamilyCardTile;
