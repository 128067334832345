import { useCallback } from "react";
import { useStores } from "../../stores/BusinessClient";
import { useFetchData } from "./BusinessClient/useFetchData";
import {
  businessClientProductsRequestUrlMap,
  FrameworkContractRequestParams,
} from "../../core/repositories/ProductsRepository/ProductsRepository";
import { IOption } from "../../core/entities/Product/Option/IOption";

export const useGetMappedProductsOptions = (tariffKey: string) => {
  const { offerStore } = useStores();
  const { fetchData } = useFetchData();
  const { setIsLoading } = offerStore.getBaseOffer();
  const getMappedProductsSummary = useCallback((): Promise<{
    landlineOptions: IOption[];
    mobileOptions: IOption[];
  }> => {
    setIsLoading(true);
    const currentFrameworkContract = offerStore.getFrameworkContract();
    const urlParams = new URLSearchParams({
      [FrameworkContractRequestParams.FRAMEWORK_CONTRACT_ID]: `${currentFrameworkContract.id}`,
      ...(tariffKey
        ? { [FrameworkContractRequestParams.TARIFF_KEY]: tariffKey }
        : {}),
    }).toString();

    const url = `${
      businessClientProductsRequestUrlMap[
        offerStore.getBaseOffer().customer.get().customerType
      ]
    }?${urlParams}`;

    return fetchData(url)
      .then(({ summary }) => ({
        landlineOptions: summary.landline.options,
        mobileOptions: summary.mobile.options,
      }))
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchData, offerStore, tariffKey, setIsLoading]);

  return { getMappedProductsSummary };
};
