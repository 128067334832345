import type {
  IOffer,
  ICardSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetCardSettingsUseCase } from "./ISetCardSettings";

export class SetCardSettingsUsecase implements ISetCardSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(settings: ICardSettings): void {
    this.entity.setCardSettings(settings);
  }
}
