import {
  IMobileSettings,
  ICartConfigurationType,
  ICartConfiguration,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { CartItemSummaryPresenter } from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import { Group } from "../../../../core/entities/Product/IProduct";
import {
  calculateConfigurationItemOnceTotalPrice,
  getConfigurationItemTotalMonthlyPrice,
  getCashbackPromotions,
  mapCashbackPromotions,
  mapTariffAddonsSummary,
} from "./index";
import { IConfigurationsSummary } from "../ISummary";

const mapMobileSummary = ({
  offerExportConfigurations,
  configurationsSummary,
  priceType,
}: {
  offerExportConfigurations: ICartConfiguration[];
  configurationsSummary: IConfigurationsSummary;
  priceType: string;
}) => {
  const filteredOfferExportConfigurations = offerExportConfigurations.filter(
    (offerExportedConfigurationItem) =>
      !!offerExportedConfigurationItem.children.getMobileTariff() &&
      !offerExportedConfigurationItem.data.isOmitted
  );

  filteredOfferExportConfigurations.forEach(
    (offerExportedConfigurationItem, index) => {
      const mobileTariff =
        offerExportedConfigurationItem.children.getMobileTariff();

      const configurationSettings = offerExportedConfigurationItem.data
        .settings as IMobileSettings;
      const presenter = new CartItemSummaryPresenter(
        mobileTariff,
        configurationSettings,
        priceType
      );
      const mobileAddons =
        offerExportedConfigurationItem.children.getMobileOptions();
      const tariffBenefit = offerExportedConfigurationItem.data.isBenefitActive
        ? offerExportedConfigurationItem.data.benefit
        : null;
      const summaryConfigurationsArr =
        configurationsSummary[ICartConfigurationType.MOBILE].configurationItems;
      summaryConfigurationsArr.push({
        title: offerExportedConfigurationItem.name
          ? offerExportedConfigurationItem.name
          : `Mobilfunk-Konfiguration Nr. ${index + 1}`,
        amount: offerExportedConfigurationItem.amount,
        // Map Tariff
        sections: [
          {
            name: "Mobilfunk Tarif",
            group: Group.tariff,
            items: [
              presenter.configurationExport(
                tariffBenefit,
                offerExportedConfigurationItem.data.note,
                configurationSettings.loyaltyBonusValue
              ),
            ],
          },
        ],
        totalPrice: {
          priceType,
          title: "Teilsumme",
          monthlyPrice: {
            mainPrice: null,
            priceStepsDescriptions: [],
          },
          oncePrice: null,
          totalOncePriceDeductions: null,
        },
      });

      if (mobileAddons.length) {
        // Map Tariff Addons
        mapTariffAddonsSummary(
          offerExportedConfigurationItem,
          configurationsSummary,
          mobileAddons,
          ICartConfigurationType.MOBILE,
          index
        );
      }
      summaryConfigurationsArr[index].totalPrice.totalOncePriceDeductions =
        mapCashbackPromotions(
          getCashbackPromotions([mobileTariff, ...mobileAddons])
        );

      summaryConfigurationsArr[index].totalPrice.monthlyPrice =
        getConfigurationItemTotalMonthlyPrice(summaryConfigurationsArr[index]);

      summaryConfigurationsArr[index].totalPrice.oncePrice =
        calculateConfigurationItemOnceTotalPrice(
          summaryConfigurationsArr[index]
        );
    }
  );
};

export default mapMobileSummary;
