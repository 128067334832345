import React from "react";
import { observer } from "mobx-react";
import { Flex } from "@dtpk-cc/components";

import { useStores } from "stores";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import ButtonGroups from "../../../../../elements/ButtonGroups";
import { PersonalDataPresenter } from "../../../../../core/presenter/PencilSelling/PersonalDataPresenter";
import { trackClick } from "../../../../../helpers/reactTracking";
import { PersistedWarning } from "../IPersonal";

interface MobileBusinessCasesProps {
  handleSettingsChange: (persistedWarning: PersistedWarning) => void;
  trackingContext: string;
  setIsWarningModalOpen: (value: boolean) => void;
}

const MobileBusinessCases = observer(
  ({
    handleSettingsChange,
    trackingContext,
    setIsWarningModalOpen,
  }: MobileBusinessCasesProps) => {
    const { offerStore, cartStore } = useStores();

    const mobileSettings = offerStore.getMobileSettings();
    const mobileBusinessCaseKey = mobileSettings.businessCase;
    const presenter = new PersonalDataPresenter(false);

    const setBusinessCase = (value) => {
      cartStore.resetCart(PortfolioKeys.MOBILE);
      offerStore.setMobileSettings({
        ...mobileSettings,
        businessCase: value,
      });
      setIsWarningModalOpen(false);
    };

    return (
      <div
        className="
              personal-offer__section
              personal-offer__section--padded
              personal-offer__section--with-bg
              personal-offer__section--full-height
              personal-offer__section--with-radius"
      >
        <Flex
          flexDirection="row"
          customClass="personal-offer__section__list-wrapper personal-offer__input-field personal-offer__input-field--spacer"
          alignItems="center"
          wrap
        >
          <span className="personal-offer__input-label personal-offer__input-label--fw">
            <b>{presenter.mobileBusinessCase.label}</b>
          </span>
          <ButtonGroups
            label="Geschäftsfall"
            name="mobileBusinessCase"
            itemWidthAuto
            itemsAmountLg={2}
            items={presenter.mobileBusinessCase.items}
            displayItemContent={(businessCase) => businessCase.name}
            onChange={(value) => {
              handleSettingsChange({
                value,
                isLandline: false,
                callback: setBusinessCase,
              });
              trackClick(`${value}`, trackingContext);
            }}
            getIsChecked={(businessCase) =>
              businessCase.key === mobileBusinessCaseKey
            }
            getValue={(businessCase) => businessCase.key}
          />
        </Flex>
      </div>
    );
  }
);

export default MobileBusinessCases;
