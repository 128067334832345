import { ProductsMapper } from "core/mapper/ProductsMapper";
import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import { IServicesEntity } from "core/entities/PencilSelling/Services/IServicesEntity";
import type { IFetchProductsUseCase } from "./IFetchProducts";
import { IOffer } from "../../../entities/PencilSelling/Offer_legacy/IOffer";
import { Endpoints } from "../../../repositories/ProductsRepository/IProductsRepository";

export class FetchProductsUseCase implements IFetchProductsUseCase {
  constructor(
    private readonly repository: IProductsRepository,
    private readonly entity: IProductsEntity,
    private readonly serviceEntity: IServicesEntity,
    private offerEntity: IOffer
  ) {}

  async execute(): Promise<void> {
    try {
      const products = await this.repository.getProducts(
        Endpoints.pencil_selling
      );
      const customPromotionsResponse =
        await this.repository.fetchCustomPromotion();
      const streamingAnalysis = await this.repository.getSreamingAnalysis();
      const mapper = new ProductsMapper({
        productResponse: products,
        calculatorResponse: null,
        streamingAnalysisResponse: streamingAnalysis,
        customPromotionsResponse,
      });
      const summary = mapper.productsSummary;
      const { customPromotions } = mapper;
      const offerPromotionSections = this.offerEntity.getCustomPromotions();
      this.offerEntity.setCustomPromotions({
        ...offerPromotionSections,
        ...customPromotions,
      });
      this.entity.setup(summary);
      this.serviceEntity.setup(
        summary?.streamingServices,
        summary?.streamingVariants
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
