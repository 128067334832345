import React from "react";
import { Heading, CopyText } from "@dtpk-cc/components";

import * as styles from "./step-header-title.module.scss";

type StepHeaderTitleProps = {
  title: string;
  subtitle: string;
  titleCustomClass?: string;
  customClass?: string;
};

const StepHeaderTitle: React.FC<StepHeaderTitleProps> = ({
  customClass = "",
  titleCustomClass = "",
  title,
  subtitle,
}) => (
  <div className={`${styles.stepHeaderTitleWrapper} ${customClass}`}>
    <Heading
      isResponsive={false}
      tag="h3"
      variants={[Heading.Variant.secondary, Heading.Variant.display]}
      customClass={`${styles.stepHeaderTitle} ${titleCustomClass}`}
    >
      {title}
    </Heading>
    <CopyText customClass={styles.stepHeaderSubtitle}>{subtitle}</CopyText>
  </div>
);

export default StepHeaderTitle;
