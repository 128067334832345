import React, { useState } from "react";
import { Heading } from "@dtpk-cc/components";
import ModalWrapper from "../ModalWrapper";
import Input from "../Input";
import ModalWrapperActionFooter from "../ModalWrapper/ModalWrapperActionFooter";
import * as styles from "./text-input-modal.module.scss";

type TextInputModalProps = {
  title: string;
  inputPlaceholder: string;
  onSubmit: (inputValue: string) => void;
  onCancel: () => void;
  onInputClick: () => void;
  defaultInputValue: string | null;
  isOpen: boolean;
  maxLength?: number;
  invalidMessage?: string;
  getIsValid?: (inputValue: string) => boolean;
  getSubmitDisabled?: (inputValue: string) => boolean;
};

const NAME_DEFAULT_MAX_LENGTH = 40;

const TextInputModal = ({
  title,
  onSubmit,
  onCancel,
  isOpen,
  inputPlaceholder,
  onInputClick,
  defaultInputValue,
  maxLength = NAME_DEFAULT_MAX_LENGTH,
  invalidMessage = "",
  getIsValid = () => true,
  getSubmitDisabled = () => false,
}: TextInputModalProps) => {
  const [inputValue, setInputValue] = useState(defaultInputValue || "");

  return (
    <ModalWrapper
      containerCustomClass={styles.modalWrapper}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <Heading
        tag="h3"
        customClass="m-b-24"
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        {title}
      </Heading>
      <div className={styles.inputWrapper}>
        <Input
          isValid={getIsValid(inputValue)}
          invalidMessage={invalidMessage}
          maxLength={maxLength}
          name="text-input"
          id="text-input"
          value={inputValue}
          onChange={({ target: { value } }) => {
            setInputValue(value);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              onSubmit(inputValue);
            }
          }}
          placeholder={inputPlaceholder}
          onClick={onInputClick}
        />
      </div>
      <ModalWrapperActionFooter
        submitDisabled={getSubmitDisabled(inputValue)}
        onSubmit={() => onSubmit(inputValue)}
        onCancel={onCancel}
      />
    </ModalWrapper>
  );
};

export default TextInputModal;
