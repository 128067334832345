import { IGetTariffExport } from "./IGetTariffExport";
import {
  ITariff,
  TariffDataExport,
} from "../../../entities/MagentaNext/Tariff/ITariff";
import { ILegacyTariff, LegacyTariffDataExport } from "../../../entities/MagentaNext/LegacyTariff/ILegacyTariff";

export class GetTariffExportUseCase implements IGetTariffExport {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(isLegacy?: boolean): LegacyTariffDataExport | TariffDataExport {
    return (isLegacy ? this.legacyEntity : this.entity).export();
  }
}
