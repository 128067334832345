import { createContext, useContext } from "react";
import {
  tariff,
  legacyTariff,
  productsRepository,
} from "../../core/MagentaNext";

const rootStoresContext = createContext({
  tariffStore: tariff,
  legacyTariffStore: legacyTariff,
  productsRepositoryStore: productsRepository,
});

export const useMagentaNextCalculatorStores = () =>
  useContext(rootStoresContext);
