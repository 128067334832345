import { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { ISetProfileDetailsInPdfIsDisplayed } from "./ISetProfileDetailsInPdfIsDisplayed";

export class SetProfileDetailsInPdfIsDisplayedUseCase
  // eslint-disable-next-line prettier/prettier
  implements ISetProfileDetailsInPdfIsDisplayed {
  constructor(private entity: IOffer) {}

  execute(isDisplayed: boolean): void {
    return this.entity.setProfileDetailsInPdfIsDisplayed(isDisplayed);
  }
}
