import { createContext, useContext } from "react";

import {
  cart,
  homeConfigurator,
  offer,
  productsEntity,
  productsRepository,
  servicesEntity,
} from "core/main";

// anti-pattern
const rootStoresContext = createContext({
  offerStore: offer,
  homeConfiguratorStore: homeConfigurator,
  cartStore: cart,
  productsStore: productsEntity,
  servicesStore: servicesEntity,
  productsRepositoryStore: productsRepository,
});

export const useStores = () => useContext(rootStoresContext);
