import type { IOffer, IOfferExport } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IGetOfferExport } from "./IGetOfferExport";

export class GetOfferExport implements IGetOfferExport {
  constructor(private entity: IOffer) {}

  execute(): IOfferExport {
    return this.entity.export();
  }
}
