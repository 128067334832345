import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import { CopyText, Heading } from "@dtpk-cc/components";

import AddressCheckInputWrapper from "../../elements/new-design/AddressCheck/AddressCheckInputWrapper";
import bgImg from "../../../assets/images/address-check/bg.png";
import BlotImg from "../../../assets/images/address-check/devices-on-blot.png";
import * as styles from "./service-charge-privelege.module.scss";
import { Endpoints } from "../../core/repositories/ProductsRepository/IProductsRepository";
import AddressCheckMsg from "../../elements/new-design/AddressCheck/AddressCheckMsg";
import AddressCheckHeader from "../../elements/new-design/AddressCheck/AddressCheckHeader";
import AddressCheckContainer from "../../elements/new-design/AddressCheck/AddressCheckContainer";
import AddressCheckWrapper from "../../elements/new-design/AddressCheck/AddressCheckWrapper";
import { MagentaBgText } from "../../elements/new-design/AddressCheck/MagentaBgText";

const ServiceChargePrivilege = () => {
  const [selectedAddress, setSelectedAddress] = useState<string>(null);

  return (
    <AddressCheckWrapper
      bgImgUrl={bgImg}
      imgAltValue="service-charge-privelege"
    >
      <AddressCheckContainer>
        <AddressCheckHeader>
          <MagentaBgText
            className={styles.firstHeading}
            text="Aktion zum Wegfall"
          />
          <MagentaBgText
            className={styles.secondHeading}
            text="der Kabel-TV Pflicht"
          />
          <Heading
            tag="h3"
            isResponsive={false}
            customClass={styles.subHeading}
            variants={[Heading.Variant.secondary, Heading.Variant.display]}
          >
            MagentaTV, den Preis-Leistungs-Sieger 9 Monate für 0 € erleben
          </Heading>
          <img className={styles.devicesImg} alt="devices-img" src={BlotImg} />
        </AddressCheckHeader>
        <AddressCheckInputWrapper
          requestUrl={Endpoints.charge_privilege_address_check}
          onAddressSelect={(address) => setSelectedAddress(address)}
          selectedAddress={selectedAddress}
        >
          <CopyText>
            Adresse eingeben und prüfen, ob die Aktion „MagentaTV 9 Monate für 0
            €“ verfügbar ist
          </CopyText>
          <CopyText customClass={styles.terms}>
            Bitte eine Adresse aus den Vorschlägen auswählen.{" "}
            <strong>
              Falls die gewünschte Adresse <u>nicht</u> dabei ist, ist die
              Aktion leider nicht verfügbar.
            </strong>
          </CopyText>
        </AddressCheckInputWrapper>
        {selectedAddress && (
          <AddressCheckMsg
            text={`Die Aktion „MagentaTV 9 Monate für 0 €“ ist für diese Adresse buchbar.
        Die Voraussetzung ist MagentaTV Neukunden.`}
          />
        )}
      </AddressCheckContainer>
    </AddressCheckWrapper>
  );
};

export default hot(ServiceChargePrivilege);
