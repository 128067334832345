import React, { ElementType } from "react";
import { List, CopyText, Icon } from "@dtpk-cc/components";
import ActionSuccessSelected from "@dtpk-cc/components/dist/icons/Action/Success/Selected";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummarySectionOptionsTitle from "../SummarySectionOptionsTitle";
import SummaryList from "../SummaryList";
import {
  IAdditionsType,
  ISummaryAdditions,
} from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import PromotionsIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/promotions.svg";
import SmartphoneIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/smartphone.svg";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";
import * as styles from "./summary-configuration-addition.module.scss";

type SummaryConfigurationAdditionProps = {
  additionItems: ISummaryAdditions[];
  customClass?: string;
  customerPricePrefix: string;
};

type IconsMap = {
  [key in IAdditionsType]?: ElementType | string;
};

const SummaryConfigurationAddition = ({
  customClass = "",
  additionItems,
  customerPricePrefix,
}: SummaryConfigurationAdditionProps) => {
  const iconsMap: IconsMap = {
    [IAdditionsType.DEVICE]: SmartphoneIcon,
    [IAdditionsType.PROMOTION]: PromotionsIcon,
  };

  return additionItems?.length ? (
    <>
      {additionItems.map((additionItem) => (
        <SummarySectionItemWrapper
          key={additionItem.name}
          customClass={customClass}
          variant="borderedInSection"
        >
          <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
            <SummarySectionOptionsTitle
              title={additionItem.name}
              fontColor="blue"
              Icon={iconsMap[additionItem.type]}
            />
          </SummarySectionItemWrapper.Main>
          <SummarySectionItemWrapper.Monthly />
          <SummarySectionItemWrapper.Once />
          {additionItem.items.map((item, index, array) => (
            <React.Fragment key={item.name}>
              <SummarySectionItemWrapper.Main
                // Apply m-b-24 to all rows except the last one
                customClass={index < array.length - 1 ? "m-b-24" : ""}
                variant="paddedLeftInSection"
              >
                <SummaryList customClass="m-l-36">
                  <List.Item
                    icon={{
                      icon: ActionSuccessSelected,
                      color: item.isAlternative
                        ? Icon.Color.gray5
                        : Icon.Color.darkBlue,
                    }}
                    customClass={styles.listItem}
                  >
                    <div>
                      <CopyText
                        customClass={`${
                          item.isAlternative ? styles.isAlternative : ""
                        } m-b-0`}
                      >
                        {item.name}
                      </CopyText>
                      {item.description &&
                        (item.description.summary || item.description.text) && (
                          <div className="m-t-6">
                            {item.description.summary && (
                              <div
                                className="editor-text-wrapper p-r-12"
                                dangerouslySetInnerHTML={{
                                  __html: item.description.summary,
                                }}
                              />
                            )}
                            {item.description.text && (
                              <div
                                className="editor-text-wrapper p-r-12"
                                dangerouslySetInnerHTML={{
                                  __html: item.description.text,
                                }}
                              />
                            )}
                          </div>
                        )}
                    </div>
                  </List.Item>
                </SummaryList>
              </SummarySectionItemWrapper.Main>
              <SummarySectionItemWrapper.Monthly>
                <SummaryConfigurationPriceItem
                  suffix={customerPricePrefix}
                  price={item.monthlyPrice}
                />
              </SummarySectionItemWrapper.Monthly>
              <SummarySectionItemWrapper.Once>
                <SummaryConfigurationPriceItem
                  suffix={customerPricePrefix}
                  price={item.oncePrice}
                  customClass={item.isAlternative ? styles.isAlternative : ""}
                />
              </SummarySectionItemWrapper.Once>
            </React.Fragment>
          ))}
        </SummarySectionItemWrapper>
      ))}
    </>
  ) : null;
};

export default SummaryConfigurationAddition;
