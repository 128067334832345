import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const LottieVisual = ({ file, initialSegment, customClass = "" }) => {
  const animationModel = useRef(null);
  const animationContainer = useRef(null);

  useEffect(() => {
    animationModel.current = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: file,
      renderer: "svg",
      loop: true,
      autoplay: true,
      initialSegment,
      rendererSettings: {
        progressiveLoad: false,
        hideOnTransparent: true,
      },
    });

    return () => {
      if (animationModel.current) {
        animationModel.current.destroy();
      }
    };
  }, [file, initialSegment]);

  return <div className={customClass} ref={animationContainer} />;
};

export default LottieVisual;
