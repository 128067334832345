import React from "react";
import { Heading, List, CopyText, Price } from "@dtpk-cc/components";
import { IConfigurationItemExport } from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import CollapsibleText from "../../../../elements/CollapsibleText";

import * as styles from "./summary-configuration-section-description.module.scss";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import TariffCalculatedMonthlyPrice from "../../../../elements/TariffCalculatedMonthlyPrice";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";

type SummaryConfigurationSectionDescriptionProps = {
  sectionItemDescriptionData: IConfigurationItemExport;
  customerPricePrefix: string;
  customClass?: string;
};

const SummaryConfigurationSectionDescription = ({
  sectionItemDescriptionData,
  customerPricePrefix,
}: SummaryConfigurationSectionDescriptionProps) => {
  const { additionalRenderData, ...sectionItem } = sectionItemDescriptionData;

  const descriptionDataArr = [
    additionalRenderData.additionalDescription,
    sectionItem.details,
    additionalRenderData.provisionText,
  ];

  return (
    <SummarySectionItemWrapper variant="borderedInSection">
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <div className="m-b-6">
          <Heading tag="h3" variants={Heading.Variant.tertiary}>
            {sectionItem.name}
          </Heading>
          {/* Add note */}
          {!!sectionItem?.note && <CopyText>{sectionItem?.note}</CopyText>}
          <List>
            {descriptionDataArr.map((infoItem = "", index) =>
              infoItem ? (
                <List.Item key={`${infoItem} ${index}`}>{infoItem}</List.Item>
              ) : null
            )}
          </List>
        </div>
        <div>
          {(additionalRenderData.productDescriptionData?.summary ||
            additionalRenderData.productDescriptionData?.text) && (
            <CollapsibleText
              descriptionCustomClass={styles.descriptionList}
              headline={additionalRenderData.productDescriptionData?.summary}
              text={additionalRenderData.productDescriptionData?.text}
              productName={additionalRenderData.productBaseName}
            />
          )}
        </div>
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly>
        <TariffCalculatedMonthlyPrice
          priceFontSizeIsLarge
          withMonthlySuffix={false}
          monthlyPrice={sectionItem.monthlyPrice}
          priceStepsDescriptions={
            additionalRenderData.monthlyPriceStepsDescriptions
          }
          monthlyPriceSuffix={sectionItem.monthlyPriceSuffix || null}
          prefix={customerPricePrefix}
          stattPriceDescr={sectionItem.monthlyStattPriceDescr}
        />
      </SummarySectionItemWrapper.Monthly>
      <SummarySectionItemWrapper.Once>
        <div className={styles.oncePriceWrapper}>
          <Price.Text customClass="scaledItem m-r-6">
            {sectionItem.onceStattPriceDescr}
          </Price.Text>
          <SummaryConfigurationPriceItem
            suffix={customerPricePrefix}
            price={sectionItem.oncePrice || 0}
          />
        </div>
      </SummarySectionItemWrapper.Once>
    </SummarySectionItemWrapper>
  );
};

export default SummaryConfigurationSectionDescription;
