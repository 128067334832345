import React from "react";
import NavigationSectionTitle from "../NavigationSectionTitle";
import { INavIconsMap, INavigationMapSection } from "../INavigation";
import NavigationItem from "../NavigationItem";

import * as styles from "./navigation-section.module.scss";

type NavigationSectionProps = {
  navigationMapSection: INavigationMapSection;
  activeItemKey: string | null;
  activeFlyoutKey: string | null;
  setActiveItem: (value: string) => void | null;
  setActiveFlyoutKey: (value: string) => void | null;
  setBackDropIsActive: (value: boolean) => void;
  iconsMap: INavIconsMap;
  userName: string;
};

const NavigationSection = ({
  navigationMapSection,
  activeItemKey,
  iconsMap,
  setActiveItem,
  setActiveFlyoutKey,
  activeFlyoutKey,
  setBackDropIsActive,
  userName,
}: NavigationSectionProps) => (
  <div className={styles.navigationSection}>
    <NavigationSectionTitle title={navigationMapSection.title} />
    {navigationMapSection.items.map(
      ({ title, onClick, withArrow, getChildren }) => (
        <NavigationItem
          key={title}
          title={title}
          onClick={onClick}
          getChildren={getChildren}
          Icon={iconsMap[title]}
          withArrow={withArrow}
          isActive={title === activeItemKey}
          flyoutIsOpen={title === activeItemKey && activeFlyoutKey === title}
          setActiveItem={setActiveItem}
          setActiveFlyoutKey={setActiveFlyoutKey}
          activeFlyoutKey={activeFlyoutKey}
          setBackDropIsActive={setBackDropIsActive}
          userName={userName}
        />
      )
    )}
  </div>
);

export default NavigationSection;
