import React from "react";
import { Icon, List } from "@dtpk-cc/components";
import LpgConfirmSolid from "@dtpk-cc/components/dist/icons/Lpg/ConfirmSolid";
import * as styles from "./tariff-configuration-addons.module.scss";
import LinkBtn from "../../../../../elements/new-design/LinkBtn";

type TariffConfigurationAddonsProps = {
  maxItemsDisplayAmount: number;
  addons: string[];
  actionBtnText?: string;
  openModalHandler?: () => void;
};

const TariffConfigurationAddons = ({
  openModalHandler = () => {},
  actionBtnText = "",
  addons,
  maxItemsDisplayAmount,
}: TariffConfigurationAddonsProps) => {
  const displayLimitIsExceeded = addons.length > maxItemsDisplayAmount;
  const showMoreButtonIsDisplayed =
    displayLimitIsExceeded &&
    addons.reduce((acc, addonItemName) => (addonItemName ? acc + 1 : acc), 0) >
      maxItemsDisplayAmount;

  return (
    <div className={styles.addonsItemsWrapper}>
      <List
        icon={{
          icon: LpgConfirmSolid,
          color: Icon.Color.darkBlue,
        }}
      >
        {(displayLimitIsExceeded
          ? addons
              // Filter from items with empty names
              .filter((addonItem) => addonItem)
              .slice(0, maxItemsDisplayAmount)
          : addons
        ).map((addonItemName, index) =>
          addonItemName ? (
            <List.Item key={`${addonItemName}${index}`}>
              <List.Text>{addonItemName}</List.Text>
            </List.Item>
          ) : null
        )}
      </List>
      {showMoreButtonIsDisplayed && openModalHandler && (
        <LinkBtn onClick={openModalHandler} text={actionBtnText} />
      )}
    </div>
  );
};

export default TariffConfigurationAddons;
