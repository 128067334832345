import React, { useState, useEffect, useCallback, useMemo } from "react";
import { observer } from "mobx-react";

import {
  Heading,
  Grid,
  Checkbox,
  EqualHeight,
  Container,
} from "@dtpk-cc/components";
import TariffPortfolio from "components/TariffPortfolio";
import OfferAdvantage from "elements/OfferAdvantage";

import "./tariff.scss";

import { TariffPresenter } from "core/presenter/PencilSelling/TariffPresenter";
import { trackClick } from "helpers/reactTracking";
import { PortfolioKeys, BenefitKeys } from "core/entities/Product/IProduct";
import {
  ICartItemDataPromotions,
  PaymentTypes,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import { Bandwidths, ITariff } from "core/entities/Product/Tariff/ITariff";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { useStores } from "stores";
import { IMobileSettings } from "core/entities/PencilSelling/Offer_legacy/IOffer";

const Tariff = observer(() => {
  const { offerStore, productsStore, cartStore } = useStores();

  const { tariffs } = productsStore;
  const tariffsInCart = cartStore.getTariffsInCart();
  const currentLandlineExtension = tariffsInCart.landline?.extension || null;
  const [landlineTariff, setLandlineTariff] = useState(
    tariffs[PortfolioKeys.LANDLINE].find(
      ({ key }) => tariffsInCart.landline?.key === key
    )
  );
  const [mobileTariff, setMobileTariff] = useState(
    tariffs[PortfolioKeys.MOBILE].find(
      ({ key }) => tariffsInCart.mobile?.key === key
    )
  );

  const notes = offerStore.getNotes();
  const showLoyaltyBonus = offerStore.showLoyaltyBonus();

  const [smartphoneInfos, setSmartphoneInfos] = useState(
    tariffsInCart.mobile?.additionalDevices || [
      {
        name: "",
        price: "",
        isAlternative: false,
        manufacturer: "",
        suffix: "",
      },
    ]
  );

  const landlineSettings = offerStore.getLandlineSettings();
  const mobileSettings = offerStore.getMobileSettings();

  const [isMobileTariffVisible, setIsMobileTariffVisible] = useState(true);
  const [isLandlineTariffVisible, setIsLandlineTariffVisible] = useState(true);
  const [activeLandlinePromotions, setActiveLandlinePromotions] = useState(
    tariffsInCart[PortfolioKeys.LANDLINE]?.promotions || []
  );
  const [activeMobilePromotions, setActiveMobilePromotions] = useState(
    tariffsInCart[PortfolioKeys.MOBILE]?.promotions || []
  );
  const customPromotions = offerStore.getCustomPromotions();
  const customLandlinePromotions = customPromotions[PortfolioKeys.LANDLINE];
  const customMobilePromotions = customPromotions[PortfolioKeys.MOBILE];

  const { isActive: isBenefitActive } = offerStore.getTariffBenefits();

  const landlineTariffPresenter = useMemo(
    () =>
      new TariffPresenter(
        tariffs[PortfolioKeys.LANDLINE],
        landlineSettings,
        true
      ),
    [landlineSettings, tariffs]
  );

  const mobileTariffPresenter = useMemo(
    () =>
      new TariffPresenter(tariffs[PortfolioKeys.MOBILE], mobileSettings, false),
    [mobileSettings, tariffs]
  );

  const productDescription = useCallback(
    (tariff: ITariff, portfolio: PortfolioKeys) => {
      if (portfolio === PortfolioKeys.LANDLINE) {
        return landlineSettings.fiber
          ? {
              summary: tariff.description.summary_fiber,
              text: TariffPresenter.getLandlineTariffDescription(
                tariff.description.text_fiber,
                isBenefitActive
              ),
            }
          : {
              summary: tariff.description.summary,
              text: TariffPresenter.getLandlineTariffDescription(
                tariff.description.text,
                isBenefitActive
              ),
            };
      }

      return {
        summary: TariffPresenter.getMobileTariffSummary(
          tariff.description.summary,
          tariff.dataVolume,
          tariff.dataVolumeMagentaOne,
          isBenefitActive
        ),
        text: TariffPresenter.getMobileTariffDescription(
          tariff.description.text,
          tariff.dataVolume,
          tariff.dataVolumeMagentaOne,
          isBenefitActive
        ),
      };
    },
    [landlineSettings.fiber, isBenefitActive]
  );

  const toggleLandlineTariffIsVisible = () => {
    setIsLandlineTariffVisible((prev) => !prev);
    trackClick("festnetz-tarife-anzeigen");
  };

  const toggleMobileTariffIsVisible = () => {
    setIsMobileTariffVisible((prev) => !prev);
    trackClick("mobilfunk-tarife-anzeigen");
  };

  const addToCart = useCallback(
    (
      tariff: ITariff,
      promotions: ICartItemDataPromotions,
      additionalDevices,
      portfolio: PortfolioKeys
    ) => {
      const selectedTariffExtension =
        portfolio === PortfolioKeys.LANDLINE ? currentLandlineExtension : null;

      const cartItem = {
        name: tariff.name,
        price: tariff.price,
        key: tariff.key,
        quantity: 1,
        portfolio,
        group: tariff.type,
        description: productDescription(tariff, portfolio),
        paymentType: PaymentTypes.MONTHLY,
        contractPeriod: tariff.contractPeriod,
        promotions,
        additionalDevices,
        isFungible: false,
        tariffWorld: tariff.tariffWorld,
        additionalDescription: tariff.additionalDescription,
        extension: selectedTariffExtension,
      };

      cartStore.addToCart(cartItem);
    },
    [cartStore, productDescription, currentLandlineExtension]
  );

  const setBenefitSettings = useCallback(
    (BenefitIsActive: boolean) => {
      const computedDataVolumeBenefit = () => {
        const hasFlatrateBenefit = mobileTariff.benefits[0]?.options?.some(
          (option) => option?.key === BenefitKeys.dataVolume && !!option.name
        );

        return !hasFlatrateBenefit &&
          BenefitIsActive &&
          mobileTariff?.dataVolumeMagentaOne
          ? {
              name: `${
                mobileTariff?.dataVolumeMagentaOne !== "∞ GB"
                  ? "Zusätzlich "
                  : ""
              }${
                Number(Bandwidths[mobileTariff.size]) >= 3
                  ? mobileTariff.dataVolumeMagentaOne
                  : mobileTariff.dataVolume
              }`,
              key: "datavolumeBenefit",
              portfolio: PortfolioKeys.MOBILE,
              // should be double-checked
              concerned: "",
              description: null,
            }
          : null;
      };

      offerStore.setTariffBenefits({
        landlineBenefit: landlineTariff?.benefits[0] || null,
        mobileBenefit: mobileTariff
          ? {
              ...mobileTariff.benefits[0],
              options: !mobileTariff.benefits[0]?.options
                ? []
                : [
                    ...mobileTariff.benefits[0].options,
                    computedDataVolumeBenefit(),
                  ],
            }
          : null,
        isActive: BenefitIsActive,
      });
    },
    [landlineTariff, mobileTariff, offerStore]
  );

  const toggleBenefit = () => {
    const benefitChangeHandler = (active) => {
      if (!active) {
        offerStore.setMobileSettings({
          ...mobileSettings,
          loyaltyBonusValue: 0,
        });
      }
    };

    setBenefitSettings(!isBenefitActive);
    benefitChangeHandler(!isBenefitActive);
  };

  const getTariffBySize = (
    sizeValue,
    tariffsByPortfolio: ITariff[],
    currentTariff: ITariff,
    isYoung
  ) =>
    tariffsByPortfolio.find(
      (portfolioTariff) =>
        portfolioTariff.size === currentTariff?.size &&
        portfolioTariff.level.key === sizeValue &&
        portfolioTariff.tariffWorld.key === currentTariff.tariffWorld.key &&
        portfolioTariff.isYoung === isYoung
    ) || null;

  const handleLandlineSettingsChange = (key, value) => {
    let tariff = null;
    cartStore.removeTariffFromCart(true);
    offerStore.setLandlineSettings({ ...landlineSettings, [key]: value });

    if (key === "level" && landlineTariff) {
      tariff = getTariffBySize(
        value,
        tariffs[PortfolioKeys.LANDLINE],
        landlineTariff,
        landlineSettings.isYoung
      );
    }

    setActiveLandlinePromotions([]);
    setLandlineTariff(tariff);
  };

  const handleMobileSettingsChange = (
    key: string,
    value: IMobileSettings[keyof IMobileSettings]
  ) => {
    let tariff = null;
    let newSettings: Record<string, IMobileSettings[keyof IMobileSettings]> = {
      [key]: value,
    };

    if (key === "loyaltyBonusValue" && typeof value === "number") {
      newSettings = { ...newSettings, hasProvisionFee: value > 0 };
    }

    offerStore.setMobileSettings({ ...mobileSettings, ...newSettings });

    if (key === "loyaltyBonusValue" || key === "hasProvisionFee") {
      return;
    }
    cartStore.removeTariffFromCart(false);

    if (key === "level" && mobileTariff) {
      tariff = getTariffBySize(
        value,
        tariffs[PortfolioKeys.MOBILE],
        mobileTariff,
        mobileSettings.isYoung
      );
    }
    setActiveMobilePromotions([]);
    setMobileTariff(tariff);
  };

  const removeMobileTariffInCart = () => {
    cartStore.removeTariffFromCart(false);
    setMobileTariff(null);
    setActiveMobilePromotions([]);
  };

  const checkMobileSettingsChange = (key, value, callBack = () => {}) => {
    handleMobileSettingsChange(key, value);
    callBack();
  };

  // Remove from phones array
  const handleSmartphoneInfoRemove = (phoneItemIndex: number) => {
    setSmartphoneInfos((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      updatedDevicesArr.splice(phoneItemIndex, 1);
      return updatedDevicesArr;
    });
  };

  const handleSmartphoneInfoAdd = () => {
    setSmartphoneInfos((devicesArr) => [
      ...devicesArr,
      {
        name: "",
        price: "",
        isAlternative: false,
        suffix: "",
        manufacturer: "",
      },
    ]);
  };

  const handleSmartphoneInfoChange = (
    index: number,
    key: string,
    value: string
  ) => {
    setSmartphoneInfos((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      updatedDevicesArr[index][key] = value;
      return updatedDevicesArr;
    });
  };

  const handleMobileTariffChange = (tariff: ITariff) => {
    const existsInCart =
      tariffsInCart[PortfolioKeys.MOBILE]?.key === tariff.key;
    if (existsInCart) {
      removeMobileTariffInCart();
    } else {
      addToCart(tariff, [], smartphoneInfos, PortfolioKeys.MOBILE);
      setMobileTariff(tariff);
      setActiveMobilePromotions([]);
    }
  };

  const handleLandlineTariffChange = (tariff: ITariff) => {
    const existsInCart =
      tariffsInCart[PortfolioKeys.LANDLINE]?.key === tariff.key;
    if (existsInCart) {
      cartStore.setLandlineTariffExtension(null);
      cartStore.removeTariffFromCart(true);
      setLandlineTariff(null);
    } else {
      addToCart(tariff, [], null, PortfolioKeys.LANDLINE);
      setLandlineTariff(tariff);
    }
    setActiveLandlinePromotions([]);
  };

  const handleAdditionalInfoChange = (
    portfolio: PortfolioKeys,
    value: string
  ) => {
    const currentNotes = offerStore.getNotes();
    offerStore.setNotes({ ...currentNotes, [portfolio]: value });
  };

  const [landlineTariffClass, mobileTariffClass] = [
    isMobileTariffVisible,
    isLandlineTariffVisible,
  ].map((isVisible) =>
    isVisible ? "" : "tariff-offer-wrapper__tariff-group--fw"
  );
  // update cart after promotions or tariff change
  useEffect(() => {
    if (mobileTariff)
      addToCart(
        mobileTariff,
        activeMobilePromotions,
        smartphoneInfos,
        PortfolioKeys.MOBILE
      );
  }, [mobileTariff, activeMobilePromotions, smartphoneInfos, addToCart]);

  useEffect(() => {
    if (landlineTariff) {
      addToCart(
        landlineTariff,
        activeLandlinePromotions,
        null,
        PortfolioKeys.LANDLINE
      );
    }
  }, [activeLandlinePromotions, addToCart, landlineTariff]);

  useEffect(() => {
    setBenefitSettings(isBenefitActive);
  }, [landlineTariff, mobileTariff, isBenefitActive, setBenefitSettings]);

  useEffect(() => {
    // Select first extension by default on tariff select if there was none selected
    if (
      landlineTariff &&
      landlineTariff.extensions.length > 0 &&
      !currentLandlineExtension
    ) {
      cartStore.setLandlineTariffExtension(landlineTariff.extensions[0]);
    }
  }, [landlineTariff, currentLandlineExtension, cartStore]);

  return (
    <Grid data-testid="tariff-tab-container" padded>
      <Grid.Row gutter style={{ margin: "0.75rem 0" }}>
        <Grid.Col style={{ textAlign: "center" }} s={6} m={12} l={12} xl={24}>
          <Heading
            tag="h1"
            variants={[Heading.Variant.secondary]}
            data-qa="quality"
            style={{ textAlign: "center" }}
          >
            Wunschtarife
          </Heading>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row style={{ justifyContent: "center" }}>
        <Checkbox
          onChange={toggleLandlineTariffIsVisible}
          checked={isLandlineTariffVisible}
          isValid
          required={false}
          checkboxProps={{
            tabIndex: -1,
            "aria-hidden": true,
            // @ts-ignore
            "data-testid": "tariff-landline-display-toggle",
          }}
          tabIndex={0}
          role="checkbox"
          value={String(isLandlineTariffVisible)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleLandlineTariffIsVisible();
            }
          }}
        >
          Festnetz-Tarife anzeigen
        </Checkbox>
        <Checkbox
          onChange={toggleMobileTariffIsVisible}
          checked={isMobileTariffVisible}
          checkboxProps={{
            tabIndex: -1,
            "aria-hidden": true,
            // @ts-ignore
            "data-testid": "tariff-mobile-display-toggle",
          }}
          tabIndex={0}
          role="checkbox"
          value={String(isMobileTariffVisible)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleMobileTariffIsVisible();
            }
          }}
        >
          Mobilfunk-Tarife anzeigen
        </Checkbox>
      </Grid.Row>

      <Grid.Row gutter customClass="tariff-offer-wrapper__offer-advantage">
        <OfferAdvantage active={isBenefitActive} onChange={toggleBenefit} />
      </Grid.Row>

      <EqualHeight>
        <Grid.Row gutter>
          {isLandlineTariffVisible && (
            <Container
              customClass={`tariff-offer-wrapper__tariff-group tariff-offer-wrapper__tariff-group--mr ${landlineTariffClass}`}
            >
              <TariffPortfolio
                title="Zuhause"
                promotions={customLandlinePromotions}
                selectedPromotions={activeLandlinePromotions}
                onChangeTariff={handleLandlineTariffChange}
                onChangeSettings={handleLandlineSettingsChange}
                onChangeAdditionalInfo={(value) =>
                  handleAdditionalInfoChange(PortfolioKeys.LANDLINE, value)
                }
                onSelectPromotions={setActiveLandlinePromotions}
                activeTariffInCart={tariffsInCart.landline}
                activeTariff={landlineTariff}
                additionalInfo={notes.landline}
                settings={landlineSettings}
                tariffPresenter={landlineTariffPresenter}
                isLandline
                isBenefitActive={isBenefitActive}
                showTelefonflat={
                  mobileTariff?.tariffWorld.key !==
                  TariffWorldKeys.magenta_mobile_prepaid
                }
              />
            </Container>
          )}
          {isMobileTariffVisible && (
            <Container
              customClass={`tariff-offer-wrapper__tariff-group tariff-offer-wrapper__tariff-group--ml ${mobileTariffClass}`}
            >
              <TariffPortfolio
                title="Mobilfunk"
                activeTariffInCart={tariffsInCart.mobile}
                activeTariff={mobileTariff}
                promotions={customMobilePromotions}
                selectedPromotions={activeMobilePromotions}
                additionalInfo={notes.mobile}
                settings={mobileSettings}
                showSmartphoneInfo
                smartphoneInfos={smartphoneInfos}
                onSmartphoneRemove={handleSmartphoneInfoRemove}
                onSmartphoneAdd={handleSmartphoneInfoAdd}
                onChangeSmartphone={handleSmartphoneInfoChange}
                onChangeTariff={handleMobileTariffChange}
                onChangeSettings={checkMobileSettingsChange}
                onChangeStaticSettings={handleMobileSettingsChange}
                onChangeAdditionalInfo={(value) =>
                  handleAdditionalInfoChange(PortfolioKeys.MOBILE, value)
                }
                onSelectPromotions={setActiveMobilePromotions}
                tariffPresenter={mobileTariffPresenter}
                isBenefitActive={isBenefitActive}
                showLoyaltyBonus={showLoyaltyBonus}
              />
            </Container>
          )}
        </Grid.Row>
      </EqualHeight>
    </Grid>
  );
});

export default Tariff;
