import { Bandwidths, ITariff } from "../core/entities/Product/Tariff/ITariff";
import { IBenefit } from "../core/entities/Product/IBenefit";
import { BenefitKeys, PortfolioKeys } from "../core/entities/Product/IProduct";

export const getMobileBenefitSettings = (
  benefitIsActive: boolean,
  currentMobileTariff: ITariff | null
): IBenefit | null => {
  let benefit: IBenefit | null = null;
  const computedDataVolumeBenefit = () => {
    const hasFlatrateBenefit = currentMobileTariff.benefits[0]?.options?.some(
      (option) => option?.key === BenefitKeys.dataVolume && !!option.name
    );

    return !hasFlatrateBenefit &&
      benefitIsActive &&
      currentMobileTariff?.dataVolumeMagentaOne
      ? {
          name: `${
            currentMobileTariff?.dataVolumeMagentaOne !== "∞ GB"
              ? "Zusätzlich "
              : ""
          }${
            Number(Bandwidths[currentMobileTariff.size]) >= 3
              ? currentMobileTariff.dataVolumeMagentaOne
              : currentMobileTariff.dataVolume
          } - auch für Zusatzkarten`,
          key: "datavolumeBenefit",
          portfolio: PortfolioKeys.MOBILE,
          // should be double-checked
          concerned: "",
          description: null,
        }
      : null;
  };

  if (
    currentMobileTariff &&
    benefitIsActive &&
    currentMobileTariff.benefits?.length
  ) {
    benefit = {
      ...currentMobileTariff.benefits[0],
    };

    if (currentMobileTariff.benefits[0]?.options) {
      const computedDataVolumeBenefitOption = computedDataVolumeBenefit();
      const discountOptionIndex =
        currentMobileTariff.benefits[0].options.findIndex(
          (option) => option.key === "discount"
        );
      benefit.options = [...currentMobileTariff.benefits[0].options];

      if (discountOptionIndex !== -1 && computedDataVolumeBenefitOption) {
        benefit.options.splice(
          discountOptionIndex + 1,
          0,
          computedDataVolumeBenefitOption
        );
      }

      if (discountOptionIndex === -1) {
        benefit.options = [
          ...currentMobileTariff.benefits[0].options,
          ...(computedDataVolumeBenefitOption
            ? [computedDataVolumeBenefitOption]
            : []),
        ];
      }
    }
  }

  return benefit;
};
