import React, { useCallback, useState } from "react";
import { Tldraw } from "@i22-td-dive/sketchboard";

import "./sketchboard.scss";
import { A11yText, Button, Container, Heading } from "@dtpk-cc/components";
import {
  ActionCloseDefault,
  LpgHamburgerButtonSolid,
} from "@dtpk-cc/components/dist/icons";
import PrintSvg from "@dtpk-cc/components/dist/icons/Action/Print/Default";
import RestartSvg from "@dtpk-cc/components/dist/icons/Action/Restart/Default";
import DeviceScreenRotateDefault from "@dtpk-cc/components/dist/icons/Device/ScreenRotate/Default";
import InfoSvg from "@dtpk-cc/components/dist/icons/Alert/Information/Default";
import { trackClick } from "../../helpers/reactTracking";
import PencilSellingToolbarCloseBtn from "../PencilSellingToolbar/PencilSellingToolbarCloseBtn";

const SketchBoard = ({
  getSketchboard,
  toggleSketchboard,
  sketchboardIsActive,
  sketchboardIsVertical,
  printSketchboard,
  // For Business Clients MVP
  withButtons = false,
  sketchboardDocument = null,
  showSketchboardInfo = () => {},
  toggleSketchboardOrientation = () => {},
}) => {
  const [sketchboardToolBarIsActive, setSketchboardToolBarIsActive] =
    useState(true);

  const toggleSketchboardToolBar = () =>
    setSketchboardToolBarIsActive((prevState) => !prevState);

  const onSketchboardMount = useCallback(
    (tldraw) => {
      document
        .querySelector("[data-sketchboard]")
        .addEventListener("contextmenu", (e) => {
          e.preventDefault();
        });

      tldraw.selectTool("Zeichnen");
      tldraw.setCamera([0, 0], 0.5);
      getSketchboard(tldraw);
    },
    [getSketchboard]
  );

  const preventCanvasScrolling = useCallback((data) => {
    const xAxis = data.getPageState().camera.point[0];
    const yAxis = data.getPageState().camera.point[1];
    const currentCameraZoom = data.getPageState().camera.zoom;

    if (yAxis !== 0) {
      data.setCamera([xAxis, 0], currentCameraZoom);
    }
    if (xAxis !== 0) {
      data.setCamera([0, yAxis], currentCameraZoom);
    }
  }, []);

  return (
    <Container
      customClass={`
          sketchboard__container
          ${sketchboardIsActive ? "sketchboard__container--active" : ""}
          ${sketchboardIsVertical ? "portrait" : "landscape"}
        `}
      data-fullscreen="sketchboard"
      aria-hidden={!sketchboardIsActive}
    >
      <div className="sketchboard__title-wrapper">
        {withButtons && (
          <div className="sketchboard__action-btns-wrapper">
            <PencilSellingToolbarCloseBtn
              customClass="sketchboard__close-btn"
              onClick={() => {
                toggleSketchboard();
                trackClick("notizblock-close");
              }}
            />
            <Button
              customClass="sketchboard__print-btn"
              variants={Button.Variant.outline}
              onClick={() => {
                printSketchboard();
                trackClick("notizblock.print");
              }}
              style={{ marginLeft: "0.375rem" }}
            >
              <Button.Icon icon={PrintSvg} />
              Drucken
            </Button>
            <Button
              // customClass="pencil-selling__sketchboard-reset"
              variants={Button.Variant.outline}
              onClick={() => {
                sketchboardDocument?.deleteAll();
                trackClick("notizblock.reset");
              }}
            >
              <Button.Icon icon={RestartSvg} />
              Neue Notiz
            </Button>
            <Button
              variants={[Button.Variant.outline, Button.Variant.icon]}
              onClick={() => {
                showSketchboardInfo();
                trackClick("notizblock.info");
              }}
            >
              <Button.Icon icon={InfoSvg} />
              <A11yText>Info</A11yText>
            </Button>
            <Button
              customClass="pencil-selling__sketchboard-orient-btn"
              variants={[Button.Variant.outline, Button.Variant.icon]}
              onClick={() => {
                toggleSketchboardOrientation();
                trackClick("notizblock.toggle-orientation");
              }}
            >
              <Button.Icon icon={DeviceScreenRotateDefault} />
              <A11yText>Notizblock zurücksetzen</A11yText>
            </Button>
          </div>
        )}
        {!withButtons && (
          <PencilSellingToolbarCloseBtn
            customClass="sketchboard__close-btn"
            onClick={() => {
              toggleSketchboard();
              trackClick("notizblock-close");
            }}
          />
        )}
        <Heading
          tag="h1"
          customClass="sketchboard__title"
          variants={Heading.Variant.secondary}
        >
          Notizblock
        </Heading>
      </div>
      <Container
        customClass={`
            sketchboard ${
              sketchboardIsVertical
                ? "sketchboard--portrait"
                : "sketchboard--landscape"
            }
            ${sketchboardToolBarIsActive ? "sketchboard__toolbar--active" : ""}
          `}
        data-sketchboard
      >
        <Button
          customClass="sketchboard__tool-btn"
          variants={[Button.Variant.outline, Button.Variant.icon]}
          onClick={() => {
            toggleSketchboardToolBar();
            trackClick("notizblock-toolbar-toggle");
          }}
          tabIndex={sketchboardToolBarIsActive ? -1 : 0}
        >
          {sketchboardToolBarIsActive ? (
            <Button.Icon icon={ActionCloseDefault} />
          ) : (
            <Button.Icon icon={LpgHamburgerButtonSolid} />
          )}
          <A11yText>Symbolleiste</A11yText>
        </Button>

        <Tldraw
          onMount={onSketchboardMount}
          showPages={false}
          showMenu={false}
          showSponsorLink={false}
          onChange={preventCanvasScrolling}
        />
      </Container>
    </Container>
  );
};

export default SketchBoard;
