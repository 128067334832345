export const CUSTOM_EXTRA_FORM_DATA = {
  title: "Weiteres Extra hinzufügen",
  namePlaceHolder: "Name des Extras",
  inputTrackNamePrefix: "extras",
  submitValue: "extras.hinzufügen",
};

export const CUSTOM_DEVICE_FORM_DATA = {
  title: "Weiteres Gerät hinzufügen",
  namePlaceHolder: "Gerätename",
  submitValue: "gerät.hinzufügen",
  inputTrackNamePrefix: "geräte",
};