import { makeAutoObservable } from "mobx";
import { ConfigurationsEntity } from "../../Configurations/Configurations";
import { BaseOffer } from "../../BaseOffer/BaseOffer";
import { IOffer, IOfferExport } from "./IOffer";

export class Offer implements IOffer {
  configurationsEntity: ConfigurationsEntity;

  baseOffer: BaseOffer;

  prevContractPrice = "";

  constructor(configurationsEntity: ConfigurationsEntity) {
    this.configurationsEntity = configurationsEntity;
    this.baseOffer = new BaseOffer(configurationsEntity);
    makeAutoObservable(this);
  }

  getBaseOffer(): BaseOffer {
    return this.baseOffer;
  }

  setPrevContractPrice(price: string) {
    this.prevContractPrice = price;
  }

  getPrevContractPrice(): string {
    return this.prevContractPrice;
  }

  export(): IOfferExport {
    return {
      ...this.baseOffer.export(),
      prevContractPrice: this.prevContractPrice,
    };
  }
}
