import type {
  IOffer,
  ILandlineSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetLandlineSettingsUseCase } from "./ISetLandlineSettings";

export class SetLandlineSettingsUseCase implements ISetLandlineSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(settings: ILandlineSettings): void {
    this.entity.setLandlineSettings(settings);
  }
}
