import React from "react";
import { Button } from "@dtpk-cc/components";
import * as styles from "./tile-button-wrapper.module.scss";

type TileButtonWrapperProps = {
  customClass?: string;
  onClick: () => void;
  disabled?: boolean;
};

const TileButtonWrapper: React.FC<TileButtonWrapperProps> = ({
  children,
  onClick,
  customClass = "",
  disabled = false,
}) => (
  <Button
    tabIndex={0}
    variants={[Button.Variant.bare]}
    onClick={onClick}
    disabled={disabled}
    customClass={`${styles.tileButtonWrapper} ${customClass}`}
  >
    {children}
  </Button>
);

export default TileButtonWrapper;
