// extracted by mini-css-extract-plugin
var _1 = "secondary-card-section-row-module-col-ePZ82";
var _2 = "secondary-card-section-row-module-comparising-rbb3J";
var _3 = "secondary-card-section-row-module-dropdown-y6F5Y";
var _4 = "secondary-card-section-row-module-inputsWrapper-QFeOW";
var _5 = "secondary-card-section-row-module-phoneName-EiyY0";
var _6 = "secondary-card-section-row-module-phonePrice-KoABP";
var _7 = "secondary-card-section-row-module-priceCol-d9t2u";
var _8 = "secondary-card-section-row-module-removeBtn-M4AQo";
var _9 = "secondary-card-section-row-module-row-EYwzg";
var _a = "secondary-card-section-row-module-secondaryCardSectionRow-q_54V";
export { _1 as "col", _2 as "comparising", _3 as "dropdown", _4 as "inputsWrapper", _5 as "phoneName", _6 as "phonePrice", _7 as "priceCol", _8 as "removeBtn", _9 as "row", _a as "secondaryCardSectionRow" }
