import {
  ICustomPromotionRequest,
  ICustomPromotionResponse,
} from "../core/repositories/ProductsRepository/ResponseInterface";
import { ICustomPromotion } from "../core/entities/PencilSelling/ICustomPromotion";

export const mapCustomPromotionToRequest = (
  promotion: ICustomPromotion
): ICustomPromotionRequest => ({
  custom_promotion: {
    key: promotion.key,
    name: promotion.name,
    portfolio: promotion.portfolio,
    description: promotion.description,
    type: promotion.discount.kind,
    pricing: {
      value: promotion.discount.value,
      kind: promotion.type,
      quantity: promotion.discount.interval,
      from: promotion.discount.from,
      to: promotion.discount.to,
    },
  },
});

export const mapCustomPromotionFromRequest = (
  promotion: ICustomPromotionResponse | null
): ICustomPromotion | null =>
  promotion
    ? {
        isEditable: true,
        key: promotion.key,
        name: promotion.name,
        description: promotion.description,
        portfolio: promotion.portfolio,
        type: promotion.pricing.kind,
        discount: {
          value: promotion.pricing.value,
          kind: promotion.type,
          interval: promotion.pricing.quantity,
          from: promotion.pricing.from,
          to: promotion.pricing.to,
        },
      }
    : null;
