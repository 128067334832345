import { makeAutoObservable } from "mobx";

import {
  Bandwidths,
  BusinessCasesLandline,
  BusinessCasesMobile,
  TariffLevelsKeys,
} from "core/entities/Product/Tariff/ITariff";

import {
  CardWorldKeys,
  TariffWorldKeys,
} from "core/repositories/ProductsRepository/DefinitionKeys";
import { ExpansionArea } from "core/entities/Applications";
import {
  ICardSettings,
  ILandlineSettings,
  IMobileSettings,
  INotes,
  IOffer,
  IOfferExport,
} from "./IOffer";
import { IPromotionSections, ICustomPromotion } from "../ICustomPromotion";
import type { ICart } from "../Cart/ICart";
import type { ICustomerEntity } from "../Customer/ICustomer";
import type { ISalesPerson } from "../SalesPerson/ISalesPerson";
import type { IBenefitConfig } from "../../Product/IBenefit";
import { Customer } from "../Customer/Customer";
import { Group, PortfolioKeys } from "../../Product/IProduct";
import { IPromotionSectionsKeys } from "../IPromotionSectionsKeys";

export const initialLandlineSettings: ILandlineSettings = {
  portfolio: PortfolioKeys.LANDLINE,
  businessCase: BusinessCasesLandline.NEUKUNDE,
  fiber: false,
  bandwidth: Bandwidths.XL,
  isYoung: false,
  isVario: null,
  tariffWorld: TariffWorldKeys.magenta_home_tv,
  level: "magenta_tv_smart",
  expansionArea: ExpansionArea.telekom,
} as const;

export const initalMobileSettings: IMobileSettings = {
  portfolio: PortfolioKeys.MOBILE,
  // TODO possibly could be set up as optional or union with null
  businessCase: BusinessCasesMobile.NEUVERTRAG,
  isYoung: false,
  isVario: null,
  tariffWorld: TariffWorldKeys.magenta_mobile,
  // TODO: Through AdminGUI level key can be arbitrary. Switch from string check to primitive value check
  level: TariffLevelsKeys.PHONE_NONE,
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
};

export const initialCardSettings: ICardSettings = {
  isYoung: false,
  isVario: null,
  tariffWorld: TariffWorldKeys.magenta_mobile,
  level: TariffLevelsKeys.PHONE_NONE,
  tariff: null,
  inheritedDataVolume: null,
  benefitIsActive: true,
};

const initialNotes = {
  landline: "",
  mobile: "",
};

const initialCustomPromotions: IPromotionSections = {
  [PortfolioKeys.MOBILE]: [],
  [PortfolioKeys.LANDLINE]: [],
  [Group.card]: [],
};

const initialBenefitConfig: IBenefitConfig = {
  landlineBenefit: null,
  mobileBenefit: null,
  isActive: true,
};

export class OfferEntity implements IOffer {
  cart: ICart;

  customer: ICustomerEntity = new Customer();

  salesPerson: ISalesPerson;

  validUntil = { date: null };

  profileDetailsInPdfIsDisplayed = true;

  constructor(cart: ICart) {
    this.cart = cart;
    makeAutoObservable(this);
  }

  private landlineSettings: ILandlineSettings = initialLandlineSettings;

  private mobileSettings: IMobileSettings = initalMobileSettings;

  private cardSettings: ICardSettings = initialCardSettings;

  private notes: INotes = initialNotes;

  private customPromotions: IPromotionSections = initialCustomPromotions;

  private tariffBenefitConfig: IBenefitConfig = {
    landlineBenefit: null,
    mobileBenefit: null,
    isActive: true,
  };

  getLandlineSettings(): ILandlineSettings {
    return this.landlineSettings;
  }

  setLandlineSettings(settings: ILandlineSettings) {
    this.landlineSettings = settings;
  }

  getMobileSettings(): IMobileSettings {
    return this.mobileSettings;
  }

  setMobileSettings(settings: IMobileSettings) {
    this.mobileSettings = settings;
  }

  getCardSettings(): ICardSettings {
    return this.cardSettings;
  }

  setCardSettings(settings: ICardSettings) {
    this.cardSettings = settings;
  }

  showLoyaltyBonus(): boolean {
    return (
      this.mobileSettings.tariffWorld === TariffWorldKeys.magenta_mobile &&
      !this.mobileSettings.isYoung
    );
  }

  getNotes(): INotes {
    return this.notes;
  }

  setNotes(notes: INotes): void {
    this.notes = notes;
  }

  getCustomPromotions(): IPromotionSections {
    return this.customPromotions;
  }

  setCustomPromotions(promotions: IPromotionSections): void {
    this.customPromotions = promotions;
  }

  replaceCustomPromotion(
    promotionSection: IPromotionSectionsKeys,
    promotion: ICustomPromotion
  ): void {
    const customPromotionSection = this.customPromotions[promotionSection];
    this.customPromotions[promotionSection] = customPromotionSection.map(
      (promotionItem) =>
        promotionItem.key === promotion.key ? promotion : promotionItem
    );
  }

  removeCustomPromotion(
    promotionSection: IPromotionSectionsKeys,
    promotionKey: string
  ): void {
    const customPromotionSection = this.customPromotions[promotionSection];
    this.customPromotions[promotionSection] = customPromotionSection.filter(
      (promotion: ICustomPromotion) => promotion.key !== promotionKey
    );
  }

  getTariffBenefits(): IBenefitConfig | null {
    return this.tariffBenefitConfig;
  }

  setTariffBenefits(config: IBenefitConfig): void {
    this.tariffBenefitConfig = config;
  }

  getValidUntil(): Date {
    return this.validUntil.date as Date;
  }

  setValidUntil(validUntil: Date): void {
    this.validUntil = {
      date: validUntil,
    };
  }

  getProfileDetailsInPdfIsDisplayed(): boolean {
    return this.profileDetailsInPdfIsDisplayed;
  }

  setProfileDetailsInPdfIsDisplayed(isDisplayed: boolean): void {
    this.profileDetailsInPdfIsDisplayed = isDisplayed;
  }

  export(): IOfferExport {
    const cards = this.cart.getCards();

    const cardsWithoutMultiSim = cards.filter(
      (card) => card.tariffWorld.key !== CardWorldKeys.magenta_mobile_multi_sim
    );
    const multiSimCards = cards
      .filter(
        (card) =>
          card.tariffWorld.key === CardWorldKeys.magenta_mobile_multi_sim
      )
      .map((card) => ({ ...card, name: `${card.name} für Hauptkarte` }));

    return {
      customerNote: this.customer.get().notice,
      details: {
        consultingTime: this.customer.get().consultingTime,
        validUntil: this.getValidUntil(),
      },
      cartSummary: {
        benefits: this.getTariffBenefits(),
        notes: this.getNotes(),
        mobileSettings: this.getMobileSettings(),
        landlineSettings: this.getLandlineSettings(),
        landlineTariff: this.cart.getLandlineTariff(),
        mobileTariff: this.cart.getMobileTariff(),
        devices: this.cart.getDevices(),
        landlineOptions: this.cart.getLandlineOptions(),
        cards: cardsWithoutMultiSim,
        multiSimCards,
        mobileOptions: this.cart.getMobileOptions(),
      },
    };
  }

  reset(): void {
    this.cart.reset();
    this.customer.reset();
    this.setLandlineSettings(initialLandlineSettings);
    this.setMobileSettings(initalMobileSettings);
    this.setCardSettings(initialCardSettings);
    this.setNotes(initialNotes);
    this.setTariffBenefits(initialBenefitConfig);
  }
}
