import React from "react";
import { Container } from "@dtpk-cc/components";
import VideoTutorial from "pencil-selling-tool/notizblock-styles-tutorial.mp4";
import ImageTutorial from "pencil-selling-tool/notizblock-tools-tutorial.jpg";

const SketchboardTutorial = ({ customClass = "" }) => (
  <Container
    customClass={`pencil-selling__sketchboard-info-modal ${customClass}`}
  >
    <img
      className="pencil-selling__sketchboard-info-modal-item"
      src={ImageTutorial}
      alt="Notizblock Tutorial"
    />
    <video
      className="pencil-selling__sketchboard-info-modal-item"
      playsInline
      autoPlay
      muted
      loop
    >
      <source src={VideoTutorial} type="video/mp4" />
    </video>
  </Container>
);

export default SketchboardTutorial;
