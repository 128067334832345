import React from "react";
import { CopyText } from "@dtpk-cc/components";
import PriceInput from "elements/PriceInput";
import LoyBonusIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/loyalty.svg";
import { trackClick } from "../../../../../helpers/reactTracking";

import * as styles from "./tariff-selection-loyalty-bonus.module.scss";
import { MAX_LOYALTY_BONUS } from "../../../../LoyaltyBonus";

type TariffSelectionLoyaltyBonusProps = {
  value: number;
  onChange: (value) => void;
  customClass?: string;
  trackingContext?: string;
};

const TariffSelectionLoyaltyBonus = ({
  onChange,
  value,
  trackingContext = "",
  customClass = "",
}: TariffSelectionLoyaltyBonusProps) => (
  <div className={`${styles.loyaltyBonus} ${customClass}`}>
    <LoyBonusIcon className={styles.loyaltyBonusIcon} />
    <CopyText customClass={styles.text}>Treuebonus hinzufügen</CopyText>
    <div className={styles.inputWrapper}>
      <PriceInput
        maxValue={MAX_LOYALTY_BONUS}
        value={value}
        onChange={onChange}
        suffix="€"
        onClick={() => {
          trackClick("treuebonus-input", trackingContext);
        }}
      />
    </div>
  </div>
);

export default TariffSelectionLoyaltyBonus;
