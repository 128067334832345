import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import type { IGetPromotions } from "./IGetPromotions";

export class GetPromotions implements IGetPromotions {
  constructor(private readonly entity: ITariff) {}

  execute(): IPromotion[] {
    return this.entity.getPromotions();
  }
}
