import React from "react";
import WarningModal from "../../WarningModal";
import { trackClick } from "../../../../helpers/reactTracking";
import { TARIFF_ADDONS_RESET_DIALOG_DATA } from "../const";
import { IStepTrackingContext } from "../../../../views/PencilSelling/IPencilSelling";

type AddonsResetWarningModalProps = {
  trackingContext: IStepTrackingContext;
  resetOptionsModalCallback: () => void;
  setResetOptionsModalIsOpen: (value: React.SetStateAction<boolean>) => void;
  resetOptionsModalIsOpen: boolean;
};

const AddonsResetWarningModal = ({
  trackingContext,
  resetOptionsModalCallback,
  setResetOptionsModalIsOpen,
  resetOptionsModalIsOpen,
}: AddonsResetWarningModalProps) => (
  <WarningModal
    onSubmit={() => {
      trackClick(
        `${trackingContext.portfolio}-addons-zurückgesetzt`,
        trackingContext.main
      );
      resetOptionsModalCallback();
      setResetOptionsModalIsOpen(false);
    }}
    onCancel={() => setResetOptionsModalIsOpen(false)}
    isOpen={resetOptionsModalIsOpen}
    mainText={TARIFF_ADDONS_RESET_DIALOG_DATA.mainText}
    titleText={TARIFF_ADDONS_RESET_DIALOG_DATA.title}
  />
);

export default AddonsResetWarningModal;
