import React, {
  ElementType,
  ReactNode,
} from "react";
import { Button, CopyText, Icon } from "@dtpk-cc/components";
import * as styles from "./step-header-action-btn.module.scss";

type StepHeaderActionBtnProps = {
  onClick: (value?: unknown) => void;
  buttonCustomClass?: string;
  buttonIconCustomClass?: string;
  icon: ElementType<ReactNode>;
  text: string;
};

const StepHeaderActionBtn = ({
  buttonCustomClass = "",
  buttonIconCustomClass = "",
  onClick,
  icon,
  text,
}: StepHeaderActionBtnProps) => (
  <Button
    customClass={`${styles.actionBtn} ${buttonCustomClass}`}
    variants={Button.Variant.bare}
    onClick={onClick}
  >
    <Icon
      customClass={styles.icon}
      wrapperProps={{
        className: `${styles.actionBtnIcon} ${styles.icon} ${buttonIconCustomClass}`,
      }}
      icon={icon}
    />
    <CopyText
      variants={CopyText.Variant.highlight}
      customClass={styles.actionBtnText}
    >
      {text}
    </CopyText>
  </Button>
);

export default StepHeaderActionBtn;
