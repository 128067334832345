import { useState } from "react";
import { NAV_TITLE_BASE_DATA } from "../../../components/new-design/Navigation/const";

export interface IUseNavigationStateProps {
  activeFlyoutKey: string;
  activeFlyoutItemKey: string;
  activeTabItemKey: string;
  setActiveFlyoutKey: (
    value: ((prevState: string | null) => string | null) | string | null
  ) => void;
  setBackDropIsActive: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
  setActiveFlyoutItemKey: (
    value: ((prevState: string | null) => string | null) | string | null
  ) => void;
  setActiveTabItemKey: (
    value: ((prevState: string) => string) | string
  ) => void;
  displayConfigurationFlyout: (flyoutKey: string) => void;
  backDropIsActive: boolean;
}

export const useNavigationState = (
  initialActiveMenuItem = NAV_TITLE_BASE_DATA
) => {
  // Sets active class to navigation items with flyout
  const [activeFlyoutItemKey, setActiveFlyoutItemKey] = useState<string | null>(
    null
  );
  // Sets active class to navigation tab items
  const [activeTabItemKey, setActiveTabItemKey] = useState(
    initialActiveMenuItem
  );

  // Sets the key for currently displayed flyout. -> Only one flyout could be displayed at a time
  const [activeFlyoutKey, setActiveFlyoutKey] = useState<string | null>(null);
  const [backDropIsActive, setBackDropIsActive] = useState(false);

  const displayConfigurationFlyout = (flyoutKey: string) => {
    setBackDropIsActive(true);
    setActiveFlyoutKey(flyoutKey);
  };

  return {
    activeFlyoutItemKey,
    setActiveFlyoutItemKey,
    activeTabItemKey,
    setActiveTabItemKey,
    activeFlyoutKey,
    setActiveFlyoutKey,
    backDropIsActive,
    setBackDropIsActive,
    displayConfigurationFlyout,
  };
};
