import React from "react";
import { CopyText, Heading } from "@dtpk-cc/components";
import ComingSoonImg from "../../../../assets/images/address-check/ComingSoon.png";

import "./coming-soon.scss";

export const ComingSoon = () => (
  <div className="coming-soon">
    <div className="coming-soon__info">
      <Heading
        tag="h1"
        variants={[Heading.Variant.secondary, Heading.Variant.display]}
        customClass="coming-soon__info-heading"
      >
        Coming Soon!
      </Heading>
      <CopyText>
        Ab dem <b>5. Oktober</b> steht der Adress-Check für die Aktion zum
        Wegfall der Kabel-TV Pflicht <b>unter diesem Link</b> zur Verfügung.
      </CopyText>
      <CopyText customClass="coming-soon__info-unless">Bis dahin!</CopyText>
    </div>
    <img src={ComingSoonImg} alt="Coming Soon" />
  </div>
);
