import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetHasProvisionFee } from "./IGetHasProvisionFee";

export class GetHasProvisionFee implements IGetHasProvisionFee {
  constructor(private readonly entity: ITariff) {}

  execute(): boolean {
    return this.entity.getHasProvisionFee();
  }
}
