import React, { useMemo, forwardRef } from "react";
import { observer } from "mobx-react";
import { Grid } from "@dtpk-cc/components";
import * as styles from "views/MagentaNext/magenta-next.module.scss";
import { useAveragePrices } from "hooks/MagentaNext/useAveragePrices";
import {
  getAverageCardSummaryTitle,
  getCardSummaryTitle,
} from "helpers/GetCardSummaryTitle";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { PriceSummary } from "../PriceSummary";
import { ConfiguratorWrapper } from "../ConfiguratorWrapper";

type TariffConfiguratorSectionWrapperProps = {
  isLegacyTariff: boolean;
  configuratorWrapperTitle: string;
  gridColClasses?: string;
  colDivClasses?: string;
  isLegacyTariffVisible?: boolean;
  priceSummaryHeight?: number;
};

const TariffConfiguratorSectionWrapper = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TariffConfiguratorSectionWrapperProps>
>(
  (
    {
      isLegacyTariff,
      configuratorWrapperTitle,
      gridColClasses,
      colDivClasses,
      isLegacyTariffVisible,
      children,
      priceSummaryHeight,
    },
    ref
  ) => {
    const { tariffStore, legacyTariffStore } = useMagentaNextCalculatorStores();
    const { averagePrice, legacyAveragePrice } = useAveragePrices();

    const settings = tariffStore.getSettings();
    const totalQuantity = tariffStore.getTotalQuantity();
    const totalPrice = tariffStore.getTotalPrice();
    const totalCredit = tariffStore.getPromotionsTotalPrice();
    const savingsTotalPrice = tariffStore.getSavingsTotalPrice();
    const legacyCustomTotalPrice = legacyTariffStore.getCustomTotalPrice();
    const legacyCustomTotalQuantity =
      legacyTariffStore.getCustomTotalQuantity();

    const priceSummaryProps = useMemo(() => {
      if (isLegacyTariff) {
        return {
          totalPrice: legacyCustomTotalPrice,
          quantity: legacyCustomTotalQuantity,
          averagePrice: legacyAveragePrice,
          classes: `${styles.magentaNext} ${styles.priceSummary} ${styles.priceSummaryLeft}`,
        };
      }
      return {
        totalPrice: Math.max(0, totalPrice - totalCredit),
        quantity: totalQuantity,
        averagePrice,
        credit: savingsTotalPrice,
        classes: `${styles.magentaNext} ${styles.priceSummary} ${
          isLegacyTariffVisible ? styles.priceSummaryRight : ""
        }`,
      };
    }, [
      averagePrice,
      isLegacyTariff,
      isLegacyTariffVisible,
      legacyAveragePrice,
      legacyCustomTotalPrice,
      legacyCustomTotalQuantity,
      savingsTotalPrice,
      totalCredit,
      totalPrice,
      totalQuantity,
    ]);

    if (isLegacyTariff && !isLegacyTariffVisible) {
      return null;
    }

    return (
      <Grid.Col
        s={6}
        m={12}
        l={isLegacyTariffVisible ? 6 : 10}
        xl={isLegacyTariffVisible ? 12 : 16}
        customClass={gridColClasses}
      >
        <div className={colDivClasses}>
          <ConfiguratorWrapper
            title={configuratorWrapperTitle}
            isExpanded={!isLegacyTariff}
          >
            {children}
          </ConfiguratorWrapper>
          <div
            ref={ref}
            className={`${styles.magentaNext} ${styles.equalHeightSummary}`}
            style={{
              height: priceSummaryHeight ? `${priceSummaryHeight}px` : null,
            }}
          >
            <PriceSummary
              totalPrice={priceSummaryProps.totalPrice}
              averagePrice={priceSummaryProps.averagePrice}
              title={getCardSummaryTitle(
                settings,
                priceSummaryProps.quantity,
                isLegacyTariff
              )}
              credit={priceSummaryProps.credit}
              averagePriceTitle={getAverageCardSummaryTitle(
                settings,
                isLegacyTariff
              )}
              className={priceSummaryProps.classes}
            />
          </div>
        </div>
      </Grid.Col>
    );
  }
);

export default observer(TariffConfiguratorSectionWrapper);
