import React, { useMemo } from "react";
import { CopyText, Heading, Price, Button, Icon } from "@dtpk-cc/components";
import PlusIcon from "../../../../../../assets/images/home-configurator/icons/plus.svg";
import MinusIcon from "../../../../../../assets/images/home-configurator/icons/minus.svg";
import {
  REPEATERS_AMOUNT_MAX,
  DeviceTypes,
} from "../../../../../core/entities/PencilSelling/HomeConfigurator/IHomeConfigurator";
import { trackClick } from "../../../../../helpers/reactTracking";
import * as styles from "./configurator-device-item.module.scss";

const devicesData = {
  [DeviceTypes.ROUTER]: {
    subtitle: "Mesh-Router",
    link: {
      text: "Mehr erfahren",
    },
  },
  [DeviceTypes.REPEATER]: {
    subtitle: "Mesh-Verstärker",
    link: {
      text: "Mehr erfahren",
    },
  },
};

type ConfiguratorDeviceItemProps = {
  type: string;
  title: string;
  price: number;
  imgSrc: string;
  amount?: number;
  amountChangeHandler?: (value: number) => void;
  trackingContext: string;
};

const ConfiguratorDeviceItem = ({
  type,
  title,
  price,
  imgSrc,
  amount = 0,
  amountChangeHandler = (value) => {},
  trackingContext,
}: ConfiguratorDeviceItemProps) => {
  const currentDeviceData = useMemo(() => ({ ...devicesData[type] }), [type]);

  return (
    <div className={styles.configuratorDeviceItem}>
      <div className={styles.content}>
        <img className={styles.img} src={imgSrc} alt="device-img" />
        <div className={styles.textual}>
          <Heading
            customClass={styles.configuratorDeviceItemTitle}
            tag="h3"
            variants={[Heading.Variant.quaternary, Heading.Variant.display]}
          >
            {title}
          </Heading>
          <CopyText customClass={styles.subtitle}>
            {currentDeviceData.subtitle}
          </CopyText>
        </div>
        <div className={styles.data}>
          {type === DeviceTypes.REPEATER && (
            <div className={styles.amountWrapper}>
              <Button
                customClass={styles.changeAmountBtn}
                disabled={amount === 0}
                onClick={() => {
                  amountChangeHandler(amount - 1);
                  trackClick("gerätemenge-reduzieren", trackingContext);
                }}
                variants={Button.Variant.roundOutline}
              >
                <Icon
                  customClass={`${styles.changeAmountBtnReduceIcon} ${styles.changeAmountBtnIcon}`}
                  icon={MinusIcon}
                  size={Icon.Size.small}
                />
              </Button>
              <div className={styles.amountValue}>{amount}</div>
              <Button
                customClass={styles.changeAmountBtn}
                disabled={amount === REPEATERS_AMOUNT_MAX}
                onClick={() => {
                  trackClick("gerätemenge-erhöhen", trackingContext);
                  amountChangeHandler(amount + 1);
                }}
                variants={Button.Variant.roundOutline}
              >
                <Icon
                  customClass={`${styles.changeAmountBtnIncreaseIcon} ${styles.changeAmountBtnIcon}`}
                  icon={PlusIcon}
                  size={Icon.Size.small}
                />
              </Button>
            </div>
          )}
          {!!price && (
            <div className={styles.dataPrice}>
              <Price variants={Price.Variant.highlight} value={price} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfiguratorDeviceItem;
