import React, { useState, useRef } from "react";
import { Container, Icon } from "@dtpk-cc/components";
import { LpgExpandOutline } from "@dtpk-cc/components/dist/icons";
import "elements/CollapsibleText/collapsible-text.scss";

type CollapsibleTextProps = {
  headline: string;
  text: string;
  toggleCollapsibleElement?: () => void;
  productName: string;
  descriptionCustomClass?: string;
};

const CollapsibleText = ({
  headline,
  text,
  toggleCollapsibleElement,
  productName,
  descriptionCustomClass = "",
}: CollapsibleTextProps) => {
  const [active, setActive] = useState<boolean>(false);

  const collapsedElement = useRef<HTMLDivElement | null>(null);
  const elementHeight =
    collapsedElement.current && active
      ? `${collapsedElement.current.scrollHeight}px`
      : "0px";

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>,
    isActive: boolean
  ) => {
    if (event.key === "Enter") {
      setActive(isActive);
    }
  };
  //  TODO Refactor component styling to css modules
  return (
    <Container customClass={"collapsible-text"}>
      <Container
        customClass={"collapsible-text__headline"}
        onClick={toggleCollapsibleElement}
      >
        <div className="collapsible-text__wrapper">
          <div
            className="collapsible-text__icon-wrapper"
            tabIndex={0}
            role="button"
            onClick={() => setActive(!active)}
            onKeyPress={(event) => handleKeyPress(event, !active)}
            aria-label={`Produktbeschreibung von ${productName} auf- und einklappen`}
          >
            <Icon
              customClass="collapsible-text__icon"
              icon={LpgExpandOutline}
              size={Icon.Size.large}
              wrapperProps={{
                style: {
                  width: "auto",
                  transition: "transform 400ms ease",
                  transform: `rotate(${active ? "-180deg" : "0"})`,
                },
              }}
            />
          </div>
          <div
            className="collapsible-text__html-elements"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </div>
      </Container>
      <div
        ref={collapsedElement}
        style={{ height: elementHeight }}
        className={`collapsible-text__description ${descriptionCustomClass}`}
      >
        <div
          className="collapsible-text__html-elements"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </Container>
  );
};

export default CollapsibleText;
