import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ISetLegacyTariffCustomPrice } from "./ISetLegacyTariffCustomPrice";

export class SetLegacyTariffCustomPrice implements ISetLegacyTariffCustomPrice {
  constructor(private readonly entity: ILegacyTariff) {}

  execute(price: number): void {
    return this.entity.setCustomTotalPrice(price);
  }
}
