import React, { useState } from "react";
import { observer } from "mobx-react";
import { Tile, Icon, Button, CopyText } from "@dtpk-cc/components";
import { ActionRemoveDefault } from "@dtpk-cc/components/dist/icons";
import CustomPromotionForm from "../CustomPromotionForm";
import CustomItem from "../../../elements/CustomItem";
import PromotionListTitle from "./PromotionListTitle";
import PromotionListActionGroup from "./PromotionListActionGroup";
import TrashCanIcon from "../../../../assets/images/icons/trash-can.svg";
import { useStores } from "../../../stores";
import { trackClick } from "../../../helpers/reactTracking";

import "./promotion-section-action-block.scss";
import { useAddTariffPromotionToOffer } from "../../../hooks/PencilSelling/useAddTariffPromotionToOffer";

const DEFAULT_FORM_CONFIG = {
  name: "",
  type: "",
  price: null,
  key: null,
  interval: null,
};

const TRACKING_CONTEXT = "individuelle Werbung";

const PromotionListActionSection = ({
  isAddMode,
  setIsAddMode,
  isFormVisible,
  setIsFormVisible,
  portfolioKey,
  promotions,
  setActivePromotions,
  highlightedPromotions,
  setHighlightedPromotions,
  setFormState,
  formState,
}) => {
  const { productsRepositoryStore, offerStore } = useStores();
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore);
  const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReplaceCustomPromotion = async (promotion) => {
    const customPromotion = await productsRepositoryStore.editCustomPromotion(
      promotion
    );
    offerStore.replaceCustomPromotion(
      customPromotion.portfolio,
      customPromotion
    );
  };

  const addCustomPromotion = async (promotionItem) => {
    const customPromotion = await productsRepositoryStore.postCustomPromotion(
      promotionItem
    );
    addPromotion(customPromotion, portfolioKey);
  };

  const openCreatePromotionForm = () => {
    setIsAddMode(true);
    setIsFormVisible(true);
    setFormState(DEFAULT_FORM_CONFIG);
  };

  const removePromotionHandler = async () => {
    try {
      setIsLoading(true);
      const filterActivePromotions = (promotionItems) => {
        const promotionExist = promotionItems.some(
          (promotionItem) => promotionItem.key === formState.key
        );

        if (promotionExist) {
          return promotionItems.filter(
            (promotionItem) => promotionItem.key !== formState.key
          );
        }
        return promotionItems;
      };
      await productsRepositoryStore.deleteCustomPromotion(formState.key);
      setDeleteConfirmModalIsOpen(false);
      setIsFormVisible(false);
      offerStore.removeCustomPromotion(portfolioKey, formState.key);
      setActivePromotions((prevSelectedPromotions) =>
        filterActivePromotions(prevSelectedPromotions)
      );
      setHighlightedPromotions((prevHighlightedPromotions) =>
        filterActivePromotions(prevHighlightedPromotions)
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="promotion-action-block">
      {!isFormVisible && !deleteConfirmModalIsOpen && (
        <CustomItem
          cssClass="promotion-action-block__action-block"
          text="Eigene Aktion hinzufügen"
          handleOnClick={() => {
            openCreatePromotionForm();
            trackClick("öffnen-sie-das-erstellungsformular", TRACKING_CONTEXT);
          }}
        />
      )}
      {isFormVisible && (
        <Tile
          customClass="promotion-action-block__action-block"
          variant="shadow"
        >
          <Tile.Content>
            <div className="promotion-action-block__heading">
              <PromotionListTitle />
              <div className="promotion-action-block__action-group">
                {isAddMode && (
                  <span className="promotion-action-block__beta-mark">
                    BETA
                  </span>
                )}
                {!isAddMode && (
                  <Button
                    disabled={isLoading}
                    variants={Button.Variant.bare}
                    onClick={() => {
                      setIsFormVisible(false);
                      setDeleteConfirmModalIsOpen(true);
                      trackClick(
                        "öffnen-sie-den-löschbestätigungsdialog",
                        TRACKING_CONTEXT
                      );
                    }}
                  >
                    <Icon size="small" icon={ActionRemoveDefault} />
                  </Button>
                )}
              </div>
            </div>
            {isFormVisible && (
              <CustomPromotionForm
                formState={formState}
                setFormState={setFormState}
                setIsFormVisible={setIsFormVisible}
                onAdd={addCustomPromotion}
                onEdit={handleReplaceCustomPromotion}
                isAddMode={isAddMode}
                defaultFormConfig={DEFAULT_FORM_CONFIG}
                portfolioKey={portfolioKey}
                promotions={promotions}
                setActivePromotions={setActivePromotions}
                highlightedPromotions={highlightedPromotions}
                setHighlightedPromotions={setHighlightedPromotions}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            )}
          </Tile.Content>
        </Tile>
      )}
      {deleteConfirmModalIsOpen && (
        <Tile
          customClass="promotion-action-block__action-block"
          variant="shadow"
        >
          <Tile.Content>
            <div className="promotion-action-block__delete-confirm">
              <PromotionListTitle customClass="promotion-action-block__delete-confirm-title" />
              <div className="promotion-action-block__delete-confirm-icon-wrapper">
                <CopyText customClass="promotion-action-block__delete-confirm-subtitle">
                  Bist Du sicher, dass Du diese Aktion löschen willst?
                </CopyText>
                <Icon icon={TrashCanIcon} />
              </div>
              <PromotionListActionGroup
                cancelBtnIsDisabled={isLoading}
                submitBtnIsDisabled={isLoading}
                cancelBtnHandler={() => {
                  setIsFormVisible(true);
                  setDeleteConfirmModalIsOpen(false);
                }}
                submitBtnHandler={() => {
                  removePromotionHandler();
                  trackClick("entfernen", TRACKING_CONTEXT);
                }}
              />
            </div>
          </Tile.Content>
        </Tile>
      )}
    </div>
  );
};

export default observer(PromotionListActionSection);
