import React from "react";

type TabPanelProps = {
  children: React.ReactNode;
};

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(
  ({ children }, ref) => (
    <div ref={ref} role="tabpanel" tabIndex={-1}>
      {children}
    </div>
  )
);

export default TabPanel;
