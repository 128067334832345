import React from "react";
import { Asterisk, Heading } from "@dtpk-cc/components";
import * as styles from "../card-category.module.scss";

type CardCategoryHeaderProps = {
  headerCustomClass: string;
  name: string;
  onAsteriskClick?: () => void;
};

const CardCategoryHeader = ({
  headerCustomClass,
  name,
  onAsteriskClick,
}: CardCategoryHeaderProps) => (
  <Heading
    tag="h3"
    customClass={`${headerCustomClass}`}
    variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
  >
    <span>
      {name}
      {onAsteriskClick && (
        <Asterisk
          customClass={`${styles.cardCategory} ${styles.asterisk}`}
          onClick={onAsteriskClick}
        />
      )}
    </span>
  </Heading>
);

export default CardCategoryHeader;
