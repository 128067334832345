import { IPromotion } from "core/entities/PencilSelling/IPromotion";
import type { ITariff as ITariffProduct } from "core/entities/Product/Tariff/ITariff";
import type {
  CardExport,
  ICard,
  ICardDetail,
  ICardDetailSection,
} from "../../Product/Card/ICard";
import { ILevel } from "../../Product/IProduct";
import { IDescriptionItem } from "../../Product/IDescription";

// TODO: replace Categories when data exists
export enum AdditionalCardModes {
  MAGENTA_MOBILE_PLUS = "magenta-mobile-plus",
  FAMILY_CARD = "family-card",
}

export interface TariffDataExport {
  cards: CardExport;
  totalPrice: number;
  credit: number;
  quantity: number;
  promotions: IPromotion[];
  benefits: IBenefitsData;
  loyaltyBonus: number;
  hasProvisionFee: boolean;
  smartphoneName: string;
  smartphonePrice: number;
}

export interface IBenefitsDataListItem {
  name: string;
  value: number;
  key: string;
}

export interface IBenefitsData {
  isActive: boolean;
  price: number | null;
  title: string;
  happyHourBenefitOptionDescription: IDescriptionItem | null;
  list: IBenefitsDataListItem[];
}

export interface ISettings {
  level: string;
  hasBenefit: boolean;
  hasProvisionFee: boolean;
  loyaltyBonusValue: number;
  smartphoneName: string;
  smartphonePrice: number;
}

export type CalculatorCard = ICard | ITariffProduct;

export type IMaxAmount = Record<string, number>;

export interface ITariff {
  get additionalCardMode(): AdditionalCardModes | null;
  get isCardLimitReached(): boolean;
  setTariffs(tariffs: ITariffProduct[]): void;
  setCards(cards: ICard[]): void;
  getCards(): CardExport;
  getCard(key: string): ICard;
  setCardSectionItem(
    sectionKey: keyof ICardDetailSection,
    cardDetails: ICardDetail[]
  ): void;
  getCardDetailSection(): ICardDetailSection;
  getPromotionsTotalPrice(): number;
  getPromotions(): IPromotion[];
  setPromotions(promotions: IPromotion[]): void;
  getActiveCards(): CardExport;
  getHasBenefit(): boolean;
  setHasBenefit(hasBenefit: boolean): void;
  setQuantity(key: string, quantity: number): void;
  getTotalQuantity(): number;
  getCombiCards(onlyActive?: boolean): ICard[];
  getMagentaNextCard(onlyActive?: boolean): ICard[];
  getMagentaMobileTariffs(onlyActive?: boolean): ITariffProduct[];
  getMagentaMobileKidsCards(onlyActive?: boolean): ICard[];
  getFamilyCards(onlyActive?: boolean): ICard[];
  getMultiSimCards(onlyActive?: boolean): ICard[];
  getMagentaMobileCardPrice(): number;
  getMagentaMobileKidsCardPrice(): number;
  getCombiCardsPrice(): number;
  getMultiSimCardsPrice(): number;
  getFamilyCardsPrice(): number;
  getTotalPrice(): number;
  getBenefitsData(): IBenefitsData;
  getLoyaltyBonus(): number;
  setLoyaltyBonus(value: number): void;
  getValueCalculatedByPeriod(value: number, period?: number): number;
  getHasProvisionFee(): boolean;
  setHasProvisionFee(hasBenefit: boolean): void;
  getSettings(): ISettings;
  setSettings(settings: ISettings): void;
  getMobileTariffsLevels(): ILevel[];
  getSavingsTotalPrice(): number;
  export(): TariffDataExport;
  reset(): void;
}
