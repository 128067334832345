import React from "react";
import { IConfigurationSummaryItem } from "../ISummary";
import SummaryConfiguration from "../SummaryConfiguration";
import SummaryConfigurationSectionWrapper from "../SummaryConfigurationSectionWrapper";
import SummaryConfigurationSection from "../SummaryConfigurationSection";
import SummaryConfigurationSectionDescription from "../SummaryConfigurationSectionDescription";
import SummaryConfigurationAddition from "../SummaryConfigurationAddition";
import SummaryConfigurationTotalPrice from "../SummaryConfigurationTotalPrice";
import SummaryConfigurationMainAveragePriceDescr from "../SummaryConfigurationMainAveragePriceDescr";

const SummaryDevices = ({
  configurations,
  displayConfigurationTotalPrice,
  customerPricePrefix,
}: {
  configurations: IConfigurationSummaryItem;
  displayConfigurationTotalPrice: boolean;
  customerPricePrefix: string;
}) => (
  <>
    {configurations.configurationItems.map((configurationItem) => (
      <SummaryConfiguration
        key={configurationItem.title}
        title={configurationItem.title}
      >
        <div>
          {configurationItem.sections.map((section) => (
            <SummaryConfigurationSectionWrapper
              key={section.name}
              name={section.name}
            >
              {section.items.map(
                ({ additionalRenderData, ...sectionItem }, index) => (
                  <SummaryConfigurationSection
                    key={`${additionalRenderData.productBaseName}${index}`}
                  >
                    <SummaryConfigurationSectionDescription
                      customerPricePrefix={customerPricePrefix}
                      sectionItemDescriptionData={{
                        additionalRenderData,
                        ...sectionItem,
                      }}
                    />
                    {additionalRenderData.itemOptionsAreExist && (
                      <SummaryConfigurationAddition
                        customerPricePrefix={customerPricePrefix}
                        additionItems={sectionItem.additions}
                      />
                    )}
                    <SummaryConfigurationMainAveragePriceDescr
                      description={sectionItem.mainAveragePriceDescription}
                    />
                  </SummaryConfigurationSection>
                )
              )}
            </SummaryConfigurationSectionWrapper>
          ))}
        </div>
        {displayConfigurationTotalPrice && (
          <SummaryConfigurationTotalPrice
            configurationTotalPrice={configurationItem.totalPrice}
          />
        )}
      </SummaryConfiguration>
    ))}
  </>
);

export default SummaryDevices;
