import React, { useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Heading, Button, CopyText, Price } from "@dtpk-cc/components";
import { DeviceTypes } from "core/entities/PencilSelling/HomeConfigurator/IHomeConfigurator";
// TODO Temporary hidden
// import RestartSvg from "@dtpk-cc/components/dist/icons/Action/Restart/Default";
import ConfiguratorDeviceItem from "./ConfiguratorDeviceItem";
import ConfiguratorServices from "./ConfiguratorServices";
import {
  REPEATER_DEVICE_NAME,
  recommendationRentProductsMap,
  SMART4_ROUTER_DEVICE_KEY,
  REPEATER_DEVICE_KEY,
  ROUTER_TITLE_SMART4,
  RecommendationProductItem,
  RecommendationTypeItem,
} from "../const";

import SmartRouterImg from "../../../../../assets/images/home-configurator/speedport-smart-4.png";
import RepeaterImg from "../../../../../assets/images/home-configurator/mesh-verstarker.png";
// TODO Temporary hidden
// import {
//   Group,
//   PortfolioKeys,
// } from "../../../../core/entities/Product/IProduct";
import { PaymentTypes } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";

import * as styles from "./configurator-recommendations.module.scss";
import { trackClick } from "../../../../helpers/reactTracking";
import { IDevice } from "../../../../core/entities/Product/Device/IDevice";
import { IAddon } from "../../../../core/presenter/PencilSelling/AddonsPresenter";
import { useStores } from "../../../../stores/PrivateClient";

const RECOMMENDATIONS_TYPE_RENT = PaymentTypes.MONTHLY;
const RECOMMENDATIONS_TYPE_PURCHASE = PaymentTypes.ONCE;

const recommendationsTypeMap = [
  {
    title: "Mieten",
    key: RECOMMENDATIONS_TYPE_RENT,
  },
  {
    title: "Kaufen",
    key: RECOMMENDATIONS_TYPE_PURCHASE,
  },
];

type ConfiguratorRecommendationsProps = {
  baseRepeatersAmount: number;
  needRouter: boolean;
  customClass: string;
  trackingContext: string;
  // TODO Temporary hidden
  // setSquareMetersInputValue: (value: number) => void;
};

const ConfiguratorRecommendations = ({
  baseRepeatersAmount,
  needRouter,
  customClass,
  trackingContext,
}: // TODO Temporary hidden
// setSquareMetersInputValue,
ConfiguratorRecommendationsProps) => {
  const { productsStore } = useStores();
  const [configuratorData, setConfiguratorData] = useState({
    repeatersAmount: baseRepeatersAmount,
    needRouter,
  });
  // Switch tabs rent <-> sale
  const [recommendationType, setRecommendationType] = useState(
    RECOMMENDATIONS_TYPE_RENT
  );
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [serviceCase, setServiceCase] = useState(null);
  const [recommendationsNote, setRecommendationsNote] = useState(null);

  const recommendationTypeChangeHandler = (type) => {
    setRecommendationType(type);
    trackClick("änderung-des-empfehlungstyps", trackingContext);
  };

  const repeatersAmountChangeHandler = (value) => {
    setConfiguratorData({ ...configuratorData, repeatersAmount: value });
  };

  const getProductsSum = useCallback(
    (products: Array<IDevice & { quantity: number }>, priceType: string) =>
      products.reduce((acc, product) => {
        const quantityMultiplier = product?.quantity ? product.quantity : 0;
        return acc + quantityMultiplier * product.price[priceType];
      }, 0),
    []
  );
  // TODO Temporary hidden
  // const submitButtonIsDisabled = useMemo(
  //   () => configuratorData.repeatersAmount === 0 && !needRouter,
  //   [configuratorData.repeatersAmount, needRouter]
  // );

  const getProductsOnRecommendationType = useCallback(
    (recommendedType, productKey) => {
      if (recommendedType === recommendationType) {
        return recommendedProducts.filter(
          (product) => product.key === productKey
        );
      }
      return [];
    },
    [recommendationType, recommendedProducts]
  );

  const repeatersOncePriceSum = useMemo(() => {
    const products = getProductsOnRecommendationType(
      RECOMMENDATIONS_TYPE_PURCHASE,
      REPEATER_DEVICE_KEY
    );

    if (products.length === 0) return null;

    return getProductsSum(products, RECOMMENDATIONS_TYPE_PURCHASE);
  }, [getProductsSum, getProductsOnRecommendationType]);

  const routerOncePrice = useMemo(() => {
    const products = getProductsOnRecommendationType(
      RECOMMENDATIONS_TYPE_PURCHASE,
      SMART4_ROUTER_DEVICE_KEY
    );

    if (products.length === 0) return null;

    return getProductsSum(products, RECOMMENDATIONS_TYPE_PURCHASE);
  }, [getProductsSum, getProductsOnRecommendationType]);

  const totalSummary = useMemo(
    () => getProductsSum(recommendedProducts, recommendationType),
    [recommendedProducts, recommendationType, getProductsSum]
  );
  // TODO Temporary hidden
  // const offerSubmitHandler = () => {
  //   //  Grab recommended products
  //   recommendedProducts.forEach((recommendedProduct) => {
  //     //  Add each by using addToCart
  //     const cartItem = {
  //       ...recommendedProduct,
  //       group: recommendedProduct.type || Group.device,
  //       portfolio: PortfolioKeys.LANDLINE,
  //       isFungible: true,
  //       paymentType: recommendationType,
  //     };
  //
  //     const productIsInCart = !!cartStore.find(cartItem.key);
  //
  //     if (productIsInCart) {
  //       cartStore.replace(cartItem.key, cartItem);
  //     } else {
  //       cartStore.addToCart(cartItem);
  //     }
  //   });
  // };
  // TODO Temporary hidden
  // const resetHandler = () => {
  //   homeConfiguratorStore.reset();
  //   setSquareMetersInputValue(homeConfiguratorStore.squareMetersValue);
  // };

  useEffect(() => {
    // Update local configurator options
    setConfiguratorData({ repeatersAmount: baseRepeatersAmount, needRouter });
  }, [baseRepeatersAmount, needRouter]);

  useEffect(() => {
    let recommendationVariantData: RecommendationTypeItem = {
      products: [],
      serviceCase: null,
      note: null,
    };
    let targetProducts = [];
    const getProducts = (targetProductsDataArr: RecommendationProductItem[]) =>
      targetProductsDataArr.reduce((acc, { productKey, productAmount }) => {
        const targetProduct = productsStore
          .getDevices()
          .find((product) => product.key === productKey);
        if (targetProduct) {
          return [...acc, { ...targetProduct, quantity: productAmount }];
        }

        return acc;
      }, [] as Array<IAddon | IDevice>);

    if (recommendationType === RECOMMENDATIONS_TYPE_RENT) {
      // Search for Rent product packages
      const routerTypeKey = needRouter ? "needRouter" : "haveRouter";
      // Accessing data from recommendationRentProductsMap object. {products: [], serviceCase: {}, note: ""}
      recommendationVariantData =
        recommendationRentProductsMap[
          `repeaterAmount_${configuratorData.repeatersAmount}`
        ][routerTypeKey];

      targetProducts = getProducts(recommendationVariantData.products);

      setRecommendationsNote(recommendationVariantData.note);
      setServiceCase(recommendationVariantData.serviceCase);
    }

    if (recommendationType === RECOMMENDATIONS_TYPE_PURCHASE) {
      // Search for Purchase products
      const updatedPurchaseProductsMap = {};

      if (needRouter) {
        updatedPurchaseProductsMap[SMART4_ROUTER_DEVICE_KEY] = {
          productAmount: 1,
        };
      }
      if (configuratorData.repeatersAmount > 0) {
        updatedPurchaseProductsMap[REPEATER_DEVICE_KEY] = {
          productAmount: configuratorData.repeatersAmount,
        };
      }

      const purchaseRecommendationData: RecommendationProductItem[] =
        Object.keys(updatedPurchaseProductsMap).map((productKey) => ({
          productKey,
          productAmount: updatedPurchaseProductsMap[productKey].productAmount,
        }));

      targetProducts = getProducts(purchaseRecommendationData);
      // TELEKOM.DE Do not reset serviceCase to have it on "Purchase" tab.
      setServiceCase(null);
      setRecommendationsNote(null);
    }

    setRecommendedProducts(targetProducts);
  }, [productsStore, configuratorData, needRouter, recommendationType]);

  return (
    <div className={`${styles.configuratorRecommendations} ${customClass}`}>
      <div className={styles.nav}>
        {recommendationsTypeMap.map(({ key, title }) => (
          <Button
            variants={Button.Variant.bare}
            key={key}
            className={`${styles.navItem} ${
              key === recommendationType ? styles.active : ""
            }`}
            onClick={() => recommendationTypeChangeHandler(key)}
          >
            {title}
          </Button>
        ))}
      </div>
      <Heading
        tag="h3"
        variants={[Heading.Variant.quaternary, Heading.Variant.display]}
      >
        Geräte
      </Heading>
      <div className={styles.devicesWrapper}>
        {needRouter && (
          <ConfiguratorDeviceItem
            title={ROUTER_TITLE_SMART4}
            type={DeviceTypes.ROUTER}
            imgSrc={SmartRouterImg}
            price={routerOncePrice}
            trackingContext={trackingContext}
          />
        )}
        <ConfiguratorDeviceItem
          title={REPEATER_DEVICE_NAME}
          type={DeviceTypes.REPEATER}
          price={repeatersOncePriceSum}
          amount={configuratorData.repeatersAmount}
          imgSrc={RepeaterImg}
          amountChangeHandler={repeatersAmountChangeHandler}
          trackingContext={trackingContext}
        />
      </div>
      {!!recommendationsNote && (
        <div className={styles.descr}>
          <CopyText customClass={styles.descrTitle}>Hinweis:</CopyText>
          <CopyText>{recommendationsNote}</CopyText>
        </div>
      )}
      {!!serviceCase && (
        <ConfiguratorServices
          serviceCase={serviceCase}
          trackingContext={trackingContext}
        />
      )}

      <hr className={styles.delimiter} />
      {!!totalSummary && (
        <div className={styles.summary}>
          <div className={styles.summaryItemsWrapper}>
            {recommendedProducts.map((product) => (
              <CopyText key={product.key} customClass={styles.summaryItem}>
                <CopyText tag="span" customClass={styles.summaryItemAmount}>
                  {product.quantity}x
                </CopyText>
                <CopyText tag="span" customClass={styles.summaryItemName}>
                  {product.hnpName}
                </CopyText>
              </CopyText>
            ))}
          </div>
          <div className={styles.summaryPrice}>
            <Price
              prefix={
                recommendationType === RECOMMENDATIONS_TYPE_RENT
                  ? "Monatlich:"
                  : "Einmalig:"
              }
              value={totalSummary}
              variants={[Price.Variant.highlight]}
            />
          </div>
        </div>
      )}
      {/* TODO Temporary hidden */}
      {/* <div className="configurator-recommendations__footer"> */}
      {/*  <Button */}
      {/*    disabled={submitButtonIsDisabled} */}
      {/*    customClass="configurator-recommendations__submit-btn" */}
      {/*    onClick={() => { */}
      {/*      offerSubmitHandler(); */}
      {/*      trackClick("einreichen", trackingContext); */}
      {/*    }} */}
      {/*  > */}
      {/*    Ins Angebot übernehmen */}
      {/*  </Button> */}
      {/*  <Button */}
      {/*    customClass="configurator-recommendations__reset-btn" */}
      {/*    variants={[Button.Variant.outline, Button.Variant.icon]} */}
      {/*    onClick={() => { */}
      {/*      resetHandler(); */}
      {/*      trackClick("zurücksetzen", trackingContext); */}
      {/*    }} */}
      {/*  > */}
      {/*    <Button.Icon icon={RestartSvg} /> */}
      {/*  </Button> */}
      {/* </div> */}
    </div>
  );
};

export default observer(ConfiguratorRecommendations);
