import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import type { IGetOptionsUseCase, OptionsExport } from "./IGetOptions";

export class GetOptionsUseCase implements IGetOptionsUseCase {
  constructor(private readonly entity: IProductsEntity) {}

  execute(): OptionsExport {
    return {
      [PortfolioKeys.LANDLINE]: this.entity.getLandlineOptions(),
      [PortfolioKeys.MOBILE]: this.entity.getMobileOptions(),
    };
  }
}
