import React, { useState } from "react";
import { trackClick } from "helpers/reactTracking";
import PropTypes from "prop-types";
import {
  Button,
  A11yText,
  Flex,
  CopyText,
  Container,
} from "@dtpk-cc/components";

import RestartSvg from "@dtpk-cc/components/dist/icons/Action/Restart/Default";
import InfoSvg from "@dtpk-cc/components/dist/icons/Alert/Information/Default";
import PrintSvg from "@dtpk-cc/components/dist/icons/Action/Print/Default";

import Modal from "components/Modal";

// import Heimvernetzung from "pencil-selling-tool/sketchboard/Heimvernetzung - hoch.png";
// import Kundenwünsche from "pencil-selling-tool/sketchboard/Kundenwünsche - hoch.png";
// import Bedarfsanalyse from "pencil-selling-tool/sketchboard/Bedarfsanalyse.jpg";
// import Angebot from "pencil-selling-tool/sketchboard/Angebot - hoch.png";
// import HomeBaseIcon from "pencil-selling-tool/sketchboard/icons/Icon Home Base.png";
// import MagentaTVBoxIcon from "pencil-selling-tool/sketchboard/icons/Icon MagentaTV Box.png";
// import MediaReceiverIcon from "pencil-selling-tool/sketchboard/icons/Icon Media Receiver.png";
// import RouterIcon from "pencil-selling-tool/sketchboard/icons/Icon Router.png";
// import SpeedHomeWLANIcon from "pencil-selling-tool/sketchboard/icons/Icon Speed Home WLAN.png";

import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";

import "./navigation-button.scss";
import SketchboardTutorial from "../SketchBoard/SketchboardTutorial";

// const sketchboardImageData = [
//   { url: Kundenwünsche, name: "Kundenwünsche - hoch" },
//   { url: Heimvernetzung, name: "Heimvernetzung - hoch" },
//   { url: Bedarfsanalyse, name: "Bedarfsanalyse" },
//   { url: Angebot, name: "Angebot - hoch" },
// ];

// const sketchboardIconsData = [
//   { url: HomeBaseIcon, name: "Home Base" },
//   { url: MagentaTVBoxIcon, name: "MagentaTV Box" },
//   { url: MediaReceiverIcon, name: "Media Receiver" },
//   { url: RouterIcon, name: "Router" },
//   { url: SpeedHomeWLANIcon, name: "Speed Home WLAN" },
// ];

const NavigationButtonSet = ({
  customClass,
  hasBack,
  hasNext,
  onBack,
  onNext,
  onReset,
  onPrint,
  printSketchboard,
  isSketchboardOpen,
  sketchboardDocument,
  toggleSketchboardOrientation,
  isLoading,
  onPdfQrGet,
  // addImageToSketchboard,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [resetInfoIsActive, setResetInfoIsActive] = useState(false);
  const [sketchboardInfoIsActive, setSketchboardInfoIsActive] = useState(false);
  // const [sketchboardDropdownIsActive, setSketchboardDropdownIsActive] =
  //   useState(false);

  // const toggleSketchboardDropdown = () =>
  //   setSketchboardDropdownIsActive((prevState) => !prevState);

  const resetToolData = () => {
    setModalOpen(false);
    onReset();
  };

  const resetInfo = () => {
    setModalOpen(true);
    setResetInfoIsActive(true);
  };

  const showSketchboardInfo = () => {
    setModalOpen(true);
    setSketchboardInfoIsActive(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSketchboardInfoIsActive(false);
    setResetInfoIsActive(false);
  };

  const hideElement = isSketchboardOpen
    ? "pencil-selling__navigation-button--hidden"
    : "";

  const removeElement = isSketchboardOpen
    ? "pencil-selling__navigation-button--removed"
    : "";

  return (
    <Flex customClass={customClass} wrap>
      {hasBack && (
        <Button
          customClass={hideElement}
          style={{ marginRight: "12px" }}
          onClick={onBack}
          variants={Button.Variant.outline}
        >
          Zurück
        </Button>
      )}
      {hasNext ? (
        <Button customClass={hideElement} onClick={onNext} disabled={isLoading}>
          Weiter
        </Button>
      ) : (
        <>
          <Button
            disabled={isLoading}
            customClass={hideElement}
            onClick={() => onPrint()}
          >
            PDF drucken
          </Button>
          <Button
            disabled={isLoading}
            customClass={`pencil-selling__pdf-qr-code-btn ${hideElement}`}
            onClick={onPdfQrGet}
          >
            PDF QR Code
          </Button>
        </>
      )}

      {isSketchboardOpen && (
        <Button
          customClass="pencil-selling__sketchboard-reset"
          variants={Button.Variant.outline}
          onClick={() => {
            sketchboardDocument?.deleteAll();
            trackClick("notizblock.reset");
          }}
        >
          <Button.Icon icon={RestartSvg} />
          Neue Notiz
        </Button>
      )}
      <Button
        customClass={removeElement}
        variants={Button.Variant.outline}
        onClick={() => {
          resetInfo();
          trackClick("neues-angebot");
        }}
        style={{ marginLeft: "auto" }}
      >
        <Button.Icon icon={RestartSvg} />
        Neues Angebot
      </Button>

      {isSketchboardOpen && (
        <Container customClass="pencil-selling__navigation-button-container pencil-selling__navigation-button-container--sketchboard">
          <Button
            variants={[Button.Variant.outline, Button.Variant.icon]}
            onClick={() => {
              showSketchboardInfo();
              trackClick("notizblock.info");
            }}
          >
            <Button.Icon icon={InfoSvg} />
            <A11yText>Info</A11yText>
          </Button>
          {/* TODO Temporary hidden. Will be returned back once customer will gave proper files for upload */}
          {/* <div className="pencil-selling__sketchboard-img-wrapper"> */}
          {/*  <div */}
          {/*    className={`pencil-selling__sketchboard-img-dropdown ${ */}
          {/*      sketchboardDropdownIsActive ? "dropdown--active" : "" */}
          {/*    }`} */}
          {/*  > */}
          {/*    <div className="pencil-selling__sketchboard-img-dropdown-main"> */}
          {/*      {sketchboardImageData.map((imageData) => ( */}
          {/*        <Button */}
          {/*          key={imageData.name} */}
          {/*          customClass="pencil-selling__sketchboard-img-btn" */}
          {/*          variants={[Button.Variant.outline]} */}
          {/*          onClick={() => { */}
          {/*            addImageToSketchboard(imageData.url, imageData.name); */}
          {/*            toggleSketchboardDropdown(); */}
          {/*          }} */}
          {/*        > */}
          {/*          {imageData.name} */}
          {/*        </Button> */}
          {/*      ))} */}
          {/*    </div> */}
          {/*    <div className="pencil-selling__sketchboard-img-dropdown-icons"> */}
          {/*      {sketchboardIconsData.map((iconData) => ( */}
          {/*        <Button */}
          {/*          key={iconData.name} */}
          {/*          customClass="pencil-selling__sketchboard-img-dropdown-icons__item" */}
          {/*          variants={[Button.Variant.outline]} */}
          {/*          onClick={() => { */}
          {/*            addImageToSketchboard(iconData.url, iconData.name); */}
          {/*          }} */}
          {/*        > */}
          {/*          {iconData.name} */}
          {/*        </Button> */}
          {/*      ))} */}
          {/*    </div> */}
          {/*  </div> */}
          {/*  <Button */}
          {/*    onClick={toggleSketchboardDropdown} */}
          {/*    customClass="pencil-selling__sketchboard-img-btn" */}
          {/*    variants={[Button.Variant.outline, Button.Variant.icon]} */}
          {/*  > */}
          {/*    Datei hochladen */}
          {/*  </Button> */}
          {/* </div> */}
          <Button
            customClass="pencil-selling__sketchboard-orient-btn"
            variants={[Button.Variant.outline, Button.Variant.icon]}
            onClick={() => {
              toggleSketchboardOrientation();
              trackClick("notizblock.toggle-orientation");
            }}
            style={{ marginLeft: "0.375rem" }}
          >
            <Button.Icon icon={RestartSvg} />
            <A11yText>Notizblock zurücksetzen</A11yText>
          </Button>
          <Button
            variants={Button.Variant.outline}
            onClick={() => {
              printSketchboard();
              trackClick("notizblock.print");
            }}
            style={{ marginLeft: "0.375rem" }}
          >
            <Button.Icon icon={PrintSvg} />
            Drucken
          </Button>
        </Container>
      )}

      <Modal
        active={modalOpen}
        close={() => {
          closeModal(false);
          // trackClick("reset-modal-close");
        }}
        showSubmitButton={false}
      >
        {resetInfoIsActive && (
          <>
            <Container customClass="pencil-selling__reset-copytext-container">
              <CopyText customClass="pencil-selling__reset-copytext">
                Bei Erstellung eines neuen Angebots werden alle zuvor getätigten
                Eingaben gelöscht.
                <br />
                Willst du wirklich fortfahren?
              </CopyText>
            </Container>
            <Container customClass="pencil-selling__reset-button-container">
              <Button
                customClass="pencil-selling__reset-button"
                onClick={() => {
                  resetToolData();
                  trackClick("modal.reset.ja");
                }}
              >
                Ja
              </Button>
              <Button
                customClass="pencil-selling__reset-button"
                onClick={() => {
                  setModalOpen(false);
                  trackClick("modal.reset.nein");
                }}
                variants={Button.Variant.outline}
              >
                Nein
              </Button>
            </Container>
          </>
        )}

        {sketchboardInfoIsActive && <SketchboardTutorial />}
      </Modal>
    </Flex>
  );
};

NavigationButtonSet.defaultProps = {
  hasBack: true,
  hasNext: true,
};

NavigationButtonSet.propTypes = {
  hasBack: PropTypes.bool,
  hasNext: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onReset: PropTypes.func,
  customClass: PropTypes.string,
};

export default NavigationButtonSet;
