import { IReplaceCustomPromotion } from "./IReplaceCustomPromotion";
import { IOffer } from "../../../entities/PencilSelling/Offer_legacy/IOffer";
import { PortfolioKeys } from "../../../entities/Product/IProduct";
import { ICustomPromotion } from "../../../entities/PencilSelling/ICustomPromotion";

export class ReplaceCustomPromotionUseCase implements IReplaceCustomPromotion {
  constructor(private entity: IOffer) {}

  execute(promotionSection: PortfolioKeys, promotion: ICustomPromotion) {
    this.entity.replaceCustomPromotion(promotionSection, promotion);
  }
}
