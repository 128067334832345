import { CopyText, Icon } from "@dtpk-cc/components";
import React, { useMemo } from "react";
import { ContentAchievementDefault } from "@dtpk-cc/components/dist/icons";
import { NetworkProvidersQualitySummary } from "views/Map/api/types.js";
import { NetworkQualityColorsMetaData } from "views/Map/constants/const";
import { MapSettingsColorScheme } from "views/Map/hooks/types";
import * as styles from "../map-sidebar-chart.module.scss";

type MapSidebarChartSummaryProps = {
  networkProvidersQualitySummary: NetworkProvidersQualitySummary;
  colorScheme: MapSettingsColorScheme;
};

const MapSidebarChartSummary = ({
  networkProvidersQualitySummary,
  colorScheme,
}: MapSidebarChartSummaryProps) => {
  const statisticsInfo = useMemo(
    () => ({
      infoText:
        NetworkQualityColorsMetaData.find(
          (el) =>
            el.value ===
            Math.round(networkProvidersQualitySummary.providers.telekom - 1)
        )?.description || "",
      telekomIsLeading: networkProvidersQualitySummary.leader === "telekom",
    }),
    [networkProvidersQualitySummary]
  );

  const colorMetaData = NetworkQualityColorsMetaData.find(
    (el) => el.description === statisticsInfo.infoText
  );

  return (
    <div className={`${styles.summary} ${styles.summaryContainer}`}>
      <div
        style={{
          background: colorMetaData?.colorCode[colorScheme],
        }}
        className={`${styles.summary} ${styles.badgeContainer}`}
      >
        <CopyText variants={CopyText.Variant.highlight}>
          {statisticsInfo.infoText}
        </CopyText>
      </div>
      {statisticsInfo.telekomIsLeading && (
        <Icon
          wrapperProps={{ style: { width: "auto" } }}
          size={Icon.Size.medium}
          icon={ContentAchievementDefault}
        />
      )}
    </div>
  );
};

export default MapSidebarChartSummary;
