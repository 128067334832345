import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import type {
  ExportCartOptions,
  IGetOptionsInCartUseCase,
} from "./IGetOptionsInCart";

export class GetOptionsInCartUseCase implements IGetOptionsInCartUseCase {
  constructor(private cart: ICart) {}

  execute(): ExportCartOptions {
    return {
      [PortfolioKeys.LANDLINE]: this.cart.getLandlineOptions(),
      [PortfolioKeys.MOBILE]: this.cart.getMobileOptions(),
    };
  }
}
