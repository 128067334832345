import React from "react";
import * as styles from "./address-check-container.module.scss";

type AddressCheckContainerProps = {
  customClass?: string;
};

const AddressCheckContainer: React.FC<AddressCheckContainerProps> = ({
  customClass = "",
  children,
}) => <div className={`${styles.container} ${customClass}`}>{children}</div>;

export default AddressCheckContainer;
