import React from "react";

// Extend inputfield number check on Firefox
const onlyNumber = (event: React.KeyboardEvent): void => {
  if (!event.key.match(/^[0-9,]+$/)) event.preventDefault();
};

const preventDecimal = (event: React.KeyboardEvent): void => {
  if (event.key === "," || event.key === ".") {
    event.preventDefault();
  }
};

const convertNumberHandler = (inFloat: boolean, number: string) =>
  inFloat ? Number(number).toFixed(2) : number;

const handleMaxValue = (maxValue: number | null, value: number) =>
  maxValue && value > maxValue ? maxValue : value;

const trimmInputValue = (inFloat: boolean, inputValue: string) => {
  let trimmedInputValue = inputValue.replace("^", "");

  if (trimmedInputValue.search(",") && trimmedInputValue.match(/,\d+/)) {
    trimmedInputValue = convertNumberHandler(
      inFloat,
      trimmedInputValue.replace(",", ".")
    );

    return trimmedInputValue;
  }

  return trimmedInputValue;
};

const handleNumberInputChange = ({
  inputValue,
  maxValue,
  inFloat,
  onChange,
}: {
  inputValue: string;
  maxValue?: number | null;
  inFloat: boolean;
  onChange: (inputValue: string | number) => void;
}) => {
  const trimmedInputValue = trimmInputValue(inFloat, inputValue);

  if (Number.isNaN(Number(trimmedInputValue))) {
    return onChange(trimmedInputValue);
  }

  const valueString = handleMaxValue(maxValue, Number(trimmedInputValue));

  return onChange(Number(valueString));
};

export {
  onlyNumber,
  preventDecimal,
  handleNumberInputChange,
  convertNumberHandler,
  handleMaxValue,
  trimmInputValue,
};
