import type { IBenefitConfig } from "core/entities/Product/IBenefit";
import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IGetBenefitUseCase } from "./IGetBenefit";

export class GetBenefitUseCase implements IGetBenefitUseCase {
  constructor(private entity: IOffer) {}

  execute(): IBenefitConfig {
    return this.entity.getTariffBenefits();
  }
}
