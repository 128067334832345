import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { CustomerType } from "../../core/entities/PencilSelling/Customer/ICustomer";

const PC_CUSTOM_ADDON_CONTRACT_PERIOD = 12;
const BC_CUSTOM_ADDON_CONTRACT_PERIOD = 36;

export const useGetCustomAddonContractPeriod = (baseOffer: BaseOffer) => {
  const customType = baseOffer.customer.get().customerType;

  return customType === CustomerType.PRIVATE
    ? PC_CUSTOM_ADDON_CONTRACT_PERIOD
    : BC_CUSTOM_ADDON_CONTRACT_PERIOD;
};
