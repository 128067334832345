// extracted by mini-css-extract-plugin
var _1 = "dialog-box-module-actionBtn-jJiNt";
var _2 = "dialog-box-module-closeBtn-DMCSy";
var _3 = "dialog-box-module-configuratorDialogBox-Hj5z9";
var _4 = "dialog-box-module-content-OsoqN";
var _5 = "dialog-box-module-icon-fKJA0";
var _6 = "dialog-box-module-iconWrapper-r3Nu8";
var _7 = "dialog-box-module-showUp-nSGJX";
var _8 = "dialog-box-module-textWrapper-f4iOx";
export { _1 as "actionBtn", _2 as "closeBtn", _3 as "configuratorDialogBox", _4 as "content", _5 as "icon", _6 as "iconWrapper", _7 as "showUp", _8 as "textWrapper" }
