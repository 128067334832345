import React, { useEffect, useRef, useState } from "react";
import ReactTimePicker, {
  TimePickerProps as ReactTimePickerProps,
} from "react-time-picker";

import { CopyText, Icon } from "@dtpk-cc/components";
import { ContentClockDefault } from "@dtpk-cc/components/dist/icons";
import * as styles from "./time-picker.module.scss";

export interface TimePickerProps extends ReactTimePickerProps {
  placeholder: string;
}

export const TimePicker = ({
  placeholder = "Visit time",
  ...timePickerProps
}: TimePickerProps) => {
  const inputsRef =
    useRef<{ hourInput: HTMLInputElement; minuteInput: HTMLInputElement }>(
      null
    );
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    const inputs = Array.from<HTMLInputElement>(
      document.querySelectorAll("#visit_time input")
    ).slice(1);
    const [hourInput, minuteInput] = inputs;
    inputsRef.current = { hourInput, minuteInput };
  }, []);

  return (
    <div className={styles.timepickerWrapper}>
      <CopyText
        customClass={`${styles.timepickerWrapper__placeholder} ${
          focused ? styles.timepickerWrapper__placeholder__focused : ""
        }`}
        onClick={() => {
          setFocused(true);
          inputsRef.current.hourInput.focus();
        }}
      >
        {placeholder}
      </CopyText>
      <ReactTimePicker {...timePickerProps} />
      <Icon
        icon={ContentClockDefault}
        size={Icon.Size.small}
        customClass={styles.timepickerWrapper__icon}
      />
    </div>
  );
};
