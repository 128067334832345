import React from "react";
import { RouteTransportation } from "views/Map/hooks/types";
import { Button, CopyText } from "@dtpk-cc/components";
import CarBlack from "../../../../../../assets/images/icons/coverage-map/car_black.svg";
import CarMagenta from "../../../../../../assets/images/icons/coverage-map/car_magenta.svg";
import TrainBlack from "../../../../../../assets/images/icons/coverage-map/train_black.svg";
import TrainMagenta from "../../../../../../assets/images/icons/coverage-map/train_magenta.svg";
import WalkingBlack from "../../../../../../assets/images/icons/coverage-map/walking_black.svg";
import WalkingMagenta from "../../../../../../assets/images/icons/coverage-map/walking_magenta.svg";
import BicycleBlack from "../../../../../../assets/images/icons/coverage-map/bicycle_black.svg";
import BicycleMagenta from "../../../../../../assets/images/icons/coverage-map/bicycle_magenta.svg";
import * as styles from "./map-sidebar-route-transportations.module.scss";
import { trackClick } from "helpers/reactTracking";
import { MAP_FEATURE_CONTENT } from "views/Map/constants/const";

type MapSidebarRouteTransportationProps = {
  selectedTransportation: RouteTransportation;
  setTransportation: (transportation: RouteTransportation) => void;
};

type TransportationOption = {
  label: string;
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  mode: RouteTransportation;
};

const MapSidebarRouteTransportation = ({
  selectedTransportation,
  setTransportation,
}: MapSidebarRouteTransportationProps) => {
  const TransportationOptions: TransportationOption[] = [
    {
      label: "Auto",
      icon: <CarBlack />,
      selectedIcon: <CarMagenta />,
      mode: RouteTransportation.drive,
    },
    {
      label: "ÖPNV",
      icon: <TrainBlack />,
      selectedIcon: <TrainMagenta />,
      mode: RouteTransportation.approximated_transit,
    },
    {
      label: "Laufen",
      icon: <WalkingBlack />,
      selectedIcon: <WalkingMagenta />,
      mode: RouteTransportation.walk,
    },
    {
      label: "Rad",
      icon: <BicycleBlack />,
      selectedIcon: <BicycleMagenta />,
      mode: RouteTransportation.bicycle,
    },
  ];

  return (
    <div className={`${styles.transportationContainer}`}>
      {TransportationOptions.map((transportation) => (
        <Button
          variants={[Button.Variant.clean]}
          customClass={`${styles.transportationOptionContainer} `}
          onClick={() => {
            trackClick(
              `routetransportation.${transportation.mode}`,
              MAP_FEATURE_CONTENT
            );
            setTransportation(transportation.mode);
          }}
          key={transportation.mode}
        >
          <div className={styles.transportationIconContainer}>
            {transportation.mode === selectedTransportation
              ? transportation.selectedIcon
              : transportation.icon}
          </div>
          <CopyText
            customClass={`${
              selectedTransportation === transportation.mode
                ? styles.transportationSelected
                : ""
            }`}
          >
            {transportation.label}
          </CopyText>
        </Button>
      ))}
    </div>
  );
};

export default MapSidebarRouteTransportation;
