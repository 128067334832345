import { ICard } from "core/entities/Product/Card/ICard";
import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { ITariffConfigurationSettings } from "core/presenter/MagentaNext/LegacyTariffPresenter";
import { BaseTariffsPresenter } from "../../../core/presenter/MagentaNext/BaseTariffsPresenter";

type IActiveSettings = {
  tariffWorld: CardWorldKeys | "";
  generation: string;
  size: string;
};
export class TariffConfiguratorPresenter extends BaseTariffsPresenter {
  constructor(private familyCards: ICard[]) {
    super();
  }

  activeSettings: IActiveSettings = {
    tariffWorld: CardWorldKeys.magenta_mobile_family_card_cards,
    generation: "",
    size: "",
  };

  setActiveSetting(key: keyof IActiveSettings, value: number | string) {
    this.activeSettings = {
      ...this.activeSettings,
      [key]: value,
    };
  }

  setActiveSettings(settings: IActiveSettings) {
    this.activeSettings = settings;
  }

  getActiveSettings(): IActiveSettings {
    return this.activeSettings;
  }

  get generations(): string[] {
    return this.familyCards.reduce((acc, { generation }) => {
      const generationExists = acc.includes(generation);
      return generation && !generationExists ? [...acc, generation] : acc;
    }, [] as string[]);
  }

  get sizes(): string[] {
    return this.familyCards.reduce((acc, { generation, size }) => {
      const matchingGeneration = this.activeSettings.generation === generation;
      const sizeExists = acc.includes(size);
      return matchingGeneration && size && !sizeExists ? [...acc, size] : acc;
    }, [] as string[]);
  }

  get settings(): ITariffConfigurationSettings {
    return {
      tariffWorlds: [
        {
          key: CardWorldKeys.magenta_mobile_family_card_cards,
          name: CardWorldKeys.magenta_mobile_family_card_cards,
          limit: null,
          valency: null,
        },
      ],
      generations: this.generations,
      sizes: this.sizes,
    };
  }

  get card() {
    const { tariffWorld, generation, size } = this.activeSettings;

    return this.familyCards.find(
      (card) =>
        card.size === size &&
        card.generation === generation &&
        card.tariffWorld?.key === tariffWorld
    );
  }
}
