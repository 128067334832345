import React, { useState } from "react";
import AddonsSelectionModalWrapper from "../AddonsSelectionModalWrapper";
import { PaymentTypes } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { IAddon } from "../../../../core/presenter/PencilSelling/AddonsPresenter";
import ProductVariantSelectionItem from "../../../../elements/new-design/ExtraSelection/ProductVariantSelectionItem";
import { trackClick } from "../../../../helpers/reactTracking";

type AddonsPriceTypeSelectionModalProps = {
  product: IAddon | null;
  isOpen: boolean;
  activeProductPriceType: PaymentTypes | string;
  getAdditionalPriceSuffix: (product: IAddon) => string;
  onCancel: () => void;
  onSubmit: (product: IAddon, paymentType?: PaymentTypes | string) => void;
  customClass?: string;
  trackingContext: string;
};

const AddonsPriceTypeSelectionModal = ({
  product,
  isOpen,
  activeProductPriceType,
  getAdditionalPriceSuffix,
  onCancel,
  onSubmit,
  trackingContext,
  customClass = "",
}: AddonsPriceTypeSelectionModalProps) => {
  const [activePriceType, setActivePriceType] = useState(
    activeProductPriceType
  );

  return (
    <AddonsSelectionModalWrapper
      submitDisabled={!!activeProductPriceType}
      customClass={customClass}
      onSubmit={() => {
        onSubmit(product, activePriceType);
        trackClick(`${product.name}.auswahl.übernehmen`, trackingContext);
      }}
      onCancel={onCancel}
      title="Preisvariante"
      isOpen={isOpen}
    >
      {Object.values(PaymentTypes).map((priceTypeValue, index) => (
        <ProductVariantSelectionItem
          key={`${product.key}-${priceTypeValue}`}
          name={product.name}
          id={`${product.name}-${index}`}
          checked={activePriceType === priceTypeValue}
          onChange={() => {
            setActivePriceType(priceTypeValue);
            trackClick(`${product.name}.${priceTypeValue}`, trackingContext);
          }}
          title={product?.name || ""}
          subtitle={product.additionalDescription || ""}
          price={{
            ...product?.price,
            [priceTypeValue === PaymentTypes.MONTHLY
              ? PaymentTypes.ONCE
              : PaymentTypes.MONTHLY]: null,
          }}
          additionalPriceSuffix={getAdditionalPriceSuffix(product)}
        />
      ))}
    </AddonsSelectionModalWrapper>
  );
};

export default AddonsPriceTypeSelectionModal;
