import React from "react";
import { Button, Icon, Tile } from "@dtpk-cc/components";
import { ActionAddDefault } from "@dtpk-cc/components/dist/icons";
import * as styles from "../card-category.module.scss";

type CardCategoryFooterProps = {
  onAddCard: () => void;
  addCardBtnText: string;
};

const CardCategoryFooter = ({
  onAddCard,
  addCardBtnText,
}: CardCategoryFooterProps) => (
  <Tile.Content
    customClass={`${styles.cardCategory} ${styles.row} ${styles.center}`}
  >
    <Button
      customClass={`${styles.cardCategory} ${styles.addBtn}`}
      variants={Button.Variant.bare}
      onClick={onAddCard}
    >
      <Icon
        icon={ActionAddDefault}
        size={Icon.Size.small}
        wrapperProps={{
          className: `${styles.cardCategory} ${styles.addBtnIconWrapper}`,
        }}
      />
      <span>{addCardBtnText}</span>
    </Button>
  </Tile.Content>
);

export default CardCategoryFooter;
