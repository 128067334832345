import type {
  ISettings,
  ITariff,
} from "core/entities/MagentaNext/Tariff/ITariff";
import type { ISetSettings } from "./ISetSettings";

export class SetSettingsUseCase implements ISetSettings {
  constructor(private readonly entity: ITariff) {}

  execute(settings: ISettings): void {
    return this.entity.setSettings(settings);
  }
}
