import React from "react";
import { v4 as uuidv4 } from "uuid";
import { CopyText, Price } from "@dtpk-cc/components";
import { ITotalPriceDescriptionItem } from "../ISummary";
import * as styles from "./summary-configuration-total-price-descr.module.scss";

type SummaryConfigurationTotalPriceDescrProps = {
  totalPriceDescriptionItems: ITotalPriceDescriptionItem[];
  customClass?: string;
};

const SummaryConfigurationTotalPriceDescr = ({
  totalPriceDescriptionItems,
  customClass = "",
}: SummaryConfigurationTotalPriceDescrProps) =>
  !!totalPriceDescriptionItems.length && (
    <div className={customClass}>
      {totalPriceDescriptionItems.map((totalPriceDescriptionItem) => (
        <div className={styles.totalPriceDescriptionWrapper} key={uuidv4()}>
          <Price value={totalPriceDescriptionItem.price} />
          <CopyText customClass="m-l-6">
            {totalPriceDescriptionItem.description}
          </CopyText>
        </div>
      ))}
    </div>
  );

export default SummaryConfigurationTotalPriceDescr;
