import { useCallback } from "react";
import { fetchAndMapProducts } from "helpers/fetchAndMapProducts";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { useStores } from "../../../stores/PrivateClient";
import { Endpoints } from "../../../core/repositories/ProductsRepository/IProductsRepository";
import { ProductsMapper } from "../../../core/mapper/ProductsMapper";

export const useFetchData = () => {
  const { productsRepositoryStore, offerStore, productsStore, factModalStore } =
    useStores();
  const baseOffer = offerStore.getBaseOffer();

  const fetchData = useCallback(async () => {
    try {
      // Get products with summary and promotions
      const streamingAnalysisResponse =
        await productsRepositoryStore.getSreamingAnalysis();
      const { summary, products: data } = await fetchAndMapProducts(
        productsRepositoryStore,
        ProductsMapper,
        CustomerType.PRIVATE,
        Endpoints.pencil_selling,
        baseOffer,
        {
          streamingAnalysisResponse,
        }
      );
      factModalStore.setupFactModalsData(data.facts);

      productsStore.setup(summary);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [productsRepositoryStore, baseOffer, productsStore, factModalStore]);

  return { fetchData };
};
