import { makeAutoObservable } from "mobx";

import type { IProductsSummary } from "core/mapper/ProductsMapper";
import { TariffExport } from "core/usecases/PencilSelling/GetTariffs/IGetTariffs";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import type { IDevice } from "../../Product/Device/IDevice";
import type { IOption } from "../../Product/Option/IOption";
import type { ICard } from "../../Product/Card/ICard";
import type { ITariff } from "../../Product/Tariff/ITariff";
import type { IProductsEntity } from "./IProductsEntity";

export class ProductsEntity implements IProductsEntity {
  private products: IProductsSummary = {
    landline: {
      tariffs: [],
      devices: [],
      options: [],
    },
    mobile: {
      tariffs: [],
      options: [],
      cards: [],
    },
    streamingServices: [],
    streamingVariants: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getLandlineTariffs(): ITariff[] | [] {
    return this.products.landline.tariffs;
  }

  getMobileTariffs(): ITariff[] {
    return this.products.mobile.tariffs;
  }

  getDevices(): IDevice[] {
    return this.products.landline.devices;
  }

  getCards(): ICard[] {
    return this.products.mobile.cards as ICard[];
  }

  get tariffCards(): ITariff[] {
    return this.products.mobile.cards as ITariff[];
  }

  getLandlineOptions(): IOption[] {
    return this.products.landline.options;
  }

  getMobileOptions(): IOption[] {
    return this.products.mobile.options;
  }

  get tariffs(): TariffExport {
    return {
      [PortfolioKeys.LANDLINE]: this.getLandlineTariffs(),
      [PortfolioKeys.MOBILE]: this.getMobileTariffs(),
    };
  }

  setup(initialData: IProductsSummary): void {
    this.products = initialData;
  }
}
