import React from "react";
import { TextLink, CopyText, Container } from "@dtpk-cc/components";

import "@dtpk-cc/components/dist/global.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";

import "./cookie-layer-setting.scss";

function CookieLayerSetting() {
  const open = (event) => {
    event.preventDefault();
    const newEvent = new Event("CookieLayerOpenEvent");

    window.dispatchEvent(newEvent);
  };

  return (
    <Container customClass="cookie-layer-setting">
      <CopyText>
        <strong>Cookie-Einstellungen:&nbsp;</strong>Sie können Ihren&nbsp;
        <TextLink title="Cookie Einstellungen" onClick={open}>
          Cookie Einstellungen
        </TextLink>
        &nbsp;jederzeit erneut aufrufen, um Ihre Präferenzen zu verwalten.
      </CopyText>
    </Container>
  );
}

export default CookieLayerSetting;
