import React from "react";
import { observer } from "mobx-react";
import { Heading, CopyText } from "@dtpk-cc/components";
import { ICartItemData } from "../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { ICartConfigurationSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import TariffCalculatedMonthlyPrice from "../../../../../elements/TariffCalculatedMonthlyPrice";
import { CartItemSummaryPresenter } from "../../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";

import * as styles from "./tariff-conifguration-monthly-price-steps.module.scss";
import { ConfigurationsEntity } from "../../../../../core/entities/PencilSelling/Configurations/Configurations";

type TariffConfigurationMonthlyPriceStepsProps = {
  activeTariffInCart: ICartItemData;
  settings: ICartConfigurationSettings;
  configurationsStore: ConfigurationsEntity;
};

const TariffConfigurationMonthlyPriceSteps = ({
  activeTariffInCart,
  settings,
  configurationsStore,
}: TariffConfigurationMonthlyPriceStepsProps) => {
  const configurationData = configurationsStore.getActiveConfigurationData();
  const presenter = new CartItemSummaryPresenter(activeTariffInCart, settings);
  const configurationExport = presenter.configurationExport(
    configurationData.benefit,
    configurationData.note
  );

  return (
    !!configurationExport.additionalRenderData
      .reducedMonthlyPriceStepsDescriptions.length && (
      <div className="m-t-36">
        <CopyText
          customClass={`m-b-0 ${styles.title}`}
          variants={CopyText.Variant.additional}
        >
          Haupttarif
        </CopyText>
        <div className={styles.container}>
          <div className={styles.tariffNameWrapper}>
            <Heading
              customClass="m-b-0"
              variants={[Heading.Variant.highlight, Heading.Variant.quaternary]}
            >
              {activeTariffInCart.name}
            </Heading>
          </div>
          <div className={styles.priceStepsWrapper}>
            <TariffCalculatedMonthlyPrice
              priceFontSizeIsLarge
              withMonthlySuffix
              monthlyPrice={
                configurationExport.additionalRenderData.reducedMonthlyPrice
              }
              priceStepsDescriptions={
                configurationExport.additionalRenderData
                  .reducedMonthlyPriceStepsDescriptions
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default observer(TariffConfigurationMonthlyPriceSteps);
