import { Button } from "@dtpk-cc/components";
import React from "react";
import * as styles from "../map-sidebar-route-icon.module.scss";
import RouteDefaultIcon from "../../../../../../assets/images/icons/coverage-map/route_default.svg";

type MapSidebarRouteIconProps = {
  label: string;
  onClick: () => void;
};

const MapSidebarRouteIcon = ({ label, onClick }: MapSidebarRouteIconProps) => (
  <Button
    variants={[Button.Variant.clean]}
    onClick={onClick}
    customClass={`${styles.routeIconButton}`}
  >
    <RouteDefaultIcon className={styles.routeIcon} />
    {label}
  </Button>
);

export default MapSidebarRouteIcon;
