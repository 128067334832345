import React from "react";
import { List, Icon } from "@dtpk-cc/components";
import LpgConfirmSolid from "@dtpk-cc/components/dist/icons/Lpg/ConfirmSolid";

type SummaryListProps = {
  customClass?: string;
  // variant?: "default" | "checked";
};

const SummaryList: React.FC<SummaryListProps> = ({
  customClass = "",
  // variant = "default",
  children,
}) => (
  <List
    icon={{
      icon: LpgConfirmSolid,
      color: Icon.Color.darkBlue,
    }}
    customClass={customClass}
  >
    {children}
  </List>
);

export default SummaryList;
