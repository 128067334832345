import React, { useCallback } from "react";

import TariffMainWrapper from "components/new-design/Tariff/TariffMainWrapper";
import { useStores } from "stores/BusinessClient";
import { secondaryCardsBusinessCase } from "core/entities/Product/BusinessClient/IBaseData";
import { ITariff } from "core/entities/Product/Tariff/ITariff";
import { IMobileSettings } from "core/entities/PencilSelling/Configurations/IConfigurations";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import TariffSelection from "components/new-design/Tariff/TariffSelection";
import { getTariffBySize } from "helpers/TariffSelection";
import { useTariffSelection } from "hooks/PencilSelling/useTariffSelection";
import { observer } from "mobx-react";
import { getDefaultTariffPrice } from "components/new-design/Tariff/TariffSelection/TariffEntries/helpers/default-tariff-prices";
import BaseMobileTariffConfiguration from "../../../../../components/new-design/Tariff/TariffConfiguration/BaseMobileTariffConfiguration";
import BusinessCard3GenModal from "../BusinessCard3GenModal";
import { useMobileTariffSelection } from "../../../../../hooks/PencilSelling/useMobileTariffSelection";
import { useTariffSelectionData } from "../../../../../hooks/PencilSelling/useTariffSelectionData";
import TariffSelectionHeader from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionHeader";
import AddonsResetWarningModal from "../../../../../components/new-design/Tariff/AddonsResetWarningModal";

const TRACKING_CONTEXT = {
  main: "wunschtarif-geschaefts",
  portfolio: "zusatzkarten",
};

export interface SecondaryCardsSelectionProps {
  onRecalculateCardsGen3Amount: () => void;
}

const SecondaryCardsSelection = ({
  onRecalculateCardsGen3Amount,
}: SecondaryCardsSelectionProps) => {
  const {
    productsStore,
    configurationsStore,
    offerStore,
    productsRepositoryStore,
  } = useStores();
  const cards = productsStore.tariffCards;
  const {
    settings: cardsSettings,
    isBenefitActive,
    tariffInCart,
    activeTariff: mobileTariff,
    setActiveTariff: setMobileTariff,
    smartphoneInfos,
    setSmartphoneInfos,
    selectedPromotions: selectedMobilePromotions,
    addToCart,
    addPromotionsHandler,
    addSmartphonesHandler,
    warningModalCallback,
    warningModalIsOpen,
    setWarningModalIsOpen,
    removeTariffAddonsDialogHandler,
    customPromotions,
  } = useTariffSelection<IMobileSettings>(
    PortfolioKeys.MOBILE,
    cards,
    configurationsStore,
    productsStore,
    offerStore.getBaseOffer()
  );
  const { businessCase } = cardsSettings;

  const {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  } = useTariffSelectionData({
    businessCase,
    isLandline: false,
    settings: cardsSettings,
    tariffs: cards,
    businessCaseData: secondaryCardsBusinessCase.items,
  });

  const { getTariffFeature } = useMobileTariffSelection({
    isBenefitActive,
    configurationsStore,
    mobileTariff,
    setMobileTariff,
    addToCart,
    smartphoneInfos,
    mobileSettings: cardsSettings,
    tariffs: cards,
  });

  const handleMobileTariffChange = useCallback(
    (tariff: ITariff | null, activeTariff: ITariff) => {
      const existsInCart = activeTariff?.key === tariff?.key;
      if (existsInCart) {
        configurationsStore
          .getActiveConfigurationChildren()
          .removeTariffFromCart(false);
        onRecalculateCardsGen3Amount();
        setMobileTariff(null);
      } else {
        const updatedBusinessCards3Amount =
          offerStore.businessCards3GenAmountTotal + 1;

        const tariffToSet =
          typeof tariff.price?.gen3Discount?.value === "number" &&
          typeof tariff.price?.gen3Discount?.required_quantity === "number" &&
          updatedBusinessCards3Amount >=
            tariff.price.gen3Discount.required_quantity
            ? {
                ...tariff,
                price: {
                  ...tariff.price,
                  monthly: tariff.price.gen3Discount.value,
                },
              }
            : {
                ...tariff,
                price: {
                  ...tariff.price,
                },
              };
        addToCart(tariffToSet, [], smartphoneInfos);
        onRecalculateCardsGen3Amount();
        setMobileTariff(tariffToSet);
      }
    },
    [
      offerStore,
      configurationsStore,
      setMobileTariff,
      addToCart,
      smartphoneInfos,
      onRecalculateCardsGen3Amount,
    ]
  );

  const handleMobileSettingsChange = useCallback(
    (
      key: keyof IMobileSettings,
      value: IMobileSettings[keyof IMobileSettings]
    ) => {
      let tariff: ITariff = null;
      configurationsStore.setActiveConfigurationSettings<IMobileSettings>({
        key,
        value,
      });

      configurationsStore
        .getActiveConfigurationChildren()
        .removeTariffFromCart(false);
      if (key === "level" && mobileTariff) {
        tariff = getTariffBySize(
          value,
          cards,
          mobileTariff,
          cardsSettings.isYoung,
          cardsSettings.isVario
        );
        handleMobileTariffChange(tariff, mobileTariff);
        // Prevents double setMobileTariff
        return;
      }
      setMobileTariff(tariff);
    },
    [
      handleMobileTariffChange,
      configurationsStore,
      cardsSettings.isYoung,
      cardsSettings.isVario,
      cards,
      mobileTariff,
      setMobileTariff,
    ]
  );

  const secondaryCardsTariffsPrice = (
    tariff: ITariff,
    benefitActive: boolean
  ) => {
    const gen3Price =
      offerStore.businessCards3GenAmountTotal >=
        tariff.price.gen3Discount.required_quantity &&
      tariff.price.gen3Discount.value;
    return gen3Price || getDefaultTariffPrice(tariff, benefitActive);
  };

  return (
    <TariffMainWrapper>
      <BaseMobileTariffConfiguration
        withMultipleOnceMonthlyPromotions={false}
        withCustomPromotions
        customPromotions={customPromotions[PortfolioKeys.MOBILE]}
        productsRepositoryStore={productsRepositoryStore}
        offerStore={offerStore.getBaseOffer()}
        configurationsStore={configurationsStore}
        businessCaseName={businessCaseName}
        businessCase={businessCase}
        activeTariffInCart={tariffInCart}
        activeTariff={mobileTariff}
        addPromotionsHandler={addPromotionsHandler}
        addSmartphonesHandler={addSmartphonesHandler}
        selectedPromotions={selectedMobilePromotions}
        smartphoneInfos={smartphoneInfos}
        setSmartphoneInfos={setSmartphoneInfos}
        settings={cardsSettings}
        onSettingsChange={(key, value) => {
          removeTariffAddonsDialogHandler(() => {
            handleMobileSettingsChange(key, value);
            onRecalculateCardsGen3Amount();
          });
        }}
        trackingContext={TRACKING_CONTEXT}
        tariffWorlds={tariffWorlds}
        levels={levels}
        activeLevelExists={activeLevelExists}
        activeLevelIndex={activeLevelIndex}
        setActiveLevelIndex={setActiveLevelIndex}
      />
      <TariffSelection
        trackingContext={TRACKING_CONTEXT}
        getTariffFeature={getTariffFeature}
        handleTariffChange={(tariff, activeTariff) => {
          removeTariffAddonsDialogHandler(() =>
            handleMobileTariffChange(tariff, activeTariff)
          );
        }}
        isBenefitActive={isBenefitActive}
        settings={cardsSettings}
        activeTariff={mobileTariff}
        tariffs={filteredTariffs}
        getTariffPrice={secondaryCardsTariffsPrice}
      >
        <TariffSelectionHeader
          checkboxLabelText="Vario-Tarife anzeigen"
          settings={cardsSettings}
          settingKey="isVario"
          isChecked={cardsSettings.isVario}
          onChange={() => {
            removeTariffAddonsDialogHandler(() => {
              handleMobileSettingsChange("isVario", !cardsSettings.isVario);
              onRecalculateCardsGen3Amount();
            });
          }}
          activeTariffWorld={activeTariffWorld}
        />
      </TariffSelection>
      <AddonsResetWarningModal
        trackingContext={TRACKING_CONTEXT}
        resetOptionsModalCallback={warningModalCallback}
        setResetOptionsModalIsOpen={setWarningModalIsOpen}
        resetOptionsModalIsOpen={warningModalIsOpen}
      />
      <BusinessCard3GenModal />
    </TariffMainWrapper>
  );
};

export default observer(SecondaryCardsSelection);
