import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import type { IGetDevicesInCartUseCase } from "./IGetDevicesInCart";

export class GetDevicesInCartUseCase implements IGetDevicesInCartUseCase {
  constructor(private cart: ICart) {}

  execute(): ICartItemData[] {
    return this.cart.getDevices();
  }
}
