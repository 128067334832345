import { ProductsMapper } from "core/mapper/ProductsMapper";
import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { useCallback } from "react";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";

export const useFetchCards = () => {
  const { tariffStore, legacyTariffStore, productsRepositoryStore } =
    useMagentaNextCalculatorStores();

  const fetchCards: () => Promise<void> = useCallback(async () => {
    const products = await productsRepositoryStore.getCalculatorProducts();
    if (products) {
      const mapper = new ProductsMapper({ calculatorResponse: products });
      const { cards, tariffs } = mapper.calculatorProductsSummary;
      tariffStore.setCards(
        cards
          .filter(
            (card) =>
              !card.isLegacy ||
              card.tariffWorld.key ===
                CardWorldKeys.magenta_mobile_family_card_cards
          )
          .map((card) => ({ ...card }))
      );
      legacyTariffStore.setCards(
        cards.filter((card) => card.isLegacy).map((card) => ({ ...card }))
      );
      legacyTariffStore.setTariffs(tariffs.filter((tariff) => tariff.isLegacy));
      tariffStore.setTariffs(tariffs.filter((tariff) => !tariff.isLegacy));
    }
  }, [tariffStore, legacyTariffStore, productsRepositoryStore]);

  return { fetchCards };
};
