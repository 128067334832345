import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import { ICalculatorExport } from "core/presenter/MagentaNext/MagentaNextSummaryPresenter";
import type { ISubmitPdfQrCodeUseCase } from "./ISubmitPdfQrCode";

export class SubmitPdfQrCodeUseCase implements ISubmitPdfQrCodeUseCase {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(data: ICalculatorExport): Promise<Response> {
    return this.repository.submitCalculatorPdfQrCode(data);
  }
}
