import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Icon } from "@dtpk-cc/components";
import CommunicationFeedbackDefault from "@dtpk-cc/components/dist/icons/Communication/Feedback/Default";
import ActionCloseDefault from "@dtpk-cc/components/dist/icons/Action/Close/Default";
import * as styles from "./fact-modal.module.scss";
import {
  FactModalKeys,
  IFactModalItem,
  IFactModalsMapItem,
} from "../../../core/entities/PencilSelling/FactModal/IFactModal";
import { FactModalEntity } from "../../../core/entities/PencilSelling/FactModal/FactModal";
import ModalWrapper from "../../../elements/new-design/ModalWrapper";
import LinkBtn from "../../../elements/new-design/LinkBtn";
import { CustomerType } from "../../../core/entities/PencilSelling/Customer/ICustomer";
import { trackClick } from "../../../helpers/reactTracking";

type FactModalProps = {
  factModalMapItem: IFactModalsMapItem | null;
  factModalKey: FactModalKeys;
  factModalStore: FactModalEntity;
  factModalItem: IFactModalItem;
  customerType: CustomerType;
};

export const factModalLayoutMap: { [key: string]: string } = {
  default: styles.defaultLayout,
  image: styles.imageLayout,
};

const FactModal = ({
  factModalMapItem,
  factModalKey,
  factModalStore,
  factModalItem,
  customerType,
}: FactModalProps) => {
  const [factModalIsOpen, setFactModalIsOpen] = useState(
    factModalMapItem?.isOpenedByDefault ?? false
  );

  const toggleFactModal = () => {
    const trackClickValue = factModalIsOpen
      ? `close.${factModalKey}`
      : `open.${factModalKey}`;
    trackClick(trackClickValue, `fact.modal.${customerType}`);
    setFactModalIsOpen((prev) => !prev);
  };

  const permanentDisplayToggleHandler = () => {
    factModalStore.toggleFactModalDisplay();
    if (!factModalMapItem.isOpenedByDefault) {
      toggleFactModal();
    }
    trackClick(
      factModalMapItem.isOpenedByDefault
        ? `dont.show.it.permanently.${factModalKey}`
        : `show.it.permanently.${factModalKey}`,
      `fact.modal.${customerType}`
    );
  };

  useEffect(() => {
    if (factModalMapItem) {
      setFactModalIsOpen(factModalMapItem.isOpenedByDefault);
    }
  }, [factModalMapItem]);

  return (
    factModalItem &&
    factModalItem?.filled && (
      <>
        <ModalWrapper
          isOpen={factModalIsOpen}
          onClose={toggleFactModal}
          containerCustomClass={styles.factModalWrapper}
          backDropCustomClass={styles.factModalBackdrop}
          onBackDropClick={toggleFactModal}
        >
          <div className={styles.factModalContentWrapper}>
            {factModalItem.image && (
              <img
                src={factModalItem.image}
                className={`${styles.factModalImg} ${
                  factModalLayoutMap[factModalItem.layout]
                }`}
                alt="fact-modal"
              />
            )}
            {factModalItem.heading && (
              <div
                className={`${styles.factModalTextual} m-b-6`}
                dangerouslySetInnerHTML={{ __html: factModalItem.heading }}
              />
            )}
            {factModalItem.copy && (
              <div
                className={styles.factModalTextual}
                dangerouslySetInnerHTML={{ __html: factModalItem.copy }}
              />
            )}
          </div>
          <div className={styles.factModalFooter}>
            <LinkBtn
              customClass={styles.permanentCloseBtn}
              onClick={permanentDisplayToggleHandler}
              text={
                factModalMapItem.isOpenedByDefault
                  ? "Nicht mehr anzeigen"
                  : "Dauerhaft anzeigen"
              }
            />
            <Button
              tabIndex={0}
              variants={Button.Variant.outline}
              onClick={toggleFactModal}
            >
              Schließen
            </Button>
          </div>
        </ModalWrapper>
        <Button
          variants={Button.Variant.bare}
          customClass={`${styles.factButton} ${
            factModalIsOpen ? styles.isActive : ""
          }`}
          onClick={toggleFactModal}
        >
          <Icon
            customClass={`${styles.factButtonIcon} ${
              factModalIsOpen ? styles.isActive : ""
            }`}
            icon={
              factModalIsOpen
                ? ActionCloseDefault
                : CommunicationFeedbackDefault
            }
          />
        </Button>
      </>
    )
  );
};

export default observer(FactModal);
