import React from "react";
import { createPortal } from "react-dom";
import * as styles from "./backdrop.module.scss";

type BackDropProps = {
  onClick?: () => void;
  customClass?: string;
  withPortal?: boolean;
};

const BackDrop: React.FC<BackDropProps> = ({
  children = null,
  onClick = () => {},
  customClass = "",
  withPortal = true,
}) => {
  const getBackDropTemplate = () => (
    <div
      role="presentation"
      onClick={onClick}
      className={`${styles.backDrop} ${
        withPortal ? styles.withPortal : ""
      } ${customClass}`}
    >
      {children}
    </div>
  );

  return withPortal
    ? createPortal(getBackDropTemplate(), document.body)
    : getBackDropTemplate();
};

export default BackDrop;
