import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import type { ISetPromotions } from "./ISetPromotions";

export class SetPromotions implements ISetPromotions {
  constructor(private entity: ITariff) {}

  execute(promotions: IPromotion[]): void {
    this.entity.setPromotions(promotions);
  }
}
