import React from "react";
import { Heading, CopyText, Price, Checkbox } from "@dtpk-cc/components";

import * as styles from "./secondary-cards-selection-summary.module.scss";
import TariffCalculatedMonthlyPrice from "../../../../../../elements/TariffCalculatedMonthlyPrice";
import {
  IConfigurationSummaryTotalMonthlyPrice,
  ITotalPriceDescriptionItem,
} from "../../../../../../components/new-design/Summary/ISummary";
import SummaryConfigurationTotalPriceDescr from "../../../../../../components/new-design/Summary/SummaryConfigurationTotalPriceDescr";
import { getSecondaryCardsTotalPriceTitle } from "../../../../../../helpers/getSecondaryCardsTotalPriceTitle";
import { SECONDARY_CARDS_AVERAGE_PRICE_TITLE } from "../../../../../../constants/const";

type SecondaryCardsSelectionSummaryProps = {
  mobileTariffName: string;
  totalPriceData: IConfigurationSummaryTotalMonthlyPrice;
  averagePricePerCard: number;
  mappedCashbackPromotions: ITotalPriceDescriptionItem[];
  cardsTotalAmount: number;
  toggleLinkedConfigurationIsOmittedHandler: () => void;
  linkedConfigurationIsOmitted: boolean;
};

const SecondaryCardsSelectionSummary = ({
  mobileTariffName,
  totalPriceData,
  averagePricePerCard,
  mappedCashbackPromotions,
  cardsTotalAmount,
  toggleLinkedConfigurationIsOmittedHandler,
  linkedConfigurationIsOmitted,
}: SecondaryCardsSelectionSummaryProps) => (
  <div className={styles.wrapper}>
    <Checkbox
      checked={linkedConfigurationIsOmitted}
      onChange={toggleLinkedConfigurationIsOmittedHandler}
      role="checkbox"
    >
      <div className={styles.textual}>
        <CopyText
          variants={CopyText.Variant.highlight}
          customClass="m-t-2 m-r-6"
        >
          Zugehöriger Mobilfunktarif
          <sup className="m-r-4">1</sup>:
        </CopyText>
        <Heading
          customClass={`m-b-0 ${styles.mobileTariffName} ${
            !linkedConfigurationIsOmitted
              ? styles.linkedConfigurationIsOmitted
              : ""
          }`}
          variants={
            linkedConfigurationIsOmitted
              ? [
                  Heading.Variant.quaternary,
                  Heading.Variant.highlight,
                  Heading.Variant.brand,
                ]
              : [Heading.Variant.quaternary, Heading.Variant.highlight]
          }
        >
          {mobileTariffName}
        </Heading>
      </div>
    </Checkbox>
    <div className={`${styles.textual} m-r-12`}>
      <CopyText
        variants={CopyText.Variant.highlight}
        customClass="m-t-0 m-r-6 m-t-2"
      >
        {getSecondaryCardsTotalPriceTitle(cardsTotalAmount)}
        <sup className="m-r-4">2</sup>:
      </CopyText>
      <div>
        <TariffCalculatedMonthlyPrice
          isBold
          priceFontSizeIsLarge
          withMonthlySuffix={false}
          monthlyPrice={totalPriceData.mainPrice}
          priceStepsDescriptions={totalPriceData.priceStepsDescriptions}
        />
      </div>
    </div>
    <div className={styles.textual}>
      <div className="m-b-6">
        <CopyText
          variants={CopyText.Variant.highlight}
          customClass="m-r-6 m-b-0 m-t-2"
        >
          {SECONDARY_CARDS_AVERAGE_PRICE_TITLE}
          <sup className="m-r-4">3</sup>:
        </CopyText>
      </div>
      <div>
        <Price
          variants={[
            Price.Variant.brand,
            Price.Variant.highlight,
            Price.Variant.large,
          ]}
          prefix="&Oslash;"
          value={averagePricePerCard}
          suffix={"mtl. pro Karte"}
        />
        <SummaryConfigurationTotalPriceDescr
          totalPriceDescriptionItems={mappedCashbackPromotions}
        />
      </div>
    </div>
  </div>
);

export default SecondaryCardsSelectionSummary;
