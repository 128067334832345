import React from "react";
import { Button, Grid } from "@dtpk-cc/components";

import "./promotion-list-action-group.scss";

const PromotionListActionGroup = ({
  cancelBtnHandler,
  submitBtnHandler,
  wrapperCustomClass = "",
  submitBtnIsDisabled = false,
  cancelBtnIsDisabled = false,
}) => (
  <Grid.Row customClass={`promotion-list-action-group ${wrapperCustomClass}`}>
    <Grid.Col s={3} m={6} l={6} xl={12}>
      <Button
        disabled={cancelBtnIsDisabled}
        customClass="promotion-list-action-group__btn"
        variants={Button.Variant.outline}
        onClick={cancelBtnHandler}
      >
        Abbrechen
      </Button>
    </Grid.Col>
    <Grid.Col s={3} m={6} l={6} xl={12}>
      <Button
        disabled={submitBtnIsDisabled}
        customClass="promotion-list-action-group__btn"
        onClick={submitBtnHandler}
      >
        Speichern
      </Button>
    </Grid.Col>
  </Grid.Row>
);

export default PromotionListActionGroup;
