import React from "react";
import { Heading, Modal, TextLink, CopyText } from "@dtpk-cc/components";
import "./qr-code-modal.scss";

const QrCodeModal = ({
  isOpen,
  closeChangeHandler,
  imageSrc,
  urlLink,
  magentaNext = false,
}) => (
  <Modal afterClose={closeChangeHandler} closable zIndex={100} isOpen={isOpen}>
    <Modal.Section>
      <div className="qr-code-modal">
        <Heading
          tag="h3"
          customClass="qr-code-modal__container qr-code-modal__title"
          variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
        >
          {magentaNext
            ? "Das Ergebnis des MagentaMobil Rechners als PDF"
            : "Die persönliche Empfehlung als PDF"}
        </Heading>
        <CopyText className="qr-code-modal__container">
          {`Einfach den QR Code mit dem Smartphone scannen. Anschließend lässt
          sich ${
            magentaNext
              ? "das Ergebnis des MagentaMobil Rechners"
              : "die persönliche Empfehlung"
          } als PDF auf dem Smartphone öffnen und
          speichern.`}
        </CopyText>
        <img className="qr-code-modal__qr-image" src={imageSrc} alt="qr-code" />
        <CopyText customClass="qr-code-modal__container qr-code-modal__url-title">
          {`Über diesen Link lässt sich ${
            magentaNext
              ? "das Ergebnis des MagentaMobil Rechners"
              : "das persönliche Angebot"
          } ebenfalls als PDF öffnen und speichern (7 Tage Gültigkeit).`}
        </CopyText>

        <TextLink
          target="_blank"
          href={urlLink}
          customClass="qr-code-modal__container qr-code-modal__link"
        >
          {urlLink}
        </TextLink>
      </div>
    </Modal.Section>
  </Modal>
);

export default QrCodeModal;
