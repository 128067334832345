import React from "react";
import * as styles from "./summary-wrapper.module.scss";

const SummaryWrapper: React.FC = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        {children}
      </div>
    </div>
  );
};

export default SummaryWrapper;