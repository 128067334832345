import {
  IBannerLinks,
  IDistributionData,
  IOfferSummaryBasePresenterExport,
  OfferSummaryBasePresenter,
} from "../OfferSummaryBasePresenter";
import { IOfferExport } from "../../../entities/PencilSelling/PrivateClient/Offer/IOffer";

export interface ISummaryPrevContractData {
  title: string;
  subtitle: string;
  monthlyPrice: string;
}

export interface IPrivateClientOfferSummaryPresenterExport
  extends IOfferSummaryBasePresenterExport {
  prevContractData: ISummaryPrevContractData | null;
}

export class PrivateClientOfferSummaryPresenter extends OfferSummaryBasePresenter {
  constructor(
    private offerExport: IOfferExport,
    protected bannerLinksData: IBannerLinks,
    protected baseDistributionData: IDistributionData
  ) {
    super(
      bannerLinksData,
      baseDistributionData,
      offerExport.details,
      offerExport.customerNote,
      offerExport.displayConfigurationTotalPrice,
      offerExport.displayOfferTotalPrice
    );
    this.offerExport = offerExport;
  }

  getPrevContractData(): ISummaryPrevContractData {
    return this.offerExport.prevContractPrice
      ? {
          title: "Bisherige Kosten",
          subtitle: "Kosten der bisherigen Verträge zum Vergleichen",
          monthlyPrice: this.offerExport.prevContractPrice,
        }
      : null;
  }

  export(): IPrivateClientOfferSummaryPresenterExport {
    return {
      ...super.export(),
      prevContractData: this.getPrevContractData(),
    };
  }
}
