import React from "react";
import { CopyText, Heading, Price } from "@dtpk-cc/components";
import * as styles from "./summary-total-price.module.scss";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";
import { IConfigurationSummaryTotalPrice } from "../ISummary";
import SummaryConfigurationTotalPriceDescr from "../SummaryConfigurationTotalPriceDescr";
import { TOTAL_SAVINGS_VALUE_DESCR } from "../../../../constants/const";

type SummaryTotalPriceProps = {
  configurationsTotalPrice: IConfigurationSummaryTotalPrice | null;
  savingsTotalValue: number | null;
};

const SummaryTotalPrice = ({
  configurationsTotalPrice,
  savingsTotalValue,
}: SummaryTotalPriceProps) =>
  configurationsTotalPrice ? (
    <div className={styles.container}>
      <SummarySectionItemWrapper>
        <SummarySectionItemWrapper.Main>
          <Heading
            customClass={styles.title}
            tag="p"
            variants={[Heading.Variant.tertiary, Heading.Variant.primary]}
          >
            {configurationsTotalPrice.title}
          </Heading>
        </SummarySectionItemWrapper.Main>
        <SummarySectionItemWrapper.Monthly>
          <Heading tag="p" variants={[Heading.Variant.quaternary]}>
            Monatlich
          </Heading>
          <SummaryConfigurationPriceItem
            isBold
            price={configurationsTotalPrice.monthlyPrice.mainPrice}
            suffix={configurationsTotalPrice.priceType}
          />
          {configurationsTotalPrice.monthlyPrice.priceStepsDescriptions.map(
            (priceStepsDescription) => (
              <CopyText
                key={priceStepsDescription}
                variants={CopyText.Variant.additional}
              >
                {priceStepsDescription}
              </CopyText>
            )
          )}
          {!!savingsTotalValue && (
            <div className={styles.totalSavingsWrapper}>
              <span>{TOTAL_SAVINGS_VALUE_DESCR}</span>
              <Price tag="span" value={savingsTotalValue} />
            </div>
          )}
        </SummarySectionItemWrapper.Monthly>
        <SummarySectionItemWrapper.Once variant="withoutPadding">
          <Heading tag="p" variants={[Heading.Variant.quaternary]}>
            Einmalig
          </Heading>
          <SummaryConfigurationPriceItem
            isBold
            price={configurationsTotalPrice.oncePrice}
            suffix={configurationsTotalPrice.priceType}
          />
          <SummaryConfigurationTotalPriceDescr
            totalPriceDescriptionItems={
              configurationsTotalPrice.totalOncePriceDeductions
            }
          />
        </SummarySectionItemWrapper.Once>
      </SummarySectionItemWrapper>
    </div>
  ) : null;

export default SummaryTotalPrice;
