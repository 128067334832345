import { Heading } from "@dtpk-cc/components";
import React from "react";
import * as styles from "../customer-type-selection-modal.module.scss";

type CustomerTypeSelectionModalTileItemProps = {
  title: string;
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};

const CustomerTypeSelectionModalTileItem = ({
  title,
  Icon,
}: CustomerTypeSelectionModalTileItemProps) => (
  <div className={`${styles.customerTypeTileItem} ${styles.container}`}>
    <div className={`${styles.customerTypeTileItem} ${styles.iconContainer}`}>
      <Icon />
    </div>
    <Heading
      tag="p"
      variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      customClass={`${styles.customerTypeTileItem} ${styles.title}`}
    >
      {title}
    </Heading>
  </div>
);

export default CustomerTypeSelectionModalTileItem;
