import React from "react";
import { CopyText } from "@dtpk-cc/components";

import * as styles from "./navigation-section-title.module.scss";

type NavigationSectionTitleProps = {
  title: string | null;
};

const NavigationSectionTitle = ({ title }: NavigationSectionTitleProps) =>
  title ? (
    <CopyText
      variants={[CopyText.Variant.additional]}
      customClass={`mx-12 m-t-0 m-b-6 ${styles.navigationSectionTitle}`}
    >
      {title}
    </CopyText>
  ) : null;

export default NavigationSectionTitle;
