import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ICustomer } from "core/entities/PencilSelling/Customer/ICustomer";
import type { ISetCustomerUseCase } from "./ISetCustomer";

export class SetCustomerUseCase implements ISetCustomerUseCase {
  constructor(private entity: IOffer) {}

  execute(customer: ICustomer): void {
    this.entity.customer.set(customer);
  }
}
