import React from "react";
import PageInner from "../../../../elements/new-design/PageWrapper/PageInner";

import * as styles from "./personal-page-inner.module.scss";

type PersonalPageInnerProps = {
  customClass?: string;
};

const PersonalPageInner: React.FC<PersonalPageInnerProps> = ({
  children,
  customClass = "",
}) => (
  <PageInner customClass={`${styles.wrapper} ${customClass}`}>
    {children}
  </PageInner>
);

export default PersonalPageInner;
