import type { IDevice } from "core/entities/Product/Device/IDevice";
import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import type { IGetDevicesUseCase } from "./IGetDevices";

export class GetDevicesUseCase implements IGetDevicesUseCase {
  constructor(private readonly entity: IProductsEntity) {}

  execute(): IDevice[] {
    return this.entity.getDevices();
  }
}
