import { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { ProductsMapper } from "core/mapper/ProductsMapper";
import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import type { IFetchCardsUseCase } from "./IFetchCards";

export class FetchCardsUseCase implements IFetchCardsUseCase {
  constructor(
    private readonly repository: IProductsRepository,
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  async execute(): Promise<void> {
    const products = await this.repository.getCalculatorProducts();
    if (products) {
      const mapper = new ProductsMapper({ calculatorResponse: products });
      const { cards, tariffs } = mapper.calculatorProductsSummary;
      this.entity.setCards(
        cards
          .filter(
            (card) =>
              !card.isLegacy ||
              card.tariffWorld.key ===
                CardWorldKeys.magenta_mobile_family_card_cards
          )
          .map((card) => ({ ...card }))
      );
      this.legacyEntity.setCards(
        cards.filter((card) => card.isLegacy).map((card) => ({ ...card }))
      );
      this.legacyEntity.setTariffs(tariffs.filter((tariff) => tariff.isLegacy));
      this.entity.setTariffs(tariffs.filter(({ isLegacy }) => !isLegacy));
    }
  }
}
