import React from "react";
import { Grid } from "@dtpk-cc/components";
import CartItemSummary from "components/CartItemSummary";

import "./cart-section.scss";
import SummarySectionHeader from "../SummarySectionHeader";

const CartSection = ({
  headline,
  items,
  settings,
  note = "",
  benefit = null,
  withHeading = true,
  isCardSection = false,
  openModal = (_freeMonths) => {},
  showLoyaltyBonus = false,
}) => (
  <>
    {!!items.length && (
      <Grid.Row customClass="cart-section">
        {withHeading && <SummarySectionHeader headline={headline} />}

        {items.map((item) => (
          <Grid.Row key={item.key} customClass="cart-section__product-row">
            <CartItemSummary
              item={item}
              settings={settings}
              benefit={benefit}
              note={note}
              isCardSection={isCardSection}
              openModal={(freeMonths) => openModal(freeMonths)}
              showLoyaltyBonus={showLoyaltyBonus}
            />
          </Grid.Row>
        ))}
      </Grid.Row>
    )}
  </>
);

export default CartSection;
