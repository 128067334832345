import React from "react";
import { Container, Heading } from "@dtpk-cc/components";
import * as styles from "./map-sidebar-component-wrapper.module.scss";

type MapSidebarComponentWrapperProps = {
  headline: string;
  showDivider: boolean;
};

const MapSidebarComponentWrapper: React.FC<MapSidebarComponentWrapperProps> = ({
  headline,
  showDivider,
  children,
}) => (
  <Container variants={[Container.Variant.paddedVertical]}>
    <Heading
      tag="h4"
      variants={[Heading.Variant.quaternary]}
      customClass={`${styles.componentWrapperHeadline}`}
    >
      {headline}
    </Heading>
    {children}
    {showDivider && <div className={`${styles.componentWrapperDivider}`} />}
  </Container>
);

export default MapSidebarComponentWrapper;
