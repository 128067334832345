import React, { useCallback, useState, useEffect } from "react";
import { Button } from "@dtpk-cc/components";
import HomeConfigurator from "../../views/PencilSelling/HomeConfigurator";
// TODO temporary hidden
// import StreamingAnalysis from "../../views/PencilSelling/StreamingAnalysis";
import { trackClick } from "../../helpers/reactTracking";

import "./pencil-selling-toolbar.scss";

export const TOOL_KEY_HNP = "hnp";
// TODO temporary hidden
// export const TOOL_KEY_STREAMING_ANALYSIS = "streamingAnalysis";
export const TOOL_KEY_SKETCHBOARD = "sketchboard";

const headerDefaultHeight = 72;

const PencilSellingToolbar = ({
  toggleSketchboard,
  applyToolConfig,
  initialActiveComponentKey,
}) => {
  const [toolbarIsOpen, setToolbarIsOpen] = useState(false);
  const [toolbarYOffset, setToolbarYOffset] = useState(
    document.querySelector(".page-header")?.offsetHeight || headerDefaultHeight
  );
  const [activeComponentKey, setActiveComponentKey] = useState(
    initialActiveComponentKey
  );

  const toggleToolbar = useCallback(() => {
    setToolbarIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (window) {
      const headerHeight =
        document.querySelector(".page-header")?.offsetHeight || 0;
      const mainNavHeight =
        document.querySelector(".main-nav")?.offsetHeight || 0;
      document.addEventListener("scroll", () => {
        // Recalculate toolbar position only if toolbarIsOpen for performance case.
        // And to prevent action group to change position on scroll
        if (toolbarIsOpen) {
          const headerTotalHeight = headerHeight + mainNavHeight;
          if (window.scrollY >= headerTotalHeight) {
            setToolbarYOffset(0);
          } else {
            setToolbarYOffset(headerTotalHeight - window.scrollY);
          }
        }
      });
    }
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, [toolbarIsOpen]);

  const toolsMap = {
    [TOOL_KEY_SKETCHBOARD]: {
      componentData: {
        component: null,
        props: {},
      },
      actionBtnData: {
        text: "Notizblock",
        onClick: () => {
          toggleSketchboard();
          trackClick("notizblock-open");
        },
      },
      closeHandler: () => {},
    },
    [TOOL_KEY_HNP]: {
      componentData: {
        component: HomeConfigurator,
        props: {
          applyToolConfig: () => {
            toggleToolbar();
            applyToolConfig();
          },
        },
      },
      actionBtnData: {
        text: "Heimnetz",
        onClick: (componentKey) => {
          setActiveComponentKey(componentKey);
          toggleToolbar();
          trackClick("heimnetz-open");
        },
      },
      closeHandler: () => {
        toggleToolbar();
        trackClick("heimnetz-close");
      },
    },
    // TODO temporary hidden
    // [TOOL_KEY_STREAMING_ANALYSIS]: {
    //   componentData: {
    //     component: StreamingAnalysis,
    //     props: {
    //       applyToolConfig,
    //       toggleToolbar,
    //       setForceRerender: () => {
    //         setForceRerender((prev) => !prev);
    //       },
    //     },
    //   },
    //   actionBtnData: {
    //     text: "TV-Streaming Check",
    //     onClick: (componentKey) => {
    //       setActiveComponentKey(componentKey);
    //       toggleToolbar();
    //       trackClick("streaming-check-open");
    //     },
    //   },
    //   closeHandler: () => {
    //     toggleToolbar();
    //     trackClick("streaming-check-close");
    //   },
    // },
  };

  const ActiveComponent =
    toolsMap[activeComponentKey]?.componentData?.component || null;
  const activeComponentProps =
    toolsMap[activeComponentKey]?.componentData?.props || {};

  useEffect(() => {
    if (initialActiveComponentKey) {
      setActiveComponentKey(initialActiveComponentKey);
      toggleToolbar();
    }
  }, [toggleToolbar, initialActiveComponentKey]);

  return (
    <div
      style={{ top: toolbarYOffset }}
      className={`pencil-selling-toolbar
              ${toolbarIsOpen ? "pencil-selling-toolbar--active" : ""}`}
    >
      <div aria-hidden={!toolbarIsOpen}>
        {ActiveComponent && (
          <ActiveComponent
            {...activeComponentProps}
            closeHandler={() => {
              toolsMap[activeComponentKey].closeHandler();
            }}
          />
        )}
      </div>
      <div className="pencil-selling-toolbar__action-group">
        {Object.keys(toolsMap).map((toolKey) => (
          <Button
            key={toolKey}
            onClick={() => {
              toolsMap[toolKey].actionBtnData.onClick(toolKey);
            }}
            customClass="pencil-selling-toolbar__action-btn"
          >
            {toolsMap[toolKey].actionBtnData.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default PencilSellingToolbar;
