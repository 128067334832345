import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ICustomer } from "core/entities/PencilSelling/Customer/ICustomer";
import type { IGetCustomerUseCase } from "./IGetCustomer";

export class GetCustomerUseCase implements IGetCustomerUseCase {
  constructor(private entity: IOffer) {}

  execute(): ICustomer {
    return this.entity.customer.get();
  }
}
