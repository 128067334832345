import React, { SyntheticEvent } from "react";
import {
  Badge,
  CopyText,
  Heading,
  Price,
  Tile,
  Dropdown,
  Input,
} from "@dtpk-cc/components";
import { CardSections, ICard } from "core/entities/Product/Card/ICard";
import { observer } from "mobx-react";
import * as styles from "../card-tile.module.scss";
import { useCardsLevelChange } from "../../../../hooks/MagentaNext/useCardsLevelChange";

type MagentaNextCardTileProps = {
  isActive: boolean;
  title: string;
  onClick: () => void;
  priceSuffix: string;
  content: string;
  className?: string;
  disabled?: boolean;
  magentaNextCards?: ICard[];
  changeCardQuantity: (key: string, quantity: number) => void;
};

const MagentaNextCardTile = ({
  isActive,
  title,
  onClick,
  priceSuffix,
  content,
  className = "",
  disabled = false,
  magentaNextCards = [],
  changeCardQuantity,
}: MagentaNextCardTileProps) => {
  const {
    addCardHandler,
    activeCardsPointers,
    removeCardHandler,
    changeLevelHandler,
    levelsNameArr,
    changeSmartPhoneDataHandler,
    getCardMonthlyPrice,
  } = useCardsLevelChange(
    magentaNextCards,
    changeCardQuantity,
    CardSections.MAGENTA_NEXT
  );

  const toggleCardAdd = () => {
    if (!activeCardsPointers.length) {
      addCardHandler(false);
    } else {
      removeCardHandler(0, activeCardsPointers[0].cardKey);
    }
  };

  const preventPropagation = (event: SyntheticEvent) => {
    if (isActive) {
      event.stopPropagation();
    }
  };

  return (
    <Tile
      variant="shadow"
      tabIndex={0}
      role="button"
      onClick={() => {
        onClick();
        toggleCardAdd();
      }}
      customClass={`
        ${styles.cardTile}
        ${styles.tile}
        ${className}
        ${isActive ? styles.active : ""}
        ${disabled ? styles.disabled : ""}
      `}
    >
      {isActive && (
        <Badge
          variants={Badge.Variant.flag}
          customClass={`${styles.rightCornerBadge}`}
        >
          <Badge.Paragraph fonts={Badge.Paragraph.Font.additionalHighlight}>
            <span className={`${styles.cardTile} ${styles.badge}`}>
              Ausgewählt
            </span>
          </Badge.Paragraph>
        </Badge>
      )}
      <Tile.Content customClass={`${styles.cardTile} ${styles.content}`}>
        <div className={`${styles.cardTile} ${styles.headerWrapper}`}>
          <Heading customClass={`${styles.cardTile} ${styles.heading}`}>
            {title}
          </Heading>
          <div className={`${styles.cardTile}`}>
            {content && (
              <CopyText
                customClass={`${styles.cardTile} ${styles.magentaNextText}`}
                variants={[CopyText.Variant.highlight]}
                tag="span"
              >
                {content}
              </CopyText>
            )}
            {activeCardsPointers[0]?.cardKey && (
              <Price
                value={getCardMonthlyPrice(activeCardsPointers[0]?.cardKey)}
                suffix={priceSuffix}
                variants={[
                  Price.Variant.brand,
                  Price.Variant.large,
                  Price.Variant.highlight,
                ]}
              />
            )}
          </div>
        </div>
        <div className={`${styles.cardTile} ${styles.inputsOuter}`}>
          <div
            tabIndex={isActive ? 0 : -1}
            role="button"
            onKeyDown={preventPropagation}
            onClick={preventPropagation}
            className={`${styles.cardTile} ${styles.inputsWrapper} ${
              !isActive ? styles.inputDisabled : ""
            }`}
          >
            <Dropdown
              tabIndex={isActive ? 0 : -1}
              customClass={`${styles.cardTile} ${styles.dropdown}`}
              label="Substufe"
              value={activeCardsPointers[0]?.levelName || ""}
              onSelection={(levelNameValue) => {
                changeLevelHandler(
                  0,
                  levelNameValue,
                  activeCardsPointers[0].levelName
                );
              }}
              items={levelsNameArr}
            />
            <Input
              customClass={`${styles.cardTile} ${styles.phoneName}`}
              tabIndex={isActive ? 0 : -1}
              onKeyDown={preventPropagation}
              onChange={(event) => {
                preventPropagation(event);
                changeSmartPhoneDataHandler(
                  "smartphoneName",
                  event.target.value,
                  0
                );
              }}
              type="text"
              value={activeCardsPointers[0]?.smartphoneName || ""}
            >
              Smartphone
            </Input>
            <Input
              customClass={`${styles.cardTile} ${styles.phonePrice}`}
              tabIndex={isActive ? 0 : -1}
              onKeyPress={preventPropagation}
              onChange={(event) => {
                preventPropagation(event);
                changeSmartPhoneDataHandler(
                  "smartphonePrice",
                  event.target.value,
                  0
                );
              }}
              type="number"
              value={activeCardsPointers[0]?.smartphonePrice || ""}
            >
              Kaufpreis
            </Input>
          </div>
        </div>
      </Tile.Content>
    </Tile>
  );
};

export default observer(MagentaNextCardTile);
