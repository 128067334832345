import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { CopyText } from "@dtpk-cc/components";
import RadioButtonInput from "elements/new-design/RadioButtonMain/RadioButtonInput";
import TelekomClaimMagenta from "../../../../assets/images/t_logo_claim_magenta_2024.svg";
import * as styles from "./map-sidebar.module.scss";
import {
  MapSettingsBackgroundColorScheme,
  MapSettingsColorScheme,
  MapSettingsManager,
} from "../hooks/types";
import { MapData } from "../hooks/useMapData";
import MapSidebarComponentWrapper from "./MapSidebarComponentWrapper";
import MapSidebarChart from "./MapSidebarChart";
import MapSidebarRoute from "./MapSidebarRoute";
import MapSidebarRadiusSearch from "./MapSidebarRadiusSearch";
import { trackClick } from "helpers/reactTracking";
import { MAP_FEATURE_CONTENT } from "../constants/const";

type MapSidebarProps = {
  mapSettingsManager: MapSettingsManager;
  mapData: MapData;
};

const MapSidebarHeadlines = {
  chart: {
    viewport: "Netzabdeckung in dem Bereich",
    address: "Netzabdeckung an der Adresse",
    route: "Netzabdeckung auf der Route",
  },
} as const;

const MapSidebar = ({ mapSettingsManager, mapData }: MapSidebarProps) => {
  const sidebarRef = useRef(null);

  const { mapSettings, mapSettingsSetter, mapSettingsMode } =
    mapSettingsManager;

  useEffect(() => {
    if (sidebarRef.current) {
      L.DomEvent.disableClickPropagation(sidebarRef.current);
      L.DomEvent.disableScrollPropagation(sidebarRef.current);
    }
  }, []);

  const mapsidebarColorSchemeSettings = [
    {
      title: "Netzabdeckung",
      onChange: (value: string) => {
        trackClick(
          `netzabdeckung.${value === "true" ? "im_freien" : "im_gebaeude"}`,
          MAP_FEATURE_CONTENT
        );
        mapSettingsSetter.setOutdoorNetworkCoverage(value === "true");
      },
      value: mapSettings.layerOptions.outdoorNetworkCoverage,
      options: [
        {
          value: true,
          label: "im Freien",
        },
        {
          value: false,
          label: "im Gebäude",
        },
      ],
    },
    {
      title: "Farbschema der Karte",
      onChange: (value: MapSettingsColorScheme) => {
        trackClick(`farbschema.${value}`, MAP_FEATURE_CONTENT);
        mapSettingsSetter.setColorScheme(value);
      },
      value: mapSettings.colorScheme,
      options: [
        {
          value: MapSettingsColorScheme.colorful,
          label: "Ampel-Farben",
        },
        {
          value: MapSettingsColorScheme.magenta,
          label: "Magenta-Farben",
        },
      ],
    },
    {
      title: "Hintergrundfarbe der Karte",
      onChange: (value: MapSettingsBackgroundColorScheme) => {
        trackClick(`hintergrundfarbschema.${value}`, MAP_FEATURE_CONTENT);
        mapSettingsSetter.setBackgroundColorScheme(value);
      },
      value: mapSettings.backgroundColorScheme,
      options: [
        {
          value: MapSettingsBackgroundColorScheme.default,
          label: "Normal",
        },
        {
          value: MapSettingsBackgroundColorScheme.topografic,
          label: "Topografisch",
        },
        {
          value: MapSettingsBackgroundColorScheme.blackWhite,
          label: "Schwarz-Weiß",
        },
      ],
    },
  ];

  return (
    <div className={`${styles.sidebarContainer}`} ref={sidebarRef}>
      <TelekomClaimMagenta className={styles.telekomClaim} />
      <MapSidebarRoute
        mapSettings={mapSettings}
        mapSettingsSetter={mapSettingsSetter}
      />
      {mapData.networkProvidersQualitySummary && (
        <MapSidebarComponentWrapper
          headline={MapSidebarHeadlines.chart[mapSettingsMode]}
          showDivider={true}
        >
          <MapSidebarChart
            networkProvidersQualitySummary={
              mapData.networkProvidersQualitySummary
            }
            colorScheme={mapSettings.colorScheme}
          />
        </MapSidebarComponentWrapper>
      )}
      <MapSidebarComponentWrapper showDivider={false} headline={"Umkreissuche"}>
        <MapSidebarRadiusSearch
          mapSettings={mapSettings}
          mapSettingsSetter={mapSettingsSetter}
        />
      </MapSidebarComponentWrapper>
      {mapsidebarColorSchemeSettings.map((setting) => (
        <MapSidebarComponentWrapper
          showDivider={false}
          headline={setting.title}
          key={setting.title}
        >
          <div className={styles.colorSchemeWrapper}>
            {setting.options.map((colorSchemeOption) => (
              <label
                className={styles.colorSchemeOption}
                htmlFor={colorSchemeOption.value}
                key={colorSchemeOption.value}
              >
                <RadioButtonInput
                  value={colorSchemeOption.value}
                  name={colorSchemeOption.value}
                  id={colorSchemeOption.value}
                  inputTestId={colorSchemeOption.value}
                  checked={setting.value === colorSchemeOption.value}
                  onChange={setting.onChange}
                />
                <CopyText>{colorSchemeOption.label}</CopyText>
              </label>
            ))}
          </div>
        </MapSidebarComponentWrapper>
      ))}
    </div>
  );
};

export default MapSidebar;
