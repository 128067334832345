import React from "react";
import { Button, Tile, Heading, A11yText } from "@dtpk-cc/components";
import Plus from "../../../assets/images/icons/plus.svg";
import "./custom-item.scss";

type CustomItemProps = {
  text: string;
  handleOnClick: () => void;
  cssClass?: string;
};

const CustomItem = ({ text, handleOnClick, cssClass }: CustomItemProps) => (
  <Tile
    customClass={`custom-item ${cssClass}`}
    variant="shadow"
    onClick={handleOnClick}
    onKeyPress={(event) => (event.key === "Enter" ? handleOnClick() : null)}
    tabIndex={0}
    itemType="button"
    aria-label="Weiteres Gerät hinzufügen"
  >
    <Tile.Content>
      <Button
        customClass="custom-item__button"
        tabIndex={-1}
        variants={[Button.Variant.roundPrimary, Button.Variant.icon]}
      >
        <A11yText>{text}</A11yText>
        <Button.Icon customClass="custom-item__button-icon" icon={Plus} />
      </Button>

      <Heading
        variants={Heading.Variant.brand}
        customClass="custom-item__heading"
      >
        {text}
      </Heading>
    </Tile.Content>
  </Tile>
);

export default CustomItem;
