// extracted by mini-css-extract-plugin
var _1 = "phone-item-module-disabled-y8bJv";
var _2 = "phone-item-module-dropdownWrapper-PtlAI";
var _3 = "phone-item-module-isAlternativeCheck-JNDUE";
var _4 = "phone-item-module-phoneItemSelection-qsbZk";
var _5 = "phone-item-module-phoneItemSubtitle-XbL6h";
var _6 = "phone-item-module-removeBtn-Td9fb";
var _7 = "phone-item-module-removeBtnWrapper-YP46Q";
var _8 = "phone-item-module-removeCheckBoxWrapper-IVPNi";
export { _1 as "disabled", _2 as "dropdownWrapper", _3 as "isAlternativeCheck", _4 as "phoneItemSelection", _5 as "phoneItemSubtitle", _6 as "removeBtn", _7 as "removeBtnWrapper", _8 as "removeCheckBoxWrapper" }
