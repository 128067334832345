import { IFindProductInCart } from "./IFindProductInCart";
import { ICart } from "../../../entities/PencilSelling/Cart/ICart";
import { ICartItemData } from "../../../entities/PencilSelling/CartItem/ICartItem";

export class FindProductInCartUseCase implements IFindProductInCart {
  constructor(private cart: ICart) {}

  execute(key: string): ICartItemData | null {
    return this.cart.find(key);
  }
}