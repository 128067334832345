export interface IDiscount {
  kind: PromotionPriceType;
  value: number | null;
  interval: number | null;
  description?: string | null;
  from?: number | null;
  to?: number | null;
}

export enum PromotionPriceType {
  MONTHLY = "monthly",
  MONTHLY_DISCOUNT = "monthly_discount",
  PROVISION_DISCOUNT = "provision_discount",
  DISCOUNT = "discount",
  JUST_A_NUMBER = "just_a_number",
  CASHBACK = "cashback",
  CUSTOM_PROMOTION = "custom_promotion",
  PERMANENT_MONTHLY_DISCOUNT = "permanent_monthly_discount",
  MONTHLY_DISCOUNT_IN_PERCENT = "monthly_discount_in_percent",
  DISCOUNT_IN_PERCENT = "discount_in_percent",
  PROVISION_DISCOUNT_IN_PERCENT = "provision_discount_in_percent",
}
