import React from "react";
import { Heading } from "@dtpk-cc/components";
import SwitchButton from "../SwitchButton";

import * as styles from "./switch-variants.module.scss";

type SwitchVariantsProps = {
  onClick: () => void;
  isChecked: boolean;
  leftVariantTitle: string;
  rightVariantTitle: string;
  disabled?: boolean;
  containerCustomClass?: string;
};

const SwitchVariants = ({
  onClick,
  isChecked,
  leftVariantTitle,
  rightVariantTitle,
  containerCustomClass = "",
  disabled = false,
}: SwitchVariantsProps) => (
  <div
    className={`${styles.container} ${
      disabled ? styles.disabled : ""
    } ${containerCustomClass}`}
  >
    <Heading
      customClass={`${styles.leftVariantTitle} m-b-0`}
      tag="p"
      variants={
        !isChecked
          ? [Heading.Variant.quaternary, Heading.Variant.highlight]
          : Heading.Variant.quaternary
      }
    >
      {leftVariantTitle}
    </Heading>
    <SwitchButton
      disabled={disabled}
      customClass={styles.switchBtn}
      onClick={onClick}
      checked={isChecked}
    />
    <Heading
      customClass={`${styles.rightVariantTitle} m-b-0`}
      tag="p"
      variants={
        isChecked
          ? [Heading.Variant.quaternary, Heading.Variant.highlight]
          : Heading.Variant.quaternary
      }
    >
      {rightVariantTitle}
    </Heading>
  </div>
);

export default SwitchVariants;
