import React, { Fragment } from "react";
import {
  Tile,
  Container,
  Price,
  CopyText,
  Button,
  Grid,
  List,
} from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import { trackClick } from "helpers/reactTracking";

import ActionEditSelected from "@dtpk-cc/components/dist/icons/Action/Edit/Selected";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";

import "./card-offer-section.scss";
import { TariffSizes } from "core/entities/Product/Tariff/ITariff";
import { ICartItemData } from "../../core/entities/PencilSelling/CartItem/ICartItem";

interface CardOfferSectionProps {
  name: string;
  activeCards: ICartItemData[];
  enabled: boolean;
  dataVolume: string;
  price: number;
  limited: boolean;
  openCardSectionModal: (card: ICartItemData) => void;
  onRemove: (card: ICartItemData) => void;
  trackingContext: string;
  isMultiSimSection?: boolean;
  checkIsXLSize?: boolean;
  sectionSize?: string;
}

const CardOfferSection = ({
  name,
  activeCards = [],
  enabled,
  dataVolume,
  price,
  limited,
  openCardSectionModal,
  onRemove,
  trackingContext = "",
  isMultiSimSection,
  checkIsXLSize,
  sectionSize,
}: CardOfferSectionProps) => {
  const enabledMultiSimSection = () => {
    if (checkIsXLSize) {
      // NOTE: Multisim XL section has XL size
      return sectionSize === TariffSizes.XL;
    }
    // NOTE: Multisim section has no size
    return sectionSize === null;
  };

  const sectionEnabled = isMultiSimSection
    ? enabledMultiSimSection() && enabled
    : enabled;

  const customClasses = sectionEnabled
    ? "card-offer-section card-offer-section--enabled"
    : "card-offer-section";

  return (
    <Container customClass={customClasses}>
      <Tile variant="shadow">
        <Tile.Content customClass="card-offer-section__content">
          <Grid>
            <Grid.Row customClass={"card-offer-section__row"}>
              <Grid.Col
                m={1}
                l={1}
                xl={1}
                customClass={
                  limited
                    ? "card-offer-section__button-col card-offer-section__button-col--limited"
                    : "card-offer-section__button-col"
                }
              >
                <Button
                  variants={[Button.Variant.outline, Button.Variant.icon]}
                  disabled={!sectionEnabled || limited}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    openCardSectionModal(null);
                    event.currentTarget.blur();
                    trackClick(`modal.${name}.add-icon`, trackingContext);
                  }}
                >
                  <Button.Icon icon={ActionAddDefault} />
                </Button>
              </Grid.Col>

              <Grid.Col
                customClass="card-offer-section__padded-cell"
                m={3}
                l={3}
                xl={6}
              >
                <CopyText customClass="section-item__card-heading">
                  {name}
                </CopyText>
              </Grid.Col>
              <Grid.Col
                customClass="card-offer-section__padded-cell"
                m={3}
                l={3}
                xl={6}
              />
              <Grid.Col
                customClass="card-offer-section__padded-cell"
                m={2}
                l={2}
                xl={4}
              />

              {sectionEnabled && (
                <>
                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={3}
                    l={3}
                    xl={7}
                  >
                    <Container
                      customClass={`card-offer-section__content-section ${
                        activeCards.length
                          ? "card-offer-section__content-section--with-cards"
                          : ""
                      }`}
                    >
                      <CopyText>{dataVolume}</CopyText>
                      {!activeCards.length && (
                        <Price
                          variants={[Price.Variant.brand]}
                          suffix="mtl. pro Karte"
                          value={price}
                        />
                      )}
                    </Container>
                  </Grid.Col>
                </>
              )}
            </Grid.Row>

            {!!activeCards.length && (
              <>
                <Grid.Row customClass="card-offer-section__row card-offer-section__row--heading">
                  <Grid.Col
                    m={1}
                    l={1}
                    xl={1}
                    customClass="card-offer-section__button-col"
                  />
                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={3}
                    l={3}
                    xl={6}
                  >
                    <CopyText>Karte</CopyText>
                  </Grid.Col>

                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={2}
                    l={2}
                    xl={4}
                  >
                    <CopyText>Smartphone</CopyText>
                  </Grid.Col>

                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={3}
                    l={3}
                    xl={6}
                  >
                    <CopyText>Aktionen</CopyText>
                  </Grid.Col>
                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={1}
                    l={1}
                    xl={2}
                  />
                  <Grid.Col
                    customClass="card-offer-section__padded-cell"
                    m={2}
                    l={2}
                    xl={5}
                  >
                    <CopyText style={{ textAlign: "right" }}>Preis</CopyText>
                  </Grid.Col>
                </Grid.Row>

                {activeCards.map((card, index) => (
                  <Fragment key={card.key}>
                    <Grid.Row customClass="card-offer-section__card-row card-offer-section__row">
                      <Grid.Col
                        m={1}
                        l={1}
                        xl={1}
                        customClass="card-offer-section__button-col"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          variants={[Button.Variant.bare]}
                          onClick={() => {
                            openCardSectionModal(card);
                            trackClick(
                              `modal.${name}.edit-icon`,
                              trackingContext
                            );
                          }}
                        >
                          <Button.Icon icon={ActionEditSelected} />
                        </Button>
                      </Grid.Col>

                      <Grid.Col
                        customClass="card-offer-section__padded-cell"
                        m={3}
                        l={3}
                        xl={6}
                      >
                        <CopyText customClass="card-offer-section__card-note">
                          Karte {index + 1}{" "}
                          {card.cardData.note ? `- ${card.cardData.note}` : ""}
                        </CopyText>
                        <CopyText>{card.name}</CopyText>
                      </Grid.Col>
                      <Grid.Col
                        customClass="card-offer-section__padded-cell"
                        m={2}
                        l={2}
                        xl={4}
                      >
                        <CopyText
                          customClass={
                            card.additionalDevices[0].name
                              ? ""
                              : "card-offer-section--empty-cell"
                          }
                        >
                          {card.additionalDevices[0].name}
                        </CopyText>
                      </Grid.Col>
                      <Grid.Col
                        customClass="card-offer-section__padded-cell"
                        m={3}
                        l={3}
                        xl={6}
                      >
                        <Container
                          customClass={
                            card.promotions.length > 0
                              ? ""
                              : "card-offer-section--empty-cell"
                          }
                        >
                          {!!card.promotions.length && (
                            <List customClass="card-offer-section__list">
                              {card.promotions.map((promotion) => (
                                <List.Item key={promotion.name}>
                                  {promotion.description}
                                </List.Item>
                              ))}
                            </List>
                          )}
                        </Container>
                      </Grid.Col>

                      <Grid.Col
                        customClass="card-offer-section__padded-cell"
                        m={1}
                        l={1}
                        xl={2}
                      >
                        <Button
                          variants={[Button.Variant.bare]}
                          onClick={() => {
                            onRemove(card);
                            trackClick(
                              `modal.${name}.remove-icon`,
                              trackingContext
                            );
                          }}
                        >
                          <Button.Icon icon={ActionRemoveDefault} />
                        </Button>
                      </Grid.Col>
                      <Grid.Col
                        customClass="card-offer-section__padded-cell"
                        m={2}
                        l={2}
                        xl={5}
                      >
                        <Price
                          style={{ textAlign: "right" }}
                          variants={[Price.Variant.brand]}
                          value={card.price.monthly}
                          suffix={"mtl."}
                        />
                      </Grid.Col>
                      {!!card.cardData.multiSim.quantity && (
                        <Grid.Row customClass="card-offer-section__multisim-row">
                          <Grid.Col m={1} l={1} xl={1} />
                          <Grid.Col
                            customClass="card-offer-section__padded-cell"
                            m={9}
                            l={9}
                            xl={18}
                          >
                            <CopyText>
                              {card.cardData.multiSim.quantity}x MultiSIM
                            </CopyText>
                          </Grid.Col>
                          <Grid.Col
                            customClass="card-offer-section__padded-cell"
                            m={2}
                            l={2}
                            xl={5}
                          >
                            <Price
                              prefix="je"
                              style={{ textAlign: "right" }}
                              variants={[Price.Variant.brand]}
                              value={card.cardData.multiSim.price}
                              suffix={"mtl."}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}
                    </Grid.Row>
                  </Fragment>
                ))}
              </>
            )}
          </Grid>
        </Tile.Content>
      </Tile>
    </Container>
  );
};

export default CardOfferSection;
