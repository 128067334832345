import React from "react";
import { Tooltip } from "react-leaflet";
import { Tower } from "views/Map/api/types";
import { TowerIcons } from "views/Map/constants/const";

type MapLayerTowerTooltipProps = {
  tower: Tower | null;
};

const MapLayerTowerTooltip = ({ tower }: MapLayerTowerTooltipProps) => {
  if (tower && TowerIcons[tower]?.description) {
    return (
      <Tooltip direction="bottom" permanent>
        {TowerIcons[tower]?.description}
      </Tooltip>
    );
  }

  return null;
};

export default MapLayerTowerTooltip;
