import React from "react";
import { observer } from "mobx-react";
import AddonsSelection from "../../../../../components/new-design/AddonsSelection";
import {
  Group,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import { getBusinessCaseName } from "../../../../../helpers/GetBusinessCaseName";
import { PropsWithBusinessCase } from "../../../BusinessClient/types";
import { businessCaseOptions } from "../../../../../core/entities/Product/Tariff/ITariff";
import { useGetCustomAddonContractPeriod } from "../../../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { useStores } from "../../../../../stores/PrivateClient";
import { CUSTOM_EXTRA_FORM_DATA } from "../../../../../components/new-design/AddonsSelection/const";

const LandlineTariffAddons = ({ businessCase }: PropsWithBusinessCase) => {
  const { configurationsStore, offerStore, productsStore } = useStores();
  const addons = productsStore.getLandlineOptions();
  const addonsInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getLandlineOptions();
  const activeTariffKey =
    configurationsStore.getActiveConfigurationChildren().getLandlineTariff()
      ?.key || null;
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );

  return (
    <AddonsSelection
      key={addons.length}
      products={addons}
      activeProducts={addonsInCart}
      isFungible={true}
      allowCustomProducts={false}
      condition={activeTariffKey}
      portfolio={PortfolioKeys.LANDLINE}
      addonGroup={Group.addon}
      trackingContext="Extras Landline"
      configurationsStore={configurationsStore}
      businessCaseName={getBusinessCaseName(businessCase, businessCaseOptions)}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_EXTRA_FORM_DATA}
    />
  );
};

export default observer(LandlineTariffAddons);
