import React, { useState } from "react";
import { observer } from "mobx-react";
import { A11yText, Button, Heading, Icon } from "@dtpk-cc/components";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";
import TileContainerTitle from "components/new-design/TileContainer/TileContainerTitle";
import RadioButtonMain from "elements/new-design/RadioButtonMain";

import {
  IBusinessCaseData,
  landlineBusinessCase,
  LandlineBusinessCaseCustomerTypes,
  mobileBusinessCase,
  secondaryCardsBusinessCase,
} from "core/entities/Product/BusinessClient/IBaseData";
import { IBusinessCasesTypeValues } from "core/entities/Product/Tariff/ITariff";
import { ConfigurationsEntity } from "core/entities/PencilSelling/Configurations/Configurations";
import { trackClick } from "helpers/reactTracking";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import * as styles from "./busines-cases.module.scss";
import BusinessCaseLandlineInfoModal from "./BusinessCaseLandlineInfoModal";
import WarningModal from "../../WarningModal";
import SwitchVariants from "../../../../elements/new-design/SwitchVariants";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";

type BusinessCasesProps = {
  trackingContext: string;
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  onConfigurationsReset?: (type: ICartConfigurationType) => void;
};

const BusinessCases = ({
  trackingContext,
  configurationsStore,
  offerStore,
  onConfigurationsReset = () => {},
}: BusinessCasesProps) => {
  const isBusinessClient =
    offerStore.customer.get().customerType === CustomerType.BUSINESS;
  const landlineLabel =
    offerStore.customer.get().customerType !== CustomerType.PRIVATE
      ? landlineBusinessCase.alterLabel
      : landlineBusinessCase.label;
  const defineLandlineCustomerType = () => {
    const currentBusinessCase = offerStore.getBusinessCase(
      ICartConfigurationType.LANDLINE
    );
    const isNewCustomer = landlineBusinessCase[
      LandlineBusinessCaseCustomerTypes.NEW_CUSTOMER_ITEMS
    ].items.some((item) => item.key === currentBusinessCase);

    return isNewCustomer
      ? LandlineBusinessCaseCustomerTypes.NEW_CUSTOMER_ITEMS
      : LandlineBusinessCaseCustomerTypes.EXISTING_CUSTOMER_ITEMS;
  };
  const landlineCustomerType = defineLandlineCustomerType();

  const [landlineInfoModalIsOpen, setLandlineInfoModalIsOpen] = useState(false);
  const [businessCaseResetModalIsOpen, setBusinessCaseResetModalIsOpen] =
    useState(false);
  const [resetModalSubmitCallback, setResetModalSubmitCallback] = useState<
    () => void
  >(() => {});

  const businessCasesMap: Array<
    IBusinessCaseData & { type: ICartConfigurationType }
  > = [
    {
      type: ICartConfigurationType.MOBILE,
      label: mobileBusinessCase.label,
      items: mobileBusinessCase.items,
    },
    {
      type: ICartConfigurationType.CARD,
      label: secondaryCardsBusinessCase.label,
      items: secondaryCardsBusinessCase.items,
    },
  ];

  const removeConfigurationsDialogHandler = (
    type: ICartConfigurationType,
    callback: () => void
  ) => {
    const configurationsList =
      configurationsStore.getConfigurationsByType(type);

    if (configurationsList.length > 0) {
      setResetModalSubmitCallback(() => () => {
        onConfigurationsReset(type);
        callback();
        setBusinessCaseResetModalIsOpen(false);
      });
      setBusinessCaseResetModalIsOpen(true);
    } else {
      callback();
    }
  };

  const businessCaseChangeHandler = (
    value: IBusinessCasesTypeValues,
    type: ICartConfigurationType
  ) => {
    offerStore.setBusinessCase(type, value);
  };

  const toggleLandlineCustomerType = () => {
    const customerTypeValue =
      landlineCustomerType ===
      LandlineBusinessCaseCustomerTypes.NEW_CUSTOMER_ITEMS
        ? LandlineBusinessCaseCustomerTypes.EXISTING_CUSTOMER_ITEMS
        : LandlineBusinessCaseCustomerTypes.NEW_CUSTOMER_ITEMS;
    // When users switch between customer possible types -> select first possible business case from available one;
    businessCaseChangeHandler(
      landlineBusinessCase[customerTypeValue].items[0].key,
      ICartConfigurationType.LANDLINE
    );
  };

  return (
    <>
      {businessCasesMap.map((businessCaseSection) => (
        <div
          className={styles.radioButtonSection}
          key={businessCaseSection.label}
        >
          <TileContainerTitle
            variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
            title={businessCaseSection.label}
          />
          <div className={styles.radioButtonContainer}>
            {businessCaseSection.items.map((businessCaseItem, index) => (
              <RadioButtonMain
                key={`${businessCaseSection.label}-${businessCaseItem.name}-${index}`}
                id={`${businessCaseSection.label}-${businessCaseItem.name}-${index}`}
                inputTestId={`${businessCaseSection.label}-${businessCaseItem.name}-${index}`}
                title={businessCaseItem.name}
                onChange={(value: IBusinessCasesTypeValues) => {
                  removeConfigurationsDialogHandler(
                    businessCaseSection.type,
                    () => {
                      businessCaseChangeHandler(
                        value,
                        businessCaseSection.type
                      );
                    }
                  );
                  trackClick(
                    `${businessCaseSection.label}.${businessCaseItem.name}`,
                    trackingContext
                  );
                }}
                checked={
                  businessCaseItem.key ===
                  offerStore.getBusinessCase(businessCaseSection.type)
                }
                value={businessCaseItem.key}
                name={businessCaseSection.label}
                customClass={styles.radioButtonItem}
              />
            ))}
          </div>
        </div>
      ))}
      <div className={styles.radioButtonSection}>
        <div className={styles.sectionTitleWrapper}>
          <TileContainerTitle
            variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
            title={landlineLabel}
          />
          <Button
            data-testid="landline-business-case-info"
            customClass={styles.sectionTitleButton}
            tabIndex={0}
            variants={Button.Variant.bare}
            onClick={() => {
              setLandlineInfoModalIsOpen(true);
              trackClick(
                `${landlineLabel}.business.case.info`,
                trackingContext
              );
            }}
          >
            <Icon icon={AlertInformationDefault} size={Icon.Size.xsmall} />
            <A11yText>{landlineLabel}-Business-Case-Info</A11yText>
          </Button>
        </div>
        <SwitchVariants
          containerCustomClass={styles.landlineCustomerSwitchWrapper}
          onClick={() => {
            removeConfigurationsDialogHandler(
              ICartConfigurationType.LANDLINE,
              () => {
                toggleLandlineCustomerType();
              }
            );
          }}
          isChecked={
            landlineCustomerType ===
            LandlineBusinessCaseCustomerTypes.EXISTING_CUSTOMER_ITEMS
          }
          leftVariantTitle="Festnetz Neukunde"
          rightVariantTitle="Festnetz Bestandskunde"
        />
        <div className={styles.radioButtonContainer}>
          {landlineBusinessCase[landlineCustomerType].items
            .filter((item) =>
              isBusinessClient
                ? ["Festnetz-Neukunde", "Breitband-Neukunde"].includes(
                    item.name
                  )
                : true
            )
            .map((businessCaseItem, index) => (
              <RadioButtonMain
                key={`${landlineLabel}-${businessCaseItem.name}-${index}`}
                inputTestId={`${landlineLabel}-${businessCaseItem.name}-${index}`}
                title={
                  isBusinessClient &&
                  businessCaseItem.name === "Breitband-Neukunde"
                    ? "Festnetz-Bestandskunde"
                    : businessCaseItem.name
                }
                onChange={(value: IBusinessCasesTypeValues) => {
                  removeConfigurationsDialogHandler(
                    ICartConfigurationType.LANDLINE,
                    () => {
                      businessCaseChangeHandler(
                        value,
                        ICartConfigurationType.LANDLINE
                      );
                    }
                  );
                  trackClick(
                    `${landlineLabel}.${businessCaseItem.name}`,
                    trackingContext
                  );
                }}
                checked={
                  businessCaseItem.key ===
                  offerStore.getBusinessCase(ICartConfigurationType.LANDLINE)
                }
                value={businessCaseItem.key}
                name={landlineLabel}
                id={`${landlineLabel}-${businessCaseItem.name}-${index}`}
                customClass={styles.radioButtonItem}
              />
            ))}
        </div>
      </div>
      <WarningModal
        onSubmit={() => {
          resetModalSubmitCallback();
          trackClick("geschäftsfall.zurücksetzen", trackingContext);
        }}
        onCancel={() => {
          setBusinessCaseResetModalIsOpen(false);
          trackClick("schließen.geschäftsfall.zurücksetzen", trackingContext);
        }}
        isOpen={businessCaseResetModalIsOpen}
        titleText="Ändern der Basisdaten"
        mainText="Bei einer Änderung der Basisdaten werden abhängige Tarife, Zusatzkarten, Geräte und Extras aus der aktuellen Auswahl entfernt."
      />
      <BusinessCaseLandlineInfoModal
        onClose={() => {
          setLandlineInfoModalIsOpen(false);
          trackClick(
            "schließen.geschäftsfallinformationen.zum.festnetztarif",
            trackingContext
          );
        }}
        isOpen={landlineInfoModalIsOpen}
      />
    </>
  );
};

export default observer(BusinessCases);
