import React from "react";
import { CopyText, Heading } from "@dtpk-cc/components";
import { IConfigurationSummaryTotalPrice } from "../ISummary";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";
import * as styles from "./summary-configuration-total-price.module.scss";
import SummaryConfigurationTotalPriceDescr from "../SummaryConfigurationTotalPriceDescr";

type SummaryConfigurationTotalPriceProps = {
  configurationTotalPrice: IConfigurationSummaryTotalPrice;
};

const SummaryConfigurationTotalPrice = ({
  configurationTotalPrice,
}: SummaryConfigurationTotalPriceProps) => (
  <SummarySectionItemWrapper customClass={styles.wrapper} variant="default">
    <SummarySectionItemWrapper.Main variant="default">
      <Heading
        tag="p"
        variants={[Heading.Variant.quaternary, Heading.Variant.uppercase]}
      >
        {configurationTotalPrice.title}
      </Heading>
    </SummarySectionItemWrapper.Main>
    <SummarySectionItemWrapper.Monthly>
      <SummaryConfigurationPriceItem
        isBold
        price={configurationTotalPrice.monthlyPrice.mainPrice}
        suffix={configurationTotalPrice.priceType}
      />
      {configurationTotalPrice.monthlyPrice.priceStepsDescriptions.map(
        (priceStepsDescription) => (
          <CopyText
            key={priceStepsDescription}
            variants={CopyText.Variant.additional}
          >
            {priceStepsDescription}
          </CopyText>
        )
      )}
    </SummarySectionItemWrapper.Monthly>
    <SummarySectionItemWrapper.Once variant="withoutPadding">
      <SummaryConfigurationPriceItem
        isBold
        price={
          configurationTotalPrice?.oncePrice <= 0
            ? 0
            : configurationTotalPrice.oncePrice
        }
        suffix={configurationTotalPrice.priceType}
      />
      <SummaryConfigurationTotalPriceDescr
        totalPriceDescriptionItems={
          configurationTotalPrice.totalOncePriceDeductions
        }
      />
    </SummarySectionItemWrapper.Once>
  </SummarySectionItemWrapper>
);

export default SummaryConfigurationTotalPrice;
