// extracted by mini-css-extract-plugin
var _1 = "navigation-item-module-active-EggP3";
var _2 = "navigation-item-module-arrowIcon-UZA1c";
var _3 = "navigation-item-module-button-__Bwt";
var _4 = "navigation-item-module-icon-okcOb";
var _5 = "navigation-item-module-iconContainer-c2fa0";
var _6 = "navigation-item-module-navigationItem-D9aCb";
var _7 = "navigation-item-module-profileIconWrapper-C32Vc";
var _8 = "navigation-item-module-title-U4OP7";
export { _1 as "active", _2 as "arrowIcon", _3 as "button", _4 as "icon", _5 as "iconContainer", _6 as "navigationItem", _7 as "profileIconWrapper", _8 as "title" }
