import { Input, InputProps, List } from "@dtpk-cc/components";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./input-autocomplete.module.scss";

export interface InputAutocompleteItem {
  value: string;
}

interface InputAutocompleteProps {
  value: InputAutocompleteItem;
  items: InputAutocompleteItem[];
  onChange: (value: InputAutocompleteItem) => void;
  onItemSelect: (value: InputAutocompleteItem) => void;
  inputProps?: InputProps;
}

export const InputAutocomplete = ({
  value: { value },
  items,
  onChange,
  onItemSelect,
  inputProps = {},
}: InputAutocompleteProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onInputChange = (e) => {
    onChange({ value: e.target.value });
  };

  useEffect(() => {
    const handler = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={styles.inputAutocomplete}>
      <Input
        onFocus={() => setIsOpen(true)}
        value={value}
        onChange={onInputChange}
        {...inputProps}
      >
        Adresssuche*
      </Input>
      {isOpen && items.length > 0 && (
        <List>
          {items.map((item) => (
            <List.Item
              onClick={() => {
                onItemSelect(item);
                setIsOpen(false);
              }}
              key={item.value}
            >
              {item.value}
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};
