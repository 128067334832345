import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";
// Styles from LPG Should be imported in root component, due to webpacker styles-loader workflow
import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Asterisk/Asterisk.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/EqualHeight/EqualHeight.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import "@dtpk-cc/components/dist/components/Modal/Modal.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import "@dtpk-cc/components/dist/components/Tabs/Tabs.css";
import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Tile/Tile.css";
import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Indicator/Indicator.css";
import "@dtpk-cc/components/dist/components/Tooltip/Tooltip.css";
import "@dtpk-cc/components/dist/components/StepIndicator/StepIndicator.css";
import "@dtpk-cc/components/dist/components/HintBox/HintBox.css";
import "@dtpk-cc/components/dist/components/Checkbox/Checkbox.css";
import { ErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "../../../elements/LoadingSpinner";
import { INavigationMap } from "../../../components/new-design/Navigation/INavigation";
import {
  NAV_TITLE_BASE_DATA,
  NAV_TITLE_DEVICES,
  NAV_TITLE_HEIMNETZ,
  NAV_TITLE_LANDLINE_TARIFF,
  NAV_TITLE_MOBILE_TARIFF,
  NAV_TITLE_OFFER_FINALISATION,
  NAV_TITLE_SECONDARY_CARDS,
  NAV_TITLE_SUMMARY,
} from "../../../components/new-design/Navigation/const";
import HomeConfigurator from "../HomeConfigurator";
import { useNavigationMenuMap } from "../../../hooks/PencilSelling/Navigation/useNavigationMenuMap";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import ConfigurationFlyout from "../../../components/new-design/Navigation/ConfigurationFlyout";
import { useNavigationState } from "../../../hooks/PencilSelling/Navigation/useNavigationState";
import PencilSellingContainer from "../../../components/new-design/PencilSellingContainer";
import { IPencilSellingProps } from "../IPencilSelling";
import { useProfileData } from "../../../hooks/PencilSelling/useProfileData";
import { QUERY_PARAMS_MAP } from "./const";
import { useFetchData } from "../../../hooks/PencilSelling/PrivateClient/useFetchData";
import { useStores } from "../../../stores/PrivateClient";
import * as styles from "./private-client.module.scss";
import Personal from "./Personal";
import OfferFinalization from "./OfferFinalization";
import { useRemoveConfiguration } from "../../../hooks/PencilSelling/useRemoveConfiguration";
import SecondaryCardsConfigurationFlyout from "./Navigation/SecondaryCardsConfigurationFlyout";
import MobileTariff from "./MobileTariff";
import SecondaryCards from "./SecondaryCards";
import LandlineTariff from "./LandlineTariff";
import Devices from "../../../components/new-design/Devices";
import { useApplyMagentaCalculatorConfig } from "../../../hooks/PencilSelling/PrivateClient/useApplyMagentaCalculatorConfig";
import Summary from "../../../components/new-design/Summary";
import { useGetSummaryData } from "../../../hooks/PencilSelling/useGetSummaryData";
import { trackClick } from "../../../helpers/reactTracking";
import { TRACKING_CONTEXT } from "../../../components/new-design/Navigation";
import { useGetCustomAddonContractPeriod } from "../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { CUSTOM_DEVICE_FORM_DATA } from "../../../components/new-design/AddonsSelection/const";
import { FactModalKeys } from "../../../core/entities/PencilSelling/FactModal/IFactModal";

const logError = (error: Error, info: { componentStack: string }) => {
  console.warn(
    "Caught Error in React-Error-Boundary",
    error,
    error.message,
    info.componentStack
  );
};

// Adds reloading of useEffect, useCallback, useMemo on every injection https://github.com/gaearon/react-hot-loader
setConfig({
  reloadHooks: false,
});

const PencilSellingPrivate = ({
  distributionPoint,
  distributionLink,
  magentaAppLink,
  googleReviewLink,
  onlineAppointmentBookingLink,
  user,
}: IPencilSellingProps) => {
  const {
    offerStore,
    configurationsStore,
    productsRepositoryStore,
    productsStore,
    factModalStore,
  } = useStores();
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );
  const { customerType } = offerStore.getBaseOffer().customer.get();
  const { applyMagentaCalculatorConfig } = useApplyMagentaCalculatorConfig();
  const { isLoading, setIsLoading } = offerStore.getBaseOffer();
  const {
    navigationMenuMap,
    activeStepIndex,
    addConfigurationHandler,
    navigateToStepWithFlyout,
    navigateToStepWithoutFlyout,
    getDefaultConfigurationSettings,
    defineActiveConfiguration,
  } = useNavigationMenuMap(configurationsStore, offerStore.getBaseOffer());
  const profileData = useProfileData({
    distributionPoint,
    distributionLink,
    magentaAppLink,
    googleReviewLink,
    onlineAppointmentBookingLink,
    offerStore: offerStore.getBaseOffer(),
  });
  const { getSummaryData } = useGetSummaryData({
    offerStore,
    customerType,
    distributionData: profileData.distributionData,
    bannerLinks: profileData.bannerLinks,
  });
  const { fetchData } = useFetchData();
  const navigationState = useNavigationState(NAV_TITLE_BASE_DATA);
  const { removeConfigurationHandler } = useRemoveConfiguration(
    configurationsStore,
    offerStore.getBaseOffer()
  );
  const [homeConfigurationIsActive, setHomeConfigurationIsActive] =
    useState(false);

  const addMobileConfigurationHandler = useCallback(() => {
    configurationsStore.addConfiguration(
      getDefaultConfigurationSettings(ICartConfigurationType.CARD)
    );
    const secondaryCardsConfigurationKey =
      configurationsStore.getActiveConfiguration().key;
    configurationsStore.addConfiguration(
      getDefaultConfigurationSettings(ICartConfigurationType.MOBILE)
    );
    const mobileConfigurationKey =
      configurationsStore.getActiveConfiguration().key;
    // Links Mobile and Secondary Cards configurations between each other
    configurationsStore.linkConfigurations(
      mobileConfigurationKey,
      secondaryCardsConfigurationKey
    );
  }, [configurationsStore, getDefaultConfigurationSettings]);

  const removeMobileConfigurationHandler = ({
    configurationKeysToDelete,
  }: {
    configurationKeysToDelete: string[];
  }) => {
    // Before remove Mobile configurations -> remove all linked secondary cards configurations
    const mobileConfigurationsLinkedConfigurations =
      configurationKeysToDelete.map(
        (key) =>
          configurationsStore.getConfigurationByKey(key).linkedConfigurationKey
      );
    // Remove linked secondary cards configurations
    configurationsStore.removeConfiguration(
      mobileConfigurationsLinkedConfigurations
    );
    // Remove linked mobile configurations
    configurationsStore.removeConfiguration(configurationKeysToDelete);

    const mobileConfigurations = configurationsStore.getConfigurationsByType(
      ICartConfigurationType.MOBILE
    );

    if (mobileConfigurations.length) {
      configurationsStore.setActiveConfiguration(mobileConfigurations[0]);
    } else {
      addMobileConfigurationHandler();
    }
  };

  navigationMenuMap.menu.items = [
    {
      title: NAV_TITLE_BASE_DATA,
      onClick: (navData) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.BASE_DATA);
        navigateToStepWithoutFlyout({ ...navData, stepIndex: 0 });
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_MOBILE_TARIFF,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.MOBILE_TARIFF);
        navigateToStepWithFlyout({
          isActive,
          key,
          setActiveItem,
          setActiveFlyoutKey,
          setBackDropIsActive,
          activeFlyoutKey,
          stepIndex: 1,
          displayFlyout:
            configurationsStore.getConfigurationsByType(
              ICartConfigurationType.MOBILE
            ).length > 1,
        });
        defineActiveConfiguration(ICartConfigurationType.MOBILE);
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <ConfigurationFlyout
            addConfigurationHandler={addMobileConfigurationHandler}
            removeConfigurationHandler={removeMobileConfigurationHandler}
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
            type={ICartConfigurationType.MOBILE}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_SECONDARY_CARDS,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.SECONDARY_CARDS);
        navigateToStepWithFlyout({
          isActive,
          key,
          setActiveItem,
          setActiveFlyoutKey,
          setBackDropIsActive,
          activeFlyoutKey,
          stepIndex: 2,
          displayFlyout:
            configurationsStore.getConfigurationsByType(
              ICartConfigurationType.CARD
            ).length > 1,
        });
        defineActiveConfiguration(ICartConfigurationType.CARD);
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <SecondaryCardsConfigurationFlyout
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_LANDLINE_TARIFF,
      onClick: ({
        isActive,
        key,
        setActiveItem,
        setActiveFlyoutKey,
        setBackDropIsActive,
        activeFlyoutKey,
      }) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.LANDLINE_TARIFF);
        navigateToStepWithFlyout({
          isActive,
          key,
          setActiveItem,
          setActiveFlyoutKey,
          setBackDropIsActive,
          activeFlyoutKey,
          stepIndex: 3,
          displayFlyout:
            configurationsStore.getConfigurationsByType(
              ICartConfigurationType.LANDLINE
            ).length > 1,
        });
        addConfigurationHandler(ICartConfigurationType.LANDLINE);
      },
      getChildren: ({
        key,
        activeNavItem,
        setBackDropIsActive,
        setActiveFlyoutKey,
      }) =>
        key === activeNavItem ? (
          <ConfigurationFlyout
            addConfigurationHandler={() =>
              configurationsStore.addConfiguration(
                getDefaultConfigurationSettings(ICartConfigurationType.LANDLINE)
              )
            }
            removeConfigurationHandler={removeConfigurationHandler}
            configurationsStore={configurationsStore}
            hideFlyoutHandler={() => {
              setBackDropIsActive(false);
              setActiveFlyoutKey(null);
            }}
            type={ICartConfigurationType.LANDLINE}
          />
        ) : null,
      withArrow: true,
    },
    {
      title: NAV_TITLE_DEVICES,
      onClick: (navData) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.DEVICES);
        navigateToStepWithoutFlyout({ ...navData, stepIndex: 4 });
        addConfigurationHandler(ICartConfigurationType.DEVICE);
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_OFFER_FINALISATION,
      onClick: (navData) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.OFFER_FINALISATION);
        navigateToStepWithoutFlyout({ ...navData, stepIndex: 5 });
      },
      getChildren: () => null,
      withArrow: false,
    },
    {
      title: NAV_TITLE_SUMMARY,
      onClick: (navData) => {
        factModalStore.setFactModalActiveKey(FactModalKeys.SUMMARY);
        navigateToStepWithoutFlyout({ ...navData, stepIndex: 6 });
      },
      getChildren: () => null,
      withArrow: false,
    },
  ];

  const toggleHomeConfiguration = () => {
    setHomeConfigurationIsActive((prev) => !prev);
  };

  const extendNavigationMap = (navMap: INavigationMap) => {
    navMap.tools.items.splice(1, 0, {
      title: NAV_TITLE_HEIMNETZ,
      onClick: () => {
        toggleHomeConfiguration();
        trackClick(NAV_TITLE_HEIMNETZ, TRACKING_CONTEXT);
      },
      getChildren: () => null,
      withArrow: true,
    });
  };

  const appResetHandler = () => {
    window.location.href = `${window.location.origin}/angebote-erstellen/angebotstool/geschaeftskunden`;
  };

  const offerStepsMap = [
    <Personal addMobileConfigurationHandler={addMobileConfigurationHandler} />,
    <MobileTariff
      displayConfigurationFlyout={() =>
        navigationState.displayConfigurationFlyout(NAV_TITLE_MOBILE_TARIFF)
      }
      removeConfigurationHandler={removeMobileConfigurationHandler}
    />,
    <SecondaryCards />,
    <LandlineTariff
      displayConfigurationFlyout={() =>
        navigationState.displayConfigurationFlyout(NAV_TITLE_LANDLINE_TARIFF)
      }
    />,
    <Devices
      allowCustomProducts
      subtitle="Das Angebot basiert auf den folgenden Angaben"
      trackingContext="geräte.festnetz.privatkunden"
      configurationsStore={configurationsStore}
      offerStore={offerStore.getBaseOffer()}
      productsStore={productsStore}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_DEVICE_FORM_DATA}
      productsRepositoryStore={productsRepositoryStore}
    />,
    <OfferFinalization distributionData={profileData.distributionData} />,
    <Summary
      getSummaryData={getSummaryData}
      offerStore={offerStore.getBaseOffer()}
      productsRepositoryStore={productsRepositoryStore}
    />,
  ];

  const displayHNPbySearchParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    if (
      params.get(QUERY_PARAMS_MAP.hnpOpened.key) ===
      QUERY_PARAMS_MAP.hnpOpened.value
    ) {
      toggleHomeConfiguration();
    }
  }, []);

  useEffect(() => {
    const fetchDataHandler = async () => {
      setIsLoading(true);
      try {
        await fetchData();
        displayHNPbySearchParams();
        addMobileConfigurationHandler();
        applyMagentaCalculatorConfig();
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        console.log(e?.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDataHandler().catch(() => {
      //  Do nothing...
    });
  }, [
    fetchData,
    offerStore,
    displayHNPbySearchParams,
    setIsLoading,
    addMobileConfigurationHandler,
    applyMagentaCalculatorConfig,
  ]);

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <div>An error occurred: {error.message}</div>
      )}
      onError={logError}
    >
      <LoadingSpinner isLoading={isLoading}>
        <div
          className={`${styles.hnpWrapper} ${
            homeConfigurationIsActive ? styles.active : ""
          }`}
        >
          <HomeConfigurator
            key={`${homeConfigurationIsActive}`}
            closeHandler={toggleHomeConfiguration}
          />
        </div>
        <PencilSellingContainer
          mainContainerAriaIsHidden={homeConfigurationIsActive}
          navigationMenuMap={navigationMenuMap}
          navigationState={navigationState}
          appResetHandler={appResetHandler}
          userName={`${user.firstname} ${user.lastname}`}
          adjustNavigationMap={extendNavigationMap}
          configurationsStore={configurationsStore}
          productsRepositoryStore={productsRepositoryStore}
          offerStore={offerStore.getBaseOffer()}
          factModalStore={factModalStore}
        >
          {offerStepsMap[activeStepIndex]}
        </PencilSellingContainer>
      </LoadingSpinner>
    </ErrorBoundary>
  );
};

export default hot(observer(PencilSellingPrivate));
