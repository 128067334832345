import React, { useState, useCallback } from "react";
import { trackClick } from "helpers/reactTracking";
import {
  CopyText,
  Flex,
  Heading,
  Modal,
  Grid,
  Button,
  Container,
} from "@dtpk-cc/components";
import TextArea from "elements/TextArea";
import { useStores } from "stores";
import { observer } from "mobx-react";

import { PortfolioKeys } from "core/entities/Product/IProduct";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Checkbox/Checkbox.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "./personal.scss";
import "@dtpk-cc/components/dist/global.css";
import "@dtpk-cc/components/dist/components/ButtonGroup/ButtonGroup.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import LandlineBusinessCases from "./LandlineBusinessCases";
import MobileBusinessCases from "./MobileBusinessCases";
import { PersistedWarning } from "./IPersonal";

const PersonalOffer = observer(() => {
  const trackingContext = "basisdaten";

  const { offerStore, cartStore } = useStores();

  const customer = offerStore.customer.get();
  const {
    [PortfolioKeys.MOBILE]: mobileTariff,
    [PortfolioKeys.LANDLINE]: landlineTariff,
  } = cartStore.getTariffsInCart();

  const cart = {
    mobileTariff,
    landlineTariff,
    cards: cartStore.getCards(),
    options: cartStore.getMobileAndLandlineOptions(),
    devices: cartStore.getDevices(),
  };

  const [mobileCartItems, landlineCartItems] = [
    {
      tariffName: "mobileTariff",
      optionName: PortfolioKeys.MOBILE,
      cartPropName: "cards",
    },
    {
      tariffName: "landlineTariff",
      optionName: PortfolioKeys.LANDLINE,
      cartPropName: "devices",
    },
  ].map(({ tariffName, optionName, cartPropName }) => [
    ...(cart[tariffName] ? [cart[tariffName]] : []),
    ...cart.options[optionName],
    ...cart[cartPropName],
  ]);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [persistedWarningValue, setPersistedWarningValue] = useState<
    Partial<PersistedWarning>
  >({});

  const handleCustomerChange = (value, key) => {
    offerStore.customer.set({
      ...customer,
      [key]: value,
    });
  };

  const handleSettingsChange = useCallback(
    ({ value, isLandline, callback }: PersistedWarning) => {
      if (
        (isLandline && landlineCartItems.length) ||
        (!isLandline && mobileCartItems.length)
      ) {
        setPersistedWarningValue({ value, isLandline, callback });
        setIsWarningModalOpen(true);
      } else {
        callback(value);
      }
    },
    [landlineCartItems.length, mobileCartItems.length]
  );

  /*
    Important:
    The key property for the RadioButton.Group Component
    is needed to keep it updated (controlled) after state
    changes like the warningModal
  */

  return (
    <Container variants={Container.Variant.padded}>
      <Grid padded={true}>
        <Grid.Row gutter style={{ margin: "0.75rem 0" }}>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <Heading
              tag="h1"
              variants={[Heading.Variant.secondary]}
              data-qa="quality"
            >
              Angebotstool - Basisdaten
            </Heading>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row gutter style={{ justifyContent: "center" }}>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <div className="personal-offer__user-note personal-offer__section--with-bg personal-offer__section--with-radius">
              <Heading
                customClass="personal-offer__user-note__title"
                tag="h3"
                variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
                data-qa="quality"
              >
                Anmerkungen
              </Heading>

              <TextArea
                // customClass="personal-offer__text-area"
                data-role="textbox"
                text={customer.notice}
                handleChange={(event) =>
                  handleCustomerChange(event.target.value, "notice")
                }
                onClick={() => trackClick("anmerkungen", trackingContext)}
                label="Kundenwünsche"
              />
            </div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row style={{ margin: "0.75rem 0" }} gutter>
          <Grid.Col
            customClass="personal-offer__settings-wrapper"
            s={3}
            m={12}
            l={7}
            xl={12}
          >
            <LandlineBusinessCases
              handleSettingsChange={handleSettingsChange}
              trackingContext={trackingContext}
              isWarningModalOpen={isWarningModalOpen}
              setIsWarningModalOpen={setIsWarningModalOpen}
            />
          </Grid.Col>
          <Grid.Col
            customClass="personal-offer__settings-wrapper"
            s={3}
            m={12}
            l={5}
            xl={12}
          >
            <MobileBusinessCases
              handleSettingsChange={handleSettingsChange}
              trackingContext={trackingContext}
              setIsWarningModalOpen={setIsWarningModalOpen}
            />
          </Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={isWarningModalOpen}
          afterClose={() => setIsWarningModalOpen(false)}
        >
          <Modal.Section>
            <CopyText customClass="pencil-selling__reset-copytext">
              Bei einer Änderung werden von dieser Einstellung abhängige Tarife,
              Geräte und Extras aus der aktuellen Auswahl entfernt.
            </CopyText>
            <CopyText customClass="pencil-selling__reset-copytext">
              Willst du wirklich fortfahren?
            </CopyText>
            <Flex justifyContent="center">
              <Button
                customClass="pencil-selling__reset-button"
                onClick={() => {
                  persistedWarningValue.callback(
                    persistedWarningValue.value,
                    persistedWarningValue.isLandline
                  );
                  trackClick("reset.data-ja", trackingContext);
                }}
              >
                Bestätigen
              </Button>
              <Button
                customClass="pencil-selling__reset-button"
                onClick={() => {
                  setIsWarningModalOpen(false);
                  trackClick("reset.data-nein", trackingContext);
                }}
                variants={Button.Variant.outline}
              >
                Abbrechen
              </Button>
            </Flex>
          </Modal.Section>
        </Modal>
      </Grid>
    </Container>
  );
});

export default PersonalOffer;
