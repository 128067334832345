import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import type { ISubmitPdfQrCodeUseCase } from "./ISubmitPdfQrCode";
import { IOfferSummary } from "../../../presenter/PencilSelling/OfferSummaryPresenter";
import { IOfferConfigurationsSummary } from "../../../../components/new-design/Summary/ISummary";

export class SubmitPdfQrCodeUseCase implements ISubmitPdfQrCodeUseCase {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(
    data: IOfferSummary | IOfferConfigurationsSummary
  ): Promise<Response> {
    return this.repository.submitPdfQrCode(data);
  }
}
