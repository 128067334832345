import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { IRemoveMultisimFromCartUseCase } from "./IRemoveMultisimFromCart";

// eslint-disable-next-line prettier/prettier
export class RemoveMultisimFromCartUseCase implements IRemoveMultisimFromCartUseCase {
  constructor(private cart: ICart) {}

  execute(size: string | null): void {
    this.cart.deleteMultiSimBySize(size);
  }
}
