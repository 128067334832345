import { Group } from "core/entities/Product/IProduct";
import type { IStreamingService } from "core/entities/Product/IStreamingService";
import type { IStreamingVariant } from "core/entities/Product/IStreamingVariant";
import {
  Bandwidths,
  ITariff,
  TariffLevelsKeys,
} from "core/entities/Product/Tariff/ITariff";
import faker from "faker";
import { IStreamingServicesKeys } from "core/entities/Product/IStreamingServiceKeys";
import { ICard } from "../entities/Product/Card/ICard";

// workaround to get only keys of enum, see:
// https://www.crojach.com/blog/2019/2/6/getting-enum-keys-in-typescript
export const bandwidths = Object.keys(Bandwidths).filter(
  (x) => !(parseInt(x, 10) >= 0)
);
export const generations = ["1. Gen", "2. Gen", "3. Gen", "4. Gen", "5. Gen"]; // check when data arrive

export const mockCard = (
  cardWorldKey = faker.random.word(),
  quantity = 0,
  isLegacy = false
): ICard => ({
  quantity,
  tariffWorld: {
    key: cardWorldKey,
    name: faker.random.word(),
    limit: null,
    valency: faker.datatype.number(),
  },
  isLegacy,
  dataVolume: `${faker.datatype.number({ min: 10, max: 400 })} GB`,
  dataVolumeMagentaOne: `${faker.datatype.number({ min: 10, max: 400 })} GB`,
  name: faker.random.word(),
  nameAlternative: faker.random.word(),
  type: Group.card,
  availableFor: [],
  level: {
    key: faker.random.word(),
    name: faker.random.word(),
    valency: faker.datatype.number(),
  },
  price: {
    monthly: faker.datatype.number({ min: 0, max: 100 }),
    once: 0,
    originalPrice: null,
    suffix: null,
    gen3Discount: null,
    monthlyPriceBeforeFrameworkDiscount: null,
    provision: null,
    // pricesTypeIsGross: {
    //   once: false,
    //   monthly: false,
    //   provision: false,
    //   monthlyPriceBeforeFrameworkDiscount: false,
    //   gen3Discount: false,
    // },
  },
  description: {
    summary: null,
    text: null,
  },
  generation: isLegacy ? faker.random.arrayElement(generations) : undefined,
  key: faker.datatype.uuid(),
  size: faker.random.arrayElement(bandwidths) as keyof typeof Bandwidths,
  contractPeriod: 2,
  inheritingCard: true,
  hasMultisim: true,
});

export const mockTariff = (
  tariffWorldKey = faker.random.word(),
  quantity = 0,
  isLegacy = false,
  levelKey = TariffLevelsKeys.PHONE_NONE
): ITariff => ({
  quantity,
  tariffWorld: {
    key: tariffWorldKey,
    name: faker.random.word(),
    valency: faker.datatype.number(),
    limit: null,
  },
  streamingServices: [],
  expansionArea: [],
  isFiber: false,
  isYoung: false,
  isLegacy,
  dataVolume: `${faker.datatype.number({ min: 10, max: 400 })} GB`,
  name: faker.random.word(),
  nameAlternative: faker.random.word(),
  type: Group.tariff,
  level: {
    key: levelKey,
    name: faker.random.word(),
    valency: faker.datatype.number(),
  },
  price: {
    monthly: faker.datatype.number({ min: 0, max: 100 }),
    once: 0,
    originalPrice: null,
    suffix: null,
    gen3Discount: null,
    monthlyPriceBeforeFrameworkDiscount: null,
    provision: null,
  },
  description: {
    summary: null,
    text: null,
  },
  benefits: null,
  generation: isLegacy ? faker.random.arrayElement(generations) : undefined,
  key: faker.datatype.uuid(),
  size: faker.random.arrayElement(bandwidths) as keyof Bandwidths,
  displaySize: null,
  contractPeriod: 2,
  speed: null,
  speedFiber: null,
  extensions: [],
  dataVolumeExtra: "",
  relatedAddons: [],
});

export const mockStreamingService = (): IStreamingService => ({
  name: faker.random.word(),
  key: faker.random.arrayElement(Object.values(IStreamingServicesKeys)),
  active: false,
  price: {
    monthly: faker.datatype.number({ min: 0, max: 100 }),
  },
});

export const mockVariantServices = () => ({
  live_tv: faker.datatype.number({ min: 0, max: 20 }),
  ard_plus: faker.datatype.number({ min: 0, max: 20 }),
  zdf_select: faker.datatype.number({ min: 0, max: 20 }),
  dazn: faker.datatype.number({ min: 0, max: 20 }),
  disney_plus: faker.datatype.number({ min: 0, max: 20 }),
  magenta_sport: faker.datatype.number({ min: 0, max: 20 }),
  netflix: faker.datatype.number({ min: 0, max: 20 }),
  rtl_plus: faker.datatype.number({ min: 0, max: 20 }),
});

export const mockStreamingVariant = (): IStreamingVariant => ({
  name: faker.random.word(),
  key: faker.datatype.uuid(),
  kind: faker.random.word(),
  active: false,
  monthly: faker.datatype.number({ min: 0, max: 100 }),
  tariffWorld: {
    name: faker.random.word(),
    key: faker.datatype.uuid(),
    limit: null,
    valency: null,
  },
  level: {
    name: faker.random.word(),
    key: faker.datatype.uuid(),
    valency: null,
  },
  areaOfUse: faker.random.word(),
  streamingServices: mockVariantServices(),
});
