import { LatLng, LatLngBounds } from "leaflet";
import type { FeatureCollection } from "geojson";
import {
  RouteTransportation,
} from "../hooks/types";
import {
  AddressDetails,
  AddressSuggestions,
  BestTower,
  NetworkProvidersQualitySummary,
  PrevalentKind,
  RouteDetails,
  Tower,
} from "./types";

const basePath = "/api/tools/network_coverage";

const csrfToken: HTMLMetaElement = document.querySelector(
  "meta[name=\"csrf-token\"]"
);

const getResponseJson = async <T>(
  url: string,
  init?: RequestInit
): Promise<T> => {
  const res = await fetch(url, init);
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json() as Promise<T>;
};

const outsideNetworkCoverageToString = (outdoor: boolean) =>
  outdoor ? "outdoor" : "indoor";

const getNetworkProvidersQualityForArea = async (
  bounds: LatLngBounds,
  outsideNetworkCoverage: boolean
): Promise<NetworkProvidersQualitySummary> => {
  const urlParams = new URLSearchParams({
    top_left_x: String(bounds.getNorthWest().lng),
    top_left_y: String(bounds.getNorthWest().lat),
    bottom_right_x: String(bounds.getSouthEast().lng),
    bottom_right_y: String(bounds.getSouthEast().lat),
    environment: outsideNetworkCoverageToString(outsideNetworkCoverage),
  }).toString();

  const url = `${basePath}/network_quality/for_area?${urlParams}`;

  try {
    return await getResponseJson<NetworkProvidersQualitySummary>(url);
  } catch {
    return null;
  }
};

const getNetworkProvidersQualityForPoint = async (
  point: LatLng,
  outsideNetworkCoverage: boolean
): Promise<NetworkProvidersQualitySummary> => {
  const urlParams = new URLSearchParams({
    x: String(point.lng),
    y: String(point.lat),
    environment: outsideNetworkCoverageToString(outsideNetworkCoverage),
  }).toString();

  const url = `${basePath}/network_quality/for_point?${urlParams}`;

  try {
    return await getResponseJson<NetworkProvidersQualitySummary>(url);
  } catch {
    return null;
  }
};

const getNetworkProvidersQualityForGeoJSON = async (
  geoJSON: FeatureCollection,
  outsideNetworkCoverage: boolean
): Promise<NetworkProvidersQualitySummary> => {
  const url = `${basePath}/network_quality/for_geojson`;

  if (!geoJSON.features?.length) return null;

  const geojson = geoJSON.features[0]?.geometry;

  try {
    return await getResponseJson<NetworkProvidersQualitySummary>(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      body: JSON.stringify({
        geojson,
        environment: outsideNetworkCoverageToString(outsideNetworkCoverage),
      }),
    });
  } catch {
    return null;
  }
};

const getAddressSuggestions = async (
  input: string
): Promise<AddressDetails[]> => {
  const urlParams = new URLSearchParams({
    query: input,
  }).toString();

  const url = `${basePath}/geocoding/autocomplete?${urlParams}`;

  try {
    const json = await getResponseJson<AddressSuggestions>(url);
    return json.addresses.map((el) => ({
      address: el.address,
      point: new LatLng(el.y, el.x),
    }));
  } catch {
    return null;
  }
};

const getAddress = async (point: LatLng): Promise<AddressDetails> => {
  const urlParams = new URLSearchParams({
    x: String(point.lng),
    y: String(point.lat),
  }).toString();

  const url = `${basePath}/geocoding/reverse?${urlParams}`;

  try {
    return await getResponseJson<AddressDetails>(url);
  } catch {
    return null;
  }
};

const getRoute = async (
  start: LatLng,
  end: LatLng,
  transport: RouteTransportation
): Promise<RouteDetails> => {
  const urlParams = new URLSearchParams({
    from_x: String(start.lng),
    from_y: String(start.lat),
    to_x: String(end.lng),
    to_y: String(end.lat),
    mode: transport,
  }).toString();

  const url = `${basePath}/geocoding/route?${urlParams}`;

  try {
    return await getResponseJson<RouteDetails>(url);
  } catch {
    return null;
  }
};

const getBestKindInCircle = async (
  point: LatLng,
  radius: number
): Promise<Tower> => {
  const urlParams = new URLSearchParams({
    x: String(point.lng),
    y: String(point.lat),
    radius: String(radius),
  }).toString();

  const url = `${basePath}/towers/best_kind_in_circle?${urlParams}`;

  try {
    const json = await getResponseJson<BestTower>(url);
    return json.best_tower;
  } catch {
    return null;
  }
};

const getPrevalentKindInGeoJson = async (
  geoJSON: FeatureCollection,
  buffer: number
): Promise<Tower> => {
  const url = `${basePath}/towers/prevalent_kind_in_geojson`;

  if (!geoJSON.features?.length) return null;

  const geojson = geoJSON.features[0]?.geometry;

  try {
    const json = await getResponseJson<PrevalentKind>(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      body: JSON.stringify({ geojson, buffer }),
    });
    return json.prevalent_kind;
  } catch {
    return null;
  }
};

const getLocationsForArea = async (
  bounds: LatLngBounds
): Promise<FeatureCollection> => {
  const urlParams = new URLSearchParams({
    top_left_x: String(bounds.getNorthWest().lng),
    top_left_y: String(bounds.getNorthWest().lat),
    bottom_right_x: String(bounds.getSouthEast().lng),
    bottom_right_y: String(bounds.getSouthEast().lat),
  }).toString();

  const url = `${basePath}/locations/for_area?${urlParams}`;

  try {
    return await getResponseJson<FeatureCollection>(url);
  } catch {
    return null;
  }
};

export const mapApi = {
  getNetworkProvidersQualityForArea,
  getNetworkProvidersQualityForPoint,
  getNetworkProvidersQualityForGeoJSON,
  getAddressSuggestions,
  getAddress,
  getRoute,
  getBestKindInCircle,
  getPrevalentKindInGeoJson,
  getLocationsForArea,
};
