import { ICart } from "core/entities/PencilSelling/Cart/ICart";
import { IUpdateDynamicCardDescriptionsUseCase } from "./IUpdateDynamicCardDescriptions";

export class UpdateDynamicCardDescriptionsUseCase
  // eslint-disable-next-line prettier/prettier
  implements IUpdateDynamicCardDescriptionsUseCase {
  constructor(private cart: ICart) {}

  execute(
    cardKey: string,
    descriptionSummary: string,
    descriptionText: string
  ): void {
    this.cart.updateDynamicCardDescriptions(
      cardKey,
      descriptionSummary,
      descriptionText
    );
  }
}
