import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { trackClick } from "../../../../helpers/reactTracking";
import { TRACKING_CONTEXT } from "./const";
import { ConfigurationsEntity } from "../../../../core/entities/PencilSelling/Configurations/Configurations";

export const setActiveConfigurationHandler = (
  hideFlyoutHandler: () => void,
  configuration: ICartConfiguration,
  configurationsStore: ConfigurationsEntity,
  trackingContext: string,
  type: ICartConfigurationType
) => {
  configurationsStore.setActiveConfiguration(configuration);
  trackClick(`wählen.${type}`, TRACKING_CONTEXT);
  hideFlyoutHandler();
};
