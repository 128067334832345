import { IServicesEntity } from "core/entities/PencilSelling/Services/IServicesEntity";
import { IStreamingService } from "core/entities/Product/IStreamingService";
import { IGetStreamingServices } from "./IGetStreamingServices";

export class GetStreamingServicesUseCase implements IGetStreamingServices {
  constructor(private readonly entity: IServicesEntity) {}

  execute(): IStreamingService[] {
    return this.entity.getStreamingServices();
  }
}
