// extracted by mini-css-extract-plugin
var _1 = "landline-tariff-filter-module-disabled-Vtl8Y";
var _2 = "landline-tariff-filter-module-fiberIcon-duBoD";
var _3 = "landline-tariff-filter-module-inactive-SSsWs";
var _4 = "landline-tariff-filter-module-radioBtnWrapper-dlR3V";
var _5 = "landline-tariff-filter-module-radioLabel-9QyNq";
var _6 = "landline-tariff-filter-module-textual-DXfXz";
var _7 = "landline-tariff-filter-module-tileBtn-J0TAn";
var _8 = "landline-tariff-filter-module-toggleMainBtn-O2xdt";
export { _1 as "disabled", _2 as "fiberIcon", _3 as "inactive", _4 as "radioBtnWrapper", _5 as "radioLabel", _6 as "textual", _7 as "tileBtn", _8 as "toggleMainBtn" }
