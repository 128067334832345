import {
  IBusinessCasesTypeValues,
  ITariff,
} from "../core/entities/Product/Tariff/ITariff";
import { ICard } from "../core/entities/Product/Card/ICard";
import { IPromotion } from "../core/entities/PencilSelling/IPromotion";

export const getPromotionsFilteredByBusinessCase = (
  activeProductItem: ITariff | ICard,
  businessCase: IBusinessCasesTypeValues
): IPromotion[] =>
  activeProductItem?.promotions?.filter((item) =>
    item.conditions.includes(businessCase as string)
  ) || [];
