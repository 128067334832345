import React from "react";
import { Heading } from "@dtpk-cc/components";

const PromotionListTitle = ({ customClass }) => (
  <Heading
    customClass={`promotion-action-block__title ${customClass}`}
    tag="h3"
    variants={[Heading.Variant.quaternary, Heading.Variant.display]}
  >
    Eigene Aktion hinzufügen
  </Heading>
);

export default PromotionListTitle;
