// extracted by mini-css-extract-plugin
var _1 = "promotion-selection-item-module-checkBoxWrapper-sHNRv";
var _2 = "promotion-selection-item-module-currentPriceWrapper-J8hqe";
var _3 = "promotion-selection-item-module-customPromotionBadge-DazAk";
var _4 = "promotion-selection-item-module-customPromotionBadgeText-dLGuv";
var _5 = "promotion-selection-item-module-editBtn-oDWXi";
var _6 = "promotion-selection-item-module-editIcon-Rn6q5";
var _7 = "promotion-selection-item-module-monthlyPrice-L0i39";
var _8 = "promotion-selection-item-module-oncePrice-UnBIe";
var _9 = "promotion-selection-item-module-percentageValue-QYQ00";
var _a = "promotion-selection-item-module-row-FFqo3";
var _b = "promotion-selection-item-module-wrapper-sz3LJ";
export { _1 as "checkBoxWrapper", _2 as "currentPriceWrapper", _3 as "customPromotionBadge", _4 as "customPromotionBadgeText", _5 as "editBtn", _6 as "editIcon", _7 as "monthlyPrice", _8 as "oncePrice", _9 as "percentageValue", _a as "row", _b as "wrapper" }
