import React from "react";
import * as styles from "./address-check-header.module.scss";

type AddressCheckHeaderProps = {
  customClass?: string;
};

const AddressCheckHeader: React.FC<AddressCheckHeaderProps> = ({
  customClass = "",
  children,
}) => (
  <div className={`${styles.infoContainer} ${customClass}`}>{children}</div>
);

export default AddressCheckHeader;
