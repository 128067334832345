import type { ITariff as ITariffProduct } from "core/entities/Product/Tariff/ITariff";
import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { IGetMagentaMobileCards } from "./IGetMagentaMobileCards";

export class GetMagentaMobileCards implements IGetMagentaMobileCards {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(isLegacy?: boolean): ITariffProduct[] {
    return isLegacy
      ? this.legacyEntity.getMagentaMobileTariffs()
      : this.entity.getMagentaMobileTariffs();
  }
}
