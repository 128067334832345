import { useCallback } from "react";
import { ProductsMapper } from "../../../core/mapper/ProductsMapper";
import { useStores } from "../../../stores";
import { Endpoints } from "../../../core/repositories/ProductsRepository/IProductsRepository";

export const useFetchProducts = () => {
  const { productsRepositoryStore, offerStore, productsStore, servicesStore } =
    useStores();

  const fetchProducts = useCallback(async () => {
    try {
      const products = await productsRepositoryStore.getProducts(
        Endpoints.pencil_selling
      );
      const customPromotionsResponse =
        await productsRepositoryStore.fetchCustomPromotion();
      const streamingAnalysis =
        await productsRepositoryStore.getSreamingAnalysis();
      const mapper = new ProductsMapper({
        productResponse: products,
        streamingAnalysisResponse: streamingAnalysis,
        customPromotionsResponse,
      });
      const summary = mapper.productsSummary;
      const { customPromotions } = mapper;
      const offerPromotionSections = offerStore.getCustomPromotions();
      offerStore.setCustomPromotions({
        ...offerPromotionSections,
        ...customPromotions,
      });
      productsStore.setup(summary);
      servicesStore.setup(
        summary?.streamingServices,
        summary?.streamingVariants
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [productsRepositoryStore, offerStore, productsStore, servicesStore]);

  return { fetchProducts };
};
