import React from "react";
import {
  Button,
  Badge,
  Tile,
  Container,
  Heading,
  Price,
  CopyText,
  TextLink,
  Icon,
  List,
} from "@dtpk-cc/components";

import {
  ActionCloseDefault,
  ActionSuccessSelected,
} from "@dtpk-cc/components/dist/icons";

import "elements/DetailTileItem/detail-tile-item.scss";
import IncrementalButtonGroup from "../IncrementalButtonGroup";
import { ICartItemData } from "../../core/entities/PencilSelling/CartItem/ICartItem";

type DetailTileItemProps = {
  description: string | null;
  item: ICartItemData;
  additionalPriceSuffix?: string;
  hasConditions: boolean;
  isFungible: boolean;
  hasPromotions: boolean;
  onRemove: () => void;
  onConditionChange: () => void;
  onQuantityChange: (quantity: number) => void;
  onPromotionChange: () => void;
};

const DetailTileItem = ({
  item,
  additionalPriceSuffix = "",
  onRemove,
  hasConditions,
  onConditionChange,
  onQuantityChange,
  onPromotionChange,
  description,
  isFungible = false,
  hasPromotions = false,
}: DetailTileItemProps) => {
  const { name, price, paymentType, contractPeriod } = item;
  const selectedPrice = price[paymentType];
  const priceVariant = paymentType === "once" ? "einmalig" : "mtl.";
  const priceSuffix = `${priceVariant} ${additionalPriceSuffix || ""}`;

  const handleChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    callback: () => void
  ) => {
    event.stopPropagation();
    event.preventDefault();
    callback();
  };

  return (
    <Tile customClass={"detail-tile-item"} variant="shadow">
      <Badge variants={Badge.Variant.flag}>
        <Badge.Paragraph
          style={{ color: "#fff" }}
          fonts={Badge.Paragraph.Font.additionalHighlight}
        >
          Ausgewählt
        </Badge.Paragraph>
      </Badge>
      <Tile.Content customClass="detail-tile-item__content">
        <Heading
          variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          customClass="detail-tile-item__heading"
        >
          {name}
        </Heading>
        {description && (
          <CopyText customClass="detail-tile-item__copytext">
            {description}
          </CopyText>
        )}
        <Container customClass="detail-tile-item__content-container">
          <div className="detail-tile-item__price">
            <Price
              variants={[Price.Variant.offering]}
              suffix={priceSuffix}
              value={selectedPrice}
            />

            {item.paymentType === "monthly" &&
              typeof contractPeriod === "number" && (
                <Price.Text>
                  {contractPeriod === 0
                    ? "Keine MVLZ"
                    : `MVLZ ${contractPeriod}`}
                </Price.Text>
              )}
          </div>
          {isFungible && (
            <IncrementalButtonGroup
              customClass="detail-tile-item__quantity"
              handleMinusClick={onQuantityChange}
              handlePlusClick={onQuantityChange}
              value={item.quantity || 1}
              minusAriaLabel={`${item.name} verringern`}
              plusAriaLabel={`${item.name} erhöhen`}
            />
          )}
        </Container>
        <Container>
          {hasConditions && (
            <TextLink
              customClass="detail-tile-item__link"
              onClick={(event) => {
                handleChange(event, onConditionChange);
              }}
              aria-label={`${item.name} auswahl ändern`}
            >
              Auswahl ändern
            </TextLink>
          )}
          <List
            icon={{
              icon: ActionSuccessSelected,
              color: Icon.Color.functionGreen,
            }}
          >
            {item.promotions.map((entry) => (
              <List.Item key={entry.key}>
                <List.Text>{`${entry.description}`}</List.Text>
              </List.Item>
            ))}
          </List>

          {hasPromotions && (
            <TextLink
              customClass="detail-tile-item__link"
              onClick={(event) => handleChange(event, onPromotionChange)}
            >
              Aktion hinzufügen/ändern
            </TextLink>
          )}
        </Container>
        <Button
          customClass="detail-tile-item__button"
          variants={Button.Variant.outline}
          onClick={onRemove}
          aria-label={`${item.name} entfernen`}
        >
          <Button.Icon icon={ActionCloseDefault} />
          Entfernen
        </Button>
      </Tile.Content>
    </Tile>
  );
};

export default DetailTileItem;
