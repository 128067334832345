/* global tracking, utag */
const underscore = (string) => string.toLowerCase().replace(/ /g, "-");

const trackClick = (value, context = "") => {
  if (typeof utag === "undefined") return;

  const trackingValue = context
    ? underscore(`content.button.${context}.${value}`)
    : underscore(`content.button.${value}`);

  tracking.trackLink(trackingValue);
};

const trackLink = (value, context = "") => {
  if (typeof utag === "undefined") return;

  const trackingValue = context
    ? underscore(`content.text.${context}.${value}`)
    : underscore(`content.text.${value}`);

  tracking.trackLink(trackingValue);
};

const trackPencilSellingPage = (value) => {
  if (typeof utag === "undefined") return;

  tracking.pstStep = `${value}`;
  const trackValue = `schritt-${value}`;
  const trackingPath = tracking.path + trackValue;

  utag.view({
    page_content_id: trackingPath,
    page_active_tab: trackValue,
    page_type: tracking.type,
    page_target_organisation: tracking.org,
  });
};

const trackPdf = (context, payload) => {
  if (typeof utag === "undefined") return;

  utag.link({
    wt_link_id: context,
    pdf_payload: payload,
  });
};

export { trackClick, trackLink, trackPencilSellingPage, trackPdf };
