import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetIsCardLimitReached } from "./IGetIsCardLimitReached";

export class GetIsCardLimitReached implements IGetIsCardLimitReached {
  constructor(private entity: ITariff) {}

  execute(): boolean {
    return this.entity.isCardLimitReached;
  }
}
