import type { ICard } from "core/entities/Product/Card/ICard";
import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import type { IGetCardsUseCase } from "./IGetCards";

export class GetCardsUseCase implements IGetCardsUseCase {
  constructor(private readonly entity: IProductsEntity) {}

  execute(): ICard[] {
    return this.entity.getCards();
  }
}
