import {
  CustomAddonsRequestKeys,
  ICustomAddonItemRequest,
  ICustomAddonItemResponse,
} from "../core/repositories/ProductsRepository/ResponseInterface";
import { ICustomAddon } from "../components/new-design/AddonsSelection/IAddonsSelection";
import { Group } from "../core/entities/Product/IProduct";

export const mapCustomAddonToRequest = (
  customAddon: ICustomAddon,
  customAddonTypeKey: CustomAddonsRequestKeys
): ICustomAddonItemRequest => ({
  [customAddonTypeKey]: {
    key: customAddon.key,
    name: customAddon.name,
    type: customAddon.type,
    pricing: {
      once: customAddon.price.once,
      monthly: customAddon.price.monthly,
    },
    contract_period: customAddon.contractPeriod,
  },
});

export const mapCustomAddonFromResponse = (
  customAddon: ICustomAddonItemResponse
): ICustomAddon => ({
  category: null,
  description: null,
  nameAlternative: null,
  productGroup: null,
  isCustom: true,
  key: customAddon.key,
  name: customAddon.name,
  type: customAddon.type as Group,
  price: {
    once: customAddon.pricing.once,
    monthly: customAddon.pricing.monthly,
  },
  contractPeriod: customAddon.contract_period,
});
