import type { IShowLoyaltyBonusUseCase } from "./IShowLoyaltyBonus";
import { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";

export class ShowLoyaltyBonusUseCase implements IShowLoyaltyBonusUseCase {
  constructor(private readonly offer: IOffer) {}

  execute(): boolean {
    return this.offer.showLoyaltyBonus();
  }
}
