import React, { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  NetworkProviders,
  NetworkProvidersQualitySummary,
} from "views/Map/api/types";
import { NetworkQualityColorsMetaData } from "views/Map/constants/const";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { CopyText } from "@dtpk-cc/components";
import * as styles from "../map-sidebar-chart.module.scss";
import * as stylesToggleBtn from "../../../../../elements/new-design/ToggleMainBtn/toggle-main-btn.module.scss";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const BackgroundColors: Record<NetworkProviders, string> = {
  vodafone: "#E60001",
  telekom: "#E20074",
  telefonica: "#00327F",
} as const;

type MapSidebarChartDetailsProps = {
  networkProvidersQualitySummary: NetworkProvidersQualitySummary;
};

const MapSidebarChartDetails = ({
  networkProvidersQualitySummary,
}: MapSidebarChartDetailsProps) => {
  const [compareWithOtherProviders, setCompareWithOtherProviders] =
    useState(false);

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const providersMap = useMemo(
    () =>
      Object.entries(networkProvidersQualitySummary.providers).reduce(
        (prev, [curKey, curValue]) => {
          const { data, backgroundColor, labels } = prev;
          if (compareWithOtherProviders || curKey === "telekom") {
            return {
              data: [...data, curValue - 1],
              backgroundColor: [...backgroundColor, BackgroundColors[curKey]],
              labels: [...labels, capitalizeFirstLetter(curKey)],
            };
          }
          // manipulate data of others bars, if only telekom-bar is visible, this will hide them
          return {
            data: [...data, 0],
            backgroundColor: [...backgroundColor, BackgroundColors[curKey]],
            labels: [...labels, ""],
          };
        },
        { data: [], backgroundColor: [], labels: [] }
      ),
    [networkProvidersQualitySummary.providers, compareWithOtherProviders]
  );

  return (
    <div className={`${styles.details} ${styles.detailsContainer}`}>
      <Bar
        data={{
          labels: providersMap.labels,
          datasets: [
            {
              data: providersMap.data,
              backgroundColor: providersMap.backgroundColor,
              borderWidth: 1,
              barThickness: 50,
            },
          ],
        }}
        options={{
          plugins: {},
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              max: 3,
              ticks: {
                callback(value: number) {
                  return NetworkQualityColorsMetaData.find(
                    (el) => el.value === value
                  )?.description;
                },
              },
            },
          },
        }}
      />
      <div
        className={styles.compareWithOtherProvidersWrapper}
        onClick={() => setCompareWithOtherProviders((cur) => !cur)}
      >
        <div
          className={`${stylesToggleBtn.toggleButton} ${
            compareWithOtherProviders ? stylesToggleBtn.checked : ""
          }`}
        >
          <span className={stylesToggleBtn.toggleButtonSwitcher} />
        </div>
        <CopyText>Mit anderen Anbietern vergleichen</CopyText>
      </div>
    </div>
  );
};

export default MapSidebarChartDetails;
