import React from "react";
import { observer } from "mobx-react";
import * as styles from "./page-inner.module.scss";

type PageInnerProps = {
  customClass?: string;
};

const PageInner: React.FC<PageInnerProps> = ({
  children,
  customClass = "",
}) => <div className={`${styles.pageInner} ${customClass}`}>{children}</div>;

export default observer(PageInner);
