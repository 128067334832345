import React from "react";
import { Button } from "@dtpk-cc/components";

import * as styles from "./modal-wrapper-action-footer.module.scss";

type ModalWrapperActionFooterProps = {
  onSubmit: () => void;
  onCancel: () => void;
  cancelBtnText?: string;
  submitBtnText?: string;
  customClass?: string;
  submitDisabled?: boolean;
};

const ModalWrapperActionFooter = ({
  onCancel,
  onSubmit,
  customClass = "",
  cancelBtnText = "Abbrechen",
  submitBtnText = "Übernehmen",
  submitDisabled = false,
}: ModalWrapperActionFooterProps) => (
  <div className={`${styles.footer} ${customClass}`}>
    <Button
      tabIndex={0}
      onClick={onCancel}
      customClass="m-r-24"
      variants={Button.Variant.outline}
    >
      {cancelBtnText}
    </Button>
    <Button disabled={submitDisabled} tabIndex={0} onClick={onSubmit}>
      {submitBtnText}
    </Button>
  </div>
);

export default ModalWrapperActionFooter;
