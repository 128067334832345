import React from "react";
import {
  businessClientProductsRequestUrlMap,
  FrameworkContractRequestParams,
} from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { IFrameWorkContractItem } from "../../../core/entities/PencilSelling/BusinessClient/Offer/IOffer";
import { IFrameworkContractSelectState } from "../../../views/PencilSelling/IPencilSelling";
import { useFetchData } from "./useFetchData";
import { useStores } from "../../../stores/BusinessClient";

export const useGetProductsWithFrameworkContract = () => {
  const { fetchData } = useFetchData();
  const { offerStore, productsStore } = useStores();
  const getProductsWithFrameworkContract = async (
    targetFrameworkContract: IFrameWorkContractItem,
    setFrameworkContractSelectState: React.Dispatch<
      React.SetStateAction<IFrameworkContractSelectState>
    >
  ) => {
    const urlParams = new URLSearchParams({
      [FrameworkContractRequestParams.FRAMEWORK_CONTRACT_ID]: `${targetFrameworkContract.id}`,
    }).toString();

    const url = `${
      businessClientProductsRequestUrlMap[
        offerStore.getBaseOffer().customer.get().customerType
      ]
    }?${urlParams}`;

    const fetchProductsResponse = await fetchData(url);

    productsStore.setup(fetchProductsResponse.summary);
    offerStore.setFrameworkContract(targetFrameworkContract);
    setFrameworkContractSelectState((prev) => ({
      ...prev,
      isSelected: true,
      displayErrorMsg: false,
    }));
  };

  return { getProductsWithFrameworkContract };
};
