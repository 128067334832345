import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import { IPostCustomPromotion } from "./IPostCustomPromotion";
import { ICustomPromotion } from "../../../entities/PencilSelling/ICustomPromotion";

export class PostCustomPromotionUseCase implements IPostCustomPromotion {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(customPromotion: ICustomPromotion): Promise<ICustomPromotion> {
    const result = await this.repository.postCustomPromotion(customPromotion);
    return result;
  }
}
