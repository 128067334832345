import { makeAutoObservable } from "mobx";

import { floorsArr } from "views/PencilSelling/HomeConfigurator/const";
import {
  IHomeConfigurator,
  ConfigParamsKeys,
  SQUARE_METERS_MIN,
  IFloorItem,
} from "./IHomeConfigurator";

export const homeConfiguratorDefaultSettings = {
  squareMetersKey: ConfigParamsKeys.LESS_THAN_60,
  squareMetersValue: SQUARE_METERS_MIN,
  activeFloorVariant: floorsArr[0],
  routerIndex: 0,
  floorsVariants: floorsArr,
};

export class HomeConfigurator implements IHomeConfigurator {
  squareMetersKey: ConfigParamsKeys;

  squareMetersValue: number;

  activeFloorVariant: IFloorItem;

  routerIndex: number;

  floorsVariants: IFloorItem[];

  constructor() {
    this.squareMetersKey = ConfigParamsKeys.LESS_THAN_60;
    this.squareMetersValue = SQUARE_METERS_MIN;
    this.activeFloorVariant = floorsArr[0];
    this.routerIndex = 0;
    this.floorsVariants = floorsArr;
    makeAutoObservable(this);
  }

  setSquareMetersKey(keyValue: ConfigParamsKeys) {
    this.squareMetersKey = keyValue;
  }

  setSquareMetersValue(value: number) {
    this.squareMetersValue = value;
  }

  setActiveFloorVariant(floorVariant: IFloorItem) {
    this.activeFloorVariant = floorVariant;
  }

  setRouterIndex(value: number) {
    this.routerIndex = value;
  }

  setFloorsVariants(currentFloorVariants: IFloorItem[]) {
    this.floorsVariants = currentFloorVariants;
  }

  reset() {
    this.squareMetersKey = ConfigParamsKeys.LESS_THAN_60;
    this.squareMetersValue = SQUARE_METERS_MIN;
    this.activeFloorVariant = floorsArr[0];
    this.routerIndex = 0;
    this.floorsVariants = floorsArr;
  }
}
