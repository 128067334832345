import React, { useMemo } from "react";
import { Flex, Grid, Icon, List, CopyText } from "@dtpk-cc/components";
import ActionSuccessSelected from "@dtpk-cc/components/dist/icons/Action/Success/Selected";
import { CartItemSummaryPresenter } from "../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import "./cart-item.scss";
import TariffCalculatedMonthlyPrice from "../TariffCalculatedMonthlyPrice";

const CartItem = ({
  activeTariffInCart,
  selectedPromotions,
  title,
  tariffSettings,
}) => {
  const presenter = useMemo(
    () => new CartItemSummaryPresenter(activeTariffInCart, tariffSettings),
    [activeTariffInCart, tariffSettings]
  );
  const monthlyPriceObject = presenter.monthlyPrice();

  return (
    <div className="cart-item__wrapper">
      <Grid.Row>
        <CopyText customClass="cart-item__title">{title}</CopyText>
      </Grid.Row>
      <Grid.Row>
        <Flex customClass="cart-item__name-wrapper">
          <CopyText
            customClass="cart-item__data-item--60"
            variants={[CopyText.Variant.highlight]}
          >
            {activeTariffInCart?.name || ""}
          </CopyText>
          <div className="cart-item__data-item--40  cart-item__data-item--text-right">
            <TariffCalculatedMonthlyPrice
              withMonthlySuffix
              priceFontSizeIsLarge={false}
              monthlyPrice={monthlyPriceObject.price}
            />
          </div>
        </Flex>
      </Grid.Row>

      <Grid.Row customClass="cart-item__list-wrapper">
        <List
          customClass="cart-item__list"
          icon={{
            icon: ActionSuccessSelected,
            color: Icon.Color.functionGreen,
          }}
        >
          {!!selectedPromotions.length &&
            selectedPromotions.map((promotion) => (
              <List.Item key={promotion.key}>
                <List.Text>{promotion.description}</List.Text>
              </List.Item>
            ))}
        </List>
      </Grid.Row>
    </div>
  );
};

export default CartItem;
