import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { ICardDetailSection } from "core/entities/Product/Card/ICard";
import { IGetCardDetailSection } from "./IGetCardDetailSection";

export class GetCardDetailSection implements IGetCardDetailSection {
  constructor(private readonly entity: ITariff) {}

  execute(): ICardDetailSection {
    return this.entity.getCardDetailSection();
  }
}
