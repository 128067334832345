import { LatLngExpression } from "leaflet";
import { TileLayerProps } from "react-leaflet";
import { TelekomShop, Tower } from "../api/types";
import fiveG_booster_tower_icon from "../../../../assets/images/coverage-map/5G_booster_tower.png";
import fiveG_tower_icon from "../../../../assets/images/coverage-map/5G_tower.png";
import planned_fiveG_tower_icon from "../../../../assets/images/coverage-map/5G_ausbau_tower.png";
import telekom_shop_icon from "../../../../assets/images/coverage-map/telekom_shop.png";
import telekom_partner_shop_icon from "../../../../assets/images/coverage-map/telekom_partner_shop.png";
import start_icon from "../../../../assets/images/coverage-map/location_start.png";
import end_icon from "../../../../assets/images/coverage-map/location_end.png";
import {
  IconData,
  LocationPointKey,
  MapSettings,
  MapSettingsBackgroundColorScheme,
  MapSettingsColorScheme,
} from "../hooks/types";

const generateVectorLayerBaseUrl = (environment: string) =>
  process.env.NODE_ENV === "development"
    ? `http://localhost:3003/services/${environment}/tiles/{z}/{x}/{y}.pbf`
    : `netzabdeckung/services/${environment}/tiles/{z}/{x}/{y}.pbf`;

export const generateRasterLayerUrl = (mapSettings: MapSettings) => {
  const environment = mapSettings.layerOptions.outdoorNetworkCoverage
    ? "germany_outdoor"
    : "germany_indoor";
  const mapLayer = `${environment}_${mapSettings.colorScheme}`;

  return process.env.NODE_ENV === "development"
    ? `http://localhost:8080/data/${mapLayer}/{z}/{x}/{y}.png`
    : `netzabdeckung/data/${mapLayer}/{z}/{x}/{y}.png`;
};

export const vectorLayerOutdoorUrl =
  generateVectorLayerBaseUrl("germany_outdoor");

export const vectorLayerIndoorUrl =
  generateVectorLayerBaseUrl("germany_indoor");

const generateBaseMapTileLayer = (environment: string) => ({
  url: `https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_${environment}/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png`,
  attribution:
    "&copy; <a href=\"https://www.bkg.bund.de/\">BKG</a> <a href=\"https://creativecommons.org/licenses/by/4.0/\">CC BY 4.0</a>",
});

export const tileLayerProps: Record<
  MapSettingsBackgroundColorScheme,
  TileLayerProps
> = {
  [MapSettingsBackgroundColorScheme.topografic]: {
    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
  },
  [MapSettingsBackgroundColorScheme.default]: generateBaseMapTileLayer("farbe"),
  [MapSettingsBackgroundColorScheme.blackWhite]:
    generateBaseMapTileLayer("grau"),
};

export const tileLayerAttribution =
  "&copy; <a href=\"https://www.bkg.bund.de/\">BKG</a> <a href=\"https://creativecommons.org/licenses/by/4.0/\">CC BY 4.0</a>";

export const centerOfGermany: LatLngExpression = [51.4167, 12.4167];

export const NetworkQualityColorsMetaData = [
  {
    colorCode: {
      magenta: "rgba(241, 128, 185)",
      colorful: "rgba(0, 200, 0)",
    },
    description: "Überragend",
    value: 3,
  },
  {
    colorCode: {
      magenta: "rgba(246, 178, 213)",
      colorful: "rgba(150, 255, 0)",
    },
    description: "Sehr gut",
    value: 2,
  },
  {
    colorCode: {
      magenta: "rgba(251, 217, 234)",
      colorful: "rgba(255, 255, 0)",
    },
    description: "Gut",
    value: 1,
  },
  {
    colorCode: {
      magenta: "rgba(254, 242, 248)",
      colorful: "rgba(255, 197, 59)",
    },
    description: "Schwächer",
    value: 0,
  },
];

export const LocationIcons: Record<
  LocationPointKey,
  IconData & { width: number; height: number }
> = {
  start: {
    icon: start_icon,
    description: "Standort",
    width: 50,
    height: 67,
  },
  end: {
    icon: end_icon,
    description: "Ziel (Route)",
    width: 33,
    height: 44,
  },
};

export const QualityIcons: Record<MapSettingsColorScheme, IconData[]> = {
  magenta: [
    {
      icon: "rgba(226, 0, 116, 0.5)",
      description: "Überragend",
    },
    {
      icon: "rgba(226, 0, 116, 0.3)",
      description: "Sehr gut",
    },
    {
      icon: "rgba(226, 0, 116, 0.15)",
      description: "Gut",
    },
    {
      icon: "rgba(226, 0, 116, 0.05)",
      description: "Schwächer",
    },
  ],
  colorful: [
    {
      icon: "rgba(0, 200, 0)",
      description: "Überragend",
    },
    {
      icon: "rgba(150, 255, 0)",
      description: "Sehr gut",
    },
    {
      icon: "rgba(255, 255, 0)",
      description: "Gut",
    },
    {
      icon: "rgba(255, 197, 59)",
      description: "Schwächer",
    },
  ],
};

export const TowerIcons: Record<Tower, IconData> = {
  "4g_or_5g_tower": {
    icon: fiveG_tower_icon,
    description: "4G/5G",
  },
  "5g_booster_tower": {
    icon: fiveG_booster_tower_icon,
    description: "5G Boost",
  },
  "5g_booster_planned_tower": {
    icon: planned_fiveG_tower_icon,
    description: "5G Boost (Plan)",
  },
};

export const ShopIcons: Record<TelekomShop, IconData> = {
  telekom_shop: {
    icon: telekom_shop_icon,
    description: "Telekom Shop",
  },
  telekom_partner_shop: {
    icon: telekom_partner_shop_icon,
    description: "Partner Shop",
  },
};

export const MIN_ZOOM_FOR_INFRASTRUCTURE = 9;

export const MAP_FEATURE_CONTENT = "mobilfunk-netzabdeckung";
