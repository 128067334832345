import React, { ElementType } from "react";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import SummarySectionOptionsTitle from "../SummarySectionOptionsTitle";

type SummaryConfigurationInfoItemProps = {
  customClass?: string;
  title: string;
  Icon?: ElementType | string;
  fontColor: "magenta" | "blue" | "default";
};

const SummaryConfigurationInfoItem: React.FC<
  SummaryConfigurationInfoItemProps
> = ({ customClass = "", fontColor, title, Icon, children }) => (
  <>
    <SummarySectionItemWrapper
      variant="borderedInSection"
      customClass={customClass}
    >
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <SummarySectionOptionsTitle
          title={title}
          fontColor={fontColor}
          Icon={Icon}
        />
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly />
      <SummarySectionItemWrapper.Once />
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        {children}
      </SummarySectionItemWrapper.Main>
    </SummarySectionItemWrapper>
  </>
);

export default SummaryConfigurationInfoItem;
