import React from "react";
import { Heading } from "@dtpk-cc/components";
import * as styles from "./configuration-flyout-title.module.scss";

type ConfigurationFlyoutTitleProps = {
  title: string;
};

const ConfigurationFlyoutTitle = ({ title }: ConfigurationFlyoutTitleProps) => (
  <Heading
    tag="h3"
    variants={[Heading.Variant.tertiary, Heading.Variant.display]}
    customClass={styles.title}
  >
    {title}
  </Heading>
);

export default ConfigurationFlyoutTitle;
