import React from "react";
import { Grid } from "@dtpk-cc/components";
import * as styles from "./summary-section-item-wrapper.module.scss";

type SummarySectionItemWrapperProps = {
  customClass?: string;
  variant?: "default" | "borderedInSection" | "borderedInAddition";
};

type MainProps = {
  customClass?: string;
  variant?: "default" | "paddedLeftInSection" | "paddedLeftInAddition";
};

type MonthlyProps = {
  customClass?: string;
};

type OnceProps = {
  customClass?: string;
  variant?: "paddedRight" | "withoutPadding";
};

const summarySectionItemWrapperVariantsMap = {
  borderedInSection: styles.borderedInSection,
  borderedInAddition: styles.borderedInAddition,
};

const summarySectionItemOnceVariantsMap = {
  paddedRight: styles.paddedRight,
  withoutPadding: "",
};
const summarySectionItemMainVariantsMap = {
  paddedLeftInSection: styles.paddedLeftInSection,
  paddedLeftInAddition: styles.paddedLeftInAddition,
};

const SummarySectionItemWrapper: React.FC<SummarySectionItemWrapperProps> & {
  Main: React.FC<MainProps>;
  Monthly: React.FC<MonthlyProps>;
  Once: React.FC<OnceProps>;
} = ({ variant = "default", children, customClass = "" }) => {
  const currentStyles = `${styles.summaryItemWrapper} ${
    variant !== "default" ? summarySectionItemWrapperVariantsMap[variant] : ""
  }`;

  return (
    <Grid.Row customClass={`${currentStyles} ${customClass}`}>
      {children}
    </Grid.Row>
  );
};

const Main: React.FC<MainProps> = ({
  variant = "default",
  customClass = "",
  children,
}) => {
  const currentStyles =
    variant !== "default" ? summarySectionItemMainVariantsMap[variant] : "";

  return (
    <Grid.Col
      customClass={`${currentStyles} ${customClass}`}
      m={8}
      l={8}
      xl={16}
    >
      {children}
    </Grid.Col>
  );
};
const Monthly: React.FC<MonthlyProps> = ({ children, customClass = "" }) => (
  <Grid.Col customClass={customClass} m={2} l={2} xl={4}>
    {children}
  </Grid.Col>
);
const Once: React.FC<OnceProps> = ({
  variant = "paddedRight",
  children,
  customClass = "",
}) => {
  const currentStyles = summarySectionItemOnceVariantsMap[variant];

  return (
    <Grid.Col
      customClass={`${currentStyles} ${customClass}`}
      m={2}
      l={2}
      xl={4}
    >
      {children}
    </Grid.Col>
  );
};

SummarySectionItemWrapper.Main = Main;
SummarySectionItemWrapper.Monthly = Monthly;
SummarySectionItemWrapper.Once = Once;

export default SummarySectionItemWrapper;
