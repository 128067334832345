import React, { useState } from "react";
import { trackClick } from "helpers/reactTracking";
import { CopyText } from "@dtpk-cc/components";
import PromotionTile from "elements/PromotionTile";
import CustomItem from "elements/CustomItem";

import "components/PromotionList/promotion-list.scss";
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import CustomPromotionTile from "components/CustomPromotionTile";

const DEFAULT_PROMOTION_ITEM = {
  name: "",
  interval: "",
  value: "",
};

const PromotionList = ({
  trackingContext,
  promotions,
  selectedPromotions,
  onSelect,
  productPrice,
  onAdd,
  className = "",
  flexStart = false,
  showPromotionText = true,
  portfolio,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [customPromotion, setCustomPromotion] = useState(
    DEFAULT_PROMOTION_ITEM
  );

  const [hint, setHint] = useState("");

  const customPromotionText = promotions.length
    ? "Weitere Aktion hinzufügen"
    : "Aktion hinzufügen";

  const addCustomPromotion = () => {
    if (!customPromotion.name) return;

    const promotionExists = promotions.some(
      ({ name }) => name === customPromotion.name
    );
    if (promotionExists) {
      setHint(`${customPromotion.name} existiert bereits`);
      return;
    }

    setHint("");
    onAdd({
      isEditable: false,
      portfolio,
      name: customPromotion.name,
      description: customPromotion.name,
      key: Date.now(),
      // TODO Define type of value for Custom promotions in Secondary cards
      type: null,
      discount: {
        value: customPromotion.value,
        interval: customPromotion.interval,
        kind: PromotionPriceType.CUSTOM_PROMOTION,
      },
    }); // use Date.now for unique key
    setIsFormVisible(false);
    setCustomPromotion(DEFAULT_PROMOTION_ITEM);
  };

  return (
    <div className={`promotion-list ${className}`}>
      <div
        className={`promotion-list__wrapper ${
          !flexStart && promotions.length + 1 <= 2
            ? "promotion-list__wrapper--center"
            : ""
        }`}
      >
        {promotions?.map((promotion) => (
          <PromotionTile
            key={promotion.key}
            customClass="promotion-list__promotion"
            pricing={promotion.discount}
            name={promotion.description}
            additionalDescription={promotion.additionalDescription}
            productPrice={productPrice}
            active={selectedPromotions.some(({ key }) => promotion.key === key)}
            handleClick={() => {
              onSelect(promotion);
              trackClick(promotion.description, trackingContext);
            }}
          />
        ))}

        {!!onAdd &&
          (isFormVisible ? (
            <CustomPromotionTile
              hint={hint}
              customPromotion={customPromotion}
              setCustomPromotion={setCustomPromotion}
              trackingContext={trackingContext}
              addCustomPromotion={addCustomPromotion}
            />
          ) : (
            <CustomItem
              cssClass="promotion-list__promotion"
              handleOnClick={() => {
                setIsFormVisible(true);
                trackClick("aktion-plus-icon", trackingContext);
              }}
              text={customPromotionText}
            />
          ))}
      </div>

      {!promotions?.length && showPromotionText && (
        <CopyText customClass="promotion-list__hint-text">
          Momentan sind keine zentralen Aktionen verfügbar.{" "}
          {!!onAdd && (
            <>
              Du kannst jedoch manuell Aktionen über den Button „
              {customPromotionText}“ erstellen
            </>
          )}
        </CopyText>
      )}
    </div>
  );
};

export default PromotionList;
