import type { ITariff } from "core/entities/Product/Tariff/ITariff";
import { makeAutoObservable } from "mobx";
import { CardExport, CardSections, ICard } from "../../Product/Card/ICard";
import type {
  ILegacyTariff,
  LegacySummaryBaseCardData,
  LegacyTariffDataExport,
} from "./ILegacyTariff";


export class LegacyTariff implements ILegacyTariff {
  private tariffs: ITariff[] = [];

  private cards: ICard[] = [];

  private customCards: ICard[] = [];

  private customTotalPrice = 0;

  private customTotalQuantity = 0;

  private legacySummaryCardData: LegacySummaryBaseCardData = {
    name: "Mobilfunk-Karten (SIM-only)",
    key: CardSections.LEGACY_SUMMARY,
  };

  constructor() {
    makeAutoObservable(this);
  }

  getLegacySummaryCardData() {
    return this.legacySummaryCardData;
  }

  setTariffs(tariffs: ITariff[]): void {
    this.tariffs = tariffs.filter((tariff) => tariff.isLegacy);
  }

  getTariff(key: string): ITariff | undefined {
    return this.tariffs.find((tariff) => tariff.key === key);
  }

  setCards(cards: ICard[]): void {
    this.cards = cards.filter((card) => card.isLegacy);
  }

  getCard(key: string): ICard | undefined {
    return this.cards.find((card) => card.key === key);
  }

  getCustomCard(key: string): ICard | undefined {
    return this.customCards.find((card) => card.key === key);
  }

  getMagentaMobileTariffs(onlyActive?: boolean): ITariff[] {
    return this.tariffs.filter(({quantity}) => !onlyActive || quantity);
  }

  getAdditionalCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(({quantity}) => !onlyActive || quantity);
  }

  getCustomCards(onlyActive?: boolean): ICard[] {
    return this.customCards.filter(({quantity}) => !onlyActive || quantity);
  }

  getCards(): CardExport {
    return {
      [CardSections.MAGENTA_MOBILE_CARD]: this.getMagentaMobileTariffs(),
      [CardSections.ADDITIONAL_CARD]: this.getAdditionalCards(),
      [CardSections.CUSTOM_CARD]: this.getCustomCards(),
    };
  }

  getActiveCards(): CardExport {
    return {
      [CardSections.MAGENTA_MOBILE_CARD]: this.getMagentaMobileTariffs(true),
      [CardSections.ADDITIONAL_CARD]: this.getAdditionalCards(true),
      [CardSections.CUSTOM_CARD]: this.getCustomCards(true),
    };
  }

  addCustomCard(card: ICard) {
    this.customCards = [...this.customCards, card];
  }

  deleteCustomCard(key: string) {
    this.customCards = this.customCards.filter((card) => card.key !== key);
  }

  setQuantity(key: string, quantity: number): void {
    const productToChange =
      this.getCard(key) || this.getTariff(key) || this.getCustomCard(key);
    if (productToChange) productToChange.quantity = quantity;
  }

  setName(key: string, name: string): void {
    const productToChange = this.getCustomCard(key);
    if (productToChange) productToChange.name = name;
  }

  getTotalQuantity(): number {
    const tariffsQuantity = this.tariffs.reduce(
      (acc, item) => acc + (item.quantity || 0),
      0
    );
    const cardsQuantity = this.cards.reduce(
      (acc, item) => acc + (item.quantity || 0),
      0
    );
    const customCardsQuantity = this.customCards.reduce(
      (acc, item) => acc + (item.quantity || 0),
      0
    );
    return tariffsQuantity + cardsQuantity + customCardsQuantity;
  }

  setCardDataVolume(key: string, dataVolume: string): void {
    const cardToChange = this.getCustomCard(key);
    if (cardToChange) cardToChange.dataVolume = dataVolume;
  }

  setCardPrice(key: string, price: number): void {
    const cardToChange = this.getCustomCard(key);
    if (cardToChange) cardToChange.price.monthly = price;
  }

  getMagentaMobileTariffPrice(): number {
    return this.getMagentaMobileTariffs(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * (item.quantity || 0)) / 100,
      0
    );
  }

  getAdditionalCardsPrice(): number {
    return this.getAdditionalCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getCustomCardsPrice(): number {
    return this.getCustomCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getTotalPrice(): number {
    return (
      this.getMagentaMobileTariffPrice() +
      this.getAdditionalCardsPrice() +
      this.getCustomCardsPrice()
    );
  }

  getCustomTotalPrice(): number {
    return this.customTotalPrice;
  }

  setCustomTotalPrice(price: number): void {
    this.customTotalPrice = price;
  }

  getCustomTotalQuantity(): number {
    return this.customTotalQuantity;
  }

  setCustomTotalQuantity(quantity: number): void {
    this.customTotalQuantity = quantity;
  }

  export(): LegacyTariffDataExport {
    return {
      cards: this.getActiveCards(),
    };
  }

  reset(): void {
    this.tariffs = this.tariffs.map((tariff) => ({
      ...tariff,
      quantity: 0,
    }));
    this.cards = this.cards.map((card) => ({
      ...card,
      quantity: 0,
    }));
    this.customCards = [];
    this.customTotalPrice = 0;
    this.customTotalQuantity = 0;
  }
}
