export enum IStreamingServicesKeys {
  ard_plus = "ard_plus",
  zdf_select = "zdf_select",
  live_tv = "live_tv",
  dazn = "dazn",
  disney_plus = "disney_plus",
  magenta_sport = "magenta_sport",
  netflix = "netflix",
  rtl_plus = "rtl_plus",
  hd_plus = "hd_plus",
  wow_live_sport = "wow_live_sport",
}