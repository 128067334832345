import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";
import {
  trackClick,
  trackPdf,
} from "helpers/reactTracking";
import { Container, Heading, Modal } from "@dtpk-cc/components";
import { CSSTransition } from "react-transition-group";

import "views/resets.scss";
import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Asterisk/Asterisk.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/EqualHeight/EqualHeight.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import "@dtpk-cc/components/dist/components/Modal/Modal.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import "@dtpk-cc/components/dist/components/Tabs/Tabs.css";
import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Tile/Tile.css";
import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Indicator/Indicator.css";
import "@dtpk-cc/components/dist/components/Tooltip/Tooltip.css";
import "@dtpk-cc/components/dist/components/StepIndicator/StepIndicator.css";
import "views/PencilSelling/PrivateClient_legacy/pencil-selling.scss";

import Personal from "views/PencilSelling/PrivateClient_legacy/Personal";
import Tariff from "views/PencilSelling/PrivateClient_legacy/Tariff";
import Devices from "views/PencilSelling/PrivateClient_legacy/Devices";
import OptionsLandline from "views/PencilSelling/PrivateClient_legacy/OptionsLandline";
import OptionsMobile from "views/PencilSelling/PrivateClient_legacy/OptionsMobile";
import OfferFinalization from "views/PencilSelling/PrivateClient_legacy/OfferFinalization";
import Summary from "views/PencilSelling/PrivateClient_legacy/Summary";
import TabPanel from "elements/TabPanel";

import LoadingSpinner from "elements/LoadingSpinner";
import StepIndicator from "components/StepIndicator";
import NavigationButtonSet from "components/NavigationButtonSet";
import SketchBoard from "components/SketchBoard";

import { useQrCodeModalData } from "hooks/useQrCodeModalData";
import { OfferSummaryPresenter } from "core/presenter/PencilSelling/OfferSummaryPresenter";
import {
  IBannerLinks,
  IDistributionPoint,
} from "../../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import { TABS_INDEXES, QUERY_PARAMS_MAP } from "./const";
import SecondaryCards from "./SecondaryCards";
import QrCodeModal from "../../../components/QrCodeModal";
import { useApplyMagentaCalculatorConfig } from "../../../hooks/PencilSelling/LegacyPrivateClient/useApplyMagentaCalculatorConfig";
import { useStores } from "../../../stores";
// TODO Temporary hidden
// import { fetchFile } from "../../helpers/FetchFile";
import PencilSellingToolbar, {
  TOOL_KEY_HNP,
} from "../../../components/PencilSellingToolbar";
import { useFetchProducts } from "../../../hooks/PencilSelling/LegacyPrivateClient/useFetchProducts";
import { CustomerType } from "../../../core/entities/PencilSelling/Customer/ICustomer";
// Adds reloading of useEffect, useCallback, useMemo on every injection https://github.com/gaearon/react-hot-loader
setConfig({
  reloadHooks: false,
});

const NAVIGATION_STEPS = [
  { label: "Basisdaten", content: "" },
  { label: "Wunschtarife", content: "" },
  { label: "Zusatzkarten", content: "" },
  { label: "Geräte Festnetz", content: "" },
  { label: "Extras Festnetz", content: "" },
  { label: "Extras Mobilfunk", content: "" },
  { label: "Angebot fertigstellen", content: "" },
  { label: "Angebot", content: "" },
];

const NAVIGATION = {
  initialStep: 1,
  maxSteps: 8,
};

interface PencilSellingProps extends IBannerLinks {
  distributionPoint: IDistributionPoint;
  distributionLink: string;
}

const PencilSelling = ({
  distributionPoint,
  distributionLink,
  magentaAppLink,
  onlineAppointmentBookingLink,
  googleReviewLink,
}: PencilSellingProps) => {
  const view = useRef<HTMLDivElement>(null);
  const { qrCodeModalData, setQrCodeModalData } = useQrCodeModalData();
  const { applyMagentaCalculatorConfig } = useApplyMagentaCalculatorConfig();
  const [isLoading, setIsLoading] = useState(true);
  const { productsRepositoryStore, offerStore } = useStores();
  const { fetchProducts } = useFetchProducts();

  const [sketchboardDocument, setSketchboardDocument] = useState();
  const [sketchboardIsActive, setSketchboardIsActive] = useState(false);
  const [sketchboardIsVertical, setSketchboardIsVertical] = useState(true);
  const [activeToolKey, setActiveToolKey] = useState("");

  const [submitMsgIsDisplayed, setSubmitMsgIsDisplayed] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(
    NAVIGATION.initialStep
  );

  const applyToolConfig = () => {
    setSubmitMsgIsDisplayed(true);
    setTimeout(() => {
      setSubmitMsgIsDisplayed(false);
    }, 1500);
  };

  // const applyStreamingAnalysis = () => {
  //   setForceRerender((prev) => !prev);

  //   setSubmitMsgIsDisplayed(true);
  //   setTimeout(() => {
  //     setSubmitMsgIsDisplayed(false);
  //   }, 1500);
  // };
  const distributionData: {
    distributionLink: string;
    distributionPoint: IDistributionPoint;
  } = useMemo(
    () => ({
      distributionLink,
      distributionPoint: Object.values(distributionPoint).some(
        (distributionItem) => !!distributionItem
      )
        ? distributionPoint
        : null,
    }),
    [distributionLink, distributionPoint]
  );

  const bannerLinks = useMemo(
    () => ({ magentaAppLink, googleReviewLink, onlineAppointmentBookingLink }),
    [magentaAppLink, googleReviewLink, onlineAppointmentBookingLink]
  );

  const toggleSketchboard = useCallback(() => {
    const bodyElem = document.querySelector("body");
    /*
     * "fullscreen" class is a flag, that gives an understanding of is fullscreen mode is turned on.
     * If it is, than we add "sketchboard--fullscreen" class to remove footer, adjust styling for body and sketchboard container
     * */
    if (bodyElem?.classList.contains("fullscreen")) {
      bodyElem.classList.toggle("sketchboard--fullscreen");
    }

    setSketchboardIsActive((prev) => !prev);
  }, []);

  const toggleSketchboardOrientation = useCallback(
    () => setSketchboardIsVertical((prevState) => !prevState),
    []
  );
  // TODO Temporary hidden
  // const setSketchboardImageOriginSize = useCallback((tlDrawInstance) => {
  //   const createdImageId =
  //     tlDrawInstance.history[tlDrawInstance.history.length - 1].after.document
  //       .pageStates.page.selectedIds[0];
  //   const shapeInstance = tlDrawInstance.getShape(createdImageId);
  //   tlDrawInstance.setShapeProps(
  //     {
  //       ...shapeInstance,
  //       size: [shapeInstance.size[0] * 2, shapeInstance.size[1] * 2],
  //       point: [50, 50],
  //     },
  //     [createdImageId]
  //   );
  //   return tlDrawInstance;
  // }, []);
  // const addImageToSketchboard = useCallback(
  //   async (imageUrl, imageName) => {
  //     let tlDrawInstance;
  //     const file = await fetchFile(imageUrl, imageName);
  //     tlDrawInstance = await sketchboardDocument.addMediaFromFile(file);
  //     tlDrawInstance = await setSketchboardImageOriginSize(tlDrawInstance);
  //     await tlDrawInstance.selectTool("select");
  //   },
  //   [sketchboardDocument, setSketchboardImageOriginSize]
  // );

  const getOfferPresenter = useCallback(() => {
    const offerExport = offerStore.export();
    const showDistributionData =
      !!offerStore.getProfileDetailsInPdfIsDisplayed();

    return new OfferSummaryPresenter(offerExport, bannerLinks, {
      ...distributionData,
      showDistributionData,
    });
  }, [offerStore, distributionData, bannerLinks]);

  const getOfferPdfQrCode = useCallback(() => {
    setIsLoading(true);
    const presenter = getOfferPresenter();
    productsRepositoryStore
      .submitPdfQrCode({
        ...presenter.exportSummary(),
        tool_domain: offerStore.customer.get().domainType,
        bge_context: false,
      })
      .then((data) => data.json())
      .then((data) => {
        setQrCodeModalData({
          isOpen: true,
          urlLink: data.url,
          imageSrc: `data:image/jpg;base64,${data.qr_code}`,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    getOfferPresenter,
    offerStore.customer,
    productsRepositoryStore,
    setQrCodeModalData,
  ]);

  const getOfferPdf = useCallback(
    async (email) => {
      const offerExport = offerStore.export();
      setIsLoading(true);
      const presenter = getOfferPresenter();
      const pdf = await productsRepositoryStore.submitOrder({
        ...presenter.exportSummary(),
        tool_domain:
          offerStore.customer.get().customerType === CustomerType.PRIVATE
            ? "personal"
            : "business",
        bge_context: false,
      });
      setIsLoading(false);
      trackPdf("PDF-erstellen", offerExport);
      if (pdf) window.open(pdf, "_blank", "noopener,noreferrer");
    },
    [productsRepositoryStore, getOfferPresenter, offerStore]
  );

  const getSketchboard = useCallback((sketchboard) => {
    setSketchboardDocument(sketchboard);
  }, []);

  const printSketchboard = useCallback(() => {
    document.body.classList.add("sketchboard-print");
    window.print();
  }, []);

  const handleReset = useCallback(() => {
    offerStore.reset();
    setActiveStepIndex(1);
  }, [offerStore]);

  const displayHNPbySearchParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    if (
      params.get(QUERY_PARAMS_MAP.hnpOpened.key) ===
      QUERY_PARAMS_MAP.hnpOpened.value
    ) {
      setActiveToolKey(TOOL_KEY_HNP);
    }
  }, []);

  useEffect(() => {
    let mediaQueryList: MediaQueryList;
    if (window.matchMedia && window) {
      mediaQueryList = window.matchMedia("print");
      mediaQueryList.addEventListener("change", (mql) => {
        if (!mql.matches) {
          document.body.classList.remove("sketchboard-print");
        }
      });
    }
    return () => {
      if (window.matchMedia && window) {
        mediaQueryList.removeEventListener("change", () => {
          //  Do nothing.
        });
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchProducts();
      displayHNPbySearchParams();
      applyMagentaCalculatorConfig();
      setIsLoading(false);
    };
    fetchData().catch(() => {
      //  Do nothing...
    });
  }, [fetchProducts, displayHNPbySearchParams, applyMagentaCalculatorConfig]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <SketchBoard
        printSketchboard={printSketchboard}
        getSketchboard={getSketchboard}
        toggleSketchboard={toggleSketchboard}
        sketchboardIsActive={sketchboardIsActive}
        sketchboardIsVertical={sketchboardIsVertical}
      />
      <CSSTransition
        in={sketchboardIsActive}
        timeout={150}
        classNames="pencil-selling-transition"
      >
        <Container customClass="pencil-selling">
          <StepIndicator
            steps={NAVIGATION_STEPS}
            position={activeStepIndex}
            onStepClick={(index) => {
              setActiveStepIndex(index);
              // trackPencilSellingPage(index);
            }}
          />
          {!isLoading && (
            <PencilSellingToolbar
              toggleSketchboard={toggleSketchboard}
              applyToolConfig={applyToolConfig}
              initialActiveComponentKey={activeToolKey}
            />
          )}
          {/* Sections render */}

          <TabPanel ref={view}>
            {activeStepIndex === TABS_INDEXES.personal && <Personal />}

            {activeStepIndex === TABS_INDEXES.tariff && <Tariff />}

            {activeStepIndex === TABS_INDEXES.secondaryCards && (
              <SecondaryCards />
            )}

            {activeStepIndex === TABS_INDEXES.devices && <Devices />}

            {activeStepIndex === TABS_INDEXES.optionsLandline && (
              <OptionsLandline />
            )}

            {activeStepIndex === TABS_INDEXES.optionsMobile && (
              <OptionsMobile />
            )}

            {activeStepIndex === TABS_INDEXES.offerFinalization && (
              <OfferFinalization distributionData={distributionData} />
            )}

            {activeStepIndex === TABS_INDEXES.summary && (
              <Summary presenter={getOfferPresenter()} />
            )}
          </TabPanel>
        </Container>
      </CSSTransition>

      <Container customClass="pencil-selling__bottom-container ">
        <NavigationButtonSet
          onReset={handleReset}
          customClass="pencil-selling__nav-button-set"
          hasBack={activeStepIndex !== NAVIGATION.initialStep}
          hasNext={activeStepIndex < NAVIGATION.maxSteps}
          onBack={() => {
            setActiveStepIndex((oldStepIndex) => oldStepIndex - 1);
            view.current.focus({ preventScroll: true });
            // trackPencilSellingPage(activeStepIndex - 1);
          }}
          onNext={() => {
            setActiveStepIndex((oldStepIndex) => oldStepIndex + 1);
            view.current.focus({ preventScroll: true });
            // trackPencilSellingPage(activeStepIndex + 1);
          }}
          onPdfQrGet={() => {
            getOfferPdfQrCode();
            trackClick("PDF-QR-Code");
          }}
          onPrint={getOfferPdf}
          printSketchboard={printSketchboard}
          isSketchboardOpen={sketchboardIsActive}
          sketchboardDocument={sketchboardDocument}
          toggleSketchboardOrientation={toggleSketchboardOrientation}
          // TODO Temporary hidden
          // addImageToSketchboard={addImageToSketchboard}
          isLoading={isLoading}
        />
      </Container>
      {!isLoading && qrCodeModalData.isOpen && (
        <QrCodeModal
          isOpen={qrCodeModalData.isOpen}
          urlLink={qrCodeModalData.urlLink}
          imageSrc={qrCodeModalData.imageSrc}
          closeChangeHandler={() =>
            setQrCodeModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
          }
        />
      )}
      <Modal zIndex={100} isOpen={submitMsgIsDisplayed}>
        <Modal.Section>
          <Heading
            tag="h3"
            customClass="pencil-selling__tool-apply-success-msg-text"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            Zum Angebot hinzugefügt
          </Heading>
        </Modal.Section>
      </Modal>
    </LoadingSpinner>
  );
};

export default hot(observer(PencilSelling));
