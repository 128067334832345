import { ISettings } from "core/entities/MagentaNext/Tariff/ITariff";

const getCardSummaryTitle = (
  settings: ISettings,
  quantity: number,
  isLegacy = false
) =>
  `Summe für ${quantity} Mobilfunk-Karte${quantity === 1 ? "" : "n"} ${
    !isLegacy && settings.loyaltyBonusValue > 0 ? "ohne Treuebonus" : ""
  }`;

const getAverageCardSummaryTitle = (settings: ISettings, isLegacy = false) =>
  `Rechnerischer Durchschnittspreis pro Karte ${
    !isLegacy && settings.hasProvisionFee ? "inkl. Bereitstellungspreis" : ""
  } ${!isLegacy && settings.loyaltyBonusValue > 0 ? "mit Treuebonus" : ""}`;

export { getCardSummaryTitle, getAverageCardSummaryTitle };
