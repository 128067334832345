import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { IDeleteCard } from "./IDeleteCard";

export class DeleteCard implements IDeleteCard {
  constructor(private legacyEntity: ILegacyTariff) {}

  execute(key: string): void {
    this.legacyEntity.deleteCustomCard(key);
  }
}
