import { Feature, FeatureCollection, Point } from "geojson";
import L, { LatLng, Layer } from "leaflet";
import React, { useMemo } from "react";
import { GeoJSON } from "react-leaflet";
import { MapLayerOptions } from "views/Map/hooks/types";
import MarkerClusterGroup from "react-leaflet-cluster";
import { ShopIcons, TowerIcons } from "views/Map/constants/const";

type MapLayerInfrastructureProps = {
  infrastructure: FeatureCollection | null;
  layerOptions: MapLayerOptions;
};

const createClusterIcon = (cluster: any) => {
  const count = cluster.getChildCount();
  const iconSize: [number, number] = [48, 48];

  const generateCircleStyles = (radius: string, opacity: number) => `
    width: ${radius};
    height: ${radius};
    background: rgba(226, 0, 116, ${opacity});
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return L.divIcon({
    html: `<div style="${generateCircleStyles("100%", 0.25)}">
      <div style="
        ${generateCircleStyles(`${iconSize[0] * 0.65}px`, 1)}
        color: white;
        font-size: 18px;
        font-family: TeleNeo;
        font-weight: 700;"
      >
        ${count}
      </div>
    </div>`,
    className: "marker-cluster-custom",
    iconSize,
    iconAnchor: [iconSize[0] / 2, iconSize[1] / 2],
  });
};

const pointToLayer = (
  geoJsonPoint: Feature<Point, any>,
  latlng: LatLng
): Layer =>
  L.marker(latlng, {
    icon: L.icon({
      iconUrl: { ...TowerIcons, ...ShopIcons }[geoJsonPoint.properties.kind]
        .icon,
      iconSize: [41, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
  });

const MapLayerInfrastructure = ({
  infrastructure,
  layerOptions,
}: MapLayerInfrastructureProps) => {
  const filteredInfrastructure = useMemo(() => {
    if (infrastructure) {
      return {
        ...infrastructure,
        features: infrastructure.features.filter(
          (el) => layerOptions[el.properties.kind]
        ),
      };
    }
    return null;
  }, [infrastructure, layerOptions]);

  if (filteredInfrastructure) {
    return (
      <MarkerClusterGroup iconCreateFunction={createClusterIcon}>
        <GeoJSON
          key={JSON.stringify(filteredInfrastructure)}
          data={filteredInfrastructure}
          pointToLayer={pointToLayer}
        />
      </MarkerClusterGroup>
    );
  }

  return null;
};

export default MapLayerInfrastructure;
