import { Button, CopyText, Heading } from "@dtpk-cc/components";
import ModalWrapper from "elements/new-design/ModalWrapper";
import React, { useState } from "react";

import DropdownMain from "elements/new-design/DropdownMain";
import { useStores } from "stores/BusinessClient";
import { observer } from "mobx-react";
import * as styles from "./business-cards-3gen-modal.module.scss";

/*
appears only once during sessionand could not be restore from ui before next session
we should take in account the selected amount of business card gen 3
*/
const BusinessCard3GenModal = () => {
  const firstItemLabel = "Keine";

  const items: Record<string, number> = {
    [firstItemLabel]: 0,
    "1 Karte": 1,
    "2 Karten oder mehr": 2,
  };
  const { offerStore } = useStores();

  const [selectedVariant, setSelectedVariant] =
    useState<string>(firstItemLabel);

  const closeModal = () => {
    offerStore.setBusinessCards3IsModalOpen(false);
  };

  const onSelection = (value: string) => {
    setSelectedVariant(value);
  };

  const amountApprove = () => {
    const modalAmount = items[selectedVariant];
    offerStore.setBusinessCardsGen3Amount({ modal: modalAmount });
    closeModal();
  };

  return (
    <ModalWrapper
      isOpen={offerStore.businessCards3IsModalOpen}
      onClose={closeModal}
      containerCustomClass={styles.businessCards3GenModal}
      onBackDropClick={closeModal}
    >
      <Heading
        tag="h3"
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        Vorhandene BusinessCards 3. Generation
      </Heading>
      <CopyText>
        Wenn bereits eine oder mehrere BusinessCards 3. Generation vorhanden
        sind, kann das Auswirkungen auf den Preis haben. Geben Sie die Anzahl
        bitte hier an:
      </CopyText>
      <div className="m-t-26">
        <DropdownMain
          label="Anzahl vorhandener Karten"
          items={Object.keys(items)}
          onSelection={onSelection}
          value={selectedVariant}
        />
      </div>
      <div className={`${styles.businessCards3GenModal__ctaWrapper}`}>
        <Button onClick={amountApprove}>Übernehmen</Button>
      </div>
    </ModalWrapper>
  );
};

export default observer(BusinessCard3GenModal);
