import React from "react";
import { Price } from "@dtpk-cc/components";
import { roundUpValue } from "helpers/NumberHelpers";

import "./loyalty-bonus-price-badge.scss";

interface LoyaltyBonusPriceBadgeProps {
  loyaltyBonusPrice: number;
  isLegacyTariffVisible?: boolean;
  disabled: boolean;
  id: string;
}

const LoyaltyBonusPriceBadge = ({
  loyaltyBonusPrice,
  isLegacyTariffVisible,
  disabled,
  id,
}: LoyaltyBonusPriceBadgeProps) => {
  if (disabled) {
    return null;
  }

  const roundedUpValue = roundUpValue(loyaltyBonusPrice, 2);

  return (
    <div
      data-testid="loyalty-bonus-price-badge"
      id={id}
      className={`loyalty-bonus-price-badge ${
        isLegacyTariffVisible ? "loyalty-bonus-price-badge--comparsing" : ""
      }`}
    >
      <Price
        customClass="loyalty-bonus-price-badge__price"
        // @ts-ignore
        prefix={<>&Oslash;</>}
        value={roundedUpValue}
      />
      <Price.Text customClass="loyalty-bonus-price-badge__text">
        mit Treuebonus
      </Price.Text>
    </div>
  );
};

export default LoyaltyBonusPriceBadge;
