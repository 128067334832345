import { MagentaNextSummaryPresenter } from "core/presenter/MagentaNext/MagentaNextSummaryPresenter";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { IQRCodeModalData } from "hooks/useQrCodeModalData";
import { useAveragePrices } from "./useAveragePrices";
import { trackClick, trackPdf } from "../../helpers/reactTracking";
import { QRCodeResponse } from "../../core/repositories/ProductsRepository/ResponseInterface";

export interface PdfFunctions {
  openPdf: () => Promise<void>;
  getOfferPdfQrCode: () => Promise<void>;
}

export const usePdfFunctions = (
  legacyTariffDetailViewIsVisible: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setQrCodeModalData: React.Dispatch<React.SetStateAction<IQRCodeModalData>>
) => {
  const { legacyTariffStore, tariffStore, productsRepositoryStore } =
    useMagentaNextCalculatorStores();

  const { averagePrice, legacyAveragePrice } = useAveragePrices();

  const legacyCustomTotalPrice = legacyTariffStore.getCustomTotalPrice();
  const legacyCustomTotalQuantity = legacyTariffStore.getCustomTotalQuantity();

  const computeTotalPrice = (price, credit = 0) => {
    const result = price - credit;
    return result > 0 ? result : 0;
  };

  const getPDFSummary = () => {
    // Get all data from tariffs
    const legacyTariffExport = legacyTariffStore.export();
    const tariffExport = tariffStore.export();
    const legacySummaryCardData = legacyTariffStore.getLegacySummaryCardData();

    const tariffsTotalPrice = computeTotalPrice(
      tariffExport.totalPrice,
      tariffExport.credit
    );
    const tariffExportUpdated = {
      ...tariffExport,
      totalPrice: tariffsTotalPrice,
      averagePrice,
    };
    const legacyTariffExportUpdated = {
      cards: !legacyTariffDetailViewIsVisible
        ? {
            [legacySummaryCardData.key]: [
              {
                name: legacySummaryCardData.name,
                quantity: legacyCustomTotalQuantity,
                price: { monthly: legacyCustomTotalPrice, once: null },
              },
            ],
          }
        : legacyTariffExport.cards,
      quantity: legacyCustomTotalQuantity,
      totalPrice: legacyCustomTotalPrice,
      averagePrice: legacyAveragePrice,
    };

    const pdfPresenter = new MagentaNextSummaryPresenter(
      legacyTariffExportUpdated,
      tariffExportUpdated
    );

    return pdfPresenter.export();
  };

  const openPdf = async () => {
    setIsLoading(true);
    try {
      const pdfData = getPDFSummary();
      const pdfFile = await productsRepositoryStore.getCalculatorPDF(pdfData);
      trackPdf("content.button.pdf-drucken-magentamobil-rechner", pdfData);
      if (pdfFile) window.open(pdfFile, "_blank", "noopener,noreferrer");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOfferPdfQrCode = async () => {
    setIsLoading(true);
    try {
      const pdfData = getPDFSummary();
      const responseJsonData =
        await productsRepositoryStore.submitCalculatorPdfQrCode(pdfData);
      const responseData = (await responseJsonData.json()) as QRCodeResponse;
      setQrCodeModalData({
        isOpen: true,
        urlLink: responseData.url,
        imageSrc: `data:image/jpg;base64,${responseData.qr_code}`,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
    trackClick("qr-code-magentamobil-rechner");
  };

  return {
    openPdf,
    getOfferPdfQrCode,
  };
};
