import { BENEFIT_VALUE } from "core/entities/MagentaNext/Tariff/Tariff";
import { ITariff } from "core/entities/Product/Tariff/ITariff";

export const getFreeMonths = (
  mainTariff: ITariff,
  loyaltyBonus: number
): number => {
  if (!mainTariff) return 0;

  const result = Math.floor(
    (loyaltyBonus - mainTariff.price.provision) /
      (mainTariff.price.monthly - BENEFIT_VALUE)
  );

  return result > 0 ? result : 0;
};
