import React from "react";
import { observer } from "mobx-react";

import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import AddonsSelection from "components/AddonsSelection";
import { useStores } from "../../../../stores";

const OptionsLandline = () => {
  const { productsStore, cartStore } = useStores();
  const options = productsStore.getLandlineOptions();
  const optionsInCart = cartStore.getLandlineOptions();
  const tariffs = cartStore.getTariffsInCart();

  return (
    <AddonsSelection
      isFungible={false}
      allowCustomProducts={false}
      headline="Extras Festnetz"
      name="Extras"
      products={options}
      activeProducts={optionsInCart}
      condition={tariffs[PortfolioKeys.LANDLINE]?.key}
      portfolio={PortfolioKeys.LANDLINE}
      addonGroup={Group.addon}
    />
  );
};

export default observer(OptionsLandline);
