import { IServicesEntity } from "core/entities/PencilSelling/Services/IServicesEntity";
import { IStreamingVariant } from "core/entities/Product/IStreamingVariant";
import { IGetStreamingVariants } from "./IGetStreamingVariants";

export class GetStreamingVariantsUseCase implements IGetStreamingVariants {
  constructor(private readonly entity: IServicesEntity) {}

  execute(): IStreamingVariant[] {
    return this.entity.getStreamingVariants();
  }
}
