import React from "react";
import { StepIndicator, Container } from "@dtpk-cc/components";

import "components/StepIndicator/step-indicator.scss";

function StepIndicatorSix({ position, steps, onStepClick }) {
  const handleKeyPress = (event, step) => {
    // 13 = enter
    if (event.key === "Enter") {
      onStepClick(step);
    }
  };

  const ariaCurrent = (index) => {
    const currentStep = position - 1;
    return currentStep === index ? { "aria-current": "true" } : null;
  };

  const ariaHidden = !(window.innerWidth < 800);

  const tabIndex = window.innerWidth < 800 ? 0 : -1;

  return (
    <Container
      customClass="step-indicator"
      variants={[Container.Variant.padded]}
      role="navigation"
      aria-label="Fortschrittsanzeige"
    >
      <StepIndicator
        currentStep={position}
        hideLabelUntil={800}
        allowUserClick
        onStepChange={(stepOrder) => onStepClick(stepOrder)}
      >
        {steps.map(({ label }, i) => (
          <StepIndicator.Step
            tabIndex={tabIndex}
            key={label}
            stepOrder={i + 1}
            aria-hidden={ariaHidden}
          >
            <span
              {...ariaCurrent(i)}
              content={label}
              tabIndex={0}
              role="link"
              style={{ cursor: "pointer" }}
              onClick={() => onStepClick(i + 1)}
              onKeyPress={(event) => handleKeyPress(event, i + 1)}
            >
              {label}
            </span>
          </StepIndicator.Step>
        ))}
      </StepIndicator>
    </Container>
  );
}

export default StepIndicatorSix;
