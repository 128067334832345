import { useCallback } from "react";
import { fetchAndMapProducts } from "helpers/fetchAndMapProducts";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { useStores } from "../../../stores/BusinessClient";
import { ProductsMapperBC } from "../../../core/mapper/ProductsMapperBC";

export const useFetchData = () => {
  const { productsRepositoryStore, offerStore } = useStores();
  const baseOffer = offerStore.getBaseOffer();

  const fetchData = useCallback(
    async (getProductsUrl: string) => {
      // Get products with summary and promotions
      const { products: data, summary } = await fetchAndMapProducts(
        productsRepositoryStore,
        ProductsMapperBC,
        CustomerType.BUSINESS,
        getProductsUrl,
        baseOffer
      );

      return { data, summary };
    },
    [productsRepositoryStore, baseOffer]
  );

  return { fetchData };
};
