import React from "react";
import { observer } from "mobx-react";
import StepHeader from "components/new-design/StepHeader";
import PageWrapper from "../../../elements/new-design/PageWrapper";
import PageInner from "../../../elements/new-design/PageWrapper/PageInner";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import SummaryMobile from "./SummaryMobile";
import SummaryLandline from "./SummaryLandline";
import SummaryDevices from "./SummaryDevices";
import SummaryTotalPrice from "./SummaryTotalPrice";
import { useQrCodeModalData } from "../../../hooks/useQrCodeModalData";
import SummaryWrapper from "./SummaryWrapper";
import SummaryDetails from "./SummaryDetails";
import QRCodeModal from "./QRCodeModal";
import SummaryCards from "./SummaryCards";
import { trackClick, trackPdf } from "../../../helpers/reactTracking";
import { QRCodeResponse } from "../../../core/repositories/ProductsRepository/ResponseInterface";
import SummaryPDFButtons from "./SummaryPDFButtons";
import { CustomerType } from "../../../core/entities/PencilSelling/Customer/ICustomer";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { IGetSummaryData } from "../../../hooks/PencilSelling/useGetSummaryData";
import SummaryPrevContractData from "./SummaryPrevContractData";
import { mapTotalPricesForPDF } from "./helpers";

type SummaryProps = {
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  getSummaryData: IGetSummaryData;
};

const Summary = ({
  getSummaryData,
  offerStore,
  productsRepositoryStore,
}: SummaryProps) => {
  const { setIsLoading } = offerStore;
  const { qrCodeModalData, setQrCodeModalData } = useQrCodeModalData();
  const { customerType, customerPricePrefix, domainType } = offerStore.customer.get();
  const customerTypeMap = {
    [CustomerType.BUSINESS]: "business",
    [CustomerType.EMPLOYEE]: "bge",
    [CustomerType.PRIVATE]: "private",
  };

  const {
    configurations,
    configurationsExists,
    offerPresenterExport,
    savingsTotalValue,
  } = getSummaryData();

  const prevContractData =
    "prevContractData" in offerPresenterExport
      ? offerPresenterExport.prevContractData
      : null;
  const displayConfigurationTotalPrice =
    "displayConfigurationTotalPrice" in offerPresenterExport
      ? offerPresenterExport.displayConfigurationTotalPrice
      : true;
  const displayOfferTotalPrice =
    "displayOfferTotalPrice" in offerPresenterExport
      ? offerPresenterExport.displayOfferTotalPrice
      : true;

  const pdfPayload = {
    details: offerPresenterExport.details,
    distributionData: offerPresenterExport.distributionData,
    prevContractData:
      "prevContractData" in offerPresenterExport
        ? offerPresenterExport.prevContractData
        : null,
    bannerLinks: offerPresenterExport.bannerLinks,
    configurations: mapTotalPricesForPDF(
      configurations,
      displayConfigurationTotalPrice,
      displayOfferTotalPrice
    ),
    tool_domain: domainType,
    bge_context: customerType === CustomerType.EMPLOYEE,
  };

  const getOfferPdf = async () => {
    setIsLoading(true);
    try {
      const pdf = await productsRepositoryStore.submitOrder(pdfPayload);
      trackPdf(
        `content.button.pdf-drucken ${customerTypeMap[customerType]}`,
        pdfPayload
      );
      if (pdf) window.open(pdf, "_blank", "noopener,noreferrer");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getPdfQRCode = async () => {
    setIsLoading(true);
    try {
      const qrCodeRes = await productsRepositoryStore.submitPdfQrCode(
        pdfPayload
      );
      const parsedQrCodeData = (await qrCodeRes.json()) as QRCodeResponse;

      setQrCodeModalData({
        isOpen: true,
        urlLink: parsedQrCodeData.url,
        imageSrc: `data:image/jpg;base64,${parsedQrCodeData.qr_code}`,
      });
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log("error", e?.message);
    } finally {
      setIsLoading(false);
    }
    trackClick(`${customerTypeMap[customerType]} PDF-QR-Code`);
  };

  return (
    <PageWrapper>
      <StepHeader
        title="Ihre persönliche Empfehlung"
        subtitle="Vielen Dank für Ihr Interesse!"
      >
        <SummaryPDFButtons
          getOfferPdf={getOfferPdf}
          getPdfQRCode={getPdfQRCode}
        />
      </StepHeader>
      <PageInner>
        {configurationsExists && (
          <SummaryWrapper>
            <div>
              <SummaryMobile
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.MOBILE]}
              />
              <SummaryCards
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.CARD]}
              />
              <SummaryLandline
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.LANDLINE]}
              />
              <SummaryDevices
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.DEVICE]}
              />
            </div>
            {displayOfferTotalPrice && (
              <SummaryTotalPrice
                configurationsTotalPrice={configurations.totalPrice}
                savingsTotalValue={savingsTotalValue}
              />
            )}
          </SummaryWrapper>
        )}
        {prevContractData && (
          <SummaryPrevContractData
            prevContractData={prevContractData}
            customerPricePrefix={customerPricePrefix}
          />
        )}
        <SummaryDetails details={offerPresenterExport.details} />
      </PageInner>
      <QRCodeModal
        onClose={() =>
          setQrCodeModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        qrCodeData={qrCodeModalData}
      />
    </PageWrapper>
  );
};

export default observer(Summary);
