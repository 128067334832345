import React from "react";

type StreamingServiceItemProps = {
  isActive: boolean;
  toggleServiceActive: () => void;
  name: string;
  priceValue: string;
  customClass?: string;
};

const StreamingServiceItem = ({
  isActive,
  toggleServiceActive,
  name,
  priceValue,
  customClass = "",
}: StreamingServiceItemProps) => (
  <div className={`${customClass} streaming-services__item`}>
    <div className="streaming-services__button">
      <button
        className={`service-button ${isActive ? "service-button--active" : ""}`}
        onClick={toggleServiceActive}
      >
        {name}
      </button>
    </div>
    <div
      className={`streaming-services__price streaming-services__price--center ${
        isActive ? "" : "streaming-services__price--inactive"
      }`}
    >
      {priceValue}
    </div>
  </div>
);

export default StreamingServiceItem;
