import { Heading, List } from "@dtpk-cc/components";
import React from "react";
import * as styles from "../customer-type-selection-modal.module.scss";

type CustomerTypeSelectionModalTileDescriptionsProps = {
  descriptions: JSX.Element[];
};

const CustomerTypeSelectionModalTileDescriptions = ({
  descriptions,
}: CustomerTypeSelectionModalTileDescriptionsProps) => (
  <div className={`${styles.customerTypeTile} ${styles.description}`}>
    <List>
      {descriptions.map((descr, index) => (
        <List.Item key={index}>
          <List.Text>
            <Heading variants={[Heading.Variant.quaternary]}>{descr}</Heading>
          </List.Text>
        </List.Item>
      ))}
    </List>
  </div>
);

export default CustomerTypeSelectionModalTileDescriptions;
