import { ITariff } from "../core/entities/Product/Tariff/ITariff";
import { ConfigurationsEntity } from "../core/entities/PencilSelling/Configurations/Configurations";
import { PortfolioKeys } from "../core/entities/Product/IProduct";

export const getRelatedAddonsAreSelected = (
  tariff: ITariff,
  configurationsStore: ConfigurationsEntity
): boolean => {
  const selectedExtrasKeysMap = {};
  configurationsStore
    .getActiveConfigurationChildren()
    .getOptions(PortfolioKeys.MOBILE)
    .forEach((extra) => {
      selectedExtrasKeysMap[extra.key] = true;
    });
  return tariff.relatedAddons.some(
    (relatedExtra) => selectedExtrasKeysMap[relatedExtra]
  );
};
