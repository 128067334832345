import { useCallback, useMemo } from "react";
import { useMagentaNextCalculatorStores } from "../../stores/MagentaNext/index";
import {
  ICard,
  ICardDetail,
  ICardDetailSection,
} from "../../core/entities/Product/Card/ICard";
import { getTariffLevels } from "../../helpers/GetTariffLevels";
import { getCardQuantity } from "../../helpers/GetCardQuantity";

export const useCardsLevelChange = (
  cards: ICard[],
  changeCardQuantity: (key: string, quantity: number) => void,
  cardSectionKey: keyof ICardDetailSection
) => {
  const { tariffStore } = useMagentaNextCalculatorStores();
  const levelsArr = useMemo(() => getTariffLevels(cards), [cards]);

  const levelsNameArr = useMemo(
    () => levelsArr.map((entry) => entry.name),
    [levelsArr]
  );

  const activeCardsPointers =
    tariffStore.getCardDetailSection()[cardSectionKey];

  const defaultCard = useMemo(
    () => cards.find((card) => card.level.key === levelsArr[0].key),
    [levelsArr, cards]
  );

  const getCardMonthlyPrice = useCallback(
    (cardKey) =>
      cards.find((card) => card.key === cardKey)?.price?.monthly || 0,
    [cards]
  );

  const addCardHandler = (updateCardQuantity = true) => {
    // Get current quantity of the card with default level
    const defaultCardQuantity = getCardQuantity(defaultCard);
    const updatedActiveCardsPointers: ICardDetail[] = [
      ...activeCardsPointers,
      {
        cardKey: defaultCard.key,
        levelName: levelsNameArr[0],
        smartphoneName: "",
        smartphonePrice: "",
      },
    ];

    // MAGENTA_NEXT card edge case. Prevent quantity increase. It is already handles when MagentaNextCard Tile gets activated
    if (updateCardQuantity) {
      // set quantity
      changeCardQuantity(defaultCard.key, defaultCardQuantity + 1);
    }

    tariffStore.setCardSectionItem(cardSectionKey, updatedActiveCardsPointers);
  };

  const removeCardHandler = (currentIndex: number, key: string) => {
    const cardToReduce = cards.find((card) => card.key === key);
    // Reduce the current amount of the card
    changeCardQuantity(cardToReduce.key, cardToReduce.quantity - 1);
    const updatedActiveCardsPointers = [...activeCardsPointers];
    updatedActiveCardsPointers.splice(currentIndex, 1);
    tariffStore.setCardSectionItem(cardSectionKey, updatedActiveCardsPointers);
  };

  const updateCardPointers = (targetLevelName: string, index: number) => {
    const targetCard = cards.find(
      (card) => card.level.name === targetLevelName
    );

    const updatedActiveCardsPointers = [...activeCardsPointers];

    updatedActiveCardsPointers[index] = {
      // Save existing smartphone data
      ...updatedActiveCardsPointers[index],
      cardKey: targetCard.key,
      levelName: targetLevelName,
    };
    tariffStore.setCardSectionItem(cardSectionKey, updatedActiveCardsPointers);
  };

  const changeSmartPhoneDataHandler = (
    key: keyof ICardDetail,
    value: string | number,
    index: number
  ) => {
    const updatedActiveCardsPointers = [...activeCardsPointers];

    updatedActiveCardsPointers[index] = {
      // Save existing smartphone data
      ...updatedActiveCardsPointers[index],
      [key]: value,
    };
    tariffStore.setCardSectionItem(cardSectionKey, updatedActiveCardsPointers);
  };

  const changeLevelHandler = (
    index: number,
    targetLevelName: string,
    currentLevelName: string
  ) => {
    if (currentLevelName === targetLevelName) return;
    const currentCard = cards.find(
      (card) => card.level.name === currentLevelName
    );
    const targetCard = cards.find(
      (card) => card.level.name === targetLevelName
    );

    const currentCardQuantity = getCardQuantity(currentCard);
    const targetCardQuantity = getCardQuantity(targetCard);
    //  Reduce currentCard quantity
    const currentCardReducedQuantity =
      currentCardQuantity > 0 ? currentCardQuantity - 1 : 0;

    changeCardQuantity(currentCard.key, currentCardReducedQuantity);
    changeCardQuantity(targetCard.key, targetCardQuantity + 1);
    updateCardPointers(targetLevelName, index);
  };

  return {
    addCardHandler,
    activeCardsPointers,
    removeCardHandler,
    changeLevelHandler,
    levelsNameArr,
    changeSmartPhoneDataHandler,
    getCardMonthlyPrice,
  };
};
