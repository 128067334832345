import React from "react";
import { observer } from "mobx-react";
import TariffMainWrapper from "../../../../../components/new-design/Tariff/TariffMainWrapper";
import { useStores } from "../../../../../stores/PrivateClient";
import { useTariffSelection } from "../../../../../hooks/PencilSelling/useTariffSelection";
import { IMobileSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";
import { useMobileTariffSelection } from "../../../../../hooks/PencilSelling/useMobileTariffSelection";
import { useTariffSelectionData } from "../../../../../hooks/PencilSelling/useTariffSelectionData";
import { mobileBusinessCase } from "../../../../../core/entities/Product/BusinessClient/IBaseData";
import { ITariff } from "../../../../../core/entities/Product/Tariff/ITariff";
import BaseMobileTariffConfiguration from "../../../../../components/new-design/Tariff/TariffConfiguration/BaseMobileTariffConfiguration";
import TariffSelection from "../../../../../components/new-design/Tariff/TariffSelection";
import TariffSelectionBenefit from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import TariffSelectionHeader from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionHeader";
import WarningModal from "../../../../../components/new-design/WarningModal";
import { trackClick } from "../../../../../helpers/reactTracking";
import { SECONDARY_CARDS_RESET_DIALOG_DATA } from "../../../../../components/new-design/Tariff/const";

const TRACKING_CONTEXT = {
  main: "wunschtarif-privat",
  portfolio: "mobilfunk",
};

const MobileTariffSelection = () => {
  const {
    productsStore,
    configurationsStore,
    offerStore,
    productsRepositoryStore,
  } = useStores();

  const { tariffs } = productsStore;

  const {
    settings: mobileSettings,
    isBenefitActive,
    tariffInCart,
    activeTariff: mobileTariff,
    setActiveTariff: setMobileTariff,
    smartphoneInfos,
    setSmartphoneInfos,
    selectedPromotions: selectedMobilePromotions,
    addToCart,
    addPromotionsHandler,
    addSmartphonesHandler,
    removeTariffAddonsDialogHandler,
    customPromotions,
    warningModalIsOpen,
    setWarningModalIsOpen,
    warningModalCallback,
    warningModalText,
    dialogHandler,
  } = useTariffSelection<IMobileSettings>(
    PortfolioKeys.MOBILE,
    tariffs[PortfolioKeys.MOBILE],
    configurationsStore,
    productsStore,
    offerStore.baseOffer
  );
  const {
    getTariffFeature,
    setBenefitSettings,
    handleMobileTariffChange,
    handleMobileSettingsChange,
  } = useMobileTariffSelection({
    isBenefitActive,
    configurationsStore,
    mobileTariff,
    mobileSettings,
    setMobileTariff,
    addToCart,
    smartphoneInfos,
    tariffs: tariffs[PortfolioKeys.MOBILE],
  });
  const { businessCase } = mobileSettings;
  const {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  } = useTariffSelectionData({
    businessCase,
    isLandline: false,
    settings: mobileSettings,
    tariffs: tariffs[PortfolioKeys.MOBILE],
    businessCaseData: mobileBusinessCase.items,
  });

  const linkedSecondaryCardConfiguration =
    configurationsStore.getConfigurationByKey(
      configurationsStore.getActiveConfiguration().linkedConfigurationKey
    );

  const secondaryCardsNotExist = () =>
    !linkedSecondaryCardConfiguration.children.getCards().length;

  const resetSecondaryCards = () => {
    linkedSecondaryCardConfiguration.children.resetAllCards();
  };

  const toggleBenefit = (tariff: ITariff | null) => {
    const toggleBenefitCallback = () => {
      const IsBenefitActive =
        configurationsStore.getActiveConfigurationBenefitIsActive();

      setBenefitSettings(!IsBenefitActive, tariff);
      configurationsStore.setActiveConfigurationBenefitIsActive(
        !IsBenefitActive
      );
      addToCart(mobileTariff, selectedMobilePromotions, smartphoneInfos);
    };

    dialogHandler(
      toggleBenefitCallback,
      secondaryCardsNotExist,
      resetSecondaryCards,
      SECONDARY_CARDS_RESET_DIALOG_DATA
    );
  };

  return (
    <TariffMainWrapper>
      <BaseMobileTariffConfiguration
        withMultipleOnceMonthlyPromotions
        withCustomPromotions
        productsRepositoryStore={productsRepositoryStore}
        offerStore={offerStore.getBaseOffer()}
        customPromotions={customPromotions[PortfolioKeys.MOBILE]}
        configurationsStore={configurationsStore}
        businessCaseName={businessCaseName}
        businessCase={businessCase}
        activeTariffInCart={tariffInCart}
        activeTariff={mobileTariff}
        addPromotionsHandler={addPromotionsHandler}
        addSmartphonesHandler={addSmartphonesHandler}
        selectedPromotions={selectedMobilePromotions}
        smartphoneInfos={smartphoneInfos}
        setSmartphoneInfos={setSmartphoneInfos}
        settings={mobileSettings}
        onSettingsChange={(key, value) => {
          removeTariffAddonsDialogHandler(
            () => handleMobileSettingsChange(key, value),
            secondaryCardsNotExist,
            resetSecondaryCards
          );
        }}
        trackingContext={TRACKING_CONTEXT}
        tariffWorlds={tariffWorlds}
        levels={levels}
        activeLevelExists={activeLevelExists}
        activeLevelIndex={activeLevelIndex}
        setActiveLevelIndex={setActiveLevelIndex}
      />
      <TariffSelection
        trackingContext={TRACKING_CONTEXT}
        getTariffFeature={getTariffFeature}
        handleTariffChange={(tariff, activeTariff) => {
          removeTariffAddonsDialogHandler(
            () => handleMobileTariffChange(tariff, activeTariff),
            secondaryCardsNotExist,
            resetSecondaryCards
          );
        }}
        isBenefitActive={isBenefitActive}
        settings={mobileSettings}
        activeTariff={mobileTariff}
        tariffs={filteredTariffs}
        businessCase={businessCase}
      >
        <TariffSelectionHeader
          checkboxLabelText="Young-Tarife anzeigen"
          settings={mobileSettings}
          settingKey="isYoung"
          isChecked={mobileSettings.isYoung}
          onChange={() => {
            removeTariffAddonsDialogHandler(
              () =>
                handleMobileSettingsChange("isYoung", !mobileSettings.isYoung),
              secondaryCardsNotExist,
              resetSecondaryCards
            );
          }}
          activeTariffWorld={activeTariffWorld}
        />
        <div className="tariff-entries-bonus-wrapper">
          <TariffSelectionBenefit
            withLoyaltyBonus={false}
            isActive={isBenefitActive}
            onClick={() => toggleBenefit(mobileTariff)}
          />
        </div>
      </TariffSelection>
      <WarningModal
        onSubmit={() => {
          trackClick(
            `${TRACKING_CONTEXT.main}-extras-zurückgesetzt`,
            TRACKING_CONTEXT.portfolio
          );
          warningModalCallback();
          setWarningModalIsOpen(false);
        }}
        onCancel={() => setWarningModalIsOpen(false)}
        isOpen={warningModalIsOpen}
        mainText={warningModalText.mainText}
        titleText={warningModalText.title}
      />
    </TariffMainWrapper>
  );
};

export default observer(MobileTariffSelection);
