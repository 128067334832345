import React from "react";
import {
  LocationPointKey,
  MapSettings,
  MapSettingsSetter,
} from "views/Map/hooks/types";
import { useManageRoute } from "views/Map/hooks/useManageRoute";
import { trackClick } from "helpers/reactTracking";
import { MAP_FEATURE_CONTENT } from "views/Map/constants/const";
import MapSidebarAddress from "../MapSidebarAddressInput";
import * as styles from "./map-sidebar-route-icon.module.scss";
import MapSidebarRouteIcon from "./MapSidebarRouteIcon";
import MapSidebarRouteTransportation from "./MapSidebarRouteTransportations";

type MapSidebarRouteProps = {
  mapSettings: MapSettings;
  mapSettingsSetter: MapSettingsSetter;
};

const MapSidebarRoute = ({
  mapSettings,
  mapSettingsSetter,
}: MapSidebarRouteProps) => {
  const {
    onlyAddress,
    selectedAddressField,
    setSelectedAddressField,
    handleSetLocationPoint,
    toggleRouteStatus,
  } = useManageRoute(mapSettingsSetter);

  return (
    <div className={`${styles.routeContainer}`}>
      {!onlyAddress && (
        <MapSidebarRouteTransportation
          selectedTransportation={mapSettings.routeTransportation}
          setTransportation={mapSettingsSetter.setRouteTransportation}
        />
      )}
      <MapSidebarAddress
        id={LocationPointKey.start}
        isSelected={selectedAddressField === LocationPointKey.start}
        setLocationPoint={(point) => {
          trackClick(
            `address.${LocationPointKey.start}.${point}`,
            MAP_FEATURE_CONTENT
          );
          handleSetLocationPoint(LocationPointKey.start, point);
        }}
        selectAddressField={() => {
          trackClick(
            `address.${LocationPointKey.start}.select`,
            MAP_FEATURE_CONTENT
          );
          setSelectedAddressField(LocationPointKey.start);
        }}
      />
      {!onlyAddress && (
        <MapSidebarAddress
          id={LocationPointKey.end}
          isSelected={selectedAddressField === LocationPointKey.end}
          setLocationPoint={(point) => {
            trackClick(
              `address.${LocationPointKey.end}.${point}`,
              MAP_FEATURE_CONTENT
            );
            handleSetLocationPoint(LocationPointKey.end, point);
          }}
          selectAddressField={() => {
            trackClick(`${LocationPointKey.end}.select`, MAP_FEATURE_CONTENT);
            setSelectedAddressField(LocationPointKey.end);
          }}
        />
      )}
      <div className={`${styles.routeIconsContainer}`}>
        <MapSidebarRouteIcon
          label={onlyAddress ? "Routenplaner" : "Route beenden"}
          onClick={() => {
            trackClick(
              onlyAddress ? "Routenplaner" : "Route beenden",
              MAP_FEATURE_CONTENT
            );
            toggleRouteStatus();
          }}
        />
      </div>
    </div>
  );
};

export default MapSidebarRoute;
