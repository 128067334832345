import { IServicesEntity } from "core/entities/PencilSelling/Services/IServicesEntity";
import { ISetStreamingServiceActiveUseCase } from "./ISetStreamingServiceActive";

export class SetStreamingServiceActiveUseCase
  implements ISetStreamingServiceActiveUseCase {
  constructor(private readonly entity: IServicesEntity) {}

  execute(key: string, isActive: boolean): void {
    return this.entity.setStreamingServiceActive(key, isActive);
  }
}
