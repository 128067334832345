import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores/PrivateClient";
import { useStepActionHeaderNavMap } from "../../../../hooks/PencilSelling/useStepActionHeaderNavMap";
import { useGetActiveConfigurationDefaultName } from "../../../../hooks/PencilSelling/useGetActiveConfigurationDefaultName";
import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import LandlineTariffSelection from "./LandlineTariffSelection";
import LandlineTariffAddons from "./LandlineTariffAddons";
import PageWrapper from "../../../../elements/new-design/PageWrapper";
import StepActionHeader from "../../../../components/new-design/StepHeader/StepActionHeader";
import PageInner from "../../../../elements/new-design/PageWrapper/PageInner";
import { useRemoveConfiguration } from "../../../../hooks/PencilSelling/useRemoveConfiguration";

const TRACKING_CONTEXT = "festnetz-tarife-privatkunden";

type LandlineTariffProps = {
  displayConfigurationFlyout: (flyoutKey: string) => void;
};

const LandlineTariff = ({
  displayConfigurationFlyout,
}: LandlineTariffProps) => {
  const { configurationsStore, offerStore } = useStores();
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const { removeConfigurationHandler } = useRemoveConfiguration(
    configurationsStore,
    offerStore.getBaseOffer()
  );
  const { activeTabIndex, defaultNavMap } = useStepActionHeaderNavMap(
    TRACKING_CONTEXT,
    configurationsStore
  );
  const { activeConfigurationName } = useGetActiveConfigurationDefaultName(
    ICartConfigurationType.LANDLINE,
    configurationsStore
  );
  const businessCase = offerStore
    .getBaseOffer()
    .getBusinessCase(ICartConfigurationType.LANDLINE);

  const navTabsMap = [
    <LandlineTariffSelection />,
    <LandlineTariffAddons businessCase={businessCase} />,
  ];

  return (
    <PageWrapper>
      <StepActionHeader
        configurationsStore={configurationsStore}
        trackingContext={TRACKING_CONTEXT}
        title={activeConfigurationName}
        subtitle="Passende Lösungen für Festnetz & Internet"
        navMap={defaultNavMap}
        activeTabIndex={activeTabIndex}
        offerStore={offerStore.getBaseOffer()}
        displayConfigurationFlyout={displayConfigurationFlyout}
        removeConfigurationHandler={() =>
          removeConfigurationHandler({
            type: ICartConfigurationType.LANDLINE,
            configurationKeysToDelete: [activeConfiguration.key],
          })
        }
      />
      <PageInner>{navTabsMap[activeTabIndex]}</PageInner>
    </PageWrapper>
  );
};

export default observer(LandlineTariff);
