import React from "react";
import { observer } from "mobx-react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { Grid, Heading } from "@dtpk-cc/components";
import TileContainer from "../../TileContainer";
import { TileContainerVariants } from "../../TileContainer/ITileContainer";
import TileContainerTitle from "../../TileContainer/TileContainerTitle";
import { InputDate } from "../../../../elements/new-design/InputDate";
import { trackClick } from "../../../../helpers/reactTracking";
import { TimePicker } from "../../../../elements/new-design/TimePicker";
import { DateTimeHelper } from "../../../../helpers/DateTimeHelper";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { IConsultingTime } from "../../../../core/entities/PencilSelling/IConsultingTime";

registerLocale("de", de);
setDefaultLocale("de");

type OfferFinalizationDateTimeProps = {
  offerStore: BaseOffer;
  trackingContext: string;
  customClass?: string;
};

const OfferFinalizationDateTime = ({
  customClass = "",
  offerStore,
  trackingContext,
}: OfferFinalizationDateTimeProps) => {
  const { customer } = offerStore;
  const { consultingTime } = customer.get();
  const validUntil = offerStore.getValidUntil();

  const handleNextAppointmentChange = (
    value: Partial<IConsultingTime[keyof IConsultingTime]>,
    key: keyof IConsultingTime
  ) => {
    offerStore.customer.set({
      ...customer.get(),
      consultingTime: {
        ...consultingTime,
        [key]: value,
      },
    });
  };

  const handleDateChange = (value: Date) => offerStore.setValidUntil(value);

  return (
    <TileContainer
      customClass={`dataContainer ${customClass}`}
      variant={TileContainerVariants.outline}
    >
      <div>
        <TileContainerTitle
          variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
          title="Gültigkeit des Angebots"
        />
        <InputDate
          id="Gültigkeit des Angebots"
          selected={validUntil}
          minDate={new Date()}
          aria-labelledby="valid-until"
          dateFormat="dd.MM.yyy"
          onChange={(date) => {
            handleDateChange(date);
            trackClick("angebot-gültig-bis", trackingContext);
          }}
          aria-hidden={true}
          preventOpenOnFocus
          placeholderText="Angebot gültig bis TT.MM.JJJJ"
        />
      </div>
      <div>
        <TileContainerTitle
          variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
          title="Ihr nächster Beratungstermin"
        />
        <Grid>
          <Grid.Row gutter>
            <Grid.Col s={3} m={6} l={6} xl={12}>
              <InputDate
                id="nextAppointmentDate"
                selected={consultingTime?.nextAppointmentDate}
                minDate={new Date()}
                dateFormat="dd.MM.yyy"
                onChange={(date: Date) => {
                  handleNextAppointmentChange(date, "nextAppointmentDate");
                  trackClick("ihr-nächster-beratungstermin", trackingContext);
                }}
                aria-hidden={true}
                preventOpenOnFocus
                placeholderText="Datum"
              />
            </Grid.Col>
            <Grid.Col s={3} m={6} l={6} xl={12}>
              <TimePicker
                id="visit_time"
                placeholder="Uhrzeit"
                value={consultingTime?.nextAppointmentTime}
                minuteAriaLabel="Minuten für ihren nächsten Beratungstermin eingeben"
                hourAriaLabel="Stunde für ihren nächsten Beratungstermin eingeben"
                clearIcon={null}
                disableClock={true}
                onChange={(timeInput: string) => {
                  if (DateTimeHelper.isTimeValid(timeInput)) {
                    handleNextAppointmentChange(
                      timeInput,
                      "nextAppointmentTime"
                    );
                    trackClick("uhrzeit-eingeben", trackingContext);
                  }
                }}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </TileContainer>
  );
};

export default observer(OfferFinalizationDateTime);
