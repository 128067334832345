import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetTariffTotalPrice } from "./IGetTariffTotalPrice";

export class GetTariffTotalPrice implements IGetTariffTotalPrice {
  constructor(
    private readonly entity: ITariff,
    private readonly legacyEntity: ILegacyTariff
  ) {}

  execute(isLegacy?: boolean): number {
    return (isLegacy ? this.legacyEntity : this.entity).getTotalPrice();
  }
}
