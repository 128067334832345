import React from "react";
import { A11yText, Button, IconType } from "@dtpk-cc/components";

type CardCategoryItemControlProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  hintText: string;
  customClass?: string;
  icon: IconType;
};

const CardCategoryItemControl = ({
  onClick,
  hintText,
  customClass,
  icon,
}: CardCategoryItemControlProps) => (
  <Button
    customClass={customClass}
    variants={[Button.Variant.outline, Button.Variant.icon]}
    onClick={onClick}
  >
    <Button.Icon icon={icon} />
    <A11yText>{hintText}</A11yText>
  </Button>
);

export default CardCategoryItemControl;
