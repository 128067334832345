import type {
  IOffer,
  IMobileSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IGetMobileSettingsUseCase } from "./IGetMobileSettings";

export class GetMobileSettingsUseCase implements IGetMobileSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(): IMobileSettings {
    return this.entity.getMobileSettings();
  }
}
