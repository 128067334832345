import React from "react";
import { CopyText, Flex, Price, Container, Tooltip } from "@dtpk-cc/components";
import { humandReadableNumber } from "helpers/NumberHelpers";
import { TOTAL_SAVINGS_VALUE_DESCR } from "../../../constants/const";
import * as styles from "./price-summary.module.scss";

type PriceSummaryProps = {
  totalPrice: number;
  averagePrice: number;
  credit?: number;
  title: string;
  averagePriceTitle: string;
  className?: string;
};

export const PriceSummary = ({
  totalPrice,
  averagePrice,
  credit = 0,
  title,
  averagePriceTitle,
  className = "",
}: PriceSummaryProps) => (
  <Flex
    customClass={`${styles.priceSummary} ${className}`}
    justifyContent="space-between"
  >
    <div className={`${styles.priceSummary} ${styles.copy}`}>
      <CopyText>
        <strong>{averagePriceTitle}</strong>
      </CopyText>
      <CopyText>{title}</CopyText>
    </div>
    <div className={`${styles.priceSummary} ${styles.price}`}>
      <Container customClass={`${styles.priceSummary} ${styles.priceWrapper}`}>
        <Price
          prefix="&Oslash;"
          variants={[
            Price.Variant.brand,
            Price.Variant.highlight,
            Price.Variant.large,
          ]}
          suffix="mtl."
          value={averagePrice}
        />
      </Container>
      <Price.Text className={`${styles.priceSummary} ${styles.totalPrice}`}>
        {humandReadableNumber(totalPrice, " € mtl.")}
      </Price.Text>
      {credit ? (
        <Tooltip content="Die Ersparnis enthält den Treuebonus, MagentaEINS Vorteil und Aktionen.">
          <Price.Text
            customClass={`${styles.priceSummary} ${styles.savings}`}
            variants={Price.Text.Variant.benefit}
          >
            <span>{TOTAL_SAVINGS_VALUE_DESCR} </span>
            <span>{`${humandReadableNumber(credit, " €")}`}</span>
          </Price.Text>
        </Tooltip>
      ) : null}
    </div>
  </Flex>
);
