import React from "react";
import { List } from "@dtpk-cc/components";
import SummaryConfigurationInfoItem from "../SummaryConfigurationInfoItem";
import { IDescriptionItem } from "../../../../core/entities/Product/IDescription";

type SummaryConfigurationMainAveragePriceDescrProps = {
  customClass?: string;
  description: IDescriptionItem | null;
};

const SummaryConfigurationMainAveragePriceDescr = ({
  customClass = "",
  description,
}: SummaryConfigurationMainAveragePriceDescrProps) =>
  description && (
    <SummaryConfigurationInfoItem
      title={description.title}
      fontColor="default"
      customClass={customClass}
    >
      <List customClass="m-r-12">
        <List.Item
          dangerouslySetInnerHTML={{
            __html: description.text,
          }}
        />
      </List>
    </SummaryConfigurationInfoItem>
  );

export default SummaryConfigurationMainAveragePriceDescr;
