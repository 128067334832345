import React, { useEffect, useMemo, useRef } from "react";
import { GeoJSON, Marker, useMap } from "react-leaflet";
import { RouteDetails, Tower } from "views/Map/api/types";
import L, { LatLng } from "leaflet";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import { v4 as uuidv4 } from "uuid";
import MapLayerTowerTooltip from "../MapLayerTowerTooltip";

type MapLayerRouteProps = {
  routeDetails: RouteDetails;
  tower: Tower | null;
};

const icon = new L.Icon({
  iconUrl,
});

const MapLayerRoute = ({ routeDetails, tower }: MapLayerRouteProps) => {
  const geoJSONLayerRef = useRef(null);
  const map = useMap();

  useEffect(() => {
    if (geoJSONLayerRef.current && routeDetails) {
      map.fitBounds(geoJSONLayerRef.current.getBounds(), { duration: 250 });
    }
  }, [routeDetails, map]);

  const centerOfRoute = useMemo(() => {
    if (!routeDetails?.features?.length) return null;

    const { coordinates } = routeDetails.features[0].geometry;
    const middleIndex = Math.floor(coordinates[0].length / 2);
    const [lat, lng] = coordinates[0][middleIndex];
    return new LatLng(lng, lat);
  }, [routeDetails]);

  if (routeDetails?.features) {
    return (
      <GeoJSON ref={geoJSONLayerRef} key={uuidv4()} data={routeDetails}>
        {centerOfRoute && (
          <Marker position={centerOfRoute} opacity={0} icon={icon}>
            <MapLayerTowerTooltip tower={tower} />
          </Marker>
        )}
      </GeoJSON>
    );
  }

  return null;
};

export default MapLayerRoute;
