import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import type { IAddToCartUseCase } from "./IAddToCart";

export class AddToCartUseCase implements IAddToCartUseCase {
  constructor(private cart: ICart) {}

  execute(cartItem: ICartItemData): void {
    const isMobileTariff =
      cartItem.portfolio === PortfolioKeys.MOBILE &&
      cartItem.group === Group.tariff;
    const isLandlineTariff =
      cartItem.portfolio === PortfolioKeys.LANDLINE &&
      cartItem.group === Group.tariff;

    if (isMobileTariff) {
      this.cart.filterActiveOptionsByTariffKey(cartItem.key);
      this.cart.deleteMobileTariff();
    }
    if (isLandlineTariff) this.cart.deleteLandlineTariff();

    this.cart.add(cartItem);
  }
}
