import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IResetOfferUseCase } from "./IResetOffer";

export class ResetOfferUseCase implements IResetOfferUseCase {
  constructor(private entity: IOffer) {}

  execute(): void {
    this.entity.reset();
  }
}
