import React from "react";
import { Button } from "@dtpk-cc/components";

import * as styles from "./custom-promotions-action-block.module.scss";

type CustomPromotionsActionBlockProps = {
  cancelBtnHandler: () => void;
  submitBtnHandler?: () => void;
  wrapperCustomClass?: string;
  submitBtnIsDisabled?: boolean;
  isAddMode: boolean;
};

const CustomPromotionsActionBlock = ({
  cancelBtnHandler,
  submitBtnHandler = null,
  isAddMode,
  submitBtnIsDisabled = false,
}: CustomPromotionsActionBlockProps) => (
  <div className={styles.wrapper}>
    <Button
      customClass="m-r-12"
      variants={Button.Variant.outline}
      onClick={cancelBtnHandler}
    >
      Abbrechen
    </Button>
    <Button
      onClick={submitBtnHandler}
      type="submit"
      disabled={submitBtnIsDisabled}
      customClass={`default-button ${isAddMode ? "magenta" : "blue"}`}
      variants={Button.Variant.bare}
    >
      Speichern
    </Button>
  </div>
);

export default CustomPromotionsActionBlock;
