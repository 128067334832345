import { CardSections } from "core/entities/Product/Card/ICard";
import { roundUpValue } from "helpers/NumberHelpers";
import { useCallback, useMemo } from "react";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";

const secondaryCardsWithProvision = [
  CardSections.MAGENTA_MOBILE_DATA_CARD,
  CardSections.ADDITIONAL_CARD,
];

export const useAveragePrices = () => {
  const { legacyTariffStore, tariffStore } = useMagentaNextCalculatorStores();

  const activeMainCard =
    tariffStore.getActiveCards()[CardSections.MAGENTA_MOBILE_CARD][0];
  const totalQuantity = tariffStore.getTotalQuantity();
  const totalPrice = tariffStore.getTotalPrice();
  const totalCredit = tariffStore.getPromotionsTotalPrice();
  const settings = tariffStore.getSettings();

  const legacyCustomTotalPrice = legacyTariffStore.getCustomTotalPrice();
  const legacyCustomTotalQuantity = legacyTariffStore.getCustomTotalQuantity();

  const getSecondaryCardsProvisionFeeTotalValue = useCallback(() => {
    const activeCards = tariffStore.getActiveCards();
    let result = 0;

    secondaryCardsWithProvision.forEach((cardSectionKey) => {
      const sectionActiveCards = activeCards[cardSectionKey];

      sectionActiveCards.forEach((cardItem) => {
        const calculatedProvisionFeeValue = parseFloat(
          tariffStore
            .getValueCalculatedByPeriod(cardItem.price?.provision || 0)
            .toFixed(2)
        );

        result += calculatedProvisionFeeValue;
      });
    });

    return result;
  }, [tariffStore]);

  const averagePrice = useMemo(() => {
    if (!totalQuantity) return 0;

    let result = (totalPrice - totalCredit) / totalQuantity;

    if (
      settings.loyaltyBonusValue > 0 &&
      activeMainCard &&
      settings.hasBenefit
    ) {
      result -=
        tariffStore.getValueCalculatedByPeriod(settings.loyaltyBonusValue) /
        totalQuantity;
    }

    if (settings.hasProvisionFee && activeMainCard) {
      result +=
        tariffStore.getValueCalculatedByPeriod(activeMainCard.price.provision) /
        totalQuantity;
    }

    result += getSecondaryCardsProvisionFeeTotalValue();

    if (settings.loyaltyBonusValue > 0 || settings.hasProvisionFee) {
      result = roundUpValue(result, 2);
    }

    return result > 0 ? result : 0;
  }, [
    activeMainCard,
    settings,
    totalCredit,
    totalPrice,
    totalQuantity,
    getSecondaryCardsProvisionFeeTotalValue,
    tariffStore,
  ]);

  const legacyAveragePrice = useMemo(() => {
    if (!legacyCustomTotalQuantity) return 0;

    const result = legacyCustomTotalPrice / legacyCustomTotalQuantity;
    return result > 0 ? result : 0;
  }, [legacyCustomTotalPrice, legacyCustomTotalQuantity]);

  return {
    averagePrice,
    legacyAveragePrice,
  };
};
