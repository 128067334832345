// extracted by mini-css-extract-plugin
var _1 = "card-config-modal-module-cardConfigModal-b716r";
var _2 = "card-config-modal-module-container-V0gAQ";
var _3 = "card-config-modal-module-definitionList-lKgCX";
var _4 = "card-config-modal-module-delete-ZEpK4";
var _5 = "card-config-modal-module-description-eMomH";
var _6 = "card-config-modal-module-flex-ip99E";
var _7 = "card-config-modal-module-select-sX_uz";
var _8 = "card-config-modal-module-term-iTUfK";
export { _1 as "cardConfigModal", _2 as "container", _3 as "definitionList", _4 as "delete", _5 as "description", _6 as "flex", _7 as "select", _8 as "term" }
