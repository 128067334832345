import React from "react";
import { Accordion, Icon, Heading } from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";

import * as styles from "./summary-configuration.module.scss";

type SummaryConfigurationProps = {
  title: string;
  amount?: number;
};

const SummaryConfiguration: React.FC<SummaryConfigurationProps> = ({
  title,
  amount,
  children,
}) => (
  <Accordion customClass={styles.accordionContainer}>
    <Accordion.Item customClass={styles.accordionItem} isExpanded={true}>
      {/* Active Header */}
      <Accordion.Item.Toggle customClass={styles.header}>
        <div className={styles.rowContainer}>
          <div className={styles.headerMain}>
            <Icon
              wrapperProps={{
                className: styles.headerMainIcon,
              }}
              icon={ActionAddDefault}
              size={Icon.Size.small}
            />
            <Heading
              tag="p"
              variants={[Heading.Variant.quaternary]}
              customClass="textEllipsis"
            >
              {amount && (
                <span className={styles.headerConfigurationAmount}>
                  {amount}x
                </span>
              )}
              {title}
            </Heading>
          </div>
          <div className={styles.headerMonthly}>
            <Heading tag="p" variants={Heading.Variant.quaternary}>
              Monatlich
            </Heading>
          </div>
          <div className={styles.headerOnce}>
            <Heading tag="p" variants={Heading.Variant.quaternary}>
              Einmalig
            </Heading>
          </div>
        </div>
      </Accordion.Item.Toggle>
      {/* Main Container */}
      <Accordion.Panel>
        <div className={styles.contentContainer}>{children}</div>
      </Accordion.Panel>
    </Accordion.Item>
  </Accordion>
);

export default SummaryConfiguration;
