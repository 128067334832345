import { ICard, Volume } from "../../entities/Product/Card/ICard";
import { ITariff } from "../../entities/Product/Tariff/ITariff";

export class BaseTariffsPresenter {
  static getVolume(card: ICard | ITariff, hasBenefit: boolean): string {
    if (!card) return "";

    const volume = (hasBenefit && card.dataVolumeMagentaOne) || card.dataVolume;
    return volume === Volume.INFINITY_RAW ? Volume.INFINITY_VALUE : volume;
  }

  static overwriteVolume(
    card: ICard,
    hasBenefit: boolean,
    activeMainCard: ITariff
  ) {
    return {
      ...card,
      dataVolume: activeMainCard
        ? BaseTariffsPresenter.getVolume(activeMainCard, hasBenefit)
        : BaseTariffsPresenter.getVolume(card, hasBenefit),
    };
  }
}
