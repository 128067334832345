import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@dtpk-cc/components";
import RestartSvg from "@dtpk-cc/components/dist/icons/Action/Restart/Default";
import PrintSvg from "@dtpk-cc/components/dist/icons/Action/Print/Default";
import SketchBoard from "../index";
import { trackClick } from "../../../helpers/reactTracking";
import ModalWrapper from "../../../elements/new-design/ModalWrapper";
import SketchboardTutorial from "../SketchboardTutorial";

import * as styles from "./sketchboard-wrapper.module.scss";

type SketchBoardWrapperProps = {
  sketchboardIsActive: boolean;
  toggleSketchboard: () => void;
};

const SketchBoardWrapper = ({
  sketchboardIsActive,
  toggleSketchboard,
}: SketchBoardWrapperProps) => {
  const [tutorialModalIsOpen, setTutorialModalIsOpen] = useState(false);
  const [sketchboardIsVertical, setSketchboardIsVertical] = useState(true);
  const [sketchboardDocument, setSketchboardDocument] = useState(null);

  const getSketchboard = useCallback((sketchboard) => {
    setSketchboardDocument(sketchboard);
  }, []);
  // TODO Add buttons for sketchboard actions trigger
  const printSketchboard = useCallback(() => {
    document.body.classList.add("sketchboard-print");
    window.print();
  }, []);

  const toggleSketchboardOrientation = useCallback(
    () => setSketchboardIsVertical((prevState) => !prevState),
    []
  );

  // TODO Temporary hidden
  // const setSketchboardImageOriginSize = useCallback((tlDrawInstance) => {
  //   const createdImageId =
  //     tlDrawInstance.history[tlDrawInstance.history.length - 1].after.document
  //       .pageStates.page.selectedIds[0];
  //   const shapeInstance = tlDrawInstance.getShape(createdImageId);
  //   tlDrawInstance.setShapeProps(
  //     {
  //       ...shapeInstance,
  //       size: [shapeInstance.size[0] * 2, shapeInstance.size[1] * 2],
  //       point: [50, 50],
  //     },
  //     [createdImageId]
  //   );
  //   return tlDrawInstance;
  // }, []);
  // const addImageToSketchboard = useCallback(
  //   async (imageUrl, imageName) => {
  //     let tlDrawInstance;
  //     const file = await fetchFile(imageUrl, imageName);
  //     tlDrawInstance = await sketchboardDocument.addMediaFromFile(file);
  //     tlDrawInstance = await setSketchboardImageOriginSize(tlDrawInstance);
  //     await tlDrawInstance.selectTool("select");
  //   },
  //   [sketchboardDocument, setSketchboardImageOriginSize]
  // );

  useEffect(() => {
    let mediaQueryList: MediaQueryList;
    const eventListenerHandler = (mql) => {
      if (!mql.matches) {
        document.body.classList.remove("sketchboard-print");
      }
    };
    if (window.matchMedia && window) {
      mediaQueryList = window.matchMedia("print");
      mediaQueryList.addEventListener("change", eventListenerHandler);
    }
    return () => {
      if (window.matchMedia && window) {
        mediaQueryList.removeEventListener("change", eventListenerHandler);
      }
    };
  }, []);

  return (
    <>
      {/* Header */}
      {/* TODO Move Header inside Sketchboard component */}
      {/* <div> */}
      {/*  <Button */}
      {/*    customClass="pencil-selling__sketchboard-reset" */}
      {/*    variants={Button.Variant.outline} */}
      {/*    onClick={() => { */}
      {/*      // TODO Define the typeof sketchboard instance */}
      {/*      // eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
      {/*      sketchboardDocument?.deleteAll(); */}
      {/*      trackClick("notizblock.reset"); */}
      {/*    }} */}
      {/*  > */}
      {/*    <Button.Icon icon={RestartSvg} /> */}
      {/*    Neue Notiz */}
      {/*  </Button> */}
      {/* </div> */}
      {/* <Button */}
      {/*  variants={Button.Variant.outline} */}
      {/*  onClick={() => { */}
      {/*    printSketchboard(); */}
      {/*    trackClick("notizblock.print"); */}
      {/*  }} */}
      {/*  style={{ marginLeft: "0.375rem" }} */}
      {/* > */}
      {/*  <Button.Icon icon={PrintSvg} /> */}
      {/*  Drucken */}
      {/* </Button> */}
      <SketchBoard
        withButtons
        sketchboardDocument={sketchboardDocument}
        showSketchboardInfo={() => setTutorialModalIsOpen(true)}
        toggleSketchboardOrientation={toggleSketchboardOrientation}
        printSketchboard={printSketchboard}
        getSketchboard={getSketchboard}
        toggleSketchboard={toggleSketchboard}
        sketchboardIsActive={sketchboardIsActive}
        sketchboardIsVertical={sketchboardIsVertical}
      />
      <ModalWrapper
        containerCustomClass={`modal--high-contrast ${styles.sketchboardWrapper}`}
        onClose={() => setTutorialModalIsOpen(false)}
        isOpen={tutorialModalIsOpen}
      >
        <SketchboardTutorial customClass={styles.tutorialContainer} />
      </ModalWrapper>
    </>
  );
};

export default SketchBoardWrapper;
