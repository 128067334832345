import React, { useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import "elements/TextArea/text-area.scss";
import { getHtmlFontSize } from "../../helpers/GetHtmlFontSize";

const TextArea = ({
  text,
  placeholder,
  customClass,
  label,
  handleChange,
  onClick,
}) => {
  const textArea = useRef();
  const [hasFocus, setHasFocus] = useState(false);
  const [isLabelTop, setIsLabelTop] = useState(!!text);
  const autoGrow = () => {
    const htmlCurrentFontSize = getHtmlFontSize();
    textArea.current.style.height = `${60 / htmlCurrentFontSize}rem`;
    textArea.current.style.height = `${
      textArea.current.scrollHeight / htmlCurrentFontSize
    }rem`;
  };

  const handleOnchange = (event) => {
    handleChange(event);
  };

  useEffect(() => {
    autoGrow();
    setIsLabelTop(!!text || hasFocus);
  }, [text, hasFocus]);

  return (
    <div className="text-area__container">
      <label
        className={`
          text-area__label
          ${isLabelTop ? "text-area__label--top" : ""}
        `}
        htmlFor="wish-area"
        id="area-label"
      >
        {label}
      </label>
      <textarea
        ref={textArea}
        id="wish-area"
        value={text}
        placeholder={placeholder}
        className={`
          text-area__content
          ${customClass}
        `}
        onChange={(event) => handleOnchange(event)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        aria-labelledby="area-label"
        onClick={onClick}
      />
    </div>
  );
};

TextArea.defaultProps = {
  text: "",
  placeholder: "",
  label: "",
  customClass: "",
  handleChange: () => {},
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  handleChange: PropTypes.func,
};

export default TextArea;
