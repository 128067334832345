import React, { useMemo, useEffect } from "react";
import { Tile, Button, Grid, CopyText, Price } from "@dtpk-cc/components";
import { ActionAddDefault } from "@dtpk-cc/components/dist/icons";
import { trackClick } from "helpers/reactTracking";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { observer } from "mobx-react";
import SecondaryCardSectionRow from "../SecondaryCardSectionRow";
import {
  CardSections,
  ICard,
  ICardDetailSection,
} from "../../../core/entities/Product/Card/ICard";
import { useCardsLevelChange } from "../../../hooks/MagentaNext/useCardsLevelChange";

import * as styles from "./secondary-card-section.module.scss";

type SecondaryCardSectionProps = {
  title: string;
  cards: ICard[];
  isCardLimitReached: boolean;
  disabled?: boolean;
  dataVolume?: string;
  changeCardQuantity: any;
  isLegacyTariffVisible: boolean;
  cardSectionKey: keyof ICardDetailSection;
};

const SecondaryCardSection = ({
  title,
  cards,
  isCardLimitReached,
  disabled = false,
  dataVolume = "",
  changeCardQuantity,
  isLegacyTariffVisible,
  cardSectionKey,
}: SecondaryCardSectionProps) => {
  const { tariffStore } = useMagentaNextCalculatorStores();
  const {
    addCardHandler,
    activeCardsPointers,
    removeCardHandler,
    changeLevelHandler,
    levelsNameArr,
    changeSmartPhoneDataHandler,
    getCardMonthlyPrice,
  } = useCardsLevelChange(cards, changeCardQuantity, cardSectionKey);

  const limitCard = useMemo(
    () =>
      isCardLimitReached ? true : cards[0]?.limit <= activeCardsPointers.length,
    [isCardLimitReached, cards, activeCardsPointers]
  );

  const cardsSummaryPrice = useMemo(
    () =>
      activeCardsPointers.reduce((acc, pointer) => {
        const cardPrice =
          cards.find((card) => card.key === pointer.cardKey)?.price.monthly ||
          0;
        return acc + cardPrice;
      }, 0),
    [cards, activeCardsPointers]
  );

  useEffect(() => {
    if (disabled) {
      tariffStore.setCardSectionItem(CardSections.MAGENTA_NEXT_SECONDARY, []);
    }
  }, [disabled, tariffStore]);

  return (
    <Tile
      customClass={`${styles.secondaryCardSection} ${styles.tile} ${
        disabled ? styles.disabled : ""
      }`}
      variant="shadow"
    >
      <Tile.Content>
        <Grid>
          <Grid.Row
            customClass={`${styles.secondaryCardSection} ${styles.gridRow}`}
          >
            <Grid.Col
              m={1}
              l={1}
              xl={2}
              customClass={`${styles.secondaryCardSection} ${
                styles.buttonCol
              } ${limitCard ? styles.limited : ""}`}
            >
              <Button
                variants={[Button.Variant.outline, Button.Variant.icon]}
                disabled={disabled || limitCard}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  addCardHandler();
                  trackClick(`${title}-plus-icon`);
                }}
              >
                <Button.Icon icon={ActionAddDefault} />
              </Button>
            </Grid.Col>

            <Grid.Col m={5} l={5} xl={10}>
              <CopyText>{title}</CopyText>
            </Grid.Col>
            <Grid.Col m={2} l={2} xl={4}>
              <CopyText
                customClass={`${styles.secondaryCardSection} ${styles.dataVolume}`}
                variants={CopyText.Variant.highlight}
              >
                {dataVolume}
              </CopyText>
            </Grid.Col>
            <Grid.Col m={2} l={2} xl={4}>
              {!!cardsSummaryPrice && (
                <CopyText
                  customClass={`${styles.secondaryCardSection} ${styles.priceSummary}`}
                >
                  Summe:&nbsp;
                </CopyText>
              )}
            </Grid.Col>
            <Grid.Col
              customClass={`${styles.secondaryCardSection} ${styles.priceCol}`}
              m={2}
              l={2}
              xl={4}
            >
              {!!cardsSummaryPrice && (
                <>
                  <Price
                    variants={[Price.Variant.brand]}
                    value={cardsSummaryPrice}
                    suffix={"mtl."}
                  />
                </>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid>
          {activeCardsPointers.map((cardPointer, index) => (
            <SecondaryCardSectionRow
              key={`${cardPointer.cardKey}${cardPointer.levelName}${index}`}
              currentCardKey={cardPointer.cardKey}
              removeCardHandler={(cardKey) => removeCardHandler(index, cardKey)}
              changeLevelHandler={(targetLevel, currentLevel) =>
                changeLevelHandler(index, targetLevel, currentLevel)
              }
              levelsNameArr={levelsNameArr}
              currentLevel={cardPointer.levelName}
              monthlyPrice={getCardMonthlyPrice(cardPointer.cardKey)}
              changeSmartPhoneDataHandle={(value, key) =>
                changeSmartPhoneDataHandler(key, value, index)
              }
              isLegacyTariffVisible={isLegacyTariffVisible}
              index={index}
              smartphoneName={cardPointer.smartphoneName}
              smartphonePrice={cardPointer.smartphonePrice}
            />
          ))}
        </Grid>
      </Tile.Content>
    </Tile>
  );
};

export default observer(SecondaryCardSection);
