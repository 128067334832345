import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import {
  Container,
  Heading,
  Grid,
  Button,
  Modal as LPGModal,
  CopyText,
  Flex,
} from "@dtpk-cc/components";
import Modal from "components/Modal";
import CardsPortfolio from "components/CardsPortfolio";
import CardOfferSection from "elements/CardOfferSection";
import OfferAdvantage from "elements/OfferAdvantage";

import { trackClick } from "helpers/reactTracking";

import { CardsPresenter } from "core/presenter/PencilSelling/CardsPresenter";
import { TIMESTAMP_MARK } from "constants/const";

import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { useAddSecondaryCardToCart } from "../../../../hooks/PencilSelling/useAddSecondaryCardToCart";
import { useStores } from "../../../../stores";

import "./cards.scss";
import { ICardSettings } from "../../../../core/entities/PencilSelling/Offer_legacy/IOffer";
import { ITariff } from "../../../../core/entities/Product/Tariff/ITariff";

interface CardsProps {
  isActive: boolean;
  cardSettings: ICardSettings;
  setHasCardSection: (hasCardSection: boolean) => void;
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (isWarningModalOpen: boolean) => void;
  setToBeSelectedSetting: ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => void;
  handleRemoveTariff: () => void;
  trackingContext: string;
  checkIsXLSize: boolean;
  handleCardSettingsChange: (
    key: string,
    value: string | boolean | ITariff
  ) => void;
}

const Cards = ({
  isActive,
  cardSettings,
  setHasCardSection,
  isWarningModalOpen,
  setIsWarningModalOpen,
  setToBeSelectedSetting,
  handleRemoveTariff,
  trackingContext = "",
  checkIsXLSize,
  handleCardSettingsChange,
}: CardsProps) => {
  const { productsStore, offerStore, cartStore } = useStores();
  const cardsInCart = cartStore.getCards();
  const tabNavigation = useRef<HTMLDivElement>(null);
  const { addSecondaryCardToCart } = useAddSecondaryCardToCart();
  const cardsPresenter = new CardsPresenter(
    productsStore.getCards(),
    cardSettings.tariffWorld
  );

  const coreCardSections = cardsPresenter.getInheritingCardSection(true);
  const secondaryCardSections = cardsPresenter.getInheritingCardSection(false);
  const [activeCard, setActiveCard] = useState(null);

  const [isCardSectionModalOpen, setIsCardSectionModalOpen] = useState(false);
  const [modalCardSection, setModalCardSection] = useState({
    name: "",
    size: "",
    key: "",
    cards: [],
    dataVolume: null,
    dataVolumeMagentaOne: null,
    limit: null,
  });

  const handleDelete = (card) => {
    cartStore.delete(card.key);
  };

  const handleCardSubmit = ({
    card,
    additionalDevices,
    promotions,
    note,
    multiSim,
    businessCaseKey,
  }) => {
    const timestamp = new Date().getTime();
    addSecondaryCardToCart({
      card,
      additionalDevices,
      promotions,
      note,
      multiSim,
      businessCaseKey,
      cardSettings,
      // multiple cards of same type allowed
      cardKey: `${card.key}${TIMESTAMP_MARK}${timestamp}`,
    });
    setIsCardSectionModalOpen(false);
  };

  const cardSectionName = (section) =>
    section.size ? `${section.name} ${section.size}` : section.name;

  const isEnabled = () => !!cardSettings.tariff;
  const isMultiSimSection = (section) =>
    section.key === CardWorldKeys.magenta_mobile_multi_sim;

  const activeCardsBySection = (section) =>
    cardsInCart.filter(
      (card) =>
        card.tariffWorld.key === section.key &&
        card.cardData.size === section.size
    );

  const openCardSectionModal = (cardSection, card) => {
    setActiveCard(card);

    setModalCardSection(cardSection);
    setIsCardSectionModalOpen(true);
  };

  const toggleBenefitSetting = () => {
    handleCardSettingsChange("benefitIsActive", !cardSettings.benefitIsActive);
  };

  useEffect(() => {
    setHasCardSection(
      !!coreCardSections.length || !!secondaryCardSections.length
    );
  }, [coreCardSections, secondaryCardSections, setHasCardSection]);

  useEffect(() => {
    const dataVolume = cardSettings.benefitIsActive
      ? cardSettings.tariff?.dataVolumeMagentaOne
      : cardSettings.tariff?.dataVolume;

    offerStore.setCardSettings({
      ...offerStore.getCardSettings(),
      inheritedDataVolume: dataVolume,
    });
  }, [cardSettings.tariff, cardSettings.benefitIsActive, offerStore]);

  // reset cardsInCart, dynamic card description useEffect
  useEffect(() => {
    if (!cardSettings.tariff) {
      cartStore.resetCards();
    }

    cartStore.getCards().forEach((card) => {
      const originalSummaryText =
        productsStore
          .getCards()
          .find(
            (originalCard) =>
              originalCard.key === card.key.split(TIMESTAMP_MARK)[0]
          )?.description?.summary || "";

      const originalDescriptionText =
        productsStore
          .getCards()
          .find(
            (originalCard) =>
              originalCard.key === card.key.split(TIMESTAMP_MARK)[0]
          )?.description?.text || "";

      const summary = card.inheritingCard
        ? CardsPresenter.getCardSummary(
            originalSummaryText,
            cardSettings.tariff?.dataVolume,
            cardSettings.inheritedDataVolume,
            cardSettings.benefitIsActive
          )
        : CardsPresenter.getStaticCardSummary(
            originalSummaryText,
            card.cardData?.dataVolume,
            card.cardData?.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          );

      const text = card.inheritingCard
        ? CardsPresenter.getCardDescription(
            originalDescriptionText,
            cardSettings.tariff?.size,
            cardSettings.tariff?.dataVolume,
            cardSettings.tariff?.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          )
        : CardsPresenter.getStaticCardDescription(
            originalDescriptionText,
            card.cardData?.dataVolume,
            card.cardData?.dataVolumeMagentaOne,
            cardSettings.benefitIsActive
          );
      cartStore.updateDynamicCardDescriptions(card.key, summary, text);
    });
  }, [
    cartStore,
    productsStore,
    cardSettings.tariff,
    cardSettings.inheritedDataVolume,
    cardSettings.benefitIsActive,
  ]);

  return (
    <>
      <Container style={{ margin: "0 24px" }}>
        <OfferAdvantage
          active={cardSettings.benefitIsActive}
          onChange={toggleBenefitSetting}
        />
      </Container>
      <Container
        variants={Container.Variant.padded}
        customClass={isActive ? "card-offer card-offer--active" : "card-offer"}
      >
        <Container
          tabIndex={-1}
          customClass="card-offer__tab-navigation"
          ref={tabNavigation}
        >
          {!!coreCardSections.length && !cardSettings.isYoung && (
            <Grid.Row>
              <Heading
                tag="h2"
                variants={[Heading.Variant.tertiary]}
                style={{ textAlign: "left" }}
              >
                Zusatzkarten
              </Heading>
              {coreCardSections.map((section, index) => (
                <Grid.Col
                  key={`${section.key as string}${index}`}
                  s={6}
                  m={12}
                  l={12}
                  xl={24}
                >
                  <CardOfferSection
                    // NOTE: edgecase disable multisim if checkIsXLSize is true and vice versa
                    isMultiSimSection={isMultiSimSection(section)}
                    checkIsXLSize={checkIsXLSize}
                    sectionSize={section.size as string}
                    trackingContext={trackingContext}
                    name={cardSectionName(section)}
                    enabled={isEnabled()}
                    price={section.cards[0].price.monthly}
                    dataVolume={cardSettings.inheritedDataVolume}
                    limited={CardsPresenter.isLimited(section, cardsInCart)}
                    activeCards={activeCardsBySection(section)}
                    openCardSectionModal={(card) =>
                      openCardSectionModal(section, card)
                    }
                    onRemove={(card) => handleDelete(card)}
                  />
                </Grid.Col>
              ))}
            </Grid.Row>
          )}
          {!!secondaryCardSections.length && (
            <Grid.Row>
              <Heading
                tag="h2"
                variants={[Heading.Variant.tertiary]}
                data-qa="quality"
                style={{ textAlign: "left" }}
              >
                Weitere Zusatzkarten
              </Heading>

              {secondaryCardSections.map((section, index) => (
                <Grid.Col
                  key={`${(section.key, +index)}`}
                  s={6}
                  m={12}
                  l={12}
                  xl={24}
                >
                  <CardOfferSection
                    trackingContext={trackingContext}
                    name={cardSectionName(section)}
                    enabled
                    price={section.cards[0].price.monthly}
                    dataVolume={section.dataVolume as string}
                    limited={CardsPresenter.isLimited(section, cardsInCart)}
                    activeCards={activeCardsBySection(section)}
                    openCardSectionModal={(card) =>
                      openCardSectionModal(section, card)
                    }
                    onRemove={handleDelete}
                  />
                </Grid.Col>
              ))}
            </Grid.Row>
          )}
        </Container>

        <Modal
          close={() => {
            setIsCardSectionModalOpen(false);
            tabNavigation?.current?.focus({ preventScroll: true });
          }}
          active={isCardSectionModalOpen}
        >
          <CardsPortfolio
            trackingContext={trackingContext}
            title="Zusatzkarten konfigurieren"
            cardSectionName={cardSectionName(modalCardSection)}
            cardSection={modalCardSection}
            cardsPresenter={cardsPresenter}
            onClose={() => setIsCardSectionModalOpen(false)}
            onSubmit={handleCardSubmit}
            cardInCart={activeCard}
            onRemove={handleDelete}
          />
        </Modal>
      </Container>

      <LPGModal
        zIndex={10}
        isOpen={isWarningModalOpen}
        afterClose={() => {
          setIsWarningModalOpen(false);
          setToBeSelectedSetting(null);
        }}
      >
        <LPGModal.Section customClass="card-offer__modal-section">
          <CopyText customClass="pencil-selling__reset-copytext">
            Wichtig: Durch die Abwahl des Mobilfunktarifs werden alle
            ausgewählten Zusatzkarten entfernt.
          </CopyText>
          <CopyText customClass="pencil-selling__reset-copytext">
            Willst du wirklich fortfahren?
          </CopyText>
          <Flex justifyContent="center">
            <Button
              customClass="pencil-selling__reset-button"
              onClick={() => {
                handleRemoveTariff();
                trackClick("modal-reset.bestätigen", trackingContext);
              }}
            >
              Bestätigen
            </Button>
            <Button
              customClass="pencil-selling__reset-button"
              onClick={() => {
                setIsWarningModalOpen(false);
                setToBeSelectedSetting(null);
                trackClick("modal-reset.abbrechen", trackingContext);
              }}
              variants={Button.Variant.outline}
            >
              Abbrechen
            </Button>
          </Flex>
        </LPGModal.Section>
      </LPGModal>
    </>
  );
};

export default observer(Cards);
