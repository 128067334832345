import React from "react";
import { observer } from "mobx-react";
import Input from "../../../../../elements/new-design/Input";
import { trackClick } from "../../../../../helpers/reactTracking";
import { IStepTrackingContext } from "../../../../../views/PencilSelling/IPencilSelling";
import { ConfigurationsEntity } from "../../../../../core/entities/PencilSelling/Configurations/Configurations";

type TariffConfigurationNoteProps = {
  trackingContext: IStepTrackingContext;
  configurationsStore: ConfigurationsEntity;
};

const TariffConfigurationNote = ({
  trackingContext,
  configurationsStore,
}: TariffConfigurationNoteProps) => {
  const data = configurationsStore.getActiveConfigurationData();

  const setNoteHandler = (value: string): void => {
    configurationsStore.setActiveConfigurationNote(value);
  };

  return (
    <Input
      name={"note"}
      id={"note"}
      value={data.note}
      onChange={({ target: { value } }) => {
        setNoteHandler(value);
      }}
      placeholder="Kundenwünsche"
      onClick={() => {
        trackClick(
          `${trackingContext.portfolio}.ergänzungen`,
          trackingContext.main
        );
      }}
    />
  );
};

export default observer(TariffConfigurationNote);
