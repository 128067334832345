import React from "react";
import { observer } from "mobx-react";
import { CopyText } from "@dtpk-cc/components";
import * as stylesTariffConfiguration from "components/new-design/Tariff/TariffConfiguration/BaseMobileTariffConfiguration/tariff-configuration.module.scss";
import { ExpansionArea } from "../../../../../core/entities/Applications";
import { Bandwidths } from "../../../../../core/entities/Product/Tariff/ITariff";
import LandlineFilterIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/landline-filter.svg";
import LandlineFilterInactiveIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/landline-filter-inactive.svg";
import { ILandlineSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { trackClick } from "../../../../../helpers/reactTracking";
import TileButtonWrapper from "../../../../../elements/new-design/TileButtonWrapper";
import ToggleMainBtn, {
  ToggleMainBtnRoles,
} from "../../../../../elements/new-design/ToggleMainBtn";
import * as styles from "./landline-tariff-filter.module.scss";
import RadioButtonInput from "../../../../../elements/new-design/RadioButtonMain/RadioButtonInput";

type LandlineTariffFilterProps = {
  settings: ILandlineSettings;
  applyLandlineFilter: (filterSettings: Partial<ILandlineSettings>) => void;
  customClass?: string;
};

const TRACKING_CONTEXT = "basisdaten";

const LandlineTariffFilter = ({
  settings,
  applyLandlineFilter,
  customClass = "",
}: LandlineTariffFilterProps) => {
  const fiberToggleHandler = (fiberIsActive: boolean) => {
    applyLandlineFilter({
      bandwidth: Number(fiberIsActive ? Bandwidths.GIGA : Bandwidths.XL),
      expansionArea: ExpansionArea.telekom,
      fiber: fiberIsActive,
    });
  };

  const expansionAreaSetHandler = (expansionAreaValue: ExpansionArea) => {
    applyLandlineFilter({
      expansionArea: expansionAreaValue,
      fiber: false,
      bandwidth: Number(Bandwidths.XL),
    });
    trackClick(`${expansionAreaValue}-ausbaugebiet`, TRACKING_CONTEXT);
  };

  return (
    <div
      className={`${stylesTariffConfiguration.addonsWrapper} ${customClass}`}
    >
      <TileButtonWrapper
        customClass={`${styles.tileBtn} ${
          !settings.fiber ? styles.inactive : ""
        } ${stylesTariffConfiguration.addonsTileButton}`}
        onClick={() => {
          fiberToggleHandler(!settings.fiber);
          trackClick(
            `glasfaser ${!settings.fiber ? "Nein" : "Ja"}`,
            TRACKING_CONTEXT
          );
        }}
      >
        <ToggleMainBtn
          customClass={`${styles.toggleMainBtn}`}
          checked={settings.fiber}
          role={ToggleMainBtnRoles.div}
        />
        <div className={styles.textual}>
          {settings.fiber && (
            <LandlineFilterIcon className={styles.fiberIcon} />
          )}
          {!settings.fiber && (
            <LandlineFilterInactiveIcon className={styles.fiberIcon} />
          )}
          <CopyText variants={CopyText.Variant.additional}>
            Verfügbar an Ihrer Adresse
          </CopyText>
        </div>
      </TileButtonWrapper>
      <div className={styles.radioBtnWrapper}>
        <label
          className={`m-b-24 ${styles.radioLabel} ${
            settings.fiber ? styles.disabled : ""
          }`}
          htmlFor={ExpansionArea.telekom}
        >
          <RadioButtonInput
            value={ExpansionArea.telekom}
            name="expansionArea"
            id={ExpansionArea.telekom}
            disabled={settings.fiber}
            inputTestId={ExpansionArea.telekom}
            checked={settings.expansionArea === ExpansionArea.telekom}
            onChange={expansionAreaSetHandler}
          />
          <CopyText
            customClass="m-t-0 m-b-0 m-l-12"
            variants={
              settings.expansionArea === ExpansionArea.telekom
                ? CopyText.Variant.highlight
                : null
            }
          >
            Telekom Ausbaugebiet
          </CopyText>
        </label>
        <label
          className={`${styles.radioLabel} ${
            settings.fiber ? styles.disabled : ""
          }`}
          htmlFor={ExpansionArea.regio}
        >
          <RadioButtonInput
            value={ExpansionArea.regio}
            name="expansionArea"
            id={ExpansionArea.regio}
            disabled={settings.fiber}
            inputTestId={ExpansionArea.regio}
            checked={settings.expansionArea === ExpansionArea.regio}
            onChange={expansionAreaSetHandler}
          />
          <CopyText
            customClass="m-t-0 m-b-0 m-l-12"
            variants={
              settings.expansionArea === ExpansionArea.regio
                ? CopyText.Variant.highlight
                : null
            }
          >
            Regio Ausbaugebiet
          </CopyText>
        </label>
      </div>
    </div>
  );
};

export default observer(LandlineTariffFilter);
