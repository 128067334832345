import { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { ISetActiveAdditionCard } from "./ISetActiveAdditionCard";

export class SetActiveAdditionCard implements ISetActiveAdditionCard {
  constructor(private entity: ITariff) {}

  execute(cardKey?: string): void {
    this.entity.getFamilyCards(true).forEach(({ key }) => {
      this.entity.setQuantity(key, 0);
    });

    this.entity.getMagentaNextCard(true).forEach(({ key }) => {
      this.entity.setQuantity(key, 0);
    });

    if (cardKey) this.entity.setQuantity(cardKey, 1);
  }
}
