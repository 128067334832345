export const fetchFile = async (
  url: string,
  name = "default",
  fileType = "image/png"
): Promise<File> => {
  const fileExtensionInstance: RegExpMatchArray = url.match(/\.[0-9a-z]+$/i);
  const fileName = `${name}${fileExtensionInstance[0].toLowerCase()}`;
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], fileName, {
    type: data.type || fileType,
  });
};
