import React from "react";
import { observer } from "mobx-react";
import { CopyText, Button, TextLink } from "@dtpk-cc/components";

import { trackClick } from "helpers/reactTracking";
import {
  PAGE_LOGIN,
  PAGE_USER_PROFILE,
  PAGE_ADMIN_GUI,
} from "routes/extern-url";
import ProfileIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/profile/profile.svg";
import AdminGuiIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/profile/admin-gui.svg";
import LogoutIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/profile/logout.svg";

import * as styles from "./profile-menu.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../../core/repositories/ProductsRepository/ProductsRepository";

const TRACKING_CONTEXT = "profilmenü";

type ProfileMenuProps = {
  onClick: () => void;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
};

const ProfileMenu = ({
  onClick,
  offerStore,
  productsRepositoryStore,
}: ProfileMenuProps) => {
  const { setIsLoading } = offerStore;
  const logoutHandler = async () => {
    setIsLoading(true);
    try {
      await productsRepositoryStore.logout();
      window.location.href = PAGE_LOGIN;
    } catch (e) {
      onClick();
      setIsLoading(false);
    }
  };

  return (
    <div className={`p-12 ${styles.profile}`}>
      <TextLink
        tabIndex={0}
        target="_blank"
        rel="noopener noreferrer"
        href={`${window.location.origin}/${PAGE_USER_PROFILE}`}
        className={`p-6 ${styles.profileItem}`}
        onClick={() => {
          trackClick("profil", TRACKING_CONTEXT);
          onClick();
        }}
      >
        <div className={`m-r-16 ${styles.iconContainer}`}>
          <ProfileIcon className={styles.icon} />
        </div>
        <CopyText customClass={styles.title}>Profil</CopyText>
      </TextLink>
      <TextLink
        tabIndex={0}
        target="_blank"
        rel="noopener noreferrer"
        href={`${window.location.origin}/${PAGE_ADMIN_GUI}`}
        className={`p-6 ${styles.profileItem}`}
        onClick={() => {
          trackClick("backend", TRACKING_CONTEXT);
          onClick();
        }}
      >
        <div className={`m-r-16 ${styles.iconContainer}`}>
          <AdminGuiIcon className={styles.icon} />
        </div>
        <CopyText customClass={styles.title}>Backend</CopyText>
      </TextLink>
      <Button
        onClick={async () => {
          trackClick("ausloggen", TRACKING_CONTEXT);
          await logoutHandler();
        }}
        variants={[Button.Variant.bare]}
        customClass={`p-6 ${styles.profileItem}`}
        tabIndex={0}
      >
        <div className={`m-r-16 ${styles.iconContainer}`}>
          <LogoutIcon className={styles.icon} />
        </div>
        <CopyText customClass={styles.title}>Ausloggen</CopyText>
      </Button>
    </div>
  );
};

export default observer(ProfileMenu);
