import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import type { IResetCartUseCase } from "./IResetCart";

export class ResetCartUseCase implements IResetCartUseCase {
  constructor(private cart: ICart) {}

  execute(portfolio?: PortfolioKeys): void {
    if (!portfolio || portfolio === PortfolioKeys.LANDLINE) {
      this.cart.resetLandline();
    }
    if (!portfolio || portfolio === PortfolioKeys.MOBILE)
      this.cart.resetMobile();
  }
}
