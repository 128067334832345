import React from "react";
import { Heading, CopyText, Price } from "@dtpk-cc/components";
import { ISummaryPrevContractData } from "../../../../core/presenter/PencilSelling/PrivateClient/PrivateClientOfferSummaryPresenter";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import * as styles from "./summary-prev-contract-data.module.scss";

const SummaryPrevContractData = ({
  prevContractData,
  customerPricePrefix,
}: {
  prevContractData: ISummaryPrevContractData;
  customerPricePrefix: string;
}) => (
  <SummarySectionItemWrapper customClass={styles.wrapper}>
    <SummarySectionItemWrapper.Main>
      <Heading
        tag="p"
        customClass="m-0"
        variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
      >
        {prevContractData.title}
      </Heading>
      <CopyText customClass="m-0">{prevContractData.subtitle}</CopyText>
    </SummarySectionItemWrapper.Main>
    <SummarySectionItemWrapper.Monthly>
      <Heading tag="p" variants={[Heading.Variant.quaternary]}>
        Monatlich
      </Heading>
      <Price
        variants={[
          Price.Variant.currentColor,
          Price.Variant.large,
          Price.Variant.highlight,
        ]}
        value={+prevContractData.monthlyPrice.replace(",", ".")}
        suffix={customerPricePrefix}
      />
    </SummarySectionItemWrapper.Monthly>
  </SummarySectionItemWrapper>
);

export default SummaryPrevContractData;
