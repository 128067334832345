import React from "react";
import { Grid, Heading, Price, List } from "@dtpk-cc/components";

const MultiSimSummary = ({ price, quantity }) => (
  <>
    <Grid.Col s={3} m={7} l={7} xl={14} style={{ marginTop: "1rem" }}>
      <Heading tag="h3" variants={Heading.Variant.tertiary}>
        MultiSIM
      </Heading>
    </Grid.Col>

    <Grid.Col s={2} m={3} l={3} xl={6} style={{ marginTop: "1rem" }}>
      <Price
        variants={[Price.Variant.brand, Price.Variant.large]}
        value={price * quantity}
      />
    </Grid.Col>

    <Grid.Col s={1} m={2} l={2} xl={4}></Grid.Col>

    <Grid.Col s={6} m={12} l={12} xl={24} customClass="summary__list-column">
      <List>
        <List.Item>
          <List.Text>
            {`${quantity} x MultiSIM für je ${price} € mtl.`}
          </List.Text>
        </List.Item>
      </List>
    </Grid.Col>
  </>
);

export default MultiSimSummary;
