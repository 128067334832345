import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { CopyText } from "@dtpk-cc/components";
import { useStores } from "../../../../stores/PrivateClient";
import StepHeader from "../../../../components/new-design/StepHeader";
import PageWrapper from "../../../../elements/new-design/PageWrapper";
import PageInner from "../../../../elements/new-design/PageWrapper/PageInner";
import SecondaryCardsSelection from "./SecondaryCardsSelection";
import NotifyHintBox from "../../../../elements/new-design/NotifyHintBox";

import * as styles from "./secondary-cards.module.scss";
import { useGetActiveConfigurationDefaultName } from "../../../../hooks/PencilSelling/useGetActiveConfigurationDefaultName";
import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { getConfigurationName } from "../../../../helpers/GetConfigurationName";
import { CardsPresenter } from "../../../../core/presenter/PencilSelling/CardsPresenter";

const TRACKING_CONTEXT = "zusatzkarten-privatkunden";

const SecondaryCards = () => {
  const { configurationsStore, productsStore } = useStores();
  const linkedConfigurationKey =
    configurationsStore.getActiveConfigurationLinkedConfigurationKey();
  const linkedConfiguration = configurationsStore.getConfigurationByKey(
    linkedConfigurationKey || ""
  );
  const linkedConfigurationTariff =
    linkedConfiguration.children.getMobileTariff();
  const { activeConfigurationName, activeConfigurationIndex } =
    useGetActiveConfigurationDefaultName(
      ICartConfigurationType.CARD,
      configurationsStore
    );
  const cardsPresenter = new CardsPresenter(
    productsStore.getCards(),
    linkedConfigurationTariff?.tariffWorld?.key || ""
  );
  const coreCardSections = cardsPresenter.getInheritingCardSection(true);
  const secondaryCardSections = cardsPresenter.getInheritingCardSection(false);
  const noCardsAreAvailable = useMemo(
    () => !coreCardSections.length && !secondaryCardSections.length,
    [coreCardSections.length, secondaryCardSections.length]
  );

  return (
    <PageWrapper>
      <StepHeader
        title={activeConfigurationName}
        subtitle={getConfigurationName(
          linkedConfiguration,
          // Since mobile and secondary cards configurations are synchronized in array order ->
          // we're good to set as index the index of active one.
          activeConfigurationIndex,
          ICartConfigurationType.MOBILE
        )}
      />
      <PageInner
        customClass="m-b-36"
      >
        {linkedConfigurationTariff &&
          !!(coreCardSections.length || secondaryCardSections.length) && (
            <SecondaryCardsSelection
              trackingContext={TRACKING_CONTEXT}
              linkedConfiguration={linkedConfiguration}
              coreCardSections={coreCardSections}
              secondaryCardSections={secondaryCardSections}
              cardsPresenter={cardsPresenter}
            />
          )}
        {(!linkedConfigurationTariff || noCardsAreAvailable) && (
          <NotifyHintBox customClass={styles.notifyBoxWrapper}>
            <CopyText>
              {!linkedConfigurationTariff &&
                "Bitte wähle einen Mobilfunktarif aus, um die dazu passenden Zusatzkarten mit entsprechenden Datenvolumen zu sehen und konfigurieren zu können."}
              {linkedConfigurationTariff &&
                !!noCardsAreAvailable &&
                "Für diesen Tarif gibt es zur Zeit leider keine verfügbaren Zusatzkarten."}
            </CopyText>
          </NotifyHintBox>
        )}
      </PageInner>
    </PageWrapper>
  );
};

export default observer(SecondaryCards);
