import type {
  IOffer,
  ILandlineSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IGetLandlineSettingsUseCase } from "./IGetLandlineSettings";

export class GetLandlineSettingsUseCase implements IGetLandlineSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(): ILandlineSettings {
    return this.entity.getLandlineSettings();
  }
}
