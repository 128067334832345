import React, { useState } from "react";
import { trackClick } from "helpers/reactTracking";
import { Modal as LPGModal, CopyText } from "@dtpk-cc/components";

import Modal from "components/Modal";
import TileItem from "elements/TileItem";

import "./grouped-products-modal.scss";

const GroupedProductsModal = ({
  headline,
  activeProducts = [],
  isOpen,
  closeModal,
  title,
  description,
  groupedProducts,
  onSave,
  getAdditionalPriceSuffix,
}) => {
  const activeProductsKeys = activeProducts.map((item) => item.key);
  const [activeProduct, setActiveProduct] = useState(
    groupedProducts.find((item) => activeProductsKeys.includes(item.key))
  );

  return (
    <Modal
      active={isOpen}
      showSubmitButton
      close={() => closeModal()}
      submitButtonDisabled={!activeProduct}
      handleSubmitButton={() => {
        onSave(activeProduct);
        trackClick(`${activeProduct.name}.auswahl-übernehmen`, headline);
      }}
    >
      <CopyText customClass="grouped-products-modal__copy">
        {description}
      </CopyText>
      <LPGModal.Heading>{title}</LPGModal.Heading>

      <div
        className={`
          grouped-products-modal__container
          ${
            groupedProducts.length <= 2
              ? "grouped-products-modal__container--center"
              : ""
          }
        `}
      >
        {groupedProducts.map((product) => (
          <TileItem
            active={activeProduct?.key === product.key}
            description={product.additionalDescription}
            key={product.key}
            item={product}
            price={product.price}
            additionalPriceSuffix={getAdditionalPriceSuffix(product)}
            onClick={() => {
              setActiveProduct(product);
              trackClick(`${product.name}`, headline);
            }}
          />
        ))}
      </div>
    </Modal>
  );
};

export default GroupedProductsModal;
