// extracted by mini-css-extract-plugin
var _1 = "configurator-device-item-module-amountValue-7pmvd";
var _2 = "configurator-device-item-module-amountWrapper-DYZba";
var _3 = "configurator-device-item-module-changeAmountBtn-T4bFQ";
var _4 = "configurator-device-item-module-changeAmountBtnIcon-aj6K4";
var _5 = "configurator-device-item-module-changeAmountBtnIncreaseIcon-T0PgF";
var _6 = "configurator-device-item-module-changeAmountBtnReduceIcon-uT7vg";
var _7 = "configurator-device-item-module-configuratorDeviceItem-jGwCs";
var _8 = "configurator-device-item-module-configuratorDeviceItemContent-hQm51";
var _9 = "configurator-device-item-module-configuratorDeviceItemTitle-vUBnP";
var _a = "configurator-device-item-module-content-oiXkI";
var _b = "configurator-device-item-module-data-hXIkF";
var _c = "configurator-device-item-module-dataPrice-KRD66";
var _d = "configurator-device-item-module-img-HcXAi";
var _e = "configurator-device-item-module-link-gASRC";
var _f = "configurator-device-item-module-subtitle-CnkVi";
var _10 = "configurator-device-item-module-textual-oPqCO";
export { _1 as "amountValue", _2 as "amountWrapper", _3 as "changeAmountBtn", _4 as "changeAmountBtnIcon", _5 as "changeAmountBtnIncreaseIcon", _6 as "changeAmountBtnReduceIcon", _7 as "configuratorDeviceItem", _8 as "configuratorDeviceItemContent", _9 as "configuratorDeviceItemTitle", _a as "content", _b as "data", _c as "dataPrice", _d as "img", _e as "link", _f as "subtitle", _10 as "textual" }
