import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { IGetOfferValidUntilUseCase } from "./IGetOfferValidUntil";

export class GetOfferValidUntilUseCase implements IGetOfferValidUntilUseCase {
  constructor(private readonly entity: IOffer) {}

  execute(): Date {
    return this.entity.getValidUntil();
  }
}
