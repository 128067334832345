import React from "react";
import { Heading } from "@dtpk-cc/components";
import TileContainer from "../../../TileContainer";

import * as styles from "./tariff-conifguration-wrapper.module.scss";

type TariffConfigurationWrapperProps = Partial<{
  customClass: string;
  title: string;
}>;

const TariffConfigurationWrapper: React.FC<TariffConfigurationWrapperProps> = ({
  children,
  customClass = "",
  title = "Konfiguration",
}) => (
  <TileContainer
    customClass={`${styles.tariffConfigurationWrapper} ${customClass}`}
  >
    <Heading
      customClass={styles.title}
      tag="h3"
      variants={[Heading.Variant.secondary]}
    >
      {title}
    </Heading>
    {children}
  </TileContainer>
);

export default TariffConfigurationWrapper;
