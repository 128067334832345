import React from "react";
import { CopyText } from "@dtpk-cc/components";
import * as styles from "./tooltip.module.scss";

type TooltipProps = {
  customClass: string;
};

const Tooltip: React.FC<TooltipProps> = ({ customClass = "", children }) => (
  <div className={`${styles.tooltip} ${customClass}`}>
    <CopyText
      variants={[CopyText.Variant.additional, CopyText.Variant.highlight]}
    >
      {children}
    </CopyText>
  </div>
);

export default Tooltip;
