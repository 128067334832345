import React, { useState } from "react";
import {
  IBusinessCasesTypeValues,
  ITariff,
} from "core/entities/Product/Tariff/ITariff";
import AddPromotionIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/add-promotion.svg";
import { IStepTrackingContext } from "../../../../../views/PencilSelling/IPencilSelling";
import { trackClick } from "../../../../../helpers/reactTracking";
import {
  ICartItemData,
  ICartItemDataPromotions,
} from "../../../../../core/entities/PencilSelling/CartItem/ICartItem";

import * as stylesTariffConfiguration from "../BaseMobileTariffConfiguration/tariff-configuration.module.scss";
import TariffConfigurationAddons from "../TariffConfigurationAddons";
import PromotionSelection from "../../../PromotionSelection";
import { ICustomPromotion } from "../../../../../core/entities/PencilSelling/ICustomPromotion";
import { getPromotionsFilteredByBusinessCase } from "../../../../../helpers/GetPromotionsFilteredByBusinessCase";
import { BaseOffer } from "../../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../../../core/repositories/ProductsRepository/ProductsRepository";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";
import TariffConfigurationTileButton from "../TariffConfigurationTileButton";
import { getPromotionsName } from "../../../../../helpers/GetPromotionsName";

type TariffConfigurationPromotionsProps = {
  trackingContext: IStepTrackingContext;
  businessCaseName: string;
  businessCase: IBusinessCasesTypeValues;
  tariffInCart: ICartItemData | undefined;
  activeTariff: ITariff | null;
  maxItemsDisplayAmount: number;
  customClass?: string;
  selectedPromotions: ICartItemDataPromotions;
  addPromotionsHandler: (promotions: ICartItemDataPromotions) => void;
  customPromotions?: ICustomPromotion[];
  withCustomPromotions?: boolean;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  portfolioKey: PortfolioKeys;
  withMultipleOnceMonthlyPromotions: boolean;
};

const TariffConfigurationPromotions = ({
  trackingContext,
  businessCaseName,
  businessCase,
  tariffInCart,
  activeTariff,
  customClass = "",
  maxItemsDisplayAmount,
  selectedPromotions,
  addPromotionsHandler,
  customPromotions = [],
  withCustomPromotions = false,
  offerStore,
  productsRepositoryStore,
  portfolioKey,
  withMultipleOnceMonthlyPromotions = true,
}: TariffConfigurationPromotionsProps) => {
  const [promotionsModalIsOpen, setPromotionsModalIsOpen] = useState(false);

  const filteredTariffPromotionsByCurrentBusinessCase =
    getPromotionsFilteredByBusinessCase(activeTariff, businessCase);

  const promotionsOpenModalHandler = () => {
    setPromotionsModalIsOpen(true);
    trackClick(
      `${trackingContext.portfolio}.modales.aktionen.geöffnet`,
      trackingContext.main
    );
  };

  const promotionTileButtonIsDisabled =
    withCustomPromotions && activeTariff
      ? false
      : !filteredTariffPromotionsByCurrentBusinessCase.length;
  const promotionsNamesArr = getPromotionsName(selectedPromotions);

  return (
    <div
      className={`${stylesTariffConfiguration.addonsWrapper} ${customClass}`}
    >
      <TariffConfigurationTileButton
        disabled={promotionTileButtonIsDisabled}
        customClass={stylesTariffConfiguration.addonsTileButton}
        Icon={AddPromotionIcon}
        title="Aktion hinzufügen"
        subtitle="Angebote und Preisvorteile"
        onClick={promotionsOpenModalHandler}
      />
      <TariffConfigurationAddons
        maxItemsDisplayAmount={maxItemsDisplayAmount}
        addons={promotionsNamesArr}
        actionBtnText="Alle Aktionen anzeigen"
        openModalHandler={promotionsOpenModalHandler}
      />
      {/* To reset local state */}
      {promotionsModalIsOpen && (
        <PromotionSelection
          trackingContext={trackingContext}
          businessCaseName={businessCaseName}
          productInCartName={tariffInCart?.name}
          activeProductItem={activeTariff}
          selectedPromotions={selectedPromotions}
          customPromotions={customPromotions}
          promotions={filteredTariffPromotionsByCurrentBusinessCase}
          setPromotionsModalIsOpen={setPromotionsModalIsOpen}
          promotionsModalIsOpen={promotionsModalIsOpen}
          addPromotionsHandler={addPromotionsHandler}
          withCustomPromotions={withCustomPromotions}
          offerStore={offerStore}
          productsRepositoryStore={productsRepositoryStore}
          portfolioKey={portfolioKey}
          withMultipleOnceMonthlyPromotions={withMultipleOnceMonthlyPromotions}
        />
      )}
    </div>
  );
};

export default TariffConfigurationPromotions;
