import React from "react";
import { Heading, CopyText } from "@dtpk-cc/components";
import ModalWrapper from "elements/new-design/ModalWrapper";

import ModalWrapperActionFooter from "elements/new-design/ModalWrapper/ModalWrapperActionFooter";
import * as styles from "./warning-modal.module.scss";

type WarningModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
  mainText: string;
  titleText: string;
  submitBtnText?: string;
  cancelBtnText?: string;
};

const WarningModal: React.FC<WarningModalProps> = ({
  onSubmit,
  onCancel,
  isOpen,
  mainText,
  titleText = "",
  submitBtnText = "Bestätigen",
  cancelBtnText = "Abbrechen",
}) => (
  <ModalWrapper
    isOpen={isOpen}
    onClose={onCancel}
    containerCustomClass={styles.warningModal}
    onBackDropClick={onCancel}
  >
    <Heading
      tag="h3"
      customClass="m-b-24"
      variants={[Heading.Variant.tertiary, Heading.Variant.display]}
    >
      {titleText}
    </Heading>
    <CopyText customClass="m-b-24">{mainText}</CopyText>
    <CopyText>Willst du wirklich fortfahren?</CopyText>
    <ModalWrapperActionFooter
      onSubmit={onSubmit}
      onCancel={onCancel}
      cancelBtnText={cancelBtnText}
      submitBtnText={submitBtnText}
    />
  </ModalWrapper>
);

export default WarningModal;
