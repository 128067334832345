import { useEffect, useState, useCallback } from "react";

const useResize = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener("resize", callback);
    callback();
    return () => window.removeEventListener("resize", callback);
  }, [callback]);
};

export const useWindowWidth = () => {
  const [width, setWidth] = useState<number>(0);

  const updateWidth = useCallback(() => {
    setWidth(document.documentElement.clientWidth);
  }, []);

  useResize(updateWidth);

  return width;
};
