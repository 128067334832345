export const TABS_INDEXES = {
  personal: 1,
  tariff: 2,
  secondaryCards: 3,
  devices: 4,
  optionsLandline: 5,
  optionsMobile: 6,
  offerFinalization: 7,
  summary: 8,
};

export const QUERY_PARAMS_MAP = {
  hnpOpened: {
    key: "hnp",
    value: "opened",
  },
};
