import React, { ElementType } from "react";
import { Button, Heading, CopyText } from "@dtpk-cc/components";
import * as tileStyles from "../../navigation-flyout-tile-item.module.scss";

type ConfigurationFlyoutItemProps = {
  onClick: () => void;
  isActive: boolean;
  name: string;
  subtitle?: string;
  Icon: ElementType | string;
  customClass?: string;
};

const ConfigurationFlyoutItem: React.FC<ConfigurationFlyoutItemProps> = ({
  onClick,
  isActive,
  name,
  Icon,
  subtitle,
  children,
  customClass = "",
}) => (
  <Button
    tabIndex={0}
    onClick={onClick}
    variants={Button.Variant.bare}
    customClass={`m-12 py-24 px-20 ${isActive ? tileStyles.active : ""}  ${
      tileStyles.navigationFlyoutTileItem
    } ${customClass}`}
  >
    {children}
    <div className={tileStyles.navigationFlyoutTileItemContainer}>
      <Icon className="m-b-12" />
    </div>
    <Heading
      customClass={`${tileStyles.navigationFlyoutTileItemText} textEllipsis`}
      tag="p"
      variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
    >
      {name}
    </Heading>
    {subtitle && (
      <CopyText
        customClass={`${tileStyles.navigationFlyoutTileItemText} textEllipsis`}
      >
        {subtitle}
      </CopyText>
    )}
  </Button>
);

export default ConfigurationFlyoutItem;
