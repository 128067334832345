export class DateTimeHelper {
  static currentDisplayDate(startDate = new Date()): string {
    const day = String(startDate.getDate()).padStart(2, "0");
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = startDate.getFullYear();

    return `${day}.${month}.${year}`;
  }

  static isTimeValid(value: string): boolean {
    return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
  }
}
