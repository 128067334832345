import { CustomerType } from "../../core/entities/PencilSelling/Customer/ICustomer";
import {
  ICardSettings,
  ILandlineSettings,
  IMobileSettings,
  IBusinessClientsCardSettings,
} from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  initalMobileSettings,
  initialLandlineSettings,
  initialBusinessClientsCardSettings,
  initialBusinessClientsLandlineSettings,
  initialBusinessClientsMobileSettings,
} from "../../core/entities/PencilSelling/Configurations/const";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";

export type DefaultSettingsMap = {
  landlineSettings: ILandlineSettings;
  mobileSettings: IMobileSettings;
  cardSettings: IBusinessClientsCardSettings | ICardSettings;
};

const defaultSettingsMap: {
  [key in CustomerType]: DefaultSettingsMap;
} = {
  [CustomerType.EMPLOYEE]: {
    landlineSettings: initialLandlineSettings,
    mobileSettings: initialBusinessClientsMobileSettings,
    cardSettings: initialBusinessClientsCardSettings,
  },
  [CustomerType.BUSINESS]: {
    landlineSettings: initialBusinessClientsLandlineSettings,
    mobileSettings: initialBusinessClientsMobileSettings,
    cardSettings: initialBusinessClientsCardSettings,
  },
  [CustomerType.PRIVATE]: {
    landlineSettings: initialLandlineSettings,
    mobileSettings: initalMobileSettings,
    cardSettings: null,
  },
};

export const useGetDefaultSettings = (
  offerStore: BaseOffer
): DefaultSettingsMap =>
  defaultSettingsMap[offerStore.customer.get().customerType];
