export const TIMESTAMP_MARK = "&&";
export const DIVE_LOCAL_STORAGE_PREFIX = "DIVE:";
export const MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY = `${DIVE_LOCAL_STORAGE_PREFIX}CalculatorConfig`;
export const FACT_MODALS_DATA_LOCAL_STORAGE_KEY = `${DIVE_LOCAL_STORAGE_PREFIX}FactModal`;
export const LOYALTY_BONUS_TEXT =
  "Der Treuebonus wird auf der Mobilfunk-Rechnung gutgeschrieben und reduziert die monatlichen und einmaligen Entgelte aller Leistungen der Telekom Deutschland GmbH des neu abgeschlossenen Hauptvertrages (Auszahlung ausgeschlossen)";

export const HAPPY_HOUR_BENEFIT_OPTION_TARIFF_TILE_TEXT = "Mobile Happy Hour";
export const HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION = {
  title: "Mobile Happy Hour",
  text: "Mit der Mobile Happy Hour täglich 1 Stunde in Deutschland und der EU, der Schweiz und Großbritannien kostenlos surfen. <br/> <b>Mit einem MagentaTV Tarif sogar täglich 2x 1 Stunde!</b> <br/> Zur Aktivierung einfach die <a rel=\"noopener noreferrer\" target=\"_blank\" href=\"https://www.telekom.de/apps/meinmagenta-app?samChecked=true\">MeinMagentaApp</a> herunterladen.",
};

export const FALLBACK_APP_LINK = "https://www.telekom.de/meinmagenta-start";

export const SMARTPHONES_MANUFACTURERS = [
  "Apple",
  "Apple iPhone",
  "Fairphone",
  "Google Pixel",
  "Nothing Phone",
  "Samsung Galaxy",
  "Telekom T Phone",
  "Telekom T Tablet",
  "Xiaomi",
  "Xiaomi Redmi",
  "Xplora",
];

export const PRODUCT_DEFAULT_CONTRACT_PERIOD = 24;

export const SECONDARY_CARDS_AVERAGE_PRICE_TITLE =
  "rechnerischer 2-Jahres-Preis pro Karte";

export const TOTAL_SAVINGS_VALUE_DESCR = "Ersparnis über 24 Monate:";

export const AMOUNT_PICKER_MAX_VALUE = 999;
