import { makeAutoObservable } from "mobx";

import type { IServicesEntity } from "./IServicesEntity";
import type { IStreamingService } from "../../Product/IStreamingService";
import type { IStreamingVariant } from "../../Product/IStreamingVariant";

export class ServicesEntity implements IServicesEntity {
  private streamingServices: IStreamingService[] = [];

  private streamingVariants: IStreamingVariant[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getStreamingServices(): IStreamingService[] {
    return this.streamingServices;
  }

  getStreamingVariants(): IStreamingVariant[] {
    return this.streamingVariants;
  }

  setStreamingServiceActive(key: string, isActive: boolean): void {
    const item = this.streamingServices.find((service) => service.key === key);
    if (item) item.active = isActive;
    this.streamingServices = [...this.streamingServices];
  }

  getStreamingServicesPrice(): number {
    return this.streamingServices.reduce(
      (acc, { active, price }) => (active ? acc + (price.monthly || 0) : acc),
      0
    );
  }

  setup(
    streamingServices: IStreamingService[],
    streamingVariants: IStreamingVariant[]
  ): void {
    this.streamingServices = streamingServices;
    this.streamingVariants = streamingVariants;
  }
}
