import { PortfolioKeys } from "core/entities/Product/IProduct";
import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import type { IGetTariffsUseCase, TariffExport } from "./IGetTariffs";

export class GetTariffsUseCase implements IGetTariffsUseCase {
  constructor(private readonly entity: IProductsEntity) {}

  execute(): TariffExport {
    return {
      [PortfolioKeys.LANDLINE]: this.entity.getLandlineTariffs(),
      [PortfolioKeys.MOBILE]: this.entity.getMobileTariffs(),
    };
  }
}
