import React from "react";

import * as styles from "./tariff-main-wrapper.module.scss";

type TariffMainWrapperProps = {
  customClass?: string;
};

const TariffMainWrapper: React.FC<TariffMainWrapperProps> = ({
  customClass = "",
  children,
}) => (
  <div className={`${styles.tariffMainWrapper} ${customClass}`}>{children}</div>
);

export default TariffMainWrapper;
