import type { IBenefitConfig } from "core/entities/Product/IBenefit";
import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetBenefitUseCase } from "./ISetBenefit";

export class SetBenefitUseCase implements ISetBenefitUseCase {
  constructor(private entity: IOffer) {}

  execute(config: IBenefitConfig): void {
    this.entity.setTariffBenefits(config);
  }
}
