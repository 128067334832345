import { useEffect, useState } from "react";
import type { FeatureCollection } from "geojson";
import { MapSettings, MapSettingsMode } from "./types";
import { mapApi } from "../api/mapApi";
import {
  NetworkProvidersQualitySummary,
  RouteDetails,
  Tower,
} from "../api/types";

export type MapData = {
  networkProvidersQualitySummary: NetworkProvidersQualitySummary | null;
  routeDetails: RouteDetails | null;
  infrastructure: FeatureCollection | null;
  tower: Tower | null;
};

export const useMapData = (
  mapSettings: MapSettings,
  mapSettingsMode: MapSettingsMode
): MapData => {
  const {
    viewport,
    locationPoints,
    routeTransportation,
    layerOptions,
    hideInfrastructure,
  } = mapSettings;

  const [networkProvidersQualitySummary, setNetworkProvidersQualitySummary] =
    useState<NetworkProvidersQualitySummary>(null);

  const [routeDetails, setRouteDetails] = useState<RouteDetails>(null);

  const [infrastructure, setInfrastructure] = useState<FeatureCollection>(null);

  const [tower, setTower] = useState<Tower>(null);

  // set tower address
  useEffect(() => {
    if (mapSettingsMode === MapSettingsMode.address && locationPoints.start) {
      mapApi
        .getBestKindInCircle(locationPoints.start, 300)
        .then((res) => setTower(res));
    }
  }, [locationPoints.start, mapSettingsMode]);

  // set tower route
  useEffect(() => {
    if (mapSettingsMode === MapSettingsMode.route && routeDetails) {
      mapApi
        .getPrevalentKindInGeoJson(routeDetails, 300)
        .then((res) => setTower(res));
    }
  }, [routeDetails, mapSettingsMode]);

  // set locations
  useEffect(() => {
    if (hideInfrastructure) {
      setInfrastructure(null);
      return;
    }

    if (viewport?.bounds) {
      mapApi
        .getLocationsForArea(viewport.bounds)
        .then((res) => setInfrastructure(res));
    }
  }, [viewport, hideInfrastructure]);

  // set NetworkProvidersQualitySummary viewport
  useEffect(() => {
    if (mapSettingsMode === MapSettingsMode.viewport && viewport) {
      mapApi
        .getNetworkProvidersQualityForArea(
          viewport.bounds,
          layerOptions.outdoorNetworkCoverage
        )
        .then((res) => setNetworkProvidersQualitySummary(res));
    }
  }, [viewport, mapSettingsMode, layerOptions.outdoorNetworkCoverage]);

  // set NetworkProvidersQualitySummary address
  useEffect(() => {
    if (mapSettingsMode === MapSettingsMode.address && locationPoints.start) {
      mapApi
        .getNetworkProvidersQualityForPoint(
          locationPoints.start,
          layerOptions.outdoorNetworkCoverage
        )
        .then((res) => setNetworkProvidersQualitySummary(res));
    }
  }, [
    locationPoints.start,
    mapSettingsMode,
    layerOptions.outdoorNetworkCoverage,
  ]);

  // set NetworkProvidersQualitySummary route
  useEffect(() => {
    if (mapSettingsMode === MapSettingsMode.route && routeDetails) {
      mapApi
        .getNetworkProvidersQualityForGeoJSON(
          routeDetails,
          layerOptions.outdoorNetworkCoverage
        )
        .then((res) => setNetworkProvidersQualitySummary(res));
    }
  }, [routeDetails, mapSettingsMode, layerOptions.outdoorNetworkCoverage]);

  // set RouteDetails
  useEffect(() => {
    const { start, end } = locationPoints;

    if (start && end) {
      mapApi
        .getRoute(start, end, routeTransportation)
        .then((res) => setRouteDetails(res))
        .catch(() => setRouteDetails(null));
    } else {
      setRouteDetails(null);
    }
  }, [locationPoints, routeTransportation]);

  return {
    networkProvidersQualitySummary,
    routeDetails,
    infrastructure,
    tower,
  };
};
