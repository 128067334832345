import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { IGetLoyaltyBonus } from "./IGetLoyaltyBonus";

export class GetLoyaltyBonus implements IGetLoyaltyBonus {
  constructor(private readonly entity: ITariff) {}

  execute(): number {
    return this.entity.getLoyaltyBonus();
  }
}
