import { useMemo } from "react";
import { IDistributionData } from "../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import { IPencilSellingProps } from "../../views/PencilSelling/IPencilSelling";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";

interface IUseProfileData extends Partial<IPencilSellingProps> {
  offerStore: BaseOffer;
}

export const useProfileData = ({
  distributionPoint,
  distributionLink,
  magentaAppLink,
  googleReviewLink,
  onlineAppointmentBookingLink,
  offerStore,
}: IUseProfileData) => {
  const distributionData: IDistributionData = useMemo(
    () => ({
      distributionLink,
      distributionPoint: Object.values(distributionPoint).some(
        (distributionItem) => !!distributionItem
      )
        ? distributionPoint
        : null,
    }),
    [distributionLink, distributionPoint]
  );

  const bannerLinks = useMemo(
    () => ({ magentaAppLink, googleReviewLink, onlineAppointmentBookingLink }),
    [magentaAppLink, googleReviewLink, onlineAppointmentBookingLink]
  );

  return {
    bannerLinks,
    distributionData: {
      ...distributionData,
      showDistributionData: offerStore.getProfileDetailsInPdfIsDisplayed(),
    },
  };
};
