import React from "react";
import {
  Container,
  Heading,
  Flex,
  ThemeProvider,
  Theme,
  Button,
} from "@dtpk-cc/components";

import { trackClick } from "helpers/reactTracking";
import MagentaLogoActive from "./assets/magentaeins-benefit-logo-active.svg";

import "elements/OfferAdvantage/offer-advantage.scss";

const OfferAdvantage = ({ active = false, onChange, customClass = "" }) => {
  const buttonText = active ? "Ausgewählt" : "Auswählen";

  return (
    <Container
      onClick={() => {
        onChange(active);
        trackClick("magentaeins-vorteil");
      }}
    >
      <Flex
        style={{ flexWrap: "wrap" }}
        customClass={`offer-advantage ${customClass} ${
          active ? "offer-advantage--active" : ""
        }`}
        padded
      >
        <MagentaLogoActive
          className={`offer-advantage__logo ${
            active ? "offer-advantage__logo--active" : ""
          }`}
        />

        <ThemeProvider value={active ? Theme.dark : Theme.white}>
          <Container customClass="offer-advantage__flex-wrapper">
            <Heading
              tag="h3"
              customClass="offer-advantage__heading"
              variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
            >
              MagentaEINS - Festnetz und Mobilfunk kombinieren und Vorteile
              sichern
            </Heading>
            <Button
              customClass={`offer-advantage__button ${
                active ? "offer-advantage__button--active" : null
              }`}
              variants={active ? Button.Variant.outline : null}
            >
              {buttonText}
            </Button>
          </Container>
        </ThemeProvider>
      </Flex>
    </Container>
  );
};

export default OfferAdvantage;
