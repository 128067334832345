import React from "react";
import { trackClick } from "helpers/reactTracking";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import TimePicker from "react-time-picker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import {
  Heading,
  Grid,
  CopyText,
  Container,
  Checkbox,
  TextLink,
} from "@dtpk-cc/components";
import { LpgContinueSolid } from "@dtpk-cc/components/dist/icons";

import { DateTimeHelper } from "helpers/DateTimeHelper";

import "./offer-selection.scss";

registerLocale("de", de);
setDefaultLocale(de);

const OfferSelection = ({
  validUntil,
  consultingTime,
  onNextAppointmentChange,
  onDateChange,
  toggleDistributionDataDisplay,
  showDistributionData,
  distributionData,
}) => {
  const trackingContext = "angebot-fertigstellen";
  const handleTimeInputChange = (timeInput) => {
    if (DateTimeHelper.isTimeValid(timeInput)) {
      onNextAppointmentChange(timeInput, "nextAppointmentTime");
    }
  };

  return (
    <Container
      customClass="offer-selection"
      variants={Container.Variant.padded}
    >
      <Grid.Row>
        <Grid.Col
          customClass="offer-selection__title"
          s={6}
          m={12}
          l={12}
          xl={24}
        >
          <Heading
            tag="h1"
            variants={[Heading.Variant.secondary]}
            style={{ textAlign: "center" }}
          >
            Angebot fertigstellen
          </Heading>
          <CopyText>Angebot vom {DateTimeHelper.currentDisplayDate()}</CopyText>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row
        style={{ justifyContent: "center", margin: "0.75rem 0" }}
        gutter
      >
        <Grid.Col s={6} m={12} l={12} xl={16}>
          <Grid.Row gutter>
            <Grid.Col s={6} m={12} l={12} xl={24}>
              <div className="offer-selection__custom-input">
                <CopyText id="valid-until">
                  Angebot gültig bis (TT.MM.JJJJ)
                </CopyText>
                <DatePicker
                  // eslint-disable-next-line no-return-assign
                  // onFocus={(e) => e.preventDefault()} // Tablet keyboard removal
                  selected={validUntil}
                  minDate={new Date()}
                  aria-labelledby="valid-until"
                  dateFormat="dd.MM.yyy"
                  onChange={(date) => {
                    onDateChange(date);
                    trackClick("angebot-gültig-bis", trackingContext);
                  }}
                  aria-hidden={true}
                  preventOpenOnFocus
                  customInput={
                    <input
                      aria-label="Angebot gültig bis (TT.MM.JJJJ)"
                      type="input"
                    ></input>
                  }
                />
              </div>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row customClass="offer-selection__sub-title">
            <Heading tag="h2" variants={[Heading.Variant.tertiary]}>
              Ihr nächster Beratungstermin
            </Heading>
          </Grid.Row>
          <Grid.Row gutter>
            <Grid.Col s={3} m={6} l={6} xl={12}>
              <CopyText id="consulting-date">
                Datum wählen (TT.MM.JJJJ)
              </CopyText>
              <div className="offer-selection__custom-input">
                <DatePicker
                  // eslint-disable-next-line no-return-assign
                  // onFocus={(e) => (e.target.readOnly = true)} // Tablet keyboard removal
                  selected={consultingTime.nextAppointmentDate}
                  dateFormat="dd.MM.yyy"
                  ariaLabelledBy="consulting-date"
                  minDate={new Date()}
                  onChange={(date) => {
                    onNextAppointmentChange(date, "nextAppointmentDate");
                    trackClick("ihr-nächster-beratungstermin", trackingContext);
                  }}
                  preventOpenOnFocus
                  customInput={
                    <input
                      aria-label="Datum wählen (TT.MM.JJJJ)"
                      type="input"
                    ></input>
                  }
                />
              </div>
            </Grid.Col>

            <Grid.Col s={3} m={6} l={6} xl={12}>
              <CopyText>Uhrzeit eingeben</CopyText>
              <div className="offer-selection__custom-input">
                <TimePicker
                  className="offer-selection__time-picker"
                  value={consultingTime.nextAppointmentTime}
                  minuteAriaLabel="Minuten für ihren nächsten Beratungstermin eingeben"
                  hourAriaLabel="Stunde für ihren nächsten Beratungstermin eingeben"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(timeInput) => {
                    handleTimeInputChange(timeInput);
                    if (timeInput)
                      trackClick("uhrzeit-eingeben", trackingContext);
                  }}
                />
              </div>
            </Grid.Col>
            {(distributionData.distributionPoint ||
              distributionData.distributionLink) && (
              <Grid.Col
                customClass="offer-selection__distribution-data"
                s={6}
                m={12}
                l={12}
                xl={24}
              >
                <div className="offer-selection__distribution-data-inner">
                  <Heading tag="h3" variants={[Heading.Variant.tertiary]}>
                    Ihre Shop Adresse
                  </Heading>
                  <div className="offer-selection__distribution-data-items-wrapper">
                    {distributionData?.distributionPoint && (
                      <>
                        <CopyText customClass="offer-selection__distribution-data-item">
                          {distributionData?.distributionPoint.name}
                        </CopyText>
                        <CopyText customClass="offer-selection__distribution-data-item">
                          {distributionData?.distributionPoint.adress}
                        </CopyText>
                        <CopyText customClass="offer-selection__distribution-data-item">
                          <span>{distributionData?.distributionPoint.plz}</span>
                          <span>
                            {distributionData?.distributionPoint.location}
                          </span>
                        </CopyText>
                      </>
                    )}
                    {distributionData?.distributionLink && (
                      <CopyText>{distributionData?.distributionLink}</CopyText>
                    )}
                  </div>
                  <TextLink
                    customClass="offer-selection__distribution-data-link"
                    target="_blank"
                    href="/users/edit"
                    variants={TextLink.Variant.highlight}
                    iconRight={LpgContinueSolid}
                    rightIconProps={{
                      style: { position: "absolute", top: 2, left: 2 },
                    }}
                  >
                    Shop Adresse bearbeiten
                  </TextLink>
                  <Checkbox
                    checked={showDistributionData}
                    onChange={toggleDistributionDataDisplay}
                    tabIndex="0"
                    role="checkbox"
                  >
                    Shop Adresse auf PDF anzeigen
                  </Checkbox>
                </div>
              </Grid.Col>
            )}

            {!(
              distributionData.distributionPoint ||
              distributionData.distributionLink
            ) && (
              <Grid.Col
                customClass="offer-selection__distribution-data"
                s={6}
                m={12}
                l={12}
                xl={24}
              >
                <TextLink
                  customClass="offer-selection__distribution-data-link"
                  target="_blank"
                  href="/users/edit"
                  variants={TextLink.Variant.highlight}
                  iconRight={LpgContinueSolid}
                  rightIconProps={{
                    style: { position: "absolute", top: 2, left: 2 },
                  }}
                >
                  Shop Link und Adresse im Profil eintragen und dem Angebot
                  hinzufügen
                </TextLink>
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default OfferSelection;
