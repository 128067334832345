import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Icon, Button, Grid, Heading, CopyText } from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import {
  CardSection,
  CardsPresenter,
} from "../../../../../../core/presenter/PencilSelling/CardsPresenter";
import SecondaryCardsSectionCardItem from "../SecondaryCardsSectionCardItem";
import { ICard } from "../../../../../../core/entities/Product/Card/ICard";
import { TIMESTAMP_MARK } from "../../../../../../constants/const";
import { getSecondaryCardDataForCart } from "../../../../../../helpers/GetSecondaryCardDataForCart";
import {
  IBusinessCasesTypeValues,
  ITariff,
} from "../../../../../../core/entities/Product/Tariff/ITariff";
import {
  IAdditionalDevices,
  ICartItemData,
  ICartItemDataPromotions,
} from "../../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { ICustomPromotion } from "../../../../../../core/entities/PencilSelling/ICustomPromotion";
import { Cart } from "../../../../../../core/entities/PencilSelling/Cart/Cart";
import * as styles from "./secondary-cards-section.module.scss";
import { createSecondaryCardKey } from "../../../../../../helpers/CreateSeconaryCardKey";

type SecondaryCardsSectionProps = {
  section: CardSection;
  name: string;
  dataVolume: string;
  cardsPresenter: CardsPresenter;
  businessCase: IBusinessCasesTypeValues;
  mobileTariff: ITariff;
  inheritedDataVolume: string;
  benefitIsActive: boolean;
  customPromotions: ICustomPromotion[];
  trackingContext: string;
  configurationCart: Cart;
  cardsInCart: ICartItemData[];
};

const SecondaryCardsSection = ({
  section,
  name,
  dataVolume,
  cardsPresenter,
  businessCase,
  mobileTariff,
  inheritedDataVolume,
  benefitIsActive,
  customPromotions,
  trackingContext,
  configurationCart,
  cardsInCart,
}: SecondaryCardsSectionProps) => {
  const distinctLevelNames = cardsPresenter
    .distinctLevels(section.cards as ICard[])
    .map((entry) => entry.name);

  const getTargetCardByLevel = (levelName: string) =>
    (section?.cards as ICard[])?.find((card) => card.level.name === levelName);

  const getTargetCardByKey = (cardInCartKey: string): ICard => {
    const originCardKey = cardInCartKey.split(TIMESTAMP_MARK)[0];
    return (section?.cards as ICard[]).find(
      (card) => card.key === originCardKey
    );
  };

  const addSecondaryCardHandler = () => {
    const selectedCard = getTargetCardByLevel(distinctLevelNames[0]);
    const cardKey = createSecondaryCardKey(selectedCard.key);
    const cartItemData = getSecondaryCardDataForCart({
      card: selectedCard,
      additionalDevices: [],
      promotions: [],
      note: "",
      cardSettings: {
        inheritedDataVolume,
        benefitIsActive,
        tariff: mobileTariff,
      },
    });
    cartItemData.key = cardKey;

    configurationCart.addToCart(cartItemData);
  };

  const editSecondaryCardHandler = ({
    levelName,
    promotions,
    additionalDevices,
    note,
    cardKey,
  }: {
    levelName: string;
    promotions: ICartItemDataPromotions;
    additionalDevices: IAdditionalDevices[];
    note: string;
    cardKey: string;
  }) => {
    const selectedCard = getTargetCardByLevel(levelName);
    const newCardKey = createSecondaryCardKey(selectedCard.key);
    const cartItemData = getSecondaryCardDataForCart({
      additionalDevices,
      promotions,
      note,
      card: selectedCard,
      cardSettings: {
        inheritedDataVolume,
        benefitIsActive,
        tariff: mobileTariff,
      },
    });
    cartItemData.key = newCardKey;
    configurationCart.replace(cardKey, cartItemData);
  };

  const deleteCardHandler = (cardKey: string) => {
    configurationCart.delete(cardKey);
  };

  const activeCardsBySection = useMemo(
    () =>
      cardsInCart.filter(
        (card) =>
          card.tariffWorld.key === section.key &&
          card.cardData.size === section.size
      ),
    [cardsInCart, section.key, section.size]
  );

  const cardSectionLimitIsReached = useMemo(
    () => CardsPresenter.isLimited(section, cardsInCart),
    [cardsInCart, section]
  );

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <Grid customClass={styles.header}>
        <Grid.Row customClass="m-0">
          <Grid.Col
            m={3}
            l={3}
            xl={7}
            customClass={`p-r-0 ${styles.headerCell}`}
          >
            <Button
              disabled={cardSectionLimitIsReached}
              variants={Button.Variant.outline}
              customClass={styles.addBtn}
              onClick={addSecondaryCardHandler}
            >
              <Icon customClass={styles.addBtnIcon} icon={ActionAddDefault} />
            </Button>
            <Heading
              tag="p"
              customClass="m-0"
              variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
            >
              {name}
            </Heading>
          </Grid.Col>
          <Grid.Col m={3} l={3} xl={5} />
          <Grid.Col m={2} l={2} xl={4} />
          <Grid.Col m={2} l={2} xl={4} />
          <Grid.Col m={1} l={1} xl={3} />
          <Grid.Col
            m={1}
            l={1}
            xl={1}
            customClass={`${styles.dataVolumeWrapper} ${styles.headerCell}`}
          >
            <CopyText variants={CopyText.Variant.highlight}>
              {dataVolume}
            </CopyText>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <div>
        {activeCardsBySection.map((cardInCart, index) => (
          <SecondaryCardsSectionCardItem
            key={cardInCart.key}
            distinctLevelNames={distinctLevelNames}
            customPromotions={customPromotions}
            trackingContext={trackingContext}
            card={getTargetCardByKey(cardInCart.key)}
            cardInCart={cardInCart}
            businessCase={businessCase}
            editSecondaryCardHandler={editSecondaryCardHandler}
            deleteCardHandler={deleteCardHandler}
            cardSectionName={name}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(SecondaryCardsSection);
