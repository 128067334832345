import { CalculatorCard } from "core/entities/MagentaNext/Tariff/ITariff";
import { ILevel } from "../core/entities/Product/IProduct";

// TODO clarify the type
export const getTariffLevels = <T extends CalculatorCard>(
  tariffs: T[]
): ILevel[] => {
  const uniqueLevels = tariffs.reduce((acc, { level }) => {
    const levelExists = acc.some(({ key }) => level.key === key);

    return levelExists ? acc : [...acc, level];
  }, [] as ILevel[]);

  return uniqueLevels.sort((current, next) => current.valency - next.valency);
};
