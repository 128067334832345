import React, { useState } from "react";
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import { trackClick } from "helpers/reactTracking";
import PromotionListActionSection from "../PromotionListActionSection";
import PromotionTile from "../../../elements/PromotionTile";

import "./promotion-section.scss";

const DEFAULT_FORM_CONFIG = {
  name: "",
  type: "",
  price: null,
  key: null,
  interval: null,
};

const PromotionsSection = ({
  promotions,
  productPrice,
  highlightedPromotions,
  setActivePromotions,
  setHighlightedPromotions,
  trackingContext,
  onSelect,
  portfolioKey,
}) => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formState, setFormState] = useState(DEFAULT_FORM_CONFIG);

  const promotionEditHandler = (promotion) =>
    promotion.discount.kind === PromotionPriceType.CUSTOM_PROMOTION &&
    !!promotion?.isEditable
      ? () => {
          setIsFormVisible(true);
          setIsAddMode(false);
          setFormState({
            name: promotion.name,
            type: promotion.type,
            key: promotion.key,
            value:
              promotion.discount.value || promotion.discount.value === 0
                ? promotion.discount.value.toString()
                : null,
            interval:
              promotion.discount.interval || promotion.discount.interval === 0
                ? promotion.discount.interval.toString()
                : null,
          });
        }
      : null;

  return (
    <div className="promotion-section">
      <div className="promotion-section__list-wrapper">
        <div className="promotion-section__list">
          {promotions.map((promotion) => (
            <PromotionTile
              key={promotion.key}
              customClass="promotion-section__list-item"
              pricing={promotion.discount}
              name={promotion?.description || promotion.name}
              additionalDescription={promotion.additionalDescription}
              productPrice={productPrice}
              active={highlightedPromotions.some(
                ({ key }) => promotion.key === key
              )}
              handleClick={() => {
                onSelect(promotion);
                trackClick(
                  promotion?.description || promotion.name,
                  trackingContext
                );
              }}
              onEdit={promotionEditHandler(promotion)}
            />
          ))}
        </div>
      </div>
      <PromotionListActionSection
        isAddMode={isAddMode}
        setIsAddMode={setIsAddMode}
        isFormVisible={isFormVisible}
        setIsFormVisible={setIsFormVisible}
        portfolioKey={portfolioKey}
        promotions={promotions}
        setActivePromotions={setActivePromotions}
        highlightedPromotions={highlightedPromotions}
        setHighlightedPromotions={setHighlightedPromotions}
        formState={formState}
        setFormState={setFormState}
      />
    </div>
  );
};

export default PromotionsSection;
