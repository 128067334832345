import { makeAutoObservable } from "mobx";
import {
  FactModalKeys,
  IFactModalItem,
  IFactModalsData,
  IFactModalsMap,
  IFactModalsMapItem,
} from "./IFactModal";
import { FACT_MODALS_DATA_LOCAL_STORAGE_KEY } from "../../../../constants/const";
import { BaseOffer } from "../BaseOffer/BaseOffer";
import { CustomerType } from "../Customer/ICustomer";

export class FactModalEntity {
  factModalsData: IFactModalsData = null;

  baseOffer: BaseOffer;

  customerType: CustomerType | null = null;

  factModalMap: IFactModalsMap | null;

  factModalActiveKey: FactModalKeys = FactModalKeys.BASE_DATA;

  constructor(baseOffer: BaseOffer) {
    this.baseOffer = baseOffer;
    this.customerType = null;
    this.factModalMap = JSON.parse(
      localStorage.getItem(FACT_MODALS_DATA_LOCAL_STORAGE_KEY)
    ) as IFactModalsMap;
    makeAutoObservable(this);
  }

  getBaseOffer(): BaseOffer {
    return this.baseOffer;
  }

  getFactModalsData() {
    return this.factModalsData;
  }

  setFactModalsData(factModalsData: IFactModalsData | null) {
    this.factModalsData = factModalsData;
  }

  getFactModalActiveKey() {
    return this.factModalActiveKey;
  }

  setFactModalActiveKey(key: FactModalKeys) {
    this.factModalActiveKey = key;
  }

  getActiveFactModal(): IFactModalItem | null {
    return this.getFactModalsData()?.[this.getFactModalActiveKey()] || null;
  }

  getActiveFactModalMapItem(): IFactModalsMapItem | null {
    return (
      this.factModalMap?.[this.customerType]?.[this.getFactModalActiveKey()] ||
      null
    );
  }

  getFactModalsMap(): IFactModalsMap | null {
    return this.factModalMap;
  }

  setFactModalsMap(factModalsMap: IFactModalsMap) {
    this.factModalMap = factModalsMap;
    localStorage.setItem(
      FACT_MODALS_DATA_LOCAL_STORAGE_KEY,
      JSON.stringify(factModalsMap)
    );
  }

  updateFactModalsDefaultOpenedState() {
    this.customerType = this.getBaseOffer().customer.get().customerType;
    let factModalsMap = this.getFactModalsMap();
    let factModalsMapCustomerType = {};

    if (factModalsMap) {
      factModalsMapCustomerType = factModalsMap[this.customerType];
    }

    if (!factModalsMap) {
      factModalsMap = {};
    }

    if (!factModalsMap[this.customerType]) {
      factModalsMap[this.customerType] = {};
      factModalsMapCustomerType = factModalsMap[this.customerType];
    }

    const factModalsMapUpdateHandler = () => {
      Object.keys(this.getFactModalsData()).forEach((factModalItemKey) => {
        let factModalsMapItem: IFactModalsMapItem;
        const factModalItem = this.getFactModalsData()[
          factModalItemKey
        ] as IFactModalItem;

        if (!factModalItem) return;

        if (
          factModalsMapCustomerType &&
          factModalsMapCustomerType[factModalItemKey]
        ) {
          factModalsMapItem = factModalsMapCustomerType[
            factModalItemKey
          ] as IFactModalsMapItem;
          if (factModalsMapItem.uuid !== factModalItem.uuid) {
            factModalsMapItem.isOpenedByDefault = true;
            factModalsMapItem.uuid = factModalItem.uuid;
          }
        } else {
          factModalsMapCustomerType[factModalItemKey] = {
            uuid: factModalItem.uuid,
            isOpenedByDefault: true,
          };
        }
      });
    };

    factModalsMapUpdateHandler();
    this.setFactModalsMap(factModalsMap);
  }

  setupFactModalsData(factModalsData: IFactModalsData | null) {
    const mappedFactModalsData = { ...factModalsData };
    Object.keys(mappedFactModalsData).forEach((factModalsMapKey) => {
      const factModalItem = mappedFactModalsData[
        factModalsMapKey
      ] as IFactModalItem;
      if (!factModalItem.active) {
        mappedFactModalsData[factModalsMapKey] = null;
      }
    });

    this.setFactModalsData(mappedFactModalsData);
    this.updateFactModalsDefaultOpenedState();
  }

  toggleFactModalDisplay() {
    const factModalMap = { ...this.factModalMap };
    if (factModalMap[this.customerType][this.getFactModalActiveKey()]) {
      const factModalMapActiveItem =
        factModalMap[this.customerType][this.getFactModalActiveKey()];
      factModalMapActiveItem.isOpenedByDefault =
        !factModalMapActiveItem.isOpenedByDefault;
    }
    this.setFactModalsMap(factModalMap);
  }
}
