import React, { useState } from "react";
import { CopyText } from "@dtpk-cc/components";

import "./period-input.scss";

const PeriodInput = ({ value = 0, onChange, onClick }) => {
  const [period, setPeriod] = useState(Number(value));

  function handleChange(periodInput) {
    onChange(Number(periodInput));
    setPeriod(periodInput);
  }

  function handleBlur(periodInput) {
    const formattedperiod = Number(periodInput);
    if (period !== formattedperiod) setPeriod(formattedperiod);
  }

  return (
    <>
      <input
        className="period-input"
        value={period}
        type="number"
        min="0"
        onClick={onClick}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
      />
      <CopyText tag="span" customClass="period-input__copy">
        x
      </CopyText>
    </>
  );
};

export default PeriodInput;
