import React from "react";
import { Heading } from "@dtpk-cc/components";

import * as styles from "./tariff-selection-wrapper.module.scss";

type TariffSelectionWrapperProps = Partial<{
  customClass: string;
  title: string;
  tariffName?: string;
}>;

const TariffSelectionWrapper: React.FC<TariffSelectionWrapperProps> = ({
  customClass = "",
  title = "Tarifauswahl",
  tariffName = "",
  children,
}) => (
  <div className={`${styles.tariffSelectionWrapper} ${customClass}`}>
    <div className={styles.titleWrapper}>
      <Heading
        customClass={styles.title}
        tag="h3"
        variants={[Heading.Variant.secondary]}
      >
        {title}
      </Heading>
      <Heading customClass={styles.tariffName} tag="h3" variants={[Heading.Variant.quaternary]}>
        {tariffName}
      </Heading>
    </div>
    {children}
  </div>
);

export default TariffSelectionWrapper;
