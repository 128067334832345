export const TARIFF_ADDONS_RESET_DIALOG_DATA = {
  mainText:
    "Bei einer Änderung des Tarifs werden abhängige Promotionen und Extras aus der aktuellen Auswahl entfernt.",
  title: "Ändern des Tarifs",
};

export const SECONDARY_CARDS_RESET_DIALOG_DATA = {
  mainText:
    "Bei Änderung der MagentaEINS Vorteile werden alle bereits hinzugefügten Zusatzkarten entfernt.",
  title: "Ändern der MagentaEINS Vorteile",
};
