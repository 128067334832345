import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";
import {
  centerOfGermany,
  generateRasterLayerUrl,
  tileLayerProps,
} from "./constants/const";
import "views/PencilSelling/pencil-selling-global.scss";
import MapSidebar from "./MapSidebar";
import * as styles from "./map.module.scss";
import "leaflet/dist/leaflet.css";
import { useManageMapSettings } from "./hooks/useManageMapSettings";
import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Asterisk/Asterisk.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/EqualHeight/EqualHeight.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import "@dtpk-cc/components/dist/components/Modal/Modal.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import "@dtpk-cc/components/dist/components/Tabs/Tabs.css";
import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Tile/Tile.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Indicator/Indicator.css";
import "@dtpk-cc/components/dist/components/Tooltip/Tooltip.css";
import "@dtpk-cc/components/dist/components/StepIndicator/StepIndicator.css";
import "@dtpk-cc/components/dist/components/HintBox/HintBox.css";
import "@dtpk-cc/components/dist/components/Checkbox/Checkbox.css";
import "@dtpk-cc/components/dist/components/AutoSuggestedInput/AutoSuggestedDropdown.css";
import "@dtpk-cc/components/dist/components/Accordion/Accordion.css";

import MapLayer from "./MapLayer";
import { useMapData } from "./hooks/useMapData";
import MapLegend from "./MapLegend";

// This wrapper is needed to get access to the `map` in the useMapData hook
const App = () => {
  const mapSettingsManager = useManageMapSettings();

  const mapData = useMapData(
    mapSettingsManager.mapSettings,
    mapSettingsManager.mapSettingsMode
  );

  useEffect(() => {
    document.body.classList.add("pst-body-container");
  }, []);

  return (
    <MapContainer
      center={centerOfGermany}
      zoomControl={false}
      zoom={7}
      maxZoom={18}
      minZoom={7}
      maxBounds={[
        [47.270111, 5.866342],
        [55.05814, 15.041896],
      ]}
      scrollWheelZoom={true}
      className={styles.mapContainer}
    >
      <div className={styles.mapLayout}>
        <MapSidebar mapSettingsManager={mapSettingsManager} mapData={mapData} />
        <div className={styles.mapContent}>
          <TileLayer
            {...tileLayerProps[
              mapSettingsManager.mapSettings.backgroundColorScheme
            ]}
          />
          <TileLayer
            url={generateRasterLayerUrl(mapSettingsManager.mapSettings)}
            maxNativeZoom={15}
          ></TileLayer>
          <MapLayer mapSettingsManager={mapSettingsManager} mapData={mapData} />
        </div>
        <MapLegend colorScheme={mapSettingsManager.mapSettings.colorScheme} />
      </div>
    </MapContainer>
  );
};

export default App;
