import React, { ElementType } from "react";
import { Button, CopyText } from "@dtpk-cc/components";
import { trackClick } from "../../../../helpers/reactTracking";
import { INavigationMapItem } from "../INavigation";
import NavArrow from "../../../../../assets/images/icons/pencil-selling-tool/navigation/nav-arrow.svg";

import * as styles from "./navigation-item.module.scss";

type NavigationItemProps = {
  isActive: boolean;
  setActiveItem: (value: string) => void | null;
  setActiveFlyoutKey: (value: string) => void | null;
  setBackDropIsActive: (value: boolean) => void;
  activeFlyoutKey: string | null;
  Icon: ElementType | string;
  userName: string;
  flyoutIsOpen: boolean;
};

const NavigationItem: React.FC<INavigationMapItem & NavigationItemProps> = ({
  title,
  onClick,
  getChildren,
  Icon,
  withArrow,
  isActive,
  setActiveItem,
  setActiveFlyoutKey,
  activeFlyoutKey,
  setBackDropIsActive,
  userName,
  flyoutIsOpen,
}) => {
  const clickHandler = () => {
    onClick({
      isActive,
      key: title,
      setActiveItem,
      setActiveFlyoutKey,
      setBackDropIsActive,
      activeFlyoutKey,
    });
  };

  const isProfileNavItem = title === userName;

  return (
    <div className={`${styles.navigationItem} `}>
      <Button
        tabIndex={0}
        role="button"
        onClick={clickHandler}
        customClass={`py-6 p-l-12  ${styles.button} ${
          withArrow ? "p-r-6" : "p-l-12"
        } ${isActive ? styles.active : ""}`}
        variants={[Button.Variant.bare]}
      >
        {Icon && (
          <div
            className={`m-r-6 ${
              isProfileNavItem
                ? styles.profileIconWrapper
                : styles.iconContainer
            }`}
          >
            <Icon className={`${!isProfileNavItem ? styles.icon : ""}`} />
          </div>
        )}
        <CopyText customClass={styles.title}>{title}</CopyText>
        {withArrow && (
          <NavArrow
            className={`${styles.arrowIcon} ${
              isActive && flyoutIsOpen ? styles.active : ""
            }`}
          />
        )}
      </Button>
      {getChildren({
        key: title,
        activeNavItem: activeFlyoutKey,
        setBackDropIsActive,
        setActiveFlyoutKey,
      })}
    </div>
  );
};

export default NavigationItem;
