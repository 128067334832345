import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ISetHasBenefit } from "./ISetHasBenefit";

export class SetHasBenefit implements ISetHasBenefit {
  constructor(private readonly entity: ITariff) {}

  execute(hasBenefit: boolean): void {
    return this.entity.setHasBenefit(hasBenefit);
  }
}
