import { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { IGetProfileDetailsInPdfIsDisplayed } from "./IGetProfileDetailsInPdfIsDisplayed";

// eslint-disable-next-line prettier/prettier
export class GetProfileDetailsInPdfIsDisplayedUseCase implements IGetProfileDetailsInPdfIsDisplayed {
  constructor(private entity: IOffer) {}

  execute(): boolean {
    return this.entity.getProfileDetailsInPdfIsDisplayed();
  }
}
