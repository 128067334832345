import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { IDeleteFromCartUseCase } from "./IDeleteFromCart";

export class DeleteFromCartUseCase implements IDeleteFromCartUseCase {
  constructor(private cart: ICart) {}

  execute(key: string): void {
    this.cart.delete(key);
  }
}
