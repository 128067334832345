import React from "react";
import * as styles from "./checkbox-input.module.scss";

type CheckboxInputProps = {
  value?: string;
  name: string;
  id: string;
  inputTestId?: string;
  checked: boolean;
  onChange?: (value) => void;
  customClass?: string;
  disabled?: boolean;
};

const CheckboxInput = ({
  customClass = "",
  disabled = false,
  value = "",
  name,
  id,
  inputTestId = null,
  checked,
  onChange = () => {},
}: CheckboxInputProps) => (
  <>
    <input
      tabIndex={disabled ? -1 : 0}
      type="checkbox"
      value={value}
      name={name}
      onKeyPress={(event) => {
        onChange(event);
      }}
      onChange={(event) => {
        onChange(event);
      }}
      data-testid={inputTestId}
      id={id}
      checked={checked}
      className={styles.input}
    />
    <span
      className={`${styles.checkMark} ${customClass} ${
        disabled ? styles.disabled : ""
      }`}
    />
  </>
);

export default CheckboxInput;
