import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { IResetCoreCardsInCartUseCase } from "./IResetCoreCardsInCart";

export class ResetCoreCardsInCartUseCase
  implements IResetCoreCardsInCartUseCase {
  constructor(private cart: ICart) {}

  execute(): void {
    this.cart.resetCards();
  }
}
