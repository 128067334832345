import React from "react";
import { Heading, CopyText } from "@dtpk-cc/components";
import ModalWrapper from "elements/new-design/ModalWrapper";

type FrameworkContractModalProps = {
  onClose: () => void;
  isOpen: boolean;
  containerCustomClass?: string;
};

const FrameworkContractModal = ({
  onClose,
  isOpen,
  containerCustomClass = "",
}: FrameworkContractModalProps) => (
  <ModalWrapper
    isOpen={isOpen}
    onClose={onClose}
    onBackDropClick={onClose}
    containerCustomClass={containerCustomClass}
  >
    <Heading
      tag="h3"
      variants={[Heading.Variant.tertiary, Heading.Variant.display]}
    >
      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
    </Heading>
    <CopyText>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium
      consequatur deserunt eos ex fugit hic ipsa magni nihil quia recusandae
      reprehenderit, sapiente sunt, ullam ut vero. Aperiam consectetur
      consequuntur deserunt doloremque, harum labore pariatur veniam? Accusamus
      aperiam, culpa debitis distinctio dolorem dolorum, ducimus enim facilis
      iste, magnam modi mollitia sequi?
    </CopyText>
  </ModalWrapper>
);

export default FrameworkContractModal;
