import React from "react";
import { Button, HintBox, Icon } from "@dtpk-cc/components";
import {
  AlertErrorSelected,
  ActionCloseDefault,
} from "@dtpk-cc/components/dist/icons";
import { trackClick } from "../../../../helpers/reactTracking";
import * as styles from "./dialog-box.module.scss";

type DialogBoxProps = {
  trackingContext: string;
  activeMode: {
    isActive: boolean;
    submitBtnHandler: () => void;
    cancelBtnHandler: () => void;
    content: {
      title: string;
      mainText: string;
      submitBtnText: string;
      cancelBtnText: string;
    };
  };
};

const DialogBox = ({ activeMode, trackingContext }: DialogBoxProps) => (
  <div className={styles.configuratorDialogBox}>
    <HintBox>
      <Button
        onClick={activeMode.cancelBtnHandler}
        variants={[Button.Variant.bare, Button.Variant.icon]}
        customClass={styles.closeBtn}
      >
        <Icon icon={ActionCloseDefault} size={Icon.Size.medium} />
      </Button>
      <div className={styles.content}>
        <Icon
          customClass={styles.icon}
          size={Icon.Size.medium}
          icon={AlertErrorSelected}
          wrapperProps={{
            className: styles.iconWrapper,
          }}
        />
        <div className={styles.textWrapper}>
          <HintBox.Heading>{activeMode.content.title}</HintBox.Heading>
          <HintBox.Text>{activeMode.content.mainText}</HintBox.Text>
          <Button
            onClick={() => {
              activeMode.submitBtnHandler();
              trackClick("änderungen-übernehmen", trackingContext);
            }}
            variants={Button.Variant.outline}
            customClass={styles.actionBtn}
          >
            {activeMode.content.submitBtnText}
          </Button>
        </div>
      </div>
      {/* TODO Temporary hidden */}
      {/* {dialogMode.isActive && ( */}
      {/*  <div> */}
      {/*    <Button */}
      {/*      onClick={activeMode.submitBtnHandler} */}
      {/*      variants={Button.Variant.outline} */}
      {/*      customClass="configurator-dialog-box__action-btn" */}
      {/*    > */}
      {/*      {activeMode.content.submitBtnText} */}
      {/*    </Button> */}
      {/*    <Button */}
      {/*      onClick={activeMode.cancelBtnHandler} */}
      {/*      variants={Button.Variant.outline} */}
      {/*      customClass="configurator-dialog-box__action-btn" */}
      {/*    > */}
      {/*      {activeMode.content.cancelBtnText} */}
      {/*    </Button> */}
      {/*  </div> */}
      {/* )} */}
    </HintBox>
  </div>
);

export default DialogBox;
