import React, { useState } from "react";
import { observer } from "mobx-react";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import { IStepActionHeaderNavMap } from "hooks/PencilSelling/useStepActionHeaderNavMap";
import AmountPicker from "elements/new-design/AmountPicker";
import StepHeader from "../index";
import NavTabButton from "../NavTabButton";
import WarningModal from "../../WarningModal";
import RenameConfigurationModal from "../../../../elements/new-design/RenameConfigurationModal/RenameConfigurationModal";
import { trackClick } from "../../../../helpers/reactTracking";
import { ConfigurationsEntity } from "../../../../core/entities/PencilSelling/Configurations/Configurations";
import * as styles from "./step-header-with-actions.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { CustomerType } from "../../../../core/entities/PencilSelling/Customer/ICustomer";
import DisplayConfigurationFlyoutBtn from "../DisplayConfigurationFlyoutBtn";
import StepHeaderActionBtn from "../StepHeaderActionBtn";
import { AMOUNT_PICKER_MAX_VALUE } from "../../../../constants/const";
import Tooltip from "../../../../elements/new-design/Tooltip";

type StepActionHeaderProps = {
  navMap: IStepActionHeaderNavMap[];
  title: string;
  subtitle: string;
  activeTabIndex: number;
  trackingContext: string;
  amountPickerOnChange?: () => void;
  removeConfigurationHandler?: () => void;
  disabledPlus?: boolean;
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  displayConfigurationFlyout: (flyoutKey: string) => void;
  limit?: number | null;
  toggleAmountPickerTooltip?: (
    newAmount: number,
    callback: (isOpened: boolean) => void
  ) => void;
};

const StepActionHeader = ({
  title,
  subtitle,
  navMap,
  activeTabIndex,
  trackingContext,
  amountPickerOnChange = () => {},
  removeConfigurationHandler = () => {},
  disabledPlus = false,
  configurationsStore,
  offerStore,
  displayConfigurationFlyout,
  limit = null,
  toggleAmountPickerTooltip = () => {},
}: StepActionHeaderProps) => {
  const [removeConfigModalIsOpen, setRemoveConfigModalIsOpen] = useState(false);
  const [amountPickerTooltipIsActive, setAmountPickerTooltipIsActive] =
    useState(false);
  const activeConfigurationAmount =
    configurationsStore.getActiveConfigurationAmount();
  const { customerType } = offerStore.customer.get();
  const removeConfigModalToggleHandler = () =>
    setRemoveConfigModalIsOpen((prev) => !prev);

  const updateActiveConfigurationAmount = (value: number) =>
    configurationsStore.setActiveConfigurationAmount(value);

  const onAmountPickerChange = (analyticContext: string, value: number) => {
    trackClick(analyticContext, trackingContext);
    const newAmount = value > limit ? limit : value || 1;
    updateActiveConfigurationAmount(newAmount);
    toggleAmountPickerTooltip(newAmount, setAmountPickerTooltipIsActive);
    amountPickerOnChange();
  };

  return (
    <StepHeader
      customClass={styles.stepActionHeader}
      title={title}
      subtitle={subtitle}
      titleCustomClass={`${styles.title} textEllipsis`}
    >
      <RenameConfigurationModal
        configurationsStore={configurationsStore}
        trackingContext={trackingContext}
      />
      <div className={styles.mainActionGroupWrapper}>
        <div className={styles.amountPickerWrapper}>
          <AmountPicker
            withInput
            customClass={
              customerType === CustomerType.PRIVATE ? styles.hidden : ""
            }
            onSubtractClick={() => {
              onAmountPickerChange(
                "konfigurationsmenge.reduzieren",
                activeConfigurationAmount - 1
              );
            }}
            onAddClick={() => {
              onAmountPickerChange(
                "konfigurationsmenge.erhöhen",
                activeConfigurationAmount + 1
              );
            }}
            onChange={(price: number) => {
              onAmountPickerChange("konfigurationsmenge.ändern", price);
            }}
            value={activeConfigurationAmount}
            disabledPlus={
              disabledPlus ||
              activeConfigurationAmount >= AMOUNT_PICKER_MAX_VALUE
            }
          />
          {amountPickerTooltipIsActive && (
            <Tooltip customClass={styles.amountPrickerTooltip}>
              Hinweis: Es können maximal 10 Zusatzkarten zu einer Hauptkarte
              gebucht werden.
            </Tooltip>
          )}
        </div>
        <div className={styles.navButtonsWrapper}>
          {navMap.map((navItem, index) => (
            <NavTabButton
              key={navItem.title}
              text={navItem.title}
              onClick={navItem.onClick}
              isActive={activeTabIndex === index}
              disabled={navItem.disabled}
            />
          ))}
        </div>
      </div>
      <DisplayConfigurationFlyoutBtn
        customClass={styles.showFlyoutBtn}
        onClick={displayConfigurationFlyout}
      />
      <StepHeaderActionBtn
        onClick={removeConfigModalToggleHandler}
        icon={ActionRemoveDefault}
        text="Löschen"
      />
      <WarningModal
        onSubmit={() => {
          removeConfigurationHandler();
          removeConfigModalToggleHandler();
        }}
        onCancel={removeConfigModalToggleHandler}
        isOpen={removeConfigModalIsOpen}
        titleText={"Löschen der Konfiguration"}
        mainText={
          "Mit Bestätigung werden alle Einstellungen der Konfiguration gelöscht."
        }
      />
    </StepHeader>
  );
};

export default observer(StepActionHeader);
