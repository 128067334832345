import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { IRemoveTariffFromCart } from "./IRemoveTariffFromCart";

export class RemoveTariffFromCart implements IRemoveTariffFromCart {
  constructor(private cart: ICart) {}

  execute(isLandline = false): void {
    if (isLandline) this.cart.deleteLandlineTariff();
    else this.cart.deleteMobileTariff();
  }
}
