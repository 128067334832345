import React, { useEffect } from "react";
import { Map } from "leaflet";
import { useMapEvents } from "react-leaflet";
import MapLayerLocation from "./MapLayerLocation";
import { MapSettingsManager } from "../hooks/types";
import { MapData } from "../hooks/useMapData";
import MapLayerRoute from "./MapLayerRoute";
import MapLayerInfrastructure from "./MapLayerInfrastructure";
import { LocationIcons } from "../constants/const";

type MapLayerProps = {
  mapSettingsManager: MapSettingsManager;
  mapData: MapData;
};

const MapLayer = ({ mapSettingsManager, mapData }: MapLayerProps) => {
  const { mapSettings, mapSettingsMode, mapSettingsSetter } =
    mapSettingsManager;

  let map: Map;

  const handleViewportChange = () => {
    const bounds = map.getBounds();
    const size = map.getSize();
    const zoom = map.getZoom();
    mapSettingsSetter.setViewport({ bounds, size, zoom });
  };

  map = useMapEvents({
    resize: () => handleViewportChange(),
    dragend: () => handleViewportChange(),
    zoomend: () => handleViewportChange(),
  });

  // renders initial overlay
  useEffect(() => {
    handleViewportChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // center map to locationPoint, after it´s set
  useEffect(() => {
    if (mapSettings.locationPoints.start) {
      map.flyTo(mapSettings.locationPoints.start, 15);
    }
  }, [map, mapSettings.locationPoints.start]);

  return (
    <>
      <MapLayerLocation
        locationPoint={mapSettings.locationPoints.start}
        iconUrl={LocationIcons.start.icon}
        iconSize={[LocationIcons.start.width, LocationIcons.start.height]}
        tower={mapSettingsMode === "address" ? mapData.tower : null}
      />
      <MapLayerLocation
        locationPoint={mapSettings.locationPoints.end}
        iconUrl={LocationIcons.end.icon}
        iconSize={[LocationIcons.end.width, LocationIcons.end.height]}
        tower={null}
      />
      <MapLayerRoute
        routeDetails={mapData.routeDetails}
        tower={mapSettingsMode === "route" ? mapData.tower : null}
      />
      <MapLayerInfrastructure
        infrastructure={mapData.infrastructure}
        layerOptions={mapSettings.layerOptions}
      />
    </>
  );
};

export default MapLayer;
