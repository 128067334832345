import React from "react";
import { Button, CopyText } from "@dtpk-cc/components";
import * as styles from "./nav-tab-button.module.scss";

type NavTabButtonProps = {
  text: string;
  onClick: () => void;
  customClass?: string;
  isActive: boolean;
  disabled: boolean;
};

const NavTabButton = ({
  text,
  onClick,
  isActive,
  customClass = "",
  disabled,
}: NavTabButtonProps) => (
  <Button
    disabled={disabled}
    // variants={!isActive && Button.Variant.outline}
    variants={Button.Variant.bare}
    customClass={`${styles.navTabButton} ${
      isActive ? styles.active : ""
    } ${customClass}`}
    tabIndex={0}
    onClick={onClick}
  >
    <CopyText tag="span">{text}</CopyText>
  </Button>
);

export default NavTabButton;
