import React from "react";
import {
  Badge,
  Tile,
  Container,
  Heading,
  Price,
  CopyText,
} from "@dtpk-cc/components";

import "elements/TileItem/tile-item.scss";
import { IPrice } from "../../core/entities/Product/IPrice";
import {
  IAddon,
  ProductGroup,
} from "../../core/presenter/PencilSelling/AddonsPresenter";

type TileItemProps = {
  active?: boolean;
  disabled: boolean;
  item: ProductGroup | IAddon;
  price: IPrice;
  isProductGroup: boolean;
  additionalPriceSuffix: string;
  description?: string;
  onClick: () => void;
};

const TileItem = ({
  active = false,
  disabled = false,
  item,
  price,
  isProductGroup = false,
  additionalPriceSuffix,
  description,
  onClick,
}: TileItemProps) => {
  const { name } = item;
  const monthlyPriceSuffix = `mtl. ${additionalPriceSuffix || ""}`;
  const hasMultiplePrices =
    typeof price?.monthly === "number" && typeof price?.once === "number";
  return (
    <Tile
      customClass={`
        tile-item
        ${disabled ? "tile-item--disabled" : ""}
        ${active ? "tile-item--active" : ""}
      `}
      tabIndex={0}
      variant="shadow"
      onKeyPress={disabled ? undefined : () => onClick()}
      onClick={disabled ? undefined : () => onClick()}
      aria-label={`${item.name} auswählen`}
    >
      {(active || disabled) && (
        <Badge
          customClass="badge__disabled"
          variants={Badge.Variant.flag}
          colors={active ? Badge.Color.magenta : null}
        >
          <Badge.Paragraph
            style={{ color: "#fff" }}
            fonts={Badge.Paragraph.Font.additionalHighlight}
          >
            {active ? "Ausgewählt" : "Bereits ausgewählt"}
          </Badge.Paragraph>
        </Badge>
      )}

      <Tile.Content customClass="tile-item__content">
        <Heading customClass="tile-item__heading">{name}</Heading>
        {description && (
          <CopyText customClass="tile-item__copytext">{description}</CopyText>
        )}
        <Container customClass="tile-item__price-container">
          {typeof price?.monthly === "number" && (
            <Price
              variants={Price.Variant.brand}
              suffix={monthlyPriceSuffix}
              prefix={isProductGroup ? "ab" : ""}
              value={price.monthly}
            />
          )}
          {typeof price?.once === "number" && (
            <Price
              variants={hasMultiplePrices ? null : Price.Variant.brand}
              suffix="einmalig"
              prefix={`
                ${hasMultiplePrices ? "oder " : ""}
                ${isProductGroup ? "ab" : ""}
              `}
              value={price.once}
            />
          )}
        </Container>
      </Tile.Content>
    </Tile>
  );
};

export default TileItem;
