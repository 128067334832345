import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import type { ILandlineSettings } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ILevel, ITariffWorld } from "core/entities/Product/IProduct";
import type { IStreamingService } from "core/entities/Product/IStreamingService";
import type { IStreamingVariant } from "core/entities/Product/IStreamingVariant";
import {
  ITariff,
  businessCaseOptions,
  BusinessCasesLandline,
} from "core/entities/Product/Tariff/ITariff";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { IStreamingServicesKeys } from "../../entities/Product/IStreamingServiceKeys";

export type ISettings = {
  tariffWorld: string;
  level: string;
  businessCase: BusinessCasesLandline;
};

export type VariantKeyPrices = Record<string, number>;

export enum LevelsKinds {
  tariff_world = "tariff_world",
  addon = "addon",
}

const megathekStreamingServicesKeys = [
  IStreamingServicesKeys.ard_plus,
  IStreamingServicesKeys.zdf_select,
];

const mainStreamingServicesKeys = [
  "live_tv",
  "hd_plus",
  "disney_plus",
  "netflix",
  "rtl_plus",
];

const megaSportStreamingServicesKeys = [
  "dazn",
  "magenta_sport",
  "wow_live_sport",
];

export class StreamingAnalysisPresenter {
  private settings: ISettings = {
    tariffWorld: "",
    level: "",
    businessCase: BusinessCasesLandline.NEUKUNDE,
  };

  private priceFormat = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  constructor(
    initialSettings: ILandlineSettings,
    private services: IStreamingService[],
    private variants: IStreamingVariant[]
  ) {
    this.settings.tariffWorld = this.getInitialTariffWorld();
    this.settings.level = this.getCheapestVariant()?.level?.key || "";
    this.settings.businessCase = initialSettings.businessCase;
  }

  private getActiveServices(): IStreamingService[] {
    return this.services.filter((service) => service.active);
  }

  private getActiveServicesKeys(): string[] {
    return this.getActiveServices().map((item) => item.key);
  }

  private getVariantsByTariffWorld(): IStreamingVariant[] {
    return this.variants.filter(
      ({ tariffWorld }) => tariffWorld.key === this.settings.tariffWorld
    );
  }

  getVariantPriceByActiveServices(variant: IStreamingVariant): number {
    const activeServicesKeys = this.getActiveServicesKeys();

    const servicePrice = activeServicesKeys.reduce(
      (acc, serviceKey) =>
        (acc * 100 + variant.streamingServices[serviceKey] * 100) / 100,
      variant?.monthly
    );

    return servicePrice;
  }

  private getVariantKeyPrices(): VariantKeyPrices {
    const variants = this.getVariantsByTariffWorld();

    // returns Object of variant keys with variant sum price depending on active services
    const variantPrices = variants.reduce(
      (acc, variant) => ({
        ...acc,
        [variant.key]: this.getVariantPriceByActiveServices(variant),
      }),
      {} as VariantKeyPrices
    );

    return variantPrices;
  }

  getInitialTariffWorld() {
    return (
      this.tariffWorlds.find(
        (tariffWorld) => tariffWorld.key === TariffWorldKeys.magenta_home_tv
      ).key || ""
    );
  }

  getDisplayPrice(price: number): string {
    return this.priceFormat.format(price).replace(",00", "");
  }

  getCheapestVariant(): IStreamingVariant {
    const variants = this.getVariantsByTariffWorld();
    if (variants.length === 1) return variants[0];

    const keyPrices = this.getVariantKeyPrices();
    const lowestPrice = this.getCheapestPrice();

    const lowestKeys = Object.keys(keyPrices).filter(
      (key) => keyPrices[key] === lowestPrice
    );

    // if there are 2 streamingVariants of the same total price, we prefer the variant with selected streamingServices that cost less in total
    if (lowestKeys.length === 1)
      return variants.find((variant) => variant.key === lowestKeys[0]);
    return this.getPreferedVariant(lowestKeys);
  }

  private getPreferedVariant(lowestKeys: string[]): IStreamingVariant {
    const variants = this.getVariantsByTariffWorld();
    const comparableVariants = variants.filter((variant) =>
      lowestKeys.includes(variant.key)
    );
    const activeServiceKeys = this.getActiveServicesKeys();

    return comparableVariants.reduce((acc, variant) => {
      const previousServicePrice = activeServiceKeys.reduce(
        (servicePriceSum, activeServiceKey) =>
          servicePriceSum + acc.streamingServices[activeServiceKey],
        0
      );

      const currentServicePrice = activeServiceKeys.reduce(
        (servicePriceSum, activeServiceKey) =>
          servicePriceSum + variant.streamingServices[activeServiceKey],
        0
      );

      return previousServicePrice >= currentServicePrice ? variant : acc;
    }, comparableVariants[0]);
  }

  private getCheapestPrice(): number {
    const keyPrices = this.getVariantKeyPrices();
    const lowestPrice = Object.values(keyPrices).reduce((acc, price) => {
      const getLowerPrice = (): number => (price < acc ? price : acc);
      return acc ? getLowerPrice() : price;
    }, 0);

    return lowestPrice;
  }

  getSettings(): ISettings {
    return this.settings;
  }

  setSetting(key: keyof ISettings, value: string): void {
    if (key === "businessCase")
      this.settings[key] = value as BusinessCasesLandline;
    else if (key === "tariffWorld") {
      this.settings[key] = value;
      // reset level to cheapest variant on tariffWorld change
      const level = this.getCheapestVariant()?.level?.key || "";
      this.settings.level = level;
    } else this.settings[key] = value;

    this.settings = { ...this.settings };
  }

  getPromotions(tariff: ITariff): IPromotion[] {
    return (
      tariff?.promotions?.filter((promotion) =>
        promotion.conditions.includes(this.settings.businessCase)
      ) || []
    );
  }

  isPromotionActive(tariff: ITariff): boolean {
    return (
      this.getStreamingVariant.kind === LevelsKinds.tariff_world &&
      !!this.getPromotions(tariff).length
    );
  }

  getBusinessCaseName(): string {
    return (
      businessCaseOptions.find(
        (businessCase) => businessCase.key === this.settings.businessCase
      )?.name || ""
    );
  }

  exportOptionKeys(): string[] {
    const variant = this.getStreamingVariant;
    const initialOptions =
      variant.productKey && variant.kind === LevelsKinds.addon
        ? [variant.productKey]
        : [];
    const optionKeys = this.getActiveServices().reduce(
      (acc, { key, productKey }) => {
        const hasValue = productKey && variant.streamingServices[key];
        return hasValue ? [...acc, productKey] : acc;
      },
      initialOptions
    );
    return optionKeys;
  }

  get priceSum(): number {
    return this.getVariantPriceByActiveServices(this.getStreamingVariant);
  }

  get getStreamingVariant(): IStreamingVariant {
    // returns variant by current settings
    return this.variants.find(
      ({ tariffWorld, level }) =>
        tariffWorld.key === this.settings.tariffWorld &&
        (level?.key || "") === this.settings.level
    );
  }

  get isRecommendatedVariantActive(): boolean {
    return this.getCheapestVariant()?.key === this.getStreamingVariant?.key;
  }

  get tariffWorlds(): ITariffWorld[] {
    return this.variants.reduce((acc, { tariffWorld }) => {
      const worldExists = acc.some((item) => item.key === tariffWorld.key);
      return worldExists ? acc : [...acc, tariffWorld];
    }, [] as ITariffWorld[]);
  }

  get levels(): ILevel[] {
    const result = this.variants.reduce((acc, { tariffWorld, level }) => {
      if (!level || this.settings.tariffWorld !== tariffWorld.key) return acc;

      const levelExists = acc.some((item) => item.key === level.key);
      return levelExists ? acc : [...acc, level];
    }, [] as ILevel[]);

    return result;
  }

  get megaSportStreamingServices(): IStreamingService[] {
    return this.services.reduce(
      (acc, service) =>
        megaSportStreamingServicesKeys.includes(service.key)
          ? [...acc, service]
          : acc,
      [] as IStreamingService[]
    );
  }

  get megathekStreamingServices(): IStreamingService[] {
    return this.services.reduce(
      (acc, service) =>
        megathekStreamingServicesKeys.includes(service.key)
          ? [...acc, service]
          : acc,
      [] as IStreamingService[]
    );
  }

  get mainStreamingServicesKeys(): IStreamingService[] {
    return this.services.reduce(
      (acc, service) =>
        mainStreamingServicesKeys.includes(service.key)
          ? [...acc, service]
          : acc,
      [] as IStreamingService[]
    );
  }

  getAdditionalProductKeys(): string[] {
    return this.variants.reduce(
      (acc, { productKey }) => (productKey ? [...acc, productKey] : acc),
      [] as string[]
    );
  }
}
