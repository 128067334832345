import React, { useEffect, useRef } from "react";
import { Icon, Button } from "@dtpk-cc/components";
import { ActionCloseDefault } from "@dtpk-cc/components/dist/icons";
import BackDrop from "./BackDrop";

import * as styles from "./modal-wrapper.module.scss";

type ModalWrapperProps = {
  onClose: () => void;
  isOpen: boolean;
  containerCustomClass?: string;
  backDropCustomClass?: string;
  onBackDropClick?: () => void;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  isOpen,
  containerCustomClass = "",
  backDropCustomClass = "",
  onClose,
  onBackDropClick = () => {},
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  return isOpen ? (
    <BackDrop
      onClick={onBackDropClick}
      customClass={`${styles.backDrop} ${backDropCustomClass}`}
      withPortal
    >
      <div
        ref={modalRef}
        role="dialog"
        aria-modal="true"
        className={`${containerCustomClass} ${styles.modalWrapper}`}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onKeyUp={(event) => {
          if (event.key === "Escape") {
            onClose();
          }
        }}
        tabIndex={-1}
      >
        <Button
          onClick={onClose}
          variants={Button.Variant.bare}
          customClass={styles.closeBtn}
        >
          <Icon size={Icon.Size.small} icon={ActionCloseDefault} />
        </Button>

        {children}
      </div>
    </BackDrop>
  ) : null;
};

export default ModalWrapper;
