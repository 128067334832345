import type {
  IOffer
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IPromotionSections } from "core/entities/PencilSelling/IPromotionSections";
import type { ISetCustomPromotionsUseCase } from "./ISetCustomPromotions";

export class SetCustomPromotionsUseCase implements ISetCustomPromotionsUseCase {
  constructor(private entity: IOffer) {}

  execute(promotions: IPromotionSections): void {
    this.entity.setCustomPromotions(promotions);
  }
}
