import React from "react";
import { observer } from "mobx-react";

import OfferSelection from "components/OfferSelection";
import { useStores } from "../../../../stores";

const OfferFinalization = observer(({ distributionData }) => {
  const { offerStore } = useStores();

  const {
    customer,
    validUntil: { date: validUntil },
    profileDetailsInPdfIsDisplayed,
  } = offerStore;
  const { consultingTime } = customer;

  const handleNextAppointmentChange = (value, key) => {
    offerStore.customer.set({
      ...customer,
      consultingTime: {
        ...consultingTime,
        [key]: value,
      },
    });
  };

  const handleDateChange = (value) => offerStore.setValidUntil(value);

  const toggleProfileDataInPdfIsDisplayed = (isDisplayed) =>
    offerStore.setProfileDetailsInPdfIsDisplayed(isDisplayed);

  return (
    <OfferSelection
      validUntil={validUntil}
      consultingTime={consultingTime}
      onNextAppointmentChange={handleNextAppointmentChange}
      onDateChange={handleDateChange}
      toggleDistributionDataDisplay={toggleProfileDataInPdfIsDisplayed}
      showDistributionData={profileDetailsInPdfIsDisplayed}
      distributionData={distributionData}
    />
  );
});

export default OfferFinalization;
