import React from "react";
import { HintBox, Icon } from "@dtpk-cc/components";
import { ActionSuccessDefault } from "@dtpk-cc/components/dist/icons";

import * as styles from "./notify-hint-box.module.scss";

const NotifyHintBox = () => (
  <div className={styles.notifyHintBox}>
    <HintBox>
      <div className={styles.content}>
        <Icon
          customClass={styles.icon}
          icon={ActionSuccessDefault}
          size={Icon.Size.medium}
          wrapperProps={{
            className: styles.iconWrapper,
          }}
        />
        <div className={styles.textWrapper}>
          <HintBox.Heading>
            Ihr Zuhause ist bereits optimal vernetzt
          </HintBox.Heading>
          <HintBox.Text>
            Bei der Größe reicht das WLAN Signal Ihres Routers aus. Für
            verwinkelte Wohnsituationen empfehlen wir einen zusätzlichen
            Repeater.
          </HintBox.Text>
        </div>
      </div>
    </HintBox>
  </div>
);

export default NotifyHintBox;
