import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import { CopyText, Heading } from "@dtpk-cc/components";
import AddressCheckWrapper from "../../elements/new-design/AddressCheck/AddressCheckWrapper";
import AddressCheckContainer from "../../elements/new-design/AddressCheck/AddressCheckContainer";
import AddressCheckHeader from "../../elements/new-design/AddressCheck/AddressCheckHeader";
import PeopleImg from "../../../assets/images/address-check/fiber-check-people.png";
import HeaderBgImg from "../../../assets/images/address-check/fiber-check-header-bg.png";
import BgImg from "../../../assets/images/address-check/fiber-bg.png";
import { Endpoints } from "../../core/repositories/ProductsRepository/IProductsRepository";
import AddressCheckInputWrapper from "../../elements/new-design/AddressCheck/AddressCheckInputWrapper";
import AddressCheckMsg from "../../elements/new-design/AddressCheck/AddressCheckMsg";
import * as styles from "./fiber-address-check.module.scss";

const FiberAddressCheck = () => {
  const [selectedAddress, setSelectedAddress] = useState<string>(null);

  return (
    <AddressCheckWrapper bgImgUrl={BgImg} imgAltValue="main-bg-img">
      <div className={styles.bgImgWrapper}>
        <img src={PeopleImg} alt="people-img" className={styles.peopleImg} />
      </div>
      <AddressCheckContainer>
        <AddressCheckHeader customClass={styles.headerWrapper}>
          <Heading
            tag="h1"
            isResponsive={false}
            data-text="treueangebot für"
            variants={[
              Heading.Variant.uppercase,
              Heading.Variant.highlight,
              Heading.Variant.hero,
            ]}
            customClass={`${styles.headerFirstTitle} ${styles.headerTitle} ${styles.headerItem}`}
          >
            treueangebot für
          </Heading>
          <Heading
            tag="h1"
            isResponsive={false}
            data-text="ausgewählte kunden"
            variants={[
              Heading.Variant.hero,
              Heading.Variant.uppercase,
              Heading.Variant.highlight,
            ]}
            customClass={`${styles.headerItem} ${styles.headerTitle} ${styles.headerSecondTitle}`}
          >
            ausgewählte kunden
          </Heading>
          <Heading
            isResponsive={false}
            customClass={`${styles.subHeading} ${styles.headerItem}`}
            variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
          >
            Ausgewählte MagentaZuhause Kunden können in bestimmten
            Ausbaugebieten des Wettbewerbs zwischen den beiden Angeboten “Next
            Speed for free” und <br /> “3x 19,95 € / Monat” wählen.
          </Heading>
          <img
            src={HeaderBgImg}
            alt="header-bg-img"
            className={styles.headerBgImg}
          />
        </AddressCheckHeader>
        <AddressCheckInputWrapper
          requestUrl={Endpoints.fiber_address_check}
          onAddressSelect={(address) => setSelectedAddress(address)}
          selectedAddress={selectedAddress}
        >
          <CopyText customClass={styles.addressCheckDescr}>
            Adresse eingeben und prüfen, ob die Aktion „Next Speed for free“
            oder “3x 19,95 € / Monat” verfügbar ist
          </CopyText>
        </AddressCheckInputWrapper>
        {selectedAddress && (
          <AddressCheckMsg text="Die Aktion „Next Speed for free“  oder “3x 19,95 € / Monat”  ist verfügbar." />
        )}
      </AddressCheckContainer>
    </AddressCheckWrapper>
  );
};

export default hot(FiberAddressCheck);
