import React from "react";
import { Button } from "@dtpk-cc/components";
import { ActionCloseDefault } from "@dtpk-cc/components/dist/icons";

const PencilSellingToolbarCloseBtn = ({ onClick, customClass }) => (
  <Button
    onClick={onClick}
    customClass={`pencil-selling-toolbar__close-btn ${customClass}`}
    variants={Button.Variant.outline}
  >
    <Button.Icon icon={ActionCloseDefault} />
    <span>Schließen</span>
  </Button>
);

export default PencilSellingToolbarCloseBtn;
