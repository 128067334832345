import React from "react";
import { Tile } from "@dtpk-cc/components";

import * as styles from "./tile-container.module.scss";
import { TileContainerVariants } from "./ITileContainer";

type TileContainerProps = {
  customClass: string;
  variant: TileContainerVariants;
};

const TileContainer: React.FC<Partial<TileContainerProps>> = ({
  customClass = "",
  variant = TileContainerVariants.blue,
  children,
}) => (
  <Tile customClass={`${styles.tileWrapper} ${styles[variant]} ${customClass}`}>
    {children}
  </Tile>
);

export default TileContainer;
