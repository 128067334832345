export interface IGridBaseProps {
  s?: 1 | 2 | 3 | 4 | 5 | 6;
  m?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  l?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
}

interface ISummarySectionGridColData {
  main: IGridBaseProps;
  monthlyPrice: IGridBaseProps;
  oncePrice: IGridBaseProps;
}

export const summarySectionGridColData: ISummarySectionGridColData = {
  main: {
    s: 3,
    m: 7,
    l: 7,
    xl: 14,
  },
  monthlyPrice: {
    s: 2,
    m: 3,
    l: 3,
    xl: 6,
  },
  oncePrice: {
    s: 1,
    m: 2,
    l: 2,
    xl: 4,
  },
};
