import React from "react";
import { Heading } from "@dtpk-cc/components";
import TileContainer from "../../../../../components/new-design/TileContainer";
import TileContainerTitle from "../../../../../components/new-design/TileContainer/TileContainerTitle";
import { Offer } from "../../../../../core/entities/PencilSelling/PrivateClient/Offer/Offer";
import Input from "../../../../../elements/new-design/Input";
import { onlyNumber } from "../../../../../helpers/NumericCalculation";
import { trackClick } from "../../../../../helpers/reactTracking";

import { TileContainerVariants } from "../../../../../components/new-design/TileContainer/ITileContainer";

type PrevContractDataProps = {
  trackingContext: string;
  offerStore: Offer;
  customClass?: string;
};

const PrevContractData = ({
  customClass = "",
  offerStore,
  trackingContext,
}: PrevContractDataProps) => (
  <div className={customClass}>
    <TileContainer variant={TileContainerVariants.outline}>
      <TileContainerTitle
        variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
        title="Bisherige Verträge"
      />
      <Input
        id="prev-contract-price"
        value={offerStore.getPrevContractPrice()}
        onChange={({ target: { value } }) =>
          offerStore.setPrevContractPrice(value)
        }
        onClick={() => {
          trackClick("prev-contract-price.input", trackingContext);
        }}
        onKeyPress={(e) => onlyNumber(e)}
        placeholder="Kosten der bisherigen Verträge zum Vergleichen"
      />
    </TileContainer>
  </div>
);

export default PrevContractData;
