import type { IOffer, INotes } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetNotesUseCase } from "./ISetNotes";

export class SetNotesUseCase implements ISetNotesUseCase {
  constructor(private entity: IOffer) {}

  execute(notes: INotes): void {
    this.entity.setNotes(notes);
  }
}
