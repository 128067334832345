import type {
  IOffer
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IPromotionSections } from "core/entities/PencilSelling/ICustomPromotion";
import type { IGetCustomPromotionsUseCase } from "./IGetCustomPromotions";

export class GetCustomPromotionsUseCase implements IGetCustomPromotionsUseCase {
  constructor(private entity: IOffer) {}

  execute(): IPromotionSections {
    return this.entity.getCustomPromotions();
  }
}
