import React, { useMemo } from "react";
import { TextLink } from "@dtpk-cc/components";
import ContinueOutline from "@dtpk-cc/components/dist/icons/Lpg/ContinueOutline";
import * as styles from "../tariff-selection.module.scss";
import { ICartConfigurationSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { ITariffWorld } from "../../../../../core/entities/Product/IProduct";

type TariffLinkProps = {
  settings: ICartConfigurationSettings;
  activeTariffWorld: ITariffWorld;
};

const TariffLink = ({ settings, activeTariffWorld }: TariffLinkProps) => {
  const tariffLink =
    "isYoung" in settings && settings.isYoung
      ? activeTariffWorld?.links?.young
      : activeTariffWorld?.links?.default;

  const tariffLinkDescription = useMemo(
    () => activeTariffWorld?.links.link_title || null,
    [activeTariffWorld]
  );

  return (
    tariffLinkDescription &&
    tariffLink && (
      <TextLink
        customClass={styles.tariffLink}
        href={tariffLink}
        target="_blank"
        iconRight={ContinueOutline}
      >
        {tariffLinkDescription}
      </TextLink>
    )
  );
};

export default TariffLink;
