import React from "react";
import { observer } from "mobx-react";

import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import AddonsSelection from "../AddonsSelection";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import { getBusinessCaseName } from "../../../helpers/GetBusinessCaseName";
import { businessCaseOptions } from "../../../core/entities/Product/Tariff/ITariff";
import PageWrapper from "../../../elements/new-design/PageWrapper";
import StepHeader from "../StepHeader";
import PageInner from "../../../elements/new-design/PageWrapper/PageInner";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsEntity } from "../../../core/entities/PencilSelling/Products/ProductsEntity";
import {
  ICustomAddon,
  ICustomAddonFormData,
} from "../AddonsSelection/IAddonsSelection";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { Endpoints } from "../../../core/repositories/ProductsRepository/IProductsRepository";
import { getCustomDevicesDomainType } from "../../../helpers/GetCustomDevicesDomainType";
import { CustomAddonsRequestKeys } from "../../../core/repositories/ProductsRepository/ResponseInterface";

interface IDevices {
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  productsStore: ProductsEntity;
  allowCustomProducts: boolean;
  trackingContext: string;
  subtitle: string;
  customAddonContractPeriod: number;
  customAddonFormData: ICustomAddonFormData;
  productsRepositoryStore: ProductsRepository;
}

const Devices = ({
  configurationsStore,
  offerStore,
  productsStore,
  allowCustomProducts,
  subtitle,
  trackingContext,
  customAddonContractPeriod,
  customAddonFormData,
  productsRepositoryStore,
}: IDevices) => {
  const devices = productsStore.getDevices();
  const devicesInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getDevices();
  const businessCase = offerStore.getBusinessCase(
    ICartConfigurationType.LANDLINE
  );
  const customDomainType = getCustomDevicesDomainType(offerStore);

  const addCustomDeviceHandler = async (customDevice: ICustomAddon) => {
    offerStore.setIsLoading(true);
    try {
      const customDeviceResponse =
        await productsRepositoryStore.createCustomAddon(
          Endpoints.handle_custom_devices,
          customDomainType,
          customDevice,
          CustomAddonsRequestKeys.DEVICE
        );
      const customDevices = offerStore.getCustomDevices();
      offerStore.setCustomDevices([...customDevices, customDeviceResponse]);

      return customDeviceResponse;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    } finally {
      offerStore.setIsLoading(false);
    }
  };

  const removeCustomDeviceHandler = async (customDeviceKey: string) => {
    offerStore.setIsLoading(true);
    try {
      await productsRepositoryStore.deleteCustomAddon(
        Endpoints.handle_custom_devices,
        customDomainType,
        customDeviceKey
      );
      offerStore.removeCustomDevice(customDeviceKey);
    } catch (error) {
      console.log(error);
    } finally {
      offerStore.setIsLoading(false);
    }
    return null;
  };

  return (
    <PageWrapper>
      <StepHeader title="Geräte" subtitle={subtitle} />
      <PageInner>
        <AddonsSelection
          isFungible
          trackingContext={trackingContext}
          products={devices}
          activeProducts={devicesInCart}
          allowCustomProducts={allowCustomProducts}
          condition={null}
          portfolio={PortfolioKeys.LANDLINE}
          addonGroup={Group.device}
          businessCaseName={getBusinessCaseName(
            businessCase,
            businessCaseOptions
          )}
          configurationsStore={configurationsStore}
          customAddonContractPeriod={customAddonContractPeriod}
          customAddonFormData={customAddonFormData}
          addCustomAddon={addCustomDeviceHandler}
          removeCustomAddon={removeCustomDeviceHandler}
          customAddons={offerStore.getCustomDevices()}
        />
      </PageInner>
    </PageWrapper>
  );
};

export default observer(Devices);
