// replaced by CMS start screen
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Button,
  CopyText,
  Container,
  ThemeProvider,
  Theme,
  Modal,
  Heading,
  TextLink,
  List,
} from "@dtpk-cc/components";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "./intro.scss";

const Intro = ({ onButtonClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <ThemeProvider value={Theme.dark}>
      <Container customClass="pst-intro">
        <Container customClass="pst-intro__container">
          <div>
            <Heading
              tag="h1"
              customClass="pst-intro__heading"
              variants={[Heading.Variant.primary]}
            >
              Angebote für Kunden erstellen{" "}
            </Heading>
            <CopyText customClass="pst-intro__copy">
              In wenigen Schritten zum passenden Angebot
            </CopyText>
            <CopyText customClass="pst-intro__copy">
              Erstelle und drucke in wenigen Schritten Mobilfunk- und
              Festnetz-Angebote für deine Kunden.
            </CopyText>
            <Button customClass="pst-intro__button" onClick={onButtonClick}>
              Angebot erstellen
            </Button>
            <CopyText>
              <strong>NEU:</strong>{" "}
              <TextLink onClick={openModal}>
                Angebotserstellung im beschreibbaren PDF und zusätzliche neue
                PDF ergänzend zum Angebotstool zur Bedarfs- und Umfeldanalyse
              </TextLink>
            </CopyText>
          </div>
          <CopyText>
            Du hast Anregungen und möchtest bei der Weiterentwicklung dabei
            sein?{" "}
            <TextLink target="_blank" href="/feedback">
              Schreib&nbsp;uns!
            </TextLink>
          </CopyText>
        </Container>

        <Modal
          isOpen={modalOpen}
          portalless
          afterClose={() => setModalOpen(false)}
          zIndex={150}
        >
          <Modal.Section>
            <Heading
              customClass="pst-intro__copy"
              tag="h4"
              variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
            >
              Folgende Hilfen zur Angebotserstellung haben wir dir hier
              zusätzlich als PDF-Vorlagen erstellt.
            </Heading>
            <List variants={List.Variant.ordered}>
              <List.Item customClass="pst-intro__copy">
                <CopyText customClass="pst-intro__copy">
                  Das bekannte Pencil Selling PDF für die komplette
                  handschriftliche Angebotserstellung im Microsoft Edge Browser
                  direkt mit Dell Active-Pen bearbeiten und drucken.
                </CopyText>
                <Button
                  customClass="pst-intro__modal-button"
                  tag="a"
                  href="/downloads/PencilSelling_PK.pdf"
                  download
                >
                  PDF erstellen PK
                </Button>
              </List.Item>
              <List.Item customClass="pst-intro__copy">
                <CopyText customClass="pst-intro__copy">
                  Das Selling PDF für die handschriftliche Angebotserstellung in
                  der Variante für Geschäftskunden (für Telekom Shops). Auch
                  dieses lässt sich bearbeiten und drucken.
                </CopyText>
                <Button
                  customClass="pst-intro__modal-button"
                  tag="a"
                  href="/downloads/PencilSelling_GK_TelekomShops.pdf"
                  download
                >
                  PDF erstellen GK
                </Button>
              </List.Item>
              <List.Item customClass="pst-intro__copy">
                <CopyText customClass="pst-intro__copy">
                  Das Selling PDF für die handschriftliche Angebotserstellung in
                  der Variante für Geschäftskunden (für Handel). Auch dieses
                  lässt sich bearbeiten und drucken.
                </CopyText>
                <Button
                  customClass="pst-intro__modal-button"
                  tag="a"
                  href="/downloads/PencilSelling_GK_Handel.pdf"
                  download
                >
                  PDF erstellen GK
                </Button>
              </List.Item>
              <List.Item customClass="pst-intro__copy">
                <CopyText customClass="pst-intro__copy">
                  Das neue Pencil Selling PDF ergänzend zum Angebotstoolals
                  Leitfaden und Einstieg zur Bedarfs -und Umfeldanalyse,
                  ebenfalls im Edge Browser direkt mit dem Pen bearbeitbar und
                  druckbar.
                </CopyText>
                <Button
                  customClass="pst-intro__modal-button"
                  tag="a"
                  href="/downloads/Bedarfs-_und_Umfeldanalyse.pdf"
                  download
                >
                  PDF erstellen PK
                </Button>
              </List.Item>
            </List>
            <CopyText>
              <strong>Hinweise:</strong>
            </CopyText>
            <List customClass="pst-intro__copy">
              <List.Item>
                <CopyText>
                  Bearbeite die PDF im Microsoft Edge Browser mit deinem
                  Active-Pen oder Tastatur
                </CopyText>
              </List.Item>
              <List.Item>
                <CopyText>
                  Stiftstärke, Farbe und Textmarker- Funktion in Funktionsleiste
                  auswählbar
                </CopyText>
              </List.Item>
              <List.Item>
                <CopyText>Radieren über Funktionstaste auf Active-Pen</CopyText>
              </List.Item>
              <List.Item>
                <CopyText>
                  Runtergeladene PDF-Unterlagen öffnest du über das Kontextmenü
                  der rechten Maustaste und „Öffnen mit Microsoft Edge“
                </CopyText>
              </List.Item>
              <List.Item>
                <CopyText>
                  Nach Fertigstellung können alle Varianten über die
                  Druckfunktion einfach ausgedruckt werden.
                </CopyText>
              </List.Item>
            </List>
            <CopyText>
              <strong>Datenschutzhinweis:</strong> Bitte stellen Sie aus
              Datenschutzgründen sicher, dass keine personenbezogen - und/oder
              personenbeziehbaren Daten (z.B. Legitimationsdaten, Bankdaten,
              Adressdaten, sonstige Klartextdaten die Rückschluss auf eine
              Person ermöglichen, etc.) in das Freitextfeld eingetragen werden.
            </CopyText>
          </Modal.Section>
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

Intro.propTypes = {
  onButtonClick: PropTypes.func,
};
export default Intro;
