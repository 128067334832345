import React from "react";
import { Button, A11yText } from "@dtpk-cc/components";
import "./custom-toggle-button.scss";

enum CustomToggleButtonSizes {
  sm = "sm",
  m = "m",
  l = "l",
}

type CustomToggleButtonProps = {
  checked: boolean;
  customClass?: string;
  onClick: () => void;
  size?: string;
};

const CustomToggleButton = ({
  checked,
  customClass = "",
  onClick,
  size = CustomToggleButtonSizes.sm,
}: CustomToggleButtonProps) => (
  <Button
    onClick={onClick}
    variants={Button.Variant.bare}
    customClass={`toggle-button toggle-button--${size} ${
      checked ? "toggle-button--active" : ""
    } ${customClass}`}
    role="radio"
  >
    <A11yText>Tariftyp</A11yText>
    <span className="toggle-button__switcher" />
  </Button>
);

export default CustomToggleButton;
