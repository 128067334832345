import { BenefitKeys } from "../core/entities/Product/IProduct";
import type { IBenefit } from "../core/entities/Product/IBenefit";
import { IBenefitOption } from "../core/entities/Product/IBenefit";

export const getHappyHourBenefitOptionName = (
  benefit: IBenefit | null
): string | null =>
  benefit &&
  (benefit.options.find((option) => option.key === BenefitKeys.happyHour)
    ?.name ||
    null);

export const getDiscountOption = (
  benefit: IBenefit | null
): IBenefitOption | null =>
  benefit &&
  (benefit.options.find((option) => option.key === BenefitKeys.discount) ||
    null);
