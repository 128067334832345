import React, { ElementType } from "react";
import TariffConfigurationTileButton from "components/new-design/Tariff/TariffConfiguration/TariffConfigurationTileButton";
import {
  MapLayerOptionsKey,
  MapSettings,
  MapSettingsSetter,
} from "views/Map/hooks/types";
import PlannedTowerIconBlack from "../../../../../assets/images/icons/coverage-map/planned_tower_black.svg";
import PlannedTowerIconMagenta from "../../../../../assets/images/icons/coverage-map/planned_tower_magenta.svg";
import ShopIconBlack from "../../../../../assets/images/icons/coverage-map/t_store_black.svg";
import ShopIconMagenta from "../../../../../assets/images/icons/coverage-map/t_store_magenta.svg";
import TowerIconBlack from "../../../../../assets/images/icons/coverage-map/tower_black.svg";
import TowerIconMagenta from "../../../../../assets/images/icons/coverage-map/tower_magenta.svg";
import * as styles from "./map-sidebar-radius-search.module.scss";
import { trackClick } from "helpers/reactTracking";
import { MAP_FEATURE_CONTENT } from "views/Map/constants/const";

type MapSidebarRadiusSearchProps = {
  mapSettings: MapSettings;
  mapSettingsSetter: MapSettingsSetter;
};

type MapSidebarRadiusSearchElement = {
  subtitle: string;
  title: string;
  Icon: ElementType;
  ActiveIcon: ElementType;
  keys: MapLayerOptionsKey[];
  disabled: boolean;
};

const MapSidebarRadiusSearch = ({
  mapSettings,
  mapSettingsSetter,
}: MapSidebarRadiusSearchProps) => {
  const radiusSearchElements: MapSidebarRadiusSearchElement[] = [
    {
      title: "Telekom Shops",
      subtitle: "Verfügbarkeit in der Umgebung",
      Icon: ShopIconBlack,
      ActiveIcon: ShopIconMagenta,
      keys: ["telekom_shop", "telekom_partner_shop"],
      disabled: mapSettings.hideInfrastructure,
    },
    {
      title: "Masten",
      subtitle: "5G und 4G",
      Icon: TowerIconBlack,
      ActiveIcon: TowerIconMagenta,
      keys: ["4g_or_5g_tower", "5g_booster_tower"],
      disabled: mapSettings.hideInfrastructure,
    },
    {
      title: "Geplante Masten",
      subtitle: "Geplanter und bereits begonnener 5G-Ausbau",
      Icon: PlannedTowerIconBlack,
      ActiveIcon: PlannedTowerIconMagenta,
      keys: ["5g_booster_planned_tower"],
      disabled: mapSettings.hideInfrastructure,
    },
  ];

  const isActive = (keys: MapLayerOptionsKey[]) =>
    keys.every((key) => mapSettings.layerOptions[key]);

  return (
    <div className={`${styles.radiusSearchContainer}`}>
      {radiusSearchElements.map((el) => (
        <TariffConfigurationTileButton
          customClass={`${styles.radiusSearchElement} ${
            isActive(el.keys) ? styles.active : ""
          }`}
          Icon={isActive(el.keys) ? el.ActiveIcon : el.Icon}
          onClick={() => {
            trackClick(
              `umkreissuche.${el.keys}.${
                isActive(el.keys) ? "ausblenden" : "einblenden"
              }`,
              MAP_FEATURE_CONTENT
            );
            mapSettingsSetter.toggleMapLayerOptions(el.keys);
          }}
          subtitle={el.subtitle}
          title={el.title}
          key={el.keys.join("")}
          disabled={el.disabled}
        />
      ))}
    </div>
  );
};

export default MapSidebarRadiusSearch;
