import React, { ElementType } from "react";
import { Button, Icon, CopyText } from "@dtpk-cc/components";

import * as styles from "./link-btn.module.scss";

type LinkBtnProps = {
  icon?: ElementType;
  onClick: () => void;
  text: string;
  customClass?: string;
  iconWrapperCustomClass?: string;
};

const LinkBtn = ({ icon, text, onClick, customClass = "", iconWrapperCustomClass = "" }: LinkBtnProps) => (
  <Button
    tabIndex={0}
    customClass={`${styles.linkBtn} ${customClass}`}
    variants={Button.Variant.bare}
    onClick={onClick}
  >
    {icon && (
      <Icon
        wrapperProps={{ className: `${styles.linkBtnIcon} ${iconWrapperCustomClass}` }}
        icon={icon}
        size={Icon.Size.xsmall}
      />
    )}
    <CopyText>{text}</CopyText>
  </Button>
);

export default LinkBtn;
