import { OfferEntity } from "core/entities/PencilSelling/Offer_legacy/Offer";
import { Cart } from "core/entities/PencilSelling/Cart/Cart";
import { ProductsEntity } from "core/entities/PencilSelling/Products/ProductsEntity";
import { ProductsRepository } from "core/repositories/ProductsRepository/ProductsRepository";
import { FetchProductsUseCase } from "core/usecases/PencilSelling/FetchProducts/FetchProducts";
import { GetTariffsUseCase } from "core/usecases/PencilSelling/GetTariffs/GetTariffs";
import { GetLandlineSettingsUseCase } from "core/usecases/PencilSelling/GetLandlineSettings/GetLandlineSettings";
import { SetLandlineSettingsUseCase } from "core/usecases/PencilSelling/SetLandlineSettings/SetLandlineSettings";
import { SetMobileSettingsUseCase } from "core/usecases/PencilSelling/SetMobileSettings/SetMobileSettings";
import { GetMobileSettingsUseCase } from "core/usecases/PencilSelling/GetMobileSettings/GetMobileSettings";
import { GetCardSettingsUsecase } from "core/usecases/PencilSelling/GetCardSettings/GetCardSettings";
import { SetCardSettingsUsecase } from "core/usecases/PencilSelling/SetCardSettings/SetCardSettings";
import { GetCustomerUseCase } from "core/usecases/PencilSelling/GetCustomer/GetCustomer";
import { SetCustomerUseCase } from "core/usecases/PencilSelling/SetCustomer/SetCustomer";
import { GetTariffsInCartUseCase } from "core/usecases/PencilSelling/GetTariffsInCart/GetTariffsInCart";
import { GetCardsInCartUseCase } from "core/usecases/PencilSelling/GetCardsInCart/GetCardsInCart";
import { GetDevicesInCartUseCase } from "core/usecases/PencilSelling/GetDevicesInCart/GetDevicesInCart";
import { GetOptionsInCartUseCase } from "core/usecases/PencilSelling/GetOptionsInCart/GetOptionsInCart";
import { AddToCartUseCase } from "core/usecases/PencilSelling/AddToCart/AddToCart";
import { ReplaceCartItemUseCase } from "core/usecases/PencilSelling/ReplaceCartItem/ReplaceCartItem";
import { SetCartItemQuantityUseCase } from "core/usecases/PencilSelling/SetCartItemQuantity/SetCartItemQuantity";
import { DeleteFromCartUseCase } from "core/usecases/PencilSelling/DeleteFromCart/DeleteFromCart";
import { RemoveTariffFromCart } from "core/usecases/PencilSelling/RemoveTariffFromCart/RemoveTariffFromCart";
import { GetNotesUseCase } from "core/usecases/PencilSelling/GetNotes/GetNotes";
import { SetNotesUseCase } from "core/usecases/PencilSelling/SetNotes/SetNotes";
import { GetBenefitUseCase } from "core/usecases/PencilSelling/GetBenefit/GetBenefit";
import { SetBenefitUseCase } from "core/usecases/PencilSelling/SetBenefit/SetBenefit";
import { GetCustomPromotionsUseCase } from "core/usecases/PencilSelling/GetCustomPromotions/GetCustomPromotions";
import { SetCustomPromotionsUseCase } from "core/usecases/PencilSelling/SetCustomPromotions/SetCustomPromotions";
import { GetCardsUseCase } from "core/usecases/PencilSelling/GetCards/GetCards";
import { GetDevicesUseCase } from "core/usecases/PencilSelling/GetDevices/GetDevices";
import { GetOptionsUseCase } from "core/usecases/PencilSelling/GetOptions/GetOptions";
import { GetOfferValidUntilUseCase } from "core/usecases/PencilSelling/GetOfferValidUntil/GetOfferValidUntil";
import { SetOfferValidUntilUseCase } from "core/usecases/PencilSelling/SetOfferValidUntil/SetOfferValidUntil";
import { SubmitEmailUseCase } from "core/usecases/PencilSelling/SubmitEmail/SubmitEmail";
import { ResetOfferUseCase } from "core/usecases/PencilSelling/ResetOffer/ResetOffer";
import { ResetCartUseCase } from "core/usecases/PencilSelling/ResetCart/ResetCart";
import { GetOfferExport } from "core/usecases/PencilSelling/GetOfferExport/GetOfferExport";
import { FindProductInCartUseCase } from "core/usecases/PencilSelling/FindProductInCart/FindProductInCart";
import { FetchFileUseCase } from "./usecases/PencilSelling/FetchFile/FetchFile";

import { ResetCoreCardsInCartUseCase } from "./usecases/PencilSelling/ResetCards/ResetCoreCardsInCart";
import { UpdateDynamicCardDescriptionsUseCase } from "./usecases/PencilSelling/UpdateDynamicCardDescriptions/UpdateDynamicCardDescriptions";
import { ServicesEntity } from "./entities/PencilSelling/Services/ServicesEntity";
import { GetStreamingServicesUseCase } from "./usecases/PencilSelling/GetStreamingServices/GetStreamingServices";
import { GetStreamingVariantsUseCase } from "./usecases/PencilSelling/GetStreamingVariants/GetStreamingVariants";
import { SetStreamingServiceActiveUseCase } from "./usecases/PencilSelling/SetStreamingServiceActive/SetStreamingServiceActive";
import { GetStreamingServicesPriceUseCase } from "./usecases/PencilSelling/GetStreamingServicesPrice/GetStreamingServicesPrice";
import { HomeConfigurator } from "./entities/PencilSelling/HomeConfigurator/HomeConfigurator";
import { SubmitPdfQrCodeUseCase } from "./usecases/PencilSelling/SubmitPdfQrCode/SubmitPdfQrCode";
import { ShowLoyaltyBonusUseCase } from "./usecases/PencilSelling/ShowLoyaltyBonus/ShowLoyaltyBonus";
import { RemoveCustomPromotionUseCase } from "./usecases/PencilSelling/RemoveCustomPromotion/RemoveCustomPromotion";
import { ReplaceCustomPromotionUseCase } from "./usecases/PencilSelling/ReplaceCustomPromotion/ReplaceCustomPromotion";
import { GetProfileDetailsInPdfIsDisplayedUseCase } from "./usecases/PencilSelling/GetProfileDetailsInPdfIsDisplayed/GetProfileDetailsInPdfIsDisplayed";
import { SetProfileDetailsInPdfIsDisplayedUseCase } from "./usecases/PencilSelling/SetProfileDetailsInPdfIsDisplayed/SetProfileDetailsInPdfIsDisplayed";
import { RemoveMultisimFromCartUseCase } from "./usecases/PencilSelling/RemoveMultisimFromCart/RemoveMultisimFromCart";
import { PostCustomPromotionUseCase } from "./usecases/PencilSelling/PostCustomPromotion/PostCustomPromotion";
import { EditCustomPromotionUseCase } from "./usecases/PencilSelling/EditCustomPromotion/EditCustomPromotion";
import { DeleteCustomPromotionUseCase } from "./usecases/PencilSelling/DeleteCustomPromotion/DeleteCustomPromotion";

let httpFetch: typeof fetch = () => Promise.reject();
if (window.fetch) httpFetch = window.fetch.bind(window) as typeof fetch;

export const homeConfigurator = new HomeConfigurator();
export const cart = new Cart();
export const offer = new OfferEntity(cart);
export const productsEntity = new ProductsEntity();
export const servicesEntity = new ServicesEntity();
export const productsRepository = new ProductsRepository(httpFetch);

export const useCases = {
  getLandlineSettingsUseCase: new GetLandlineSettingsUseCase(offer),
  setLandlineSettingsUseCase: new SetLandlineSettingsUseCase(offer),
  setMobileSettingsUseCase: new SetMobileSettingsUseCase(offer),
  getMobileSettingsUseCase: new GetMobileSettingsUseCase(offer),
  getCardSettingsUsecase: new GetCardSettingsUsecase(offer),
  setCardSettingsUsecase: new SetCardSettingsUsecase(offer),
  setCustomerUseCase: new SetCustomerUseCase(offer),
  getCustomerUseCase: new GetCustomerUseCase(offer),
  fetchProductsUseCase: new FetchProductsUseCase(
    productsRepository,
    productsEntity,
    servicesEntity,
    offer
  ),
  getTariffsUseCase: new GetTariffsUseCase(productsEntity),
  getTariffsInCartUseCase: new GetTariffsInCartUseCase(offer.cart),
  getCardsInCartUseCase: new GetCardsInCartUseCase(offer.cart),
  getDevicesInCartUseCase: new GetDevicesInCartUseCase(offer.cart),
  getOptionsInCartUseCase: new GetOptionsInCartUseCase(offer.cart),
  addToCartUseCase: new AddToCartUseCase(offer.cart),
  findProductInCartUseCase: new FindProductInCartUseCase(offer.cart),
  replaceCartItemUseCase: new ReplaceCartItemUseCase(offer.cart),
  deleteFromCartUseCase: new DeleteFromCartUseCase(offer.cart),
  setCartItemQuantityUseCase: new SetCartItemQuantityUseCase(offer.cart),
  removeTariffFromCartUseCase: new RemoveTariffFromCart(offer.cart),
  removeMultisimFromCartUseCase: new RemoveMultisimFromCartUseCase(offer.cart),
  getNotesUseCase: new GetNotesUseCase(offer),
  setNotesUseCase: new SetNotesUseCase(offer),
  getCustomPromotionsUseCase: new GetCustomPromotionsUseCase(offer),
  setCustomPromotionsUseCase: new SetCustomPromotionsUseCase(offer),
  removeCustomPromotionUseCase: new RemoveCustomPromotionUseCase(offer),
  replaceCustomPromotionUseCase: new ReplaceCustomPromotionUseCase(offer),
  getBenefitUseCase: new GetBenefitUseCase(offer),
  setBenefitUseCase: new SetBenefitUseCase(offer),
  getCardsUseCase: new GetCardsUseCase(productsEntity),
  getDevicesUseCase: new GetDevicesUseCase(productsEntity),
  getOptionsUseCase: new GetOptionsUseCase(productsEntity),
  getOfferValidUntilUseCase: new GetOfferValidUntilUseCase(offer),
  setOfferValidUntilUseCase: new SetOfferValidUntilUseCase(offer),
  resetOfferUseCase: new ResetOfferUseCase(offer),
  submitEmailUseCase: new SubmitEmailUseCase(productsRepository),
  resetCartUseCase: new ResetCartUseCase(cart),
  resetCoreCardsInCartUseCase: new ResetCoreCardsInCartUseCase(cart),
  updateDynamicCardDescriptionsUseCase:
    new UpdateDynamicCardDescriptionsUseCase(cart),
  fetchFileUseCase: new FetchFileUseCase(),
  getOfferExportUseCase: new GetOfferExport(offer),
  showLoyaltyBonusUseCase: new ShowLoyaltyBonusUseCase(offer),
  getStreamingServices: new GetStreamingServicesUseCase(servicesEntity),
  getStreamingVariants: new GetStreamingVariantsUseCase(servicesEntity),
  setStreamingServiceActive: new SetStreamingServiceActiveUseCase(
    servicesEntity
  ),
  getStreamingServicesUseCase: new GetStreamingServicesUseCase(servicesEntity),
  getStreamingVariantsUseCase: new GetStreamingVariantsUseCase(servicesEntity),
  setStreamingServiceActiveUseCase: new SetStreamingServiceActiveUseCase(
    servicesEntity
  ),
  getStreamingServicesPriceUseCase: new GetStreamingServicesPriceUseCase(
    servicesEntity
  ),
  submitPdfQrCodeUseCase: new SubmitPdfQrCodeUseCase(productsRepository),
  getProfileDetailsInPdfIsDisplayedUseCase:
    new GetProfileDetailsInPdfIsDisplayedUseCase(offer),
  setProfileDetailsInPdfIsDisplayedUseCase:
    new SetProfileDetailsInPdfIsDisplayedUseCase(offer),
  postCustomPromotionUseCase: new PostCustomPromotionUseCase(
    productsRepository
  ),
  editCustomPromotionUseCase: new EditCustomPromotionUseCase(
    productsRepository
  ),
  deleteCustomPromotionUseCase: new DeleteCustomPromotionUseCase(
    productsRepository
  ),
};
