import React from "react";
import "./badge-custom.scss";

type BadgeCustomProps = {
  customClass?: string;
};

const BadgeCustom: React.FC<BadgeCustomProps> = ({
  customClass = "",
  children,
}) => <div className={`${customClass} badge-custom`}>{children}</div>;

export default BadgeCustom;
