import React from "react";
import { observer } from "mobx-react";
import {
  IAdditionalDevices,
  ICartItemData,
  ICartItemDataPromotions,
} from "../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import {
  IBusinessCasesTypeValues,
  ITariff,
} from "../../../../../core/entities/Product/Tariff/ITariff";
import { IStepTrackingContext } from "../../../../../views/PencilSelling/IPencilSelling";
import {
  ILevel,
  ITariffWorld,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import { ICartConfigurationSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import TariffConfigurationWrapper from "../TariffConfigurationWrapper";
import TariffConfigurationWorldsLevels from "../TariffConfigurationWorldsLevels";
import TariffConfigurationPromotions from "../TariffConfigurationPromotions";
import TariffConfigurationPhones from "../TariffConfigurationPhones";
import TariffConfigurationNote from "../TariffConfigurationNote";
import { ADDONS_DISPLAY_AMOUNT } from "../const";
import { ConfigurationsEntity } from "../../../../../core/entities/PencilSelling/Configurations/Configurations";
import { ICustomPromotion } from "../../../../../core/entities/PencilSelling/ICustomPromotion";
import { BaseOffer } from "../../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../../../core/repositories/ProductsRepository/ProductsRepository";
import TariffConfigurationMonthlyPriceSteps from "../TariffConfigurationMonthlyPriceSteps";

type BaseMobileTariffConfigurationProps = {
  activeTariffInCart: ICartItemData | undefined;
  activeTariff: ITariff;
  smartphoneInfos: IAdditionalDevices[];
  setSmartphoneInfos: React.Dispatch<
    React.SetStateAction<IAdditionalDevices[]>
  >;
  trackingContext: IStepTrackingContext;
  tariffWorlds: ITariffWorld[];
  levels: ILevel[];
  onSettingsChange: (
    key: keyof ICartConfigurationSettings,
    value: ICartConfigurationSettings[keyof ICartConfigurationSettings]
  ) => void;
  setActiveLevelIndex: (value: number) => void;
  activeLevelIndex: number;
  activeLevelExists: boolean;
  settings: ICartConfigurationSettings;
  selectedPromotions: ICartItemDataPromotions;
  addPromotionsHandler: (promotions: ICartItemDataPromotions) => void;
  addSmartphonesHandler: (smartphoneItems: IAdditionalDevices[]) => void;
  businessCase: IBusinessCasesTypeValues;
  businessCaseName: string;
  customPromotions?: ICustomPromotion[];
  withCustomPromotions?: boolean;
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  withMultipleOnceMonthlyPromotions: boolean;
};

const BaseMobileTariffConfiguration = ({
  trackingContext,
  tariffWorlds,
  levels,
  onSettingsChange,
  settings,
  setActiveLevelIndex,
  smartphoneInfos,
  setSmartphoneInfos,
  selectedPromotions,
  addPromotionsHandler,
  addSmartphonesHandler,
  activeTariffInCart,
  activeTariff,
  businessCase,
  businessCaseName,
  activeLevelIndex,
  activeLevelExists,
  configurationsStore,
  customPromotions = [],
  withCustomPromotions = false,
  offerStore,
  productsRepositoryStore,
  withMultipleOnceMonthlyPromotions = true,
}: BaseMobileTariffConfigurationProps) => (
  <TariffConfigurationWrapper>
    <TariffConfigurationWorldsLevels
      onChange={onSettingsChange}
      trackingContext={trackingContext}
      tariffWorlds={tariffWorlds}
      levels={levels}
      activeLevelExists={activeLevelExists}
      settings={settings}
      activeLevelIndex={activeLevelIndex}
      setActiveLevelIndex={setActiveLevelIndex}
    />
    {/* Promotions Selection */}
    <TariffConfigurationPromotions
      portfolioKey={PortfolioKeys.MOBILE}
      addPromotionsHandler={addPromotionsHandler}
      selectedPromotions={selectedPromotions}
      maxItemsDisplayAmount={ADDONS_DISPLAY_AMOUNT}
      businessCaseName={businessCaseName}
      businessCase={businessCase}
      tariffInCart={activeTariffInCart}
      activeTariff={activeTariff}
      trackingContext={trackingContext}
      customPromotions={customPromotions}
      withCustomPromotions={withCustomPromotions}
      offerStore={offerStore}
      productsRepositoryStore={productsRepositoryStore}
      withMultipleOnceMonthlyPromotions={withMultipleOnceMonthlyPromotions}
    />
    {/* Smartphone Selection */}
    <TariffConfigurationPhones
      addSmartphonesHandler={addSmartphonesHandler}
      maxItemsDisplayAmount={ADDONS_DISPLAY_AMOUNT}
      smartphoneInfos={smartphoneInfos}
      setSmartphoneInfos={setSmartphoneInfos}
      businessCaseName={businessCaseName}
      trackingContext={trackingContext}
      tariffInCart={activeTariffInCart}
    />
    <TariffConfigurationNote
      configurationsStore={configurationsStore}
      trackingContext={trackingContext}
    />
    {activeTariffInCart && (
      <TariffConfigurationMonthlyPriceSteps
        configurationsStore={configurationsStore}
        activeTariffInCart={activeTariffInCart}
        settings={settings}
      />
    )}
  </TariffConfigurationWrapper>
);
export default observer(BaseMobileTariffConfiguration);
