import React, { ElementType } from "react";
import { Heading } from "@dtpk-cc/components";
import * as styles from "./summary-section-options-title.module.scss";

type SummarySectionOptionsTitleProps = {
  title: string;
  fontColor: "magenta" | "blue" | "default";
  customClass?: string;
  Icon?: ElementType | string;
};

const SummarySectionOptionsTitle = ({
  customClass = "",
  title,
  fontColor,
  Icon,
}: SummarySectionOptionsTitleProps) => {
  const color = styles[fontColor] || "";

  return (
    <Heading
      customClass={`${styles.title} ${color} ${customClass}`}
      variants={Heading.Variant.quaternary}
    >
      {Icon && <Icon className={styles.icon} />}
      <span
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </Heading>
  );
};

export default SummarySectionOptionsTitle;
