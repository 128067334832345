import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../core/entities/PencilSelling/Configurations/IConfigurations";
import { configurationsDefaultNameMap } from "../core/entities/PencilSelling/Configurations/const";

export const getConfigurationName = (
  configuration: ICartConfiguration,
  index: number,
  type: ICartConfigurationType
): string | null =>
  configuration.name
    ? configuration.name
    : `${configurationsDefaultNameMap[type]} ${index + 1}`;
