// extracted by mini-css-extract-plugin
var _1 = "configurator-recommendations-module-active-gNooB";
var _2 = "configurator-recommendations-module-configuratorRecommendations-LkDK3";
var _3 = "configurator-recommendations-module-delimiter-g94SD";
var _4 = "configurator-recommendations-module-descr-hngU1";
var _5 = "configurator-recommendations-module-descrTitle-r4bQc";
var _6 = "configurator-recommendations-module-devicesWrapper-c6uTy";
var _7 = "configurator-recommendations-module-footer-aHuc8";
var _8 = "configurator-recommendations-module-nav-eYJaB";
var _9 = "configurator-recommendations-module-navItem-N7m2d";
var _a = "configurator-recommendations-module-resetBtn-vvAgO";
var _b = "configurator-recommendations-module-submitBtn-FdsIh";
var _c = "configurator-recommendations-module-summary-ZND_d";
var _d = "configurator-recommendations-module-summaryItem-rl82u";
var _e = "configurator-recommendations-module-summaryItemAmount-I3Tkm";
var _f = "configurator-recommendations-module-summaryItemName-cxKI6";
var _10 = "configurator-recommendations-module-summaryItemsWrapper-d5rFT";
var _11 = "configurator-recommendations-module-summaryPrice-FisFC";
export { _1 as "active", _2 as "configuratorRecommendations", _3 as "delimiter", _4 as "descr", _5 as "descrTitle", _6 as "devicesWrapper", _7 as "footer", _8 as "nav", _9 as "navItem", _a as "resetBtn", _b as "submitBtn", _c as "summary", _d as "summaryItem", _e as "summaryItemAmount", _f as "summaryItemName", _10 as "summaryItemsWrapper", _11 as "summaryPrice" }
