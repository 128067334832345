import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";

export const mockLegacyTariffEntity = (): jest.Mocked<ILegacyTariff> => ({
  setTariffs: jest.fn(),
  setCards: jest.fn(),
  getCards: jest.fn(),
  getCard: jest.fn(),
  getActiveCards: jest.fn(),
  getMagentaMobileTariffs: jest.fn(),
  getAdditionalCards: jest.fn(),
  setQuantity: jest.fn(),
  setName: jest.fn(),
  getTotalQuantity: jest.fn(),
  setCardPrice: jest.fn(),
  addCustomCard: jest.fn(),
  deleteCustomCard: jest.fn(),
  getMagentaMobileTariffPrice: jest.fn(),
  getAdditionalCardsPrice: jest.fn(),
  getCustomCardsPrice: jest.fn(),
  getTotalPrice: jest.fn(),
  getCustomTotalPrice: jest.fn(),
  setCustomTotalPrice: jest.fn(),
  getCustomTotalQuantity: jest.fn(),
  setCustomTotalQuantity: jest.fn(),
  export: jest.fn(),
  getLegacySummaryCardData: jest.fn(),
  setCardDataVolume: jest.fn(),
  reset: jest.fn(),
});

export const mockTariffEntity = (): jest.Mocked<ITariff> => ({
  additionalCardMode: null,
  isCardLimitReached: true,
  setTariffs: jest.fn(),
  setCards: jest.fn(),
  getCards: jest.fn(),
  setCardSectionItem: jest.fn(),
  getCardDetailSection: jest.fn(),
  getPromotionsTotalPrice: jest.fn(),
  setPromotions: jest.fn(),
  getPromotions: jest.fn(),
  getCard: jest.fn(),
  getActiveCards: jest.fn(),
  getHasBenefit: jest.fn(),
  setHasBenefit: jest.fn(),
  setQuantity: jest.fn(),
  getTotalQuantity: jest.fn(),
  getCombiCards: jest.fn(),
  getFamilyCards: jest.fn(),
  getMagentaNextCard: jest.fn(),
  getMagentaMobileTariffs: jest.fn(),
  getMagentaMobileKidsCards: jest.fn(),
  getMultiSimCards: jest.fn(),
  getMagentaMobileCardPrice: jest.fn(),
  getMagentaMobileKidsCardPrice: jest.fn(),
  getCombiCardsPrice: jest.fn(),
  getMultiSimCardsPrice: jest.fn(),
  getFamilyCardsPrice: jest.fn(),
  getTotalPrice: jest.fn(),
  getBenefitsData: jest.fn(),
  getLoyaltyBonus: jest.fn(),
  setLoyaltyBonus: jest.fn(),
  getValueCalculatedByPeriod: jest.fn(),
  getHasProvisionFee: jest.fn(),
  setHasProvisionFee: jest.fn(),
  getSettings: jest.fn(),
  setSettings: jest.fn(),
  getMobileTariffsLevels: jest.fn(),
  getSavingsTotalPrice: jest.fn(),
  export: jest.fn(),
  reset: jest.fn(),
});
