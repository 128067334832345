import React from "react";
import { observer } from "mobx-react";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import AddonsSelection from "components/AddonsSelection";
import { useStores } from "../../../../stores";

const OptionsMobile = () => {
  const { productsStore, cartStore } = useStores();
  const options = productsStore.getMobileOptions();
  const optionsInCart = cartStore.getMobileOptions();
  const tariffs = cartStore.getTariffsInCart();

  return (
    <AddonsSelection
      isFungible={false}
      allowCustomProducts={false}
      headline="Extras Mobilfunk"
      name="Extras"
      products={options}
      activeProducts={optionsInCart}
      condition={tariffs[PortfolioKeys.MOBILE]?.key}
      portfolio={PortfolioKeys.MOBILE}
      addonGroup={Group.addon}
    />
  );
};

export default observer(OptionsMobile);
