import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Icon } from "@dtpk-cc/components";
import { ContentCalendarDefault } from "@dtpk-cc/components/dist/icons";

import Input from "../Input";

import "react-datepicker/dist/react-datepicker.css";
import * as styles from "./input-date.module.scss";

export type InputDateProps = ReactDatePickerProps;

export const InputDate = (props: InputDateProps) => (
  <div className={styles.inputDate__wrapper}>
    <DatePicker
      customInput={<Input />}
      {...props}
    />
    <Icon
      icon={ContentCalendarDefault}
      size={Icon.Size.small}
      customClass={styles.inputDate__icon}
    />
  </div>
);
