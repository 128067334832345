import { makeAutoObservable, runInAction } from "mobx";

import { IBenefit } from "core/entities/Product/IBenefit";
import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { TariffSizes } from "core/entities/Product/Tariff/ITariff";
import { ExportCartTariffs } from "core/usecases/PencilSelling/GetTariffsInCart/IGetTariffsInCart";
import { ExportCartOptions } from "core/usecases/PencilSelling/GetOptionsInCart/IGetOptionsInCart";
import { IExtension } from "core/entities/Product/IExtension";
import type { ICartItem, ICartItemData } from "../CartItem/ICartItem";
import type { ICart } from "./ICart";
import { Group, PortfolioKeys } from "../../Product/IProduct";
import { CartItem } from "../CartItem/CartItem";

export interface ICartSection {
  name: string;
  items: ICartItemData[];
  benefit?: IBenefit;
  note?: string;
}

export class Cart implements ICart {
  items: ICartItem[] = [];

  constructor(items?: ICartItemData[]) {
    if (items) {
      this.items = items.map((item) => new CartItem(item));
    }
    makeAutoObservable(this);
  }

  getCards(): ICartItemData[] {
    return this.items.reduce((acc, item) => {
      const isCard = item.group === Group.card;

      return isCard ? [...acc, item.export()] : acc;
    }, [] as ICartItemData[]);
  }

  getDevices(): ICartItemData[] {
    return this.items.reduce((acc, item) => {
      const isDevice = item.group === Group.device;

      return isDevice ? [...acc, item.export()] : acc;
    }, [] as ICartItemData[]);
  }

  private findTariff(key: PortfolioKeys): ICartItem {
    return (
      this.items.find(
        ({ group, portfolio }) => group === Group.tariff && portfolio === key
      ) || null
    );
  }

  getTariff(key: PortfolioKeys): ICartItemData | null {
    const tariffItem = this.findTariff(key);

    return tariffItem?.export() || null;
  }

  getMobileTariff(): ICartItemData | null {
    return this.getTariff(PortfolioKeys.MOBILE);
  }

  getLandlineTariff(): ICartItemData | null {
    return this.getTariff(PortfolioKeys.LANDLINE);
  }

  getTariffsInCart(): ExportCartTariffs {
    return {
      [PortfolioKeys.LANDLINE]: this.getLandlineTariff(),
      [PortfolioKeys.MOBILE]: this.getMobileTariff(),
    };
  }

  getOptions(key: PortfolioKeys): ICartItemData[] {
    const options = this.items.filter(
      ({ group, portfolio }) => group === Group.addon && portfolio === key
    );

    return options.map((item) => item.export());
  }

  getLandlineOptions(): ICartItemData[] {
    return this.getOptions(PortfolioKeys.LANDLINE);
  }

  getMobileOptions(): ICartItemData[] {
    return this.getOptions(PortfolioKeys.MOBILE);
  }

  getMobileAndLandlineOptions(): ExportCartOptions {
    return {
      [PortfolioKeys.LANDLINE]: this.getLandlineOptions(),
      [PortfolioKeys.MOBILE]: this.getMobileOptions(),
    };
  }

  delete(key: string): void {
    this.items = this.items.filter((item) => item.key !== key);
  }

  deleteLandlineTariff(): void {
    const tariff = this.getLandlineTariff();
    if (tariff) this.delete(tariff.key);
  }

  deleteMobileTariff(): void {
    const tariff = this.getMobileTariff();
    if (tariff) this.delete(tariff.key);
  }

  deleteMultiSimBySize(size: string | null): void {
    this.items = this.items.filter((item) =>
      item.tariffWorld?.key === CardWorldKeys.magenta_mobile_multi_sim
        ? (item.cardData?.size === TariffSizes.XL && size === TariffSizes.XL) ||
          (size !== TariffSizes.XL && item.cardData?.size !== TariffSizes.XL)
        : true
    );
  }

  add(product: ICartItemData): void {
    this.items = [...this.items, new CartItem(product)];
  }

  replace(key: string, product: ICartItemData): void {
    this.items = this.items.map((cartItem) =>
      cartItem.key === key ? new CartItem(product) : cartItem
    );
  }

  find(key: string): ICartItem | null {
    return this.items.find((product) => product.key === key) || null;
  }

  setQuantity(key: string, quantity: number): void {
    runInAction(() => {
      const item = this.find(key);
      item.setQuantity(quantity);
    });
  }

  resetMobile(): void {
    this.resetItemsByPortfolioKey(PortfolioKeys.MOBILE);
  }

  resetOptions(portfolio: PortfolioKeys): void {
    this.items = this.items.filter(
      (product) =>
        product.group !== Group.addon &&
        product.portfolio !== portfolio
    );
  }

  resetLandline(): void {
    this.resetItemsByPortfolioKey(PortfolioKeys.LANDLINE);
  }

  resetItemsByPortfolioKey(portfolioKey: PortfolioKeys): void {
    this.items = this.items.filter(
      ({ portfolio }) => portfolio !== portfolioKey
    );
  }

  resetCart(portfolio?: PortfolioKeys): void {
    if (!portfolio || portfolio === PortfolioKeys.LANDLINE) {
      this.resetLandline();
    }
    if (!portfolio || portfolio === PortfolioKeys.MOBILE) this.resetMobile();
  }

  // NOTE: reset all core cards
  resetCards(): void {
    this.items = this.items.filter(
      ({ group, inheritingCard }) =>
        group !== Group.card ||
        (group === Group.card && inheritingCard !== true)
    );
  }

  resetAllCards(): void {
    this.items = this.items.filter(
      ({ group }) =>
        group !== Group.card
    );
  }

  updateDynamicCardDescriptions(
    cardKey: string,
    descriptionSummary: string,
    descriptionText: string
  ): void {
    this.items = this.items.map((item) => {
      if (item.key === cardKey) {
        const cartItem = new CartItem({
          ...item,
          description: {
            summary: descriptionSummary,
            text: descriptionText,
          },
        });
        return cartItem;
      }

      return item;
    });
  }

  reset(): void {
    this.items = [];
  }

  filterActiveOptionsByTariffKey(tariffKey: string): void {
    return this.getMobileOptions().forEach((option) => {
      if (!option.availableFor.includes(tariffKey)) {
        this.delete(option.key);
      }
    });
  }

  addToCart(cartItem: ICartItemData): void {
    const isMobileTariff =
      cartItem.portfolio === PortfolioKeys.MOBILE &&
      cartItem.group === Group.tariff;
    const isLandlineTariff =
      cartItem.portfolio === PortfolioKeys.LANDLINE &&
      cartItem.group === Group.tariff;

    if (isMobileTariff) {
      this.filterActiveOptionsByTariffKey(cartItem.key);
      this.deleteMobileTariff();
    }
    if (isLandlineTariff) this.deleteLandlineTariff();

    this.add(cartItem);
  }

  removeTariffFromCart(isLandline = false): void {
    if (isLandline) this.deleteLandlineTariff();
    else this.deleteMobileTariff();
  }

  setLandlineTariffExtension(extension: IExtension | null) {
    const currentTariff = this.findTariff(PortfolioKeys.LANDLINE);
    currentTariff.extension = extension;
  }

  getCurrentLandlineTariffExtension(): IExtension | null {
    return this.getLandlineTariff()?.extension || null;
  }
}
