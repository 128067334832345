import React from "react";
import { observer } from "mobx-react";
import { Heading, Button } from "@dtpk-cc/components";
import AddIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/step-header/add.svg";
import SubtractIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/step-header/subtract.svg";

import * as styles from "./amount-picker.module.scss";

import {
  onlyNumber,
  preventDecimal,
  handleNumberInputChange,
} from "../../../helpers/NumericCalculation";

type AmountPickerProps = {
  onSubtractClick: () => void;
  onAddClick: () => void;
  onChange?: (price: number) => void;
  value: number;
  customClass?: string;
  minusAriaLabel?: string;
  plusAriaLabel?: string;
  disabledPlus?: boolean;
  withInput: boolean;
  inputTrackingValue?: string;
  trackingContext?: string;
};

const AmountPicker = ({
  customClass = "",
  onSubtractClick,
  onAddClick,
  value,
  minusAriaLabel = "Menge verringern",
  plusAriaLabel = "Menge erhöhen",
  disabledPlus = false,
  withInput,
  onChange = () => {},
}: AmountPickerProps) => (
  <div className={`${styles.amountPicker} ${customClass}`}>
    <Button
      onClick={onSubtractClick}
      tabIndex={0}
      variants={Button.Variant.bare}
      customClass={styles.itemWrapper}
      disabled={value === 1}
      aria-label={minusAriaLabel}
    >
      <SubtractIcon className={styles.icon} />
    </Button>
    {withInput && (
      <input
        type="number"
        className={`${styles.itemWrapper} ${styles.input}`}
        value={value}
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(event) =>
          handleNumberInputChange({
            inputValue: event.target.value,
            inFloat: false,
            onChange,
          })
        }
        onKeyPress={(event) => {
          onlyNumber(event);
          preventDecimal(event);
        }}
      />
    )}
    {!withInput && (
      <Heading
        customClass={styles.value}
        tag="h3"
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        {value}
      </Heading>
    )}
    <Button
      onClick={onAddClick}
      tabIndex={0}
      variants={Button.Variant.bare}
      customClass={styles.itemWrapper}
      aria-label={plusAriaLabel}
      disabled={disabledPlus}
    >
      <AddIcon className={styles.icon} />
    </Button>
  </div>
);

export default observer(AmountPicker);
