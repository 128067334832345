import React, { useState, useEffect } from "react";
import { trackClick } from "helpers/reactTracking";
import { Modal as LPGModal, CopyText } from "@dtpk-cc/components";

import Modal from "components/Modal";
import TileItem from "elements/TileItem";

import "./product-prices-modal.scss";

const ProductPricesModal = ({
  headline,
  isOpen,
  closeModal,
  title,
  description,
  product,
  activePrice = "",
  onSave,
  getAdditionalPriceSuffix,
}) => {
  const [activePriceType, setActivePriceType] = useState(activePrice);

  useEffect(() => {
    setActivePriceType(activePrice);
  }, [isOpen, activePrice]);

  return (
    <Modal
      active={isOpen}
      close={closeModal}
      showSubmitButton
      submitButtonDisabled={!activePriceType}
      handleSubmitButton={() => {
        onSave(product, activePriceType);
        setActivePriceType(activePrice);
        trackClick(`${product.name}.auswahl-übernehmen`, headline);
      }}
    >
      <CopyText customClass="modal__copy">{description}</CopyText>
      <LPGModal.Heading>{title}</LPGModal.Heading>
      <div className="product-prices__container product-prices__container--center">
        <TileItem
          active={activePriceType === "monthly"}
          item={product}
          price={{ monthly: product?.price?.monthly }}
          additionalPriceSuffix={getAdditionalPriceSuffix(product)}
          onClick={() => {
            setActivePriceType("monthly");
            trackClick(`${product.name}.monatlich`, headline);
          }}
        />
        <TileItem
          active={activePriceType === "once"}
          item={product}
          price={{ once: product?.price?.once }}
          onClick={() => {
            setActivePriceType("once");
            trackClick(`${product.name}.einmalig`, headline);
          }}
        />
      </div>
    </Modal>
  );
};

export default ProductPricesModal;
