import { useCallback } from "react";
import {
  IBusinessClientsCardSettings,
  ICartConfiguration,
  ICartConfigurationType,
} from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Cart } from "../../core/entities/PencilSelling/Cart/Cart";
import { useGetDefaultSettings } from "./useGetDefaultSettings";
import {
  BusinessCasesLandline,
  BusinessCasesMobile,
} from "../../core/entities/Product/Tariff/ITariff";
import { CustomerType } from "../../core/entities/PencilSelling/Customer/ICustomer";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";

export const useGetDefaultConfigurationSettings = (offerStore: BaseOffer) => {
  const defaultSettings = useGetDefaultSettings(offerStore);

  const defaultMobileConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.MOBILE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: {
          ...defaultSettings.mobileSettings,
          businessCase: offerStore.getBusinessCase(
            ICartConfigurationType.MOBILE
          ) as BusinessCasesMobile,
        },
        note: "",
        isBenefitActive: false,
        benefit: null,
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    [offerStore, defaultSettings]
  );

  const defaultSecondaryCardsConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.CARD,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: null,
        note: "",
        isBenefitActive: true,
        benefit: null,
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    []
  );
  const defaultLandlineConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.LANDLINE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: {
          ...defaultSettings.landlineSettings,
          businessCase: offerStore.getBusinessCase(
            ICartConfigurationType.LANDLINE
          ) as BusinessCasesLandline,
        },
        note: "",
        isBenefitActive: true,
        benefit: null,
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    [defaultSettings, offerStore]
  );
  const defaultDeviceConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.DEVICE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: null,
        isBenefitActive: false,
        benefit: null,
        note: "",
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    []
  );

  const defaultBKConfigurationMap: () => {
    [key in ICartConfigurationType]: ICartConfiguration;
  } = useCallback(
    () => ({
      [ICartConfigurationType.MOBILE]: defaultMobileConfigurationSettings(),
      [ICartConfigurationType.CARD]: {
        ...defaultSecondaryCardsConfigurationSettings(),
        data: {
          ...defaultSecondaryCardsConfigurationSettings().data,
          settings: {
            ...(defaultSettings.cardSettings as IBusinessClientsCardSettings),
            businessCase: offerStore.getBusinessCase(
              ICartConfigurationType.CARD
            ) as BusinessCasesMobile,
          },
        },
      },
      [ICartConfigurationType.LANDLINE]: defaultLandlineConfigurationSettings(),
      [ICartConfigurationType.DEVICE]: defaultDeviceConfigurationSettings(),
    }),
    [
      defaultDeviceConfigurationSettings,
      defaultLandlineConfigurationSettings,
      defaultMobileConfigurationSettings,
      defaultSecondaryCardsConfigurationSettings,
      defaultSettings.cardSettings,
      offerStore,
    ]
  );

  const defaultPKConfigurationMap: () => {
    [key in ICartConfigurationType]: ICartConfiguration;
  } = useCallback(
    () => ({
      [ICartConfigurationType.MOBILE]: {
        ...defaultMobileConfigurationSettings(),
        data: {
          ...defaultMobileConfigurationSettings().data,
          isBenefitActive: true,
        },
      },
      [ICartConfigurationType.CARD]: {
        ...defaultSecondaryCardsConfigurationSettings(),
        data: {
          ...defaultSecondaryCardsConfigurationSettings().data,
          isBenefitActive: false,
        },
      },
      [ICartConfigurationType.LANDLINE]: defaultLandlineConfigurationSettings(),
      [ICartConfigurationType.DEVICE]: defaultDeviceConfigurationSettings(),
    }),
    [
      defaultDeviceConfigurationSettings,
      defaultLandlineConfigurationSettings,
      defaultMobileConfigurationSettings,
      defaultSecondaryCardsConfigurationSettings,
    ]
  );

  const getDefaultConfigurationSettings = useCallback(
    (type: ICartConfigurationType): ICartConfiguration =>
      offerStore.customer.get().customerType === CustomerType.PRIVATE
        ? defaultPKConfigurationMap()[type]
        : defaultBKConfigurationMap()[type],
    [defaultBKConfigurationMap, defaultPKConfigurationMap, offerStore.customer]
  );

  return { getDefaultConfigurationSettings };
};
