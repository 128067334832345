import React from "react";
import PropTypes from "prop-types";
import { Button, Flex } from "@dtpk-cc/components";
import Minus from "../../../assets/images/icons/pencil-selling-tool/minus.svg";
import Plus from "../../../assets/images/icons/pencil-selling-tool/plus.svg";

import "elements/IncrementalButtonGroup/incremental-button-group.scss";

const IncrementalButtonGroup = ({
  label,
  handlePlusClick,
  handleMinusClick,
  value,
  customClass = "",
  minQuantity = 1,
  maxQuantity = 99,
  minusAriaLabel = "Menge verringern",
  plusAriaLabel = "Menge erhöhen",
}) => (
  <Flex
    flexWrap="wrap"
    alignItems="flex-start"
    customClass={`incremental-button-group ${customClass}`}
  >
    {label && <span className="incremental-button-group__label">{label}</span>}
    <div className="incremental-button-group__wrapper">
      <Button
        customClass="incremental-button-group__button incremental-button-group__button--minus"
        variants={[Button.Variant.outline, Button.Variant.icon]}
        disabled={value <= minQuantity}
        onClick={() => handleMinusClick(value - 1)}
        aria-label={minusAriaLabel}
      >
        <Button.Icon
          customClass="incremental-button-group__icon"
          icon={Minus}
        />
      </Button>

      <input
        disabled={true}
        value={value}
        className="incremental-button-group__input"
        aria-label="Gesamtmenge"
      ></input>
      <Button
        customClass="incremental-button-group__button incremental-button-group__button--plus"
        variants={[Button.Variant.outline, Button.Variant.icon]}
        disabled={value >= maxQuantity}
        onClick={() => handlePlusClick(value + 1)}
        aria-label={plusAriaLabel}
      >
        <Button.Icon customClass="incremental-button-group__icon" icon={Plus} />
      </Button>
    </div>
  </Flex>
);

IncrementalButtonGroup.defaultProps = {
  label: "",
  value: 0,
};

IncrementalButtonGroup.propTypes = {
  label: PropTypes.string,
  handleMinusClick: PropTypes.func,
  handlePlusClick: PropTypes.func,
  value: PropTypes.number,
};

export default IncrementalButtonGroup;
