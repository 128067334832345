import React, { useEffect, useState, useCallback } from "react";
import { Accordion } from "@dtpk-cc/components";
import * as styles from "./configurator-wrapper.module.scss";

const MIN_DESKTOP = 1024;

type ConfiguratorWrapperProps = {
  title: string;
  isExpanded: boolean;
};

export const ConfiguratorWrapper: React.FC<ConfiguratorWrapperProps> = ({
  title,
  children,
  isExpanded,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MIN_DESKTOP);

  const setMobileState = useCallback(() => {
    const isMobileView = window.innerWidth <= MIN_DESKTOP;
    if (isMobile !== isMobileView) {
      setIsMobile(isMobileView);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("resize", setMobileState);

    return () => window.removeEventListener("resize", setMobileState);
  }, [setMobileState]);

  if (!isMobile) return <>{children}</>;

  return (
    <div className={`${styles.configuratorWrapper} ${styles.base}`}>
      <Accordion>
        <Accordion.Item isExpanded={isExpanded}>
          <Accordion.Item.Toggle>
            <Accordion.Item.Headline>
              <span
                className={`${styles.configuratorWrapper} ${styles.headline}`}
              >
                {title}
              </span>
            </Accordion.Item.Headline>
          </Accordion.Item.Toggle>
          <Accordion.Panel>
            <div className={`${styles.configuratorWrapper} ${styles.panel}`}>
              {children}
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
