import React from "react";
import { List } from "@dtpk-cc/components";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import MagentaOneIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/magenta-one.svg";
import SummarySectionOptionsTitle from "../SummarySectionOptionsTitle";
import { Benefits } from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import SummaryConfigurationPriceItem from "../SummaryConfigurationPriceItem";
import * as styles from "./summary-configuration-benefits.module.scss";

type SummaryConfigurationBenefitsProps = {
  benefits: Benefits;
  customerPricePrefix: string;
};

const SummaryConfigurationBenefits = ({
  benefits,
  customerPricePrefix,
}: SummaryConfigurationBenefitsProps) =>
  benefits ? (
    <SummarySectionItemWrapper variant="borderedInSection">
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <SummarySectionOptionsTitle
          title={benefits?.name}
          fontColor="magenta"
          Icon={MagentaOneIcon}
        />
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly />
      <SummarySectionItemWrapper.Once />
      {benefits.items.map((item) => (
        <React.Fragment key={item.name}>
          <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
            <List customClass="m-l-36">
              <List.Item customClass={styles.benefitItem}>
                {item.name}
              </List.Item>
            </List>
          </SummarySectionItemWrapper.Main>
          <SummarySectionItemWrapper.Monthly>
            <SummaryConfigurationPriceItem
              suffix={customerPricePrefix}
              price={item.monthlyPrice}
            />
          </SummarySectionItemWrapper.Monthly>
          <SummarySectionItemWrapper.Once>
            <SummaryConfigurationPriceItem
              suffix={customerPricePrefix}
              price={item.oncePrice}
            />
          </SummarySectionItemWrapper.Once>
        </React.Fragment>
      ))}
    </SummarySectionItemWrapper>
  ) : null;

export default SummaryConfigurationBenefits;
