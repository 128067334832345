import React from "react";

import "elements/RangeSlider/range-slider.scss";

const RangeSlider = ({ min, max, value, onChange }) => (
  <input
    type="range"
    min={min}
    max={max}
    value={value}
    onChange={(event) => onChange(event.currentTarget.value)}
    className="pst-slider"
  ></input>
);

export default RangeSlider;
