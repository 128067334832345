import { ICalculatorExport } from "core/presenter/MagentaNext/MagentaNextSummaryPresenter";
import { IGetCalculatorPDF } from "./IGetCalculatorPDF";
import { IProductsRepository } from "../../../../repositories/ProductsRepository/IProductsRepository";

export class GetCalculatorPDFUseCase implements IGetCalculatorPDF {
  constructor(private readonly repository: IProductsRepository) {}

  execute(data: ICalculatorExport): Promise<string> {
    return this.repository.getCalculatorPDF(data);
  }
}
