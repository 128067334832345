import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ISetCardName } from "./ISetCardName";

export class SetCardName implements ISetCardName {
  constructor(private entity: ILegacyTariff) {}

  execute(key: string, name: string): void {
    this.entity.setName(key, name);
  }
}
