import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetHasBenefit } from "./IGetHasBenefit";

export class GetHasBenefit implements IGetHasBenefit {
  constructor(private readonly entity: ITariff) {}

  execute(): boolean {
    return this.entity.getHasBenefit();
  }
}
