import React, { useState } from "react";
import { CartItemSummaryPresenter } from "core/presenter/PencilSelling/CartItemSummaryPresenter";
import { PromotionPriceType } from "core/entities/Product/IDiscount";

import MultiSimSummary from "components/MultiSimSummary";
import LoyaltyBonusSection from "components/LoyaltyBonusSection";
import CollapsibleText from "elements/CollapsibleText";
import { Grid, CopyText, Heading, Price, List } from "@dtpk-cc/components";
import MagentaLogo from "../../elements/OfferAdvantage/assets/magentaeins-benefit-logo.svg";
import { summarySectionGridColData } from "../CartSection/summarySectionGridColData";

import "./cart-item-summary.scss";
import TariffCalculatedMonthlyPrice from "../../elements/TariffCalculatedMonthlyPrice";

const CartItemSummary = ({
  item,
  settings,
  note,
  benefit,
  isCardSection,
  openModal,
  showLoyaltyBonus = false,
}) => {
  const [presenter] = useState(new CartItemSummaryPresenter(item, settings));

  const devices = item.additionalDevices;
  const benefitOptions = benefit?.options.filter(
    (option) => option !== null && option.portfolio === settings.portfolio
  );

  const showDeviceSection = devices?.some((device) => !!device.name);

  const monthlyPriceObject = presenter.monthlyPrice();

  const showLoyaltyBonusValue =
    settings.loyaltyBonusValue > 0 && showLoyaltyBonus;

  const isMonthlyCustomPromotion = (promotion) =>
    promotion.discount.interval > 0;

  const isOnceCustomPromotion = (promotion) => promotion.discount.interval <= 0;

  return (
    <>
      {(item?.cardData?.note || note) && (
        <CopyText customClass="cart-item-summary__card-note">
          {item?.cardData?.note || note}
        </CopyText>
      )}

      <Grid.Col
        {...summarySectionGridColData.main}
        customClass="summary__product-column"
      >
        <Heading tag="h3" variants={Heading.Variant.tertiary}>
          {presenter.getDisplayName()}
        </Heading>
        <CopyText>{presenter.additionalDescription}</CopyText>
        {typeof item.contractPeriod === "number" && (
          <CopyText>{presenter.contractPeriod}</CopyText>
        )}

        {settings.hasProvisionFee && (
          <CopyText>{presenter.getProvisionText()}</CopyText>
        )}
      </Grid.Col>

      <Grid.Col
        {...summarySectionGridColData.monthlyPrice}
        customClass="summary__product-column"
      >
        <TariffCalculatedMonthlyPrice
          priceFontSizeIsLarge
          withMonthlySuffix={false}
          monthlyPrice={monthlyPriceObject.price}
          priceStepsDescriptions={monthlyPriceObject.priceStepsDescriptions}
          monthlyPriceSuffix={item.price.suffix || null}
        />
      </Grid.Col>

      <Grid.Col
        {...summarySectionGridColData.oncePrice}
        customClass="summary__product-column"
      >
        {presenter.hasOncePrice() && (
          <Price
            variants={[Price.Variant.brand, Price.Variant.large]}
            value={presenter.oncePrice()}
          />
        )}
      </Grid.Col>

      {item.description?.text && (
        <CollapsibleText
          headline={item.description.summary}
          text={item.description.text}
          productName={item.name}
        ></CollapsibleText>
      )}

      {!!(isCardSection && item.cardData.multiSim.quantity) && (
        <MultiSimSummary
          price={item.cardData.multiSim.price}
          quantity={item.cardData.multiSim.quantity}
        />
      )}

      {!!item.promotions.length && (
        <>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <Heading
              customClass="summary__promotion-heading"
              variants={Heading.Variant.tertiary}
            >
              Sonderkonditionen
            </Heading>
          </Grid.Col>
          {item.promotions.map((promotion) => (
            <React.Fragment key={promotion.key}>
              <Grid.Col
                customClass="summary__list-column"
                s={3}
                m={7}
                l={7}
                xl={14}
              >
                <List>
                  <List.Item>
                    <List.Text>
                      {promotion.description} {""}
                      {promotion.discount
                        ? `${CartItemSummaryPresenter.getDiscountText(
                            promotion.discount
                          )}`
                        : ""}
                    </List.Text>
                  </List.Item>
                </List>
              </Grid.Col>

              <Grid.Col {...summarySectionGridColData.monthlyPrice}>
                {promotion.discount?.kind ===
                  PromotionPriceType.CUSTOM_PROMOTION &&
                  isMonthlyCustomPromotion(promotion) &&
                  promotion.discount.value > 0 && (
                    <Price
                      variants={[Price.Variant.benefit, Price.Variant.large]}
                      value={-promotion.discount.value}
                    />
                  )}
              </Grid.Col>

              <Grid.Col {...summarySectionGridColData.oncePrice}>
                {promotion.discount?.kind === PromotionPriceType.DISCOUNT && (
                  <Price
                    variants={[Price.Variant.benefit, Price.Variant.large]}
                    value={promotion.discount?.value}
                  />
                )}
                {promotion.discount?.kind ===
                  PromotionPriceType.CUSTOM_PROMOTION &&
                  isOnceCustomPromotion(promotion) &&
                  promotion.discount.value > 0 && (
                    <Price
                      variants={[Price.Variant.benefit, Price.Variant.large]}
                      value={-promotion.discount.value}
                    />
                  )}
              </Grid.Col>
            </React.Fragment>
          ))}
        </>
      )}

      {!!benefitOptions?.length && (
        <>
          <Grid.Col s={6} m={12} l={12} xl={24}>
            <Heading
              customClass="summary__promotion-heading"
              variants={Heading.Variant.brand}
            >
              <MagentaLogo
                viewBox="0 0 40 40"
                className="summary__logo summary__logo--down"
              />

              {benefit.name}
            </Heading>
          </Grid.Col>

          {!!benefitOptions?.length && (
            <>
              <Grid.Col s={6} m={12} l={12} xl={24}>
                <List>
                  <Grid.Row>
                    {benefitOptions.map((option) => (
                      <React.Fragment key={option.key}>
                        <Grid.Col
                          customClass="summary__list-column"
                          s={3}
                          m={7}
                          l={7}
                          xl={14}
                        >
                          <List.Item>
                            <List.Text>{option.name}</List.Text>
                          </List.Item>
                        </Grid.Col>

                        {option.value && (
                          <Grid.Col
                            {...summarySectionGridColData.monthlyPrice}
                            key={option.key}
                          >
                            <Price
                              variants={[
                                Price.Variant.benefit,
                                Price.Variant.large,
                              ]}
                              value={-option.value}
                            />
                          </Grid.Col>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid.Row>
                </List>
              </Grid.Col>
            </>
          )}
        </>
      )}

      {showLoyaltyBonusValue && (
        <LoyaltyBonusSection
          settings={settings}
          openModal={openModal}
          reducedTariffMonthlyPrice={presenter.getReducedTariffMonthlyPrice()}
          freeMonths={presenter.getFreeMonths()}
          contractPeriod={item.contractPeriod || 1}
        />
      )}

      {showDeviceSection && (
        <>
          <Grid.Col s={6} m={12} l={12} xl={24} style={{ marginTop: "1rem" }}>
            <Heading variants={Heading.Variant.quaternary}>Smartphone</Heading>
          </Grid.Col>
          {devices.map(
            (device, index) =>
              !!device?.name && (
                <React.Fragment key={`smartphone-${index}`}>
                  <Grid.Col
                    {...summarySectionGridColData.main}
                    customClass="summary__list-column"
                  >
                    <List>
                      <List.Item>
                        <CopyText>{device.name}</CopyText>
                      </List.Item>
                    </List>
                  </Grid.Col>
                  <Grid.Col
                    {...summarySectionGridColData.monthlyPrice}
                  ></Grid.Col>
                  <Grid.Col {...summarySectionGridColData.oncePrice}>
                    {device.price && (
                      <Price
                        variants={[Price.Variant.brand, Price.Variant.large]}
                        value={device.price.replace(",", ".")}
                      />
                    )}
                  </Grid.Col>
                </React.Fragment>
              )
          )}
        </>
      )}
    </>
  );
};

export default CartItemSummary;
