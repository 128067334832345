import React from "react";
import { CopyText, Heading } from "@dtpk-cc/components";
import { IDetailNote } from "../../../../core/presenter/PencilSelling/OfferSummaryBasePresenter";

import * as styles from "./summary-details.module.scss";
import TileContainer from "../../TileContainer";

const SummaryDetails = ({ details }: { details: IDetailNote[] }) => (
  <div className={styles.container}>
    {details.map((detail, index) => (
      <TileContainer key={index} customClass={styles.detailItem}>
        {detail.headline && (
          <Heading
            tag="h2"
            variants={[Heading.Variant.tertiary]}
            dangerouslySetInnerHTML={{
              __html: detail.headline,
            }}
          />
        )}
        <CopyText
          customClass={styles.detailText}
          dangerouslySetInnerHTML={{
            __html: detail.text,
          }}
        />
      </TileContainer>
    ))}
  </div>
);

export default SummaryDetails;
