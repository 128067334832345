import React from "react";
import { Container, CopyText, Icon } from "@dtpk-cc/components";
import { LpgConfirmationOutline } from "@dtpk-cc/components/dist/icons";
import * as styles from "./address-check-msg.module.scss";

type AddressCheckMsgProps = {
  text: string;
};

const AddressCheckMsg = ({
  text = `Die Aktion „MagentaTV 9 Monate für 0 €“ ist für diese Adresse buchbar.
        Die Voraussetzung ist MagentaTV Neukunden.`,
}: AddressCheckMsgProps) => (
  <div className={styles.addressCheckMsgWrapper}>
    <div className={styles.addressCheckMsgIconWrapper}>
      <Icon icon={LpgConfirmationOutline} size={Icon.Size.medium} />
    </div>
    <Container variants={[Container.Variant.padded]}>
      <CopyText>
        <b>Herzlichen Glückwunsch!</b>
      </CopyText>
      <CopyText>{text}</CopyText>
    </Container>
  </div>
);

export default AddressCheckMsg;
