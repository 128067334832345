// extracted by mini-css-extract-plugin
var _1 = "tariff-selection-benefit-module-active-Pw1fb";
var _2 = "tariff-selection-benefit-module-logoIcon-W5RAb";
var _3 = "tariff-selection-benefit-module-magentaBenefit-KXs_l";
var _4 = "tariff-selection-benefit-module-rollIn-UC_Al";
var _5 = "tariff-selection-benefit-module-rollOut-udK0G";
var _6 = "tariff-selection-benefit-module-text-DPc65";
var _7 = "tariff-selection-benefit-module-toggleBtn-J225L";
var _8 = "tariff-selection-benefit-module-withLoyaltyBonus-qbHv4";
export { _1 as "active", _2 as "logoIcon", _3 as "magentaBenefit", _4 as "rollIn", _5 as "rollOut", _6 as "text", _7 as "toggleBtn", _8 as "withLoyaltyBonus" }
