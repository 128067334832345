import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Button, CopyText, Input } from "@dtpk-cc/components";
import {
  SQUARE_METERS_MAX,
  SQUARE_METERS_MIN,
} from "../../../../core/entities/PencilSelling/HomeConfigurator/IHomeConfigurator";
import { arrayOfRouterSegments } from "../const";
import { onlyNumber } from "../../../../helpers/NumericCalculation";
import { trackClick } from "../../../../helpers/reactTracking";

import leftArrowImg from "../../../../../assets/images/home-configurator/icons/arrow-left.svg";
import rightArrowImg from "../../../../../assets/images/home-configurator/icons/arrow-right.svg";

import * as styles from "./home-configurator-options.module.scss";
import { useStores } from "../../../../stores/PrivateClient";

type HomeConfiguratorOptionsProps = {
  customClass: string;
  squareMetersInputValue: number;
  setSquareMetersInputValue: (value: number) => void;
  trackingContext: string;
};

const HomeConfiguratorOptions = ({
  customClass = "",
  squareMetersInputValue,
  setSquareMetersInputValue,
  trackingContext,
}: HomeConfiguratorOptionsProps) => {
  const { homeConfiguratorStore } = useStores();
  const { activeFloorVariant, routerIndex, floorsVariants } =
    homeConfiguratorStore;
  const [squareMetersInputIsValid, setSquareMetersInputIsValid] =
    useState(true);

  const checkFloorButtonIsDisabled = useCallback(
    (floorValue, currentSquareMetersInputValue) => {
      if (
        floorValue > 2 &&
        floorValue <= 4 &&
        currentSquareMetersInputValue <= 60
      ) {
        return true;
      }
      return (
        floorValue === 4 &&
        currentSquareMetersInputValue > 60 &&
        currentSquareMetersInputValue <= 100
      );
    },
    []
  );

  const updateFloorVariantsDisableState = useCallback(
    () =>
      // Map floorsVariants for enable/disable case
      floorsVariants.map((floorVariant) => {
        // Define whether a floor button should be disabled
        const shouldBeDisabled = checkFloorButtonIsDisabled(
          floorVariant.value,
          squareMetersInputValue
        );
        const isActive = activeFloorVariant.key === floorVariant.key;
        /*
         * On square meters input value submit, if the current button is active but should be disabled
         * Then activate the highest button which is not disabled.
         * */
        if (
          isActive &&
          shouldBeDisabled &&
          squareMetersInputValue > 60 &&
          squareMetersInputValue <= 100
        ) {
          homeConfiguratorStore.setActiveFloorVariant(floorsVariants[2]);
        }

        if (isActive && shouldBeDisabled && squareMetersInputValue <= 60) {
          homeConfiguratorStore.setActiveFloorVariant(floorsVariants[1]);
        }

        return {
          ...floorVariant,
          disabled: shouldBeDisabled,
        };
      }),
    [
      squareMetersInputValue,
      floorsVariants,
      checkFloorButtonIsDisabled,
      activeFloorVariant,
      homeConfiguratorStore,
    ]
  );

  const squareMetersSubmitHandler = () => {
    const updatedFloorVariants = updateFloorVariantsDisableState();
    homeConfiguratorStore.setFloorsVariants(updatedFloorVariants);
    homeConfiguratorStore.setSquareMetersValue(squareMetersInputValue);
    trackClick("quadratmeter-eingabewert-anwenden", trackingContext);
  };

  const squareMetersChangeHandler = ({ target }) => {
    const squareMetersValue = Number(target.value);
    const inputIsValid = !(
      squareMetersValue > SQUARE_METERS_MAX ||
      squareMetersValue < SQUARE_METERS_MIN
    );

    setSquareMetersInputValue(squareMetersValue);
    setSquareMetersInputIsValid(inputIsValid);
  };

  return (
    <div className={customClass}>
      <div className={styles.homeConfiguratorOptionsItem}>
        <CopyText customClass={styles.homeConfiguratorOptionsTitle}>
          Wie viele m² Wohnfläche nutzen Sie?
        </CopyText>
        <div className={styles.homeConfiguratorSquareMetersInputWrapper}>
          <Input
            tabIndex={0}
            className={`${styles.homeConfiguratorSquareMetersInput} ${
              squareMetersInputIsValid
                ? ""
                : styles.error
            }`}
            type="text"
            value={squareMetersInputValue.toString()}
            onChange={squareMetersChangeHandler}
            onKeyPress={(event) => {
              onlyNumber(event);
              if (squareMetersInputIsValid && event.key === "Enter") {
                squareMetersSubmitHandler();
              }
            }}
          />
          <Button
            disabled={!squareMetersInputIsValid}
            tabIndex={0}
            customClass={styles.homeConfiguratorSquareMetersSubmitBtn}
            onClick={squareMetersSubmitHandler}
          >
            ok
          </Button>
        </div>
        <CopyText
          customClass={`${styles.homeConfiguratorSquareMetersSubtitle} ${
            squareMetersInputIsValid
              ? ""
              : styles.error
          }`}
        >
          Werte zwischen {SQUARE_METERS_MIN}-{SQUARE_METERS_MAX} m² möglich
        </CopyText>
      </div>

      <div className={styles.homeConfiguratorOptionsItem}>
        <CopyText customClass={styles.homeConfiguratorOptionsTitle}>
          Wie viele Etagen haben Sie?
        </CopyText>
        <div className={styles.homeConfiguratorFloorsItems}>
          {floorsVariants.map((floorItem, index) => (
            <Button
              aria-label={`home-konfigurator-etagenwohnung-${index + 1}`}
              customClass={`${styles.homeConfiguratorFloorsFloorBtn} ${
                activeFloorVariant.value === floorItem.value
                  ? styles.active
                  : ""
              }`}
              tabIndex={0}
              key={floorItem.key}
              disabled={floorItem.disabled}
              onClick={() => {
                homeConfiguratorStore.setActiveFloorVariant(
                  floorsVariants[index]
                );
                trackClick("menge-der-hausböden-festlegen", trackingContext);
              }}
              variants={Button.Variant.roundOutline}
            >
              {floorItem.value}
            </Button>
          ))}
        </div>
      </div>

      <div className={styles.homeConfiguratorOptionsItem}>
        <CopyText customClass={styles.homeConfiguratorOptionsTitle}>
          Welchen Router nutzen Sie aktuell?
        </CopyText>
        <div className={styles.homeConfiguratorRouterSliderWrapper}>
          <Button
            aria-label="home-konfigurator-vorheriger-routertyp"
            customClass={styles.homeConfiguratorRouterSliderBtn}
            disabled={routerIndex === 0}
            onClick={() => {
              homeConfiguratorStore.setRouterIndex(routerIndex - 1);
              trackClick("routertyp-einstellen", trackingContext);
            }}
            variants={Button.Variant.roundOutline}
          >
            <Button.Icon icon={leftArrowImg} />
          </Button>
          <img
            className={styles.homeConfiguratorRouterOptionImg}
            src={arrayOfRouterSegments[routerIndex].src}
            alt="router-type"
          />
          <Button
            aria-label="home-konfigurator-nächster-router-typ"
            customClass={styles.homeConfiguratorRouterSliderBtn}
            disabled={routerIndex === arrayOfRouterSegments.length - 1}
            onClick={() => {
              homeConfiguratorStore.setRouterIndex(routerIndex + 1);
              trackClick("routertyp-einstellen", trackingContext);
            }}
            variants={Button.Variant.roundOutline}
          >
            <Button.Icon icon={rightArrowImg} />
          </Button>
        </div>
        <CopyText customClass={styles.homeConfiguratorRouterSubtitle}>
          {arrayOfRouterSegments[routerIndex].value}
        </CopyText>
      </div>
    </div>
  );
};

export default observer(HomeConfiguratorOptions);
