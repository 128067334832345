import { Checkbox } from "@dtpk-cc/components";
import { trackClick } from "helpers/reactTracking";
import React from "react";
import * as styles from "views/MagentaNext/magenta-next.module.scss";

type LegacyTariffCheckboxProps = {
  isLegacyTariffVisible: boolean;
  setIsLegacyTariffVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LegacyTariffCheckbox = ({
  isLegacyTariffVisible,
  setIsLegacyTariffVisible,
}: LegacyTariffCheckboxProps) => (
  <div className={`${styles.magentaNext} ${styles.checkbox}`}>
    <Checkbox
      checked={isLegacyTariffVisible}
      onChange={(value) => {
        setIsLegacyTariffVisible(value);
        trackClick("mit-mobilfunk-tarifen-im-bestand-vergleichen");
      }}
    >
      Mit Mobilfunk-Tarifen im Bestand vergleichen
    </Checkbox>
  </div>
);
