import type { IProduct, Group, ILevel, ITariffWorld } from "../IProduct";
import type { Bandwidths, ITariff } from "../Tariff/ITariff";

export enum CardSections {
  MAGENTA_MOBILE_CARD = "mobile-card",
  MAGENTA_NEXT = "next-card",
  MAGENTA_NEXT_SECONDARY = "next-secondary-card",
  MAGENTA_MOBILE_KIDS_CARD = "mobile-kids-card",
  MAGENTA_MOBILE_DATA_CARD = "mobile-data-card",
  FAMILY_CARD = "family-card",
  ADDITIONAL_CARD = "additional-card",
  COMBI_CARD = "combi-card",
  // To differentiate the products by their size
  COMBI_CARD_S = "combi-card-s",
  COMBI_CARD_M = "combi-card-m",
  CUSTOM_CARD = "custom-card",
  LEGACY_SUMMARY = "legacy-summary",
}

export enum FilteredCardSections {
  MAGENTA_NEXT = "next-card",
  MAGENTA_NEXT_SECONDARY = "next-secondary-card",
  ADDITIONAL_CARD = "additional-card",
  MAGENTA_MOBILE_KIDS_CARD = "mobile-kids-card",
  MAGENTA_MOBILE_DATA_CARD = "mobile-data-card",
}

interface AllSections {
  [CardSections.MAGENTA_MOBILE_CARD]: ITariff[];
  [CardSections.MAGENTA_NEXT]: ICard[];
  [CardSections.MAGENTA_NEXT_SECONDARY]: ICard[];
  [CardSections.MAGENTA_MOBILE_KIDS_CARD]: ICard[];
  [CardSections.MAGENTA_MOBILE_DATA_CARD]: ICard[];
  [CardSections.FAMILY_CARD]: ICard[];
  [CardSections.ADDITIONAL_CARD]: ICard[];
  [CardSections.COMBI_CARD]: ICard[];
  [CardSections.CUSTOM_CARD]: ICard[];
  [CardSections.LEGACY_SUMMARY]: ICard[];
}

export type CardExport = Partial<AllSections>;

export type LegacySummaryCard = {
  name: string;
  quantity: number;
  price: {
    monthly: number | null;
    once: number | null;
    provision?: number | null;
  };
  dataVolume?: string;
  tariffWorld: ITariffWorld;
  contractPeriod: null;
};

export enum CardLimits {
  TOTAL = 10,
}

export enum Volume {
  INFINITY_RAW = "unbegrenzt",
  INFINITY_VALUE = "∞ GB",
}

export interface CardDetails {
  smartphoneData: {
    name: string;
    price: string;
  }[];
}

/**
Interface for a card product that extends the base product interface.
 * @interface ICard
 * @extends {IProduct}
 * @property {ITariffWorld} tariffWorld - The tariff world associated with the card.
 * @property {Group.card} type - The product type is always set to 'card' for card products.
 * @property {string | undefined} dataVolume - The data volume of the card product. If inheritingCard is true this value should be undefined
 * @property {string | undefined} dataVolumeMagentaOne - The data volume of the card product if benefit is activated. If inheritingCard is true this value should be undefined
 * @property {(keyof Bandwidths | undefined)} size - The size of the card product in terms of bandwidth.
 * @property {string[]} availableFor - The tariff world keys that the card product is available for. Cards can only be selected if the main mobile TariffWorldKey is selected and included in this array. Identical to available_for in ResponseInterface.
 * @property {ILevel} level - The level of the card product.
 * @property {number | undefined} quantity - The quantity of the card product. Only Applicable for magenta_next
 * @property {boolean | undefined} isLegacy - A flag indicating if the card product is legacy or not. Only applicable for magenta_next
 * @property {string | undefined} generation - The generation of the card product. Only applicable for magenta_next
 * @property {number | undefined} limit - The limit associated with the card world. Within a specific card world, the limit is the maximum number of cards of this card world that can be added. Not applicable for tariff world
 * @property {(boolean | null)} inheritingCard - A flag indicating if the card inherits data volume from a main tariff or not.
 * @property {(boolean | null)} hasMultisim - A flag indicating if the card supports multi-sim.
 * @property {(CardDetails | undefined)} cardDetails - The details of the added smartphones associated with the tariff. Name should be deviceDetails.
*/
export interface ICard extends IProduct {
  tariffWorld: ITariffWorld;
  type: Group.card;
  dataVolume?: string;
  dataVolumeMagentaOne?: string;
  size?: keyof typeof Bandwidths;
  availableFor: string[];
  level: ILevel;
  quantity?: number;
  isLegacy?: boolean;
  generation?: string;
  limit?: number;
  inheritingCard: boolean | null;
  hasMultisim: boolean | null;
  cardDetails?: CardDetails;
}

export interface ICardDetailSection {
  [CardSections.MAGENTA_NEXT]: ICardDetail[];
  [CardSections.MAGENTA_NEXT_SECONDARY]: ICardDetail[];
  [CardSections.MAGENTA_MOBILE_KIDS_CARD]: ICardDetail[];
  [CardSections.MAGENTA_MOBILE_DATA_CARD]: ICardDetail[];
  [CardSections.COMBI_CARD_S]: ICardDetail[];
  [CardSections.COMBI_CARD_M]: ICardDetail[];
  [CardSections.ADDITIONAL_CARD]: ICardDetail[];
}

export interface ICardDetail {
  cardKey: string;
  levelName: string;
  smartphoneName: string;
  smartphonePrice: string;
}
