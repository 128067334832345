// TODO Add max input value constant
export const SQUARE_METERS_MIN = 50;
export const SQUARE_METERS_MAX = 420;
export const REPEATERS_AMOUNT_MAX = 5;

export enum FloorVariantKeys {
  FLOOR1 = "floor1",
  FLOOR2 = "floor2",
  FLOOR3 = "floor3",
  FLOOR4 = "floor4",
}

export enum ConfigParamsKeys {
  LESS_THAN_60 = "less_60",
  MORE_THAN_60 = "more_than_60",
  MORE_THAN_100 = "more_100",
  MORE_THAN_200 = "more_than_200",
  MORE_THAN_300 = "more_than_300",
}

export enum RouterType {
  NEED_ROUTER = "need-router",
  SMART4 = "smart-4",
  PRO_PLUS = "pro-plus",
  OTHER = "other",
}

export enum DeviceTypes {
  ROUTER = "router",
  REPEATER = "repeater",
}

export type IConfiguratorFloorsMap = {
  [key in FloorVariantKeys]: {
    lottieFile: object;
    baseRepeatersAmount: number;
  };
};

export type IConfiguratorParamsMap = {
  [key in ConfigParamsKeys]: Partial<IConfiguratorFloorsMap>;
};

export type IArraySegment = {
  key: Partial<RouterType>;
  value: string;
  segment: [number, number];
  src: string;
};

export type IFloorItem = {
  key: Partial<FloorVariantKeys>;
  value: number;
  disabled: boolean;
};

export type IConfiguratorSettings = {
  squareMetersKey: Partial<ConfigParamsKeys>;
  activeFloorVariant: IFloorItem;
  routerIndex: number;
  squareMetersValue: number;
  floorsVariants: IFloorItem[];
};

export interface IHomeConfigurator {
  setSquareMetersKey(keyValue: ConfigParamsKeys): void;
  setSquareMetersValue(value: number): void;
  setActiveFloorVariant(floorVariant: IFloorItem): void;
  setRouterIndex(value: number): void;
  setFloorsVariants(currentFloorVariants: IFloorItem[]): void;
  reset(): void;
}
