import React from "react";
import { CopyText } from "@dtpk-cc/components";
import { IStreamingServicesKeys } from "../../core/entities/Product/IStreamingServiceKeys";

type StreamingServiceStateProps = {
  keyValue: string;
  priceIsDisplayed: boolean;
  withAdditionalInfo: boolean;
  isActive: boolean;
  priceValue?: string;
  customClass?: string;
  serviceName: string;
};

const StreamingServicePriceResult = ({
  keyValue,
  serviceName,
  priceIsDisplayed,
  withAdditionalInfo,
  isActive,
  customClass = "",
  priceValue = "",
}: StreamingServiceStateProps) =>
  priceIsDisplayed ? (
    <>{!isActive ? `zubuchbar (${priceValue})` : priceValue}</>
  ) : (
    <>
      <CopyText
        customClass={`streaming-services__inclusive-wrapper streaming-services__inclusive-wrapper--bold ${customClass}`}
      >
        {keyValue === IStreamingServicesKeys.hd_plus
          ? "Private TV-Sender in HD"
          : serviceName}
        <CopyText variants={CopyText.Variant.magenta} tag="span">
          inklusive
        </CopyText>
      </CopyText>
      {withAdditionalInfo && (
        <div className="streaming-services__inclusive-wrapper--additional-info">
          {keyValue === IStreamingServicesKeys.live_tv && (
            <>
              <CopyText variants={CopyText.Variant.highlight}>
                <CopyText
                  variants={[
                    CopyText.Variant.magenta,
                    CopyText.Variant.highlight,
                  ]}
                  tag="span"
                >
                  inklusive
                </CopyText>{" "}
                aller Spiele der FIFA WM 2022 live
              </CopyText>
              <CopyText>50 Std. Aufnahmespeicher</CopyText>
            </>
          )}
        </div>
      )}
    </>
  );

export default StreamingServicePriceResult;
