import { IConfigurationsSummary } from "../ISummary";
import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Group } from "../../../../core/entities/Product/IProduct";
import { CartItemSummaryPresenter } from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import {
  calculateConfigurationItemOnceTotalPrice,
  getConfigurationItemTotalMonthlyPrice,
  getCashbackPromotions,
  mapCashbackPromotions,
} from "./index";

const mapDevicesSummary = ({
  offerExportConfigurations,
  configurationsSummary,
  priceType,
}: {
  offerExportConfigurations: ICartConfiguration[];
  configurationsSummary: IConfigurationsSummary;
  priceType: string;
}) => {
  const filteredOfferExportConfigurations = offerExportConfigurations.filter(
    (offerExportedConfigurationItem) =>
      !!offerExportedConfigurationItem.children.getDevices().length
  );

  filteredOfferExportConfigurations.forEach((configurationItem, index) => {
    const configurationDevices = configurationItem.children.getDevices();

    const summaryConfigurationsArr =
      configurationsSummary[ICartConfigurationType.DEVICE].configurationItems;
    summaryConfigurationsArr.push({
      title: "Geräte-Konfiguration",
      amount: configurationItem.amount,
      sections: [
        {
          name: "Geräte",
          group: Group.device,
          items: configurationDevices.map((device) => {
            const presenter = new CartItemSummaryPresenter(
              device,
              null,
              priceType
            );
            return presenter.configurationExport();
          }),
        },
      ],
      totalPrice: {
        priceType,
        title: "Teilsumme",
        monthlyPrice: {
          mainPrice: null,
          priceStepsDescriptions: [],
        },
        oncePrice: null,
        totalOncePriceDeductions: null,
      },
    });

    summaryConfigurationsArr[index].totalPrice.totalOncePriceDeductions =
      mapCashbackPromotions(getCashbackPromotions(configurationDevices));

    summaryConfigurationsArr[index].totalPrice.monthlyPrice =
      getConfigurationItemTotalMonthlyPrice(summaryConfigurationsArr[index]);

    summaryConfigurationsArr[index].totalPrice.oncePrice =
      calculateConfigurationItemOnceTotalPrice(summaryConfigurationsArr[index]);
  });
};

export default mapDevicesSummary;
