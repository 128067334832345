import { Heading } from "@dtpk-cc/components";
import React from "react";

import * as styles from "./magenta-bg-text.module.scss";

interface MagentaBgTextProps {
  text: string;
  className?: string;
}

export const MagentaBgText = ({ text, className = "" }: MagentaBgTextProps) => (
  <Heading
    tag="h1"
    isResponsive={false}
    variants={[Heading.Variant.primary, Heading.Variant.display]}
    customClass={`${styles.bgText} ${className}`}
  >
    {text}
  </Heading>
);
