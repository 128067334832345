import { ConfigurationsEntity } from "../Configurations/Configurations";
import { Offer } from "./Offer/Offer";
import { ProductsEntity } from "../Products/ProductsEntity";
import { ProductsRepository } from "../../../repositories/ProductsRepository/ProductsRepository";
import { HomeConfigurator } from "../HomeConfigurator/HomeConfigurator";
import { FactModalEntity } from "../FactModal/FactModal";

let httpFetch: typeof fetch = () => Promise.reject();
if (window.fetch) httpFetch = window.fetch.bind(window) as typeof fetch;

export const homeConfigurator = new HomeConfigurator();
export const configuration = new ConfigurationsEntity();
export const offer = new Offer(configuration);
export const productsEntity = new ProductsEntity();
export const productsRepository = new ProductsRepository(
  httpFetch,
  offer.getBaseOffer()
);
export const factModalEntity = new FactModalEntity(offer.getBaseOffer());
