import React from "react";
import { Heading } from "@dtpk-cc/components";
import ModalWrapper from "elements/new-design/ModalWrapper";
import ModalWrapperActionFooter from "elements/new-design/ModalWrapper/ModalWrapperActionFooter";
import ModalSelectionDescription from "elements/new-design/ModalSelectionDescription";
import * as styles from "./tariff-configuration-extra-selection-modal.module.scss";

type TariffConfigurationExtraSelectionModalProps = {
  isOpen: boolean;
  title?: string;
  businessCaseName: string;
  tariffName?: string;
  onSubmit: () => void;
  onCancel: () => void;
  setIsOpen: (value) => void;
  customClass?: string;
  submitDisabled?: boolean;
};

const TariffConfigurationExtraSelectionModal: React.FC<
  TariffConfigurationExtraSelectionModalProps
> = ({
  isOpen,
  setIsOpen,
  businessCaseName,
  tariffName = "",
  children,
  onSubmit,
  onCancel,
  title = "Endgeräte hinzufügen",
  customClass = "",
  submitDisabled = false,
}) => (
  <ModalWrapper
    containerCustomClass={`${customClass} ${styles.extrasModal}`}
    onBackDropClick={() => {
      setIsOpen(false);
    }}
    onClose={() => {
      setIsOpen(false);
    }}
    isOpen={isOpen}
  >
    <Heading
      tag="h3"
      variants={[Heading.Variant.quaternary, Heading.Variant.display]}
      customClass={styles.modalHeader}
    >
      {title}
    </Heading>
    <div>
      <ModalSelectionDescription
        businessCaseName={businessCaseName}
        productName={tariffName}
      />
    </div>
    {children}
    <ModalWrapperActionFooter
      onSubmit={onSubmit}
      onCancel={onCancel}
      cancelBtnText="Abbrechen"
      submitBtnText="Übernehmen"
      submitDisabled={submitDisabled}
    />
  </ModalWrapper>
);

export default TariffConfigurationExtraSelectionModal;
