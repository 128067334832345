import React from "react";
import PageWrapper from "../../../../elements/new-design/PageWrapper";
import StepHeader from "../../StepHeader";
import PageInner from "../../../../elements/new-design/PageWrapper/PageInner";
import { FactModalEntity } from "../../../../core/entities/PencilSelling/FactModal/FactModal";
import { FactModalKeys } from "../../../../core/entities/PencilSelling/FactModal/IFactModal";

type OfferFinalizationWrapperProps = {
  title: string;
  subtitle: string;
  customClass?: string;
};

const OfferFinalizationWrapper: React.FC<OfferFinalizationWrapperProps> = ({
  customClass = "",
  children,
  title,
  subtitle,
}) => (
  <PageWrapper>
    <StepHeader title={title} subtitle={subtitle} />
    <PageInner>
      <div className={`mainContent ${customClass}`}>{children}</div>
    </PageInner>
  </PageWrapper>
);

export default OfferFinalizationWrapper;
