import type {
  IOffer,
  ICardSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { IGetCardSettingsUseCase } from "./IGetCardSettings";

export class GetCardSettingsUsecase implements IGetCardSettingsUseCase {
  constructor(private entity: IOffer) {}

  execute(): ICardSettings {
    return this.entity.getCardSettings();
  }
}
