import React from "react";
import { CopyText, Grid, Heading, Price } from "@dtpk-cc/components";
import CollapsibleText from "elements/CollapsibleText";
import SummarySectionHeader from "../../SummarySectionHeader";

import "./extension-section.scss";
import { summarySectionGridColData } from "../../CartSection/summarySectionGridColData";
import { IExtension } from "../../../core/entities/Product/IExtension";

type IExtensionSectionProps = {
  headline: string;
  extensionItem: IExtension;
};

const ExtensionSection = ({
  headline,
  extensionItem,
}: IExtensionSectionProps) => (
  <Grid.Row customClass="cart-section">
    <SummarySectionHeader headline={headline} />
    <Grid.Row customClass="cart-section__product-row">
      <Grid.Col
        {...summarySectionGridColData.main}
        customClass="extension-section__col"
      >
        <Heading tag="h3" variants={Heading.Variant.tertiary}>
          {extensionItem.name}
        </Heading>
        {!!extensionItem.productDescription.description && (
          <CopyText>{extensionItem.productDescription.description}</CopyText>
        )}
      </Grid.Col>
      <Grid.Col
        {...summarySectionGridColData.monthlyPrice}
        customClass="extension-section__col"
      >
        {!!extensionItem.price.monthly && (
          <Price
            variants={[Price.Variant.brand, Price.Variant.large]}
            value={extensionItem.price.monthly}
          />
        )}
      </Grid.Col>
      <Grid.Col
        {...summarySectionGridColData.oncePrice}
        customClass="extension-section__col"
      >
        {!!extensionItem.price.once && (
          <Price
            variants={[Price.Variant.brand, Price.Variant.large]}
            value={extensionItem.price.once}
          />
        )}
      </Grid.Col>
      {!!extensionItem.productDescription.text && (
        <CollapsibleText
          headline={extensionItem.productDescription.summary}
          text={extensionItem.productDescription.text}
          productName={extensionItem.name}
        ></CollapsibleText>
      )}
    </Grid.Row>
  </Grid.Row>
);

export default ExtensionSection;
