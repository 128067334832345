import type { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import type { ISetCardPrice } from "./ISetCardPrice";

export class SetCardPrice implements ISetCardPrice {
  constructor(private entity: ILegacyTariff) {}

  execute(key: string, price: number): void {
    this.entity.setCardPrice(key, price);
  }
}
