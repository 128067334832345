import { IRemoveCustomPromotion } from "./IRemoveCustomPromotion";
import { IOffer } from "../../../entities/PencilSelling/Offer_legacy/IOffer";
import { PortfolioKeys } from "../../../entities/Product/IProduct";

export class RemoveCustomPromotionUseCase implements IRemoveCustomPromotion {
  constructor(private entity: IOffer) {}

  execute(promotionSection: PortfolioKeys, promotionKey: string) {
    this.entity.removeCustomPromotion(promotionSection, promotionKey);
  }
}
