import React from "react";
import { CopyText } from "@dtpk-cc/components";

import * as styles from "./modal-selection-description.module.scss";

type ModalSelectionDescriptionProps = {
  businessCaseName: string;
  productName: string;
};

const ModalSelectionDescription = ({
  businessCaseName,
  productName,
}: ModalSelectionDescriptionProps) => (
  <div className={styles.descrWrapper}>
    <CopyText customClass={styles.descrItem}>
      <span className={styles.descrItemKey}>Geschäftsfall:</span>
      <span className={styles.descrItemValue}>{businessCaseName}</span>
    </CopyText>
    <CopyText customClass={styles.descrItem}>
      <span className={styles.descrItemKey}>Tarif:</span>
      <span className={styles.descrItemValue}>{productName}</span>
    </CopyText>
  </div>
);

export default ModalSelectionDescription;
