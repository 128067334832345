import React, { useEffect, useMemo, useState, useCallback } from "react";
import { trackClick } from "helpers/reactTracking";
import {
  Heading,
  HintBox,
  Icon,
  Grid,
  Container,
  CopyText,
  EqualHeight,
  ModalLegal,
} from "@dtpk-cc/components";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";
import {
  Bandwidths,
  ITariff,
  TariffSizes,
} from "core/entities/Product/Tariff/ITariff";
import TariffEntry from "elements/TariffEntry";
import { CardConfigModal } from "components/CardConfigModal";
import {
  CardLimits,
  CardSections,
  ICard,
} from "core/entities/Product/Card/ICard";
import ProvisionFee from "components/ProvisionFee";
import LoyaltyBonusPriceBadge from "components/LoyaltyBonusPriceBadge";
import * as styles from "views/MagentaNext/magenta-next.module.scss";
import { ISettings } from "core/entities/MagentaNext/Tariff/ITariff";
import { IPromotion } from "core/entities/PencilSelling/IPromotion";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { ILegacyTariff } from "core/entities/MagentaNext/LegacyTariff/ILegacyTariff";
import { observer } from "mobx-react";
import {
  IActiveSettings,
  MagentaNextCard,
} from "core/presenter/MagentaNext/LegacyTariffPresenter";
import CardCategory from "components/CardCategory";
import { TariffConfiguratorPresenter } from "./Presenter";
import { BENEFIT_VALUE } from "../../../core/entities/MagentaNext/Tariff/Tariff";
import TariffSettings from "../TariffSettings";
import OfferAdvantage from "../../../elements/OfferAdvantage";
import SecondaryCardSection from "../SecondaryCardSection";
import MagentaNextCardTile from "../CardTile/MagentaNextCardTile";
import FamilyCardTile from "../CardTile/FamilyCardTile";
import PromotionsCardCategory from "../../CardCategory/PromotionsCardCategory";
import {
  getDiscountOption,
  getHappyHourBenefitOptionName,
} from "../../../helpers/GetBenefitOptions";
import { HAPPY_HOUR_BENEFIT_OPTION_TARIFF_TILE_TEXT } from "../../../constants/const";

const TRACKING_CONTEXT = "magentaeins-rechner";

type TariffConfiguratorProps = {
  isLegacyTariffVisible: boolean;
  handleSettingsChange: (key: keyof ISettings, value: any) => void;
  hasProvisionFee: boolean;
  settings: ISettings;
  onAsteriskClick: () => void;
  setTransferButtonIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const TariffConfigurator = ({
  isLegacyTariffVisible,
  handleSettingsChange,
  hasProvisionFee,
  settings,
  onAsteriskClick,
  setTransferButtonIsEnabled,
}: TariffConfiguratorProps) => {
  const { tariffStore } = useMagentaNextCalculatorStores();

  const cards = tariffStore.getCards();

  const [presenter] = useState(
    new TariffConfiguratorPresenter(cards[CardSections.FAMILY_CARD])
  );
  const activeCards = tariffStore.getActiveCards();
  const customPromotions = tariffStore.getPromotions();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false);
  // 'activeSettings' are used by card modal not to be confused with 'settings'
  const [activeSettings, setActiveSettings] = useState(
    presenter.getActiveSettings()
  );

  const activeMainCard = activeCards[CardSections.MAGENTA_MOBILE_CARD][0];

  const familyCard = presenter.card;

  const mainTariffLevels = tariffStore.getMobileTariffsLevels();
  const magentaNextCards = cards[CardSections.MAGENTA_NEXT];
  const nextSecondaryCards = cards[CardSections.MAGENTA_NEXT_SECONDARY];
  const mobileCards = cards[CardSections.MAGENTA_MOBILE_CARD];
  const kidsCards = cards[CardSections.MAGENTA_MOBILE_KIDS_CARD];
  const dataCards = cards[CardSections.MAGENTA_MOBILE_DATA_CARD];
  let multiSimCards = cards[CardSections.ADDITIONAL_CARD].map((card) =>
    TariffConfiguratorPresenter.overwriteVolume(
      card,
      settings.hasBenefit,
      activeMainCard
    )
  );

  const combiCards = cards[CardSections.COMBI_CARD];
  const combiCardsSSize = combiCards.filter((card) => card.size === "S");
  const combiCardsMSize = combiCards.filter((card) => card.size === "M");
  const [multiSimDefaultCard, multiSimXlCard] = multiSimCards;
  multiSimCards =
    activeMainCard?.size === TariffSizes.XL
      ? [multiSimXlCard]
      : [multiSimDefaultCard];

  const [activeNextSecondaryCard] =
    activeCards[CardSections.MAGENTA_NEXT_SECONDARY];
  const isMagentaNextActive = !!activeCards[CardSections.MAGENTA_NEXT].length;
  const isFamilyCardActive = !!activeCards[CardSections.FAMILY_CARD].length;
  const isAdditionalCardModeActive = isMagentaNextActive || isFamilyCardActive;
  const { isCardLimitReached } = tariffStore;

  const filteredMainCards: MagentaNextCard[] = useMemo(
    () => mobileCards.filter((tariff) => tariff.level.key === settings.level),
    [mobileCards, settings.level]
  );

  const setActiveAdditionCard = (cardKey?: string) => {
    tariffStore.getFamilyCards(true).forEach(({ key }) => {
      tariffStore.setQuantity(key, 0);
    });

    tariffStore.getMagentaNextCard(true).forEach(({ key }) => {
      tariffStore.setQuantity(key, 0);
      tariffStore.setCardSectionItem(CardSections.MAGENTA_NEXT, []);
    });

    if (cardKey) tariffStore.setQuantity(cardKey, 1);
  };

  const handleCardClick = useCallback(
    (card: ITariff | ICard) => {
      if (!card) return;

      if (card.tariffWorld.key === TariffWorldKeys.magenta_mobile) {
        tariffStore.getMagentaMobileTariffs(true).forEach(({ key }) => {
          tariffStore.setQuantity(key, 0);
        });
      }

      if (card.tariffWorld.key === CardSections.CUSTOM_CARD) {
        (tariffStore as unknown as ILegacyTariff).addCustomCard(card as ICard);
      }

      tariffStore.setQuantity(card.key, 1);
    },
    [tariffStore]
  );

  function handleCardAdditionClick(key?: string) {
    setActiveAdditionCard(key);
  }

  function changeCardQuantity(key: string, quantity: number) {
    tariffStore.setQuantity(key, quantity);
  }

  function handleFamilyCardChange() {
    setIsModalOpen(true);
  }

  function handleActiveSettingsChange(
    key: keyof IActiveSettings,
    value: string | number
  ) {
    presenter.setActiveSetting(key, value);
    setActiveSettings(presenter.getActiveSettings());
  }

  function handlePromotionChange(
    index: string,
    key: string,
    value: number | string
  ) {
    const items = customPromotions;

    items[index] = {
      ...items[index],
      [key]: key === "discount" ? { kind: "monthly", value } : value,
    };
    tariffStore.setPromotions([...items]);
  }

  function handleRemovePromotion(item: IPromotion) {
    const items = customPromotions.filter((el) => el.key !== item.key);
    tariffStore.setPromotions(items);
  }

  function handleAddPromotion() {
    const emptyPromotion: IPromotion = {
      key: String(customPromotions.length),
      name: `Aktionsname ${customPromotions.length + 1}`,
      contractPeriod: 0,
      conditions: [],
      totalPriceDescription: null,
      summaryDescription: { summary: null, text: null },
    };
    tariffStore.setPromotions([...customPromotions, emptyPromotion]);
  }

  function getTariffEntryPrice(
    productMonthlyPrice: number,
    provisionFee: number
  ) {
    if (settings.loyaltyBonusValue <= 0) return productMonthlyPrice;

    let price =
      productMonthlyPrice -
      (BENEFIT_VALUE +
        tariffStore.getValueCalculatedByPeriod(settings.loyaltyBonusValue));

    if (hasProvisionFee) {
      price += tariffStore.getValueCalculatedByPeriod(provisionFee);
    }

    return price > 0 ? price : 0;
  }

  function getDataVolume(card: MagentaNextCard) {
    const targetCard =
      card && "inheritingCard" in card && card?.inheritingCard
        ? activeMainCard
        : card;

    return TariffConfiguratorPresenter.getVolume(
      targetCard,
      settings.hasBenefit
    );
  }

  function getCardHighlightedText(card: MagentaNextCard): string {
    return getHappyHourBenefitOptionName("benefits" in card && card.benefits[0])
      ? HAPPY_HOUR_BENEFIT_OPTION_TARIFF_TILE_TEXT
      : "∞ für alle";
  }

  function cardIsHighlighted(card: MagentaNextCard): boolean {
    return (
      (getHappyHourBenefitOptionName("benefits" in card && card.benefits[0]) &&
        settings.hasBenefit) ||
      (card.size === "L" &&
        settings.hasBenefit &&
        settings.loyaltyBonusValue <= 0)
    );
  }

  // MAGENTA_NEXT: choose new activeMainCard, if level changes
  useEffect(() => {
    if (activeMainCard && activeMainCard.level.key !== settings.level) {
      const newActiveMainCard = filteredMainCards.find(
        (card) =>
          card.size === activeMainCard.size && card.level.key === settings.level
      );
      handleCardClick(newActiveMainCard);
    }
  }, [filteredMainCards, settings.level, activeMainCard, handleCardClick]);

  // MAGENTA_NEXT: reset nextSecondaryCard if no nextCard is selected
  useEffect(() => {
    if (activeNextSecondaryCard?.quantity && !isAdditionalCardModeActive) {
      tariffStore.setQuantity(activeNextSecondaryCard.key, 0);
    }
  }, [
    activeNextSecondaryCard?.key,
    activeNextSecondaryCard?.quantity,
    isAdditionalCardModeActive,
    tariffStore,
  ]);

  // MAGENTA_NEXT: reset multiSim depending on selected mainCard
  useEffect(() => {
    if (
      activeMainCard?.size === TariffSizes.XL &&
      multiSimDefaultCard?.quantity
    ) {
      tariffStore.setQuantity(multiSimDefaultCard?.key, 0);
    }

    if (activeMainCard?.size !== TariffSizes.XL && multiSimXlCard?.quantity) {
      tariffStore.setQuantity(multiSimXlCard.key, 0);
    }
  }, [
    activeMainCard?.size,
    multiSimDefaultCard?.key,
    multiSimDefaultCard?.quantity,
    multiSimXlCard?.key,
    multiSimXlCard?.quantity,
    tariffStore,
  ]);

  return (
    <div className={`${styles.magentaNext} ${styles.tariffConfigurator}`}>
      <OfferAdvantage
        customClass={`${styles.magentaNext} ${styles.benefitsButton}`}
        active={settings.hasBenefit}
        onChange={(value) => {
          handleSettingsChange("hasBenefit", !value);
          trackClick("checkbox.magentaeins-vorteile");
        }}
      />

      {/*
          temporary hidden
          
          <LoyaltyBonus
            rerender={settings.hasBenefit}
            trackingContext={TRACKING_CONTEXT}
            value={settings.loyaltyBonusValue}
            disabled={!settings.hasBenefit}
            onChange={(value) => {
              handleSettingsChange("loyaltyBonusValue", value);
            }}
            openModal={onAsteriskClick}
            freeMonths={getFreeMonths(activeMainCard, settings.loyaltyBonusValue)}
            showFreeMonthsText={Boolean(
              settings.loyaltyBonusValue > 0 && activeMainCard
            )}
          />
        */}

      <Container>
        <div className={`${styles.magentaNext} ${styles.scrollContainer}`}>
          {filteredMainCards.map((card: ITariff, index) => {
            const discountOption = getDiscountOption(card.benefits[0]);
            return (
              <Grid.Col
                customClass={`${styles.magentaNext} ${styles.scrollChild}
              ${activeMainCard?.key === card.key ? "" : styles.hideInPrint}
              ${isLegacyTariffVisible ? styles.noFlexShrink : ""}
            `}
                key={card.key}
                s={isLegacyTariffVisible ? 4 : 3}
                m={isLegacyTariffVisible ? 4 : 3}
                l={isLegacyTariffVisible ? 4 : 3}
                xl={isLegacyTariffVisible ? 7 : 6}
              >
                <TariffEntry
                  index={index}
                  toggleActive={() => handleCardClick(card)}
                  active={activeMainCard?.key === card.key}
                  price={
                    settings.hasBenefit && discountOption
                      ? card.price.monthly - (discountOption.value || 0)
                      : card.price?.monthly
                  }
                  legacyPrice={
                    settings.hasBenefit && discountOption
                      ? card.price.monthly
                      : null
                  }
                  subtitle={{
                    tariffFeature: getDataVolume(card),
                    isHighlighted: settings.hasBenefit,
                  }}
                  displaySize={card.displaySize}
                  size={Bandwidths[card.size]}
                  isHighlighted={cardIsHighlighted(card)}
                  highlightText={getCardHighlightedText(card)}
                  withHappyHour={
                    !!getHappyHourBenefitOptionName(
                      "benefits" in card && card.benefits[0]
                    )
                  }
                  trackingContext={TRACKING_CONTEXT}
                  name={card.name}
                  loyaltyBonusPriceBadgeComp={
                    <LoyaltyBonusPriceBadge
                      id="loyalty-bonus-price-badge"
                      loyaltyBonusPrice={getTariffEntryPrice(
                        card.price.monthly,
                        card.price.provision
                      )}
                      disabled={
                        !(settings.loyaltyBonusValue > 0 && settings.hasBenefit)
                      }
                      isLegacyTariffVisible={isLegacyTariffVisible}
                    />
                  }
                />
              </Grid.Col>
            );
          })}
        </div>
      </Container>
      <Container>
        <TariffSettings
          settings={settings}
          levels={mainTariffLevels}
          onChange={(key, value) => handleSettingsChange(key, value)}
          trackingContext={TRACKING_CONTEXT}
        />
      </Container>
      <Container>
        <ProvisionFee
          disabled={settings.loyaltyBonusValue > 0}
          value={hasProvisionFee}
          onChange={(value) => handleSettingsChange("hasProvisionFee", value)}
          trackingContext={TRACKING_CONTEXT}
        />
      </Container>
      <Heading
        tag="h3"
        customClass={`${styles.magentaNext} ${styles.headline} ${styles.headlineWithDelimiter}`}
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        <span>Zusatzkarten</span>
      </Heading>
      <EqualHeight responsiveUpdate>
        <div
          className={`${styles.magentaNext} ${styles.additionalCardsWrapper}`}
        >
          {!!magentaNextCards.length && (
            <MagentaNextCardTile
              isActive={isMagentaNextActive}
              changeCardQuantity={changeCardQuantity}
              magentaNextCards={magentaNextCards}
              disabled={isCardLimitReached && !isAdditionalCardModeActive}
              priceSuffix="mtl."
              title="MagentaMobil PlusKarte"
              content={getDataVolume(magentaNextCards[0])}
              onClick={() => {
                handleCardAdditionClick(
                  !isMagentaNextActive ? magentaNextCards[0].key : undefined
                );
                trackClick(magentaNextCards[0].name);
              }}
            />
          )}
          <div className={`${styles.magentaNext} ${styles.separator}`}>
            <Heading variants={[Heading.Variant.quaternary]}>oder</Heading>
          </div>
          <FamilyCardTile
            isActive={isFamilyCardActive}
            title={familyCard?.name || "Bereits vorhandene Family Card"}
            onClick={() => {
              if (!familyCard) {
                trackClick("tarif-modal.open");
                handleFamilyCardChange();
              } else {
                trackClick(familyCard.name);
                handleCardAdditionClick(
                  !isFamilyCardActive ? familyCard.key : undefined
                );
              }
            }}
            onChange={handleFamilyCardChange}
            price={familyCard?.price.monthly}
            priceSuffix="mtl."
            content={getDataVolume(familyCard)}
            disabled={isCardLimitReached && !isAdditionalCardModeActive}
          />
        </div>
      </EqualHeight>
      <SecondaryCardSection
        title="MagentaMobil PlusKarte+"
        cards={nextSecondaryCards}
        isCardLimitReached={isCardLimitReached}
        disabled={!isFamilyCardActive && !isMagentaNextActive}
        dataVolume={getDataVolume(nextSecondaryCards[0])}
        changeCardQuantity={changeCardQuantity}
        isLegacyTariffVisible={isLegacyTariffVisible}
        cardSectionKey={CardSections.MAGENTA_NEXT_SECONDARY}
      />
      <SecondaryCardSection
        title="MagentaMobil PlusKarte Kids & Teens"
        cards={kidsCards}
        isCardLimitReached={isCardLimitReached}
        dataVolume={getDataVolume(kidsCards[0])}
        changeCardQuantity={changeCardQuantity}
        isLegacyTariffVisible={isLegacyTariffVisible}
        cardSectionKey={CardSections.MAGENTA_MOBILE_KIDS_CARD}
      />
      <SecondaryCardSection
        title="MagentaMobil PlusKarte Data"
        cards={dataCards}
        isCardLimitReached={isCardLimitReached}
        dataVolume={getDataVolume(dataCards[0])}
        changeCardQuantity={changeCardQuantity}
        isLegacyTariffVisible={isLegacyTariffVisible}
        cardSectionKey={CardSections.MAGENTA_MOBILE_DATA_CARD}
      />
      <Heading
        tag="h3"
        customClass={`${styles.magentaNext} ${styles.headline} ${styles.headlineWithDelimiter}`}
        variants={[Heading.Variant.tertiary, Heading.Variant.display]}
      >
        <span>Weitere Zusatzkarten</span>
      </Heading>
      {isCardLimitReached && (
        <div className={`${styles.magentaNext} ${styles.hintbox}`}>
          <HintBox>
            <HintBox.Text>
              <Icon
                icon={AlertInformationDefault}
                size={Icon.Size.small}
                wrapperProps={{
                  className: `${styles.magentaNext} ${styles.hintboxIcon}`,
                }}
              />
              Die maximale Anzahl von {CardLimits.TOTAL} Zusatzkarten ist
              erreicht!
            </HintBox.Text>
          </HintBox>
        </div>
      )}
      <SecondaryCardSection
        title="CombiCard Smart Connect S"
        cards={combiCardsSSize}
        isCardLimitReached={isCardLimitReached}
        dataVolume={getDataVolume(combiCardsSSize[0])}
        changeCardQuantity={changeCardQuantity}
        isLegacyTariffVisible={isLegacyTariffVisible}
        cardSectionKey={CardSections.COMBI_CARD_S}
      />
      <SecondaryCardSection
        title="CombiCard Smart Connect M"
        cards={combiCardsMSize}
        isCardLimitReached={isCardLimitReached}
        dataVolume={getDataVolume(combiCardsMSize[0])}
        changeCardQuantity={changeCardQuantity}
        isLegacyTariffVisible={isLegacyTariffVisible}
        cardSectionKey={CardSections.COMBI_CARD_M}
      />
      <CardCategory
        cards={multiSimCards}
        isActiveHighlighted
        onChangeQuantity={changeCardQuantity}
      />
      <PromotionsCardCategory
        activeMainCard={activeMainCard}
        headerCustomClass={`${styles.magentaNext} ${styles.headline} ${styles.headlineWithDelimiter}`}
        name="Aktionen"
        activePromotions={customPromotions}
        addCardBtnText="Aktion hinzufügen"
        defaultName="Aktionsname"
        onChangeName={(key, name) => {
          handlePromotionChange(key, "name", name);
        }}
        onChangePrice={(key, price) => {
          handlePromotionChange(key, "discount", price);
        }}
        onChangePeriod={(key, period) => {
          handlePromotionChange(key, "contractPeriod", period);
        }}
        onDeleteCard={(item) => {
          handleRemovePromotion(item);
        }}
        onAddCard={() => {
          handleAddPromotion();
          trackClick("aktion-hinzufügen");
        }}
        onAsteriskClick={() => {
          trackClick("aktionen-asterisk", "aktion");
          setIsLegalModalOpen(true);
        }}
        setTransferButtonIsEnabled={setTransferButtonIsEnabled}
      />
      {isModalOpen && (
        <CardConfigModal
          isOpen={isModalOpen}
          card={presenter.card}
          settings={presenter.settings}
          activeSettings={activeSettings}
          headline={"Family Card ändern"}
          onChange={(key, value) => {
            handleActiveSettingsChange(key, value);
            trackClick(`family-card.${value}`);
          }}
          onSubmit={() => {
            setActiveAdditionCard(presenter.card.key);
            trackClick("family-card.speichern");
          }}
          onCloseModal={() => setIsModalOpen(false)}
          withoutBasicSize
        />
      )}
      <ModalLegal
        isOpen={isLegalModalOpen}
        afterClose={() => setIsLegalModalOpen(false)}
        zIndex={100}
        style={{ padding: "1rem" }}
      >
        <ModalLegal.Heading>Berücksichtigung der Aktionen</ModalLegal.Heading>
        <br />
        <CopyText>
          Zusätzliche Aktionen werden in der Gesamtrechnung berücksichtigt, in
          dem diese
        </CopyText>
        <ModalLegal.List>
          <ModalLegal.ListItem>auf 24 Monate umgerechnet</ModalLegal.ListItem>
          <ModalLegal.ListItem>
            {" "}
            und anschließend vom durchschnittlichen Preis pro Karte abgezogen
            werden
          </ModalLegal.ListItem>
        </ModalLegal.List>
      </ModalLegal>
    </div>
  );
};

export default observer(TariffConfigurator);
