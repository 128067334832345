import React, { useRef } from "react";
import ConfigurationFlyoutWrapper from "../../../../../components/new-design/Navigation/ConfigurationFlyout/ConfigurationFlyoutWrapper";
import ConfigurationFlyoutTitle from "../../../../../components/new-design/Navigation/ConfigurationFlyout/ConfigurationFlyoutTitle";
import ConfigurationFlyoutContainer from "../../../../../components/new-design/Navigation/ConfigurationFlyout/ConfigurationFlyoutContainer";
import { ConfigurationsEntity } from "../../../../../core/entities/PencilSelling/Configurations/Configurations";
import { ICartConfigurationType } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import ConfigurationFlyoutItem from "../../../../../components/new-design/Navigation/ConfigurationFlyout/ConfigurationFlyoutItem";
import {
  configurationsNameMap,
  iconsMap,
  TRACKING_CONTEXT,
} from "../../../../../components/new-design/Navigation/ConfigurationFlyout/const";
import { setActiveConfigurationHandler } from "../../../../../components/new-design/Navigation/ConfigurationFlyout/helpers";
import { getConfigurationName } from "../../../../../helpers/GetConfigurationName";

import * as styles from "./secondary-cards-configuration-flyout.module.scss";

type SecondaryCardsConfigurationFlyoutProps = {
  configurationsStore: ConfigurationsEntity;
  hideFlyoutHandler: () => void;
};

const SecondaryCardsConfigurationFlyout = ({
  configurationsStore,
  hideFlyoutHandler,
}: SecondaryCardsConfigurationFlyoutProps) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const configurations = configurationsStore.getConfigurationsByType(
    ICartConfigurationType.CARD
  );

  const getConfigurationSubtitle = (
    linkedConfigurationKey: string,
    index: number
  ) => {
    const linkedConfiguration = configurationsStore.getConfigurationByKey(
      linkedConfigurationKey
    );
    return getConfigurationName(
      linkedConfiguration,
      index,
      ICartConfigurationType.MOBILE
    );
  };

  return (
    <ConfigurationFlyoutWrapper configurations={configurations} ref={tileRef}>
      <ConfigurationFlyoutTitle
        title={configurationsNameMap[ICartConfigurationType.CARD].title}
      />
      <ConfigurationFlyoutContainer>
        {configurations.map((configuration, index) => (
          <ConfigurationFlyoutItem
            key={configuration.key}
            customClass={styles.configurationTile}
            onClick={() =>
              setActiveConfigurationHandler(
                hideFlyoutHandler,
                configuration,
                configurationsStore,
                TRACKING_CONTEXT,
                ICartConfigurationType.CARD
              )
            }
            isActive={
              configurationsStore.getActiveConfiguration().key ===
              configuration.key
            }
            subtitle={getConfigurationSubtitle(
              configuration.linkedConfigurationKey,
              index
            )}
            name={getConfigurationName(
              configuration,
              index,
              ICartConfigurationType.CARD
            )}
            Icon={iconsMap[ICartConfigurationType.CARD]}
          />
        ))}
        <div ref={tileRef} />
      </ConfigurationFlyoutContainer>
    </ConfigurationFlyoutWrapper>
  );
};

export default SecondaryCardsConfigurationFlyout;
