import { ExpansionArea } from "core/entities/Applications";
import { CardDetails } from "core/entities/Product/Card/ICard";
import type { IBenefit } from "../IBenefit";
import type { ITariffWorld, ILevel, IProduct } from "../IProduct";
import { Group } from "../IProduct";
import { IExtension } from "../IExtension";

/**
 * Represents a speed object containing download and upload speeds.
 *
 * @interface
 * @property {?number} download - The download speed in megabits per second, or `null` if none is available.
 * @property {?number} upload - The upload speed in megabits per second, or `null` if none is available.
 */
interface ISpeed {
  download: number | null;
  upload: number | null;
}

/**
 * Enum mapping sizing to an actual value, so that we can display the products with varying sizes in a consistent way. Ascending value means higher the display size.
 * */

export enum Bandwidths {
  Start = 0,
  XS = 0,
  Basic = 0,
  S = 1,
  Hybrid = 1, // special treatment for magenta_home_hybrid #1080
  M = 2,
  L = 3,
  "XL Special" = 4,
  XL = 4,
  XXL = 5,
  Max = 5,
  "5G Jahrestarif" = 5,
  GIGA = 6,
  EINS = 5,
  Flex = 2,
  Speedbox = 3,
}

export enum TariffSizes {
  XL = "XL",
  L = "L",
}

export enum BusinessCasesLandline {
  NEUKUNDE = "landline_prospect",
  BREITBAND_NEUKUNDE = "broadband_prospect",
  BESTANDSKUNDE_DP = "double_play_client",
  BESTANDSKUNDE_TP = "triple_play_client",
  /* Customer switching from another provider to Telekom */
  KOMPLETTWECHSLER = "complete_changer",
}

export enum BusinessCasesMobile {
  NEUVERTRAG = "new_mobile_contract",
  VERLAENGERUNG = "mobile_contract_extension",
}

export type IBusinessCasesTypeValues = Record<
  BusinessCasesLandline & BusinessCasesMobile,
  string
>;
export enum TariffLevelsKeys {
  PHONE_NONE = "phone_none",
  DEVICE_NONE = "device_none",
  PHONE_DEFAULT = "phone_default",
  STANDART_TARIFF_GK = "standard-tarif_gk_landline_level_definition",
}
// TODO refactor naming
// eslint-disable-next-line @typescript-eslint/naming-convention
export type businessCaseOption = {
  name: string;
  key: BusinessCasesLandline;
};
// Business cases for Landline
export const businessCaseOptions: businessCaseOption[] = [
  {
    name: "Festnetz-Neukunde",
    key: BusinessCasesLandline.NEUKUNDE,
  },
  {
    name: "Wechsel vom Wettbewerber",
    key: BusinessCasesLandline.KOMPLETTWECHSLER,
  },
  {
    name: "MagentaZuhause (DP)",
    key: BusinessCasesLandline.BESTANDSKUNDE_DP,
  },
  {
    name: "MZ mit MagentaTV (TP)",
    key: BusinessCasesLandline.BESTANDSKUNDE_TP,
  },
  {
    name: "Breitband-Neukunde",
    key: BusinessCasesLandline.BREITBAND_NEUKUNDE,
  },
];

export const BusinessCasesLandlineTooltipData = [
  {
    title: "Festnetz Neukunde",
    content: [
      "Kunden, die in den letzten 3 Monaten keinen Festnetz-Anschluss bei der Telekom hatten. Geschäftsfall „NEU/NEU“.",
    ],
  },
  {
    title: "Wechsel vom Wettbewerber",
    content: [
      "Anbieterwechsel zurück zur Telekom Kunde hat Festnetz und Internet beim Wettbewerber und möchte vom jetzigen Anbieter zur Telekom wechseln.",
    ],
  },
  {
    title: "Breitband-Neukunde",
    content: [
      "Telekom Festnetz Kunde, der in den letzten 3 Monaten keinen Breitbandanschluss bei der Telekom hatte sowie Upgrader von Single Play auf Double/Triple Play. Geschäftsfall „WECHSLER/NEU“.",
    ],
  },
  {
    title: "Bestandskunde MagentaZuhause (DP)",
    content: ["Telekom Festnetz-Kunde mit einem Telekom Breitbandanschluss."],
  },
  {
    title: "Bestandskunde MZ mit MagentaTV (TP)",
    content: [
      "Telekom Festnetz-Kunde mit einem MagentaZuhause Tarif mit MagentaTV inklusive Telekom Breitbandanschluss.",
    ],
  },
];

/**
 * Represents a tariff product.
 * @interface ITariff
 * @extends {IProduct}
 * @property {Group.tariff} type - The type of product, always "tariff".
 * @property {string} [dataVolume] - The data volume of the tariff. Only available for mobile tariffs.
 * @property {string} [dataVolumeMagentaOne] - The data volume for Magenta One tariff. Only available for mobile tariffs.
 * @property {ISpeed} speed - The download and upload speed of the tariff
 * @property {ISpeed} speedFiber - The download and upload speed of when fiber option is checked.
 * @property {boolean} isFiber - Whether the tariff supports a fiber connection.
 * @property {(keyof Bandwidths | string)} size - The size of the tariff. Can be a string or a key of the Bandwidths enum.
 * @property {(keyof Bandwidths | string)} displaySize - The sizing_alternative of the tariff. Can be a string or a key of the Bandwidths enum. Gets used when Tariff has size represented as a number. Due to the fact the numeric value can't be used in Enums as a key.
 * @property {IBenefit[]} benefits - The benefits associated with the tariff.
 * @property {(IBusinessCasesTypeValues[] | [])} [availableForBusinessCases] - An array containing business case keys for tariffs. This limits the visibility of the product to the specified business case keys.
 * @property {boolean} isYoung - Whether the tariff is a young tariff.
 * @property {boolean} isVario - Whether the tariff is a vario tariff.
 * @property {ITariffWorld} tariffWorld - The tariff world associated with the tariff.
 * @property {ILevel} level - The level associated with the tariff.
 * @property {number} [quantity] - The quantity of the tariff. Only applicable for magenta_next tool.
 * @property {boolean} isLegacy - Whether the tariff is a legacy tariff. Only applicable for magenta_next tool.
 * @property {string} generation - The generation of the tariff. Only applicable for magenta_next tool.
 * @property {ExpansionArea[]} expansionArea - The expansion areas where the tariff is available. Used for `Im Telekom Ausbaugebiet`, `im Regio-Ausbaugebiet` query.
 * @property {CardDetails} [cardDetails] - The details of the added smartphones associated with the tariff. Name should be deviceDetails.
 * @property {IExtension[]} extensions - Extensions that allows user to choose additional option to selected tariff.
 */

export interface ITariff extends IProduct {
  type: Group.tariff;
  dataVolume?: string;
  dataVolumeMagentaOne?: string;
  dataVolumeExtra: string | null;
  relatedAddons: string[];
  speed: ISpeed;
  speedFiber: ISpeed;
  isFiber: boolean;
  size: keyof Bandwidths | string;
  displaySize: keyof Bandwidths | string;
  benefits: IBenefit[];
  streamingServices: string[];
  availableForBusinessCases?: IBusinessCasesTypeValues[];
  isVario?: boolean;
  isYoung: boolean;
  tariffWorld: ITariffWorld;
  level: ILevel;
  quantity?: number;
  isLegacy: boolean;
  generation: string;
  expansionArea: ExpansionArea[];
  cardDetails?: CardDetails;
  extensions: IExtension[];
}
