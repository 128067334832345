import React from "react";
import { Price } from "@dtpk-cc/components";
import * as styles from "./summary-configuration-price-item.module.scss";

type SummaryConfigurationPriceItemProps = {
  price: number | null;
  customClass?: string;
  isBold?: boolean;
  suffix: string;
};

const SummaryConfigurationPriceItem = ({
  customClass = "",
  isBold = false,
  price,
  suffix,
}: SummaryConfigurationPriceItemProps) => {
  const priceVariants = isBold
    ? [Price.Variant.currentColor, Price.Variant.large, Price.Variant.offering]
    : [Price.Variant.currentColor, Price.Variant.large];

  const suffixData = price !== 0 ? suffix : "";

  return typeof price === "number" ? (
    <div
      className={`${
        price < 0 ? styles.benefit : styles.magenta
      } ${customClass}`}
    >
      <Price
        variants={priceVariants}
        value={price.toString().replace(",", ".")}
        suffix={suffixData}
      />
    </div>
  ) : null;
};

export default SummaryConfigurationPriceItem;
