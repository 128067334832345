import React, { useState, useCallback } from "react";
import { Dropdown, Input, Grid } from "@dtpk-cc/components";
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import { onlyNumber } from "helpers/NumericCalculation";
import { CustomPromotionTypes } from "core/entities/PencilSelling/ICustomPromotion";
import PromotionListActionGroup from "../PromotionListActionSection/PromotionListActionGroup";
import { trackClick } from "../../../helpers/reactTracking";

import "./custom-promotion-form.scss";

const promotionTypesMap = {
  [CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE]:
    "Monatliche Preisreduktion",
  [CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE]: "Einmalige Preisreduktion",
  [CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE]: "Aktion ohne Preis",
};

const CustomPromotionForm = ({
  setIsFormVisible,
  setFormState,
  formState,
  onAdd,
  isAddMode,
  defaultFormConfig,
  portfolioKey,
  onEdit,
  promotions,
  setActivePromotions,
  highlightedPromotions,
  setHighlightedPromotions,
  setIsLoading,
  isLoading,
}) => {
  const [promotionIsValid, setPromotionIsValid] = useState(true);

  const editCustomPromotion = useCallback(
    async (updatedPromotionItem) => {
      const promotionIsHighlighted = highlightedPromotions.some(
        (promotion) => promotion.key === updatedPromotionItem.key
      );

      if (promotionIsHighlighted) {
        const updatePromotion = (promotionItems) =>
          promotionItems.map((selectedPromotion) =>
            selectedPromotion.key === updatedPromotionItem.key
              ? updatedPromotionItem
              : selectedPromotion
          );

        // Replace existing selected promotion data in cart
        setActivePromotions((prevSelectedPromotions) =>
          updatePromotion(prevSelectedPromotions)
        );
        // Replace existing highlighted promotion data in local state
        setHighlightedPromotions((prevHighlightedPromotions) =>
          updatePromotion(prevHighlightedPromotions)
        );
      }
      await onEdit(updatedPromotionItem);
    },
    [
      setHighlightedPromotions,
      onEdit,
      highlightedPromotions,
      setActivePromotions,
    ]
  );

  const submitHandler = async () => {
    try {
      const promotionExists = promotions.some(
        // key !== formState.key check statement for Edit mode
        ({ name, key }) => name === formState.name && key !== formState.key
      );

      setPromotionIsValid(!promotionExists);

      if (promotionExists) return;
      setIsLoading(true);
      const promotionItem = {
        key: formState.key,
        portfolio: portfolioKey,
        name: formState.name,
        description: formState.name,
        type: formState.type,
        discount: {
          value: formState.value
            ? parseFloat(formState.value.replace(",", "."))
            : null,
          interval: formState.interval
            ? parseInt(formState.interval, 10)
            : null,
          kind: PromotionPriceType.CUSTOM_PROMOTION,
        },
      };

      if (isAddMode) {
        await onAdd(promotionItem);
        trackClick("fügen-sie-benutzerdefinierte-werbung-hinzu");
      } else {
        await editCustomPromotion(promotionItem);
        trackClick("benutzerdefinierte-werbung-bearbeiten");
      }

      setFormState(defaultFormConfig);
      setIsFormVisible(false);
    } finally {
      setIsLoading(false);
    }
  };

  const actionBtnIsDisabled = useCallback(
    (customValidationHandler = () => true) => {
      const formInputValues = Object.values(formState).filter(
        (formInput) => formInput !== null
      );
      const inputIsValid = customValidationHandler();

      return (
        !formInputValues.every((formInput) => !!formInput) ||
        !inputIsValid ||
        isLoading
      );
    },
    [formState, isLoading]
  );

  const inputChangeHandler = ({ target }) => {
    setFormState((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const getInputComponentTemplate = (
    placeholder,
    name,
    value = "",
    isNumberType = false,
    isValid = true,
    invalidMessage = ""
  ) => (
    <Input
      invalidMessage={invalidMessage}
      isValid={isValid}
      name={name}
      value={value}
      customClass=""
      onChange={(event) => inputChangeHandler(event)}
      onKeyPress={(e) => {
        if (isNumberType) {
          onlyNumber(e);
        }
      }}
    >
      {placeholder}
    </Input>
  );

  const formTypeConfigMap = {
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_MONTHLY]: {
    //   components: {
    //     interval: getInputComponentTemplate(
    //       "Monate",
    //       "interval",
    //       formState?.interval,
    //       true
    //     ),
    //     from: getInputComponentTemplate(
    //       "Gültig ab",
    //       "from",
    //       formState?.from,
    //       true
    //     ),
    //     to: getInputComponentTemplate("Gültig bis", "to", formState?.to, true),
    //   },
    //   formConfigData: {
    //     interval: "",
    //   },
    // },
    [CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE]: {
      components: {
        value: getInputComponentTemplate(
          "Preis",
          "value",
          formState?.value,
          true
        ),
        interval: getInputComponentTemplate(
          "Monate",
          "interval",
          formState?.interval,
          true,
          formState?.interval > 0,
          "Muss größer als 0 sein"
        ),
        // NOTE: Temporarily commented
        // from: getInputComponentTemplate(
        //   "Gültig ab",
        //   "from",
        //   formState?.from,
        //   true
        // ),
        // to: getInputComponentTemplate("Gültig bis", "to", formState?.to, true),
      },
      formConfigData: {
        value: "",
        interval: "",
      },
      validationHandler: () => {
        // No need to validate in case of null
        if (formState?.interval === null) {
          return true;
        }

        return parseInt(formState.interval, 10) > 0;
      },
    },
    [CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE]: {
      components: {
        value: getInputComponentTemplate(
          "Preis",
          "value",
          formState?.value,
          true
        ),
      },
      formConfigData: {
        value: "",
        interval: null,
      },
      validationHandler: () => true,
    },
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_PROVISION_FEE]: {
    //   components: {},
    //   formConfigData: {
    //     interval: null,
    //     from: null,
    //     to: null,
    //   },
    // },
    [CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE]: {
      components: {},
      formConfigData: {
        value: null,
        interval: null,
      },
      validationHandler: () => true,
    },
    // NOTE: Temporarily commented
    // [PROMOTION_TYPE_CONST_PRICE_REDUCE]: {
    //   components: {},
    //   formConfigData: {
    //     interval: null,
    //     from: null,
    //     to: null,
    //   },
    // },
  };

  const promotionTypeChangeHandler = (displayName) => {
    const getKeyByValue = (object, value) =>
      Object.keys(object).find((key) => object[key] === value);

    const promotionTypeKey = getKeyByValue(promotionTypesMap, displayName);

    setFormState((prev) => ({
      ...formTypeConfigMap[promotionTypeKey].formConfigData,
      name: prev.name,
      key: prev.key,
      type: promotionTypeKey,
    }));
  };

  const formComponents = formTypeConfigMap[formState.type]?.components || {};

  return (
    <Grid customClass="custom-promotion-form ">
      <Grid.Row customClass="custom-promotion-form__row">
        <Grid.Col
          customClass="custom-promotion-form__col"
          s={6}
          m={12}
          l={12}
          xl={24}
        >
          <Input
            name="name"
            isValid={promotionIsValid}
            invalidMessage={`${formState.name} existiert bereits`}
            value={formState.name}
            onChange={inputChangeHandler}
          >
            Name
          </Input>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row customClass="custom-promotion-form__row">
        <Grid.Col
          customClass="custom-promotion-form__col"
          s={6}
          m={12}
          l={12}
          xl={24}
        >
          <Dropdown
            customClass="custom-promotion-form__dropdown"
            value={promotionTypesMap[formState.type]}
            label="Wähle die Aktionsart"
            items={Object.values(promotionTypesMap)}
            onSelection={promotionTypeChangeHandler}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row customClass="custom-promotion-form__row">
        {Object.entries(formComponents).map(([key, formComponent], index) => (
          <Grid.Col
            customClass="custom-promotion-form__col"
            key={`${key}${index}`}
            s={3}
            m={6}
            l={6}
            xl={12}
          >
            {formComponent}
          </Grid.Col>
        ))}
      </Grid.Row>
      <PromotionListActionGroup
        cancelBtnHandler={() => {
          setIsFormVisible(false);
          trackClick("benutzerdefiniertes-werbeformular-schließen");
        }}
        submitBtnIsDisabled={actionBtnIsDisabled(
          formTypeConfigMap[formState.type]?.validationHandler
        )}
        cancelBtnIsDisabled={isLoading}
        submitBtnHandler={submitHandler}
      />
    </Grid>
  );
};

export default CustomPromotionForm;
