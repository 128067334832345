import React from "react";
import { A11yText, Button } from "@dtpk-cc/components";

import * as styles from "./toggle-main-btn.module.scss";

type ToggleMainBtnProps = {
  checked: boolean;
  customClass?: string;
  onClick?: (event) => void;
  role?: ToggleMainBtnRoles;
};

export enum ToggleMainBtnRoles {
  button = "button",
  div = "div",
}

const ToggleMainBtn = ({
  checked,
  customClass = "",
  onClick = () => {},
  role = ToggleMainBtnRoles.button,
}: ToggleMainBtnProps) =>
  role === ToggleMainBtnRoles.button ? (
    <Button
      onClick={onClick}
      variants={Button.Variant.bare}
      customClass={`${styles.toggleButton} ${customClass} ${
        checked ? styles.checked : ""
      }`}
      role="radio"
    >
      <A11yText>Tariftyp</A11yText>
      <span className={styles.toggleButtonSwitcher} />
    </Button>
  ) : (
    <div
      className={`${styles.toggleButton} ${customClass} ${
        checked ? styles.checked : ""
      }`}
    >
      <span className={styles.toggleButtonSwitcher} />
    </div>
  );

export default ToggleMainBtn;
