import type {
  ISettings,
  ITariff,
} from "core/entities/MagentaNext/Tariff/ITariff";
import type { IGetSettings } from "./IGetSettings";

export class GetSettingsUseCase implements IGetSettings {
  constructor(private readonly entity: ITariff) {}

  execute(): ISettings {
    return this.entity.getSettings();
  }
}
