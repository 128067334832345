import React from "react";
import Modal from "components/Modal";
import { Button, Dropdown, Flex, Modal as LPGModal } from "@dtpk-cc/components";
import { ActionRemoveDefault } from "@dtpk-cc/components/dist/icons";

import {
  IActiveSettings,
  ITariffConfigurationSettings,
  MagentaNextCard,
} from "core/presenter/MagentaNext/LegacyTariffPresenter";
import * as styles from "./card-config-modal.module.scss";

const BASIC_SIZE = "Basic"; // remove basic from size hardcoded

const SIZES_IN_ORDER = [
  "Kids & Teens",
  "Teens",
  "Basic",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "GIGA",
  "EINS",
  "Teens EINS",
  "Max",
  "5G Jahrestarif",
];

type CardConfigModalProps = {
  isOpen?: boolean;
  card: MagentaNextCard;
  settings: ITariffConfigurationSettings;
  activeSettings: IActiveSettings;
  headline?: string;
  buttonText?: string;
  onChange: (key: keyof IActiveSettings, value: string) => void;
  onSubmit: () => void;
  onDelete?: () => void;
  onCloseModal: () => void;
  withoutBasicSize?: boolean;
};

export const CardConfigModal = ({
  isOpen = false,
  card,
  settings,
  activeSettings,
  headline = "Karte hinzufügen",
  buttonText = "Speichern",
  onChange,
  onSubmit,
  onDelete = null,
  onCloseModal,
  withoutBasicSize = false,
}: CardConfigModalProps) => {
  const isModalSubmitDisabled = !card;

  const tariffWorldValue = settings.tariffWorlds?.find(
    (tariffWorld) => tariffWorld.key === activeSettings.tariffWorld
  )?.name;

  const tariffWorldOptions = settings.tariffWorlds?.map(
    (tariffWorld) => tariffWorld.name
  );

  const generationValue = activeSettings.generation
    ? activeSettings.generation
    : "";

  const submitCardConfig = () => {
    onSubmit();
    onCloseModal();
  };

  const handleTariffWorldChange = (name: string) => {
    onChange(
      "tariffWorld",
      settings.tariffWorlds?.find((tariffWorld) => tariffWorld.name === name)
        ?.key
    );
  };

  const settingSizes = settings.sizes.filter((size) =>
    withoutBasicSize ? size !== BASIC_SIZE : size
  );

  const orderedSizes = SIZES_IN_ORDER.filter((size) =>
    settingSizes.includes(size)
  );

  return (
    <Modal
      active={isOpen}
      close={onCloseModal}
      submitButtonDisabled={isModalSubmitDisabled}
      submitButtonText={buttonText}
      showSubmitButton
      handleSubmitButton={submitCardConfig}
    >
      <LPGModal.Heading>{headline}</LPGModal.Heading>
      {tariffWorldOptions?.length > 1 && (
        <Dropdown
          customClass={`${styles.cardConfigModal} ${styles.select}`}
          label="Tarifwelt"
          value={tariffWorldValue}
          items={tariffWorldOptions}
          onSelection={handleTariffWorldChange}
        />
      )}
      <Flex gap customClass={`${styles.cardConfigModal} ${styles.flex}`}>
        <Dropdown
          key={`generation-${activeSettings.tariffWorld}`}
          customClass={`${styles.cardConfigModal} ${styles.select}`}
          label="Generation"
          value={generationValue}
          items={settings.generations}
          disabled={!settings.generations.length}
          onSelection={(value) => onChange("generation", value)}
        />
        <Dropdown
          key={`size-${activeSettings.generation}`}
          customClass={`${styles.cardConfigModal} ${styles.select}`}
          label="Tarifstufe"
          value={activeSettings.size}
          items={orderedSizes}
          disabled={!settings.sizes.length}
          onSelection={(value) => onChange("size", value)}
        />
      </Flex>

      <dl className={`${styles.definitionList} ${styles.container}`}>
        <dt className={`${styles.definitionList} ${styles.term}`}>
          Datenvolumen:
        </dt>
        <dd className={`${styles.definitionList} ${styles.description}`}>
          {card?.dataVolume || "–"}
        </dd>
        <dt className={`${styles.definitionList} ${styles.term}`}>Preis:</dt>
        <dd className={`${styles.definitionList} ${styles.description}`}>
          {card
            ? `${String(card.price.monthly).replace(".", ",")} € mtl.`
            : "–"}
        </dd>
      </dl>

      {onDelete && (
        <Button
          customClass={`${styles.cardConfigModal} ${styles.delete}`}
          variants={[Button.Variant.outline, Button.Variant.icon]}
          onClick={onDelete}
        >
          <Button.Icon icon={ActionRemoveDefault} />
          Karte löschen
        </Button>
      )}
    </Modal>
  );
};
