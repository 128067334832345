import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ISetOfferValidUntilUseCase } from "./ISetOfferValidUntil";

export class SetOfferValidUntilUseCase implements ISetOfferValidUntilUseCase {
  constructor(private entity: IOffer) {}

  execute(validUntil: Date): void {
    this.entity.setValidUntil(validUntil);
  }
}
