import React from "react";
import {
  Badge,
  Tile,
  Container,
  CopyText,
  Price,
  Icon,
  Button,
  A11yText,
} from "@dtpk-cc/components";
import { ActionEditDefault } from "@dtpk-cc/components/dist/icons";

import { PromotionPriceType } from "core/entities/Product/IDiscount";

import PropTypes from "prop-types";
import { trackClick } from "../../helpers/reactTracking";

import "./promotion-tile.scss";
import { convertPriceStringToNumber } from "../../helpers/NumberHelpers";

const PromotionTile = ({
  name,
  customClass = "",
  additionalDescription,
  pricing,
  productPrice,
  active,
  handleClick,
  onEdit = null,
}) => {
  const isMonthly =
    pricing.kind === PromotionPriceType.MONTHLY ||
    pricing.kind === PromotionPriceType.MONTHLY_DISCOUNT ||
    pricing.kind === PromotionPriceType.PERMANENT_MONTHLY_DISCOUNT;

  const priceSuffix = isMonthly || pricing.interval > 0 ? "mtl." : "einmalig";

  const isCustom = pricing.kind === PromotionPriceType.CUSTOM_PROMOTION;

  const pricePrefix =
    isCustom && pricing.interval ? `${pricing.interval}x` : "";

  const priceClass = isMonthly
    ? "promotion-tile__monthly"
    : "promotion-tile__once";

  const priceAfterPromotion = () => {
    if (isCustom) {
      return pricing.value ? -convertPriceStringToNumber(pricing.value) : null;
    }
    return pricing.kind === PromotionPriceType.MONTHLY_DISCOUNT
      ? productPrice - pricing.value
      : pricing.value;
  };

  return (
    <Tile
      customClass={`${customClass} promotion-tile ${
        active ? "promotion-tile--active" : ""
      }`}
      variant="shadow"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {active && (
        <Badge variants={Badge.Variant.flag}>
          <Badge.Paragraph
            style={{ color: "#fff" }}
            fonts={Badge.Paragraph.Font.additionalHighlight}
          >
            Ausgewählt
          </Badge.Paragraph>
        </Badge>
      )}

      <Tile.Content customClass="promotion-tile__content">
        {isCustom && (
          <CopyText customClass="promotion-tile__custom-heading">
            Eigene Aktion
          </CopyText>
        )}
        <CopyText customClass="promotion-tile__heading">{name}</CopyText>
        {additionalDescription && (
          <CopyText customClass="promotion-tile__additional-description">
            {additionalDescription}
          </CopyText>
        )}
        <Container
          customClass={`promotion-tile__price-container ${priceClass}`}
        >
          {isMonthly && productPrice && (
            <Price
              prefix="statt"
              variants={[Price.Variant.scaled]}
              value={productPrice}
            />
          )}
          {typeof priceAfterPromotion() === "number" && (
            <Price
              variants={Price.Variant.currentColor}
              prefix={pricePrefix}
              suffix={priceSuffix}
              value={priceAfterPromotion()}
            />
          )}
        </Container>
        {!!onEdit && (
          <Button
            customClass="promotion-tile__edit-btn"
            variants={[Button.Variant.bare, Button.Variant.icon]}
            onClick={(event) => {
              event.stopPropagation();
              onEdit();
              trackClick("benutzerdefinierte-aktion-bearbeiten");
            }}
          >
            <Icon icon={ActionEditDefault} />
            <A11yText>Aktion bearbeiten</A11yText>
          </Button>
        )}
      </Tile.Content>
    </Tile>
  );
};

PromotionTile.propTypes = {
  name: PropTypes.string,
  pricing: PropTypes.shape({}),
  productPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default PromotionTile;
