import type { IDescription } from "core/entities/Product/IDescription";
import { IPrice } from "core/entities/Product/IPrice";
import { ProductGroupKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import type { IPromotion } from "../PencilSelling/IPromotion";

export enum Group {
  addon = "addon",
  tariff = "tariff",
  card = "card",
  device = "device",
}

export enum PortfolioKeys {
  LANDLINE = "landline",
  MOBILE = "mobile",
}

export enum BenefitKeys {
  flatrate = "flatrate",
  discount = "discount",
  dataVolume = "data_volume",
  happyHour = "happy_hour",
}

export interface ICategory {
  key: string;
  name: string;
  valency: number | null;
  link?: string;
  link_title: string;
}

export interface ILevel {
  key: string;
  name: string;
  valency: number | null;
}

export interface ITariffWorld {
  key: string;
  name: string;
  links?: {
    young: string;
    default: string;
    link_title: string | null;
  };
  limit: number | null;
  valency: number | null;
}

export interface IProductGroupBase {
  key: ProductGroupKeys | string;
  name: string;
}

/**

  * @interface IProduct
  * @description The base interface for ITariff, ICard, IDevice, IAddon. Representing a product with basic information and properties.
  * @property {string} name - The name of the product.
  * @property {IPrice} price - The price object of the product.
  * @property {IDescription} description - The description object of the product.
  * @property {string} key - The unique key identifier of the product.
  * @property {ICategory} [subcategory] - The subcategory object of the product.
  * @property {number} [valency] - This determines the display order, ascending..
  * @property {number} contractPeriod - The contract period in months of the product.
  * @property {IPromotion[]} [promotions] - The array of promotions available for the product.
  * @property {string} [additionalDescription] - An additional description of the product.
*/
export interface IProduct {
  name: string;
  nameAlternative: string | null;
  price: IPrice;
  description: IDescription;
  key: string;
  subcategory?: ICategory;
  valency?: number;
  contractPeriod: number;
  promotions?: IPromotion[];
  additionalDescription?: string;
}
