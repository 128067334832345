import React, { useState } from "react";
import { Grid, CopyText } from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import TariffConfigurationExtraSelectionModal from "../../TariffConfigurationExtraSelectionModal";
import { trackClick } from "../../../../../../helpers/reactTracking";
import PhoneItem from "../PhoneItem";
import LinkBtn from "../../../../../../elements/new-design/LinkBtn";
import {
  IAdditionalDevices,
  ICartItemData,
} from "../../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { IStepTrackingContext } from "../../../../../../views/PencilSelling/IPencilSelling";
import * as styles from "./tariff-configuration-phones-modal.module.scss";

const PHONES_MODAL_MAX_AMOUNT = 5;

type TariffConfigurationPhonesModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  businessCaseName: string;
  productInCart: ICartItemData | undefined;
  smartphoneInfos: IAdditionalDevices[];
  trackingContext: IStepTrackingContext;
  setSmartphoneInfos: React.Dispatch<
    React.SetStateAction<IAdditionalDevices[]>
  >;
  addSmartphonesHandler: (smartphoneItems: IAdditionalDevices[]) => void;
};

const TariffConfigurationPhonesModal = ({
  isOpen,
  setIsOpen,
  businessCaseName,
  productInCart,
  smartphoneInfos,
  trackingContext,
  setSmartphoneInfos,
  addSmartphonesHandler,
}: TariffConfigurationPhonesModalProps) => {
  const [modalSmartphoneInfoState, setModalSmartphoneInfoState] = useState<
    IAdditionalDevices[]
  >(
    smartphoneInfos.length > 0
      ? smartphoneInfos
      : [
          {
            name: "",
            price: "",
            manufacturer: "",
            suffix: "",
            isAlternative: false,
          },
        ]
  );

  const handleSmartphoneInfoRemove = (phoneItemIndex: number) => {
    setModalSmartphoneInfoState((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      updatedDevicesArr.splice(phoneItemIndex, 1);
      if (updatedDevicesArr.length) {
        const regularDevice = updatedDevicesArr[0];
        regularDevice.isAlternative = false;
        updatedDevicesArr[0] = regularDevice;
      }
      return updatedDevicesArr;
    });
  };

  const handleSmartphoneInfoAdd = () => {
    setModalSmartphoneInfoState((devicesArr) => [
      ...devicesArr,
      {
        name: "",
        price: "",
        manufacturer: "",
        suffix: "",
        isAlternative: false,
      },
    ]);
  };

  const handleSmartphoneInfoChange = (
    index: number,
    key: string,
    value: string | boolean
  ) => {
    setModalSmartphoneInfoState((devicesArr) => {
      const updatedDevicesArr = [...devicesArr];
      const targetDevice = { ...updatedDevicesArr[index] };
      targetDevice[key] = value;
      updatedDevicesArr[index] = targetDevice;
      return updatedDevicesArr;
    });
  };

  const submitModalHandler = () => {
    const filteredPhones = modalSmartphoneInfoState.filter(
      ({ name, manufacturer }) => !!name.trim() || manufacturer
    );
    const updatedPhones = filteredPhones.map((phoneItem) => ({
      ...phoneItem,
      suffix: phoneItem.isAlternative ? "(Alternative)" : "",
    }));
    addSmartphonesHandler(updatedPhones);
    setSmartphoneInfos(updatedPhones);
    setIsOpen(false);
  };

  const regularDeviceIsFilled =
    !!modalSmartphoneInfoState[0]?.manufacturer ||
    !!modalSmartphoneInfoState[0]?.name.trim();

  const addBtnIsDisplayed =
    modalSmartphoneInfoState.length === 0 ||
    (modalSmartphoneInfoState.length < PHONES_MODAL_MAX_AMOUNT &&
      regularDeviceIsFilled);

  return (
    <TariffConfigurationExtraSelectionModal
      submitDisabled={
        modalSmartphoneInfoState.length !== 0 && !regularDeviceIsFilled
      }
      customClass={styles.phoneModal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      businessCaseName={businessCaseName}
      tariffName={productInCart?.name}
      onSubmit={() => {
        submitModalHandler();
        trackClick(
          `${trackingContext.portfolio}.modales.smartphone.fenster`,
          trackingContext.main
        );
      }}
      onCancel={() => {
        setIsOpen(false);
        trackClick(
          `${trackingContext.portfolio}.modales.smartphone.schließen`,
          trackingContext.main
        );
      }}
    >
      <CopyText customClass="m-b-24">
        Setze ein Häkchen in der Checkbox, um das Endgerät als “Alternative” zu
        markieren. Alternative Endgeräte werden nicht in die Gesamtsumme mit
        einberechnet.
      </CopyText>
      <Grid customClass={styles.phoneItemsWrapper}>
        {modalSmartphoneInfoState.map((phoneItem, index) => (
          <PhoneItem
            key={index}
            index={index}
            isAlternative={phoneItem.isAlternative}
            name={phoneItem.name}
            manufacturer={phoneItem.manufacturer}
            price={phoneItem.price}
            onNameClick={() => {
              trackClick(
                `${trackingContext.portfolio}.smartphone.name`,
                trackingContext.main
              );
            }}
            onNameChange={(value: string) =>
              handleSmartphoneInfoChange(index, "name", value.trim())
            }
            onManufacturerChange={(value: string) => {
              handleSmartphoneInfoChange(index, "manufacturer", value);
              trackClick(
                `${value}.smartphone.manufacturer`,
                trackingContext.main
              );
            }}
            onPriceClick={() => {
              trackClick(
                `${trackingContext.portfolio}.smartphone.preis`,
                trackingContext.main
              );
            }}
            onPriceChange={(value: string) =>
              handleSmartphoneInfoChange(index, "price", value)
            }
            onIsAlternativeChange={() => {
              handleSmartphoneInfoChange(
                index,
                "isAlternative",
                !phoneItem.isAlternative
              );
              trackClick(
                `${
                  !phoneItem.isAlternative ? "alternative" : "regulär"
                }.smartphone`,
                trackingContext.main
              );
            }}
            onRemove={() => handleSmartphoneInfoRemove(index)}
          />
        ))}
        {addBtnIsDisplayed && (
          <LinkBtn
            customClass={styles.addBtn}
            icon={ActionAddDefault}
            onClick={() => {
              handleSmartphoneInfoAdd();
              trackClick(
                `${trackingContext.portfolio}.smartphone.hinzufügen`,
                trackingContext.main
              );
            }}
            text="Endgerät hinzufügen"
          />
        )}
      </Grid>
    </TariffConfigurationExtraSelectionModal>
  );
};

export default TariffConfigurationPhonesModal;
