import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useTariffSelection } from "hooks/PencilSelling/useTariffSelection";
import { useStores } from "../../../../../stores/BusinessClient";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";
import { ITariff } from "../../../../../core/entities/Product/Tariff/ITariff";
import { mobileBusinessCase } from "../../../../../core/entities/Product/BusinessClient/IBaseData";
import { TariffWorldKeys } from "../../../../../core/repositories/ProductsRepository/DefinitionKeys";
import TariffSelectionBenefit from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import TariffSelectionLoyaltyBonus from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionLoyaltyBonus";
import BaseMobileTariffConfiguration from "../../../../../components/new-design/Tariff/TariffConfiguration/BaseMobileTariffConfiguration";
import TariffSelection from "../../../../../components/new-design/Tariff/TariffSelection";
import TariffMainWrapper from "../../../../../components/new-design/Tariff/TariffMainWrapper";
import {
  ICartConfigurationType,
  IMobileSettings,
} from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { useMobileTariffSelection } from "../../../../../hooks/PencilSelling/useMobileTariffSelection";
import { useTariffSelectionData } from "../../../../../hooks/PencilSelling/useTariffSelectionData";
import TariffSelectionHeader from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionHeader";
import AddonsResetWarningModal from "../../../../../components/new-design/Tariff/AddonsResetWarningModal";

const TRACKING_CONTEXT = {
  main: "wunschtarif-geschaefts",
  portfolio: "mobilfunk",
};

const MobileTariffSelection = () => {
  const {
    productsStore,
    configurationsStore,
    offerStore,
    productsRepositoryStore,
  } = useStores();

  const { tariffs } = productsStore;

  const {
    settings: mobileSettings,
    isBenefitActive,
    tariffInCart,
    activeTariff: mobileTariff,
    setActiveTariff: setMobileTariff,
    smartphoneInfos,
    setSmartphoneInfos,
    selectedPromotions: selectedMobilePromotions,
    addToCart,
    addPromotionsHandler,
    addSmartphonesHandler,
    warningModalCallback,
    warningModalIsOpen,
    setWarningModalIsOpen,
    removeTariffAddonsDialogHandler,
    customPromotions,
  } = useTariffSelection<IMobileSettings>(
    PortfolioKeys.MOBILE,
    tariffs[PortfolioKeys.MOBILE],
    configurationsStore,
    productsStore,
    offerStore.getBaseOffer()
  );
  const {
    getTariffFeature,
    setBenefitSettings,
    handleMobileTariffChange,
    handleMobileSettingsChange,
  } = useMobileTariffSelection({
    isBenefitActive,
    configurationsStore,
    mobileTariff,
    mobileSettings,
    setMobileTariff,
    addToCart,
    smartphoneInfos,
    tariffs: tariffs[PortfolioKeys.MOBILE],
  });

  const { businessCase } = mobileSettings;
  const {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  } = useTariffSelectionData({
    businessCase,
    isLandline: false,
    settings: mobileSettings,
    tariffs: tariffs[PortfolioKeys.MOBILE],
    businessCaseData: mobileBusinessCase.items,
  });

  const setLoyaltyBonusValue = useCallback(
    (value: number) => {
      configurationsStore.setActiveConfigurationSettings<IMobileSettings>({
        key: "loyaltyBonusValue",
        value,
      });
    },
    [configurationsStore]
  );

  const toggleBenefit = (tariff: ITariff | null) => {
    const IsBenefitActive =
      configurationsStore.getActiveConfigurationBenefitIsActive();

    if (!IsBenefitActive) {
      setLoyaltyBonusValue(0);
      configurationsStore.setActiveConfigurationAmount(1);
    }

    setBenefitSettings(!IsBenefitActive, tariff);
    configurationsStore.setActiveConfigurationBenefitIsActive(!IsBenefitActive);
    addToCart(mobileTariff, selectedMobilePromotions, smartphoneInfos);
  };

  const loyaltyBonusIsDisplayed =
    configurationsStore.activeConfigurationIsWithLoyaltyBonus() &&
    offerStore.getFrameworkContract().loyaltyBonusIsAvailable &&
    isBenefitActive &&
    (mobileSettings.tariffWorld === TariffWorldKeys.business_mobil_3 ||
      mobileSettings.tariffWorld === TariffWorldKeys.business_mobil_eins);

  const benefitIsDisplayed =
    configurationsStore.activeConfigurationIsWithActiveBenefit(
      ICartConfigurationType.MOBILE
    ) && offerStore.getFrameworkContract().benefitsAreAvailable;

  useEffect(() => {
    if (!loyaltyBonusIsDisplayed && mobileSettings.loyaltyBonusValue > 0) {
      setLoyaltyBonusValue(0);
    }
  }, [
    setLoyaltyBonusValue,
    loyaltyBonusIsDisplayed,
    mobileSettings.loyaltyBonusValue,
  ]);

  return (
    <TariffMainWrapper>
      <BaseMobileTariffConfiguration
        withCustomPromotions
        customPromotions={customPromotions[PortfolioKeys.MOBILE]}
        withMultipleOnceMonthlyPromotions={false}
        productsRepositoryStore={productsRepositoryStore}
        offerStore={offerStore.getBaseOffer()}
        configurationsStore={configurationsStore}
        businessCaseName={businessCaseName}
        businessCase={businessCase}
        activeTariffInCart={tariffInCart}
        activeTariff={mobileTariff}
        addPromotionsHandler={addPromotionsHandler}
        addSmartphonesHandler={addSmartphonesHandler}
        selectedPromotions={selectedMobilePromotions}
        smartphoneInfos={smartphoneInfos}
        setSmartphoneInfos={setSmartphoneInfos}
        settings={mobileSettings}
        onSettingsChange={(key, value) => {
          removeTariffAddonsDialogHandler(() =>
            handleMobileSettingsChange(key, value)
          );
        }}
        trackingContext={TRACKING_CONTEXT}
        tariffWorlds={tariffWorlds}
        levels={levels}
        activeLevelExists={activeLevelExists}
        activeLevelIndex={activeLevelIndex}
        setActiveLevelIndex={setActiveLevelIndex}
      />
      <TariffSelection
        trackingContext={TRACKING_CONTEXT}
        getTariffFeature={getTariffFeature}
        handleTariffChange={(tariff, activeTariff) => {
          removeTariffAddonsDialogHandler(() =>
            handleMobileTariffChange(tariff, activeTariff)
          );
        }}
        isBenefitActive={isBenefitActive}
        settings={mobileSettings}
        activeTariff={mobileTariff}
        tariffs={filteredTariffs}
      >
        <TariffSelectionHeader
          checkboxLabelText="Vario-Tarife anzeigen"
          settings={mobileSettings}
          settingKey="isVario"
          isChecked={mobileSettings.isVario}
          onChange={() => {
            removeTariffAddonsDialogHandler(() =>
              handleMobileSettingsChange("isVario", !mobileSettings.isVario)
            );
          }}
          activeTariffWorld={activeTariffWorld}
        />
        {(benefitIsDisplayed || loyaltyBonusIsDisplayed) && (
          <div className="tariff-entries-bonus-wrapper">
            {benefitIsDisplayed && (
              <TariffSelectionBenefit
                withLoyaltyBonus={loyaltyBonusIsDisplayed}
                isActive={isBenefitActive}
                onClick={() => toggleBenefit(mobileTariff)}
              />
            )}
            {loyaltyBonusIsDisplayed && (
              <TariffSelectionLoyaltyBonus
                value={mobileSettings.loyaltyBonusValue}
                onChange={(value: number) => setLoyaltyBonusValue(value)}
              />
            )}
          </div>
        )}
      </TariffSelection>
      <AddonsResetWarningModal
        trackingContext={TRACKING_CONTEXT}
        resetOptionsModalCallback={warningModalCallback}
        setResetOptionsModalIsOpen={setWarningModalIsOpen}
        resetOptionsModalIsOpen={warningModalIsOpen}
      />
    </TariffMainWrapper>
  );
};

export default observer(MobileTariffSelection);
