import React, { useEffect } from "react";

import * as styles from "./configuration-flyout-wrapper.module.scss";
import { ICartConfiguration } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";

const CONFIGURATIONS_AMOUNT_WITHOUT_FLYOUT_SCROLL = 8;

type ConfigurationFlyoutWrapperProps = {
  configurations: ICartConfiguration[];
  children: React.ReactNode;
};

const ConfigurationFlyoutWrapper = React.forwardRef(
  (
    { children, configurations }: ConfigurationFlyoutWrapperProps,
    ref: React.RefObject<HTMLElement | HTMLButtonElement>
  ) => {
    useEffect(() => {
      if (configurations.length > CONFIGURATIONS_AMOUNT_WITHOUT_FLYOUT_SCROLL) {
        ref?.current?.scrollIntoView({ behavior: "smooth" });
        ref?.current?.focus();
      }
    }, [configurations.length, ref]);

    return <div className={styles.configurationFlyout}>{children}</div>;
  }
);

export default ConfigurationFlyoutWrapper;
