import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import type { ISetActiveFamilyCard } from "./ISetActiveFamilyCard";

export class SetActiveFamilyCard implements ISetActiveFamilyCard {
  constructor(private entity: ITariff) {}

  execute(cardKey: string): void {
    this.entity.getFamilyCards(true).forEach(({ key }) => {
      this.entity.setQuantity(key, 0);
    });

    this.entity.setQuantity(cardKey, 1);
  }
}
