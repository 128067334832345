import React from "react";
import { observer } from "mobx-react";
import { CopyText, Icon } from "@dtpk-cc/components";
import AlertInformationDefault from "@dtpk-cc/components/dist/icons/Alert/Information/Default";
import TariffEntry from "../TariffEntry";
import { trackClick } from "../../../../../helpers/reactTracking";
import {
  IBusinessCasesTypeValues,
  ITariff,
} from "../../../../../core/entities/Product/Tariff/ITariff";
import { roundUpValue } from "../../../../../helpers/NumberHelpers";

import * as styles from "./tariff-entries.module.scss";
import { IStepTrackingContext } from "../../../../../views/PencilSelling/IPencilSelling";
import { getBenefitDiscount } from "./helpers/default-tariff-prices";
import { GetTariffPriceProp } from "../ITariffSelection";
import { getHappyHourBenefitOptionName } from "../../../../../helpers/GetBenefitOptions";

type TariffEntriesProps = {
  trackingContext: IStepTrackingContext;
  tariffs: ITariff[];
  activeTariff: ITariff;
  handleTariffChange: (tariff: ITariff, activeTariff: ITariff) => void;
  isBenefitActive: boolean;
  getTariffFeature: (tariff: ITariff) => {
    tariffFeature: string;
    tariffFeatureUnit: string;
    tariffExtraFeature?: string;
    tariffExtraFeatureUnit?: string;
  };
  loyaltyBonusValue: number | null;
  getTariffPrice: GetTariffPriceProp;
  businessCase?: IBusinessCasesTypeValues;
};

const TariffEntries = ({
  tariffs,
  activeTariff,
  handleTariffChange,
  isBenefitActive,
  loyaltyBonusValue = 0,
  getTariffFeature,
  trackingContext,
  getTariffPrice,
  businessCase,
}: TariffEntriesProps) => {
  const getTariffPriceWithLoyaltyBonus = (
    monthlyProductPrice: number | null
  ) => {
    if (!monthlyProductPrice) return 0;

    const price = monthlyProductPrice - loyaltyBonusValue / 24;
    // TODO add provisionFee handling in PK redesign implementation
    // const provisionFee = mobileSettings.hasProvisionFee
    //   ? productPrice.provision / 24
    //   : 0;
    // const priceWithFee = price + provisionFee;
    // return priceWithFee || 0;
    return roundUpValue(price, 2);
  };

  const getLegacyPrice = (tariffEntry: ITariff) => {
    if (typeof tariffEntry.price.originalPrice === "number")
      return tariffEntry.price.originalPrice;
    const benefitDiscountValue = getBenefitDiscount(tariffEntry);

    if (
      tariffEntry.isYoung &&
      typeof tariffEntry.price.youngDiscount?.price === "number" &&
      tariffEntry.price.youngDiscount?.availableForBusinessCases.includes(
        businessCase
      )
    ) {
      return isBenefitActive
        ? tariffEntry.price.monthly - benefitDiscountValue
        : tariffEntry.price.monthly;
    }

    return tariffEntry.price.monthly;
  };

  return tariffs.length > 0 ? (
    <div className={styles.tariffEntriesWrapper}>
      {tariffs.map((tariffEntry) => (
        <TariffEntry
          key={tariffEntry.key}
          // TODO test in PK redesign
          isHybrid={tariffEntry.key === "magenta_home_hybrid"} // special treatment for magenta_home_hybrid #1080
          tariffName={tariffEntry.nameAlternative}
          isActive={activeTariff?.key === tariffEntry.key}
          onClick={() => {
            handleTariffChange(tariffEntry, activeTariff);
            trackClick(
              `${trackingContext.portfolio}.tariff.${tariffEntry.name}`,
              trackingContext.main
            );
          }}
          price={getTariffPrice(tariffEntry, isBenefitActive, businessCase)}
          legacyPrice={getLegacyPrice(tariffEntry)}
          streamingServices={tariffEntry.streamingServices}
          isYoung={tariffEntry.isYoung}
          isVario={tariffEntry.isVario}
          subtitle={getTariffFeature(tariffEntry)}
          priceWithLoyaltyBonus={
            loyaltyBonusValue > 0
              ? getTariffPriceWithLoyaltyBonus(
                  getTariffPrice(tariffEntry, isBenefitActive)
                )
              : null
          }
          description={tariffEntry.description.usps}
          monthlyPriceBeforeFrameworkDiscount={
            tariffEntry.price.monthlyPriceBeforeFrameworkDiscount
          }
          relatedAddonsAreExist={!!tariffEntry.relatedAddons.length}
          withHappyHour={
            getHappyHourBenefitOptionName(tariffEntry.benefits[0]) &&
            isBenefitActive
          }
        />
      ))}
    </div>
  ) : (
    <div className={styles.emptyPlaceholder}>
      <Icon
        wrapperProps={{ className: styles.emptyPlaceholderIcon }}
        icon={AlertInformationDefault}
      />
      <CopyText>
        In der Kombination aus Tarifwelt und Substufe sind aktuell keine Tarife
        verfügbar. Bitte ändern Sie ihre Konfiguration.
      </CopyText>
    </div>
  );
};

export default observer(TariffEntries);
