import type { ITariff } from "core/entities/MagentaNext/Tariff/ITariff";
import { IGetMobileTariffsLevels } from "./IGetMobileTariffsLevels";
import { ILevel } from "../../../entities/Product/IProduct";

export class GetMobileTariffsLevelsUseCase implements IGetMobileTariffsLevels {
  constructor(private readonly entity: ITariff) {}

  execute(): ILevel[] {
    return this.entity.getMobileTariffsLevels();
  }
}
