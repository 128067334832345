import React from "react";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import * as styles from "./address-check-wrapper.module.scss";

type AddressCheckWrapperProps = {
  customClass?: string;
  bgImgUrl: string;
  imgAltValue: string;
};

const AddressCheckWrapper: React.FC<AddressCheckWrapperProps> = ({
  customClass = "",
  bgImgUrl,
  imgAltValue,
  children,
}) => (
  <div className={`${styles.wrapper} ${customClass}`}>
    <img className={styles.bg} src={bgImgUrl} alt={imgAltValue} />
    {children}
  </div>
);

export default AddressCheckWrapper;
