import { useMemo, useState } from "react";
import {
  MapSettings,
  MapLayerOptionsKey,
  MapViewport,
  LocationPoint,
  LocationPointKey,
  RouteTransportation,
  MapSettingsManager,
  MapSettingsColorScheme,
  MapSettingsMode,
  MapSettingsBackgroundColorScheme,
} from "./types";
import { MIN_ZOOM_FOR_INFRASTRUCTURE } from "../constants/const";

export const useManageMapSettings = (): MapSettingsManager => {
  const [mapSettings, setMapSettings] = useState<MapSettings>({
    layerOptions: {
      "4g_or_5g_tower": true,
      "5g_booster_tower": true,
      "5g_booster_planned_tower": false,
      telekom_shop: false,
      telekom_partner_shop: false,
      outdoorNetworkCoverage: true,
    },
    colorScheme: MapSettingsColorScheme.colorful,
    backgroundColorScheme: MapSettingsBackgroundColorScheme.default,
    locationPoints: {
      start: null,
      end: null,
    },
    viewport: null,
    routeTransportation: RouteTransportation.drive,
    hideInfrastructure: true,
  });

  const toggleMapLayerOptions = (optionKeys: MapLayerOptionsKey[]) => {
    setMapSettings((settings) => ({
      ...settings,
      layerOptions: {
        ...settings.layerOptions,
        ...optionKeys.reduce(
          (acc, key) => ({ ...acc, [key]: !settings.layerOptions[key] }),
          {}
        ),
      },
    }));
  };

  const setOutdoorNetworkCoverage = (isOutdoor: boolean) => {
    setMapSettings((settings) => ({
      ...settings,
      layerOptions: {
        ...settings.layerOptions,
        outdoorNetworkCoverage: isOutdoor,
      },
    }));
  };

  const setLocationPoint = (key: LocationPointKey, point: LocationPoint) => {
    setMapSettings((settings) => ({
      ...settings,
      locationPoints: {
        ...settings.locationPoints,
        [key]: point,
      },
    }));
  };

  const setViewport = (viewport: MapViewport) => {
    setMapSettings((settings) => ({
      ...settings,
      viewport,
      hideInfrastructure: viewport.zoom <= MIN_ZOOM_FOR_INFRASTRUCTURE,
    }));
  };

  const setColorScheme = (scheme: MapSettingsColorScheme) => {
    setMapSettings((settings) => ({
      ...settings,
      colorScheme: scheme,
    }));
  };

  const setBackgroundColorScheme = (
    scheme: MapSettingsBackgroundColorScheme
  ) => {
    setMapSettings((settings) => ({
      ...settings,
      backgroundColorScheme: scheme,
    }));
  };

  const setEnvironment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMapSettings((settings) => ({
      ...settings,
      layerOptions: {
        ...settings.layerOptions,
        environment: value,
      },
    }));
  };

  const setRouteTransportation = (transportation: RouteTransportation) => {
    setMapSettings((settings) => ({
      ...settings,
      routeTransportation: transportation,
    }));
  };

  const mapSettingsMode = useMemo(() => {
    if (mapSettings.locationPoints.start) {
      if (mapSettings.locationPoints.end) {
        return MapSettingsMode.route;
      }
      return MapSettingsMode.address;
    }

    return MapSettingsMode.viewport;
  }, [mapSettings]);

  return {
    mapSettings,
    mapSettingsMode,
    mapSettingsSetter: {
      toggleMapLayerOptions,
      setOutdoorNetworkCoverage,
      setLocationPoint,
      setViewport,
      setRouteTransportation,
      setColorScheme,
      setBackgroundColorScheme,
      setEnvironment,
    },
  };
};
