import {
  businessCaseOptions,
  BusinessCasesLandlineTooltipData,
  BusinessCasesMobile,
} from "core/entities/Product/Tariff/ITariff";

export class PersonalDataPresenter {
  fiberQuestionTitle = "Glasfaser verfügbar?";

  bandwidthData = {
    label: "...im Telekom Ausbaugebiet",
    fiberItems: [
      { name: "M", size: 2 },
      { name: "L", size: 3 },
      { name: "XL", size: 4 },
      { name: "XXL", size: 5 },
      { name: "1000", size: 6 },
    ],
    items: [
      { name: "S", size: 1 },
      { name: "M", size: 2 },
      { name: "L", size: 3 },
      { name: "XL", size: 4 },
    ],
    regioLabel: "... im Regio-Ausbaugebiet",
    regioItems: [
      { name: "M", size: 2 },
      { name: "L", size: 3 },
      { name: "XL", size: 4 },
    ],
  };

  mobileBusinessCase = {
    label: "MOBILFUNK",
    subtitle: "Geschäftsfall",
    items: [
      { name: "Neuvertrag", key: BusinessCasesMobile.NEUVERTRAG },
      { name: "Vertragsverlängerung", key: BusinessCasesMobile.VERLAENGERUNG },
    ],
  };

  landlineBusinessCase = {
    label: "ZUHAUSE",
    subline:
      "Der ausgewählte Geschäftsfall zeigt in der weiteren Angebotserstellung <b>passende</b> Aktionen und Produkte an.",
    tooltips: BusinessCasesLandlineTooltipData,
    items: businessCaseOptions,
  };

  constructor(private isFiber: boolean) {}

  get bandwidths() {
    return this.isFiber
      ? this.bandwidthData.fiberItems
      : this.bandwidthData.items;
  }

  get regioBandwidths() {
    return this.bandwidthData.regioItems;
  }
}
