import React from "react";
import { observer } from "mobx-react";
import {
  ICartConfigurationType,
  IMobileSettings,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { useStores } from "../../../../stores/PrivateClient";
import { useStepActionHeaderNavMap } from "../../../../hooks/PencilSelling/useStepActionHeaderNavMap";
import PageWrapper from "../../../../elements/new-design/PageWrapper";
import StepActionHeader from "../../../../components/new-design/StepHeader/StepActionHeader";
import { useGetActiveConfigurationDefaultName } from "../../../../hooks/PencilSelling/useGetActiveConfigurationDefaultName";
import PageInner from "../../../../elements/new-design/PageWrapper/PageInner";
import MobileTariffAddons from "./MobileTariffAddons";
import MobileTariffSelection from "./MobileTariffSelection";

const TRACKING_CONTEXT = "mobilfunk-tarife-privatkunden";

type MobileTariffProps = {
  displayConfigurationFlyout: (flyoutKey: string) => void;
  removeConfigurationHandler: ({
    type,
    configurationKeysToDelete,
  }: {
    type: ICartConfigurationType;
    configurationKeysToDelete: string[];
  }) => void;
};

const MobileTariff = ({
  displayConfigurationFlyout,
  removeConfigurationHandler,
}: MobileTariffProps) => {
  const { configurationsStore, offerStore } = useStores();
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const { activeTabIndex, getMobileNavMap } = useStepActionHeaderNavMap(
    TRACKING_CONTEXT,
    configurationsStore
  );
  const { activeConfigurationName } = useGetActiveConfigurationDefaultName(
    ICartConfigurationType.MOBILE,
    configurationsStore
  );

  const businessCase = offerStore
    .getBaseOffer()
    .getBusinessCase(ICartConfigurationType.MOBILE);

  configurationsStore.setActiveConfigurationSettings<IMobileSettings>({
    key: "businessCase",
    value: businessCase as IMobileSettings[keyof IMobileSettings],
  });

  const navTabsMap = [
    <MobileTariffSelection />,
    <MobileTariffAddons businessCase={businessCase} />,
  ];

  return (
    <PageWrapper>
      <StepActionHeader
        configurationsStore={configurationsStore}
        trackingContext={TRACKING_CONTEXT}
        title={activeConfigurationName}
        subtitle="Alles zum mobilen Surfen und Telefonieren"
        navMap={getMobileNavMap()}
        activeTabIndex={activeTabIndex}
        offerStore={offerStore.getBaseOffer()}
        displayConfigurationFlyout={displayConfigurationFlyout}
        removeConfigurationHandler={() =>
          removeConfigurationHandler({
            type: ICartConfigurationType.MOBILE,
            configurationKeysToDelete: [activeConfiguration.key],
          })
        }
      />
      <PageInner>{navTabsMap[activeTabIndex]}</PageInner>
    </PageWrapper>
  );
};

export default observer(MobileTariff);
