// extracted by mini-css-extract-plugin
var _1 = "card-tile-module-active-tgyAk";
var _2 = "card-tile-module-badge-DIDdl";
var _3 = "card-tile-module-button-NVVAP";
var _4 = "card-tile-module-cardTile-Z3Gmf";
var _5 = "card-tile-module-content-iEVS6";
var _6 = "card-tile-module-disabled-I0PyI";
var _7 = "card-tile-module-dropdown-OTpHC";
var _8 = "card-tile-module-headerWrapper-eMryb";
var _9 = "card-tile-module-heading-m6Z3z";
var _a = "card-tile-module-headingFamily-_Q8LM";
var _b = "card-tile-module-inputDisabled-EAnBr";
var _c = "card-tile-module-inputsOuter-iy4ni";
var _d = "card-tile-module-inputsWrapper-ijRNJ";
var _e = "card-tile-module-magentaNextText-kSKRE";
var _f = "card-tile-module-phoneName-kNauH";
var _10 = "card-tile-module-phonePrice-GfgHy";
var _11 = "card-tile-module-rightCornerBadge-JhsPQ";
var _12 = "card-tile-module-tile-qmv3i";
export { _1 as "active", _2 as "badge", _3 as "button", _4 as "cardTile", _5 as "content", _6 as "disabled", _7 as "dropdown", _8 as "headerWrapper", _9 as "heading", _a as "headingFamily", _b as "inputDisabled", _c as "inputsOuter", _d as "inputsWrapper", _e as "magentaNextText", _f as "phoneName", _10 as "phonePrice", _11 as "rightCornerBadge", _12 as "tile" }
