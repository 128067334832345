import type { IOffer, INotes } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { IGetNotesUseCase } from "./IGetNotes";

export class GetNotesUseCase implements IGetNotesUseCase {
  constructor(private entity: IOffer) {}

  execute(): INotes {
    return this.entity.getNotes();
  }
}
