import React from "react";

import * as styles from "./page-wrapper.module.scss";

type PageWrapperProps = {
  customClass?: string;
};

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  customClass = "",
}) => <div className={`${styles.pageWrapper} ${customClass}`}>{children}</div>;

export default PageWrapper;
