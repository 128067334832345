import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import type { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import type { IReplaceCartItemUseCase } from "./IReplaceCartItem";

export class ReplaceCartItemUseCase implements IReplaceCartItemUseCase {
  constructor(private cart: ICart) {}

  execute(key: string, cartItem: ICartItemData): void {
    this.cart.replace(key, cartItem);
  }
}
